import { useEffect, useState, useContext } from "react";
import { Grid, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import * as XLSX from "xlsx";
import TopSearchBar from "../../../components/SearchBar";
import MyContext from "../../../Provider/MyContext";
import CustomerDataTable from "../../../components/CustomerDataTable";
import { AvenirBlackH4, H6, H4, H2 } from "../../../utils/text";
import CirclePluse from "../../../components/SVG/PluseIcon";
import ContextMenu from "../../../components/ContextMenu";
import DownloadIcon from "../../../components/SVG/DownlodeIcon";
import UploadIcon from "../../../components/SVG/UploadIcon";
import DeleteIcon from "../../../components/SVG/Delete";
import styles from "../../../styles/index";
import SnackBar from "../../../components/SnackBar";
import NoData from "../../../components/NoData";
import { downloadCustomers, uploadCustomers } from "../../../utils/excel";
import CenterModal from "../../../components/CenterModal";
import InputText from "../../../components/InputText";
import {
  fetchCustomers,
  deleteCustomer,
  fetchAllCustomers,
  uploadCustomersToServer,
} from "../../../redux/actions";
import AlertDialog from "../../../components/Dialog";
import ProvinceData from "./province";
import SourceChannelData from "./channelData";

const Customer = (props) => {
  const style = styles();
  const CustomersList = useSelector(
    (state) => state.profileReducers.customersList
  );
  const pagenumber = useSelector(
    (state) => state.profileReducers.customersOffset
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [serchText, setSerchText] = useState("");
  const [heading, setHeading] = useState("");
  const [bodyMessage, setBodyMessage] = useState("");
  const [ids, setIds] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const limit = useSelector((state) => state.profileReducers.limit);
  const Context = useContext(MyContext);
  const [snackbar, setSnackbar] = useState(null);
  const [showExtra, setShowExtra] = useState(false);
  const [UploadModal, setUploadModal] = useState(false);
  const [columns, setColumns] = useState([
    {
      field: "full_name",
      headerName: Context.langData.name,
      id: "name",
      sort: true,
      size: 3,
      desc: true,
    },
    {
      field: "email",
      headerName: Context.langData.email_header,
      id: "email",
      sort: true,
      size: 3,
      desc: true,
    },
    {
      field: "phone_number",
      headerName: Context.langData.phoneNumber,
      id: "phn",
      sort: true,
      size: 3,
    },
    {
      field: "registered_on",
      headerName: Context.langData.registrationDate,
      id: "reg",
      sort: true,
      size: 3,
      desc: true,
    },
  ]);
  const ContextMenuData = [
    {
      id: 1,
      icon: DownloadIcon,
      name: Context.langData.download,
    },
    // {
    //   id: 2,
    //   icon: UploadIcon,
    //   name: Context.langData.upload
    // },
    {
      id: 3,
      icon: DeleteIcon,
      name: Context.langData.delete,
    },
  ];

  useEffect(() => {
    let offsetValue = 0;
    if (pagenumber > 0) {
      offsetValue = pagenumber - 1;
    }
    const queryString = { limit, offset: offsetValue };
    if (serchText) {
      queryString.search_text = serchText;
    }
    if (orderBy) {
      queryString.sortBy = orderBy;
      queryString.orderBy = order;
    }
    dispatch(fetchCustomers(queryString));
  }, [pagenumber, serchText, orderBy, order]);

  const fetchCustomerDetails = () => {
    let offsetValue = 0;
    if (pagenumber > 0) {
      offsetValue = pagenumber - 1;
    }
    const queryString = { limit, offset: offsetValue };
    if (serchText) {
      queryString.search_text = serchText;
    }
    if (orderBy) {
      queryString.sortBy = orderBy;
      queryString.orderBy = order;
    }
    dispatch(fetchCustomers(queryString));
  };

  function onColumnClick(col) {
    navigate("/home/profile/customers/editCustomer", {
      state: { id: col.customer_id },
    });
  }

  const deleteCallback = (isSuccess) => {
    fetchCustomerDetails();
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.customer_deleted_successfully,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.customer_delete_failed,
        severity: "error",
      });
    }
  };
  const handleClose = (val) => {
    if (val) {
      dispatch(deleteCustomer({ delete_customer_id: ids }, deleteCallback));
    }
    setShowDialog(false);
  };

  const totalNumber = (total) => {
    const totalPages = Math.floor(total / 25) + (total % 25 !== 0 ? 1 : 0);
    return totalPages;
  };
  const onCheckedIds = (checkedIds) => {
    setIds(checkedIds);
  };
  const triggerUpload = () => {
    setUploadModal(true);
  };
  function parseCustomers(customers) {
    const isDup = _.uniq(customers, "email");
    console.log("IS DUP");
    if (typeof customers !== "undefined") {
      const obj = {
        total: customers.length,
        data: customers,
      };
      obj?.data?.map((item) => {
        item.ts = moment(item.registered_on * 1000).format("MM/DD/YYYY");
        item.registered_on = moment(item.registered_on * 1000).format(
          "MM/DD/YYYY"
        );
        item.id = item.customer_id;
        if (item?.province != null) {
          const provinceName = ProvinceData.find(
            (e) => e.value == item.province
          );
          if (provinceName) item.province = provinceName.name;
          else item.province = null;
        } else {
          item.province = null;
        }
        if (item?.source_channel != null) {
          const channelName = SourceChannelData.find(
            (e) => e.value == item.source_channel
          );
          if (channelName) item.source_channel = channelName.name;
          else item.source_channel = null;
        } else {
          item.source_channel = null;
        }
        if (!item.email) {
          item.email = "-";
        }
        if (!item.phone_number) {
          item.phone_number = "-";
        } else {
          const x = item.phone_number;
          item.phone_number = `${x.substring(0, 1)}-${x.substring(
            1,
            4
          )}-${x.substring(4, 7)}-${x.substring(7)}`;
        }
      });
      console.log(obj.data, "objobjobjobjobj");
      return obj.data;
    }
    return [];
  }
  function onDeleteCustomers() {
    if (ids.length > 0) {
      if (ids.length > 1) {
        setBodyMessage(
          `${Context.langData.customer_delete_body + ids.length} customers?`
        );
        setHeading("Delete customers?");
        setShowExtra(true);
      } else {
        setBodyMessage("Are you sure you want to delete this customer?");
        setHeading("Delete customer?");
        setShowExtra(true);
      }
      setShowDialog(true);
    }
  }
  const callBack = (id) => {
    console.log("id", id);
    if (id == 3) {
      onDeleteCustomers();
    } else if (id == 1) {
      const count = CustomersList.total;
      let offsetValue = 0;
      if (pagenumber > 0) {
        offsetValue = pagenumber - 1;
      }
      const queryString = { limit: count, offset: offsetValue };
      if (serchText) {
        queryString.search_text = serchText;
      }
      if (orderBy) {
        queryString.sortBy = orderBy;
        queryString.orderBy = order;
      }
      dispatch(
        fetchAllCustomers(queryString, (response) => {
          downloadCustomers(parseCustomers(response));
        })
      );
    }
    // else if (id == 2) {
    // triggerUpload()
    //   // $("#upload").trigger("click")
    // }
  };
  const onPageinationChange = (value) => {
    dispatch({ type: "SET_CUSTOMERS_OFFSET", payload: value });
  };
  const onChangeText = (value) => {
    setSerchText(value);
  };
  const handleSort = (name, desc, index) => {
    const data = [...columns];
    data[index] = { ...data[index], desc };
    setColumns(data);
    setOrder(desc ? "asc" : "desc");
    if (name == "full_name") {
      name = "name";
    }
    setOrderBy(name);
  };

  const handleNewCustomer = () => {
    navigate("/home/profile/customers/newCustomers");
  };

  const hasDup = (data, key) => {
    const d = data.map((e) => e[key]);
    console.log("filter", d);
    const nondup = _.uniq(d);
    console.log("uniq", nondup);
    return d.length !== nondup.length;
  };
  const upload = async (data) => {
    const customerData = await uploadCustomers(data);
    const isDup = hasDup(customerData, "email");
    if (isDup) {
      setSnackbar({
        message: "Duplicate rows found, failed to upload.",
        severity: "error",
      });
    } else if (customerData.length > 0) {
      customerData.map((item) => {
        if (item.birthday != null) {
          item.birthday = moment(item.birthday).format("MM/DD/YYYY");
        }
        if (typeof item.email !== "string") {
          item.email = item.email?.text;
        }
        if (item.phone_number) {
          item.phone_number = String(item.phone_number);
        }
      });
      console.log("customerData1212", customerData, "ISDup", isDup);
      dispatch(
        uploadCustomersToServer(customerData, (isSuccess) => {
          setUploadModal(false);
          if (isSuccess) {
            fetchCustomerDetails();
            setSnackbar({
              message: Context.langData.customer_upload_successfully,
              severity: "success",
            });
          } else {
            setSnackbar({
              message: Context.langData.customer_upload_failed,
              severity: "error",
            });
          }
        })
      );
    }
  };
  const handleUploadCallback = (uploadCustomerList) => {
    console.log(
      uploadCustomerList,
      "uploadCustomerListuploadCustomerListuploadCustomerList"
    );
    upload(uploadCustomerList);
  };
  return (
    <Grid data-qaid='customers_mainContainer' container>
      {(CustomersList.data && CustomersList.data.length > 0) || serchText ? (
        <>
          <Grid
            data-qaid='customers_subContainer1'
            container
            style={{
              margin: 20,
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
            }}>
            <Grid data-qaid='customers_TopSearchBar' item xs={12} lg={9} md={9}>
              <TopSearchBar
                value={serchText}
                onChange={(value) => onChangeText(value.target.value)}
                name='serchText'
                id='serchText'
                placeholder={Context.langData.customer_serch_text}
                style={{ height: 56 }}
                width='100%'
              />
            </Grid>
            <Grid
              data-qaid='customers_buttonContainer'
              item
              xs={12}
              lg={3}
              md={3}
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: 10,
                marginTop: 5,
              }}>
              <Button
                className={style.buttonClass}
                data-qaid='customers_buttonView'
                style={{
                  backgroundColor: "#FFB600",
                  height: 40,
                  width: 190,
                  marginRight: 5,
                  marginLeft: 24,
                }}
                variant='contained'
                id='cancel'
                onClick={handleNewCustomer}>
                <CirclePluse
                  data-qaid='customers_createpluse'
                  width={16}
                  height={16}
                />
                <AvenirBlackH4
                  data-qaid='customers_createlabel'
                  label={Context.langData.create_customer}
                  style={{ paddingLeft: 8, lineHeight: "20px" }}
                />
              </Button>
              <ContextMenu menuItems={ContextMenuData} callBack={callBack} />
            </Grid>
          </Grid>
          <Grid data-qaid='customers_subContainer2' container>
            <Grid item lg={12} style={{ marginLeft: 20, marginRight: 20 }}>
              {CustomersList.data.length > 0 ? (
                <CustomerDataTable
                  data-qaid='customers_datatable'
                  columns={columns}
                  rows={CustomersList.data}
                  onClickAction={onColumnClick}
                  onPageChange={onPageinationChange}
                  style={{ width: "100%" }}
                  handleSort={handleSort}
                  onCheckedIds={onCheckedIds}
                  offset={pagenumber}
                  total={totalNumber(CustomersList.total)}
                />
              ) : (
                <Grid
                  data-qaid='customers_labelView'
                  item
                  lg={12}
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    marginTop: "10%",
                    flexDirection: "column",
                  }}>
                  <H6
                    data-qaid='customers_noRecordsLabel'
                    label={Context.langData.no_records}
                    style={{
                      textAlign: "center",
                      width: "55%",
                      fontWeight: "bold",
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <NoData page='customer' callback={handleNewCustomer} />
      )}
      {snackbar && (
        <SnackBar
          data-qaid='shifts_snackbar'
          open
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}

      {UploadModal && (
        <UploadCustomersModal
          data-qaid='UploadCustomersModal_customer'
          open={UploadModal}
          onClose={() => setUploadModal(false)}
          callback={handleUploadCallback}
        />
      )}
      <AlertDialog
        showDialog={showDialog}
        body={bodyMessage}
        heading={heading}
        IsError={false}
        handleClose={handleClose}
        showExtra={showExtra}
      />
    </Grid>
  );
};

const UploadCustomersModal = ({ open, callback, onClose }) => {
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  const style = styles();
  const [fileName, setFileName] = useState("");
  const [itemsList, setItemsList] = useState([]);
  const triggerUpload = () => {
    document.getElementById("upload").click();
  };
  const upload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = e.target.result;
      setItemsList(data);
    };
    setFileName(file?.name);
    reader.readAsArrayBuffer(file);
  };
  const to_json = (workbook) => {
    let result = [];
    const sheetName = workbook.SheetNames[0];
    const roa = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[sheetName]
    );
    if (roa.length > 0) {
      result = roa;
    }
    return result;
  };
  const DeleteUploadFile = () => {
    setItemsList([]);
    setFileName("");
  };
  const isDisable = !fileName;
  return (
    <CenterModal open={open} onClose={onClose}>
      <Grid style={{ width: 495, height: fileName ? 405 : 305 }}>
        <Grid style={{ margin: 32, height: fileName ? 300 : 200 }}>
          <Grid>
            <H2
              label={Context.langData.customer_profiles_upload}
              style={{ marginBottom: 16 }}
            />
            <H4
              label={Context.langData.customer_profile_upload_body}
              style={{ marginBottom: 16, color: "#000000", lineHeight: "26px" }}
            />
            {fileName ? (
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 32,
                }}>
                <AvenirBlackH4
                  data-qaid='createcomment_valuelabel'
                  label={Context.langData.file}
                  style={{ marginBottom: 8 }}
                />
                <Grid style={{ display: "flex", flexDirection: "row" }}>
                  <Grid style={{ display: "flex", width: "75%" }}>
                    <InputText value={fileName} disabled id='fileName' />
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      width: "25%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      cursor: "pointer",
                    }}
                    onClick={() => DeleteUploadFile()}>
                    <DeleteIcon />
                    <AvenirBlackH4
                      data-qaid='items_createlabel'
                      label={Context.langData.delete}
                      style={{ color: "#004C60", marginLeft: 8 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          <Button
            onClick={triggerUpload}
            className={style.buttonClass}
            data-qaid='items_creategrid'
            style={{ backgroundColor: "#FFFFFF" }}
            startIcon={
              <UploadIcon
                data-qaid='items_createpluse'
                color='#004C60'
                width={20}
                height={20}
              />
            }>
            <AvenirBlackH4
              data-qaid='items_createlabel'
              label={Context.langData.upload_a_file}
              style={{ color: "#004C60" }}
            />
            <input
              onChange={upload}
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              type='file'
              style={{ display: "none" }}
              id='upload'
            />
          </Button>
        </Grid>
        <Grid
          style={{
            height: 105,
            backgroundColor: "#FAFAFA",
            borderTop: "1px solid  #C3C3C3",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}>
          <Button
            onClick={onClose}
            className={style.buttonClass}
            data-qaid='items_creategrid'
            style={{
              backgroundColor: "#FFFFFF",
              marginRight: 24,
              border: "2px solid #051D33",
              width: 109,
              height: 40,
            }}
            variant='outlined'>
            <AvenirBlackH4
              data-qaid='items_createlabel'
              label={Context.langData.cancel}
            />
          </Button>
          <Button
            onClick={() => {
              callback(itemsList);
            }}
            disabled={isDisable}
            className={style.buttonClass}
            data-qaid='items_creategrid'
            style={{
              backgroundColor: !isDisable ? "#FFB600" : "#c1c1c1",
              width: 109,
              height: 40,
              marginRight: 32,
            }}
            variant='contained'>
            <AvenirBlackH4
              data-qaid='items_createlabel'
              label={Context.langData.save}
            />
          </Button>
        </Grid>
      </Grid>
    </CenterModal>
  );
};
export default Customer;
