import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StepBar from "../StepBar";
import Input from "../Input";
import CustomCheckbox from "../../../components/CustomCheckbox";
import { savePages } from "../../../redux/actions";
import { AvenirBlackH2, H4 } from "../../../utils/text";
import Header from "./header";

const SiteSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { siteSettings, siteInfo } = useSelector((state) => state.builder);
  const { ordertypes } = useSelector((state) => state.metaReducer);
  const [aboutUs, setAboutUs] = useState(false);
  const [contactUs, setContactUs] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [terms, setTrems] = useState(false);
  const [returns, setReturns] = useState(false);
  const [aboutUsMsg, setAboutUsMsg] = useState("");
  const [contactUsMsg, setContactusMsg] = useState("");
  const [privacyMsg, setPrivacyMsg] = useState("");
  const [termsMsg, setTermsMsg] = useState("");
  const [returnsMsg, setReturnsMsg] = useState("");
  const [delivery, setDelivery] = useState({
    is_active: true,
    message: "Home Delivery",
    otype_id: "",
  });
  const [pickup, setPickUp] = useState({
    is_active: true,
    message: "Pick up at store",
    otype_id: "",
  });
  const changeDeliveryOrderType = (val) => {
    //  const type = ordertypes.find(e=>e.otype_id == val)
    const obj = { is_active: true, message: "Home Delivery", otype_id: val };
    // changeField(obj, 'delivery')
    console.log(obj, "b", siteInfo);
    setDelivery(obj);
  };
  const changePickOrderType = (val) => {
    const obj = { is_active: true, message: "Pick up at store", otype_id: val };
    console.log(obj, "pickup");
    setPickUp(obj);
  };
  useEffect(() => {
    if (siteSettings) {
      if (siteSettings.about) {
        setAboutUs(true);
      }
      if (siteSettings.privacy_policy) {
        setPrivacyPolicy(true);
      }
      if (siteSettings.contact) {
        setContactUs(true);
      }
      if (siteSettings.terms) {
        setTrems(true);
      }
      setAboutUsMsg(siteSettings.about_msg || "");
      setContactusMsg(siteSettings.contact_msg || "");
      setPrivacyMsg(siteSettings.privacy_policy_msg || "");
      setTermsMsg(siteSettings.terms_msg);
      setReturnsMsg(siteSettings.returns_msg || "");
    }
  }, [siteSettings]);
  useEffect(() => {
    //     const del = siteInfo.ordertype?.delivery?.otype_id || ""
    //     const pic = siteInfo.ordertype?.pickup?.otype_id || ""
    // setDelivery({ is_active: true, message: "Home Delivery", otype_id: del })
    // setPickUp({ is_active: true, message: "Pick up at store", otype_id: "" })
  }, [siteInfo]);
  const onSave = (next = false) => {
    const obj = {
      about: aboutUs,
      contact: contactUs,
      privacy_policy: privacyPolicy,
      terms,
      returns,
      about_msg: aboutUsMsg,
      contact_msg: contactUsMsg,
      privacy_policy_msg: privacyMsg,
      returns_msg: returnsMsg,
      terms_msg: termsMsg,
    };
    const site = {
      pages: obj,
      ordertype: {
        delivery,
        pickup,
      },
    };
    dispatch(
      savePages(obj, (status) => {
        // dispatch({ type: 'SET_SITE_SETTINGS', payload: obj })
        if (next) {
          navigate("/home/builder/pages");
        }
      })
    );
  };
  const onNext = () => {
    onSave(true);
  };
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}>
      <div style={{ flex: 3, width: "100%" }}>
        <Header step={2} onSave={onSave} onNext={onNext} />
      </div>
      <div style={{ flex: 17, width: "100%" }}>
        <Grid container style={{ justifyContent: "center" }}>
          <Grid item xs={12} lg={8}>
            <Grid item xs={12}>
              <StepBar currentStep={2} />
            </Grid>
            <Grid xs={12}>
              <Grid
                container
                style={{
                  backgroundColor: "white",
                  borderRadius: 10,
                  padding: 48,
                }}>
                {/*
              <Title title="Order types" />
              <Grid item xs={12}>
                <span>
                  Select order types for delivery and pickup.
                </span>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 30 }}>
                <Grid container>
                  <Grid item xs={6}>
                    Delivery
                  </Grid>
                  <Grid item xs={6}>
                    <SingleSelect
                      list={ordertypes}
                      handleChange={(e) => changeDeliveryOrderType(e.target.value)}
                      value={ delivery.otype_id}
                      valueKey="otype_id"
                      displayKey="otype_name" placeholder="Select delivery"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 30 }}>
                <Grid container>
                  <Grid item xs={6}>
                    Pickup
                  </Grid>
                  <Grid item xs={6}>
                    <SingleSelect
                      list={ordertypes}
                      handleChange={(e) => changePickOrderType(e.target.value)}
                      value={pickup.otype_id}
                      valueKey="otype_id"
                      displayKey="otype_name" placeholder="Select pickup"
                    />
                  </Grid>
                </Grid>

              </Grid>
              */}
                <Grid
                  item
                  xs={12}
                  style={{
                    paddingBottom: 20,
                    marginBottom: 30,
                    borderBottom: "1px solid #EEEEEE",
                  }}>
                  <AvenirBlackH2 label='Pages' />
                </Grid>
                <Grid item xs={12}>
                  <H4 label='The default site builder is set to one ordering page. If you would like to add additional pages please select them below.' />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
                  <H4 label='*Not recommended if you currently have a website.' />
                </Grid>
                <Grid item xs={8}>
                  <Grid container style={{ alignItems: "center" }}>
                    <Grid item xs={8}>
                      <CustomCheckbox
                        checked={aboutUs}
                        onChange={() => setAboutUs(!aboutUs)}
                        label='About us'
                      />
                    </Grid>
                    {aboutUs && (
                      <Grid item xs={12} style={{ marginBottom: 10 }}>
                        <Input
                          value={aboutUsMsg}
                          onChange={(e) => setAboutUsMsg(e)}
                          id='abt_message'
                          placeholder='Message'
                          multiline
                          rows={4}
                          paddingLeft={0}
                          marginTop={5}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={8}>
                  <Grid container style={{ alignItems: "center" }}>
                    <Grid item xs={8}>
                      <CustomCheckbox
                        checked={contactUs}
                        onChange={() => setContactUs(!contactUs)}
                        label='Contact us'
                      />
                    </Grid>
                    {contactUs && (
                      <Grid item xs={12} style={{ marginBottom: 10 }}>
                        <Input
                          value={contactUsMsg}
                          onChange={(e) => setContactusMsg(e)}
                          id='cnt_message'
                          placeholder='Message'
                          multiline
                          rows={4}
                          paddingLeft={0}
                          marginTop={5}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={8}>
                  <Grid container style={{ alignItems: "center" }}>
                    <Grid item xs={8}>
                      <CustomCheckbox
                        checked={privacyPolicy}
                        onChange={() => setPrivacyPolicy(!privacyPolicy)}
                        label='Privacy Policy'
                      />
                    </Grid>
                    {privacyPolicy && (
                      <Grid item xs={12} style={{ marginBottom: 10 }}>
                        <Input
                          value={privacyMsg}
                          onChange={(e) => setPrivacyMsg(e)}
                          id='pvc_message'
                          placeholder='Message'
                          multiline
                          rows={4}
                          paddingLeft={0}
                          marginTop={5}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={8}>
                  <Grid container style={{ alignItems: "center" }}>
                    <Grid item xs={8}>
                      <CustomCheckbox
                        checked={terms}
                        onChange={() => setTrems(!terms)}
                        label='Terms & Conditions'
                      />
                    </Grid>
                    {terms && (
                      <Grid item xs={12} style={{ marginBottom: 10 }}>
                        <Input
                          value={termsMsg}
                          onChange={(e) => setTermsMsg(e)}
                          id='trms_message'
                          placeholder='Message'
                          multiline
                          rows={4}
                          paddingLeft={0}
                          marginTop={5}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={8}>
                  <Grid container style={{ alignItems: "center" }}>
                    <Grid item xs={8}>
                      <CustomCheckbox
                        checked={returns}
                        onChange={() => setReturns(!returns)}
                        label='Returns'
                      />
                    </Grid>
                    {returns && (
                      <Grid item xs={12} style={{ marginBottom: 10 }}>
                        <Input
                          value={returnsMsg}
                          onChange={(e) => setReturnsMsg(e)}
                          id='returns_message'
                          placeholder='Message'
                          multiline
                          rows={4}
                          paddingLeft={0}
                          marginTop={5}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default SiteSettings;
