import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Grid,
  Button,
  makeStyles,
  withStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import MultiSelect from "../../../components/MultiSelect";
import Daterange from "../../../components/DateRange";
import MyContext from "../../../Provider/MyContext";
import styles from "../../../styles/index";
import {
  fetchAllCostingKitchens,
  fetchKitchenName,
} from "../../../redux/actions";
import SimpleTable from "../../../components/SimpleTable";
import { H4, AvenirBlackH4 } from "../../../utils/text";
import CircleClose from "../../../components/SVG/CircleClose";
import DownlodeIcon from "../../../components/SVG/DownlodeIcon";

const useStyles = makeStyles({
  btnApply: {
    backgroundColor: "#f0585f",
    color: "white",
    marginLeft: "10px",
  },
  btnClear: {
    backgroundColor: "#dcdcdc",
    color: "black",
  },
});

const Kitchens = () => {
  const style = styles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [table, setTable] = useState([]);
  const costingKitchensData = useSelector(
    (state) => state.inventory.costingKitchens
  );
  const kitchenName = useSelector((state) => state.metaReducer.kitchenName);
  const [date, setDate] = useState({
    start_ts: moment().startOf("day").unix(),
    end_ts: moment().endOf("day").unix(),
  });
  const [kitchens, setKitchens] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const Context = useContext(MyContext);

  useEffect(() => {
    dispatch(fetchAllCostingKitchens({ ...date, kitchen_id_list: "all" }));
    dispatch(fetchKitchenName());
  }, [date]);

  useEffect(() => {
    if (costingKitchensData?.length > 0) {
      setTable(costingKitchensData);
    } else {
      setTable([]);
    }
  }, [costingKitchensData]);

  const onDateChange = useCallback((d) => {
    setDate({ start_ts: d.start_date, end_ts: d.end_date });
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #f4f4f5",
      //   color:'green'
    },
  })((props) => (
    <Menu
      data-qaid='kitchen_menu'
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      "&:focus": {
        backgroundColor: "white",
        color: "black",
        // height:'20px',
        // width:'20px',
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {},
      },
    },
  }))(MenuItem);

  //   const addTotal = () => {
  //     const totalAmount = [];
  //     const add = (a, b) => a + b
  //     const result = array.reduce(add);

  //     console.log(result); // Should give 100
  //   }

  const handleTotalAmount = () => {
    costingKitchensData.map((item) => {
      const totalAmount = [];

      // totalAmount =+ item.total_cost
      console.log(totalAmount, "amount");
    });
  };
  const costingColumns = [
    { field: "stock_name", display: Context.langData.name, size: "25%" },
    { field: "issued_qty", display: Context.langData.issued_qty, size: "25%" },
    { field: "unit_price", display: Context.langData.avg_price, size: "25%" },
    { field: "total_cost", display: Context.langData.amount, size: "25%" },
  ];

  return (
    <>
      <Grid data-qaid='kitchen_main_grid' container style={{ marginLeft: 20 }}>
        <Grid
          data-qaid='kitchen_child_grid'
          container
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            marginRight: 20,
          }}>
          <Grid
            item
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}>
            <Grid
              data-qaid='kitchen_date_grid'
              item
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 16,
              }}>
              <AvenirBlackH4
                data-qaid='kitchen_date_label'
                label={Context.langData.from_to}
              />
              <Daterange
                data-qaid='kitchen_dateRange'
                onDateChange={onDateChange}
              />
            </Grid>

            <Grid
              data-qaid='kitchen_kitchen_grid'
              item
              style={{ display: "flex", flexDirection: "column" }}>
              <AvenirBlackH4
                data-qaid='kitchen_kitchenLabel'
                label={Context.langData.select_kitchen}
              />
              <MultiSelect
                data-qaid='kitchen_kitchenSelect'
                list={kitchenName}
                value='kitchen_id'
                name='name'
                placeholder={Context.langData.all_kitchens}
                selectedValue={kitchens}
                handleChange={(e) => setKitchens(e)}
                border
                minWidth={319}
              />
            </Grid>
          </Grid>
          <Grid
            data-qaid='kitchen_closeCircle'
            item
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingTop: 10,
              justifyContent: "flex-end",
            }}>
            <CircleClose
              data-qaid='kitchen_close_circle'
              data-qaid='createtaxe_closeicon'
              style={{ marginRight: 10 }}
            />
            <H4
              data-qaid='kitchen_cancel_label'
              data-qaid='createtaxe_clearlabel'
              label={Context.langData.clear}
            />
            <Button
              className={style.buttonClass}
              data-qaid='kitchen_apply_button'
              variant='contained'
              style={{
                backgroundColor: "#FFB600",
                marginLeft: 20,
                marginRight: 20,
              }}>
              <H4
                data-qaid='kitchen_apply_label'
                label={Context.langData.apply}
              />
            </Button>
            <Button
              className={style.buttonClass}
              data-qaid='kitchen_creategrid'
              style={{ backgroundColor: "#FFB600" }}
              variant='contained'
              id='cancel'>
              <DownlodeIcon
                data-qaid='kitchen_createpluse'
                style={{ marginRight: 5 }}
              />
              <H4
                data-qaid='kitchen_createlabel'
                label={Context.langData.download}
              />
            </Button>
          </Grid>
        </Grid>
        {/* <Grid container style={{ justifyContent: 'space-around', marginTop: '10px' }}>
                <Grid item>
                    <Button variant="contained" onClick={handleClick} >
                        <Typography variant="h5" > {Context.langData.download}
                        </Typography> <ArrowDropDownIcon /> </Button>
                    <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <StyledMenuItem>
                            <ListItemText primary="Excel" onClick={() => { alert('Downloading') }} />
                        </StyledMenuItem>
                    </StyledMenu>
                </Grid>
                <Grid item style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h5" >{Context.langData.amount_prefix}{handleTotalAmount}</Typography>
                    <Typography variant="h5">{Context.langData.total_amount}</Typography>
                </Grid>
            </Grid> */}
      </Grid>
      <Grid data-qaid='kitchen_simpleTable_grid' style={{ marginTop: "20px" }}>
        <SimpleTable
          data-qaid='kitchen_simpleTable'
          columns={costingColumns}
          rows={table}
        />
      </Grid>
    </>
  );
};

export default Kitchens;
