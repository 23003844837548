import instance, { tax_configuration_instance } from "./axiosInstance";

import urls, { insertId } from "./urls";

const createTaxCode = (params, id, callback) => (dispatch) => {
  tax_configuration_instance
    .post(insertId(urls.taxConfiguration.taxes, id), params)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        callback(true);
        dispatch({ type: "" });
      }
    })
    .catch((err) => {
      callback(false);
    });
};

const editTaxCode = (params, id, id1, callback) => (dispatch) => {
  tax_configuration_instance
    .put(insertId(urls.taxConfiguration.taxUrl, id, id1), params)
    .then((response) => {
      callback(true);
      dispatch({ type: "" });
    })
    .catch((err) => {
      callback(false);
    });
};
const fetchTaxCode = (id, id1, callback) => (dispatch) => {
  tax_configuration_instance
    .get(insertId(urls.taxConfiguration.taxUrl, id, id1))
    .then((response) => {
      if (response.status === 200) {
        callback(response.data);
        dispatch({ type: "GET_TAX_CODE", payload: response.data });
      }
    });
};
const fetchTaxes = (rest_id) => (dispatch) => {
  tax_configuration_instance
    .get(insertId(urls.settings.taxes, rest_id))
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({ type: "GET_TAXES_SUCCESS", payload: response.data });
      }
    });
};
const taxProfileList = (rest_id) => (dispatch) => {
  tax_configuration_instance
    .get(insertId(urls.settings.taxProfiles, rest_id))
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({ type: "GET_TAX_PROFILE_LIST", payload: response.data });
      }
    });
};

const deleteTax = (rest_id, tax_id, callback) => (dispatch) => {
  tax_configuration_instance
    .delete(insertId(urls.taxConfiguration.taxUrl, rest_id, tax_id))
    .then((response) => {
      if (response.status === 200 || response.status === 204) {
        callback(true);
      }
    })
    .catch((err) => {
      callback(false);
      console.log(err);
    });
};
const fetchAllTaxProfiles = (params) => (dispatch) => {
  tax_configuration_instance
    .get(insertId(urls.taxConfiguration.taxProfiles, params.id))
    .then()
    .then((response) => {
      console.log("response-pv", response);
      if (response.status === 200) {
        dispatch({
          type: "GET_TAX_PROFILES",
          payload: response.data,
        });
      }
    });
};

const fetchTaxProfileAssociationList = () => (dispatch) => {
  instance.get(urls.settings.taxProfilesAssociation).then((response) => {
    console.log(response);
    if (response.status === 200) {
      dispatch({
        type: "GET_TAX_PROFILE_ASSOCIATION_LIST",
        payload: response.data,
      });
    }
  });
};

const fetchTaxProfile = (restId, tpId, callback) => (dispatch) => {
  tax_configuration_instance
    .get(insertId(urls.taxConfiguration.taxProfileUrl, restId, tpId))
    .then((response) => {
      if (response.status === 200 || response.status === 304)
        callback(response.data);
    });
};

const createTaxProfile = (restId, params, callback) => (dispatch) => {
  tax_configuration_instance
    .post(insertId(urls.taxConfiguration.taxProfiles, restId), params)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        callback(true);
        dispatch({ type: "" });
      }
    })
    .catch((err) => {
      callback(false);
    });
};
const editTaxProfile = (restId, tpId, params, callback) => (dispatch) => {
  tax_configuration_instance
    .put(insertId(urls.taxConfiguration.taxProfileUrl, restId, tpId), params)
    .then((response) => {
      callback(true);
      dispatch({ type: "" });
    })
    .catch((err) => {
      callback(false);
    });
};

const deleteTaxProfile = (rest_id, taxProfile_id, callback) => (dispatch) => {
  tax_configuration_instance
    .delete(
      insertId(urls.taxConfiguration.taxProfileUrl, rest_id, taxProfile_id)
    )
    .then((response) => {
      console.log(response);
      if (response.status === 200 || response.status === 204) {
        callback(true);
      }
    })
    .catch((err) => {
      callback(false);
      console.log(err);
    });
};

export {
  createTaxCode,
  fetchAllTaxProfiles,
  fetchTaxProfile,
  createTaxProfile,
  editTaxProfile,
  deleteTaxProfile,
  fetchTaxes,
  deleteTax,
  fetchTaxCode,
  editTaxCode,
  taxProfileList,
  fetchTaxProfileAssociationList,
};
