import * as React from "react";

const LoopVector = (props) => (
  <svg
    width={20}
    height={13}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M10 0C4.48 0 0 2.24 0 5c0 2.24 2.94 4.13 7 4.77V13l4-4-4-4v2.73C3.85 7.17 2 5.83 2 5c0-1.06 3.04-3 8-3s8 1.94 8 3c0 .73-1.46 1.89-4 2.53v2.05c3.53-.77 6-2.53 6-4.58 0-2.76-4.48-5-10-5Z'
      fill='#004C60'
    />
  </svg>
);

export default LoopVector;
