import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, InputAdornment } from "@material-ui/core";
import styles from "../../../../styles/index";
import InputText from "../../../../components/InputText";
import { fetchTaxCode, editTaxCode } from "../../../../redux/actions";
import { H4, H2, AvenirBlackH4 } from "../../../../utils/text";
import MyContext from "../../../../Provider/MyContext";
import CloseCircle from "../../../../components/SVG/CloseCircle";
import SettingsIcon from "../../../../components/SVG/Settings";
import RightDrawer from "../../../../components/RightDrawer";
import Message from "../../../../components/Message";
import { validateAlphaNumeric } from "../../../../utils/util";

const EditTaxes = ({ open, callback, row, onClose, taxes }) => {
  const style = styles();
  const Context = useContext(MyContext);
  const [id, setId] = useState(null);
  const [code, setCode] = useState("");
  const [error, setError] = useState(null);
  const [rateError, setRateError] = useState(null);
  const [comment, setComment] = useState("");
  const [taxRate, setTaxRate] = useState("");
  const restId = useSelector((state) => state.loginReducer.selectedRestaurant);
  const taxesData = useSelector((state) => state.taxConfigurationReducer.taxes);
  const dispatch = useDispatch();
  const { taxCode } = useSelector((state) => state.loginReducer);
  const [msg, setMsg] = useState({ open: false, msg: "", severity: "success" });

  useEffect(() => {
    if (row) {
      setId(row.id);
      dispatch(fetchTaxCode(restId.rest_id, row.id, fetchTaxCodeCallback));
    }
  }, [restId, row]);

  const handleCode = (val) => {
    // const upper = val.charAt(0).toUpperCase() + val.slice(1);
    setCode(validateAlphaNumeric(val));
    if (taxes.indexOf(val.toLowerCase()) === -1) {
      setError("");
    } else {
      setError(`${validateAlphaNumeric(val)} already exist`);
    }
  };

  const handleRate = (inputValue) => {
    if (/^\d*\.?\d{0,2}$/.test(inputValue)) {
      if (
        inputValue === "" ||
        (parseFloat(inputValue) >= 0 && parseFloat(inputValue) <= 100)
      ) {
        setTaxRate(inputValue);
      }
    }
  };
  const fetchTaxCodeCallback = (response) => {
    if (response) {
      setId(response.id);
      setComment(response.desc);
      setCode(response.code);
      setTaxRate(response.rate);
    }
  };

  const save = () => {
    !taxRate ? setRateError(Context.langData.no_tax_rate) : setRateError(null);
    if (code && code.trim() !== "") {
      const prof = taxesData.filter(
        (e) => e.code.toLowerCase() === code.toLowerCase() && e.id !== id
      );
      if (prof.length > 0) {
        setError(Context.langData.tax_code_exist);
        return;
      }
      setError("");
    } else {
      setError(Context.langData.no_text_error);
      return;
    }
    if (code && taxRate) {
      dispatch(
        editTaxCode(
          { code, name: code, desc: comment, rate: parseFloat(taxRate) },
          restId.rest_id,
          id,
          callback
        )
      );
    }
  };

  return (
    <RightDrawer
      data-qaid='assigntaxescentermodal'
      open={open}
      onClose={onClose}
      padding={1}>
      <>
        <Grid
          style={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            width: 600,
          }}>
          <Grid
            style={{
              display: "flex",
              height: "80%",
              flexDirection: "column",
              overflowX: "auto",
            }}>
            <Grid
              data-qaid='assigntaxesclose_grid'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}>
              <CloseCircle
                data-qaid='assigntaxescloseicon'
                size={20}
                onClick={onClose}
                style={{ marginRight: 40, marginTop: 20 }}
              />
            </Grid>
            <Grid
              data-qaid='assigntaxessettings_grid'
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 56,
                marginBottom: 10,
              }}>
              <SettingsIcon
                data-qaid='assigntaxessetting_icon'
                style={{ marginRight: 10 }}
                width={16}
                height={16}
                color='#707272'
              />
              <H4
                data-qaid='assigntaxessettings'
                label={Context.langData.settings}
                style={{ color: "#707272" }}
              />
            </Grid>
            <H2
              data-qaid='assignlitchen_title'
              style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
              label={Context.langData.edit_taxes}
            />
            <Grid
              data-qaid='createtaxe_main_grid'
              style={{
                marginLeft: 56,
                marginRight: 56,
                maxWidth: 500,
                marginBottom: 20,
              }}>
              <Grid
                data-qaid='createtaxe_child_grid'
                style={{ display: "flex", flexDirection: "column" }}>
                <Grid
                  data-qaid='createtaxe_catgrid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}>
                  <AvenirBlackH4
                    data-qaid='createtaxe_catlabel'
                    label={Context.langData.name}
                    style={{ marginBottom: 5 }}
                  />
                  <InputText
                    data-qaid='createtaxe_catinput'
                    value={code}
                    error={error}
                    maxLength={64}
                    onChange={(val) => handleCode(val.target.value)}
                    id='code'
                  />
                </Grid>
                <Grid
                  data-qaid='createtaxe_comment_grid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    marginTop: 10,
                  }}>
                  <AvenirBlackH4
                    data-qaid='createtaxe_comlabel'
                    label={Context.langData.comment}
                    style={{ marginBottom: 5 }}
                  />
                  <InputText
                    data-qaid='createtaxe_comip'
                    value={comment}
                    maxLength={256}
                    onChange={(val) => setComment(val.target.value)}
                    id='name'
                  />
                </Grid>
                <Grid
                  data-qaid='createtaxe_comment_grid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    marginTop: 10,
                  }}>
                  <AvenirBlackH4
                    data-qaid='createtaxe_comlabel'
                    label={Context.langData.value}
                    style={{ marginBottom: 5 }}
                  />
                  <InputText
                    data-qaid='createtaxe_valueip'
                    value={taxRate}
                    error={rateError}
                    type='number'
                    min='0'
                    maxLength={6}
                    onChange={(val) => handleRate(val.target.value)}
                    id='rate'
                    endAdornment={
                      <InputAdornment
                        position='end'
                        style={{
                          color: "#051D33",
                        }}>
                        %
                      </InputAdornment>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{
              display: "flex",
              height: "20%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid
              data-qaid='createtaxe_action_grid'
              container
              style={{
                backgroundColor: "#FAFAFA",
                border: "1px solid #C3C3C3",
              }}>
              <Grid
                data-qaid='createtaxe_cancel_btn'
                item
                xs={12}
                lg={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 32,
                  marginBottom: 32,
                  alignItems: "center",
                }}>
                <Button
                  className={style.buttonClass}
                  data-qaid='createtaxe_save_btn'
                  variant='contained'
                  style={{
                    backgroundColor: "#FFB600",
                    marginRight: 32,
                    height: 40,
                    width: 90,
                  }}
                  onClick={save}>
                  <AvenirBlackH4
                    data-qaid='createtaxe_save_text'
                    label={Context.langData.save}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Message
            open={msg.open}
            severity={msg.severity}
            msg={msg.msg}
            handleClose={() => console.log()}
          />
        </Grid>
      </>
    </RightDrawer>
  );
};

export default EditTaxes;
