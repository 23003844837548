import React, { useState, useContext, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import MyContext from "../../../Provider/MyContext";
import InputText from "../../../components/InputText";
import RightDrawer from "../../../components/RightDrawer";
import { AvenirBlackH4, H4, DotText } from "../../../utils/text";
import InventoryIcon from "../../../components/SVG/InventoryIcon";
import CircleClose from "../../../components/SVG/CloseCircle";
import AutoCompliteDropDown from "../../../components/AutoComplete";
import { saveAddonRecipe, fetchRecipeByRecipeId } from "../../../redux/actions";
import styles from "../../../styles/index";
import DeleteIcon from "../../../components/SVG/Delete";

const blockInvalidChar = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

const AddonRecipeFormula = ({ open, row, onClose, callback }) => {
  const style = styles();
  const [quantity, setQuantity] = useState("");
  const recipeAddons = useSelector((state) => state.inventory.recipeAddons);
  const getStocks = useSelector((state) => state.inventory.stocks);
  const [itemsList, setItemsList] = useState([]);
  const Context = useContext(MyContext);
  const [name, setName] = useState("");
  const [itemName, setItemName] = useState("");
  const [id, setId] = useState("");
  const [unitValue, setUnitValue] = useState(Context.langData.units);
  const dispatch = useDispatch();
  const [sendData, setSendData] = useState({});
  const recipeViewData = useSelector((state) => state.inventory.recipeViewData);

  useEffect(() => {
    if (row) {
      setId(row.item_id);
      setItemName(row.addon_name);
      if (row.recipe_id > 0) {
        const params = { ext_product_id: row.recipe_id };
        dispatch(fetchRecipeByRecipeId(params));
      }
      setItemsList(row.recipe);
      setSendData(row);
    }
  }, []);
  useEffect(() => {
    if (recipeViewData) {
      console.log("recipeViewData", recipeViewData);
      // apend recipe details to sendData
      let sendDataObj = { ...sendData };
      if (sendDataObj && sendDataObj.recipe_id) {
        // find detail recipe from recipeViewData
        let recipeItems = [];
        const idx = recipeViewData.findIndex(
          (e) => e.extProductId == row.recipe_id
        );
        if (idx != -1) {
          recipeItems = recipeViewData[idx].items;
        }
        sendDataObj = { ...sendDataObj, recipe: recipeItems };
        setSendData(sendDataObj);
      }
    }
  }, [recipeViewData]);

  useEffect(() => {
    if (sendData) {
      const sendDataObj = { ...sendData };
      console.log("sendDataObjsendDataObj", sendDataObj);
      if (sendDataObj) {
        setItemsList(sendDataObj.recipe);
      }
    }
  }, [sendData]);

  const handleAddItem = () => {
    let sendDataObj = { ...sendData };
    if (name && quantity) {
      if (getStocks) {
        const stockObj = getStocks.filter((e) => e.name == name);
        let recipesList = [];
        if (
          sendDataObj &&
          sendDataObj.recipe &&
          sendDataObj.recipe.length > 0
        ) {
          recipesList = [...sendDataObj.recipe];
          const recipeIndex = recipesList.findIndex(
            (e) => e.id == stockObj[0].id
          );
          if (recipeIndex == -1) {
            var obj = {
              quantity: Number(quantity),
              id: stockObj[0].id,
              name: stockObj[0].name,
              unitTypeCode: stockObj[0].unitTypeCode,
            };
            recipesList.push(obj);
          } else {
          }
        } else {
          var obj = {
            quantity: Number(quantity),
            id: stockObj[0].id,
            name: stockObj[0].name,
            unitTypeCode: stockObj[0].unitTypeCode,
          };
          recipesList.push(obj);
        }
        setItemsList(recipesList);
        setQuantity("");
        sendDataObj = { ...sendDataObj, recipe: recipesList };
      }
    }
    setSendData(sendDataObj);
  };
  const handleSelectStock = (value) => {
    setName(value);
    if (getStocks) {
      const stockObj = getStocks.filter((e) => e.name == value);
      if (stockObj.length > 0) {
        setUnitValue(stockObj[0].unitTypeCode);
      }
    }
  };
  const handleSave = (value) => {
    dispatch(saveAddonRecipe(sendData, callback));
  };
  const handleRemoveStock = (recipeIndex) => {
    let sendDataObj = { ...sendData };
    if (sendDataObj && sendDataObj.recipe) {
      const recipesList = [...sendDataObj.recipe];
      if (recipesList && recipesList.length > 0) {
        recipesList.splice(recipeIndex, 1);
      } else {
      }
      setItemsList(recipesList);
      setQuantity("");
      sendDataObj = { ...sendDataObj, recipe: recipesList };
    }
    setSendData(sendDataObj);
  };
  const handleClearStockes = () => {
    let sendDataObj = { ...sendData };
    if (sendDataObj && sendDataObj.recipe) {
      setItemsList([]);
      setQuantity("");
      sendDataObj = { ...sendDataObj, recipe: [] };
    }
    setSendData(sendDataObj);
  };
  console.log("item list===>", itemsList);
  return (
    <RightDrawer
      data-qaid='addonRecipe_formula_right_drawer'
      open={open}
      padding={1}>
      <>
        <Grid
          data-qaid='addonRecipe_formula_parent_grid'
          style={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            width: 600,
            maxWidth: 600,
          }}>
          <Grid
            data-qaid='addonRecipe_formula_body_grid'
            style={{
              display: "flex",
              height: "85%",
              flexDirection: "column",
              overflowX: "auto",
              width: "100%",
            }}>
            <Grid
              data-qaid='addonRecipe_formula_close_grid'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}>
              <CircleClose
                data-qaid='addonRecipe_formula_closeicon'
                size={20}
                onClick={onClose}
                style={{ marginRight: 40, marginTop: 20 }}
              />
            </Grid>
            <Grid
              data-qaid='addonRecipe_formula_inventory_grid'
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 56,
                marginBottom: 10,
              }}>
              <InventoryIcon
                data-qaid='addonRecipe_formula_inventory_icon'
                style={{ marginRight: 15 }}
                width={22.5}
                height={24}
                color='#707272'
              />
              <H4
                data-qaid='addonRecipe_formula_inventory'
                label={Context.langData.inventory}
                style={{ color: "#707272", marginLeft: 10 }}
              />
            </Grid>
            <DotText
              data-qaid='addonRecipe_formula_title'
              style={{
                marginLeft: 56,
                marginBottom: 20,
                marginRight: 56,
                fontSize: 28,
                fontFamily: "AvenirLT-Black",
              }}
              label={itemName}
            />
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                paddingLeft: 56,
                paddingRight: 56,
              }}>
              <Grid
                data-qaid='addonRecipe_formula_searchLabelView'
                style={{
                  alignItems: "center",
                  width: "35%",
                  marginRight: "2%",
                }}>
                <AutoCompliteDropDown
                  options={getStocks}
                  onChange={handleSelectStock}
                  labelKey='name'
                  key={itemsList}
                  placeholder={Context.langData.serch_stock_items}
                />
              </Grid>
              <Grid
                data-qaid='addonRecipe_formula_quantityLabelView'
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "35%",
                  marginRight: "5%",
                  marginTop: 7,
                }}>
                <InputText
                  id='quantity'
                  height={60}
                  style={{ marginBottom: 6 }}
                  isPlaceHolder={false}
                  placeholder={Context.langData.quatity}
                  value={quantity}
                  onChange={(e) => {
                    setQuantity(e.target.value);
                  }}
                  size='small'
                  onKeyDown={blockInvalidChar}
                  type='number'
                />
              </Grid>
              <Grid
                data-qaid='addonRecipe_formula_unitsLabelView'
                item
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  display: "flex",
                  width: "20%",
                  marginRight: "5%",
                }}>
                <AvenirBlackH4
                  data-qaid='addonRecipe_formula_unitsLabel1'
                  label={unitValue}
                  style={{ marginRight: 20 }}
                />
                <Button
                  className={style.buttonClass}
                  data-qaid='addonRecipe_formula_saveLabelView'
                  style={{
                    backgroundColor: "#FFB600",
                    color: "white",
                    marginRight: 10,
                    width: "60px",
                  }}
                  variant='contained'
                  id='cancel'
                  type='submit'
                  onClick={() => {
                    handleAddItem();
                  }}>
                  <AvenirBlackH4
                    data-qaid='addonRecipe_formula_saveLabel'
                    label={Context.langData.add}
                  />
                </Button>
              </Grid>
            </Grid>
            <Grid
              data-qaid='addonRecipe_formula_itemListGrid'
              item
              style={{
                marginTop: "20px",
                marginLeft: 56,
                display: "flex",
                flexDirection: "column",
              }}>
              {itemsList
                ? itemsList.map((itemName, index) => (
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}>
                      <Grid
                        key={itemName.name}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          border: "1px solid #C3C3C3",
                          borderRadius: 5,
                          marginBottom: 10,
                          width: "65%",
                          height: 56,
                        }}>
                        <DotText
                          data-qaid='addonRecipe_formula_unitsLabel2'
                          label={itemName.name}
                          style={{
                            cursor: "pointer",
                            marginRight: 10,
                            marginLeft: 10,
                            width: "70%",
                          }}
                        />
                        <AvenirBlackH4
                          data-qaid='addonRecipe_formula_unitsLabel2'
                          label={`${itemName.quantity} ${itemName.unitTypeCode}`}
                          style={{
                            cursor: "pointer",
                            width: "30%",
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingRight: 10,
                          }}
                        />
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginLeft: 10,
                          alignItems: "center",
                        }}>
                        <Button
                          className={style.buttonClass}
                          data-qaid='addonRecipe_formula_clearButton'
                          style={{
                            backgroundColor: "#FFFFFF",
                            color: "white",
                            marginRight: 10,
                            width: "20%",
                          }}
                          variant='text'
                          id='cancel'
                          onClick={() => handleRemoveStock(index)}>
                          <DeleteIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  ))
                : null}
            </Grid>
          </Grid>
          <Grid
            style={{
              display: "flex",
              height: "15%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid
              data-qaid='addonRecipe_formula_colourLabelView'
              item
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#FAFAFA",
                width: "100%",
                paddingTop: "20px",
                alignItems: "center",
                paddingBottom: "20px",
                border: "1px solid #C3C3C3",
                paddingLeft: 20,
                marginTop: "10%",
                paddingRight: 20,
              }}>
              <Button
                className={style.buttonClass}
                data-qaid='addonRecipe_formula_colourButton'
                style={{ color: "white", marginRight: 10, width: "150px" }}
                variant='text'
                id='cancel'
                onClick={() => handleClearStockes()}>
                <AvenirBlackH4
                  data-qaid='addonRecipe_formula_colourLabel'
                  label={Context.langData.clr_formula}
                />
              </Button>
              <Grid>
                <Button
                  className={style.buttonClass}
                  data-qaid='addonRecipe_formula_cancelLabelView'
                  style={{
                    backgroundColor: "#FFF",
                    marginRight: 10,
                    width: 103,
                    height: 40,
                    border: "2px solid #051D33",
                  }}
                  variant='contained'
                  id='cancel'
                  onClick={onClose}>
                  <AvenirBlackH4
                    data-qaid='addonRecipe_formula_cancelLabel'
                    label={Context.langData.cancel}
                  />
                </Button>
                <Button
                  className={style.buttonClass}
                  data-qaid='addonRecipe_formula_saveLabelView'
                  style={{
                    backgroundColor: "#FFB600",
                    marginRight: 10,
                    width: 90,
                    height: 40,
                  }}
                  variant='contained'
                  id='cancel'
                  type='submit'
                  onClick={() => {
                    handleSave();
                  }}>
                  <AvenirBlackH4
                    data-qaid='addonRecipe_formula_saveLabel'
                    label={Context.langData.save}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    </RightDrawer>
  );
};

export default AddonRecipeFormula;
