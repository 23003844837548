import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  makeStyles,
  // Snackbar,
  Grid,
} from "@material-ui/core";
import MyContext from "../../Provider/MyContext";
import Page from "../../components/Page";
import { H4, AvenirBlackH4, H2 } from "../../utils/text";
import Circlecheck from "../../components/SVG/CircleCheck";
import styles from "../../styles/index";
import SnackBar from "../../components/SnackBar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F3FAFD",
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const ActivationFirstStep = () => {
  const style = styles();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const Context = useContext(MyContext);
  console.log(location);
  const props = location?.state?.message;
  useEffect(() => {
    if (props) {
      const d = { open: true, severity: "success", msg: props };
      setAlertData(d);
      setTimeout(() => {
        setAlertData({ open: false, severity: "success", msg: "" });
      }, 3000);
    }
  }, [props]);
  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 30000);
  }, []);
  const loginFailedCallback = () => {
    console.log("called");
    navigate("/");
  };
  return (
    <Page data-qaid='activation_page' className={classes.root}>
      <Box
        data-qaid='activation_box'
        display='flex'
        flexDirection='column'
        height='100%'
        justifyContent='center'>
        <Container
          data-qaid='activation_container'
          maxWidth='sm'
          style={{ backgroundColor: "#FFFFFF" }}>
          <Formik
            data-qaid='activation_formik'
            initialValues={{
              merchant_id: "",
              store_id: "",
            }}
            onSubmit={() => {
              console.log("called1");
              loginFailedCallback();
            }}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid
                  data-qaid='activation_formik_main_grid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 48,
                  }}>
                  <Circlecheck style={{ display: "flex", marginBottom: 16 }} />
                  <H2
                    label={Context.langData.final_activated_header}
                    style={{ color: "#051D33" }}
                  />
                  <H4
                    label={Context.langData.final_activation_body}
                    style={{
                      color: "#000000",
                      marginTop: 16,
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  />
                </Grid>
                <Box
                  data-qaid='activation_formik_action_box'
                  md={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 32,
                    marginBottom: 40,
                    marginLeft: 30,
                    marginRight: 30,
                  }}>
                  <Button
                    className={style.buttonClass}
                    data-qaid='activation_formik_login_button'
                    style={{ backgroundColor: "#FFB600" }}
                    type='submit'
                    variant='contained'>
                    <AvenirBlackH4
                      data-qaid='lactivation_formik_login_label'
                      style={{ color: "#051D33" }}
                      label={Context.langData.continue}
                    />
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
      <SnackBar
        data-qaid='loginView_formik_message'
        open={alertData.open}
        severity={alertData.severity}
        message={alertData.msg}
        handleClose={() => console.log()}
      />
    </Page>
  );
};

export default ActivationFirstStep;
