import React, { useEffect, useState, useContext } from "react";
import { Grid, Button, makeStyles, TextField } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import EventSeatIcon from "../../components/SVG/Floor";
import TextInputComponent from "../../components/TextInputComponent";
import SingleSelect from "../../components/SingleSelect";

import { createFloorPlan } from "../../redux/actions";
import Message from "../../components/Message";
import RightDrawer from "../../components/RightDrawer";
import MyContext from "../../Provider/MyContext";
import { H4, AvenirBlackH4 } from "../../utils/text";
import CloseCircle from "../../components/SVG/CircleClose";
import styles from "../../styles/index";
import { deviceDimensions } from "./builder/items";

const useStyles = makeStyles({
  mainView: {
    flexDirection: "column",
    justifyContent: "space-between",
    display: "flex",
    flex: 1,
  },
  settingsView: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
  },
  settingsText: {
    paddingLeft: "10px",
    color: "#000000",
    fontSize: "14px",
    fontFamily: "AvenirLT",
    fontWeight: 400,
  },
  floorplanEditText: {
    alignItems: "center",
    paddingTop: "10px",
  },
  floorplanTextInput: {
    fontSize: "24px",
    color: "black",
    fontFamily: "AvenirLT",
    fontWeight: "bold",
    "&::placeholder": {
      color: "#707272",
      fontWeight: "bold",
    },
  },
  ordertypeDropDownInput: {
    fontSize: "14px",
    color: "black",
    fontFamily: "AvenirLT",
    fontWeight: 400,
    "&::placeholder": {
      color: "#808285",
    },
  },
  cancelButton: {
    backgroundColor: "#00B6C9",
    color: "black",
    marginLeft: "10px",
  },
  saveButtonText: {
    fontFamily: "AvenirLT",
    fontWeight: 400,
    fontSize: "14px",
  },
  cancelButtonText: {
    color: "#000000",
    fontFamily: "AvenirLT",
    fontWeight: 400,
    fontSize: "14px",
  },
  descriptionText: {
    color: "#000000",
    fontSize: "14px",
    paddingTop: "5px",
    fontFamily: "AvenirLT",
    fontWeight: 400,
  },
  settingsMainview: {
    color: "#808285",
  },
  editDescriptionStyles: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "15px",
  },
  textAreaStyles: {
    borderWidth: 1,
    backgroundColor: "#FFFFFF",
    marginTop: "5px",
    "&::placeholder": {
      fontWeight: 300,
    },
    borderRadius: 4,
  },
  editorderStyles: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
  },
  editOrderText: {
    color: "#000000",
    fontSize: "14px",
    fontFamily: "AvenirLT",
    fontWeight: 400,
    paddingTop: "15px",
  },
  dropdownStyles: {
    height: "10px",
    marginTop: "10px",
    backgroundColor: "#FFFFFF",
  },
  inputWidth: {
    width: "40%",
  },
  bottomView: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
    padding: 20,
  },
});

const DrawerSettings = ({ onClose, callback }) => {
  const style = styles();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [inputText, setInputText] = useState("");
  const [selectedOrderType, setSelectedOrderType] = useState("");
  const [editDescription, setEditDescription] = useState(false);
  const [textArea, setTextArea] = useState("");
  const [editOrderType, setOrderType] = useState(false);
  const Context = useContext(MyContext);
  const [device, setDevice] = useState("ipad_pro_129");
  const [msg, setMsg] = useState({ open: false, msg: "", severity: "success" });
  const [btnDisable, setBtnDisable] = useState(true);
  const floorplanDetails = useSelector((state) => state.floorplan.floorplans);
  console.log(floorplanDetails, "floorplanDetailssss");

  const onHandleChange = (txtData) => {
    setInputText(txtData.target.value);
    // return txtData;
  };

  const createFloor = () => {
    let flag = true;
    // const floorName = capitalize(inputText)
    const floorName = inputText;
    if (floorplanDetails.length > 0) {
      floorplanDetails.map((item) => {
        if (item.name == floorName) {
          flag = false;
        }
      });
    }
    if (flag) {
      const obj = {
        name: floorName,
        description: textArea,
        device_name: device,
      };
      dispatch(
        createFloorPlan(obj, (inputText) => {
          callback(inputText);
        })
      );
    } else {
      DuplicateToast();
    }
  };

  const DuplicateToast = () => {
    showMessage(Context.langData.floorplan_duplicate_error, "warning");
    setTimeout(() => {}, 3000);
  };
  const btnProps = {};
  if (btnDisable) {
    btnProps.disabled = true;
  }
  useEffect(() => {
    if (inputText && textArea && device) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  }, [inputText, textArea, device]);

  const showMessage = (message, severity = "success") => {
    setMsg({ open: true, msg: message, severity });
    setTimeout(() => {
      setMsg({ open: false, msg: "", severity: "success" });
    }, 2000);
  };

  return (
    <RightDrawer data-qaid='assignkitchen_centermodal' open padding={1}>
      <>
        <Grid
          style={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            width: 500,
          }}>
          <Grid
            style={{
              display: "flex",
              height: "80%",
              flexDirection: "column",
              overflowX: "auto",
              paddingBottom: 10,
            }}>
            <Grid
              data-qaid='assignkitchen_close_grid'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}>
              <CloseCircle
                data-qaid='assignkitchen_closeicon'
                size={20}
                onClick={onClose}
                style={{ marginRight: 40, marginTop: 20 }}
              />
            </Grid>
            <Grid
              data-qaid='assignkitchen_settings_grid'
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 56,
                marginBottom: 10,
              }}>
              <EventSeatIcon
                data-qaid='assignkitchen_setting_icon'
                style={{ marginRight: 10 }}
                width={16}
                height={16}
                color='#808285'
              />
              <H4
                data-qaid='assignkitchen_settings'
                label={Context.langData.create_new_floorplan}
                style={{ color: "#707272" }}
              />
            </Grid>
            <Grid
              data-qaid='createtaxe_main_grid'
              style={{
                marginLeft: 56,
                marginRight: 56,
                maxWidth: 500,
                marginBottom: 20,
              }}>
              <Grid container className={classes.mainView}>
                <Grid>
                  <div className={classes.floorplanEditText}>
                    <TextInputComponent
                      value={inputText}
                      className='bottom_border_input'
                      onChange={onHandleChange}
                      maxLength={64}
                      placeholder={Context.langData.name_placeholder}
                      capitalize={false}
                      InputProps={{
                        classes: {
                          input: classes.floorplanTextInput,
                        },
                      }}
                    />
                  </div>

                  <div className={classes.editDescriptionStyles}>
                    <AvenirBlackH4
                      className={classes.descriptionText}
                      label={Context.langData.description}
                    />
                    <TextField
                      inputProps={{
                        maxlength: 300,
                        disableUnderline: true,
                      }}
                      variant='outlined'
                      rows={4}
                      placeholder={Context.langData.brief_desc}
                      multiline
                      value={textArea}
                      onChange={(e) => {
                        setTextArea(e.target.value);
                      }}
                      className={classes.textAreaStyles}
                    />
                    {/* <FormHelperText style={{ textAlign: 'left', color: "#707272", display: 'flex', alignItems: 'center' }}>
                                            <InfoOutlined width={12} height={12} style={{ marginRight: 5, marginTop: -5 }} color="#707272" />
                                            {textArea.length + "/300 characters used"}
                                        </FormHelperText> */}
                  </div>
                  <div className={classes.editorderStyles}>
                    <AvenirBlackH4
                      className={classes.editOrderText}
                      label={Context.langData.device_size}
                    />
                    <SingleSelect
                      list={deviceDimensions}
                      handleChange={(e) => {
                        setDevice(e.target.value);
                        setOrderType(false);
                      }}
                      value={device}
                      valueKey='id'
                      displayKey='device'
                      border={false}
                      className={classes.dropdownStyles}
                      height={56}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{
              zIndex: 9999,
              display: "flex",
              height: "20%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid
              data-qaid='createtaxe_action_grid'
              container
              style={{
                backgroundColor: "#FAFAFA",
                border: "1px solid #C3C3C3",
              }}>
              <Grid
                data-qaid='createtaxe_cancel_btn'
                item
                xs={12}
                lg={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 32,
                  marginBottom: 32,
                  alignItems: "center",
                  marginLeft: 56,
                  marginRight: 40,
                }}>
                <Button
                  variant='contained'
                  size='small'
                  className={style.buttonClass}
                  style={{
                    backgroundColor: "#FFF",
                    color: "black",
                    marginLeft: "10px",
                    height: 40,
                    width: 103,
                    border: "2px solid #051D33",
                  }}
                  onClick={onClose}>
                  <AvenirBlackH4
                    data-qaid='createtaxe_save_text'
                    label={Context.langData.cancel}
                  />
                </Button>
                <Button
                  {...btnProps}
                  className={style.buttonClass}
                  variant='contained'
                  size='small'
                  style={{
                    backgroundColor: btnDisable == true ? "#C3C3C3" : "#FFB600",
                    height: 40,
                    width: 103,
                    marginLeft: "10px",
                  }}
                  onClick={createFloor}>
                  <AvenirBlackH4
                    data-qaid='createtaxe_save_text'
                    label={Context.langData.create}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Message
          open={msg.open}
          severity={msg.severity}
          msg={msg.msg}
          handleClose={() => console.log()}
        />
      </>
    </RightDrawer>
  );
};

export default DrawerSettings;
