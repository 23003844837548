import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Box, Button, Container, makeStyles, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import MyContext from "../../Provider/MyContext";
import Page from "../../components/Page";
import { H4, AvenirBlackH4, H2 } from "../../utils/text";
import { verifyRest } from "../../redux/actions";
import Message from "../../components/Message";
import styles from "../../styles/index";
import InputText from "../../components/InputText";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F3FAFD",
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const ActivationFirstStep = () => {
  const style = styles();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const Context = useContext(MyContext);

  const closeMessage = () => {
    setTimeout(() => {
      setAlertData({ open: false, severity: "success", msg: "" });
    }, 3000);
  };
  return (
    <Page data-qaid='activation_page' className={classes.root}>
      <Box
        data-qaid='activation_box'
        display='flex'
        flexDirection='column'
        height='100%'
        justifyContent='center'>
        <Container
          data-qaid='activation_container'
          maxWidth='sm'
          style={{ backgroundColor: "#FFFFFF" }}>
          <Formik
            data-qaid='activation_formik'
            initialValues={{
              merchant_id: "",
              store_id: "",
            }}
            validationSchema={Yup.object().shape({
              merchant_id: Yup.string().required(
                Context.langData.merchant_error
              ),
              store_id: Yup.string()
                .max(255)
                .required(Context.langData.store_error),
            })}
            onSubmit={(values, actions) => {
              dispatch(
                verifyRest(
                  {
                    mmid: values.merchant_id,
                    store_id: values.store_id,
                  },
                  (status, response) => {
                    if (status) {
                      setAlertData({
                        open: true,
                        severity: "success",
                        msg: "Successfully verified",
                      });
                      navigate("/activateStep2", {
                        state: {
                          data: response.data,
                          storeData: {
                            mid: values.merchant_id,
                            store_id: values.store_id,
                          },
                        },
                      });
                    } else {
                      actions.setErrors({
                        merchant_id: "The entry isn't valid",
                        store_id: "The entry isn't valid",
                      });
                    }
                    // closeMessage()
                  }
                )
              );
            }}>
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setErrors,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid
                  data-qaid='activation_formik_main_grid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 48,
                  }}>
                  <H2
                    label={Context.langData.activation_step1}
                    style={{ color: "#051D33" }}
                  />
                  <H4
                    label={Context.langData.activation_sub_text}
                    style={{ color: "#000000", marginTop: 16 }}
                  />
                </Grid>
                <Grid
                  data-qaid='activation_formik_email_grid'
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    marginTop: 40,
                    marginLeft: 30,
                    marginRight: 30,
                  }}>
                  <AvenirBlackH4
                    data-qaid='lactivation_formik_eamillabel'
                    label={Context.langData.merchant_id}
                    style={{ color: "#051D33" }}>
                    {" "}
                  </AvenirBlackH4>
                  <InputText
                    data-qaid='activation_formik_email_input'
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: 4,
                      display: "flex",
                      marginTop: 8,
                    }}
                    error={touched.merchant_id && errors.merchant_id}
                    fullWidth
                    helperText={touched.merchant_id && errors.merchant_id}
                    margin='normal'
                    name='merchant_id'
                    InputProps={{
                      maxlength: 13,
                    }}
                    maxLength={13}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='text'
                    value={values.merchant_id}
                    variant='outlined'
                  />
                </Grid>
                <Grid
                  data-qaid='activation_formik_password_grid'
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    marginTop: 22,
                    marginLeft: 30,
                    marginRight: 30,
                  }}>
                  <AvenirBlackH4
                    data-qaid='activation_formik_password_label'
                    label={Context.langData.store_id}
                    style={{
                      color: "#051D33",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    {" "}
                  </AvenirBlackH4>
                  <InputText
                    data-qaid='activation_formik_password_input'
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: 4,
                      display: "flex",
                      marginTop: 8,
                    }}
                    error={touched.store_id && errors.store_id}
                    fullWidth
                    helperText={touched.store_id && errors.store_id}
                    margin='normal'
                    name='store_id'
                    maxLength={10}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='text'
                    value={values.store_id}
                    variant='outlined'
                  />
                </Grid>
                <Box
                  data-qaid='activation_formik_action_box'
                  md={2}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: 32,
                    marginBottom: 40,
                    marginLeft: 30,
                    marginRight: 30,
                  }}>
                  <Button
                    className={style.buttonClass}
                    data-qaid='activation_formik_login_button'
                    type='submit'
                    variant='contained'
                    style={{
                      width: 92,
                      height: 40,
                      backgroundColor: "#FFB600",
                      borderRadius: 5,
                    }}>
                    <AvenirBlackH4
                      data-qaid='lactivation_formik_login_label'
                      style={{ color: "#051D33" }}
                      label={Context.langData.next}
                    />
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
      <Message
        data-qaid='activation_formik_message'
        open={alertData.open}
        severity={alertData.severity}
        msg={alertData.msg}
        handleClose={() => console.log()}
      />
    </Page>
  );
};

export default ActivationFirstStep;
