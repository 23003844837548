import React, { useEffect, useState, useContext } from "react";
import { Grid, Button, Paper } from "@material-ui/core";
import { useNavigate, useLocation } from "react-router-dom";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import styles from "../../styles/floorplan";
import useStyles from "../../styles";
import FloorIcon from "../../components/SVG/Floor";
import { H4, H2, T, DotTextMedium } from "../../utils/text";
import { getFloorPlanBuilder, saveFloorPlanBuilder } from "../../redux/actions";
import Back from "../../components/SVG/Back";
import MyContext from "../../Provider/MyContext";
import SnackBar from "../../components/SnackBar";
import CustomButton from "../../components/CustomButton";
import CenterModal from "../../components/CenterModal";
import Confirm from "./builder/ConfirmUnsave";
import Preview from "./builder/Preview";
import { deviceDimensions } from "./builder/items";
import DargAndDrop from "./builder/DragAndDrop";

const CreateFloorPlan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showPreview, setShowPreview] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [showPopup, togglePopup] = useState(false);
  const [showConfirm, toggleConfirm] = useState(false);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const Context = useContext(MyContext);
  // const floorDetails = useSelector(state => state.floorplan.floorDetails)
  // const workingData = useSelector(state => state.floorplan.workingData)
  // const initWorkingData = useSelector(state => state.floorplan.initWorkingData)
  const {
    floorDetails,
    workingData,
    initWorkingData,
    currentSection,
    hasChanges,
  } = useSelector((state) => state.floorplan);
  const loginDetails = useSelector((state) => state.loginReducer.loginDetails);
  const autoSaveTime = loginDetails.auto_save_interval * 1500;
  const [tablesData, setTablesData] = useState({ tables: 0, seats: 0 });
  const style = styles();
  const classes = useStyles({ padding: 10, margin: 5 });
  useEffect(() => {
    const st = { ...state };
    console.log("state", st);
    const device = _.find(deviceDimensions, (d) => d.id == st.device_name);
    st.device = device;
    dispatch({ type: "SET_FLOOR", payload: st });
    dispatch(getFloorPlanBuilder({ floorplan_id: st.floorplan_id }));
  }, [state]);
  useEffect(() => {
    const tables = workingData.filter((w) => w.category === "Table");
    let seats = 0;
    tables.map((t) => {
      seats += t.seats;
    });
    setTablesData({ tables: tables.length, seats });
  }, [workingData]);
  const save = (isFromList) => {
    const st = { ...state };
    let err = "";
    if (currentSection != null) {
      let flag = "true";
      const data = [...workingData];
      console.log(data, "datafromfloorplan");
      let hasDupName = false;
      data.map((d1, i1) => {
        if (d1.name !== null)
          data.map((d2, i2) => {
            if (i1 !== i2 && d2.name !== null) {
              // console.log(d1, d2)
              if (d1.name.toLowerCase() === d2.name.toLowerCase()) {
                hasDupName = true;
                err = `${d1.name} exists more than one`;
              }
            }
          });
        else if (d1.category === "Table") {
          hasDupName = true;
          err = "Please enter a name for table";
        }
      });
      if (!hasDupName) {
        const tables = data.filter((d) => d.category == "Table");
        const objects = data.filter((d) => d.category !== "Table");
        const t = tables.map((tab) => {
          const { name, seats, table_id, object_id, ...table_config } = tab;
          return { name, seats, table_config, table_id, object_id };
        });
        const o = objects.map((tab) => {
          const { name, object_id, ...object_config } = tab;
          return { name, object_config, object_id };
        });
        const deleteConfig = [];
        initWorkingData.map((ind) => {
          const d = data.findIndex((da) => da.object_id === ind.object_id);
          if (d == -1) {
            deleteConfig.push(ind.object_id);
          }
        });
        const obj = {
          floorplan_id: floorDetails.floorplan_id,
          tables: t,
          objects: o,
          section_config: floorDetails.floor,
          deleted_objects: deleteConfig,
          name: currentSection.name,
          otype_id: currentSection.otype_id,
        };
        if (currentSection.section_id !== currentSection.name) {
          obj.section_id = currentSection?.section_id;
        }
        data.map((item) => {
          if (item.name.length == 0) {
            flag = false;
          }
        });

        if (flag) {
          setSaving(true);
          dispatch(
            saveFloorPlanBuilder(
              obj,
              (status) => callback(status, isFromList, st.floorplan_id),
              false
            )
          );
        } else {
          setAlertData({
            open: true,
            severity: "error",
            msg: Context.langData.floor_mandatory,
          });
          setTimeout(() => {
            setAlertData({ open: false, severity: "error", msg: "" });
          }, 3000);
        }
      } else {
        setAlertData({ open: true, severity: "error", msg: err });
        setTimeout(() => {
          setAlertData({ open: false, severity: "success", msg: "" });
        }, 3000);
      }
    } else {
      setTimeout(() => {
        setAlertData({
          open: true,
          severity: "error",
          msg: "Please create section",
        });
      }, 3000);
    }
    if (err == "")
      dispatch(getFloorPlanBuilder({ floorplan_id: st.floorplan_id }));
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      if (hasChanges) {
        save();
        clearTimeout(timer);
      }
    }, autoSaveTime);
    return () => clearTimeout(timer);
  }, [currentSection, workingData, floorDetails, hasChanges]);

  const callback = (status, isFromList, floorplan_id) => {
    dispatch(getFloorPlanBuilder({ floorplan_id }));
    if (isFromList !== true) {
      setTimeout(() => {
        setSaving(false);
        let d = { open: false, severity: "success", msg: "" };
        if (status == 1) {
          d = {
            open: true,
            severity: "success",
            msg: Context.langData.floor_plan_succ_message,
          };
        } else {
          d = {
            open: true,
            severity: "error",
            msg: Context.langData.error_floorplan,
          };
        }
        setAlertData(d);
        setTimeout(() => {
          setAlertData({ open: false, severity: "success", msg: "" });
        }, 3000);
      }, 2000);
    }
  };
  const onError = (msg, flag) => {
    if (flag) {
      togglePopup(true);
    } else {
      setAlertData({ open: true, severity: "error", msg });
      setTimeout(() => {
        setAlertData({ open: false, severity: "error", msg: "" });
      }, 3000);
    }
  };
  const onBack = () => {
    console.log("ON BACK", hasChanges);
    if (hasChanges) {
      console.log("Need to show modal");
      toggleConfirm(true);
    } else {
      navigate("/home/floorplan");
    }
  };
  const onConfirm = (isConfirmed) => {
    toggleConfirm(false);
    if (isConfirmed) {
      navigate("/home/floorplan");
    }
  };
  return (
    <Grid container id='floor_builder'>
      <Grid item xs={12}>
        <Grid container className={style.topBar}>
          <Grid md={1} sm={3} item>
            <Button onClick={onBack} startIcon={<Back />}>
              {/* <Typography variant="h4" className={clsx(classes.fw500, classes.black)}> {Context.langData.back}</Typography> */}
            </Button>
          </Grid>
          <Grid md={8} sm={9} item>
            <Grid container alignItems='center'>
              <FloorIcon color='black' width={20} height={20} />
              {/* <AvenirBlackH2 label={floorDetails.name} style={{ paddingLeft: 5 }} /> */}
              <DotTextMedium
                label={floorDetails.name}
                style={{ width: "30%", paddingLeft: 5, fontSize: 18 }}
              />
              {/* <Typography variant="h3" className={clsx(classes.fw600, classes.black)}>&nbsp;{floorDetails.name}</Typography> */}
              <Paper
                style={{
                  border: "1px solid",
                  borderColor: "#C3C3C3",
                  marginLeft: 40,
                  borderRadius: 5,
                }}
                className={clsx(classes.padding, classes.marginHorizantal)}>
                <H4 label={`Sections${floorDetails.number_of_sections}`} />
              </Paper>
              <Paper
                style={{
                  border: "1px solid",
                  borderColor: "#C3C3C3",
                  marginLeft: 5,
                  borderRadius: 5,
                }}
                className={clsx(classes.padding, classes.marginHorizantal)}>
                <H4 label={Context.langData.tables + tablesData.tables} />
              </Paper>
              <Paper
                style={{
                  border: "1px solid",
                  borderColor: "#C3C3C3",
                  marginLeft: 5,
                  borderRadius: 5,
                }}
                className={clsx(classes.padding, classes.marginHorizantal)}>
                <H4 label={Context.langData.seats + tablesData.seats} />
              </Paper>
              <Grid
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  display: "flex",
                  marginLeft: 30,
                  width: "30%",
                }}>
                <H4 label='Selected section:' style={{ marginRight: 5 }} />
                <DotTextMedium
                  label={currentSection?.name}
                  style={{ width: "30%", fontSize: 14 }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            md={3}
            sm={12}
            item
            style={{ justifyContent: "flex-end", display: "flex" }}>
            {/* <Grid container justify="space-between"> */}
            {/* <Button startIcon={<SettingsOutlinedIcon color='#004C60'/>}>
                <H4 label={Context.langData.settings} />
              </Button> */}
            <Grid style={{ flexDirection: "row" }}>
              <Button
                variant='contained'
                style={{
                  backgroundColor: "#00B6C9",
                  height: 40,
                  width: 109,
                  marginRight: 20,
                }}
                onClick={() => setShowPreview(true)}>
                <H4 label={Context.langData.preview} />
              </Button>
              <Button
                variant='contained'
                style={{ backgroundColor: "#FFB600", height: 40, width: 109 }}
                onClick={save}
                disabled={floorDetails.floorplan_id === -1}>
                <H4 label={Context.langData.save} />
              </Button>
            </Grid>
            {/* </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DargAndDrop
            onError={onError}
            saveOnEdit={save}
            hasChanges={hasChanges}
          />
        </Grid>
      </Grid>
      {showPreview && (
        <Preview open={showPreview} handleClose={() => setShowPreview(false)} />
      )}
      {/* <Backdrop className={classes.backdrop} open={isSaving} onClick={() => console.log()}>
        <ThreeDots
          color="gray"
          height={50}
          width={50}
        />
      </Backdrop> */}
      {showPopup && (
        <CenterModal
          open={showPopup}
          onClose={() => togglePopup(false)}
          borderRadius={4}>
          <Grid
            container
            style={{ padding: 24, maxWidth: 400, borderRadius: 5 }}>
            <Grid item xs={12}>
              <H2 label='Please create section' />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 24 }}>
              <T label='Please create at least 1 section (under Floor plan overview) before adding elements.' />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 24 }}>
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <CustomButton
                  label='Confirm'
                  width={150}
                  onClick={() => togglePopup(false)}
                />
              </Grid>
            </Grid>
          </Grid>
        </CenterModal>
      )}
      {alertData && (
        <SnackBar
          data-qaid='taxeslist_snackbar'
          open={alertData.open}
          setOpen={() => setAlertData(null)}
          severity={alertData.severity}
          message={alertData.msg}
        />
      )}
      <CenterModal open={showConfirm}>
        <Confirm callback={onConfirm} />
      </CenterModal>
    </Grid>
  );
};
export default CreateFloorPlan;
