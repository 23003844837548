import React, { useEffect, useState, useContext } from "react";
import {
  Grid,
  makeStyles,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  saveEmployee,
  fetchRoles,
  fetchEmployeeCheck,
} from "../../../redux/actions";
import TextInputComponent from "../../../components/TextInputComponent";
import ProvinceData from "../customers/province";

import InputMaskComponent from "../../../components/TextInputMask";
import SingleSelect from "../../../components/SingleSelect";
import InfoOutlinedIcon from "../../../components/SVG/InfoOutlined";
import MyContext from "../../../Provider/MyContext";
import {
  AvenirBlackH4,
  AvenirBlackH7,
  AvenirBlackH2,
  AvenirBlackH3,
  H5,
} from "../../../utils/text";
import Back from "../../../components/SVG/Back";
import styles from "../../../styles/index";
import SnackBar from "../../../components/SnackBar";
import SingleDate from "../../../components/SingleDate";
import WarningIcon from "../../../components/SVG/WarningIcon";
import {
  validateText,
  validateAlphaNumeric,
  validateNumber,
} from "../../../utils/util";

const useStyles = makeStyles((theme) => ({
  newCustomerHeadingView: {
    marginTop: "20px",
    marginLeft: "20px",
    marginBottom: "20px",
  },
  newCustomerText: {
    fontWeight: "bold",
    fontSize: 24,
  },
  saveBtn: {
    marginRight: "20px",
    backgroundColor: "#FFB600",
    color: "white",
    height: "40px",
    width: "103px",
    "&:hover": {
      backgroundColor: "#FFB600",
    },
  },
  cancelBtn: {
    marginLeft: "20px",
    backgroundColor: "#707272",
    color: "white",
    fontWeight: "bold",
  },
  contactInfoMainView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "1%",
    marginLeft: "1%",
    marginRight: "1%",
  },
  hrStyles: {
    marginTop: "1%",
    marginLeft: " 1%",
    marginRight: "1%",
    border: "1px solid #EEE",
  },
  checkboxMainView: {
    display: "flex",
    alignItems: "center",
    paddingTop: "10px",
  },
  additionalInfoCardView: {
    marginTop: "20px",
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "20px",
  },
  additionalInfoText: {
    display: "flex",
    marginTop: "1%",
    marginLeft: "1%",
    marginRight: "1%",
  },
  root: {},
  title: {
    fontSize: "18px",
  },
  input: {
    color: "black !important",
    fontSize: 14,
    backgroundColor: "#FFF",
    borderRadius: 4,
  },
  notchedOutline: {
    border: "1px solid #707272 !important",
  },
  labelRoot: {
    // fontSize:6
  },
  formControl: {
    minWidth: "100%",
    backgroundColor: "#FFF !important",
    border: "#707272",
    borderRadius: 4,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  customWidth: {
    maxWidth: 700,
  },
}));

const CreateEmployee = () => {
  const style = styles();
  const Roles = useSelector((state) => state.profileReducers.roles);
  const [snackbar, setSnackbar] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [province, setProvince] = useState("");
  const [status, setStatus] = useState(1);
  const [role, setRole] = useState("");
  const [roleId, setRoleId] = useState("0");
  const [selectedRoleType, setSelectedRoleType] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [currentDate, setCurrentDate] = useState("");
  const Context = useContext(MyContext);
  const [emailExists, setEmailExists] = useState(false);
  const [lang, setLang] = useState(0);
  const [uNameErr, setuNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const languageType = [
    { name: "English", value: 0 },
    { name: "French", value: 1 },
  ];

  const WarningText = (
    <Grid
      style={{
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        paddingTop: 5,
      }}>
      <WarningIcon color='#AB1212' height={16} width={16} />
      <H5
        label='Employee email already exists'
        style={{ color: "#AB1212", paddingLeft: 5 }}
      />
    </Grid>
  );

  useEffect(() => {
    setCurrentDate(moment().format("YYYY-MM-DD"));
    dispatch(fetchRoles());
  }, []);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleRoleChange = (val) => {
    const selected = Roles.roles.filter((role) => role.id === val.target.value);
    const roleLength = [];
    let role = null;
    if (selected.length) {
      role = selected[0];
      const roleApplications = role.role_application.split(",");
      if (roleApplications && roleApplications.length > 0) {
        roleApplications.map((namespace) => {
          if (namespace == "Portal") {
            roleLength.push("portal");
          } else if (roleLength.indexOf("app") == -1) {
            roleLength.push("app");
          }
        });
      }
    }
    let selectedRoleType = 0;
    if (
      roleLength.indexOf("app") !== -1 &&
      roleLength.indexOf("portal") !== -1
    ) {
      selectedRoleType = 1;
    } else if (roleLength.indexOf("app") !== -1) {
      selectedRoleType = 3;
    } else if (roleLength.indexOf("portal") !== -1) {
      selectedRoleType = 2;
    }
    setRole(val.target.value);
    setRoleId(val.target.value);
    setSelectedRoleType(selectedRoleType);
    setSelectedRole(role);
    if (selectedRoleType == 1 || selectedRoleType == 2) {
      setShowEmail(true);
    } else {
      setShowEmail(false);
    }
  };
  const handleCancel = () => {
    navigate("/home/profile", { state: { tab: 0, emp: true } });
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const validateAddress = (address) => {
    if (selectedRoleType == 3 && address.values.userName == "") {
      setuNameErr(true);
      setEmailErr(false);
      return "null";
    }
    if (selectedRoleType == 1) {
      if (address.values.email == "") {
        setEmailErr(true);
        return "null";
      }
      if (address.values.userName == "") {
        setuNameErr(true);
        return "null";
      }
    } else if (selectedRoleType == 2 && address.values.email == "") {
      setEmailErr(true);
      return "null";
    } else {
      setuNameErr(false);
      setEmailErr(false);
    }
    const arr = ["streetAddress", "city", "postalCode"];
    const temp = [];
    arr.map((key) => {
      if (address.values[key] == "" || address.values[key] == null) {
        temp.push(key);
      }
    });
    console.log("array length", arr.length, "temp array", temp.length);
    if (temp.length === arr.length || temp.length === 0) {
      return null;
    }
    return "Address Incomplete";
  };
  const btnProps = {};
  if (btnDisable) {
    btnProps.disabled = true;
  }

  const validateSchema = () => {
    const validate = Yup.object().shape({
      firstName: Yup.string()
        .max(255)
        .required(Context.langData.first_name_req_error),
      lastName: Yup.string()
        .max(255)
        .required(Context.langData.last_name_req_error),
      email: Yup.string().email(Context.langData.email_valid_error),
      // email: Yup.string().email(Context.langData.email_valid_error).max(255).required(Context.langData.email_required_error),
    });
    return validate;
  };

  const statusData = [
    { name: "Inactive", value: 0 },
    { name: "Active", value: 1 },
  ];

  const onBlurEmail = (e, setFieldValue) => {
    if (e.target.value != "") {
      setEmailErr(false);
      // if(e.target.value != ''){
      //     setEmailErr(false)
      // }
      dispatch(
        fetchEmployeeCheck({ email: e.target.value }, (status, data) => {
          if (status) {
            if (data.exist) {
              setEmailExists(true);
            } else {
              setEmailExists(false);
            }
          } else {
            console.log("Email already exists");
          }
        })
      );
      setFieldValue("contactEmail", e.target.value);
    } else {
      setEmailExists(false);
    }
  };

  const onChangeUName = (e) => {
    if (e.target.value !== "") {
      setuNameErr(false);
    }
  };

  const onChangeEmail = (e) => {
    if (e.target.value !== "") {
      setEmailErr(false);
    }
  };

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        userName: "",
        tempPin: "",
        phoneNumber: "",
        contactEmail: "",
        customerId: "",
        birthday: "",
        assignedEmployeeId: "",
        streetAddress: "",
        unitNumber: "",
        city: "",
        province: "",
        postalCode: "",
        notes: "",
      }}
      validationSchema={validateSchema}
      onSubmit={(values) => {
        const valid = validateAddress({ values });
        if (valid) {
          if (valid !== "null")
            setSnackbar({ message: valid, severity: "error" });
        } else {
          dispatch(
            saveEmployee(
              {
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                phone_number:
                  values.phoneNumber == null
                    ? null
                    : values.phoneNumber.replace(/-/g, ""),
                contact_email: values.contactEmail,
                enable_marketing: 0,
                assigned_employee_id: values.assignedEmployeeId,
                enable_save_contact_btn: true,
                enable_save_add_btn: false,
                birthday:
                  values.birthday == ""
                    ? null
                    : moment(values.birthday).format("MM/DD/YYYY"),
                street_address: values.streetAddress,
                unit_number: values.unitNumber,
                city: values.city,
                province: province == "Select" ? null : province,
                postal_code:
                  values.postalCode == null
                    ? null
                    : values.postalCode.replace(/ /g, ""),
                notes: values.notes,
                temp_pin: values.tempPin,
                username: values.userName,
                email: values.email,
                role_id: roleId,
                status,
                language: lang == 0 ? "English" : "French",
              },
              (status, data) => {
                if (status) {
                  navigate("/home/profile", {
                    state: {
                      tab: 0,
                      emp: true,
                      createCustomer: status,
                      message: Context.langData.employee_created_successfully,
                      ts: moment().unix(),
                    },
                  });
                } else {
                  roleId == 0
                    ? setSnackbar({
                        message: "Please select role",
                        severity: "error",
                      })
                    : setSnackbar({
                        message: data.message,
                        severity: "error",
                      });
                }
              }
            )
          );
        }
      }}>
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldError,
        isSubmitting,
        touched,
        setErrors,
        values,
        isValid,
        dirty,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid
            container
            data-qaid='createEmployee_MainContainer'
            style={{ backgroundColor: "#EEE" }}>
            <Grid
              container
              data-qaid='createEmployee_topMainContainer'
              spacing={2}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                background: "#FFF",
                paddingTop: "25px",
                paddingBottom: "25px",
              }}>
              <Grid
                item
                data-qaid='createEmployee_backView'
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  display: "flex",
                  marginLeft: 30,
                }}>
                <Back
                  data-qaid='createEmployee_backIcon'
                  onClick={() => handleCancel()}
                  style={{ cursor: "pointer" }}
                />
                <AvenirBlackH7
                  data-qaid='createEmployee_backLabel'
                  label={Context.langData.new_employee}
                  style={{ fontWeight: "bold", paddingLeft: 20 }}
                />
              </Grid>
              <Grid item data-qaid='createEmployee_cancel_save_view'>
                <Button
                  data-qaid='createEmployee_cancelView'
                  className={style.buttonClass}
                  style={{
                    backgroundColor: "#FFF",
                    marginRight: 10,
                    width: 103,
                    height: 40,
                    border: "2px solid #051D33",
                  }}
                  variant='contained'
                  id='cancel'
                  onClick={() => handleCancel()}>
                  <AvenirBlackH4
                    data-qaid='createEmployee_cancelLabel'
                    label={Context.langData.cancel}
                  />
                </Button>
                <Button
                  data-qaid='createEmployee_saveView'
                  className={[style.buttonClass, classes.saveBtn]}
                  variant='contained'
                  id='savebtn'
                  type='submit'
                  disabled={!(dirty && isValid)}>
                  <AvenirBlackH4
                    data-qaid='createEmployee_saveLabel'
                    label={Context.langData.save}
                  />
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              data-qaid='createEmployee_formMainView'
              lg={12}
              md={12}
              sm={12}
              style={{ overflow: "scroll", marginTop: 30 }}>
              <div
                data-qaid='createEmployee_formDiv'
                style={{ marginLeft: "20px", marginRight: "20px" }}>
                <Card
                  data-qaid='createEmployee_formCardView'
                  className={classes.root}>
                  <CardContent>
                    <Grid
                      data-qaid='createEmployee_accountInfoView'
                      className={classes.contactInfoMainView}>
                      <AvenirBlackH2
                        data-qaid='createEmployee_accountInfoLabel'
                        label={Context.langData.acc_information}
                      />
                    </Grid>
                    <hr
                      data-qaid='createEmployee_hr_view'
                      className={classes.hrStyles}
                    />
                    <Grid
                      container
                      data-qaid='createEmployee_fName_lName_View'
                      spacing={4}
                      style={{
                        paddingTop: "20px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}>
                      <Grid
                        item
                        data-qaid='createEmployee_fNameGrid'
                        xs={12}
                        sm={6}
                        md={6}
                        lg={5}>
                        <AvenirBlackH4
                          item
                          data-qaid='createEmployee_fNameLabel'
                          label={Context.langData.first_name}
                          style={{ marginBottom: 5 }}
                        />
                        <TextInputComponent
                          value={values.firstName}
                          onBlur={handleBlur}
                          onChange={(val) =>
                            setFieldValue(
                              "firstName",
                              validateText(val.target.value)
                            )
                          }
                          fullWidth
                          error={Boolean(touched.firstName && errors.firstName)}
                          helperText={touched.firstName && errors.firstName}
                          id='firstName'
                          name='firstName'
                          maxLength={128}
                          variant='outlined'
                          style={{ width: "100%" }}
                          InputProps={{
                            classes: {
                              root: classes.labelRoot,
                              notchedOutline: classes.notchedOutline,
                              input: classes.input,
                            },
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        data-qaid='createEmployee_lNameGrid'
                        xs={12}
                        sm={6}
                        md={6}
                        lg={5}>
                        <AvenirBlackH4
                          data-qaid='createEmployee_lNameLabel'
                          label={Context.langData.last_name}
                          style={{ marginBottom: 5 }}
                        />
                        <TextInputComponent
                          value={values.lastName}
                          onBlur={handleBlur}
                          onChange={(val) =>
                            setFieldValue(
                              "lastName",
                              validateText(val.target.value)
                            )
                          }
                          fullWidth
                          maxLength={128}
                          error={Boolean(touched.lastName && errors.lastName)}
                          helperText={touched.lastName && errors.lastName}
                          id='lastName'
                          variant='outlined'
                          style={{ width: "100%" }}
                          InputProps={{
                            classes: {
                              root: classes.labelRoot,
                              notchedOutline: classes.notchedOutline,
                              input: classes.input,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      data-qaid='createEmployee_role_status_grid'
                      spacing={4}
                      style={{
                        paddingTop: "10px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}>
                      <Grid
                        item
                        data-qaid='createEmployee_roleGrid'
                        xs={12}
                        sm={6}
                        md={6}
                        lg={5}>
                        <AvenirBlackH4
                          data-qaid='createEmployee_roleLabel'
                          label={Context.langData.role}
                          style={{ marginBottom: 5 }}
                        />
                        <SingleSelect
                          list={[{ name: "Select", role_id: 0 }].concat(
                            Roles.roles
                          )}
                          valueKey='role_id'
                          displayKey='name'
                          value={roleId}
                          handleChange={handleRoleChange}
                          border={false}
                          height={54}
                          FormWidth='100%'
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid
                        item
                        data-qaid='createEmployee_statusGrid'
                        xs={12}
                        sm={6}
                        md={6}
                        lg={5}
                        style={{
                          justifyContent: "space-between",
                          flexDirection: "row",
                          display: "flex",
                        }}>
                        <Grid style={{ width: "60%" }}>
                          <AvenirBlackH4
                            data-qaid='createEmployee_statusLabel'
                            label={Context.langData.status}
                            style={{ marginBottom: 5 }}
                          />
                          <SingleSelect
                            list={statusData}
                            valueKey='value'
                            displayKey='name'
                            value={status}
                            handleChange={handleStatusChange}
                            border={false}
                            height={54}
                            FormWidth='100%'
                          />
                        </Grid>
                        <Grid style={{ width: "35%" }}>
                          <AvenirBlackH4
                            data-qaid='newCustomers_provinceLabel'
                            label={`${Context.langData.language}*`}
                            style={{ marginBottom: 5 }}
                          />
                          <SingleSelect
                            list={languageType}
                            valueKey='value'
                            displayKey='name'
                            value={lang}
                            handleChange={(e) => setLang(e.target.value)}
                            border={false}
                            height={54}
                            FormWidth='100%'
                            borderStyle='1px solid #707272'
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {(selectedRoleType == 1 || selectedRoleType == 2) && (
                      <Grid
                        container
                        data-qaid='createEmployee_emailMainContainer'
                        spacing={4}
                        style={{
                          paddingTop: "20px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}>
                        <Grid
                          item
                          data-qaid='createEmployee_emailSubContainer'
                          xs={12}
                          sm={12}
                          md={12}
                          lg={10}>
                          <AvenirBlackH3
                            data-qaid='createEmployee_portal_credentials_label'
                            label={Context.langData.portal_credentials}
                            style={{ marginBottom: 20 }}
                          />
                          <AvenirBlackH4
                            data-qaid='createEmployee_email_header_star_label'
                            label={Context.langData.email_header_star}
                            style={{ marginBottom: 5 }}
                          />
                          <TextInputComponent
                            value={values.email}
                            onBlur={(e) => {
                              handleBlur(e);
                              onBlurEmail(e, setFieldValue);
                            }}
                            onChange={(e) => {
                              handleChange(e);
                              onChangeEmail(e, setFieldValue);
                            }}
                            // setFieldValue('email', val.target.value.trim().replace(/\s/g, ''));
                            fullWidth
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                            id='email'
                            maxLength={64}
                            variant='outlined'
                            errorBorder={
                              (emailExists && !errors.email) || emailErr
                                ? "1px solid #AB1212"
                                : null
                            }
                            style={{ width: "100%" }}
                            InputProps={{
                              classes: {
                                root: classes.labelRoot,
                                notchedOutline: classes.notchedOutline,
                                input: classes.input,
                              },
                            }}
                          />
                          {emailErr ? (
                            <H5
                              label='Email is required'
                              style={{ color: "#AB1212", paddingLeft: 10 }}
                            />
                          ) : (
                            ""
                          )}
                          {emailExists && !errors.email && (
                            <H5
                              label={emailExists ? WarningText : ""}
                              style={{ color: "#AB1212", paddingTop: 5 }}
                            />
                          )}
                          <Grid
                            item
                            data-qaid='createEmployee_infoIconView1'
                            style={{
                              marginTop: 10,
                              display: "flex",
                              flexDirection: "row",
                            }}>
                            <InfoOutlinedIcon
                              data-qaid='createEmployee_infoIcon1'
                              style={{
                                height: 16,
                                width: 16,
                                marginRight: 5,
                              }}
                            />
                            <H5
                              data-qaid='createEmployee_infoIconLabel1'
                              label={
                                Context.langData
                                  .an_email_address_is_required_for_roles
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {(selectedRoleType == 1 || selectedRoleType == 3) && (
                      <>
                        <AvenirBlackH3
                          data-qaid='createEmployee_appCredentialsLabel'
                          label={Context.langData.app_credentials}
                          style={{ paddingTop: 20, paddingLeft: 10 }}
                        />
                        <Grid
                          container
                          data-qaid='createEmployee_userName_temp_View'
                          spacing={4}
                          style={{
                            paddingTop: "20px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}>
                          <Grid
                            item
                            data-qaid='createEmployee_userName_View'
                            xs={12}
                            sm={6}
                            md={6}
                            lg={5}>
                            <AvenirBlackH4
                              data-qaid='createEmployee_userName_label'
                              label={Context.langData.user_name}
                              style={{ marginBottom: 5 }}
                            />
                            <TextInputComponent
                              value={values.userName}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handleChange(e);
                                onChangeUName(
                                  e,
                                  setFieldValue(
                                    "userName",
                                    validateAlphaNumeric(e.target.value)
                                  )
                                );
                              }}
                              maxLength={16}
                              fullWidth
                              id='userName'
                              name='userName'
                              variant='outlined'
                              errorBorder={
                                uNameErr ? "1px solid #AB1212" : null
                              }
                              style={{ width: "100%" }}
                              InputProps={{
                                classes: {
                                  root: classes.labelRoot,
                                  notchedOutline: classes.notchedOutline,
                                  input: classes.input,
                                },
                              }}
                            />
                            {uNameErr ? (
                              <H5
                                label='Username is required'
                                style={{ color: "#AB1212", paddingLeft: 10 }}
                              />
                            ) : (
                              ""
                            )}
                            <Grid
                              item
                              data-qaid='createEmployee_infoIcon_View2'
                              style={{
                                marginTop: 10,
                                display: "flex",
                                flexDirection: "row",
                              }}>
                              <InfoOutlinedIcon
                                data-qaid='createEmployee_infoIcon2'
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 5,
                                }}
                              />
                              <H5
                                data-qaid='createEmployee_infoIcon_label2'
                                label={
                                  Context.langData
                                    .a_username_is_required_for_roles
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            data-qaid='createEmployee_tempPin_View'
                            xs={12}
                            sm={6}
                            md={6}
                            lg={5}>
                            {/* <label>
                                                            Temporary PIN
                                                            <Tooltip title={TmpPintoolTip} placement="right-start">
                                                                <IconButton style={{ padding: 0, marginLeft: 5, marginBottom: 10 }} aria-label="delete">
                                                                    <InfoOutlinedIcon width={10} height={10}></InfoOutlinedIcon>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </label> */}
                            <AvenirBlackH4
                              data-qaid='createEmployee_tempPin_label'
                              label={`${Context.langData.pin}*`}
                              style={{ marginBottom: 5 }}
                            />
                            <InputMaskComponent
                              value={values.tempPin}
                              regex='pin'
                              maxLength={6}
                              isFormik
                              handleChange={(val) =>
                                setFieldValue("tempPin", val)
                              }
                              error={errors.tempPin}
                              onBlur={(value) => {
                                if (value) {
                                  setFieldError("tempPin", value);
                                } else {
                                  const err = { ...errors };
                                  delete err.tempPin;
                                  setErrors(err);
                                }
                              }}
                              id='tempPin'
                              type={showPassword ? "text" : "password"}
                              placeholder='XXXXXX'
                              endAdornment={
                                <InputAdornment position='end'>
                                  <IconButton
                                    className={style.buttonClass}
                                    aria-label='toggle password visibility'
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge='end'>
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                            <Grid
                              item
                              data-qaid='createEmployee_infoIcon_view3'
                              style={{
                                marginTop: 5,
                                display: "flex",
                                flexDirection: "row",
                              }}>
                              <InfoOutlinedIcon
                                data-qaid='createEmployee_infoIcon3'
                                style={{
                                  height: 16,
                                  width: 16,
                                  marginRight: 5,
                                }}
                              />
                              <H5
                                data-qaid='createEmployee_infoIcon_label3'
                                label={
                                  Context.langData
                                    .a_unique_6_digit_identifier_to_sign_in_to_the_app
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </CardContent>
                </Card>
              </div>

              <div
                data-qaid='createEmployee_additionalInfoCardDiv'
                className={classes.additionalInfoCardView}>
                <Card
                  data-qaid='createEmployee_additionalInfoCardView'
                  className={classes.root}>
                  <CardContent>
                    <AvenirBlackH2
                      data-qaid='createEmployee_additional_info_label'
                      label={Context.langData.additional_info}
                      style={{ paddingLeft: 10, paddingTop: 10 }}
                    />
                    <hr
                      data-qaid='createEmployee_additional_info_hr'
                      className={classes.hrStyles}
                    />
                    <Grid
                      container
                      data-qaid='createEmployee_employeeID_Phonenumber_view'
                      spacing={2}
                      style={{
                        paddingTop: "20px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}>
                      <Grid
                        item
                        data-qaid='createEmployee_employeeID_view'
                        xs={12}
                        sm={4}
                        md={4}
                        lg={4}>
                        <AvenirBlackH4
                          data-qaid='createEmployee_employeeID_label'
                          label={Context.langData.emp_id}
                          style={{ marginBottom: 5 }}
                        />
                        <TextInputComponent
                          value={values.assignedEmployeeId}
                          onBlur={handleBlur}
                          onChange={(val) =>
                            setFieldValue(
                              "assignedEmployeeId",
                              validateAlphaNumeric(val.target.value)
                            )
                          }
                          maxLength={32}
                          id='assignedEmployeeId'
                          variant='outlined'
                          style={{ width: "100%" }}
                          InputProps={{
                            classes: {
                              root: classes.labelRoot,
                              notchedOutline: classes.notchedOutline,
                              input: classes.input,
                            },
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        data-qaid='createEmployee_birthdayView'
                        xs={12}
                        sm={4}
                        md={4}
                        lg={3}>
                        <AvenirBlackH4
                          data-qaid='createEmployee_birthdayLable'
                          label={Context.langData.birthday}
                          style={{ marginBottom: 5 }}
                        />
                        <Grid item data-qaid='createEmployee_DOBPicker_view'>
                          {/* <DOBPicker
                                                            height={54}
                                                            width={276}
                                                            border={false}
                                                            callback={(val) => setFieldValue('birthday', val)}
                                                        /> */}
                          <SingleDate
                            value={values.birthday}
                            height={54}
                            maxDate={new Date()}
                            border={false}
                            callback={(val) => setFieldValue("birthday", val)}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        data-qaid='createEmployee_phoneNumber_view'
                        xs={12}
                        sm={4}
                        md={4}
                        lg={3}>
                        <AvenirBlackH4
                          data-qaid='createEmployee_phoneNumber_label'
                          label={Context.langData.phone_number}
                          style={{ marginBottom: 5 }}
                        />
                        <InputMaskComponent
                          value={values.phoneNumber}
                          regex='phone'
                          isFormik
                          error={errors.phoneNumber}
                          onBlur={(value) => {
                            if (value) {
                              setFieldError("phoneNumber", value);
                            } else {
                              const err = { ...errors };
                              delete err.phoneNumber;
                              setErrors(err);
                            }
                          }}
                          handleChange={(val) =>
                            setFieldValue("phoneNumber", val)
                          }
                          helperText={touched.phoneNumber && errors.phoneNumber}
                          id='phoneNumber'
                          // placeholder="X-XXX-XXX-XXXX"
                        />
                      </Grid>
                      <Grid
                        item
                        data-qaid='createEmployee_contactEmail_view'
                        xs={12}
                        sm={4}
                        md={4}
                        lg={3}>
                        <AvenirBlackH4
                          data-qaid='createEmployee_contactEmail_label'
                          label={Context.langData.contact_email}
                          style={{ marginBottom: 5 }}
                        />
                        <TextInputComponent
                          value={values.contactEmail}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          maxLength={32}
                          disabled={selectedRoleType != 3}
                          id='contactEmail'
                          variant='outlined'
                          style={{ width: "100%" }}
                          InputProps={{
                            classes: {
                              root: classes.labelRoot,
                              notchedOutline: classes.notchedOutline,
                              input: classes.input,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      data-qaid='createEmployee_unitNumber_streetAddress_view'
                      spacing={2}
                      style={{
                        paddingTop: "20px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}>
                      <Grid
                        item
                        data-qaid='createEmployee_streetAddress_view'
                        xs={12}
                        sm={9}
                        md={9}
                        lg={7}>
                        <AvenirBlackH4
                          data-qaid='createEmployee_streetAddress_label'
                          label={Context.langData.street_address}
                          style={{ marginBottom: 5 }}
                        />
                        <TextInputComponent
                          value={values.streetAddress}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          id='streetAddress'
                          maxLength={64}
                          variant='outlined'
                          style={{ width: "100%" }}
                          InputProps={{
                            classes: {
                              root: classes.labelRoot,
                              notchedOutline: classes.notchedOutline,
                              input: classes.input,
                            },
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        data-qaid='createEmployee_unitNumber_view'
                        xs={12}
                        sm={3}
                        md={3}
                        lg={3}>
                        <AvenirBlackH4
                          data-qaid='createEmployee_unitNumber_label'
                          label={Context.langData.unit_number}
                          style={{ marginBottom: 5 }}
                        />
                        <TextInputComponent
                          value={values.unitNumber}
                          onBlur={handleBlur}
                          onChange={(val) =>
                            setFieldValue(
                              "unitNumber",
                              validateNumber(val.target.value)
                            )
                          }
                          id='unitNumber'
                          maxLength={32}
                          variant='outlined'
                          style={{ width: "100%" }}
                          InputProps={{
                            classes: {
                              root: classes.labelRoot,
                              notchedOutline: classes.notchedOutline,
                              input: classes.input,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      data-qaid='createEmployee_city_province_notes_view'
                      spacing={2}
                      style={{
                        paddingTop: "20px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}>
                      <Grid
                        item
                        data-qaid='createEmployee_city_view'
                        xs={12}
                        sm={3}
                        md={3}
                        lg={4}>
                        <AvenirBlackH4
                          data-qaid='createEmployee_cityLabel'
                          label={Context.langData.city}
                          style={{ marginBottom: 5 }}
                        />
                        <TextInputComponent
                          value={values.city}
                          onBlur={handleBlur}
                          onChange={(val) =>
                            setFieldValue(
                              "city",
                              validateText(val.target.value)
                            )
                          }
                          id='city'
                          maxLength={32}
                          variant='outlined'
                          style={{ width: "100%" }}
                          InputProps={{
                            classes: {
                              root: classes.labelRoot,
                              notchedOutline: classes.notchedOutline,
                              input: classes.input,
                            },
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        data-qaid='createEmployee_province_view'
                        xs={12}
                        sm={3}
                        md={3}
                        lg={4}>
                        <AvenirBlackH4
                          data-qaid='createEmployee_provinceLabel'
                          label={Context.langData.province}
                          style={{ marginBottom: 5 }}
                        />
                        <SingleSelect
                          list={ProvinceData}
                          valueKey='value'
                          displayKey='name'
                          value={province}
                          placeholder='Select'
                          handleChange={(province) =>
                            setProvince(province.target.value)
                          }
                          border={false}
                          height={54}
                          FormWidth='100%'
                        />
                      </Grid>
                      <Grid
                        item
                        data-qaid='createEmployee_postalCode_view'
                        xs={12}
                        sm={3}
                        md={3}
                        lg={2}>
                        <AvenirBlackH4
                          data-qaid='createEmployee_postalCodeLabel'
                          label={Context.langData.postal_code}
                          style={{ marginBottom: 5 }}
                        />
                        <InputMaskComponent
                          value={values.postalCode}
                          regex='postal'
                          error={errors.postalCode}
                          maxLength={7}
                          onBlur={(value) => {
                            if (value) {
                              setFieldError("postalCode", value);
                            } else {
                              const err = { ...errors };
                              delete err.postalCode;
                              setErrors(err);
                            }
                          }}
                          isFormik
                          handleChange={(val) =>
                            setFieldValue("postalCode", val)
                          }
                          id='postalCode'
                          placeholder='X1X 1X1'
                        />
                      </Grid>
                      <Grid
                        item
                        data-qaid='createEmployee_notes_view'
                        xs={12}
                        sm={12}
                        md={12}
                        lg={10}>
                        <AvenirBlackH4
                          data-qaid='createEmployee_notesLabel'
                          label={Context.langData.notes}
                          style={{ marginBottom: 5 }}
                        />
                        <TextField
                          value={values.notes}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          id='notes'
                          multiline
                          rows={5}
                          InputProps={{ disableUnderline: true }}
                          inputProps={{ maxLength: 500 }}
                          style={{
                            width: "100%",
                            height: 128,
                            backgroundColor: "#FFF",
                            outline: "none",
                            fontSize: 14,
                            borderRadius: 4,
                            fontFamily: "AvenirLT-Medium",
                            color: "#051D33",
                            padding: 10,
                            border: "1px solid #707272",
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={10}
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        display: "flex",
                        padding: 10,
                        paddingTop: 5,
                      }}>
                      <Grid
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          display: "flex",
                        }}>
                        <InfoOutlinedIcon style={{ height: 16, width: 16 }} />
                        <H5
                          label={Context.langData.do_not_add_any_sensitive_info}
                          style={{ paddingLeft: 5, paddingTop: 5 }}
                        />
                      </Grid>
                      <Grid style={{ justifyContent: "flex-end" }}>
                        <H5
                          style={{ color: "#707272" }}
                          label={
                            (values?.notes?.length == undefined
                              ? "0"
                              : values?.notes?.length) +
                            Context.langData._500_characters_count
                          }
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </div>
              {snackbar && (
                <SnackBar
                  data-qaid='createEmployee_snackbar'
                  open
                  setOpen={() => setSnackbar(null)}
                  severity={snackbar.severity}
                  message={snackbar.message}
                />
              )}
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default CreateEmployee;
