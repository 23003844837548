import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { AvenirBlackH3, DotText, AvenirBlackH4 } from "../utils/text";
import { ArrowsIcon, RightArrow, DownArrow } from "./SVG";
import { CustomPagination } from ".";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
}));
export default function BasicTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [selectedId, setSelectedId] = React.useState([]);
  const [checkedAll, setCheckedAll] = React.useState(false);
  useEffect(() => {
    if (props.onCheckedIds) {
      props.onCheckedIds(selectedId);
    }
  }, [selectedId]);
  function onClickView(row) {
    props.onClickView(row);
  }
  function onPageChange() {}
  function onSortClick(value, sort, index) {
    if (props.sortingOnClick) {
      props.sortingOnClick(value, sort, index);
    }
  }
  return (
    <Grid data-qaid='recipedatatable_main_grid'>
      <Grid style={{ width: "100%" }}>
        <Grid
          data-qaid='recipedatatable_header_grid'
          style={{
            flexDirextion: "row",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            marginTop: 50,
            width: "100%",
            backgroundColor: "#F3FAFD",
            height: 56,
            paddingLeft: 20,
            paddingRight: 20,
          }}>
          {props.columns.map((column, index3) => (
            <Grid
              data-qaid='recipedatatable_header_item_grid'
              key={index3}
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0 10px",
                alignItems: "center",
                cursor: "pointer",
                width: "42%",
                paddingRight: 10,
              }}>
              <AvenirBlackH3
                data-qaid='recipedatatable_header_label'
                label={column.headerName}
              />
              {/* <ArrowsIcon data-qaid="recipedatatable_arrowsicon" /> */}
              <Grid
                onClick={() => {
                  onSortClick(column.field, column.sort, index3);
                }}>
                <DownArrow />
              </Grid>
            </Grid>
          ))}
          <Grid
            data-qaid='recipedatatable_header_item_grid2'
            style={{ width: "16%" }}
          />
        </Grid>
      </Grid>
      {props.rows.length > 0
        ? props.rows.map((row, index) => (
            <Grid
              data-qaid='recipedatatable_row_grid'
              style={{
                flexDirextion: "row",
                display: "flex",
                backgroundColor: "#FAFAFA",
                alignItems: "center",
                marginTop: 10,
                cursor: "pointer",
                width: "100%",
                height: 56,
                paddingLeft: 20,
                paddingRight: 20,
              }}
              onClick={() => {
                onClickView(row);
              }}>
              {props.columns.map((colItem, index3) => (
                <Grid
                  data-qaid='recipedatatable_roe_item_grid'
                  key={index3}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "42%",
                  }}>
                  <DotText
                    data-qaid='recipedatatable_row_value_label'
                    label={String(row[colItem.field])}
                  />
                  <ArrowsIcon
                    data-qaid='recipedatatable_arrowsicon'
                    style={{ display: "none", paddingRight: 8 }}
                  />
                </Grid>
              ))}
              <Grid
                data-qaid='recipedatatable_right_grid'
                key={`${index}`}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "16%",
                }}
                onClick={() => {
                  onClickView(row);
                }}>
                <RightArrow data-qaid='recipedatatable_rigtarrow' />
              </Grid>
            </Grid>
          ))
        : props.noRowData && (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 10,
              }}>
              <AvenirBlackH4
                data-qaid='offersList_nodata'
                label={props.noData}
              />{" "}
            </div>
          )}
      <Grid
        data-qaid='recipedatatable_footer_grid'
        container
        style={{
          justifyContent: "center",
          display: "flex",
          marginTop: 25,
          marginBottom: 25,
          width: "100%",
        }}>
        <Grid data-qaid='recipedatatable_custompagination_grid'>
          <CustomPagination
            data-qaid='recipedatatable_custompagination'
            total={1}
            onChange={onPageChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
