import * as React from "react";

const Floor = React.memo(
  ({ width = 24, height = 24, color = "#fff", ...props }) => (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.6 1.75a2.85 2.85 0 100 5.7 2.85 2.85 0 000-5.7zM.25 4.6a4.35 4.35 0 118.7 0 4.35 4.35 0 01-8.7 0zM15.4 1.75a2.85 2.85 0 100 5.7 2.85 2.85 0 000-5.7zM11.05 4.6a4.35 4.35 0 118.7 0 4.35 4.35 0 01-8.7 0zM4.6 12.55a2.85 2.85 0 100 5.7 2.85 2.85 0 000-5.7zM.25 15.4a4.35 4.35 0 118.7 0 4.35 4.35 0 01-8.7 0zm15.15-2.85a2.85 2.85 0 100 5.7 2.85 2.85 0 000-5.7zm-4.35 2.85a4.35 4.35 0 118.7 0 4.35 4.35 0 01-8.7 0z'
        fill={color}
      />
    </svg>
  )
);

export default Floor;
