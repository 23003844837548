import React from "react";
import { Typography } from "@material-ui/core";

const Currency = ({ variant, type }) => {
  const symbls = {
    dollor: "\u0024",
    rupee: "\u20B9",
    pound: "\u00A3",
    yen: "\u00A5",
    euro: "\u20AC",
  };

  return (
    <Typography
      data-qaid='currency_symbls'
      variant={variant}
      style={{ marginRight: 5 }}>
      {symbls[type]}{" "}
    </Typography>
  );
};

export default Currency;
