import instance from "./axiosInstance";
import urls from "./urls";

const websiteInformation = () => (dispatch) => {
  instance
    .get(urls.website.info)
    .then((response) => {
      const { data } = response;
      console.log(data);
      if (response.status === 200 || response.status === 304) {
      }
      dispatch({ type: "SET_INFO", payload: response.data });
    })
    .catch((err) => {
      console.log(err);
    });
};
const websiteAccount = () => (dispatch) => {
  instance
    .get(urls.website.account)
    .then((response) => {
      const { data } = response;
      console.log(data);
      if (response.status === 200 || response.status === 304) {
      }
      dispatch({ type: "SET_ACCOUNT_SETTINGS", payload: response.data });
    })
    .catch((err) => {
      console.log(err);
    });
};
const getSite = () => (dispatch) => {
  instance
    .get(urls.website.site)
    .then((response) => {
      const { data } = response;
      console.log(data);
      if (response.status === 200 || response.status === 304) {
      }
      dispatch({ type: "SET_SITE", payload: response.data });
    })
    .catch((err) => {
      console.log(err);
    });
};
const getTheme = () => (dispatch) => {
  instance
    .get(urls.website.theme)
    .then((response) => {
      const { data } = response;
      console.log(data);
      if (response.status === 200 || response.status === 304) {
      }
      dispatch({ type: "SET_THEME", payload: response.data });
    })
    .catch((err) => {
      console.log(err);
    });
};
const saveTheme = (params) => (dispatch) => {
  instance
    .post(urls.website.theme, params)
    .then((response) => {
      dispatch(getTheme());
    })
    .catch((err) => {
      console.log(err);
    });
};
const saveWebInfo = (params, callback) => (dispatch) => {
  instance
    .post(urls.website.info, params)
    .then((res) => {
      console.log(res);
      callback(true);
      dispatch(websiteInformation());
    })
    .catch((err) => {
      console.log(err);
      callback(false);
    });
};
const saveAccountInfo = (params, callback) => (dispatch) => {
  instance
    .post(urls.website.account, params)
    .then((res) => {
      console.log(res);
      callback(true);
      dispatch(websiteAccount());
    })
    .catch((err) => {
      console.log(err);
      callback(false);
    });
};
const savePages = (params, callback) => (dispatch) => {
  instance
    .post(urls.website.pages, params)
    .then((res) => {
      console.log(res);
      callback(true);
      dispatch(getSite());
    })
    .catch((err) => {
      console.log(err);
      callback(false);
    });
};
const getWebpages = () => (dispatch) => {
  instance
    .get(urls.website.webpages)
    .then((response) => {
      const { data } = response;
      console.log(data);
      if (response.status === 200 || response.status === 304) {
      }
      dispatch({ type: "SET_WEB_INFO", payload: response.data });
    })
    .catch((err) => {
      console.log(err);
    });
};
const saveWebpages = (params, callback) => (dispatch) => {
  instance
    .post(urls.website.webpages, params)
    .then((res) => {
      console.log(res);
      callback(true);
      dispatch(getWebpages());
    })
    .catch((err) => {
      console.log(err);
      callback(false);
    });
};
const saveSite = (params, callback) => (dispatch) => {
  instance
    .post(urls.website.site, params)
    .then((res) => {
      console.log(res);
      if (callback) callback(true);
      dispatch(getSite());
    })
    .catch((err) => {
      console.log(err);
      if (callback) callback(false);
    });
};

const publishSite = (callback) => {
  const params = {
    status: 1,
  };
  return (dispatch) => {
    instance
      .post(urls.website.publish, params)
      .then((res) => {
        console.log(res);
        callback(true, res);
      })
      .catch((err) => {
        console.log(err);
        callback(false, err);
      });
  };
};
const getImage = () => (dispatch) => {
  instance
    .get(urls.website.image)
    .then((res) => {
      dispatch({ type: "SET_BANNER", payload: res.data });
      dispatch(getWebpages());
    })
    .catch((err) => {
      console.log(err);
    });
};
const deleteImage = (params, callback) => (dispatch) => {
  instance
    .delete(urls.website.image, { data: params })
    .then((res) => {
      console.log(res);
      callback(true);
    })
    .catch((err) => {
      console.log(err);
      callback(false);
    });
};
const saveImage = (params, callback) => (dispatch) => {
  instance
    .post(urls.website.image, params)
    .then((res) => {
      console.log(res);
      callback(true);
    })
    .catch((err) => {
      console.log(err);
      callback(false);
    });
};

const deleteLogo = (params, callback) => (dispatch) => {
  instance
    .delete(urls.website.logo, { data: params })
    .then((res) => {
      console.log(res);
      callback(true);
    })
    .catch((err) => {
      console.log(err);
      callback(false);
    });
};
const saveLogo = (params, callback) => (dispatch) => {
  instance
    .post(urls.website.logo, params)
    .then((res) => {
      console.log(res);
      callback(true);
    })
    .catch((err) => {
      console.log(err);
      callback(false);
    });
};
export {
  saveImage,
  deleteImage,
  websiteInformation,
  websiteAccount,
  getSite,
  saveWebInfo,
  saveAccountInfo,
  savePages,
  getTheme,
  getWebpages,
  saveWebpages,
  saveTheme,
  getImage,
  saveSite,
  publishSite,
  deleteLogo,
  saveLogo,
};
