import React from "react";
import { Grid } from "@material-ui/core";
import TaxProfilesList from "./taxProfilesList";

const TaxProfiles = () => (
  <Grid>
    <TaxProfilesList data-qaid='tax-taxProfilelist' />
  </Grid>
);

export default TaxProfiles;
