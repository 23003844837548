import React from "react";

const DefaultCreditIcon = (props) => (
  <svg
    width='36'
    height='26'
    viewBox='0 0 36 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    {...props}>
    <rect y='0.420898' width='35.0649' height='25' fill='url(#pattern0)' />
    <defs>
      <pattern
        id='pattern0'
        patternContentUnits='objectBoundingBox'
        width='1'
        height='1'>
        <use
          xlinkHref='#image0_37467_639904'
          transform='scale(0.00925926 0.012987)'
        />
      </pattern>
      <image
        id='image0_37467_639904'
        width='108'
        height='77'
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGwAAABNCAMAAAB0SHFsAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABjUExURQAAAAC/vwCvzwC1ygC3xwCzxgC1ygC2yAC3xwC1ygC2yQC3yAC1xwC1yQC2yAC1yAC1yQC3ygC2ygC2yQC3ygC2ygC2yQC3yQC3ygC2yQC2yQC3ygC2yQC2yQC2yQC2yQC2yVkDubgAAAAgdFJOUwAIEBggKDA4QEhQWGBocHiAh4+Xn6evt7/Hz9ff5+/3v+H4wgAAAAlwSFlzAAAXEQAAFxEByibzPwAAAnxJREFUaEPtmWF3qjAMQAvyJirCnIAKMvj/v/I1aYYFgpNCtnN2uB9YU3q4opA2nULe4rRoxCizZGs8Gj+lXkEuG+PaVtQhyucBXP6PuJqm/qdlGbbyd0HST3DclArhbwFWQTZXsEQq0Ue8Q1HwtzqrXB9z6hIE7qlQoEyoR5Cd1jQKDhH1CIKPxiqbCy/bnPCd6FKed3TaFVa2G0teJ49GuMHJ9hDyzHsZGdnmWVKe9csysneIstAA7dg0E8ikdxrkBCODZyOjNp4JqY1fb0CBC4ystgM48yXz7MAFRjYI2ut3AgdGZO00CoG0rMMq+5YRWXklIJCWdYJV9i2rDOkEDjCyuw5ianeu70PwKLKmw8igULtTJdWRnXW7nrMwYGQRRJX1UhemWUL7SoOcYGQ4e44wr/rgZMH4IuRIQ9zgZMq/QMeQas6jqGFlusSOafK0CX0668qITIZVtgirbBF+W6bLTpvMpI0wo9hwwmnBO1JouOxxaJBSbEjbWoSRwUxiU8Gs4kO5YfMBQ48UtGCevlHwxQ06AUYGk2cHmC9xnE0JQwd7lHARr/+52jmQkcGmj00BnV5/JsC5vF8Q15g9YXPKpi32GJnaUuI1ROZzeRHFBprXgoRi5EiZekex4bHHwMnEWGWLsMoW4bdlAW7yP6d+ZNcJMLIXXJo2B02AkfWTNk+byifAyA79rM1R479wJsI9IL7Z8nuK0+KYk4nx12XwSMwrvV7CyKDWPFOPILHW3NWHPuIiSha4p1wd9LFJqUsMXEklysOdgNuBXiIR9lg7V3px+/ZK1lgCTDyDta0MuI7Wi0X8JmWp2iWkF+eDhfeSVJdEJ1Sl/gP8lirFl6LIVAAAAABJRU5ErkJggg=='
      />
    </defs>
  </svg>
);

export default DefaultCreditIcon;
