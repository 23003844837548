import moment from "moment";
import {
  getWookBook,
  downloadAs,
  setHeaderStyle,
  setNormalRowStyle,
} from "./workbook";

const downloadStockBalance = (data) => {
  const workbook = getWookBook();
  const ws = workbook.addWorksheet("Stock balance");
  ws.addRow(["Stock name", "Units", "Initial balance", "Critical balance"]);
  ws.getColumn(1).width = 30;
  ws.getColumn(2).width = 20;
  ws.getColumn(3).width = 20;
  ws.getColumn(4).width = 20;
  setHeaderStyle(ws, 1);
  data.map((obj, index) => {
    console.log("objobj", obj);
  });
  data.map((d, i) => {
    ws.addRow([d.name, d.unitTypeCode, d.currentBalance, d.criticalBalance]);
    setNormalRowStyle(ws, i + 2);
  });
  downloadAs(
    workbook,
    `stock_balance_${moment().format("yyyyMMDDHHmmss")}.xlsx`
  );
};

// const uploadStockBalance = async (data) => {
//     const workbook = new Excel.Workbook();
//     await workbook.xlsx.load(data);
//     const ws = workbook.worksheets[0]
//     let recipe = [];
//     let itemObj = null;
//     let items = [];
//     const lastRow = ws.lastRow;
//     ws.eachRow(function (row, rowNumber) {
//       if (row.values.length == 3) {
//         if (itemObj) {
//           itemObj["recipe"] = recipe;
//           items.push(itemObj)
//         }
//         recipe = [];
//         itemObj = getItemObj(ws, rowNumber)

//       }
//       else {
//         let obj = getRecipeObj(ws, rowNumber)
//         recipe.push(obj)
//       }
//       if (lastRow == row) {
//         itemObj["recipe"] = recipe;
//         items.push(itemObj)
//       }
//     });
//     console.log(items)
//   }
export { downloadStockBalance };
