import React from "react";

const MonerisLogo = (props) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox='0 0 210 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_3283_2659)'>
      <path
        d='M0 0.312727H3.90867L6.60733 7.89818H6.644L9.35733 0.312727H13.2513V11.9418H10.67V3.01818H10.6333L7.55333 11.9345H5.58067L2.618 3.01818H2.58133V11.9345H0V0.312727Z'
        fill='white'
      />
      <path
        d='M15.0187 7.94909C15.0187 7.31636 15.136 6.74182 15.3633 6.22545C15.598 5.70909 15.9133 5.27273 16.3167 4.90182C16.72 4.53818 17.1967 4.25454 17.7393 4.05818C18.282 3.86182 18.8687 3.76 19.4847 3.76C20.1007 3.76 20.6873 3.86182 21.23 4.05818C21.78 4.25454 22.2493 4.53818 22.6527 4.90182C23.056 5.26545 23.3713 5.70909 23.606 6.22545C23.8407 6.74182 23.9507 7.31636 23.9507 7.94909C23.9507 8.58182 23.8333 9.15636 23.606 9.67272C23.3713 10.1891 23.056 10.6255 22.6527 10.9964C22.2493 11.36 21.7727 11.6436 21.23 11.84C20.6873 12.0364 20.1007 12.1382 19.4847 12.1382C18.8687 12.1382 18.282 12.0364 17.7393 11.84C17.1967 11.6436 16.72 11.36 16.3167 10.9964C15.9133 10.6327 15.598 10.1891 15.3633 9.67272C15.136 9.15636 15.0187 8.58182 15.0187 7.94909ZM17.5047 7.94909C17.5047 8.55273 17.6807 9.04 18.0253 9.41091C18.37 9.78182 18.8613 9.97091 19.492 9.97091C20.1227 9.97091 20.6067 9.78182 20.9587 9.41091C21.3033 9.04 21.4793 8.55273 21.4793 7.94909C21.4793 7.34545 21.3033 6.85818 20.9587 6.48727C20.614 6.11636 20.1227 5.92727 19.492 5.92727C18.8613 5.92727 18.3773 6.11636 18.0253 6.48727C17.6733 6.85818 17.5047 7.34545 17.5047 7.94909Z'
        fill='white'
      />
      <path
        d='M25.4173 3.95636H27.8007V5.04H27.8373C27.918 4.88727 28.028 4.73454 28.1673 4.58182C28.3067 4.42909 28.4827 4.29091 28.6807 4.17454C28.8787 4.05091 29.106 3.95636 29.3627 3.87636C29.6193 3.80364 29.8907 3.76 30.1913 3.76C30.822 3.76 31.328 3.85454 31.7167 4.05091C32.1053 4.24 32.406 4.50909 32.6187 4.85091C32.8313 5.19273 32.978 5.59273 33.0587 6.05091C33.132 6.50909 33.176 7.01091 33.176 7.54182V11.9418H30.69V8.03636C30.69 7.80363 30.6827 7.57091 30.668 7.32363C30.6533 7.07636 30.602 6.85091 30.5213 6.64C30.4407 6.42909 30.3087 6.26182 30.14 6.13091C29.9713 6 29.722 5.93454 29.4067 5.93454C29.0913 5.93454 28.8273 5.99272 28.6293 6.10909C28.4313 6.22545 28.2773 6.37818 28.1747 6.56727C28.072 6.75636 27.9987 6.97454 27.9693 7.21454C27.9327 7.45454 27.918 7.70909 27.918 7.97091V11.9418H25.432V3.95636H25.4173Z'
        fill='white'
      />
      <path
        d='M42.5407 10.56C42.1447 11.0618 41.6387 11.4545 41.0373 11.7236C40.4287 12 39.798 12.1309 39.1527 12.1309C38.5367 12.1309 37.95 12.0291 37.4073 11.8327C36.8573 11.6364 36.388 11.3527 35.9847 10.9891C35.5813 10.6255 35.266 10.1818 35.0313 9.66545C34.7967 9.14909 34.6867 8.57454 34.6867 7.94182C34.6867 7.30909 34.804 6.73454 35.0313 6.21818C35.266 5.70182 35.5813 5.26545 35.9847 4.89454C36.388 4.53091 36.8647 4.24727 37.4073 4.05091C37.95 3.85454 38.5367 3.75273 39.1527 3.75273C39.7247 3.75273 40.2453 3.85454 40.7147 4.05091C41.184 4.24727 41.58 4.53091 41.91 4.89454C42.2327 5.25818 42.4893 5.70182 42.6653 6.21818C42.8413 6.73454 42.9293 7.30909 42.9293 7.94182V8.71273H37.1653C37.268 9.18545 37.4807 9.55636 37.8107 9.84C38.1407 10.1236 38.5513 10.2618 39.0353 10.2618C39.446 10.2618 39.7907 10.1745 40.0693 9.99273C40.348 9.81091 40.5973 9.57818 40.8027 9.29454L42.5407 10.56ZM40.436 7.04C40.4433 6.62545 40.3113 6.26909 40.0253 5.97091C39.7393 5.67273 39.3653 5.52727 38.918 5.52727C38.6393 5.52727 38.3973 5.57091 38.192 5.65818C37.9793 5.74545 37.8033 5.86182 37.6567 5.99273C37.51 6.13091 37.3927 6.29091 37.312 6.47273C37.2313 6.65454 37.18 6.84364 37.1727 7.04H40.436Z'
        fill='white'
      />
      <path
        d='M44.4253 3.95636H46.9113V5.23636H46.948C47.212 4.74182 47.5273 4.37091 47.894 4.13091C48.2607 3.88364 48.7153 3.76 49.2653 3.76C49.412 3.76 49.5513 3.76727 49.698 3.77454C49.8447 3.78909 49.9767 3.81091 50.094 3.84V6.08727C49.918 6.03636 49.742 5.99272 49.5733 5.96363C49.4047 5.93454 49.2213 5.92 49.038 5.92C48.5613 5.92 48.1873 5.98545 47.9087 6.11636C47.63 6.24727 47.4173 6.42909 47.2707 6.66909C47.124 6.90182 47.0287 7.18545 46.9773 7.51273C46.9333 7.84 46.9113 8.20363 46.9113 8.59636V11.9273H44.4253V3.95636Z'
        fill='white'
      />
      <path
        d='M51.0033 1.44C51.0033 1.04727 51.1427 0.712726 51.4287 0.429089C51.7073 0.152726 52.052 0.00727081 52.448 0.00727081C52.844 0.00727081 53.1813 0.145453 53.4673 0.429089C53.746 0.705453 53.8927 1.04727 53.8927 1.44C53.8927 1.83273 53.7533 2.16727 53.4673 2.45091C53.1887 2.72727 52.844 2.87273 52.448 2.87273C52.052 2.87273 51.7147 2.73454 51.4287 2.45091C51.1427 2.17454 51.0033 1.84 51.0033 1.44ZM51.2013 3.95636H53.68V11.9345H51.194V3.95636H51.2013Z'
        fill='white'
      />
      <path
        d='M60.0233 6.30545C59.6567 5.85454 59.18 5.62909 58.586 5.62909C58.3733 5.62909 58.1753 5.68 57.97 5.77454C57.772 5.87636 57.6693 6.04363 57.6693 6.28363C57.6693 6.48 57.772 6.62545 57.9773 6.72C58.1827 6.81454 58.4393 6.89454 58.7547 6.96727C59.07 7.04 59.4073 7.11272 59.7667 7.2C60.126 7.28 60.4633 7.41091 60.7787 7.58545C61.094 7.76 61.3507 8 61.556 8.29818C61.7613 8.59636 61.864 9.00363 61.864 9.50545C61.864 10.0218 61.754 10.4436 61.5267 10.7782C61.2993 11.1127 61.0133 11.3818 60.654 11.5855C60.302 11.7891 59.906 11.9273 59.4587 12.0145C59.0187 12.0945 58.5787 12.1382 58.1533 12.1382C57.5887 12.1382 57.024 12.0582 56.4447 11.8982C55.8653 11.7382 55.3813 11.4545 54.9853 11.0545L56.4887 9.39636C56.7233 9.68 56.98 9.89818 57.2587 10.0436C57.5373 10.1891 57.8673 10.2691 58.2413 10.2691C58.5273 10.2691 58.784 10.2255 59.0187 10.1455C59.2533 10.0655 59.3633 9.91272 59.3633 9.69454C59.3633 9.48363 59.2607 9.33091 59.0553 9.22909C58.85 9.12727 58.5933 9.04 58.278 8.96727C57.9627 8.89454 57.6253 8.82182 57.266 8.73454C56.9067 8.65454 56.5693 8.53091 56.254 8.36363C55.9387 8.19636 55.682 7.96363 55.4767 7.66545C55.2713 7.36727 55.1687 6.96 55.1687 6.45818C55.1687 5.98545 55.264 5.58545 55.462 5.24363C55.6527 4.90182 55.9093 4.62545 56.2247 4.40727C56.54 4.18909 56.8993 4.02909 57.31 3.92C57.7207 3.81818 58.1313 3.76727 58.5493 3.76727C59.0773 3.76727 59.6127 3.84727 60.1553 4C60.698 4.15273 61.1527 4.42909 61.5267 4.82182L60.0233 6.30545Z'
        fill='white'
      />
      <path
        d='M78.9653 11.1127C78.2027 11.5055 77.4107 11.7891 76.582 11.9636C75.7533 12.1382 74.91 12.2255 74.052 12.2255C73.1353 12.2255 72.292 12.08 71.522 11.7964C70.752 11.5127 70.0847 11.1055 69.52 10.5745C68.9553 10.0436 68.5153 9.40364 68.2073 8.64727C67.892 7.89091 67.738 7.04727 67.738 6.11636C67.738 5.18545 67.892 4.34182 68.2073 3.58545C68.5227 2.82909 68.9627 2.18909 69.52 1.65818C70.0847 1.12727 70.752 0.719999 71.522 0.436362C72.292 0.152726 73.1427 0.00727081 74.052 0.00727081C74.9907 0.00727081 75.856 0.116362 76.6407 0.341817C77.4327 0.567271 78.122 0.952725 78.716 1.49818L76.8973 3.46909C76.5453 3.10545 76.1493 2.83636 75.702 2.64727C75.262 2.45818 74.7047 2.37091 74.0447 2.37091C73.4947 2.37091 72.996 2.46545 72.5487 2.64727C72.1013 2.83636 71.72 3.09091 71.3973 3.42545C71.0747 3.76 70.8327 4.15273 70.664 4.61818C70.4953 5.07636 70.4073 5.57818 70.4073 6.10909C70.4073 6.65454 70.4953 7.15636 70.664 7.61454C70.8327 8.06545 71.082 8.46545 71.3973 8.8C71.72 9.13454 72.1013 9.39636 72.5487 9.57818C72.996 9.76727 73.4947 9.85454 74.0447 9.85454C74.6093 9.85454 75.0933 9.78909 75.504 9.66545C75.9147 9.54182 76.2373 9.41091 76.4793 9.28V7.38909H74.3747V5.02545H78.9653V11.1127Z'
        fill='white'
      />
      <path
        d='M80.6227 7.94909C80.6227 7.31636 80.74 6.74182 80.9673 6.22545C81.202 5.70909 81.5173 5.27273 81.9207 4.90182C82.324 4.53818 82.8007 4.25454 83.3433 4.05818C83.886 3.86182 84.4727 3.76 85.0887 3.76C85.7047 3.76 86.2913 3.86182 86.834 4.05818C87.384 4.25454 87.8533 4.53818 88.2567 4.90182C88.66 5.26545 88.9753 5.70909 89.21 6.22545C89.4447 6.74182 89.5547 7.31636 89.5547 7.94909C89.5547 8.58182 89.4373 9.15636 89.21 9.67272C88.9753 10.1891 88.66 10.6255 88.2567 10.9964C87.8533 11.36 87.3767 11.6436 86.834 11.84C86.2913 12.0364 85.7047 12.1382 85.0887 12.1382C84.4727 12.1382 83.886 12.0364 83.3433 11.84C82.8007 11.6436 82.324 11.36 81.9207 10.9964C81.5173 10.6327 81.202 10.1891 80.9673 9.67272C80.74 9.15636 80.6227 8.58182 80.6227 7.94909ZM83.1087 7.94909C83.1087 8.55273 83.2847 9.04 83.6293 9.41091C83.974 9.78182 84.4653 9.97091 85.096 9.97091C85.7267 9.97091 86.2107 9.78182 86.5627 9.41091C86.9073 9.04 87.0833 8.55273 87.0833 7.94909C87.0833 7.34545 86.9073 6.85818 86.5627 6.48727C86.218 6.11636 85.7267 5.92727 85.096 5.92727C84.4653 5.92727 83.9813 6.11636 83.6293 6.48727C83.2847 6.85818 83.1087 7.34545 83.1087 7.94909Z'
        fill='white'
      />
      <path
        d='M179.6 0.312727H182.981C183.391 0.312727 183.817 0.356363 184.264 0.450908C184.711 0.545454 185.122 0.705454 185.489 0.938181C185.855 1.17091 186.163 1.48364 186.398 1.88364C186.633 2.28364 186.757 2.78545 186.757 3.38909C186.757 3.91273 186.662 4.37091 186.464 4.74909C186.273 5.13454 186.009 5.44727 185.687 5.69454C185.364 5.94182 184.983 6.12364 184.557 6.24727C184.132 6.37091 183.685 6.42909 183.223 6.42909H180.693V11.9491H179.6V0.312727ZM180.693 5.43273H183.179C183.487 5.43273 183.795 5.39636 184.088 5.32364C184.389 5.25091 184.653 5.13454 184.88 4.98182C185.115 4.82182 185.298 4.61091 185.445 4.34909C185.584 4.08727 185.657 3.76 185.657 3.38182C185.657 3.01091 185.584 2.69091 185.43 2.42909C185.283 2.16727 185.085 1.94909 184.851 1.78182C184.616 1.61454 184.337 1.49091 184.029 1.41091C183.721 1.33818 183.413 1.29455 183.105 1.29455H180.685V5.43273H180.693Z'
        fill='white'
      />
      <path
        d='M188.341 6.12364C188.341 5.23636 188.488 4.42182 188.789 3.68C189.089 2.93818 189.5 2.29091 190.028 1.75273C190.556 1.20727 191.194 0.785454 191.942 0.479999C192.69 0.174545 193.504 0.0218182 194.399 0.0218182C195.293 0.0218182 196.115 0.174545 196.855 0.479999C197.603 0.785454 198.241 1.20727 198.769 1.75273C199.297 2.29091 199.715 2.93818 200.009 3.68C200.309 4.42182 200.456 5.23636 200.456 6.12364C200.456 7.01091 200.309 7.82545 200.009 8.58182C199.708 9.33091 199.297 9.97818 198.769 10.5164C198.241 11.0545 197.603 11.4764 196.855 11.7818C196.107 12.08 195.293 12.2327 194.399 12.2327C193.504 12.2327 192.683 12.08 191.942 11.7818C191.194 11.4836 190.563 11.0618 190.028 10.5164C189.5 9.97091 189.082 9.33091 188.789 8.58182C188.495 7.82545 188.341 7.01091 188.341 6.12364ZM189.434 6.12364C189.434 6.84364 189.551 7.52 189.779 8.14545C190.013 8.77091 190.343 9.30909 190.776 9.77455C191.209 10.2327 191.729 10.5964 192.338 10.8582C192.954 11.12 193.636 11.2509 194.399 11.2509C195.161 11.2509 195.851 11.12 196.459 10.8582C197.075 10.5964 197.596 10.2327 198.021 9.77455C198.454 9.31636 198.784 8.77091 199.019 8.14545C199.253 7.52 199.363 6.85091 199.363 6.12364C199.363 5.39636 199.246 4.72727 199.019 4.10182C198.784 3.47636 198.454 2.93818 198.021 2.47273C197.589 2.01455 197.068 1.65091 196.459 1.38909C195.843 1.12727 195.161 0.996363 194.399 0.996363C193.636 0.996363 192.947 1.12727 192.338 1.38909C191.722 1.65091 191.201 2.01455 190.776 2.47273C190.343 2.93091 190.013 3.47636 189.779 4.10182C189.551 4.72727 189.434 5.40364 189.434 6.12364Z'
        fill='white'
      />
      <path
        d='M203.228 9.78909C203.514 10.2836 203.888 10.6473 204.343 10.8873C204.797 11.1273 205.325 11.2509 205.905 11.2509C206.271 11.2509 206.609 11.2 206.931 11.0836C207.254 10.9745 207.533 10.8218 207.767 10.6255C208.002 10.4291 208.193 10.1891 208.332 9.91273C208.471 9.63636 208.537 9.31636 208.537 8.96727C208.537 8.56 208.449 8.22545 208.281 7.96363C208.112 7.70182 207.877 7.48364 207.591 7.31636C207.305 7.14909 206.983 7.00364 206.623 6.88C206.264 6.75636 205.897 6.64 205.516 6.51636C205.135 6.39273 204.768 6.26182 204.409 6.10182C204.049 5.94909 203.727 5.75273 203.441 5.50545C203.155 5.25818 202.927 4.95273 202.751 4.57454C202.583 4.20364 202.495 3.74545 202.495 3.19273C202.495 2.67636 202.597 2.21818 202.803 1.81818C203.008 1.41818 203.287 1.08364 203.631 0.814545C203.976 0.545454 204.379 0.341818 204.834 0.203637C205.289 0.0654548 205.758 0 206.257 0C206.895 0 207.489 0.10909 208.039 0.334545C208.589 0.559999 209.058 0.930908 209.454 1.46182L208.508 2.13818C208.229 1.75273 207.907 1.46909 207.54 1.27273C207.173 1.08364 206.733 0.981818 206.227 0.981818C205.875 0.981818 205.538 1.02545 205.215 1.12C204.893 1.21455 204.614 1.35273 204.379 1.52727C204.145 1.70909 203.954 1.93454 203.807 2.21091C203.661 2.48727 203.595 2.80727 203.595 3.17818C203.595 3.76 203.741 4.20364 204.042 4.51636C204.343 4.82909 204.709 5.07636 205.157 5.25818C205.604 5.44 206.088 5.6 206.616 5.73818C207.137 5.87636 207.628 6.05818 208.075 6.29091C208.523 6.52363 208.897 6.84364 209.19 7.25091C209.491 7.65818 209.637 8.22545 209.637 8.95273C209.637 9.47636 209.542 9.94182 209.337 10.3491C209.139 10.7564 208.867 11.0982 208.53 11.3745C208.193 11.6509 207.804 11.8618 207.364 12.0073C206.924 12.1455 206.462 12.2182 205.993 12.2182C205.223 12.2182 204.511 12.08 203.859 11.8182C203.206 11.5491 202.649 11.0982 202.187 10.4655L203.228 9.78909Z'
        fill='white'
      />
      <path
        d='M96.56 0.312727H99.64C99.794 0.312727 100.007 0.319999 100.271 0.327272C100.535 0.341818 100.821 0.370908 101.129 0.42909C101.437 0.487272 101.752 0.574545 102.075 0.690909C102.397 0.814545 102.683 0.98909 102.933 1.21454C103.189 1.44727 103.395 1.73091 103.563 2.08727C103.732 2.43636 103.813 2.86545 103.813 3.36727C103.813 3.92727 103.717 4.38545 103.527 4.74909C103.336 5.11273 103.094 5.40364 102.801 5.62909C102.507 5.85455 102.185 6.02182 101.833 6.13091C101.481 6.24 101.143 6.32 100.821 6.36363L104.231 11.9491H102.991L99.6987 6.42909H97.66V11.9491H96.5673V0.312727H96.56ZM97.6527 5.43273H99.97C100.542 5.43273 101.011 5.36 101.371 5.22182C101.73 5.07636 102.009 4.90182 102.207 4.69818C102.405 4.48727 102.537 4.26909 102.61 4.02182C102.683 3.78182 102.72 3.56364 102.72 3.36727C102.72 3.17091 102.683 2.95273 102.61 2.71273C102.537 2.47273 102.405 2.24727 102.207 2.03636C102.009 1.82545 101.73 1.65091 101.371 1.51273C101.011 1.37455 100.542 1.30182 99.97 1.30182H97.6527V5.43273Z'
        fill='white'
      />
      <path
        d='M112.855 10.5091C112.466 11.0909 111.989 11.5055 111.425 11.76C110.853 12.0145 110.229 12.1382 109.547 12.1382C108.961 12.1382 108.433 12.0364 107.956 11.84C107.479 11.6436 107.069 11.3673 106.731 11.0109C106.387 10.6545 106.13 10.2327 105.947 9.74545C105.763 9.25818 105.675 8.72 105.675 8.13091C105.675 7.56363 105.771 7.04 105.954 6.55272C106.145 6.07272 106.409 5.65091 106.746 5.29454C107.083 4.93818 107.494 4.66182 107.956 4.45818C108.418 4.25454 108.931 4.15273 109.496 4.15273C110.083 4.15273 110.596 4.25454 111.043 4.45818C111.491 4.66182 111.865 4.93091 112.173 5.26545C112.473 5.6 112.708 5.97818 112.862 6.40727C113.016 6.83636 113.097 7.26545 113.097 7.70182V8.39273H106.673C106.673 8.60363 106.717 8.86545 106.797 9.19273C106.878 9.51273 107.032 9.83273 107.252 10.1382C107.472 10.4436 107.765 10.7055 108.139 10.9236C108.506 11.1418 108.983 11.2509 109.569 11.2509C110.075 11.2509 110.567 11.1273 111.043 10.8873C111.52 10.6473 111.879 10.32 112.136 9.89818L112.855 10.5091ZM112.092 7.50545C112.092 7.16363 112.026 6.85091 111.887 6.55272C111.747 6.25454 111.564 6 111.322 5.77454C111.087 5.54909 110.801 5.37454 110.479 5.24363C110.156 5.11273 109.811 5.04727 109.445 5.04727C108.902 5.04727 108.455 5.15636 108.095 5.37454C107.736 5.59273 107.45 5.84727 107.245 6.13091C107.032 6.41454 106.885 6.69091 106.797 6.96C106.709 7.22909 106.665 7.41091 106.665 7.51273H112.092V7.50545Z'
        fill='white'
      />
      <path
        d='M115.319 10C115.531 10.4073 115.839 10.7127 116.243 10.9309C116.653 11.1418 117.079 11.2509 117.533 11.2509C117.746 11.2509 117.951 11.2145 118.164 11.1491C118.377 11.0836 118.56 10.9891 118.729 10.8727C118.897 10.7491 119.029 10.6109 119.132 10.4364C119.235 10.2691 119.286 10.0727 119.286 9.85454C119.286 9.50545 119.176 9.24363 118.949 9.06909C118.721 8.89454 118.443 8.75636 118.113 8.65454C117.783 8.55272 117.416 8.47272 117.02 8.4C116.624 8.32727 116.257 8.21818 115.927 8.07272C115.597 7.92727 115.319 7.71636 115.091 7.45454C114.864 7.19272 114.754 6.82182 114.754 6.33454C114.754 5.96363 114.835 5.63636 114.989 5.36727C115.143 5.09091 115.341 4.86545 115.59 4.68363C115.839 4.50182 116.118 4.37091 116.426 4.28363C116.734 4.19636 117.049 4.15273 117.372 4.15273C118.003 4.15273 118.545 4.26909 119 4.49454C119.455 4.72727 119.814 5.08363 120.071 5.57818L119.176 6.10182C118.985 5.75273 118.751 5.48364 118.472 5.30182C118.193 5.12 117.827 5.03273 117.372 5.03273C117.196 5.03273 117.013 5.06182 116.815 5.11273C116.624 5.16364 116.448 5.24363 116.294 5.34545C116.14 5.44727 116.008 5.57091 115.905 5.72363C115.803 5.87636 115.751 6.05091 115.751 6.24727C115.751 6.58909 115.861 6.83636 116.089 7.00364C116.316 7.17091 116.595 7.29454 116.925 7.38909C117.255 7.48363 117.621 7.56364 118.017 7.62909C118.413 7.69454 118.78 7.80364 119.11 7.94909C119.44 8.09454 119.719 8.31273 119.946 8.58909C120.173 8.86545 120.283 9.26545 120.283 9.78182C120.283 10.1745 120.21 10.5164 120.063 10.8073C119.917 11.0982 119.711 11.3382 119.447 11.5345C119.191 11.7309 118.89 11.8764 118.56 11.9782C118.23 12.08 117.878 12.1236 117.519 12.1236C116.859 12.1236 116.25 12 115.707 11.7455C115.165 11.4909 114.739 11.1055 114.439 10.5818L115.319 10Z'
        fill='white'
      />
      <path
        d='M126.099 5.23636H123.913V10.1818C123.913 10.5236 124.009 10.7855 124.192 10.9673C124.383 11.1564 124.639 11.2436 124.969 11.2436C125.16 11.2436 125.351 11.2218 125.541 11.1709C125.732 11.12 125.923 11.0618 126.099 10.9818L126.179 11.8691C125.989 11.9345 125.776 11.9927 125.534 12.0509C125.292 12.1018 125.072 12.1309 124.889 12.1309C124.537 12.1309 124.229 12.08 123.979 11.9782C123.723 11.8764 123.517 11.7382 123.363 11.5564C123.209 11.3818 123.092 11.1782 123.026 10.9382C122.953 10.7055 122.916 10.4509 122.916 10.1745V5.22909H121.259V4.34182H122.916V2.16H123.913V4.34182H126.099V5.23636Z'
        fill='white'
      />
      <path
        d='M133.813 8.74909C133.813 9.12 133.813 9.45455 133.821 9.75273C133.828 10.0509 133.835 10.32 133.843 10.5745C133.857 10.8291 133.865 11.0618 133.887 11.28C133.909 11.4982 133.931 11.7164 133.96 11.9345H133.036C132.97 11.5636 132.933 11.1491 132.933 10.7055H132.897C132.611 11.2 132.251 11.5564 131.826 11.7891C131.401 12.0218 130.865 12.1309 130.213 12.1309C129.883 12.1309 129.553 12.0873 129.237 12.0073C128.915 11.9273 128.629 11.7964 128.379 11.6145C128.123 11.4327 127.917 11.2 127.756 10.9164C127.595 10.6327 127.514 10.2836 127.514 9.88364C127.514 9.30182 127.661 8.84364 127.961 8.50182C128.262 8.16 128.629 7.90546 129.061 7.73818C129.494 7.57091 129.963 7.46182 130.455 7.41091C130.946 7.36 131.379 7.33818 131.753 7.33818H132.911V6.88C132.911 6.22545 132.706 5.75273 132.295 5.46909C131.885 5.18545 131.371 5.04 130.755 5.04C129.868 5.04 129.091 5.32364 128.423 5.89091L127.844 5.21455C128.211 4.86545 128.658 4.59636 129.201 4.41455C129.743 4.23273 130.264 4.14545 130.755 4.14545C131.679 4.14545 132.42 4.36364 132.977 4.79273C133.527 5.22182 133.806 5.92 133.806 6.88727V8.74909H133.813ZM131.826 8.12364C131.408 8.12364 130.997 8.15273 130.609 8.20364C130.213 8.26182 129.861 8.34909 129.545 8.48C129.23 8.61091 128.981 8.78545 128.79 9.00364C128.599 9.22182 128.511 9.49818 128.511 9.82545C128.511 10.0582 128.563 10.2618 128.665 10.4436C128.768 10.6255 128.907 10.7709 129.069 10.8873C129.237 11.0036 129.421 11.0909 129.626 11.1491C129.831 11.2073 130.037 11.2364 130.249 11.2364C130.777 11.2364 131.217 11.1564 131.555 11.0036C131.899 10.8509 132.171 10.6545 132.376 10.4073C132.581 10.16 132.721 9.88364 132.801 9.57091C132.882 9.25818 132.919 8.94545 132.919 8.62545V8.11636H131.826V8.12364Z'
        fill='white'
      />
      <path
        d='M142.936 9.80364C142.936 9.94909 142.943 10.1164 142.951 10.32C142.958 10.5236 142.973 10.7273 142.973 10.9382C142.98 11.1491 142.987 11.3382 142.995 11.52C143.009 11.7018 143.009 11.84 143.009 11.9418H142.012C141.997 11.6582 141.99 11.3818 141.99 11.12C141.983 10.8582 141.968 10.6909 141.946 10.6109H141.895C141.697 11.0255 141.367 11.3891 140.897 11.6873C140.435 11.9855 139.893 12.1382 139.277 12.1382C138.683 12.1382 138.191 12.0436 137.81 11.8618C137.429 11.6727 137.128 11.4327 136.915 11.1345C136.703 10.8364 136.556 10.4873 136.475 10.0945C136.395 9.70182 136.358 9.29454 136.358 8.88V4.36364H137.355V8.81455C137.355 9.12 137.385 9.41818 137.436 9.70909C137.487 10 137.59 10.2618 137.722 10.4873C137.861 10.72 138.052 10.9018 138.301 11.04C138.551 11.1782 138.873 11.2436 139.269 11.2436C139.636 11.2436 139.981 11.1782 140.303 11.0545C140.626 10.9309 140.912 10.7345 141.147 10.48C141.381 10.2255 141.572 9.89818 141.711 9.51273C141.851 9.12727 141.917 8.66909 141.917 8.13818V4.34182H142.914V9.80364H142.936Z'
        fill='white'
      />
      <path
        d='M145.671 6.48727C145.671 6.34182 145.664 6.17454 145.657 5.97091C145.642 5.76727 145.635 5.56363 145.635 5.35273C145.627 5.14182 145.62 4.95273 145.613 4.77091C145.598 4.58909 145.598 4.45091 145.598 4.34909H146.595C146.61 4.63273 146.617 4.90909 146.617 5.17091C146.625 5.43273 146.639 5.6 146.661 5.68C146.918 5.22909 147.241 4.86545 147.637 4.58182C148.033 4.29818 148.524 4.15273 149.096 4.15273C149.191 4.15273 149.294 4.16 149.382 4.17454C149.477 4.18909 149.573 4.21091 149.668 4.23273L149.551 5.2C149.419 5.15636 149.294 5.13454 149.169 5.13454C148.737 5.13454 148.363 5.2 148.055 5.33818C147.739 5.47636 147.483 5.66545 147.277 5.90545C147.072 6.14545 146.918 6.42909 146.823 6.74909C146.72 7.06909 146.676 7.41818 146.676 7.78909V11.9273H145.679V6.48727H145.671Z'
        fill='white'
      />
      <path
        d='M157.133 8.74909C157.133 9.12 157.133 9.45455 157.141 9.75273C157.148 10.0509 157.155 10.32 157.163 10.5745C157.177 10.8291 157.185 11.0618 157.207 11.28C157.221 11.4982 157.251 11.7164 157.28 11.9345H156.356C156.29 11.5636 156.253 11.1491 156.253 10.7055H156.217C155.931 11.2 155.571 11.5564 155.146 11.7891C154.721 12.0218 154.185 12.1309 153.533 12.1309C153.203 12.1309 152.873 12.0873 152.557 12.0073C152.235 11.9273 151.949 11.7964 151.699 11.6145C151.443 11.4327 151.237 11.2 151.076 10.9164C150.915 10.6327 150.834 10.2836 150.834 9.88364C150.834 9.30182 150.981 8.84364 151.281 8.50182C151.582 8.16 151.949 7.90546 152.381 7.73818C152.814 7.57091 153.283 7.46182 153.775 7.41091C154.266 7.36 154.699 7.33818 155.073 7.33818H156.231V6.88C156.231 6.22545 156.026 5.75273 155.615 5.46909C155.205 5.18545 154.691 5.04 154.075 5.04C153.188 5.04 152.411 5.32364 151.743 5.89091L151.164 5.21455C151.531 4.86545 151.978 4.59636 152.521 4.41455C153.063 4.23273 153.584 4.14545 154.075 4.14545C154.999 4.14545 155.74 4.36364 156.297 4.79273C156.847 5.22182 157.126 5.92 157.126 6.88727V8.74909H157.133ZM155.146 8.12364C154.728 8.12364 154.317 8.15273 153.929 8.20364C153.533 8.26182 153.181 8.34909 152.865 8.48C152.55 8.61091 152.301 8.78545 152.11 9.00364C151.919 9.22182 151.831 9.49818 151.831 9.82545C151.831 10.0582 151.883 10.2618 151.985 10.4436C152.088 10.6255 152.227 10.7709 152.389 10.8873C152.55 11.0036 152.741 11.0909 152.946 11.1491C153.151 11.2073 153.357 11.2364 153.569 11.2364C154.097 11.2364 154.537 11.1564 154.875 11.0036C155.219 10.8509 155.491 10.6545 155.696 10.4073C155.901 10.16 156.041 9.88364 156.121 9.57091C156.202 9.25818 156.239 8.94545 156.239 8.62545V8.11636H155.146V8.12364Z'
        fill='white'
      />
      <path
        d='M159.781 6.48727C159.781 6.34182 159.773 6.17454 159.766 5.97091C159.751 5.76727 159.744 5.56363 159.744 5.35273C159.737 5.14182 159.729 4.95273 159.722 4.77091C159.707 4.58909 159.707 4.45091 159.707 4.34909H160.705C160.719 4.63273 160.727 4.90909 160.727 5.17091C160.734 5.43273 160.749 5.6 160.771 5.68H160.822C161.02 5.26545 161.35 4.90182 161.812 4.60364C162.274 4.30545 162.817 4.15273 163.433 4.15273C164.027 4.15273 164.518 4.24727 164.892 4.42909C165.266 4.61818 165.559 4.85818 165.779 5.15636C165.992 5.45454 166.146 5.80363 166.227 6.19636C166.307 6.58909 166.351 6.99636 166.351 7.41091V11.9273H165.354V7.46182C165.354 7.15636 165.325 6.85818 165.273 6.56727C165.222 6.27636 165.119 6.01454 164.987 5.78909C164.848 5.55636 164.657 5.37454 164.408 5.23636C164.159 5.09818 163.836 5.03273 163.44 5.03273C163.073 5.03273 162.729 5.09818 162.406 5.22182C162.076 5.34545 161.797 5.54182 161.563 5.79636C161.328 6.05091 161.137 6.37818 160.998 6.76363C160.859 7.14909 160.793 7.60727 160.793 8.13818V11.9345H159.795V6.48727H159.781Z'
        fill='white'
      />
      <path
        d='M172.731 5.23636H170.546V10.1818C170.546 10.5236 170.641 10.7855 170.825 10.9673C171.015 11.1564 171.272 11.2436 171.602 11.2436C171.793 11.2436 171.983 11.2218 172.174 11.1709C172.365 11.12 172.555 11.0618 172.731 10.9818L172.812 11.8691C172.621 11.9345 172.409 11.9927 172.167 12.0509C171.925 12.1018 171.705 12.1309 171.521 12.1309C171.169 12.1309 170.861 12.08 170.612 11.9782C170.355 11.8764 170.15 11.7382 169.996 11.5564C169.842 11.3818 169.725 11.1782 169.659 10.9382C169.585 10.7055 169.549 10.4509 169.549 10.1745V5.22909H167.891V4.34182H169.549V2.16H170.546V4.34182H172.731V5.23636Z'
        fill='white'
      />
    </g>
    <defs />
  </svg>
);

export default MonerisLogo;
