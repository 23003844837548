import React, { useState, useEffect, useContext } from "react";
import { Grid, Button, makeStyles } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import VerticalTabs from "../../../components/verticalTabs";
import {
  fetchAllGroupsData,
  fetchAllKitchenStocks,
  fetchAllStocks,
  fetchDepartments,
} from "../../../redux/actions";
import SimpleTable from "../../../components/SimpleTable";
import {
  AvenirBlackH4,
  AvenirBlackH3,
  AvenirBlackH7,
} from "../../../utils/text";
import MyContext from "../../../Provider/MyContext";
import DownlodIcon from "../../../components/SVG/DownlodeIcon";
import ContextMenu from "../../../components/ContextMenu";
import styles from "../../../styles/index";
import { ConvertCurrencyPriceToDollar } from "../../../utils/util";
import SnackBar from "../../../components/SnackBar";
import { DownloadKitchen } from "../../../utils/excel/downloadKitchen";
import UpdateKitchens from "./updateKitchens";

const useStyles = makeStyles({
  mainDiv: {
    border: "1px solid #f4f4f5",
    marginLeft: "1%",
  },
  itemSalesText: {
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "20px",
    height: "50px",
    paddingTop: "10px",
    paddingLeft: "1%",
  },
  tableHeading: {
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "18px",
    height: "50px",
    paddingTop: "10px",
    paddingLeft: "1%",
    backgroundColor: "#f4f4f5",
    marginTop: "10px",
  },
  btnItems: {
    backgroundColor: "#f0585f",
    color: "white",
    marginRight: "20px",
  },
  btnDownload: {
    backgroundColor: "#f0585f",
    color: "white",
    marginLeft: "10px",
  },
});
const InventoryKitchen = () => {
  const style = styles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectIndexTabChange, setSelectIndexTabChange] = useState(0);
  const [kitchenCost, setKitchenCost] = useState(0);
  const kitchenName = useSelector((state) => state.inventory.departments);
  const kitchenStocks = useSelector((state) => state.inventory.kitchenStocks);
  const [tabs, setTabs] = useState([]);
  const [table, setTable] = useState([]);
  const Context = useContext(MyContext);
  const [snackbar, setSnackbar] = useState(null);
  const [showUpdateKitchens, setShowUpdateKitchen] = useState(false);
  const [columns, setColumns] = useState([
    { field: "name", display: Context.langData.name, size: "30%", sort: false },
    {
      field: "currentBalance",
      display: Context.langData.balance,
      size: "15%",
      sort: false,
    },
    {
      field: "unitTypeCode",
      display: Context.langData.units,
      size: "15%",
      sort: false,
    },
    {
      field: "lastReceived",
      display: Context.langData.last_received,
      size: "20%",
      sort: false,
    },
    {
      field: "lastAdjusted",
      display: Context.langData.last_updated,
      size: "20%",
      sort: false,
    },
  ]);
  const ContextMenuData = [
    {
      id: 1,
      icon: DownlodIcon,
      name: Context.langData.download,
    },
    // {
    //     id: 3,
    //     icon: DeleteIcon,
    //     name: Context.langData.delete
    // }
  ];
  useEffect(() => {
    dispatch(fetchAllGroupsData());
    dispatch(fetchDepartments());
    dispatch(fetchAllStocks());
  }, []);
  useEffect(() => {
    if (kitchenStocks?.stocks?.length > 0) {
      setTable(kitchenStocks.stocks);
      setKitchenCost(kitchenStocks.value);
    } else {
      setTable([]);
      setKitchenCost(0);
    }
  }, [kitchenStocks]);
  useEffect(() => {
    const listArray = kitchenName.map((item) => ({
      name: item.name,
      id: item.id,
    }));
    setTabs(listArray);
    if (kitchenName[selectIndexTabChange]) {
      dispatch(fetchAllKitchenStocks(kitchenName[selectIndexTabChange].id));
    }
  }, [kitchenName]);
  const callback = (value) => {
    setShowUpdateKitchen(false);
    setSelectIndexTabChange(value);
    dispatch(fetchAllKitchenStocks(kitchenName[value]?.id));
  };

  const sortingOnClick = (value, sortValue, index) => {
    console.log("sortingOnClick");
    const columnData = [...columns];
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setColumns(columnData);

    const sortKitchensData = _.orderBy(
      table,
      [
        (d) =>
          typeof d[value] === "string" ? d[value].toLowerCase() : d[value],
      ],
      [sortValue ? "desc" : "asc"]
    );
    setTable(sortKitchensData);
  };
  const contextMenuCallBack = (id) => {
    if (id == 1) {
      DownloadKitchen(table);
    }
  };

  const handleClickAdjustBalance = () => {
    setShowUpdateKitchen(true);
  };

  return (
    <Grid data-qaid='kitchen_mainContainer' container>
      <Grid
        data-qaid='kitchen_subContainer1'
        container
        style={{ marginTop: "20px", marginLeft: 15 }}>
        <Grid
          data-qaid='kitchen_verticalTabs'
          item
          xs={12}
          lg={2}
          style={{ backgroundColor: "#FAFAFA" }}>
          <VerticalTabs tabData={tabs} indexCallback={callback} />
        </Grid>
        <Grid
          data-qaid='kitchen_subContainer2'
          item
          xs={12}
          lg={10}
          style={{ backgroundColor: "white" }}>
          <Grid
            data-qaid='kitchen_kitchenCostingLabelView'
            item
            style={{
              flexDirection: "row",
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              margin: 20,
            }}>
            <Grid>
              <AvenirBlackH7
                data-qaid='kitchen_kitchenCostingLabel'
                label={`$${ConvertCurrencyPriceToDollar(kitchenCost)}`}
                style={{ fontWeight: "bold" }}
              />
              <AvenirBlackH3
                data-qaid='kitchen_kitchenCostingLabel'
                label={Context.langData.kitchen_costing}
                style={{ color: "#54575A" }}
              />
            </Grid>
            <Grid
              data-qaid='kitchen_AdjustBalanceContainer'
              style={{
                alignItems: "center",
                marginTop: 10,
                flexDirection: "row",
                display: "flex",
              }}>
              <Button
                className={style.buttonClass}
                data-qaid='kitchen_AdjustBalanceButton'
                style={{
                  backgroundColor: "#FFB600",
                  color: "white",
                  marginRight: 20,
                }}
                variant='contained'
                id='cancel'
                onClick={() => {
                  handleClickAdjustBalance();
                }}>
                <AvenirBlackH4
                  data-qaid='kitchen_AdjustBalanceLabel'
                  label={Context.langData.Adjust_balance}
                />
              </Button>
              <ContextMenu
                style={{ marginLeft: 0, marginBottom: 20 }}
                menuItems={ContextMenuData}
                callBack={contextMenuCallBack}
              />
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: 50, marginLeft: 20, marginRight: 20 }}>
            <SimpleTable
              columns={columns}
              rows={table}
              sortingOnClick={sortingOnClick}
              isOnClick={false}
              isEditArrow={false}
            />
          </Grid>
        </Grid>
      </Grid>
      {showUpdateKitchens && (
        <UpdateKitchens
          open={showUpdateKitchens}
          onClose={() => setShowUpdateKitchen(false)}
          kitchen_id={kitchenName[selectIndexTabChange]?.id}
          callback={() => {
            callback(selectIndexTabChange);
          }}
        />
      )}
      {snackbar && (
        <SnackBar
          data-qaid='categories_snackbar'
          open
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
    </Grid>
  );
};

export default InventoryKitchen;
