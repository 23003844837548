import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Overlay = ({ open }) => {
  const classes = useStyles();

  return (
    <Backdrop
      data-qaid='overlay_backdrop'
      className={classes.backdrop}
      open={open}>
      <CircularProgress data-qaid='overlay_circularprogress' color='inherit' />
    </Backdrop>
  );
};
export default Overlay;
