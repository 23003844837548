import React from "react";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import styles from "../styles";

const RightDrawer = ({
  open,
  onClose,
  children,
  padding = 20,
  onCloseOutside,
}) => {
  const style = styles({ padding });
  return (
    <Drawer
      data-qaid='rightdrawer_drawer'
      anchor='right'
      open={open}
      onClose={onClose}
      className={clsx(
        style.paddingHorizontal,
        style.paddingTop50,
        style.minWidth
      )}
      classes={{
        paper: clsx(
          style.paddingHorizontal,
          style.paddingTop50,
          style.minWidth
        ),
      }}
      onBackdropClick={onClose}>
      <>
        {/* <TopBar /> */}
        {children}
      </>
    </Drawer>
  );
};

export default RightDrawer;
