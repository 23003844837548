import React, { useEffect, useState, useContext } from "react";
import { Grid, Button, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import $ from "jquery";
import { fetchSpecificIndent } from "../../../../redux/actions";
import RightDrawer from "../../../../components/RightDrawer";
import MyContext from "../../../../Provider/MyContext";
import InventoryIcon from "../../../../components/SVG/InventoryIcon";
import CircleClose from "../../../../components/SVG/CircleClose";
import {
  AvenirBlackH4,
  H4,
  H6,
  AvenirBlackH3,
  DotText,
} from "../../../../utils/text";
import { ConvertPrice } from "../../../../utils/util";
import styles from "../../../../styles/index";

const useStyles = makeStyles((theme) => ({
  signatureClass: {
    display: "none",
  },
}));

const PrintIndent = ({ open, onClose, row, callback }) => {
  const style = styles();
  const classes = useStyles();
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  const selectedRestaurant = useSelector(
    (state) => state.loginReducer.selectedRestaurant
  );
  const indentViewData = useSelector((state) => state.inventory.indentViewdata);
  const [data, setData] = useState({});
  useEffect(() => {
    // dispatch(fetchAllIndentView({ indent_id: row.indent_id }));
    dispatch(fetchSpecificIndent(row.indent_id));
  }, []);
  useEffect(() => {
    if (indentViewData) {
      const obj = { ...indentViewData, balance: 0, total_tax: 0 };
      setData(indentViewData);
    }
  }, [indentViewData]);

  const totalAmount = () => {
    let total = 0;
    if (indentViewData?.fulfillments?.length > 0) {
      indentViewData.fulfillments.map((f) => {
        if (f.totalAmount !== null) {
          total += f.totalAmount.amount;
        }
      });
    }
    return total / 100;
  };
  const amountPaid = () => {
    let total = 0;
    if (indentViewData?.fulfillments?.length > 0) {
      indentViewData.fulfillments.map((f) => {
        if (f.amountPaid !== null) {
          total += f.amountPaid.amount;
        }
      });
    }
    return total / 100;
  };

  const prefixDollar = (value) => `$${value}`;

  const getReceivedQty = (itemId) => {
    let totalQty = 0;
    if (indentViewData.fulfillments != null) {
      indentViewData.fulfillments.map((f, index) => {
        if (f.items != null) {
          f.items.map((i, index) => {
            if (i.id == itemId) {
              totalQty += i.fulfilledQuantity;
            }
          });
        }
      });
    }
    return totalQty;
  };

  function printHandler() {
    console.log($(".modal-content").html());
    const w = window.open(null, "Print_Page", "scrollbars=yes");
    const right =
      " body{padding: 60px 50px 0px 50px}.right{float:right;text-align:right} .left{float:left}  .col,.row{width:100%;padding:5px 0px} .indent-modal{height:150px}";
    const length =
      " .l1{width:8.333%;display: inline-block;} .l2{width:16.667%;display: inline-block;} .l3{width:25%;display: inline-block;} .l4{width:33.333%;display: inline-block;} .l6{width:50%} .l8{width:66.667%} .l10{width:83.333%} .l12{width:100%}";
    const list =
      " .items_list_view{width:90%;margin: 0px 20px} .list_header{font-weight:bold;border: 1px solid;padding:3px 10px} .list_row{padding:3px 10px;border-bottom:1px solid;border-left:1px solid;border-right:1px solid;} .signature{margin-top:75px}";
    const title =
      " .title{width:100%;text-align:center;font-weight:bold;font-size:18px}";
    const style = right + length + list + title;
    w.document.write("<html><head>");
    w.document.write("<title>Print Indent</title>");
    w.document.write(`<style>${style}</style>`);
    w.document.write(
      `</head><body><div class='modal-content'><div class='title'>${
        selectedRestaurant?.name
      }</div>`
    );
    w.document.write($(".modal-content").html());
    w.document.write("</div></body></html>");
    w.document.close();
    w.print();
    onClose();
  }
  return (
    <RightDrawer open={open} padding={1}>
      <>
        <Grid
          data-qaid='viewIndentId_body_grid'
          style={{ display: "flex", height: "100%", flexDirection: "column" }}>
          <Grid
            data-qaid='viewIndentId_child1_grid'
            style={{
              display: "flex",
              height: "85%",
              flexDirection: "column",
              overflowX: "auto",
            }}>
            <Grid
              data-qaid='viewIndentId_close_grid'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}>
              <CircleClose
                data-qaid='viewIndentId_closeicon'
                size={20}
                onClick={onClose}
                style={{ marginRight: 40, marginTop: 20 }}
              />
            </Grid>
            <Grid
              data-qaid='viewIndentId_settings_grid'
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 56,
                marginBottom: 10,
              }}>
              <InventoryIcon
                data-qaid='viewIndentId_setting_icon'
                style={{ marginRight: 10 }}
                width={22.5}
                height={24}
                color='#707272'
              />
              <H4
                data-qaid='viewIndentId_settings'
                label={Context.langData.inventory}
                style={{ color: "#707272" }}
              />
            </Grid>
            <H6
              data-qaid='viewIndentId_title'
              style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
              label={Context.langData.print_indent_info}
            />
            <AvenirBlackH3
              data-qaid='transaction_title'
              label={Context.langData.transaction_details}
              style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
            />

            <Grid
              style={{
                borderBottom: "1px solid #EEEEEE",
                marginLeft: 56,
                marginBottom: 20,
                marginRight: 56,
              }}
            />
            <Grid
              data-qaid='viewIndentId_main_grid'
              style={{
                marginLeft: 56,
                marginBottom: 20,
                marginRight: 56,
                width: 500,
                display: "flex",
                flexDirection: "column",
              }}
              className='modal-content'>
              <Grid
                data-qaid='viewIndentId_vendorContainer'
                container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}>
                <Grid
                  data-qaid='viewIndentId_vendorGrid'
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "50%",
                  }}>
                  <DotText
                    data-qaid='rviewIndentId_vendorLabel'
                    label={`${Context.langData.vendor}: ${indentViewData.vendorName}`}
                    style={{ marginBottom: 10 }}
                  />
                </Grid>
                <Grid
                  data-qaid='viewIndentId_vendorGrid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "50%",
                  }}>
                  <H4
                    data-qaid='viewIndentId_dateLabel'
                    label={`${Context.langData.date_created}: ${moment(indentViewData.raisedDate).format("MMM DD, YYYY")}`}
                    style={{ marginBottom: 10 }}
                  />
                </Grid>
              </Grid>
              <Grid
                data-qaid='viewIndentId_vendorContainer'
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}>
                <Grid
                  data-qaid='viewIndentId_vendorGrid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "50%",
                  }}>
                  <H4
                    data-qaid='rviewIndentId_TotalAmountLabel'
                    label={`${Context.langData.total_amount}: ${prefixDollar(ConvertPrice(totalAmount()))}`}
                    style={{ marginBottom: 10 }}
                  />
                </Grid>
                <Grid
                  data-qaid='viewIndentId_vendorGrid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "50%",
                  }}>
                  <H4
                    data-qaid='viewIndentId_StatusLabel'
                    label={`${Context.langData.status}: ${indentViewData.status}`}
                    style={{ marginBottom: 10 }}
                  />
                </Grid>
              </Grid>
              <Grid
                data-qaid='viewIndentId_vendorContainer'
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}>
                <Grid
                  data-qaid='viewIndentId_vendorGrid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "50%",
                  }}>
                  <H4
                    data-qaid='rviewIndentId_AmountPaidLabel'
                    label={`${Context.langData.paid_amount}: ${prefixDollar(ConvertPrice(amountPaid()))}`}
                    style={{ marginBottom: 10 }}
                  />
                </Grid>
                <Grid
                  data-qaid='viewIndentId_vendorGrid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "50%",
                  }}>
                  <H4
                    data-qaid='rviewIndentId_BalanceLabel'
                    label={`${Context.langData.balance_amount}: ${prefixDollar(ConvertPrice(totalAmount() - amountPaid()))}`}
                    style={{ marginBottom: 10 }}
                  />
                </Grid>
              </Grid>

              <Grid
                data-qaid='viewIndentId_table_body'
                item
                style={{
                  marginTop: "20px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}>
                <Grid
                  data-qaid='viewIndentId_subContainer3'
                  item
                  style={{
                    height: "40px",
                    width: "100%",
                    backgroundColor: "#F3FAFD",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}>
                  <Grid
                    data-qaid='viewIndentId_dateGrid'
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "40%",
                    }}>
                    <AvenirBlackH4
                      data-qaid='viewIndentId_itemHeading'
                      label={Context.langData.items}
                      style={{ marginLeft: 20 }}
                    />
                  </Grid>
                  <Grid
                    data-qaid='viewIndentId_dateGrid'
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "30%",
                    }}>
                    <AvenirBlackH4
                      data-qaid='viewIndentId_request_qtyHeading'
                      label={Context.langData.request_qty}
                    />
                  </Grid>
                  <Grid
                    data-qaid='viewIndentId_dateGrid'
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "30%",
                    }}>
                    <AvenirBlackH4
                      data-qaid='viewIndentId_request_qtyHeading'
                      label={Context.langData.recieved_qty}
                    />
                  </Grid>
                </Grid>
                {indentViewData?.items?.length > 0
                  ? indentViewData.items.map((itemObj, index) => (
                      <Grid
                        container
                        spacing={2}
                        key={itemObj.id}
                        data-qaid='raiseIndent_stockNameContainer'
                        item
                        style={{
                          marginTop: "10px",
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          backgroundColor: "#FAFAFA",
                          marginLeft: 0,
                        }}>
                        <Grid
                          item
                          xs
                          data-qaid='viewIndentId_dateGrid'
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            width: "40%",
                          }}>
                          <H4
                            data-qaid='viewIndentId_itemHeading'
                            label={`${itemObj.name}`}
                            style={{ marginLeft: 0 }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs
                          data-qaid='viewIndentId_dateGrid'
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            width: "30%",
                          }}>
                          <H4
                            data-qaid='viewIndentId_request_qtyHeading'
                            label={`${itemObj.requestedQuantity} ${itemObj.unitTypeCode}`}
                            style={{ marginLeft: 20 }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs
                          data-qaid='viewIndentId_dateGrid'
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            width: "30%",
                          }}>
                          <H4
                            data-qaid='viewIndentId_request_qtyHeading'
                            label={`${getReceivedQty(itemObj.id)} ${itemObj.unitTypeCode}`}
                            style={{ marginLeft: 10 }}
                          />
                        </Grid>
                      </Grid>
                    ))
                  : null}
              </Grid>
              <Grid>
                <AvenirBlackH3
                  data-qaid='transaction_title'
                  label={Context.langData.fulfillments}
                  style={{
                    marginLeft: 0,
                    marginTop: 30,
                    marginBottom: 20,
                    marginRight: 0,
                  }}
                />
                <Grid
                  style={{
                    borderBottom: "1px solid #EEEEEE",
                    marginLeft: 0,
                    marginBottom: 20,
                    marginRight: 0,
                  }}
                />
                <Grid
                  data-qaid='viewIndentId_subContainer4'
                  item
                  style={{
                    height: "40px",
                    width: "100%",
                    backgroundColor: "#F3FAFD",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: 500,
                    marginLeft: 0,
                  }}>
                  <Grid
                    data-qaid='viewIndentId_dateGrid'
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "20%",
                    }}>
                    <AvenirBlackH4
                      data-qaid='viewIndentId_dateHeading'
                      label={Context.langData.date_received}
                    />
                  </Grid>
                  <Grid
                    data-qaid='viewIndentId_dateGrid'
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "20%",
                    }}>
                    <AvenirBlackH4
                      data-qaid='viewIndentId_request_fulfillmentNoHeading'
                      label={Context.langData.fulfillment_no}
                    />
                  </Grid>
                  <Grid
                    data-qaid='viewIndentId_dateGrid'
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "20%",
                    }}>
                    <AvenirBlackH4
                      data-qaid='viewIndentId_request_billNoHeading'
                      label={Context.langData.bill_no}
                    />
                  </Grid>
                  <Grid
                    data-qaid='viewIndentId_dateGrid'
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "20%",
                    }}>
                    <AvenirBlackH4
                      data-qaid='viewIndentId_request_amountPaidHeading'
                      label={Context.langData.total_amount}
                    />
                  </Grid>
                  <Grid
                    data-qaid='viewIndentId_dateGrid'
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "20%",
                    }}>
                    <AvenirBlackH4
                      data-qaid='viewIndentId_request_balanceHeading'
                      label={Context.langData.amount_paid}
                    />
                  </Grid>
                </Grid>
                <Grid>
                  {indentViewData?.fulfillments?.length > 0
                    ? indentViewData.fulfillments.map((fulfillment) => {
                        const fulfillmentWithRequestQty = { ...fulfillment };
                        fulfillmentWithRequestQty?.items?.map((fItem) => {
                          // find the request quantity from indentViewData
                          for (
                            let i = 0;
                            i < indentViewData?.items?.length;
                            i++
                          ) {
                            if (indentViewData.items[i].id == fItem.id) {
                              fItem.requestedQuantity =
                                indentViewData.items[i].requestedQuantity;
                              break;
                            }
                          }
                        });
                        return (
                          <Grid
                            container
                            spacing={2}
                            key={fulfillment.id}
                            data-qaid='raiseIndent_stockNameContainer'
                            item
                            style={{
                              maxHeight: 75,
                              marginTop: "10px",
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              backgroundColor: "#FAFAFA",
                              marginLeft: 0,
                            }}>
                            <Grid
                              item
                              xs
                              data-qaid='viewIndentId_dateGrid'
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                width: "20%",
                              }}>
                              <H4
                                data-qaid='viewIndentId_dateHeading'
                                label={moment(fulfillment.dateReceived).format(
                                  "YYYY-MM-DD"
                                )}
                              />
                            </Grid>
                            <Grid
                              item
                              xs
                              data-qaid='viewIndentId_dateGrid'
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                width: "20%",
                              }}>
                              <H4
                                data-qaid='viewIndentId_dateLabel'
                                label={fulfillment.id}
                              />
                            </Grid>
                            <Grid
                              item
                              xs
                              data-qaid='viewIndentId_dateGrid'
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                width: "20%",
                              }}>
                              <H4
                                data-qaid='viewIndentId_dateLabel'
                                label={fulfillment.billNumber}
                              />
                            </Grid>
                            <Grid
                              item
                              xs
                              data-qaid='viewIndentId_dateGrid'
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                width: "20%",
                              }}>
                              <H4
                                data-qaid='viewIndentId_dateLabel'
                                label={
                                  fulfillment.totalAmount
                                    ? prefixDollar(
                                        ConvertPrice(
                                          fulfillment.totalAmount.amount / 100
                                        )
                                      )
                                    : 0
                                }
                                style={{ marginLeft: -40 }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              data-qaid='viewIndentId_dateGrid'
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                width: "20%",
                              }}>
                              <H4
                                data-qaid='viewIndentId_dateLabel'
                                label={
                                  fulfillment.amountPaid
                                    ? prefixDollar(
                                        ConvertPrice(
                                          fulfillment.amountPaid.amount / 100
                                        )
                                      )
                                    : 0
                                }
                                style={{ marginLeft: -40 }}
                              />
                            </Grid>
                          </Grid>
                        );
                      })
                    : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            data-qaid='createGroup_cancel_main_Grid'
            style={{
              display: "flex",
              height: "15%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid
              data-qaid='createGroup_cancelGrid'
              item
              style={{
                display: "flex",
                justifyContent: "flex-end",
                backgroundColor: "#FAFAFA",
                width: "100%",
                paddingTop: "25px",
                alignItems: "center",
                paddingBottom: "25px",
                border: "1px solid #C3C3C3",
              }}>
              <Button
                className={style.buttonClass}
                data-qaid='viewIndentId_repeatIndentButton'
                size='large'
                variant='contained'
                type='submit'
                style={{
                  backgroundColor: "#FFB600",
                  cursor: "pointer",
                  marginRight: 56,
                }}
                onClick={() => {
                  printHandler();
                }}>
                <AvenirBlackH4
                  data-qaid='createGroup_cancelLabel'
                  label={Context.langData.print}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    </RightDrawer>
  );
};
export default PrintIndent;
