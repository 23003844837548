import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import { AvenirBlackH4, AvenirBlackLightH3, H6 } from "../../../utils/text";
import RightDrawer from "../../../components/RightDrawer";
import styles from "../../../styles/index";
import MyContext from "../../../Provider/MyContext";

const ConfirmRefundModal = ({ open, callback, responseData }) => {
  const style = styles();
  const Context = useContext(MyContext);
  const refundObject = useSelector((state) => state.transactions.refundObj);
  const [refundResponseData, setRefundResponseData] = useState({});
  useEffect(() => {
    if (responseData?.refunds) {
      const cardList = [];
      const cashList = [];
      console.log("responseData==>", responseData);
      responseData.refunds?.map((refundObj) => {
        const paymnetName = refundObject?.payments.filter(
          (e) => e.ptype_id == refundObj.ptype_id
        );
        if (paymnetName.length > 0) {
          const obj = { ...refundObj };
          obj.paymnetName = paymnetName[0].ptype;
          obj.masked_number = paymnetName[0].masked_number
            ? `${paymnetName[0].masked_number.substr(-4)}`
            : "0000";
          obj.activate_gift_card = refundObj?.activate_gift_card;
          if (refundObj.activate_gift_card) {
            let gift_name = "guest";
            if (
              refundObject?.customers?.length > 0 &&
              refundObject?.customers?.length == 1
            ) {
              gift_name = refundObject.customers[0].customer_name;
            }
            obj.gift_name = gift_name;
          }
          if (
            paymnetName[0]?.ptype?.toLowerCase() == "cash" ||
            refundObj?.activate_gift_card
          ) {
            cashList.push(obj);
          } else {
            cardList.push(obj);
          }
        }
      });
      console.log("cardList===>", cardList, "cashList==>", cashList);
      setRefundResponseData({ card: cardList, cash: cashList });
    }
  }, [responseData]);
  return (
    <RightDrawer data-qaid='createcomment_centermodal' open={open} padding={1}>
      <>
        <Grid
          style={{ display: "flex", height: "100%", flexDirection: "column" }}>
          <Grid
            style={{
              display: "flex",
              height: "85%",
              flexDirection: "column",
              overflowX: "auto",
            }}>
            <Grid
              data-qaid='transactions_refund_main_grid'
              container
              direction='column'
              style={{ marginLeft: 49, marginRight: 49, width: 500 }}>
              <H6
                label={Context.langData.refund_confirmation}
                style={{ marginTop: 42, marginBottom: 32 }}
              />
            </Grid>
            <Grid
              data-qaid='addnotes_note_textinput_view'
              style={{
                width: "100%",
                paddingLeft: 48,
                paddingRight: 48,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                display: "flex",
                flexDirection: "column",
              }}>
              {refundResponseData?.card?.length > 0
                ? refundResponseData.card.map((refundResponse) => (
                    <AvenirBlackLightH3
                      label={`${Context.langData.refund_confirm_1} $${refundResponse.refund_amount} ${Context.langData.refund_confirm_2} ${refundResponse.paymnetName} ${Context.langData.ending} ${refundResponse.masked_number} . `}
                      style={{ color: "#000000", lineHeight: "24px" }}
                    />
                  ))
                : null}

              {refundResponseData?.cash?.length > 0 && (
                <AvenirBlackLightH3
                  label={`${Context.langData.action_required}`}
                  style={{
                    color: "#000000",
                    textDecorationLine: "underline",
                    marginTop: 10,
                  }}
                />
              )}
              {refundResponseData?.cash?.length > 0
                ? refundResponseData.cash.map((refundResponse) => (
                    <>
                      {refundResponse.activate_gift_card ? (
                        <AvenirBlackLightH3
                          label={`${Context.langData.activate_new_gift_card} $${refundResponse.refund_amount} ${Context.langData.activate_new_gift_card_2} ${refundResponse.gift_name || ""}`}
                          style={{ color: "#000000", marginTop: 10 }}
                        />
                      ) : (
                        <AvenirBlackLightH3
                          label={`${Context.langData.cash_refund_succes} $${refundResponse.refund_amount}`}
                          style={{ color: "#000000", marginTop: 10 }}
                        />
                      )}
                    </>
                  ))
                : null}
            </Grid>
          </Grid>
          <Grid
            style={{
              display: "flex",
              height: "15%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid
              data-qaid='transactions_refund_action_grid'
              container
              style={{ backgroundColor: "#FAFAFA" }}>
              <Grid
                data-qaid='transactions_refund_cancel_btn'
                item
                xs={12}
                lg={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 32,
                  marginBottom: 32,
                }}>
                <Button
                  className={style.buttonClass}
                  data-qaid='transactions_refund_save_btn'
                  variant='contained'
                  style={{ backgroundColor: "#FFB600", marginRight: 49 }}
                  onClick={() => {
                    callback(true);
                  }}>
                  <AvenirBlackH4
                    data-qaid='transactions_refund_save_text'
                    label={Context.langData.done}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    </RightDrawer>
  );
};

export default ConfirmRefundModal;
