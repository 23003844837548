import React, { useState, useEffect, useContext } from "react";
import { Grid, Button, MenuItem, Menu } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { fetchModifierSales } from "../../../redux/actions";
import Daterange from "../../../components/DateRange";
import { AvenirBlackH4, AvenirBlackH2, TBold } from "../../../utils/text";
import MyContext from "../../../Provider/MyContext";
import CircleClose from "../../../components/SVG/CircleClose";
import DownlodeIcon from "../../../components/SVG/DownlodeIcon";
import ReportsTable from "../../../components/ReportsTable";
import SingleSelect from "../../../components/SingleSelect";
import EmployeeDropDown from "../../../components/EmployeeDropDown";
import { downloadModifierSales } from "../../../utils/excel";
import { downloadModifierSalesPdfDocument } from "../../../components/PDFTableGenerator";

const ModifierSales = () => {
  const dispatch = useDispatch();
  const [table1, setTable1] = useState([]);
  const [columns, setColumns] = useState([
    { field: "item_name", display: "Item name", size: "25%" },
    { field: "addon_name", display: "Modifier name", size: "25%" },
    { field: "total_quantity", display: "Quantity", size: "25%" },
    {
      field: "total_sales",
      display: "Total sales",
      size: "25%",
      isNumber: true,
    },
  ]);

  const modifierSales = useSelector(
    (state) => state.salesReportReducer.modifierSales
  );
  const Context = useContext(MyContext);
  const [date, setDate] = useState({
    startDate: moment().startOf("day").format("YYYY-MM-DD"),
    endDate: moment().endOf("day").format("YYYY-MM-DD"),
  });
  const [itemtypes, setItemTypes] = useState(2);
  const [empData, setEmpData] = useState([]);
  const [resetDate, toggleResetDate] = useState(false);
  const itemsDropDownList = [
    {
      id: 2,
      name: Context.langData.all_items,
    },
    {
      id: 1,
      name: Context.langData.sold_items,
    },
    {
      id: 0,
      name: Context.langData.unsold_items,
    },
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const onDateChange = (d) => {
    if (JSON.stringify(date) !== JSON.stringify(d)) {
      setDate(d);
    }
  };
  useEffect(() => {
    handleApplyDropdown();
  }, [date, itemtypes, empData]);
  const handleApplyDropdown = () => {
    let obj = {
      from_date: date.startDate,
      to_date: date.endDate,
    };
    if (empData.length > 0) {
      obj = {
        ...obj,
        employee_id: empData.join(","),
      };
    }
    if (itemtypes == 2) {
    } else {
      obj = {
        ...obj,
        sold: itemtypes,
      };
    }
    dispatch(fetchModifierSales(obj));
  };
  useEffect(() => {
    setTable1(modifierSales.report);
  }, [modifierSales]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSort = (name, desc, index, catIndx) => {
    const columnData = [...columns];
    columnData[index] = { ...columnData[index], desc };
    setColumns(columnData);
    const catItems = table1[catIndx].addons;
    let sortModifierwiseSales;
    if (name == "item_name" || name == "addon_name") {
      sortModifierwiseSales = _.orderBy(
        catItems,
        [(catItem) => catItem[name].toLowerCase()],
        [desc ? "desc" : "asc"]
      );
    } else {
      sortModifierwiseSales = _.orderBy(
        catItems,
        [name],
        [desc ? "desc" : "asc"]
      );
    }
    table1[catIndx].addons = sortModifierwiseSales;
    setTable1(table1);
  };

  const downloadExcel = () => {
    downloadModifierSales(table1);
    handleCloseMenu();
  };

  const downloadPDF = () => {
    downloadModifierSalesPdfDocument(
      columns,
      table1,
      Context.langData.modifier_sales,
      date,
      "ModifierSales"
    );
    handleCloseMenu();
  };
  const onClear = () => {
    setItemTypes(2);
    setEmpData([]);
    toggleResetDate(true);
    setTimeout(() => {
      toggleResetDate(false);
    }, 200);
  };
  return (
    <Grid
      data-qaid='modifierSales_mainContainer'
      container
      style={{ paddingLeft: 20, paddingRight: 20 }}>
      <Grid data-qaid='modifierSales_subContainer' container>
        <Grid
          data-qaid='modifierSales_dateContainer'
          item
          lg={9}
          style={{
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
          }}>
          <Grid data-qaid='modifierSales_dateGrid' item style={{}}>
            <AvenirBlackH4
              data-qaid='modifierSales_dateLabel'
              label={Context.langData.from_to}
            />
            <Daterange
              data-qaid='modifierSales_dateRange'
              onDateChange={onDateChange}
              refresh={resetDate}
            />
          </Grid>
          <Grid
            data-qaid='modifierSales_orderTypeGrid'
            item
            style={{ paddingLeft: 10 }}>
            <AvenirBlackH4
              data-qaid='modifierSales_orderTypeLabel'
              label={Context.langData.item_types}
            />
            <SingleSelect
              data-qaid='modifierSales_ordertype_singleselect'
              list={itemsDropDownList}
              value={itemtypes}
              valueKey='id'
              displayKey='name'
              handleChange={(e) => {
                setItemTypes(e.target.value);
              }}
              border={false}
              height={56}
              width={220}
            />
          </Grid>
          <EmployeeDropDown
            selectedValue={empData}
            handleChange={setEmpData}
            lg={5}
          />
        </Grid>
        <Grid
          data-qaid='modifierSales_circleCloseContainer'
          item
          lg={3}
          style={{
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 10,
          }}>
          <Grid
            data-qaid='modifierSales_circleCloseGrid'
            onClick={onClear}
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: 20,
              alignItems: "center",
              cursor: "pointer",
            }}>
            <CircleClose data-qaid='modifierSales_circleCloseIcon' />
            <AvenirBlackH4
              data-qaid='modifierSales_circleCloseLabel'
              label={Context.langData.clear}
              style={{ marginLeft: 5 }}
            />
          </Grid>
          <Button
            onClick={(event) => setAnchorEl(event.currentTarget)}
            data-qaid='modifierSales_downlodeButtonGrid'
            style={{
              backgroundColor: "#FFB600",
              height: 40,
              marginRight: 0,
              width: "149px",
            }}
            variant='contained'
            id='cancel'>
            <DownlodeIcon
              data-qaid='modifierSales_downlodeButtonIcon'
              style={{ marginRight: 10 }}
            />
            <AvenirBlackH4
              data-qaid='modifierSales_downlodeLabel'
              label={Context.langData.download}
            />
          </Button>
          <Menu
            id='simple-menu-download'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}>
            <MenuItem onClick={downloadExcel}>
              {Context.langData.export_excel}
            </MenuItem>
            <MenuItem onClick={downloadPDF}>
              {Context.langData.export_pdf}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>

      <Grid
        data-qaid='modifierSales_reportsTableGrid'
        item
        lg={12}
        style={{ marginTop: 40 }}>
        {table1?.length > 0 ? (
          table1?.map((menu, index) => (
            <Grid key={index}>
              <Grid
                data-qaid='modifierSales_HeadingLabelGrid'
                container
                style={{
                  marginTop: 40,
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}>
                <Grid item>
                  <AvenirBlackH2
                    data-qaid='modifierSales_HeadingLabel'
                    label={menu.addon_cat}
                    style={{
                      fontWeight: "bold",
                      marginLeft: 20,
                      marginBottom: 15,
                    }}
                  />
                </Grid>
              </Grid>
              <ReportsTable
                data-qaid='modifierSales_reportsTable'
                handleSort={(name, desc, colIndex) =>
                  handleSort(name, desc, colIndex, index)
                }
                columns={columns}
                rows={menu.addons}
              />
            </Grid>
          ))
        ) : (
          <Grid
            item
            lg={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              paddingTop: 20,
            }}>
            <TBold
              label='No result available'
              style={{ fontSize: 32, color: "#051D33" }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ModifierSales;
