const urls = {
  home: "/",
  sso: {
    login: "/auth/login?rest_id=:rest_id",
    loginRedirect: "/auth/login?rest_id=",
    logout: "/auth/logout",
  },
};

export default urls;
