import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const ConvertPrice = (price) => {
  if (isNaN(price)) {
    price = 0;
  }
  if (typeof price === "string") {
    price = parseFloat(price);
  }

  return Math.round(price * 100) / 100;
};
const getWookBook = () => {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = "MGA Merchant portal";
  return workbook;
};
const downloadAs = async (workbook, fileName = "sample.xlsx") => {
  const buffer = await workbook.xlsx.writeBuffer();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const blob = new Blob([buffer], { type: fileType });
  saveAs(blob, fileName);
};

const setHeaderStyle = (worksheet, rowNum) => {
  const row = worksheet.getRow(rowNum);
  row.eachCell((cell, colNumber) => {
    console.log(`Cell ${colNumber} = ${cell.value}`);
    cell.font = {
      color: { argb: "00000000" },
      size: 14,
      bold: true,
    };
    // cell.fill = {
    //   type: 'pattern',
    //   pattern: 'darkTrellis',
    //   bgColor: { argb: '00000000' }
    // }
  });
  row.height = 24;
  row.alignment = { vertical: "middle" };
};
const setNormalRowStyle = (worksheet, rowNum) => {
  const row = worksheet.getRow(rowNum);
  row.eachCell((cell, colNumber) => {
    cell.font = {
      color: { argb: "00000000" },
      size: 13,
      bold: false,
      // wrapText: true,
    };
    // cell.alignment = {
    //   vertical: 'middle',
    //   wrapText: true,
    //   flexWrap: 'wrap'
    // }
  });
  row.height = 20;
  row.alignment = { vertical: "middle" };
};
export {
  getWookBook,
  downloadAs,
  setHeaderStyle,
  setNormalRowStyle,
  ConvertPrice,
};
