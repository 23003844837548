import React, { useEffect, useState } from "react";
import { Grid, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Delete from "../../../components/SVG/Delete";
import CancelIcon from "../../../components/SVG/CloseCircle";
import UploadIcon from "../../../components/SVG/Upload";
import { H5, AvenirBlackH4, T } from "../../../utils/text";
import { fetchImageUrl, putImageUrl, saveLogo } from "../../../redux/actions";
import WebSiteTitle from "./websiteBuilderTitle";
// let img = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ83rbUoJtUNpdR_A7V2RV0VdFfpVgcKF4PHJflMhoe8op22ma9Tu23KA8xj1JWlMhPyso&usqp=CAU';
const RestLogoModal = ({ onClose, banner }) => {
  const [image, setImage] = useState(null);
  const [isChanged, toggleChange] = useState(false);
  const selectedRestaurant = useSelector(
    (state) => state.loginReducer.selectedRestaurant
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (banner) setImage(banner);
  }, [banner]);
  const isValidExtention = (type = "", ext = ".") => {
    console.log(type, ext);
    ext = ext.toLowerCase();
    type = type.toLowerCase();
    if (ext == "jpg" || ext == "jpeg") {
      if (type == "image/jpeg" || type == "image/jpg") {
        return true;
      }
      return false;
    }
    return type.indexOf(ext) !== -1;
  };
  const onUpload = (e) => {
    console.log(e);
    document.getElementById("err").innerHTML = "";
    const fileChoser = document.getElementById(`banner`);
    const { files } = fileChoser;
    if (files.length > 0) {
      const file = files[0];
      console.log("file", file);
      const fileName = file.name;
      const { type } = file;
      const sp = fileName.split(".");
      const ext = sp[sp.length - 1];
      if (isValidExtention(type, ext)) {
        const img = new Image();
        const value = URL.createObjectURL(files[0]);
        img.src = value;
        img.onload = function () {
          console.log("WIDTH", this.width, this.height, file.size);
          if (this.height <= 190 && file.size <= 100 * 1024) {
            const fileName = file.name.split(".");
            let imagename = `${
              fileName[0] ? fileName[0] : "RestLogo"
            }_${moment().unix().toString()}.png`;
            imagename = imagename.replace(/\s/g, "");
            dispatch(
              fetchImageUrl(
                {
                  file_name: imagename,
                  content_type: file.type,
                  image_loc: "online",
                },
                (response) => {
                  const obj = {};
                  const url = response.upload_url.split("?")[0];
                  obj.ContentType = file.type;
                  obj.Body = file;
                  dispatch(
                    putImageUrl(
                      response.upload_url,
                      obj,
                      (res) => {
                        setImage(url);
                        toggleChange(true);
                      },
                      response.storage == "azure"
                    )
                  );
                }
              )
            );
          } else {
            document.getElementById("err").innerHTML =
              "image dimensions are not supported";
          }
        };
      } else {
      }
      // uploadImageCallBack(file, item.item_id)
    }
  };
  const onSave = () => {
    dispatch(
      saveLogo({ url: image }, (status) => {
        dispatch({ type: "SET_LOGO", payload: image });
        onClose();
      })
    );
  };
  return (
    <Grid container style={{ maxWidth: 480, position: "relative" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: 10,
          width: "100%",
        }}>
        <CancelIcon
          style={{ float: "right", cursor: "pointer" }}
          onClick={onClose}
        />
      </div>
      <Grid container style={{ height: "90%", padding: 20 }}>
        <Grid item xs={12}>
          <WebSiteTitle />
        </Grid>
        <Grid item xs={12}>
          <span style={{ fontWeight: 900, fontSize: 18 }}>
            <H5 label='Logo' />
          </span>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <span style={{ fontWeight: 600, fontSize: 14 }}>
            <AvenirBlackH4 label='Logo Image' />
          </span>
        </Grid>
        <Grid item xs={12} style={{}}>
          <span style={{}}>
            <T label='File size must not be larger than 100 KB. Maximum logo dimensions are 190px x 190px (.png only)' />
          </span>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          {image ? (
            <div>
              <img src={image} style={{ width: 440, height: 208 }} alt='img' />
            </div>
          ) : (
            <div
              style={{
                width: 440,
                height: 208,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#EEEEEE",
              }}>
              IMG
            </div>
          )}
        </Grid>
        <Grid item xs={12} style={{ marginTop: 15 }}>
          <Grid container justify='flex-end'>
            <Grid item xs={7}>
              <Grid container justify='space-between'>
                <Button
                  style={{ color: "#004C60" }}
                  onClick={() => {
                    setImage(null);
                    toggleChange(true);
                  }}
                  startIcon={<Delete color='#004C60' />}>
                  Delete
                </Button>
                <input
                  accept='image/png, image/jpg, image/jpeg'
                  onChange={(e) => onUpload(e)}
                  id='banner'
                  style={{ display: "none" }}
                  type='file'
                />
                <label htmlFor='banner'>
                  <Button
                    component='span'
                    style={{ color: "#004C60" }}
                    startIcon={<UploadIcon />}>
                    Upload Image
                  </Button>
                </label>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          id='err'
          style={{ marginTop: 20, color: "#AB1212" }}
        />
      </Grid>
      <Grid
        container
        style={{
          borderTop: "1px solid #c3c3c3",
          position: "fixed",
          bottom: 0,
          width: "100%",
          padding: 40,
          backgroundColor: "#FAFAFA",
        }}>
        <Grid
          item
          xs={12}
          style={{
            position: "fixed",
            bottom: 20,
            alignSelf: "flex-end",
            right: 40,
          }}>
          <Grid container justify='flex-end'>
            <Button
              variant='contained'
              disabled={!isChanged}
              onClick={onSave}
              style={{ backgroundColor: "#FFB600", width: 90, height: 40 }}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RestLogoModal;
