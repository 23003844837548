import React, { useEffect, useState, useContext } from "react";
import {
  Dialog,
  Slide,
  makeStyles,
  IconButton,
  Grid,
  Paper,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import CloseCircle from "../../../components/SVG/CloseCircle";
import Tablet from "../../../components/SVG/Tablet";
import Mobile from "../../../components/SVG/Mobile";
import LandScape from "../../../components/SVG/Landscape";
import Portrait from "../../../components/SVG/Portrait";
import MyContext from "../../../Provider/MyContext";
import TopBar from "../../../layouts/DashboardLayout/TopBar";
import { H6 } from "../../../utils/text";
import Pax from "../../../components/SVG/Pax";
import TabletFrame from "../../../components/SVG/TabletFrame";
import Arrow from "../../../components/SVG/Arrow";
import DragAndResize from "./DragAndResize";
import { SvgIcon } from "./Icons";

const arrowTop = 580;
const arrowLeft = 70;
const arrowGap = 40;
const scrollAmount = 30;
const useStyles = makeStyles(() => ({
  preview: {
    // backgroundColor: '#00000099',
    backgroundColor: "transparent",
    opacity: 1,
  },
  white: {
    color: "white",
    fontFamily: "AvenirLT",
  },
  previewMode: {
    fontWeight: 400,
    fontSize: 24,
  },
  device: {
    fontWeight: 400,
    fontSize: 14,
  },
  items: {
    // marginTop: 10,
    // marginBottom: 10,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  previewCard: {
    border: "15px solid black",
    borderRadius: 10,

    // borderWidth: 10,
    // borderColor: 'black',
    // BorderStyle: 'solid'
  },
}));
const top = 161;
const left = 28;
const Transition = React.forwardRef((props, ref) => (
  <Slide direction='up' ref={ref} {...props} />
));
const Preview = ({ open, handleClose }) => {
  const [previewSize, setPreviewSize] = useState({ width: 800, height: 600 });
  const [orientation, setOrientation] = useState("landscape");
  const [mode, setMode] = useState("tablet");
  const [classnames, setClassNames] = useState("floor solid");
  const Context = useContext(MyContext);
  const { workingData, floorDetails } = useSelector((state) => ({
    workingData: state.floorplan.workingData,
    floorDetails: state.floorplan.floorDetails,
  }));
  useEffect(() => {
    const width = 800;
    const { device, floor } = floorDetails;
    const { sizes } = device;

    const newSizes = {
      width,
      height: (width / sizes[0]) * sizes[1],
    };
    // if (orientation !== 'landscape') {
    //   newSizes = {
    //     width: newSizes.height,
    //     height: newSizes.width
    //   }
    // }
    if (floor) {
      if (floor.type === "solid") {
        setClassNames("floor solid");
      } else {
        const c = `floor ${floor.type}_${floor.orientation}`;
        setClassNames(c);
      }
    }
    setPreviewSize(newSizes);
  }, [floorDetails, orientation]);
  const getDimensions = (psizes) => {
    const { device } = floorDetails;
    const { sizes } = device;
    const { width } = psizes;
    return {
      width: psizes.width,
      height: psizes.height,
      ratio: width / 800,
    };
  };

  const styles = useStyles();

  const { width, height, ratio } = getDimensions(previewSize);
  console.log(previewSize, width, height);
  const rotate = orientation == "landscape" ? 0 : 90;
  const { body } = document;
  const html = document.documentElement;

  const hei = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
  console.log("heights", window.innerHeight, hei);
  useEffect(() => {
    window.removeEventListener("keydown", keyDown);
    if (mode == "tablet") {
      window.removeEventListener("keydown", keyDown);
    } else {
      window.addEventListener("keydown", keyDown);
    }

    return () => {
      window.removeEventListener("keydown", keyDown);
    };
  }, [mode]);
  const scrollMobile = (arrow) => {
    const paxScroll = document.getElementById("mobileFrame");
    const { scrollLeft, scrollTop } = paxScroll;

    if (arrow == "up") {
      // up arrow
      if (scrollTop >= scrollAmount) paxScroll.scrollTop -= scrollAmount;
      else if (scrollTop !== 0) paxScroll.scrollTop = 0;
    } else if (arrow == "down") {
      // down arrow
      if (scrollTop + 405 + scrollAmount <= 600)
        paxScroll.scrollTop += scrollAmount;
      else if (scrollTop !== 405) paxScroll.scrollTop = 405;
    } else if (arrow == "left") {
      // left arrow
      if (scrollLeft >= scrollAmount) paxScroll.scrollLeft -= scrollAmount;
      else if (scrollLeft !== 0) paxScroll.scrollLeft = 0;
    } else if (arrow == "right") {
      // right arrow
      if (scrollLeft + 232 + scrollAmount <= 800)
        paxScroll.scrollLeft += scrollAmount;
      else if (scrollLeft !== 232) paxScroll.scrollLeft = 232;
    }
  };
  const keyDown = (e) => {
    e = e || window.event;

    // const paxScroll = document.getElementById("mobileFrame");

    // const {
    //   scrollLeft,
    //   scrollTop
    // } = paxScroll
    // console.log({
    //   scrollLeft,
    //   scrollTop
    // })
    // paxScroll.scrollTo({ top: 10, behavior: 'smooth' });
    const arrows = {
      40: "down",
      37: "left",
      38: "up",
      39: "right",
    };
    // console.log("e.keyCode", e.keyCode, arrows[e.keyCode])
    if (arrows[e.keyCode]) {
      scrollMobile(arrows[e.keyCode]);
    }
    // if (e.keyCode == '38') {
    //   // up arrow
    //   if (scrollTop >= 20)
    //     paxScroll.scrollTop -= 20;
    // }

    // else if (e.keyCode == '40') {
    //   // down arrow
    //   if (scrollTop + 425 <= 600)
    //     paxScroll.scrollTop += 20;
    // }
    // else if (e.keyCode == '37') {
    //   // left arrow
    //   if (scrollLeft >= 20)
    //     paxScroll.scrollLeft -= 20;
    // }
    // else if (e.keyCode == '39') {
    //   // right arrow
    //   if (scrollLeft + 252 <= 800)
    //     paxScroll.scrollLeft += 20;
    // }
  };
  return (
    <Dialog
      classes={{ paper: styles.preview }}
      className='previewFrame'
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}>
      <Grid container id='previewFrame'>
        <TopBar onMobileNavOpen={() => console.log("No")} />

        <Grid
          container
          justify='center'
          style={{ paddingTop: 75, height: "100%" }}>
          <Grid container>
            <Grid item xs={11}>
              <Grid container justify='center'>
                <Grid container justify='center'>
                  <H6
                    style={{ color: "white" }}
                    label={Context.langData.preview_mode}
                  />
                </Grid>
                {mode == "tablet" ? (
                  <Paper
                    id='tabletFrame1x'
                    style={{
                      transform: `rotateZ(${rotate}deg)`,
                      position: "relative",
                      width: previewSize.width + 50,
                      height: previewSize.height + 50,
                      backgroundColor: "transparent",
                      marginTop: orientation == "landscape" ? 0 : 120,
                    }}>
                    <TabletFrame
                      width={previewSize.width + 50}
                      height={previewSize.height + 50}
                    />
                    <div
                      style={{
                        width: previewSize.width,
                        height: previewSize.height,
                        opacity: 1,
                        position: "absolute",
                        top: 25,
                        left: 25,
                        zIndex: 999,
                      }}>
                      <div
                        className={classnames}
                        style={{
                          width: previewSize.width,
                          height: previewSize.height,
                          position: "relative",
                          borderRadius: 20,
                        }}>
                        {workingData.map((item, index) => {
                          if (item.content) {
                            return (
                              <DragAndResize
                                item={item}
                                id={index}
                                key={item.id}
                                ratio={ratio}
                                move={false}
                                isRotate={orientation !== "landscape"}
                                newSize={{
                                  width: item.width || 100,
                                  height: item.height || 100,
                                }}
                              />
                            );
                          }
                          <div key={index} style={{ width: 0, height: 0 }} />;
                        })}
                      </div>
                    </div>
                  </Paper>
                ) : (
                  <Grid container justify='center'>
                    <Paper
                      id='tabletFrame1x'
                      // className={classnames}
                      style={{
                        position: "relative",
                        overflow: "auto",
                        position: "relative",
                        backgroundColor: "transparent",
                      }}>
                      <Pax />
                      <span
                        onClick={() => scrollMobile("left")}
                        style={{
                          position: "absolute",
                          zIndex: 99,
                          top: arrowTop,
                          left: arrowLeft,
                        }}>
                        <Arrow size={24} />
                      </span>
                      <span
                        onClick={() => scrollMobile("right")}
                        style={{
                          position: "absolute",
                          zIndex: 99,
                          top: arrowTop,
                          left: arrowLeft + arrowGap * 1,
                        }}>
                        <Arrow size={24} rotation={180} />
                      </span>
                      <span
                        onClick={() => scrollMobile("up")}
                        style={{
                          position: "absolute",
                          zIndex: 99,
                          top: arrowTop,
                          left: arrowLeft + arrowGap * 2,
                        }}>
                        <Arrow size={24} rotation={270} />
                      </span>
                      <span
                        onClick={() => scrollMobile("down")}
                        style={{
                          position: "absolute",
                          zIndex: 99,
                          top: arrowTop,
                          left: arrowLeft + arrowGap * 3,
                        }}>
                        <Arrow size={24} rotation={90} />
                      </span>
                      <div
                        id='mobileFrame'
                        style={{
                          overflow: "auto",
                          width: 232,
                          height: 405,
                          opacity: 1,
                          position: "absolute",
                          top,
                          left,
                          zIndex: 999,
                          backgroundColor: "white",
                        }}>
                        <div
                          className={classnames}
                          style={{
                            width: 800,
                            height: 600,
                            position: "relative",
                          }}>
                          {workingData.map((item, index) => {
                            if (item.content) {
                              return (
                                <MobileItem
                                  item={item}
                                  id={index}
                                  key={item.id}
                                  ratio={1}
                                  move={false}
                                  newSize={{
                                    width: item.width || 100,
                                    height: item.height || 100,
                                  }}
                                />
                              );
                            }
                            <div key={index} style={{ width: 0, height: 0 }} />;
                          })}
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={1} style={{ position: "relative" }}>
              <Grid container style={{ position: "absolute", top: 0 }}>
                <Grid
                  item
                  className={styles.items}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <IconButton onClick={handleClose}>
                    <CloseCircle color='white' style={{ color: "white" }} />
                  </IconButton>
                </Grid>
                <Grid item xs={12} className={styles.items}>
                  <IconButton
                    onClick={() => {
                      setMode("tablet");
                      setOrientation("landscape");
                    }}>
                    <Tablet
                      width={48}
                      height={69}
                      color={mode === "tablet" ? "white" : "transparent"}
                    />
                  </IconButton>
                </Grid>

                <Grid item xs={12} className={styles.items}>
                  <IconButton
                    onClick={() => {
                      setMode("mobile");
                      setOrientation("portrait");
                    }}
                    style={{}}>
                    <Mobile
                      width={48}
                      height={69}
                      color={mode !== "tablet" ? "white" : "transparent"}
                    />
                  </IconButton>
                </Grid>
                <Grid
                  item
                  className={styles.items}
                  style={{ marginTop: 15, marginBottom: 15 }}>
                  <div
                    style={{
                      height: 2,
                      width: 68,
                      backgroundColor: "#FFFFFF55",
                    }}
                  />
                </Grid>
                {mode == "tablet" && (
                  <Grid item xs={12} className={styles.items}>
                    <IconButton onClick={() => setOrientation("landscape")}>
                      <LandScape
                        color={
                          orientation == "landscape" ? "white" : "transparent"
                        }
                      />
                    </IconButton>
                  </Grid>
                )}
                <Grid item xs={12} className={styles.items}>
                  <IconButton onClick={() => setOrientation("portrait")}>
                    <Portrait
                      color={
                        orientation !== "landscape" ? "white" : "transparent"
                      }
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

const MobileItem = ({ item }) => {
  const sizes = {
    width: item.width,
    height: item.height,
    x: item.x,
    y: item.y,
  };
  return (
    <div
      id={item.id}
      style={{
        position: "absolute",
        left: item.x,
        top: item.y,
        width: sizes.width,
        height: sizes.height,
        transform: `rotate(${item.rotate}deg)`,
      }}>
      <SvgIcon
        {...item}
        icon={item.content}
        width={sizes.width}
        height={sizes.height}
        style={{ cursor: "default" }}
        name={item.name}
        color={item.color}
      />
    </div>
  );
};
export default Preview;
