import * as React from "react";

const ArrowsIcon = (props) => (
  <svg
    width={13}
    height={19}
    viewBox='0 0 13 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M.667 6l1.41 1.41 4.59-4.58 4.59 4.58L12.667 6l-6-6-6 6zM12.667 12.82l-1.41-1.41-4.59 4.58-4.59-4.58-1.41 1.41 6 6 6-6z'
      fill='#004C60'
    />
  </svg>
);

export default ArrowsIcon;
