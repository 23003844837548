import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import RightDrawer from "../../../../components/RightDrawer";
import CircleClose from "../../../../components/SVG/CircleClose";
import BackIcon from "../../../../components/SVG/Back";
import Title from "../combos/Title";
import InputText from "../../../../components/InputText";
import CirclePlus from "../../../../components/SVG/CirclePluse";
import { AvenirBlackH3, AvenirBlackH4, H4 } from "../../../../utils/text";
import DeleteIcon from "../../../../components/SVG/Delete";
import CustomCheckbox from "../../../../components/CustomCheckbox";
import HelperText from "../../../../components/HelperText";
import { ConvertPrice, validateAlphaNumeric } from "../../../../utils/util";

const EditToppings = ({ open, onClose, sizes, onSaveSizes }) => {
  const [name, setName] = useState("");
  const [sizesList, setSizes] = useState([]);
  const [custom, setCustom] = useState([]);
  const [limit, setLimit] = useState({ hasLimit: "0", limit: 1 });
  useEffect(() => {
    console.log("sizes", sizes);
    setSizes(sizes.byo_ingredients);
    const allow_multiple_choice = sizes.allow_multiple_choice ? 1 : 0;
    setCustom(sizes.ingredient_customization_selected);
    const l = sizes.multiple_selection_config
      ? sizes.multiple_selection_config?.maximum
      : 1;
    setLimit({
      hasLimit: l == sizes.byo_ingredients.length ? "0" : "1",
      limit: l,
    });
  }, [sizes]);
  const addSize = () => {
    if (name) {
      const index = sizesList.findIndex(
        (e) => e.byo_item_ingredient_name == name
      );
      if (index == -1) {
        setSizes([...sizesList, { byo_item_ingredient_name: name, price: "" }]);
      }
    }
    setName("");
  };
  const onChangeSizeFields = (field, value, index) => {
    let _data = value;
    if (field == "byo_item_ingredient_name") {
      _data = validateAlphaNumeric(value);
    }
    setSizes([
      ...sizesList.slice(0, index),
      { ...sizesList[index], [field]: _data },
      ...sizesList.slice(index + 1),
    ]);
  };
  const deleteSize = (index) => {
    setSizes([...sizesList.slice(0, index), ...sizesList.slice(index + 1)]);
  };
  const onSave = () => {
    let max = limit.hasLimit == "0" ? sizesList.length : limit.limit;
    if (max !== "") {
      max = parseInt(max);
      if (max != 0 && max <= sizesList.length) {
        onSaveSizes({
          ...sizes,
          byo_ingredients: sizesList,
          ingredient_customization_selected: custom,
          allow_multiple_selection: parseInt(limit.hasLimit),
          multiple_selection_config: {
            minimum: 1,
            maximum:
              limit.hasLimit == "0"
                ? sizesList.length
                : limit.limit
                  ? parseInt(limit.limit)
                  : 1,
          },
        });
      }
    }
  };
  const ingredientCustomization = (id) => {
    let ic = [...custom];
    const index = ic.indexOf(id);
    if (index == -1) {
      ic = [...ic, id];
    } else {
      ic = [...ic.slice(0, index), ...ic.slice(index + 1)];
    }
    setCustom(ic);
  };
  console.log("LIMIT_____", limit);
  const handleName = (data) => {
    setName(validateAlphaNumeric(data));
  };
  return (
    <RightDrawer open={open}>
      <Grid
        container
        style={{
          minWidth: 500,
          maxWidth: 500,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          position: "relative",
        }}>
        <Grid
          container
          style={{
            width: "100%",
            flexFlow: "column",
            height: window.innerHeight - 50,
            overflow: "auto",
            paddingBottom: 80,
          }}>
          <Grid
            container
            data-qaid='create_item_container'
            style={{ height: 60, padding: "0px 24px" }}
            justify='space-between'
            alignItems='center'>
            <Grid item xs={12}>
              <Grid container justify='flex-end' alignItems='center'>
                <CircleClose
                  data-qaid='assignkitchen_closeicon'
                  size={20}
                  onClick={onClose}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction='row' style={{ paddingLeft: 20 }}>
            <BackIcon onClick={onClose} />
          </Grid>
          <Grid container>
            <Title title='Topping configure' />
          </Grid>
          <Grid
            container
            direction='row'
            style={{ marginTop: 30, padding: "0px 24px" }}>
            <Grid item xs={12}>
              <AvenirBlackH4 label='Available customization' />
            </Grid>
            <Grid item xs={12}>
              <Grid container direction='row' justify='space-between'>
                {sizes.ingredient_customization.map((ic) => (
                  <Grid item xs={6}>
                    <CustomCheckbox
                      label={ic.customization_name}
                      checked={
                        custom.indexOf(
                          ic.byo_type_ingredient_customization_id
                        ) !== -1
                      }
                      onChange={(e) =>
                        ingredientCustomization(
                          ic.byo_type_ingredient_customization_id
                        )
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <HelperText text='Topping price will adjust based on quantity selected (double = 2x, triple = 3x)' />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} style={{ marginTop: 15 }}>
                  <AvenirBlackH4 label='Limit number of toppings?' />
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup
                    value={limit.hasLimit}
                    row
                    aria-label='position'
                    name='position'
                    onChange={(e) => {
                      setLimit({
                        limit:
                          e.target.value == "0"
                            ? sizesList.length
                            : limit.limit,
                        hasLimit: e.target.value,
                      });
                    }}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        value='0'
                        control={<Radio />}
                        label='No'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        value='1'
                        control={<Radio />}
                        label='Yes'
                      />
                    </Grid>
                  </RadioGroup>
                </Grid>
                {limit.hasLimit == 1 && (
                  <Grid item xs={12}>
                    <Grid container direction='row'>
                      <Grid item xs={3}>
                        <H4 label='Toppings limit:' />
                      </Grid>
                      <Grid item xs={3}>
                        <InputText
                          type='number'
                          maxLength={8}
                          onChange={(e) =>
                            setLimit({ ...limit, limit: e.target.value })
                          }
                          value={limit.limit}
                          InputProps={{
                            min: limit.hasLimit == "0" ? 1 : 1,
                            max: sizesList.length,
                          }}
                          placeholder=''
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <InputText
                onChange={(e) => handleName(e.target.value)}
                value={name}
                maxLength={40}
                placeholder=''
              />
            </Grid>
            <Grid
              container
              direction='row'
              justify='flex-start'
              style={{ marginTop: 15, width: "auto", cursor: "pointer" }}
              onClick={addSize}>
              <CirclePlus color='#004C60' />
              <AvenirBlackH3
                label='Add'
                style={{ marginLeft: 5, color: "#004C60" }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Title title='Edit existing toppings' />
          </Grid>
          <Grid container style={{ paddingLeft: 20 }}>
            {sizesList.map((s, index) => (
              <Grid
                container
                direction='row'
                key={index}
                justify='space-between'
                style={{ marginTop: 15 }}>
                <Grid item xs={6}>
                  <InputText
                    maxLength={40}
                    onChange={(e) =>
                      onChangeSizeFields(
                        "byo_item_ingredient_name",
                        e.target.value,
                        index
                      )
                    }
                    value={s.byo_item_ingredient_name}
                    placeholder={`Topping ${index + 1}`}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Grid container>
                    <InputText
                      onChange={(e) =>
                        onChangeSizeFields(
                          "price",
                          e.target.value
                            .replace("$", "")
                            .replace(/[^0-9\.?]/g, "") || "",
                          index
                        )
                      }
                      value={`${s.price == "" ? "" : `$${s.price}`}`}
                      inputPlaceholder='$1.00'
                      maxLength={9}
                      placeholder='Price premium (optional)'
                      onBlur={() =>
                        onChangeSizeFields(
                          "price",
                          ConvertPrice(s.price),
                          index
                        )
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <Grid
                    container
                    alignItems='center'
                    justify='center'
                    style={{ height: "100%" }}>
                    <DeleteIcon onClick={() => deleteSize(index)} />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            position: "absolute",
            zIndex: 999,
            backgroundColor: "white",
            height: 60,
            backgroundColor: "white",
            borderTop: "1px solid #EEE",
            justifyContent: "flex-end",
            bottom: 0,
            left: 0,
            alignItems: "center",
            zIndex: 999,
          }}>
          <Grid item xs={12} md={6}>
            <Grid container justify='flex-end'>
              <Button style={{ border: "2px solid #707272" }} onClick={onClose}>
                <AvenirBlackH4 label='Cancel' />
              </Button>
              <Button
                variant='contained'
                style={{
                  backgroundColor: "#FFB600",
                  marginLeft: 20,
                  marginRight: 20,
                }}
                onClick={onSave}>
                <AvenirBlackH4 label='Save' />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RightDrawer>
  );
};

export default EditToppings;
