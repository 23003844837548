import React, { useState, useEffect, useContext, useRef } from "react";
import { Grid, Button, Paper } from "@material-ui/core";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import SimpleTable from "../../../components/SimpleTable";
import Daterange from "../../../components/DateRange";
import { AvenirBlackH4 } from "../../../utils/text";
import MyContext from "../../../Provider/MyContext";
import DownlodeIcon from "../../../components/SVG/DownlodeIcon";
import SmallMailIcon from "../../../components/SVG/SmallMailIcon";
import RightDrawer from "../../../components/RightDrawer";
import CloseCircle from "../../../components/SVG/CloseCircle";
import useStyles from "../../../styles/index";
import { downloadEodReport } from "../../../utils/excel";
import {
  fetchListOfEodReports,
  fetchSpecificEodReport,
  postEmailReport,
} from "../../../redux/actions";
import SnackBar from "../../../components/SnackBar";
import { handleSorting } from "../../../Provider/ReportingServices";
import { downloadEodPdfDocument } from "../../../components/PDFTableGenerator";
import { getDateTimeFormat, getDateFormat } from "../../../utils/util";
import ReportView from "./ReportView";

const EndOfDayReports = () => {
  const toDate = new Date();
  const fromDate = new Date(toDate);
  fromDate.setDate(fromDate.getDate() - 7);
  const style = useStyles();
  const dispatch = useDispatch();
  const printJobRef = useRef();
  const eodReportsList = useSelector(
    (state) => state.eodReportsReducer.listEodReports
  );
  const [table, setTable] = useState([]);
  const Context = useContext(MyContext);
  const [executionId, setExecutionId] = useState("");
  const [date, setDate] = useState({
    from_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
  });
  const [initialValue, setInitialValue] = useState([fromDate, toDate]);
  const [initialRange, setInitialRange] = useState({
    label: Context.langData.last_7days,
    startDate: fromDate,
    endDate: toDate,
  });
  const [pageNumber, setPageNumber] = useState(0);
  const eodSpecificReport = useSelector(
    (state) => state.eodReportsReducer.specificEodReport
  );
  const [snackbar, setSnackbar] = useState(null);
  const [eodColumns, setEodColumns] = useState([
    {
      field: "executedAt",
      display: Context.langData.date_and_time,
      size: "33%",
    },
    {
      field: "employeeName",
      display: Context.langData.generated_by,
      size: "33%",
    },
    {
      field: "amount",
      display: Context.langData.total_sales,
      size: "33%",
      isNumber: true,
    },
  ]);
  const [closeOpen, setCloseOpen] = useState(false);
  const onDateChange = (d) => {
    if (date.from_date !== d.startDate || date.to_date !== d.endDate) {
      dispatch({
        type: "GET_LIST_EOD_REPORTS",
        payload: [],
      });
      dispatch(
        fetchListOfEodReports({
          id: d.startDate,
          id2: d.endDate,
        })
      );
      setDate({
        from_date: d.startDate,
        to_date: d.endDate,
      });
    }
  };

  const onRowClick = (e) => {
    dispatch(fetchSpecificEodReport(e.executionId));
    setExecutionId(e.executionId);
    setCloseOpen(true);
  };

  const sortingOnClick = (value, sortValue, index) => {
    const columnData = [...eodColumns];
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setEodColumns(columnData);
    setTable(handleSorting(value, table, sortValue));
  };

  const downloadExcel = () => {
    const executed_at = getDateFormat(eodSpecificReport?.executedAt);
    const report_title = eodSpecificReport?.reportName;
    const report_subtitle = getDateTimeFormat(eodSpecificReport?.executedAt);
    const file_name = `EndOfDayReport_${executed_at}.xlsx`;
    downloadEodReport(
      eodSpecificReport,
      report_title,
      report_subtitle,
      file_name
    );
  };

  const downloadPDF = () => {
    const executed_at = getDateFormat(eodSpecificReport?.executedAt);
    const report_title = eodSpecificReport?.reportName;
    const report_subtitle = getDateTimeFormat(eodSpecificReport?.executedAt);
    const file_name = `EndOfDayReport_${executed_at}.pdf`;

    downloadEodPdfDocument(
      report_title,
      report_subtitle,
      eodSpecificReport?.ReportData?.data,
      file_name
    );
  };

  useEffect(() => {
    dispatch({
      type: "GET_LIST_EOD_REPORTS",
      payload: [],
    });
    dispatch(
      fetchListOfEodReports({
        id: moment().subtract(7, "d").format("YYYY-MM-DD"),
        id2: moment().format("YYYY-MM-DD"),
      })
    );
  }, []);

  useEffect(() => {
    if (eodReportsList) {
      const sortedList = eodReportsList.reverse();
      const finalEodReportsList = sortedList.map((item) => {
        const formattedExecutedAt = getDateTimeFormat(item.executedAt);
        return {
          executionId: item.executionId,
          executedAt: formattedExecutedAt,
          employeeName: item.executedBy.employeeName,
          amount:
            item.summary.filter((item) => item.name === "Gross Sales")[0].value
              .amount / 100,
        };
      });
      setTable(finalEodReportsList);
    }
  }, [eodReportsList]);

  const handleEmailReports = () => {
    dispatch(postEmailReport(executionId, handleToast));
  };

  const handleToast = (value) => {
    if (value) {
      setSnackbar({
        message: Context.langData.eod_email_sent_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.eod_email_failed,
        severity: "error",
      });
    }
  };

  const onPageChange = (value) => {
    setPageNumber(value);
  };
  return (
    <Grid
      data-qaid='eodReportList_mainContainer'
      container
      style={{ paddingLeft: 10, paddingRight: 10 }}>
      <Grid data-qaid='eodReportList_subContainer' container>
        <Grid
          data-qaid='eodReportList_dateContainer'
          item
          lg={9}
          style={{
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}>
          <Grid
            data-qaid='eodReportList_dateGrid'
            item
            style={{ marginLeft: 10 }}>
            <AvenirBlackH4
              data-qaid='eodReportSales_dateLabel'
              label={Context.langData.from_to}
            />
            <Daterange
              data-qaid='eodReportList_dateRange'
              isUTCChange
              onDateChange={onDateChange}
              initialValue={initialValue}
              initialRange={initialRange}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        lg={12}
        data-qaid='eodReportList_simpleTableGrid'
        style={{ marginTop: 40 }}>
        <SimpleTable
          data-qaid='eodReportList_simpleTable'
          columns={eodColumns}
          rows={table}
          isEditArrow
          isOnClick
          sortingOnClick={sortingOnClick}
          onRowClick={onRowClick}
          onPageChange={onPageChange}
        />
      </Grid>
      <RightDrawer
        open={closeOpen}
        onClose={() => {
          setCloseOpen((prev) => !prev);
        }}>
        <Grid display='flex' flexDirection='column'>
          <Grid
            data-qaid='close_grid'
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}>
            <CloseCircle
              data-qaid='closeicon'
              size={20}
              onClick={() => {
                setCloseOpen(false);
                setExecutionId("");
              }}
              style={{
                marginRight: 40,
                marginTop: 20,
              }}
            />
          </Grid>
          <Paper ref={printJobRef}>
            <ReportView isEodReport />
          </Paper>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px",
            }}>
            <Grid
              style={{
                display: "flex",
                justifyContent: "flex-start",
                padding: "20px",
              }}>
              <Button
                startIcon={<DownlodeIcon />}
                style={{ marginLeft: 10 }}
                onClick={downloadExcel}>
                <AvenirBlackH4
                  label='Download Excel'
                  style={{ color: "#004C60" }}
                />
              </Button>
              <Button
                startIcon={<DownlodeIcon />}
                style={{ marginLeft: 10 }}
                onClick={downloadPDF}>
                <AvenirBlackH4
                  label='Download PDF'
                  style={{ color: "#004C60" }}
                />
              </Button>
              <Button
                startIcon={<SmallMailIcon />}
                style={{ marginLeft: 10 }}
                onClick={handleEmailReports}>
                <AvenirBlackH4 label='Email' style={{ color: "#004C60" }} />
              </Button>
            </Grid>
            <Grid
              style={{
                display: "flex",
                justifyContent: "flex-start",
                padding: "20px",
              }}>
              <ReactToPrint
                trigger={() => (
                  <Button
                    className={style.buttonClass}
                    variant='contained'
                    size='small'
                    style={{
                      backgroundColor: "#FFB600",
                      height: 40,
                      width: 103,
                      marginLeft: "10px",
                    }}>
                    <AvenirBlackH4
                      data-qaid='print-eod-reports'
                      label={Context.langData.print}
                    />
                  </Button>
                )}
                content={() => printJobRef.current}
              />
            </Grid>
          </Grid>
        </Grid>
      </RightDrawer>
      {snackbar && (
        <SnackBar
          data-qaid='categories_snackbar'
          open
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
    </Grid>
  );
};

export default EndOfDayReports;
