import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { RefreshIcon } from "./SVG";

const RefreshButton = ({ refresh, ...props }) => (
  <IconButton
    data-qaid='refreshbutton_iconbutton'
    aria-label='delete'
    onClick={refresh}
    {...props}>
    <RefreshIcon data-qaid='refreshbutton_refresh_icon' />
  </IconButton>
);

export default React.memo(RefreshButton);
