import React, { useCallback, useEffect, useState, useRef } from "react";
import { Grid, Avatar, Hidden } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { fetchAllCategories, saveTheme } from "../../../redux/actions";
import { ConvertPrice } from "../../../utils/util";
import { H4, AvenirBlackH2, H6, AvenirBlackH4 } from "../../../utils/text";

import RightDrawer from "../../../components/RightDrawer";
import CancelIcon from "../../../components/SVG/Close";
import Color from "../../../components/Color";
import CustomButton from "../../../components/CustomButton";
import WebSiteTitle from "./websiteBuilderTitle";
import EditButton from "./editButton";

const Menu = React.memo(({ isPreview = false }) => {
  const { theme } = useSelector((state) => state.builder);
  const color = theme.color_pallete?.select_item_color || "#C8E6F1";
  const menuBackgroundColor = theme.color_pallete?.menu_backgroud || "#C8E6F1";
  const { categories, items } = useSelector((state) => state.menu);
  const [menuItems, setItems] = useState([]);
  const [itemsList, setItemsList] = useState([]);
  const [showModal, setModal] = useState(false);
  const [currentCat, setCurrentCat] = useState({ cat_id: -1, name: "Menu" });
  const itemsRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllCategories());
  }, []);
  useEffect(() => {
    if (categories.length > 0) {
      const temp = [];
      let newItems = [];
      setCurrentCat({ cat_id: categories[0].cat_id, name: categories[0].name });
      categories.map((c) => {
        const itms = items.filter((e) => e.cat_id == c.cat_id);
        temp.push({ ...c, items: itms });
        newItems = [...newItems, ...itms];
      });

      setItems(temp);
      setItemsList(newItems);
    }
  }, [categories, items]);
  const onScroll = useCallback(
    (e) => {
      const height = e.target.scrollTop;

      const itemIndex = Math.floor(height / 194);
      const item = itemsList[itemIndex];
      const cat = menuItems.filter((c) => c.cat_id == item.cat_id);
      console.log(cat);
      if (cat.length > 0) {
        if (cat[0].cat_id != currentCat.cat_id) {
          setCurrentCat({ cat_id: cat[0].cat_id, name: cat[0].name });
        }
      }
    },
    [itemsList, categories, menuItems, currentCat]
  );
  const onMenuClick = (cat, i, cnt = 0) => {
    if (itemsRef.current) {
      const divHeight = 194;
      const c = [...menuItems];
      const cc = c.slice(0, i);
      let length = 0;
      cc.map((cat) => {
        const l = menuItems.find((e) => e.cat_id == cat.cat_id);

        length += l.items.length;
      });
      itemsRef.current?.scrollTo(0, length * divHeight - (length - 1) * 1);

      if (cnt == 1) {
        setCurrentCat({ cat_id: cat.cat_id, name: cat.name });
      }
      // setCurrentCat({ cat_id: cat.cat_id, cat_name: cat.cat_name })
      if (cnt == 0)
        setTimeout(() => {
          onMenuClick(cat, i, 1);
        }, 20);
    }
  };
  return (
    <Grid
      container
      justify='center'
      style={{ paddingTop: 40, paddingBottom: 40 }}>
      <Grid item xs={11} lg={9}>
        <Grid container justify='space-between'>
          <Hidden xsDown>
            <Grid item xs={3}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container direction='row' justify='space-between'>
                    <AvenirBlackH2
                      label='Categories'
                      style={{ marginBottom: 20 }}
                    />
                    {/* <Button
                      startIcon={<PencileIcon color="#FFF" height={16} width={16} />}
                      style={{ backgroundColor: '#004C60', height: 30 }}
                      onClick={() => setModal(true)}
                      variant="contained">
                      <span style={{ color: 'white' }}>Edit</span>
                    </Button> */}
                    {!isPreview && (
                      <EditButton onClick={() => setModal(true)} />
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ height: 500, overflow: "auto" }}>
                    {menuItems.map((cat, i) => (
                      <div
                        key={i}
                        onClick={() => onMenuClick(cat, i)}
                        style={{
                          cursor: "pointer",
                          padding: 15,
                          borderRadius: 5,
                          backgroundColor:
                            cat.cat_id == currentCat.cat_id
                              ? menuBackgroundColor
                              : "transparent",
                          border: "1px solid #EEEEEE",
                          marginBottom: 10,
                        }}>
                        <H4 label={cat.name} />
                      </div>
                    ))}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={8}>
            <H6 label={currentCat.name} style={{ marginBottom: 20 }} />
            <div
              ref={itemsRef}
              onScroll={(e) => onScroll(e)}
              style={{ height: 500, overflow: "auto" }}>
              {menuItems.map((item, index) => (
                <Category key={index} cat={item} />
              ))}
            </div>
          </Grid>
        </Grid>
      </Grid>
      {showModal && (
        <RightDrawer open={showModal} onCloseOutside={() => setModal(false)}>
          <MenuBackground onClose={() => setModal(false)} />
        </RightDrawer>
      )}
    </Grid>
  );
});
const MenuBackground = ({ onClose }) => {
  const { theme } = useSelector((state) => state.builder);
  const menuColor = theme.custom
    ? theme.color_pallete.menu_backgroud
    : theme.primary_color;
  const [color, setColor] = useState(menuColor);
  const dispatch = useDispatch();
  const onSaveTheme = () => {
    const thm = {
      primary_color: theme.primary_color,
      custom: 1,
      color_pallete: {
        menu_backgroud: color,
        link_color: theme.color_pallete.link_color,
        select_item_color: theme.color_pallete.select_item_color,
      },
    };
    dispatch(saveTheme(thm));
    onClose();
  };
  return (
    <Grid container style={{ width: 400, maxWidth: 480, position: "relative" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: 10,
          width: "100%",
        }}>
        <CancelIcon
          style={{ float: "right", cursor: "pointer" }}
          color='#fff'
          onClick={onClose}
        />
      </div>
      <Grid container style={{ height: "90%", padding: 20 }}>
        <Grid item xs={12}>
          <WebSiteTitle />
        </Grid>
        <Grid item xs={12}>
          <span style={{ fontWeight: 900, fontSize: 18 }}>Menu Categories</span>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 30 }}>
          <AvenirBlackH4
            data-qaid='inputtext_placeholder'
            label='Active state colour'
            style={{ marginBottom: -5 }}
          />
          <Color setColor={(e) => setColor(e)} color={color} width='100%' />
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          borderTop: "1px solid #c3c3c3",
          position: "fixed",
          bottom: 0,
          width: "100%",
          padding: 40,
          paddingRight: 20,
          backgroundColor: "#FAFAFA",
        }}>
        <Grid
          item
          xs={12}
          style={{
            position: "fixed",
            bottom: 20,
            alignSelf: "flex-end",
            right: 40,
          }}>
          <Grid container justify='flex-end'>
            {/* <Button variant="contained" onClick={onSave} style={{ backgroundColor: '#FFB600' }}>
              <AvenirBlackH4 label="Save" />
            </Button> */}
            <CustomButton label='Save' onClick={onSaveTheme} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
const Category = React.memo(({ cat }) => (
  <>
    {cat.items.length > 0 && (
      <Grid>
        <Grid item xs={12}>
          {cat.items.map((item) => (
            <Item key={item.item_id} item={item} />
          ))}
        </Grid>
      </Grid>
    )}
  </>
));
const useStyles = makeStyles((theme) => ({
  size: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: 100,
      height: 100,
    },
    [theme.breakpoints.up("md")]: {
      width: 184,
      height: 184,
    },
  },
}));
const Item = React.memo(({ item }) => {
  if (item.price == null) {
  }
  const getName = () => {
    const i = item.name.split(" ");
    return i.map((s) => s.substring(0, 1).toUpperCase()).join("");
  };
  const classes = useStyles();
  return (
    <div
      style={{
        paddingLeft: 16,
        borderRadius: 5,
        border: "1px solid #EEEEEE",
        marginBottom: 10,
      }}>
      <Grid container>
        <Grid item xs={8}>
          <Grid container alignItems='center' style={{ height: "100%" }}>
            <Grid item xs={12}>
              <div
                style={{
                  fontSize: 18,
                  color: "#051D33",
                  marginBottom: 10,
                  fontWeight: 900,
                }}>
                {item.name}
              </div>
              <div
                style={{
                  fontSize: 14,
                  color: "#051D33",
                  marginBottom: 10,
                  fontWeight: 900,
                }}>
                ${item.price !== null ? ConvertPrice(item.price) : "0.00"}
              </div>
              <div style={{ fontSize: 14, color: "#373737" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container justify='flex-end'>
            <Avatar
              className={classes.size}
              variant='square'
              alt='Remy Sharp'
              src={item.img_url}>
              {getName()}
            </Avatar>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
});
export default Menu;
