import React from "react";
import {
  Select,
  Checkbox,
  MenuItem,
  FormControl,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import _ from "lodash";
import clsx from "clsx";
import { H4, DotText } from "../utils/text";
import { DownVector } from "./SVG";

const MultiSelect = ({
  list,
  selectedValue = [],
  handleChange,
  value,
  name,
  placeholder = "",
  border = false,
  minWidth,
  height,
  width,
  isVendor = false,
  ...props
}) => {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 500,
    },
    selectRoot: {
      "&:focus": {
        backgroundColor: "#FFF",
      },
    },
    hoverMenuItem: {
      "&:hover": {
        backgroundColor: "#C8E6F1",
        borderRadius: 5,
      },
    },
  }));
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const menuProps = {
    PaperProps: {
      style: {
        marginTop: 8,
        backgroundColor: "#FFFFFF",
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };

  const changeValue = (e) => {
    console.log(e.target.value);
    handleChange(e.target.value);
  };
  const selectedRender = (selected) => {
    if (selected.length === 0) {
      return <H4 label={placeholder} />;
    }
    return selected
      .map((s) => {
        const i = _.find(list, (li) => li[value] == s);
        if (i) {
          return i[name];
        }
        return "";
      })
      .join(", ");
  };
  const classes = useStyles({ border });
  const iconStyles = {
    selectIcon: {
      marginTop: 8,
    },
  };
  const CustomExpandVector = withStyles(iconStyles)(
    ({ className, classes, ...rest }) => (
      <DownVector {...rest} className={clsx(className, classes.selectIcon)} />
    )
  );
  return (
    <FormControl
      data-qaid='multiselect_form_control'
      variant={border ? "outlined" : "filled"}
      style={{ minWidth }}>
      <Select
        data-qaid='multiselect_select'
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        multiple
        displayEmpty
        style={{
          border: "1px solid #707272",
          backgroundColor: "#FFFFFF",
          fontSize: 14,
          fontFamily: "AvenirLT",
          height,
          width,
          borderRadius: 4,
        }}
        IconComponent={CustomExpandVector}
        classes={{ root: classes.selectRoot }}
        disableUnderline
        value={selectedValue}
        onChange={changeValue}
        renderValue={selectedRender || placeholder}
        MenuProps={menuProps}
        {...props}>
        {list.map((item, index) => (
          <MenuItem
            data-qaid='multiselect_menuitem'
            key={index}
            value={item[value]}
            classes={{ root: classes.hoverMenuItem }}
            style={{ maxWidth: width }}>
            <Checkbox
              data-qaid='multiselect_checkbox'
              checked={selectedValue.indexOf(item[value]) > -1}
            />
            <DotText data-qaid='multiselect_label_text' label={item[name]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default React.memo(MultiSelect);
