import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import moment from "moment";
import _ from "lodash";
import CirclePluse from "../../../components/SVG/CirclePluse";
import MyContext from "../../../Provider/MyContext";
import { H4, H3, AvenirBlackH4, H6, AvenirBlackH2 } from "../../../utils/text";
// import DatePicker from '../../../components/DatePicker';
import SingleDate from "../../../components/SingleDate";
import InfoOutlined from "../../../components/SVG/InfoOutlined";
import {
  fetchPettyCashExpenses,
  fetchPettyCashSession,
  updatePettyCashSession,
} from "../../../redux/actions";
import styles from "../../../styles/index";
import SimpleTable from "../../../components/SimpleTable";
import SnackBar from "../../../components/SnackBar";
import { ConvertPrice } from "../../../utils/util";
import AlertDialog from "../../../components/Dialog";
import StartSession from "./startSession";
import AddExpenses from "./addExpension";

const ExpensesList = () => {
  const style = styles();
  const Context = useContext(MyContext);
  const [columns, setColumns] = useState([
    { field: "serial_no", display: Context.langData.bill_No, size: "10%" },
    { field: "time", display: Context.langData.time_date, size: "15%" },
    { field: "cat_name", display: Context.langData.category_name, size: "17%" },
    {
      field: "tx_type",
      display: Context.langData.transaction_type,
      size: "15%",
    },
    { field: "notes", display: Context.langData.comments, size: "18%" },
    { field: "name", display: Context.langData.transaction_by, size: "15%" },
    {
      field: "amount",
      display: Context.langData.amount,
      size: "10%",
      isNumber: true,
    },
  ]);
  const [selectedId, setSelectedId] = React.useState([]);
  const [btnDisable, setBtnDisable] = React.useState(true);
  const [openStartSession, toggleOpenStartSessionDrawer] = useState(false);
  const [openAddExpenses, toggleOpenAddExpenses] = useState(false);
  const [openEdit, toggleEditDrawer] = useState(false);
  const [row, setRow] = useState({});
  const [sessionStarted, setSessionStarted] = useState(false);
  const PettyCashSession = useSelector(
    (state) => state.pettyCashReducer.session
  );
  const PettyCashExpenses = useSelector(
    (state) => state.pettyCashReducer.expenses
  );
  const [startDate, setStartDate] = useState();
  const [isActiveSession, setIsActiveSession] = useState(false);
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const [heading, setHeading] = useState(Context.langData.stop_session);
  const [bodyMessage, setBodyMessage] = useState(Context.langData.stop_body);
  const [selectedTabData, setSelectedTabData] = useState([]);
  const [openBlance, setOpenBalance] = useState(0);
  const [closeBalance, setCloseBalance] = useState(0);
  const [creditBalance, setCreditBalance] = useState(0);
  const [debitBalance, setDebitBalance] = useState(0);
  const PettyCashCategories = useSelector(
    (state) => state.pettyCashReducer.categories
  );
  const [snackbar, setSnackbar] = useState(null);
  const btnProps = {};
  const [date, setDate] = useState();
  console.log(date, "datedateee");
  console.log(startDate, "startDatestartDate");

  if (btnDisable) {
    btnProps.disabled = true;
  }

  useEffect(() => {
    let total_debits = 0;
    let total_credits = 0;
    if (PettyCashExpenses.expenses) {
      var expensesArray = [];
      PettyCashExpenses.expenses.map((item, index) => {
        const obj = {};
        if (item.tx_type == 0) total_credits += item.amount;
        else total_debits += item.amount;
        obj.time = moment(item.ts * 1000).format("h:mm a MMM D, YYYY");
        obj.tx_type = item.tx_type == 1 ? "Debit" : "Credit";
        obj.serial_no = index + 1;
        obj.notes = item.notes;
        obj.item_amount = item.amount;
        if (item.notes != null) {
          if (item.notes.length > 48) {
            obj.notes = `${item.notes.substring(0, 48)}...`;
          } else {
            obj.notes = item.notes;
          }
        } else {
          obj.notes = "N/A";
        }
        if (item.tx_type == 1) {
          obj.amount = (
            <span style={{ color: "#FC4C02" }}>
              {" "}
              (${ConvertPrice(item.amount)})
            </span>
          );
        } else {
          obj.amount = <span>${ConvertPrice(item.amount)}</span>;
        }
        obj.name = item.name;
        if (PettyCashCategories) {
          const cateNameArray = PettyCashCategories.filter(
            (e) => e.cat_id == item.cat_id
          );
          if (cateNameArray.length > 0) {
            obj.cat_name = cateNameArray[0].cat_name;
          } else {
            obj.cat_name = "-";
          }
        } else {
          obj.cat_name = "-";
        }
        expensesArray.push(obj);
      });
    }
    console.log(
      "PettyCashExpenses==>",
      PettyCashExpenses,
      "expensesArray=>",
      expensesArray
    );
    setSelectedTabData(expensesArray);
    setCreditBalance(total_credits.toFixed(2));
    setDebitBalance(total_debits.toFixed(2));
    if (PettyCashExpenses.opening_balance) {
      setOpenBalance(PettyCashExpenses.opening_balance.toFixed(2));
      setCloseBalance(
        (
          PettyCashExpenses.opening_balance +
          total_credits -
          total_debits
        ).toFixed(2)
      );
    } else {
      setCloseBalance((0 + total_credits - total_debits).toFixed(2));
      setOpenBalance((0).toFixed(2));
    }
  }, [PettyCashExpenses, PettyCashCategories]);
  const getDate = (dateStr) => {
    const date = dateStr
      ? stringToDate(dateStr, "YYYY-MM-DD", "-")
      : new Date();
    const MM = date.getMonth() + 1;
    const da = date.getDate();
    const year = date.getFullYear();
    return `${MM}/${da}/${year}`;
  };
  function stringToDate(_date, _format, _delimiter) {
    const formatLowerCase = _format.toLowerCase();
    const formatItems = formatLowerCase.split(_delimiter);
    const dateItems = _date.split(_delimiter);
    const monthIndex = formatItems.indexOf("mm");
    const dayIndex = formatItems.indexOf("dd");
    const yearIndex = formatItems.indexOf("yyyy");
    let month = parseInt(dateItems[monthIndex]);
    month -= 1;
    const formatedDate = new Date(
      dateItems[yearIndex],
      month,
      dateItems[dayIndex]
    );
    return formatedDate;
  }
  useEffect(() => {
    if (PettyCashSession && PettyCashSession.session_date) {
      console.log(PettyCashSession.session_date, "psession_date");
      console.log(startDate, "pstartDate");
      setStartDate(PettyCashSession.session_date);

      console.log(
        "SETTINGS DATE.............,",
        getDate(PettyCashSession.session_date)
      );
      setDate(getDate(PettyCashSession.session_date));
      if (moment(PettyCashSession.session_date).unix() <= moment().unix()) {
        setIsActiveSession(true);
        setBtnDisable(false);
      } else {
        setIsActiveSession(false);
        setBtnDisable(true);
      }
    } else {
      console.log("SETTINGS DATE.............,", getDate());
      setStartDate(moment().format("YYYY-MM-DD"));
      setDate(getDate());
      setIsActiveSession(false);
    }
    setSessionStarted(true);
  }, [PettyCashSession]);
  useEffect(() => {
    if (startDate && sessionStarted) {
      dispatch(fetchPettyCashExpenses({ datestr: startDate }));
      setTimeout(() => {
        setSessionStarted(false);
      }, 500);
    }
  }, [startDate, sessionStarted]);
  function onCheckedIds(ids) {
    setSelectedId(ids);
    if (ids.length > 0) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  }
  function onClickAction(rowValue) {
    setRow(rowValue);
    toggleEditDrawer(true);
  }
  function commanCallback() {
    toggleOpenStartSessionDrawer(false);
    toggleEditDrawer(false);
    toggleOpenAddExpenses(false);
    dispatch(fetchPettyCashSession());
  }
  function createSessionCallback(val, status) {
    if (val) {
      handleDateCallback(status);
      setSnackbar({
        message: Context.langData.session_create_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.session_create_failed,
        severity: "error",
      });
    }
    commanCallback();
  }
  function createExpensionsCallback(length, isSuccess) {
    if (isSuccess) {
      if (length == 1) {
        setSnackbar({
          message: Context.langData.expense_create_succ,
          severity: "success",
        });
      } else {
        setSnackbar({
          message: Context.langData.expenses_create_succ,
          severity: "success",
        });
      }
    } else {
      setSnackbar({
        message: Context.langData.expenses_create_failed,
        severity: "error",
      });
    }
    toggleOpenStartSessionDrawer(false);
    toggleEditDrawer(false);
    toggleOpenAddExpenses(false);
    dispatch(
      fetchPettyCashExpenses({
        datestr: moment(PettyCashSession.session_date).format("YYYY-MM-DD"),
      })
    );
  }
  function sessionStopCallback(isSuccess) {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.session_stop_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.session_stop_failed,
        severity: "error",
      });
    }
    commanCallback();
  }
  const handleClose = (val) => {
    if (val) {
      const obj = {
        session_status: 0,
        session_date: PettyCashSession.session_date,
      };
      dispatch(updatePettyCashSession(obj, sessionStopCallback));
    }
    setShowDialog(false);
  };
  const onPageinationChange = () => {};
  const handleDateCallback = (value) => {
    console.log("date call back", value);
    const dateVal = moment(value, "MM/DD/YYYY").format("YYYY-MM-DD");
    setStartDate(dateVal);
    setSessionStarted(true);
    // setDate(value)
    setDate(value);
  };
  console.log("datecall_back", date);
  console.log("iestartDate", startDate);

  console.log(
    PettyCashSession.session_date,
    "PettyCashSessionPettyCashSession_date"
  );

  const handleShowSessiondate = () => {
    const sessionDate = moment(
      PettyCashSession.session_date,
      "YYYY-MM-DD"
    ).format("MM/DD/YYYY");
    setDate(sessionDate);
    const startDataVal = moment(
      PettyCashSession.session_date,
      "YYYY-MM-DD"
    ).format("YYYY-MM-DD");
    setStartDate(startDataVal);
    setSessionStarted(true);
  };
  const sortingOnClick = (value, sortValue, index) => {
    const columnData = [...columns];
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setColumns(columnData);
    if (value == "amount") {
      var sortExpenses = _.orderBy(
        selectedTabData,
        ["item_amount"],
        [sortValue ? "desc" : "asc"]
      );
    } else {
      var sortExpenses = _.orderBy(
        selectedTabData,
        [value],
        [sortValue ? "desc" : "asc"]
      );
    }
    setSelectedTabData(sortExpenses);
  };
  return (
    <div data-qaid='expensesList_div'>
      <Grid
        data-qaid='expensesList_grid'
        container
        direction='row'
        style={{ padding: 20 }}>
        <Grid
          data-qaid='expensesList_buttongrid'
          item
          xs={12}
          sm={12}
          lg={12}
          style={{ marginTop: 24 }}>
          {!isActiveSession && (
            <Grid
              data-qaid='expensesList_session_notstarted_grid'
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                display: "flex",
                justifyContent: "center",
                height: 42,
                backgroundColor: "#F3FAFD",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: 12,
                marginBottom: 20,
              }}>
              <InfoOutlined
                width={18}
                height={18}
                color='#004C60'
                style={{ marginRight: 10 }}
              />
              <AvenirBlackH2
                label={Context.langData.pettycash_session_not_started}
                style={{ color: "#004C60" }}
              />
            </Grid>
          )}
          <Grid
            data-qaid='expensesList_add grid'
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginRight: 0,
              marginBottom: 20,
            }}>
            <Grid
              data-qaid='expensesList_date_grid'
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                marginRight: 20,
                marginBottom: 20,
                marginLeft: 12,
              }}>
              <AvenirBlackH4
                data-qaid='expensesList_date_label'
                label={Context.langData.date}
                style={{ marginBottom: 5 }}
              />
              <SingleDate
                value={date}
                height={54}
                maxDate={new Date()}
                border={false}
                callback={handleDateCallback}
              />
            </Grid>
            {/* <Grid data-qaid="expensesList_add grid" item xs={12} sm={12} md={9} lg={9} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginRight: 0, marginBottom: 20 }} > */}
            {/* <Button className={style.buttonClass} data-qaid="expensesList_addbutton" {...btnProps} style={{ backgroundColor: btnDisable ? "#FFFFFF" : "#FFFFFF", color: "white", marginRight: 10 }} id="cancel" onClick={() => toggleOpenAddExpenses(true)} >
                                <CirclePluse data-qaid="expensesList_circleicon" style={{ marginRight: 5 }} color={btnDisable ? '#707272' : '#004C60'} width={23} height={23} />
                                <AvenirBlackH4 data-qaid="expensesList_createlabel" style={{ color: btnDisable ? '#707272' : '#004C60' }} label={Context.langData.add_expenses} />
                            </Button> */}
            {!isActiveSession ? (
              <Grid
                data-qaid='expensesList_add grid'
                item
                xs={12}
                sm={12}
                md={9}
                lg={9}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginRight: 0,
                  marginBottom: 20,
                }}>
                <Button
                  className={style.buttonClass}
                  data-qaid='expensesList_addbutton'
                  {...btnProps}
                  style={{
                    backgroundColor: "#FFFFFF",
                    color: "white",
                    marginRight: 10,
                    cursor: "default",
                  }}
                  id='cancel'>
                  <CirclePluse
                    data-qaid='expensesList_circleicon'
                    style={{ marginRight: 5 }}
                    color='#707272'
                    width={23}
                    height={23}
                  />
                  <AvenirBlackH4
                    data-qaid='expensesList_createlabel'
                    style={{ color: "#707272" }}
                    label={Context.langData.add_expenses}
                  />
                </Button>
                <Button
                  className={style.buttonClass}
                  data-qaid='expensesList_addbutton'
                  style={{
                    backgroundColor: "#FFB600",
                    color: "white",
                    marginRight: 10,
                    height: 40,
                  }}
                  variant='contained'
                  id='cancel'
                  onClick={() => toggleOpenStartSessionDrawer(true)}>
                  <AvenirBlackH4
                    data-qaid='expensesList_createlabel'
                    label={Context.langData.start_session}
                  />
                </Button>
              </Grid>
            ) : (
              <Grid
                data-qaid='expensesList_add grid'
                item
                xs={12}
                sm={12}
                md={9}
                lg={9}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 0,
                  position: "relative",
                }}>
                <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Grid
                    data-qaid='expensesList_add grid'
                    style={{ display: "flex", flexDirection: "row" }}>
                    <Button
                      className={style.buttonClass}
                      data-qaid='expensesList_addbutton'
                      {...btnProps}
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "white",
                        marginRight: 10,
                      }}
                      id='cancel'
                      onClick={() => toggleOpenAddExpenses(true)}>
                      <CirclePluse
                        data-qaid='expensesList_circleicon'
                        style={{ marginRight: 5 }}
                        color='#004C60'
                        width={23}
                        height={23}
                      />
                      <AvenirBlackH4
                        data-qaid='expensesList_createlabel'
                        style={{ color: "#004C60" }}
                        label={Context.langData.add_expenses}
                      />
                    </Button>
                    <Button
                      className={style.buttonClass}
                      data-qaid='expensesList_addbutton'
                      style={{
                        backgroundColor: "#FFB600",
                        color: "white",
                        marginRight: 10,
                        height: 40,
                      }}
                      variant='contained'
                      id='cancel'
                      onClick={() => setShowDialog(true)}>
                      <AvenirBlackH4
                        data-qaid='expensesList_createlabel'
                        label={Context.langData.stop_session}
                      />
                    </Button>
                  </Grid>
                  <Grid
                    style={{
                      position: "absolute",
                      flexDirection: "row",
                      display: "flex",
                      padding: 10,
                      justifyContent: "flex-end",
                      marginTop: 50,
                    }}>
                    <H4
                      label={Context.langData.session_started_for_date}
                      style={{ marginRight: 5 }}
                    />
                    <H4
                      label={moment(
                        PettyCashSession.session_date,
                        "YYYY-MM-DD"
                      ).format("MMM DD, YYYY")}
                      onClick={handleShowSessiondate}
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "#004C60",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {/* </Grid> */}
          </Grid>
          <Grid
            data-qaid='expensesList_header_value_grid'
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginRight: 20,
              marginBottom: 30,
              marginLeft: 12,
            }}>
            <Grid
              data-qaid='expensesList_opening_grid'
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 28,
              }}>
              <H6
                data-qaid='expensesList_opening_amount'
                label={
                  PettyCashExpenses.opening_balance == null
                    ? `$${ConvertPrice("0")}`
                    : `$${ConvertPrice(PettyCashExpenses.opening_balance)}`
                }
              />
              <H3
                data-qaid='expensesList_opneiningamount_label'
                label={Context.langData.opening_amount}
                style={{ color: "#54575A" }}
              />
            </Grid>
            <Grid
              data-qaid='expensesList_creditamount_grid'
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 28,
              }}>
              <H6
                data-qaid='expensesList_credit_amount_value'
                label={`$${ConvertPrice(creditBalance)}`}
              />
              <H3
                data-qaid='expensesList_criditamount_label'
                label={Context.langData.credit_amount}
                style={{ color: "#54575A" }}
              />
            </Grid>
            <Grid
              data-qaid='expensesList_debitamount_grid'
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 28,
              }}>
              <H6
                data-qaid='expensesList_debitamount_value'
                label={`$${ConvertPrice(debitBalance)}`}
              />
              <H3
                data-qaid='expensesList_debitamount_label'
                label={Context.langData.debit_amount}
                style={{ color: "#54575A" }}
              />
            </Grid>
            <Grid
              data-qaid='expensesList_closingamount_grid'
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 28,
              }}>
              <H6
                data-qaid='expensesList_closingamount_value'
                label={`$${ConvertPrice(closeBalance)}`}
              />
              <H3
                data-qaid='expensesList_closingamount_label'
                label={Context.langData.closing_amount}
                style={{ color: "#54575A" }}
              />
            </Grid>
          </Grid>
          <SimpleTable
            data-qaid='expensesList_datatable'
            columns={columns}
            rows={selectedTabData}
            onCheckedIds={onCheckedIds}
            onRowClick={onClickAction}
            onPageChange={onPageinationChange}
            sortingOnClick={sortingOnClick}
            isEditArrow={false}
          />
        </Grid>
      </Grid>
      {openStartSession && (
        <StartSession
          data-qaid='expensesList_startsession'
          open={openStartSession}
          onClose={() => toggleOpenStartSessionDrawer(false)}
          callback={createSessionCallback}
        />
      )}
      {openAddExpenses && (
        <AddExpenses
          data-qaid='expensesList_addexpenses'
          open={openAddExpenses}
          sessionData={PettyCashSession.session_date}
          onClose={() => toggleOpenAddExpenses(false)}
          callback={createExpensionsCallback}
          closeBalance={closeBalance}
        />
      )}
      {snackbar && (
        <SnackBar
          data-qaid='categories_snackbar'
          open
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
      <AlertDialog
        data-qaid='expensesList_alertdialog'
        showDialog={showDialog}
        body={bodyMessage}
        heading={heading}
        IsError={false}
        showExtra={false}
        handleClose={handleClose}
      />
    </div>
  );
};

export default ExpensesList;
