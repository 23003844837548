import React, { forwardRef } from "react";
import PropTypes from "prop-types";

const Page = forwardRef(({ children, title = "", ...rest }, ref) => (
  <div data-qaid='page_div' ref={ref} {...rest}>
    {children}
  </div>
));

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
