import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import moment from "moment";
import styles from "../../../styles/index";
import RightDrawer from "../../../components/RightDrawer";
import InputText from "../../../components/InputText";
import MyContext from "../../../Provider/MyContext";
import { H4, AvenirBlackH4, H5, H8 } from "../../../utils/text";
import CloseCircle from "../../../components/SVG/CloseCircle";
// import DatePicker from '../../../components/DatePicker';
import SingleDate from "../../../components/SingleDate";
import PettyCashIcon from "../../../components/SVG/PettyCashIcon";
import { createPettyCashSession } from "../../../redux/actions";
import ExclamationIcon from "../../../components/SVG/ExclamationIcon";

const StartSession = ({ open, callback, onClose }) => {
  const style = styles();
  const Context = useContext(MyContext);
  const [error, setError] = useState("");
  const [openingBalance, setOpeningBalance] = useState("");
  const dispatch = useDispatch();
  const [startData, setStartData] = useState("");
  const [date, setDate] = useState(moment().format("MM/DD/YYYY"));
  const dateCallback = (value) => {
    setStartData(moment(value, "MM/DD/YYYY").format("YYYY-MM-DD"));

    setDate(value);
  };
  function stringToDate(_date, _format, _delimiter) {
    const formatLowerCase = _format.toLowerCase();
    const formatItems = formatLowerCase.split(_delimiter);
    const dateItems = _date.split(_delimiter);
    const monthIndex = formatItems.indexOf("mm");
    const dayIndex = formatItems.indexOf("dd");
    const yearIndex = formatItems.indexOf("yyyy");
    let month = parseInt(dateItems[monthIndex]);
    month -= 1;
    const formatedDate = new Date(
      dateItems[yearIndex],
      month,
      dateItems[dayIndex]
    );
    return formatedDate;
  }

  const getDate = (dateStr = null) => {
    const date = dateStr || new Date();
    const MM = date.getMonth() + 1;
    const da = date.getDate();
    const year = date.getFullYear();
    return `${MM}-${da}-${year}`;
  };
  const handleSave = () => {
    // handleCreateCallback(true)
    let errorFlag = 0;
    if (openingBalance) {
      const currentDate = getDate();
      const dateString =
        date == ""
          ? currentDate
          : getDate(stringToDate(date, "MM/DD/YYYY", "/"));
      const dateFormat = dateString;
      if (isNaN(+openingBalance)) {
        errorFlag += 1;
      }
      const obj = {
        start_date: dateFormat,
        start_ts: Math.floor(
          stringToDate(dateString, "MM-DD-YYYY", "-").getTime() / 100
        ),
        opening_balance: openingBalance,
        session_status: 1,
      };
      if (errorFlag == 0) {
        dispatch(createPettyCashSession(obj, handleCreateCallback));
      } else {
      }
    } else {
      setError(Context.langData.opne_balance_error);
    }
  };
  const handleCreateCallback = (status) => {
    const currentDate = moment().format("MM-DD-YYYY");
    const dateString = date == "" ? currentDate : date;
    const dateFormat = moment(dateString).format("MM-DD-YYYY");

    callback(status, dateFormat);
  };

  const isValidNumber = (value) => isNaN(value);
  return (
    <RightDrawer
      data-qaid='startSession_centermodal'
      open={open}
      onClose={onClose}
      padding={1}>
      <>
        <Grid
          data-qaid='startSession_main_grid'
          style={{ display: "flex", height: "100%", flexDirection: "column" }}>
          <Grid
            data-qaid='startSession_child2_grid'
            style={{ display: "flex", height: "85%", flexDirection: "column" }}>
            <Grid
              data-qaid='startSession_close_grid'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}>
              <CloseCircle
                data-qaid='startSession_closeicon'
                size={20}
                onClick={onClose}
                style={{ marginRight: 40, marginTop: 20 }}
              />
            </Grid>
            <Grid
              data-qaid='startSession_settings_grid'
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 56,
                marginBottom: 10,
              }}>
              <PettyCashIcon
                data-qaid='startSession_setting_icon'
                style={{ marginRight: 10 }}
                width={22.5}
                height={24}
                color='#707272'
              />
              <H4
                data-qaid='startSession_settings'
                label={Context.langData.petty_cash}
                style={{ color: "#707272" }}
              />
            </Grid>
            <H8
              data-qaid='startSession_title'
              style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
              label={Context.langData.start_session}
            />
            <Grid
              data-qaid='startSession_grid1'
              style={{
                display: "flex",
                flexDirection: "column",
                width: 350,
                marginLeft: 56,
                marginRight: 56,
              }}>
              <Grid
                data-qaid='startSession_date_grid'
                item
                xs={12}
                lg={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: 32,
                  width: "100%",
                }}>
                <AvenirBlackH4
                  data-qaid='startSession_date_label'
                  label={Context.langData.date}
                  style={{ marginBottom: 5 }}
                />
                {/* <DatePicker data-qaid="startSession_date_picker" callback={dateCallback} maxDate={new Date()}/> */}
                <SingleDate
                  value={date}
                  height={54}
                  maxDate={new Date()}
                  border={false}
                  callback={dateCallback}
                />
              </Grid>
              <Grid
                data-qaid='startSession_username_grid'
                item
                xs={12}
                lg={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 20,
                  width: "100%",
                }}>
                <AvenirBlackH4
                  data-qaid='startSession_username_label'
                  label={Context.langData.opening_balance}
                  style={{ marginBottom: 10 }}
                />
                <InputText
                  data-qaid='startSession_usernameip'
                  value={`$${openingBalance}`}
                  error={error}
                  maxLength={9} // with $
                  onChange={(val) =>
                    setOpeningBalance(
                      val.target.value.replace(/[^0-9\.?]/g, "")
                    )
                  }
                  id='name'
                />
                {isValidNumber(openingBalance) && (
                  <Grid
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      display: "flex",
                      marginTop: 5,
                    }}>
                    <ExclamationIcon
                      color='#AB1212'
                      style={{ marginRight: 5 }}
                    />
                    <H5
                      label={Context.langData.not_a_valid_number}
                      style={{ color: "#AB1212" }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            data-qaid='startSession_button_grid'
            style={{
              display: "flex",
              height: "15%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid
              data-qaid='startSession_action_grid'
              container
              style={{
                backgroundColor: "#FAFAFA",
                border: "1px solid #C3C3C3",
              }}>
              <Grid
                data-qaid='startSession_cancel_btn_grid'
                item
                xs={12}
                lg={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 32,
                  marginBottom: 32,
                }}>
                <Button
                  className={style.buttonClass}
                  data-qaid='startSession_cancel_btn'
                  onClick={onClose}
                  variant='contained'
                  style={{
                    backgroundColor: "#FFF",
                    marginRight: 16,
                    width: 103,
                    border: "2px solid #051D33",
                  }}>
                  <AvenirBlackH4
                    data-qaid='startSession_cancel_text'
                    label={Context.langData.cancel}
                  />
                </Button>
                <Button
                  className={style.buttonClass}
                  data-qaid='startSession_save_btn'
                  variant='contained'
                  style={{
                    backgroundColor: "#FFB600",
                    marginRight: 32,
                    width: 90,
                  }}
                  onClick={() => handleSave()}>
                  <AvenirBlackH4
                    data-qaid='startSession_save_text'
                    label={Context.langData.save}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    </RightDrawer>
  );
};

export default StartSession;
