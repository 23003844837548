import React, { useEffect, useState, useContext } from "react";
import { Grid, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import moment from "moment";
import MyContext from "../Provider/MyContext";
import { AvenirBlackH4, H4, H8 } from "../utils/text";
import { Delete, CircleClose, Back as BackIcon, UploadIcon } from "./SVG";
import { RightDrawer } from ".";

const ImageUpload = ({ open, onClose, callback, img }) => {
  const selectedRestaurant = useSelector(
    (state) => state.loginReducer.selectedRestaurant
  );
  const Context = useContext(MyContext);
  const [error, setError] = useState("");
  const [image, setImage] = useState(null);
  useEffect(() => {
    if (img) setImage(img);
  }, [img]);
  useEffect(() => {
    if (image) {
      const img = new Image();
      // var value = URL.createObjectURL(image)
      img.src = image;
      img.onload = function () {
        const { width, height } = this;
        console.log("height after image", width, height);
        console.log("ratio", width / height);
        const r = 200 / height;
        const w = width * r;
        const h = height * r;
        const imageObj = document.getElementById("imageL");
        imageObj.style.width = `${w}px`;
        imageObj.style.height = `${h}px`;
      };
    }
  }, [image]);
  const isValidExtention = (type = "", ext = ".") => {
    ext = ext.toLowerCase();
    type = type.toLowerCase();
    if (ext == "jpg" || ext == "jpeg") {
      if (type == "image/jpeg" || type == "image/jpg") {
        return true;
      }
      return false;
    }
    return type.indexOf(ext) !== -1;
  };
  const onUpload = (e) => {
    console.log(e);
    setError("");
    const fileChoser = document.getElementById(`img`);
    const { files } = fileChoser;
    if (files.length > 0) {
      const file = files[0];
      const fileName = file.name;
      const { type } = file;
      const sp = fileName.split(".");
      const ext = sp[sp.length - 1];
      const limit = 1024 * 1024;
      if (isValidExtention(type, ext)) {
        console.log("upllllllllllllllll");
        if (file.size > limit) {
          setError(`Image size exceeds 1MB`);
        } else {
          const img = new Image();
          const value = URL.createObjectURL(files[0]);
          img.onload = function () {
            console.log("image onload", this.width, this.height);
            const { width, height } = this;

            if (width > 200 || height > 200) {
              setError(`Image dimensions exceeds 200*200`);
            } else {
              const ratio = width / height;
              if (ratio > 0.9 && ratio < 1.1) {
                setImage(value);
              } else {
                setError(
                  `Image dimensions(${width}px * ${height}px) are not valid`
                );
              }

              console.log(value, file);
            }
          };
          console.log("img", img);

          img.src = value;
        }
      } else {
        setError(
          `Content type(${type}) is not matched with file extension(.${ext})`
        );
      }
    }
  };
  const onSave = () => {
    if (image) {
      const fileChoser = document.getElementById(`img`);
      const { files } = fileChoser;
      if (files.length > 0) {
        const file = files[0];
        const fileName = file.name.split(" ").join("_").split(".");
        const imagename = `${fileName[0] ? fileName[0] : "MenuImage"}_${moment()
          .unix()
          .toString()}.${fileName[fileName.length - 1]}`;
        callback(imagename, file);
      }
    } else {
      callback(null, null);
    }
  };
  return (
    <RightDrawer open={open} padding={1}>
      <>
        <Grid
          style={{
            display: "flex",
            height: "95%",
            flexDirection: "column",
            overflow: "auto",
            marginBottom: 100,
          }}>
          <Grid
            data-qaid='assignkitchen_close_grid'
            style={{ display: "flex", justifyContent: "flex-end" }}>
            <CircleClose
              data-qaid='assignkitchen_closeicon'
              size={20}
              onClick={onClose}
              style={{ marginRight: 40, marginTop: 20 }}
            />
          </Grid>
          <Grid
            data-qaid='assignkitchen_settings_grid'
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginLeft: 56,
              marginBottom: 10,
            }}>
            <BackIcon
              data-qaid='categories_editBack_icon'
              onClick={() => onClose()}
              style={{ cursor: "pointer" }}
            />
          </Grid>
          <H8
            data-qaid='assignlitchen_title'
            style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
            label={`${Context.langData.edit_image}`}
          />
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              width: 500,
              marginLeft: 56,
              marginRight: 56,
            }}>
            <AvenirBlackH4 label={Context.langData.image_upload} />
            <H4 label={Context.langData.img_body} />
            <H4 style={{ color: "#AB1212" }} label={error} />
            <Grid
              item
              xs={12}
              style={{ marginTop: 10, height: 200, width: 200 }}>
              {image ? (
                <div style={{ width: 200, height: 200, flex: 1 }}>
                  <label htmlFor='img'>
                    <img
                      src={image}
                      id='imageL'
                      style={{
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      }}
                      alt='img'
                    />
                  </label>
                </div>
              ) : (
                <label htmlFor='img'>
                  <div
                    style={{
                      cursor: "pointer",
                      width: 200,
                      height: 200,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#EEEEEE",
                    }}>
                    {Context.langData.img}
                  </div>
                </label>
              )}
            </Grid>

            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                marginTop: 20,
              }}>
              <Button
                style={{ color: "#004C60" }}
                onClick={() => setImage(null)}
                startIcon={<Delete color='#004C60' />}>
                <AvenirBlackH4
                  label={Context.langData.delete}
                  style={{ color: "#004C60" }}
                />
              </Button>
              <input
                accept='image/png, image/jpg, image/jpeg'
                onChange={(e) => onUpload(e)}
                id='img'
                style={{ display: "none" }}
                type='file'
              />
              <label htmlFor='img'>
                <Button
                  component='span'
                  style={{ color: "#004C60" }}
                  startIcon={<UploadIcon color='#004C60' />}>
                  <AvenirBlackH4
                    label={Context.langData.upload_an_image}
                    style={{ color: "#004C60" }}
                  />
                </Button>
              </label>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            position: "fixed",
            height: "15%",
            bottom: 0,
            width: "100%",
            padding: 40,
            backgroundColor: "#FAFAFA",
          }}>
          <Grid
            item
            xs={12}
            style={{
              position: "fixed",
              bottom: 20,
              alignSelf: "flex-end",
              right: 40,
            }}>
            <Grid container justify='flex-end'>
              <Button
                variant='contained'
                onClick={onSave}
                style={{ backgroundColor: "#FFB600" }}>
                {" "}
                <AvenirBlackH4 label={Context.langData.save} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    </RightDrawer>
  );
};
export default ImageUpload;
