import React, { useState, useEffect } from "react";
import { Resizable } from "react-resizable";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid, Snackbar } from "@material-ui/core";
import { HotKeys } from "react-hotkeys";
import MuiAlert from "@material-ui/lab/Alert";
import moment from "moment";
import { validateAlphaNumeric } from "../../../utils/util";
import { SvgIcon } from "./Icons";

const timer = 0;
const prevent = false;
const nagetiveX = ["sw", "w", "nw"];
const nagetiveY = ["nw", "n", "ne"];
const DragAndResize = ({
  id,
  item,
  ratio = 1,
  move = true,
  callback,
  isRotate = false,
}) => {
  const [isSelected, setSelected] = useState(false);
  const [sizes, setSizes] = useState({ width: 50, height: 50 });
  const dispatch = useDispatch();
  const [positions, setPositions] = useState({ x: 100, y: 100 });
  const selectedIndex = useSelector((state) => state.floorplan.selectedIndex);
  const workingData = useSelector((state) => state.floorplan.workingData);
  const [interval, setInterval] = useState(null);
  const [isDragable, setDragable] = useState(false);
  const [isResizable, setResizable] = useState(false);
  const [isOnClick, setIsOnClick] = useState(false);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const hotKeys = {
    delete: ["del", "backspace"],
    copy: ["Ctrl+c", "Cmd+c"],
    paste: ["Ctrl+v", "Cmd+v"],
  };
  const Handler = {
    delete: () => {
      DeleteElement();
    },
    // copy: (e) => {
    //   CopyElement()
    // },
    paste: () => {
      console.log("paste", id, selectedIndex);
      PasteElement();
    },
  };

  useEffect(() => {
    setSizes({ width: item.width, height: item.height, x: item.x, y: item.y });
  }, [item]);
  useEffect(() => {
    if (selectedIndex == -1) {
      setDragable(false);
      setResizable(false);
    } else if (selectedIndex === id) {
      setDragable(true);
      setResizable(true);
    }

    // else {
    //   if (!(isResizable || isDragable)) {
    //     if (id == selectedIndex)
    //       setDragable(true)
    //   }
    // }
  }, [selectedIndex]);
  const onResize = (event, { size, handle }) => {
    setDragable(false);
    const min = 50;
    const cat = ["Wall", "Plant"].indexOf(item.category) === -1;
    if ((size.width >= min && size.height >= min) || !cat) {
      if (
        nagetiveX.indexOf(handle) !== -1 ||
        nagetiveY.indexOf(handle) !== -1
      ) {
        let diffX = 0;
        let diffY = 0;
        if (nagetiveX.indexOf(handle) !== -1) diffX = size.width - item.width;
        if (nagetiveY.indexOf(handle) !== -1) diffY = size.height - item.height;
        const obj = { ...size, x: item.x - diffX, y: item.y - diffY };
        setSizes(obj);
      } else {
        setSizes(size);
      }
    }
  };
  const onResizeStop = (event, { size, handle }) => {
    if (item.width !== size.width || item.height !== size.height) {
      // UPDATE_POSITION_SIZE
      // console.log("resizing stoped", size, item, handle)
      if (
        nagetiveX.indexOf(handle) !== -1 ||
        nagetiveY.indexOf(handle) !== -1
      ) {
        let diffX = 0;
        let diffY = 0;
        if (nagetiveX.indexOf(handle) !== -1) diffX = size.width - item.width;
        if (nagetiveY.indexOf(handle) !== -1) diffY = size.height - item.height;

        const obj = { ...size, x: item.x - diffX, y: item.y - diffY };
        // console.log("diff", diffX, diffY, obj)
        dispatch({ type: "UPDATE_POSITION_SIZE", payload: obj, index: id });
      } else {
        dispatch({ type: "UPDATE_SIZES", payload: size, index: id });
      }
    }
    setDragable(true);
  };
  const onDragEnd = (e, data) => {
    if (item.x !== data.x || item.y !== data.y) {
      dispatch({
        type: "UPDATE_POSITION",
        payload: { x: data.x, y: data.y },
        index: id,
      });
    }
  };
  const onSelect = (selId) => {
    // onSelectItem(id)
    dispatch({ type: "SELECT_ITEM", index: selId });
  };

  const hasEmptyName = () => {
    let flag = true;
    if (selectedIndex != -1) {
      console.log(selectedIndex, workingData.length);
      const currentItem = workingData[selectedIndex];
      if (currentItem.name == "") {
        flag = false;
      }
    }
    return flag;
  };

  const handleClick = () => {
    console.log("Drag and Resize onclick");
    if (move) {
      if (hasEmptyName()) {
        console.log("Drag and Resize onclick move", move);
        callback(isOnClick);
        setIsOnClick(!isOnClick);
        setResizable(!isResizable);
        setDragable(!isDragable);
        onSelect(selectedIndex == id ? -1 : id);
      } else {
        setAlertData({
          open: true,
          severity: "error",
          msg: "Please complete all mandatory * fields before continuing.",
        });
        setTimeout(() => {
          setAlertData({ open: false, severity: "error", msg: "" });
        }, 3000);
      }
    }
    // timer = setTimeout(function () {
    //   if (!prevent) {

    //     if (!isResizable) {
    //       onSelect(isDragable ? -1 : id);
    //       setDragable(!isDragable)

    //     }
    //   }
    //   prevent = false;
    // }, delay);
  };
  const DeleteElement = () => {
    dispatch({ type: "DELETE_ITEM_HOT", index: id });
  };
  // const CopyElement = () => {
  //     dispatch({ type: 'DELETE_ITEM_HOT', index: id })
  // }
  const PasteElement = () => {
    const time = moment().unix().toString();
    const { table_id, object_id, ...newItem } = item;
    dispatch({
      type: "DUPLICATE",
      item: { ...newItem, x: item.x + 50, y: item.y + 50, id: time },
      id,
    });
  };

  const Message = ({ open, handleClose, severity, msg }) => (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity}>
        {msg}
      </Alert>
    </Snackbar>
  );

  const Alert = (props) => (
    <MuiAlert
      elevation={6}
      variant='filled'
      {...props}
      style={{ alignItems: "center", justifyContent: "center" }}
    />
  );

  return (
    <HotKeys keyMap={hotKeys} handlers={Handler}>
      <div
        onClick={handleClick}
        // onDoubleClick={handleDoubleClick}
        // onDoubleClick={handleDoubleClick}
      >
        <Draggable
          key={id}
          bounds='.floor'
          handle='.handle'
          position={{ x: sizes.x * ratio, y: sizes.y * ratio }}
          grid={[1, 1]}
          scale={1}
          disabled={selectedIndex !== id && !isDragable}
          onStop={onDragEnd}>
          <div
            className={
              isDragable && id === selectedIndex
                ? "drag layoutRoot"
                : "layoutRoot"
            }
            style={{ position: "absolute" }}>
            <Resizable
              handle={(h, ref) => (
                <span
                  ref={ref}
                  className={`custom-handle custom-handle-${h}`}
                />
              )}
              height={sizes.height}
              width={sizes.width}
              onResizeStop={onResizeStop}
              onResize={onResize}
              resizeHandles={
                id === selectedIndex && isResizable
                  ? ["sw", "se", "nw", "ne", "w", "e", "n", "s"]
                  : []
              }>
              <Grid
                container
                justify='center'
                style={{ flexDirection: "column" }}>
                <div
                  id={item.id}
                  style={{
                    width: sizes.width * ratio,
                    height: sizes.height * ratio,
                    transform: `rotate(${item.rotate}deg)`,
                  }}
                  className='handle'>
                  <SvgIcon
                    {...item}
                    icon={item.content}
                    width={sizes.width * ratio}
                    height={sizes.height * ratio}
                    name={item.name}
                    color={item.color}
                    stroke={item.color}
                    isRotate={isRotate}
                    style={{ cursor: move ? "pointer" : "default" }}
                  />
                </div>
                {item.content === "kitchen" && (
                  <Typography variant='caption' style={{ color: "white" }}>
                    {validateAlphaNumeric(item.name)}
                  </Typography>
                )}
              </Grid>
            </Resizable>
          </div>
        </Draggable>
        <Message
          open={alertData.open}
          severity={alertData.severity}
          msg={alertData.msg}
          handleClose={() => console.log()}
        />
      </div>
    </HotKeys>
  );
};

export default DragAndResize;
