import "react-perfect-scrollbar/dist/css/styles.css";
import React, { useEffect, useState, useContext } from "react";
import { useRoutes, useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider, Grid, Button } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { PersistGate } from "redux-persist/integration/react";
import { Provider, useSelector, useDispatch } from "react-redux";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import packageInfo from "../package.json";
import GlobalStyles from "./components/GlobalStyles";
import theme from "./theme";
import routes from "./routes";
import StoreConfig from "./redux/store";
import "./css/styles.css";
import "./css/example.css";
import MyContext from "./Provider/MyContext";
import "./css/index.css";
import "./css/App.css";
import ProviderFile from "./Provider/Provider";
import Loader from "./components/Loader";
import IdleTimer from "./utils/IdelTimer";
import { SsoTimer, SsoWarningTimer } from "./utils/SsoTimer";
import { logoutUser } from "./redux/actions";
import CenterModal from "./components/CenterModal";
import { AvenirBlackH2, T, AvenirBlackH3 } from "./utils/text";
import {
  ReadFromSession,
  RemoveFromSession,
  SaveToSession,
} from "./Provider/sessionStorageServices";
import { StorageKey } from "./models/Constant";
import urls from "./urls";
import config from "./config.json";
import { IsAuthenticatedProvider } from "./custom-hooks/useIsAuthenticated";

const App = () => (
  <Provider store={StoreConfig.store}>
    <ProviderFile>
      <PersistGate loading={null} persistor={StoreConfig.persistor}>
        <ThemeBase />
      </PersistGate>
    </ProviderFile>
  </Provider>
);

const ThemeBase = () => {
  const routing = useRoutes(routes);
  const location = useLocation();
  const { pathname, state } = location;
  let appPath = useSelector((state) => state.loader.appPath);
  const backedSessionTimeout = useSelector(
    (state) => state.loginReducer.backendSessionTimeout
  );
  const loginDetails = useSelector((state) => state.loginReducer.loginDetails);
  const expiryCode = useSelector((state) => state.dashboard.setExpiry);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { store } = StoreConfig;
  const Context = useContext(MyContext);
  const [sessionTimeOut, setSessionTimeOut] = useState(false);
  const [timeoutWarning, setTimeoutWarning] = useState(false);
  useEffect(() => {
    if (!appPath || appPath != packageInfo.routepath) {
      dispatch({ type: "SET_APP_PATH", payload: packageInfo.routepath });
      appPath = packageInfo.routepath;
    }
    if (pathname.indexOf(appPath) == -1) {
      navigate(`/${appPath}${pathname}`, { state });
    }
    if (
      ReadFromSession(StorageKey.Is_Sso) &&
      pathname !== "/auth/callback" &&
      pathname !== "/auth/logout" &&
      pathname !== "/auth/login"
    ) {
      SaveToSession(StorageKey.PreviousAppRouteState, pathname);
    }
  }, [pathname]);
  useEffect(() => {
    const codes = [401, 403, 419];
    if (codes.indexOf(expiryCode?.status_code) != -1) {
      const rest_id = ReadFromSession(StorageKey.Rest_id);
      dispatch({ type: "SET_BACKED_SESSION_TIMEOUT", payload: false });
      setSessionTimeOut(false);
      dispatch({ type: "CLEAR" });

      if (ReadFromSession(StorageKey.Is_Sso) == "true") {
        RemoveFromSession(StorageKey.Is_Sso);

        navigate(urls.sso.logout, { state: { rest_id } });
      } else {
        navigate(urls.home);
      }
    }
  }, [expiryCode]);
  useEffect(() => {
    dispatch({ type: "LOADED" });
  }, []);
  useEffect(() => {
    setSessionTimeOut(backedSessionTimeout);
  }, [backedSessionTimeout]);
  useEffect(() => {
    if (loginDetails.sso_session_expiry) {
      const warningTimer = new SsoWarningTimer({
        // sso_session_expiry is given as a date in the future. It is supposed to appear 5 minutes before expiry.
        //* 1000 because api gives epoch time in seconds, and js uses date in miliseconds
        timeout: loginDetails.sso_session_expiry * 1000,
        onTimeout: () => {
          setTimeoutWarning(true);
        },
        onExpired: () => {},
        store,
      });
      return () => {
        warningTimer.cleanUp();
      };
    }
  }, [loginDetails.oauth_token]);
  useEffect(() => {
    if (loginDetails.sso_session_expiry) {
      const timer = new SsoTimer({
        // alternate timeout if sso session
        //* 1000 because api gives epoch time in seconds, and js uses date in milliseconds
        timeout: loginDetails.sso_session_expiry * 1000,
        onTimeout: () => {
          dismissPopup();
          setSessionTimeOut(true);
        },
        onExpired: () => {},
        store,
      });
      return () => {
        timer.cleanUp();
      };
    }
    const timer = new IdleTimer({
      timeout: (loginDetails?.session_size || 30) * 60,
      onTimeout: () => {
        dismissPopup();
        setSessionTimeOut(true);
      },
      onExpired: () => {},
      store,
    });
    return () => {
      timer.cleanUp();
    };
  }, [loginDetails.oauth_token]);
  const handleLogoutCall = () => {
    dispatch(
      logoutUser(
        { oauth_token: store.getState().loginReducer.loginDetails.oauth_token },
        logoutCallback
      )
    );
  };
  const logoutCallback = () => {
    const rest_id = ReadFromSession(StorageKey.Rest_id);
    dispatch({ type: "SET_BACKED_SESSION_TIMEOUT", payload: false });
    setSessionTimeOut(false);
    dispatch({ type: "LOGOUT" });

    // If user is logged in via SSO, then redirect to login prompt page
    if (ReadFromSession(StorageKey.Is_Sso) == "true") {
      RemoveFromSession(StorageKey.Is_Sso);
      navigate(urls.sso.logout, { state: { rest_id } });
    } else {
      navigate(urls.home);
    }
  };
  const dismissPopup = () => {
    setTimeoutWarning(false);
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <Loader />
        <GlobalStyles />
        <CssBaseline />
        <IsAuthenticatedProvider>{routing}</IsAuthenticatedProvider>
      </ThemeProvider>
      {timeoutWarning && (
        <CenterModal
          open={timeoutWarning}
          onClose={dismissPopup}
          borderRadius={5}
          padding={20}>
          <Grid
            container
            style={{ padding: 32, width: 400, borderRadius: 5, height: 258 }}>
            <Grid item xs={12}>
              <AvenirBlackH2
                label={Context.langData.session_timeout_warning_header}
                style={{ fontWeight: 500, fontSize: 18, marginBottom: 16 }}
              />
              <T
                label={Context.langData.session_timeout_warning_body}
                style={{ display: "flex", marginBottom: 32 }}
              />
            </Grid>
            <Button
              variant='contained'
              onClick={() => dismissPopup()}
              style={{ backgroundColor: "#FFB600", width: 336 }}>
              <AvenirBlackH3
                label={Context.langData.session_timeout_warning_button}
              />
            </Button>
          </Grid>
        </CenterModal>
      )}
      {sessionTimeOut && (
        <CenterModal open={sessionTimeOut} borderRadius={5} padding={20}>
          <Grid
            container
            style={{ padding: 32, width: 400, borderRadius: 5, height: 258 }}>
            <Grid item xs={12}>
              <AvenirBlackH2
                label={Context.langData.session_time_out_header}
                style={{ fontWeight: 500, fontSize: 18, marginBottom: 16 }}
              />
              <T
                label={Context.langData.session_time_out_body}
                style={{ display: "flex", marginBottom: 32 }}
              />
            </Grid>
            <Button
              variant='contained'
              onClick={() => handleLogoutCall()}
              style={{ backgroundColor: "#FFB600", height: 72, width: 336 }}>
              <AvenirBlackH3 label={Context.langData.session_time_out_button} />
            </Button>
          </Grid>
        </CenterModal>
      )}
    </>
  );
};

// Set clientSideID to your own Client-side ID. You can find this in
// your LaunchDarkly portal under Account settings / Projects
export default withLDProvider({
  clientSideID: config.featureFlag.clientSideId,
})(App);
