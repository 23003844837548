import setState from "./setState";

const initialState = {
  ordertypes: [],
  paymentTypes: [],
  kitchenName: [],
  printerUnassigned: [],
  orderType_ps: [],
};
function parsePayments(response) {
  const obj = response;
  obj.map((d) => {
    d.id = d.payment_id;
  });
  return obj;
}
function parseOrderTypes(response) {
  const obj = response;
  obj.map((d) => {
    d.id = d.otype_id;
    d.otype_name = d.name;
  });
  return obj;
}
function parseKitchens(response) {
  const obj = response;
  obj.map((d) => {
    d.id = d.kitchen_id;
    d.userName = d.name;
    d.password = d.name;
  });
  return obj;
}
function parseKitchenList(response) {
  const obj = response;
  obj.map((d) => {
    d.id = d.kitchen_id;
    d.name = d.kitchen_name;
  });
  return obj;
}
function metaReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_ORDERTYPES":
      return {
        ...state,
        ordertypes: parseOrderTypes(action.payload),
      };
    case "SET_PAYMENTS":
      return {
        ...state,
        paymentTypes: parsePayments(action.payload),
      };
    case "GET_KITCHEN_NAME":
      return {
        ...state,
        kitchenName: parseKitchens(action.payload),
      };
    case "GET_KITCHEN_PRINTER_UNASSIGNED":
      return {
        ...state,
        printerUnassigned: parseKitchenList(action.payload),
      };
    case "SET_ORDERTYPE_PS":
      return setState(state, action.payload, "orderType_ps");
    case "CLEAR":
      return { ...initialState };

    default:
      return state;
  }
}

export default metaReducer;
