import React, { useState, useEffect, useContext } from "react";
import { Grid, Snackbar } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import styles from "../../../styles/floorplan";

import MyContext from "../../../Provider/MyContext";
import ZoomableArea from "./ZoomableArea";
import DragAndResize from "./DragAndResize";

const FloorArea = ({
  items = [],
  dimensions,
  showElements,
  tempItems = [],
}) => {
  const [size, setSize] = useState({ width: 100, height: 100 });
  const [classnames, setClassNames] = useState("floor solid");
  const classes = styles();
  const dispatch = useDispatch();
  const floorDetails = useSelector((state) => state.floorplan.floorDetails);
  const isFloorSelected = useSelector(
    (state) => state.floorplan.isFloorSelected
  );
  const selectedIndex = useSelector((state) => state.floorplan.selectedIndex);
  const workingData = useSelector((state) => state.floorplan.workingData);
  const [isOnClick, setIsOnClick] = useState(false);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const Context = useContext(MyContext);
  const { currentSection } = useSelector((state) => state.floorplan);
  useEffect(() => {
    console.log(document.getElementById("floor_container").offsetWidth);
  }, [showElements]);

  useEffect(() => {
    const { floor } = floorDetails;
    if (floor) {
      if (floor.type === "solid") {
        setClassNames("floor solid");
      } else {
        const c = `floor ${floor.type}_${floor.orientation}`;
        setClassNames(c);
      }
    }
  }, [floorDetails]);

  const hasEmptyName = () => {
    let flag = true;
    if (selectedIndex != -1) {
      const currentItem = workingData[selectedIndex];
      if (currentItem.name == "") {
        flag = false;
      }
    }
    return flag;
  };
  const handleDoubleClick = () => {
    if (currentSection) {
      if (hasEmptyName()) {
        console.log("double click");
        dispatch({ type: "SET_FLOORSELECTED" });
      } else {
        setAlertData({
          open: true,
          severity: "error",
          msg: Context.langData.please_complite_mandatory,
        });
        setTimeout(() => {
          setAlertData({ open: false, severity: "error", msg: "" });
        }, 3000);
      }
    }
  };

  const handleSigleClick = () => {
    if (hasEmptyName()) {
      console.log(" handleSigleClick isOnClick", isOnClick);
      if (isOnClick) {
        dispatch({ type: "SELECT_ITEM", index: -1 });
        setIsOnClick(!isOnClick);
      } else {
        setIsOnClick(!isOnClick);
        console.log("single click else");
      }
    } else {
      setAlertData({
        open: true,
        severity: "error",
        msg: Context.langData.please_complite_mandatory,
      });
      setTimeout(() => {
        setAlertData({ open: false, severity: "error", msg: "" });
      }, 3000);
    }
  };

  const Message = ({ open, handleClose, severity, msg }) => (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity}>
        {msg}
      </Alert>
    </Snackbar>
  );

  const Alert = (props) => (
    <MuiAlert
      elevation={6}
      variant='filled'
      {...props}
      style={{ alignItems: "center", justifyContent: "center" }}
    />
  );

  const color =
    floorDetails?.floor?.type === "solid" ? floorDetails?.floor?.color : "";
  console.log("color", color, floorDetails);
  const rotation = floorDetails.rotate % 180 == 0;
  const { width } = dimensions;
  const { height } = dimensions;
  // if(!rotation){
  //    height = dimensions.width;
  //   width = dimensions.height;
  // }
  // transform: `rotate(${floorDetails.rotate}deg)`,
  const ratio = width / 800;
  const DropResizeCallback = (value) => {
    console.log("value floor area", value);
    setIsOnClick(value);
  };
  return (
    <ZoomableArea>
      <Grid
        container
        onClick={handleSigleClick}
        onDoubleClick={handleDoubleClick}
        className={classnames}
        style={{
          position: "relative",
          width,
          height,
          borderRadius: 10,
          backgroundColor: color,
        }}>
        {isFloorSelected && (
          <Grid
            container
            style={{
              border: "2px dotted #c6c6c6",
              position: "absolute",
              width: width - 20,
              height: dimensions.height - 20,
              borderRadius: 10,
              backgroundColor: "transparent",
              top: 10,
              left: 10,
            }}
          />
        )}
        <div style={{ width, height }}>
          {items.map((item, index) => {
            const Icon = item.content;
            if (item.content) {
              return (
                <DragAndResize
                  item={item}
                  id={index}
                  ratio={ratio}
                  key={index}
                  callback={DropResizeCallback}
                  newSize={{
                    width: item.width || 100,
                    height: item.height || 100,
                  }}
                />
              );
            }
            <div key={index} style={{ width: 0, height: 0 }} />;
          })}
          {tempItems.map((item, index) => {
            const Icon = item.content;
            if (item.content) {
              return (
                <DragAndResize
                  item={item}
                  id={index}
                  ratio={ratio}
                  key={index}
                  callback={DropResizeCallback}
                  newSize={{
                    width: item.width || 100,
                    height: item.height || 100,
                  }}
                />
              );
            }
            <div key={index} style={{ width: 0, height: 0 }} />;
          })}
        </div>
        <Message
          open={alertData.open}
          severity={alertData.severity}
          msg={alertData.msg}
          handleClose={() => console.log()}
        />
      </Grid>
    </ZoomableArea>
  );
};

export default FloorArea;
