import React, { useState } from "react";
import { Grid, IconButton } from "@material-ui/core";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import _ from "lodash";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import { useSelector, useDispatch } from "react-redux";
import SingleSelect from "../../../components/SingleSelect";

const rotateStep = 90;
const ZoomableArea = ({
  children,
  dimensions = { width: 700, height: 400 },
}) => {
  const [disablePan, setDisable] = useState(true);
  const dispatch = useDispatch();
  const rotate = useSelector((state) => state.floorplan.floorDetails.rotate);
  const selectedIndex = useSelector((state) => state.floorplan.selectedIndex);
  const onChangeScale = (scale) => {
    const dis = scale <= 1;
    console.log("dis", dis, scale);
    if (dis !== disablePan) {
      setDisable(dis);
    }
  };
  const rotateLeft = () => {
    const to =
      rotate > rotateStep ? rotate - rotateStep : 360 + rotate - rotateStep;
    dispatch({ type: "ROTATE", payload: to });
  };
  const rotateRight = () => {
    const to =
      rotate + rotateStep > 360
        ? rotate + rotateStep - 360
        : rotate + rotateStep;
    dispatch({ type: "ROTATE", payload: to });
  };
  console.log(
    "disable",
    disablePan || selectedIndex !== -1,
    disablePan,
    selectedIndex !== -1
  );
  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      style={{ position: "relative" }}>
      <TransformWrapper
        defaultScale={1}
        zoomIn={{ step: 5, animation: false }}
        zoomOut={{ step: 5, animation: false }}
        pan={{ disabled: disablePan || selectedIndex !== -1 }}
        doubleClick={{ disabled: true }}
        options={{
          limitToWrapper: true,
          minScale: 1,
          maxScale: 3,
          centerContent: false,
        }}
        wheel={{ disabled: true, wheelEnabled: false }}>
        {({ zoomIn, zoomOut, resetTransform, scale, setScale }) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TransformComponent>{children}</TransformComponent>
            <BtnGroup
              rotateLeft={rotateLeft}
              rotateRight={rotateRight}
              setScale={setScale}
              resetTransform={resetTransform}
              onChange={onChangeScale}
              scale={scale}
            />
          </div>
        )}
      </TransformWrapper>
    </Grid>
  );
};

const BtnGroup = ({
  scale,
  setScale,
  onChange,
  rotateLeft,
  rotateRight,
  resetTransform,
}) => {
  const scales = [
    // { scale: 0.5, label: '50%' },
    // { scale: 0.75, label: '75%' },
    { scale: 1, label: "100%" },
    { scale: 1.5, label: "150%" },
    { scale: 2.0, label: "200%" },
    { scale: 2.5, label: "250%" },
    { scale: 3.0, label: "300%" },
  ];
  const [isZoom, setIsZoom] = useState(false);
  const setZoomIn = () => {
    const index = _.findIndex(scales, (o) => o.scale == scale);

    if (index < scales.length - 1) {
      const newScale = scales[index + 1].scale;

      callback(newScale);
    }
  };
  const callback = (newScale) => {
    setIsZoom(true);
    if (newScale <= 1) {
      resetTransform();
    } else {
      setScale(newScale, 200, "linear");
    }
    setTimeout(() => {
      onChange(newScale);
      setIsZoom(false);
    }, 300);
  };
  const setZoomOut = () => {
    const index = _.findIndex(scales, (o) => o.scale == scale);

    if (index > 0) {
      const newScale = scales[index - 1].scale;

      callback(newScale);
    }
  };
  const changeScale = (e) => {
    const newScale = e.target.value;

    callback(newScale);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "absolute",
        bottom: "-15px",
        left: "40%",
      }}>
      <div
        style={{
          backgroundColor: "#FFFFFF",
          display: "flex",
          marginBottom: "30px",
          borderRadius: "5px",
          opacity: ".9",
        }}>
        <span style={{ width: 80, paddingTop: "5px" }}>
          <SingleSelect
            list={scales}
            value={scale}
            displayKey='label'
            valueKey='scale'
            handleChange={changeScale}
            singleBorder={false}
          />
        </span>
        <IconButton disabled={isZoom} onClick={setZoomIn}>
          <ZoomInIcon />
        </IconButton>
        <IconButton disabled={isZoom} onClick={setZoomOut}>
          <ZoomOutIcon />
        </IconButton>
        {/* <IconButton onClick={resetTransform} >
          <ZoomOutMapIcon />
        </IconButton> */}
        <IconButton disabled={isZoom} onClick={rotateLeft}>
          <RotateLeftIcon />
        </IconButton>
        <IconButton disabled={isZoom} onClick={rotateRight}>
          <RotateRightIcon />
        </IconButton>
      </div>
    </div>
  );
};
export default ZoomableArea;
