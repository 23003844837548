import * as React from "react";

const LineIcon = ({ color = "#fff", ...props }) => (
  <svg
    width={2}
    height={20}
    viewBox='0 0 2 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path fill={color} d='M.348.76h1.48V20H.348z' />
  </svg>
);

export default LineIcon;
