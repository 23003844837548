import * as React from "react";

const SmallMailIcon = (props) => (
  <svg
    width='24'
    height='17'
    viewBox='0 0 24 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M1.5 1.25H22.5V16.25H1.5V1.25Z'
      stroke='#004C60'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M22.1609 1.80078L14.0169 8.06478C13.4386 8.50964 12.7295 8.75084 11.9999 8.75084C11.2703 8.75084 10.5611 8.50964 9.98287 8.06478L1.83887 1.80078'
      stroke='#004C60'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default SmallMailIcon;
