import moment from "moment";
import { PurchaseOrderStatus } from "../../utils/util";

const initialState = {
  vendors: [],
  stocks: [],
  departments: [],
  recipeItems: [],
  recipeAddons: [],

  costingVendors: [],
  costingKitchens: [],
  editVendors: [],
  kitchenStocks: [],
  groupsData: [],
  stockBalanceView: [],
  issues: [],
  limit: 20,
  indents: [],
  issuesViewData: [],
  indentViewdata: [],
  recipeViewData: [],
  kitchenValue: 0,
  recipeItemDetails: [],
  recipeAddonDetails: [],
};

const IssueType = {
  ISSUE: "Issue",
  RETURN: "Return",
};

const parseVendor = (response) => {
  const obj = response.data;
  obj.map((d) => {
    d.vendor_id = d.id;
    d.name = d.profile.vendorName;
    d.mob = d.profile.phoneNumber;
    d.email = d.profile.email;
  });
  return obj;
};

const parseDepartment = (response) => {
  const obj = response.data;
  obj.map((d) => {
    d.id = d.id;
    d.name = d.name;
    d.kitchen_id = d.extDepartmentId;
  });
  return obj;
};
const parseStocks = (response) => {
  const obj = response.data;
  obj.map((d) => {
    d.id = d.id;
  });
  return obj;
};

const dateConverter = (d) => {
  const setDate = moment.unix(d).format("MMM DD, YYYY");
  return setDate;
};

const dateStringConverter = (d) => {
  const setDate = moment(Date.parse(d)).tz("Etc/UTC").format("MMM DD, YYYY");
  return setDate;
};

const parseIndents = (response) => {
  const obj = response;
  const purchaseOrderStatus = PurchaseOrderStatus();
  obj.data.map((d) => {
    d.id = d.id;
    d.date = moment(d.raisedDate).format("MMM DD, YYYY");
    d.indent_id = d.id;
    d.vendor_name = d.vendorName;
    d.vendor_id = d.vendorId;
    d.total_amount = d.totalAmount.amount / 100.0;
    d.status = purchaseOrderStatus[d.status];
  });

  obj.data.sort((a, b) => b.id - a.id);
  return obj;
};

const parseIndent = (response) => {
  const obj = response;

  if (obj?.fulfillments) {
    obj.fulfillments.sort((a, b) => b.id - a.id);
  }
  obj.status = PurchaseOrderStatus()[obj.status];
  return obj;
};

const parseKitchenStocks = (response) => {
  const obj = response;
  obj?.stocks?.map((d) => {
    if (d.lastReceived != "") {
      d.lastReceived = dateStringConverter(d.lastReceived);
    } else {
      d.lastReceived = "--";
    }
    if (d.lastAdjusted != "") {
      d.lastAdjusted = dateStringConverter(d.lastAdjusted);
    } else {
      d.lastAdjusted = "--";
    }
  });
  return obj;
};

const parseIssues = (response) => {
  const obj = response;
  obj.data.map((d) => {
    d.dateTime = moment(d.dateTime).format("MMM DD, YYYY");
    d.IssueId = d.id;
    d.type = IssueType[d.type];
  });
  obj.data.sort((a, b) => b.IssueId - a.IssueId);
  return obj;
};
const parseRecipeItem = (response) => {
  const obj = response;
  obj.map((d) => {
    d.recipeStatus = d.is_recipe_available ? "Available" : "Unavailable";
  });
  return obj;
};
const parseRecipeAddons = (response) => {
  const obj = response;
  obj.map((d) => {
    d.recipeStatus = d.recipe_id > 0 ? "Available" : "Unavailable";
  });
  return obj;
};
const convertDecimal = (val) => {
  const v = parseInt(val);
  let value = val;
  if (v !== val) {
    value = val.toFixed(2);
  }
  console.log("val", val, value);
  return value;
};
const parseConsumptions = (response) => {
  response.map((res) => {
    res.variation = res.variation != 0 ? `${res.variation}%` : "-";
  });
  return response;
};
const inventory = (state = initialState, action) => {
  switch (action.type) {
    case "SET_VENDORS":
      return {
        ...state,
        vendors: parseVendor(action.payload),
      };
    case "SET_KITCHEN":
      return {
        ...state,
        departments: parseDepartment(action.payload),
      };
    case "SET_STOCKS":
      return {
        ...state,
        stocks: parseStocks(action.payload),
      };
    case "SET_RECIPE_ITEMS":
      return {
        ...state,
        recipeItems: parseRecipeItem(action.payload),
      };
    case "SET_RECIPE_ADDONS":
      return {
        ...state,
        recipeAddons: parseRecipeAddons(action.payload),
      };

    case "SET_COSTING_VENDORS":
      return {
        ...state,
        costingVendors: action.payload,
      };
    case "SET_COSTING_KITCHENS":
      return {
        ...state,
        costingKitchens: action.payload,
      };
    case "SET_KITCHEN_STOCKS":
      return {
        ...state,
        kitchenStocks: parseKitchenStocks(action.payload),
      };
    case "SET_KITCHEN_VALUE":
      return {
        ...state,
        kitchenValue: action.payload,
      };
    case "SET_GROUPS_DATA":
      return {
        ...state,
        groupsData: action.payload,
      };
    case "SET_STOCK_BALANCE":
      return {
        ...state,
        stockBalanceView: action.payload,
      };
    case "SET_ISSUES":
      return {
        ...state,
        issues: parseIssues(action.payload),
      };
    case "SET_INDENT":
      return {
        ...state,
        indents: parseIndents(action.payload),
      };
    case "SET_ISSUES_VIEW":
      return {
        ...state,
        issuesViewData: action.payload,
      };
    case "SET_INDENT_VIEW":
      return {
        ...state,
        indentViewdata: action.payload,
      };
    case "CLEAR":
      return {
        ...initialState,
      };
    case "SET_RECIPE_VIEW":
      return {
        ...state,
        recipeViewData: action.payload,
      };
    case "SET_RECIPE_ITEMS_DETAIL":
      return {
        ...state,
        recipeItemDetails: action.payload,
      };
    case "SET_RECIPE_ADDONS_DETAIL":
      return {
        ...state,
        recipeAddonDetails: action.payload,
      };
    default:
      return state;
  }
};

export default inventory;
