import React, { useState, useCallback, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Button, MenuItem, Menu } from "@material-ui/core";
import _ from "lodash";
import moment from "moment-timezone";
import MyContext from "../../../Provider/MyContext";
import DownlodeIcon from "../../../components/SVG/DownlodeIcon";
import { AvenirBlackH4 } from "../../../utils/text";
import { fetchConsolidatedData } from "../../../redux/actions";
import MonthPicker from "../../../components/MonthPicker";
import RefreshIcon from "../../../components/SVG/RefreshIcon";
import ReportsTable from "../../../components/ReportsTable";
import { downloadConsolidateReport } from "../../../utils/excel";
import { downloadConsolidatedPdfDocument } from "../../../components/PDFTableGenerator";

const Consolidated = () => {
  const now = new Date();
  const [table, setTable] = useState([]);
  const Context = useContext(MyContext);
  const consolidatedData = useSelector(
    (state) => state.inventoryReportReducer.consolidated
  );
  const [month, setMonth] = useState({
    month: now.getMonth() + 1,
    year: now.getFullYear(),
  });
  const [consolidateColumns, setConsolidateColumns] = useState([
    { field: "itemName", display: Context.langData.stock_name, size: "20%" },
    { field: "itemUnitTypeCode", display: Context.langData.units, size: "8%" },
    {
      field: "openingBalance",
      display: Context.langData.opening_balance,
      size: "12%",
    },
    {
      field: "closingBalance",
      display: Context.langData.closing_balance,
      size: "12%",
    },
    { field: "issuedQuantity", display: Context.langData.issual, size: "12%" },
    {
      field: "purchasedQuantity",
      display: Context.langData.purchasal,
      size: "12%",
    },
    {
      field: "consumedQuantity",
      display: Context.langData.consumption,
      size: "12%",
    },
    {
      field: "disposedQuantity",
      display: Context.langData.disposal,
      size: "12%",
    },
  ]);

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    refreshConsolidateList();
  }, [month]);
  const refreshConsolidateList = () => {
    const fromDate = moment({
      year: month.year,
      month: month.month - 1,
      day: 1,
    });
    const toDate = moment(fromDate).endOf("month");

    dispatch(
      fetchConsolidatedData({
        from_date: fromDate.startOf("day").format("YYYY-MM-DD"),
        to_date: toDate.startOf("day").format("YYYY-MM-DD"),
      })
    );
  };
  const handleMonthChange = useCallback((d) => {
    setMonth(d);
  }, []);
  useEffect(() => {
    if (consolidatedData?.length > 0) {
      setTable(consolidatedData);
    } else {
      setTable([]);
    }
  }, [consolidatedData]);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const downloadExcel = () => {
    console.log(table);
    downloadConsolidateReport(table);
    handleCloseMenu();
  };

  const downloadPDF = () => {
    downloadConsolidatedPdfDocument(
      consolidateColumns,
      table,
      Context.langData.consolidated_inventory_report,
      month,
      "ConsolidatedInventory"
    );
    handleCloseMenu();
  };

  const handleSort = (itemName, desc, index) => {
    const columnData = [...consolidateColumns];
    columnData[index] = { ...columnData[index], desc };
    setConsolidateColumns(columnData);
    let sortItemwiseSales;
    if (itemName == "itemName") {
      sortItemwiseSales = _.orderBy(
        table,
        [(item) => item[itemName].toLowerCase()],
        [desc ? "desc" : "asc"]
      );
    } else {
      sortItemwiseSales = _.orderBy(
        table,
        [(item) => convertNAToZero(item, itemName)],
        [desc ? "desc" : "asc"]
      );
    }
    setTable(sortItemwiseSales);
  };
  const convertNAToZero = (item, itemName) => {
    if (item[itemName] == "N/A") {
      return 0;
    }
    return item[itemName];
  };
  return (
    <Grid
      data-qaid='consolidated_main_grid'
      container
      style={{ paddingLeft: 20, paddingRight: 20 }}>
      <Grid
        data-qaid='consolidated_child_grid'
        container
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <Grid data-qaid='consolidated_index_monthpicker' item xs={12} lg={3}>
          <AvenirBlackH4
            data-qaid='consolidated_createlabel'
            label={Context.langData.date}
          />
          <MonthPicker
            selectedDate={month}
            handleDateChange={handleMonthChange}
          />
        </Grid>
        <Grid
          data-qaid='consolidated_index_head1_grid'
          item
          xs={12}
          lg={9}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}>
          <Grid
            style={{
              flexDirection: "row",
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
            }}
            onClick={() => refreshConsolidateList()}>
            <RefreshIcon />
            <AvenirBlackH4
              data-qaid='consolidated_create_label'
              label={Context.langData.refresh}
              style={{ marginLeft: 5, marginRight: 20, color: "#004C60" }}
            />
          </Grid>
          <Button
            onClick={(event) => setAnchorEl(event.currentTarget)}
            data-qaid='consolidated_index_h1_download'
            style={{
              backgroundColor: "#FFB600",
              marginRight: 0,
              height: 40,
              width: "149px",
            }}
            variant='contained'
            id='cancel'>
            <DownlodeIcon
              data-qaid='consolidated_index_h1_downlodeicon'
              style={{ marginRight: 10 }}
            />
            <AvenirBlackH4
              data-qaid='consolidated_create_label'
              label={Context.langData.download}
            />
          </Button>
          <Menu
            id='simple-menu-download'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}>
            <MenuItem onClick={downloadExcel}>
              {Context.langData.export_excel}
            </MenuItem>
            <MenuItem onClick={downloadPDF}>
              {Context.langData.export_pdf}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>

      <Grid
        data-qaid='consolidated_grid_item'
        item
        style={{ marginTop: 20, width: "100%" }}
        lg={12}>
        <ReportsTable
          data-qaid='consolidated_simpletabel'
          columns={consolidateColumns}
          rows={table}
          handleSort={(itemName, desc, colIndex) =>
            handleSort(itemName, desc, colIndex)
          }
        />
      </Grid>
    </Grid>
  );
};

export default Consolidated;
