import React from "react";
import MyContext from "./MyContext";
import en from "../languageFiles/en.json";
import fr from "../languageFiles/fr.json";
export default class MyProvider extends React.Component {
  state = {
    time: 99,
    lang: "en",
    langData: {},
  };
  componentDidMount() {
    this.setState({ langData: en });
  }

  changeLang(lang) {
    this.setState({ lang });
    switch (lang) {
      case "en":
        this.setState({ langData: en });
        break;
      case "fr":
        this.setState({ langData: fr });
        break;
      default:
        this.setState({ langData: en });
    }
  }

  render() {
    console.log(
      "Provider called",
      this.state.lang,
      "langData",
      this.state.langData
    );
    return (
      <MyContext.Provider
        value={{
          ...this.state,
          saveTimeOffset: (time) => {
            this.setState({
              time,
            });
          },
          changeLang: this.changeLang,
        }}>
        {this.props.children}
      </MyContext.Provider>
    );
  }
}
