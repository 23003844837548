import React, { useEffect, useState, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import _ from "lodash";
import CardView from "../../components/CardView";
import RightDrawer from "../../components/RightDrawer";
import styles from "../../styles";
import { fetchFloorPlans, fetchOrderTypes } from "../../redux/actions";
import { H2, H6, AvenirBlackH4 } from "../../utils/text";
import MyContext from "../../Provider/MyContext";
import Message from "../../components/Message";
import CirclePluse from "../../components/SVG/CirclePluse";
import DrawerCreateFloorplan from "./drawerCreateFloorplan";
import DrawerSettings from "./drawerSettings";

const FloorPlan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [CardData, setData] = useState({ active: [], inactive: [] });
  const [isOpenDrawer, toggleDrawer] = useState(false);
  const [enable, setEnable] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [createNewFloorDrawer, toggleNewFloorDrawer] = useState(false);
  const style = styles({ padding: 20 });
  const floorplans = useSelector((state) => state.floorplan.floorplans);
  const ordertypes = useSelector((state) => state.metaReducer.ordertypes);
  const Context = useContext(MyContext);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const userPermissions = useSelector(
    (state) => state.loginReducer.userPermissions
  );
  const [msg, setMsg] = useState({ open: false, msg: "", severity: "success" });

  const isFloorPlanHasPermission = useMemo(() => {
    const isIndex = userPermissions.findIndex((e) => e.key == "floor_plans");
    if (isIndex != -1) {
      if (userPermissions[isIndex].status) {
        return true;
      }
      return false;
    }
    return false;
  }, [userPermissions]);
  useEffect(() => {
    if (isFloorPlanHasPermission) {
      dispatch(fetchOrderTypes());
      dispatch(fetchFloorPlans());

      dispatch({ type: "CLEAR_FLOORPLAN" });
    }
  }, []);
  useEffect(() => {
    if (floorplans.length > 0) {
      floorplans.map((fl) => {
        const otype_names = [];
        fl.section.map((se) => {
          const otype = _.find(ordertypes, (o) => o.otype_id == se.otype_id);
          if (otype) {
            if (otype_names.indexOf(otype.name) == -1) {
              otype_names.push(otype.name);
            }
          }
        });
        fl.otype_names = otype_names.join(", ");
      });
      const active = _.filter(floorplans, (p) => p.is_active === 1);
      const inactive = _.filter(floorplans, (p) => p.is_active !== 1);
      setData({ active, inactive });
    } else {
      setData({ active: [], inactive: [] });
    }
  }, [floorplans, ordertypes]);
  const onEdit = (item) => {
    console.log(item);
    navigate("/floorplan/create", { state: item });
  };
  const getOrderTypeName = (id) => {
    let oName = "";
    if (ordertypes.length > 0) {
      const array = ordertypes.filter((e) => e.otype_id === id);
      if (array.length > 0) {
        oName = array[0].otype_name;
      }
    }

    return oName;
  };
  const createCallback = (isSucess) => {
    toggleNewFloorDrawer(false);
    if (isSucess) {
      setMsg({
        open: true,
        msg: Context.langData.floorplan_create_succ,
        severity: "success",
      });
    } else {
      setMsg({
        open: true,
        msg: Context.langData.floorplan_create_failed,
        severity: "error",
      });
    }
  };
  const copyCallback = (isSucess) => {
    toggleDrawer(false);
    if (isSucess) {
      setMsg({
        open: true,
        msg: Context.langData.floor_copy_succ,
        severity: "success",
      });
    } else {
      setMsg({
        open: true,
        msg: Context.langData.floor_copy_failed,
        severity: "error",
      });
    }
  };
  const deleteCallback = (isSucess) => {
    toggleDrawer(false);
    if (isSucess) {
      setMsg({
        open: true,
        msg: Context.langData.floorplan_delete_succ,
        severity: "success",
      });
    } else {
      setMsg({
        open: true,
        msg: Context.langData.floorplan_delete_failed,
        severity: "error",
      });
    }
  };
  return (
    <Grid container style={{ backgroundColor: "white", overflow: "hidden" }}>
      <Grid item xs={12}>
        <Grid
          container
          justify='space-between'
          style={{ paddingLeft: 56, alignItems: "center", paddingTop: 30 }}>
          <Grid item xs={12} md={6}>
            <span className={style.subheader}>
              <H6 label={Context.langData.my_floor_plan} />
            </span>
          </Grid>
          {isFloorPlanHasPermission ? (
            <Grid item xs={12} md={6}>
              <Grid container justify='flex-end'>
                {/* <Button variant="contained" style={{ marginRight: 10, backgroundColor: '#00B6C9' }} color="default" >{Context.langData.manage_floor_plan}</Button> */}
                <Button
                  variant='contained'
                  style={{
                    marginRight: 20,
                    backgroundColor: "#FFB600",
                    height: 40,
                    width: 157,
                  }}
                  onClick={() => {
                    toggleNewFloorDrawer(true);
                  }}>
                  <CirclePluse data-qaid='kitchenList_circleicon' />
                  <AvenirBlackH4
                    data-qaid='kitchenList_createlabel'
                    label={Context.langData.create_new}
                    style={{ paddingLeft: 5 }}
                  />
                </Button>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        {isFloorPlanHasPermission ? (
          <>
            <Grid container justify='flex-start' style={{ marginTop: 20 }}>
              {CardData.active.length > 0 && (
                <Grid
                  container
                  style={{ backgroundColor: "#F3FAFD", padding: 20 }}
                  className={clsx(style.marginVertical, style.paddingVertical)}>
                  <Grid container>
                    <H2
                      className={style.sub_header}
                      label={Context.langData.active_floor_plan}
                    />
                  </Grid>
                  <Grid container>
                    {CardData.active.map((item, index) => (
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <CardView
                          backgroundColor={false}
                          item={item}
                          orderTypeName={getOrderTypeName(item.otype_id)}
                          key={index}
                          onEdit={onEdit}
                          isActive
                          onSettings={(item) => {
                            setSelectedFloor(item);
                            toggleDrawer(true);
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid container justify='flex-start' style={{ marginTop: 20 }}>
              {CardData.inactive.length > 0 && (
                <Grid container className={style.padding}>
                  <Grid container>
                    <H2
                      className={style.sub_header}
                      label={Context.langData.inactive_floor_plan}
                    />
                  </Grid>
                  <Grid container style={{ paddingHorizontal: 10 }}>
                    {CardData.inactive.map((item, index) => (
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <CardView
                          backgroundColor
                          item={item}
                          orderTypeName={getOrderTypeName(item.otype_id)}
                          key={index}
                          onEdit={onEdit}
                          onSettings={(item) => {
                            setSelectedFloor(item);
                            toggleDrawer(true);
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        ) : (
          <Grid
            data-qaid='floorPlan_nopermissions_View'
            item
            lg={12}
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              marginTop: "20%",
              marginBottom: "80%",
            }}>
            <H6
              data-qaid='floorPlan_nopermissions_message'
              label={Context.langData.no_permission_error}
              style={{
                textAlign: "center",
                width: "55%",
                fontWeight: "bold",
              }}
            />
          </Grid>
        )}
        <RightDrawer
          open={isOpenDrawer}
          onCloseOutside={() => {
            toggleDrawer(false);
          }}
          padding={1}>
          <DrawerSettings
            item={selectedFloor}
            onClose={() => {
              toggleDrawer(false);
            }}
            copyCallback={copyCallback}
            deleteCallback={deleteCallback}
          />
        </RightDrawer>

        <RightDrawer
          open={createNewFloorDrawer}
          onCloseOutside={() => {
            toggleNewFloorDrawer(false);
          }}>
          <DrawerCreateFloorplan
            onClose={() => {
              toggleNewFloorDrawer(false);
            }}
            callback={createCallback}
          />
        </RightDrawer>
      </Grid>
      <Message
        open={msg.open}
        severity={msg.severity}
        msg={msg.msg}
        handleClose={() =>
          setMsg({ open: false, msg: "", severity: "success" })
        }
      />
    </Grid>
  );
};
export default FloorPlan;
