import React, { useState, useEffect, useContext } from "react";
import { Button, CardContent, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  fetchRoleDetails,
  editRole,
  fetchRoleNameCheck,
} from "../../../redux/actions";
import {
  AvenirBlackH4,
  AvenirBlackH7,
  AvenirBlackH2,
  H5,
} from "../../../utils/text";
import Back from "../../../components/SVG/Back";
import TextInputComponent from "../../../components/TextInputComponent";
import styles from "../../../styles/index";
import MyContext from "../../../Provider/MyContext";
import CustomCheckbox from "../../../components/CustomCheckbox";
import SnackBar from "../../../components/SnackBar";
import { validateAlphaNumeric } from "../../../utils/util";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    fontSize: "18px",
  },
  input: {
    color: "black !important",
    fontSize: 14,
    backgroundColor: "#FFF",
    borderRadius: 4,
  },
  notchedOutline: {
    border: "1px solid #707272 !important",
  },
  hrStyles: {
    marginTop: "1%",
    marginLeft: " 1%",
    marginRight: "1%",
    border: "1px solid #EEE",
  },
  contactInfoMainView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "1%",
    marginLeft: "1%",
    marginRight: "1%",
  },
  labelRoot: {},
}));

const EditRole = (props) => {
  const style = styles();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [snackbar, setSnackbar] = useState(null);
  const Permissions = useSelector((state) => state.profileReducers.permissions);
  const RolePermissions = useSelector(
    (state) => state.profileReducers.rolePermissions
  );
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [btnDisable, setBtnDisable] = useState(true);
  const Context = useContext(MyContext);
  const [checkedList, setCheckedList] = useState([]);
  const [permissionItem, setPermissionItem] = useState([]);
  const [errorStatus, setErrorStatus] = useState(false);
  const [emailExists, setEmailExists] = useState(false);

  useEffect(() => {
    if (state && state.id) {
      dispatch(fetchRoleDetails({ role_id: state.id }, onFetchRoleCallback));
    }
  }, []);

  const onBlurName = (e) => {
    if (e.target.value) {
      dispatch(
        fetchRoleNameCheck({ name: e.target.value }, (status, data) => {
          if (status) {
            if (data.exist) {
              setEmailExists(true);
            } else {
              setEmailExists(false);
            }
          } else {
          }
        })
      );
    }
  };

  function onFetchRoleCallback(response) {
    let ids = [];
    if (response.permissions) {
      ids = response.permissions.map((selectedItemId) =>
        parseInt(selectedItemId)
      );
    }
    convertPermissionArray(
      Permissions,
      response.role_application,
      response.permissions
    );
    setName(validateAlphaNumeric(response.name));
    setDescription(response.description);
    if (response.role_application && response.role_application.length > 0) {
      const arr = [];
      const arr1 = [];
      response.role_application.map((item) => {
        const filterData = Permissions.applications.filter(
          (e) => e.name == item
        );
        arr.push(filterData);
        const filterpermissionData = RolePermissions.filter(
          (pItem) => pItem.application_id == filterData[0].application_id
        );
        const obj = {};
        let allChecked = true;
        filterpermissionData.map((e) => {
          if (ids.indexOf(e.permission_id) == -1) {
            allChecked = false;
          }
        });
        obj.id = filterData[0].application_id;
        obj.name = filterData[0].name;
        obj.Permissions = filterpermissionData;
        obj.selectAll = allChecked;
        obj.isError = false;
        arr1.push(obj);
      });
      setPermissionItem(arr1);
      setCheckedList(arr);
    }
    console.log("selected permisions", ids);
    setSelectedPermissions(ids);
  }
  useEffect(() => {
    if (
      Permissions &&
      Permissions.permissions &&
      Permissions.permissions.length > 0
    ) {
      const arr = [];
      Permissions.applications.map((item) => {
        const obj = {};
        const filterPermission = RolePermissions.filter(
          (e) => e.application_id == item.application_id
        );
        obj.id = item.application_id;
        obj.name = item.name;
        obj.Permissions = filterPermission;
        obj.selectAll = false;
        obj.isError = false;
        arr.push(obj);
      });
      console.log(arr, "arrrrrrrrrrr");
      setPermissionItem(arr);
    }
  }, [Permissions]);

  function enableSaveButton(name, description) {
    if (name && description) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  }
  function changeText(value, key) {
    let Name = name;
    let Desc = description;
    if (key == "name") {
      Name = validateAlphaNumeric(value);
    } else {
      Desc = value;
    }
    setName(Name);
    setDescription(Desc);
    enableSaveButton(Name, Desc);
  }
  function convertPermissionArray(array, selectedArray, ids) {
    const newArray = [];
    if (selectedArray.length) {
      for (let x = 0; x < selectedArray.length; x++) {
        var obj = {};
        var applicationId = array.applications.filter(
          (e) => e.name === selectedArray[x]
        );
        if (applicationId.length) {
          var permissionsArray = array.permissions.filter(
            (e) => e.application_id === applicationId[0].application_id
          );
          obj.name = selectedArray[x];
          obj.id = permissionsArray[0].application_id;
          obj.Permissions = permissionsArray;
          obj.selectAll = false;
          newArray.push(obj);
        }
      }
    } else if (array.permissions.length) {
      for (let x = 0; x < array.applications.length; x++) {
        var obj = {};
        var permissionsArray = array.permissions.filter(
          (e) => e.application_id === array.applications[x].application_id
        );
        obj.name = array.applications[x].name;
        obj.id = array.applications[x].application_id;
        obj.Permissions = permissionsArray;
        obj.selectAll = false;
        newArray.push(obj);
      }
    }
    if (state.id) {
      changeCheckCondition(newArray, ids);
    } else {
      setPermissions(newArray);
    }
  }
  function changeCheckCondition(array, selectedIds) {
    if (selectedIds) {
      let ids = [];
      if (selectedIds) {
        ids = selectedIds.map((selectedItemId) => parseInt(selectedItemId));
      }
      ids.map((itemId) => {
        if (array.length) {
          const newArray = [...array];
          array.map((item) => {
            const permissionObj = item.Permissions.filter(
              (e) => e.permission_id === itemId
            );
            if (permissionObj.length > 0) {
              const ApplicationPermissions = array.filter(
                (element) => element.id == item.id
              );
              let length = 0;
              if (
                ApplicationPermissions.length > 0 &&
                ApplicationPermissions[0].Permissions.length
              ) {
                ApplicationPermissions[0].Permissions.map((permissionItem) => {
                  const ispresent = ids.filter(
                    (e2) => e2 === permissionItem.permission_id
                  );
                  if (ispresent.length > 0) {
                    length++;
                  } else {
                  }
                });
              }
              if (length == ApplicationPermissions[0].Permissions.length) {
                const elementsIndex = array.findIndex(
                  (indexElement) => indexElement.id == item.id
                );
                newArray[elementsIndex] = {
                  ...newArray[elementsIndex],
                  selectAll: true,
                };
              } else {
              }
            } else {
            }
          });
          setPermissions(newArray);
        }
      });
      setSelectedPermissions(ids);
    } else {
      // nothing
    }
  }
  function submit() {
    const ids = selectedPermissions;
    let flag = true;
    const permissionsArray = [...permissionItem];
    for (let j = 0; j < permissionsArray.length; j++) {
      if (!permissionsArray[j].isDisable) {
        const per = permissionsArray[j];
        let length = 0;
        const permission = per.Permissions;
        for (let i = 0; i < permission.length; i++) {
          const p = permission[i];
          if (ids.indexOf(parseInt(p.permission_id)) !== -1) {
            length = 1;
            break;
          }
        }
        if (length == 0) {
          flag = false;
          permissionsArray[j] = { ...permissionsArray[j], isError: true };
        } else {
        }
      }
    }
    setPermissionItem(permissionsArray);
    if (ids.length > 0) {
      if (flag) {
        dispatch(
          editRole(
            {
              role_id: state.id,
              name,
              description,
              permissions: ids,
            },
            onCallback
          )
        );
      } else {
      }
    } else {
      setSnackbar({
        message: "Select at least one permission",
        severity: "error",
      });
    }
  }
  function onCallback(response, message) {
    if (response) {
      if (state && state.from === "role") {
        navigate("/home/profile", {
          state: {
            tab: 0,
            emp: false,
            createCustomer: true,
            message: Context.langData.role_edit_successfully,
            ts: moment().unix(),
          },
        });
      } else if (state && state.from === "employee") {
        navigate("/home/profile", {
          state: {
            tab: 0,
            emp: true,
            createCustomer: false,
            message: Context.langData.role_edit_successfully,
            ts: moment().unix(),
          },
        });
      }
    } else {
      setSnackbar({
        message:
          message === "Role is not editable"
            ? "Super Admin Role is not editable"
            : message,
        severity: "error",
      });
    }
  }
  function backtoHome() {
    if (state && state.from === "role") {
      navigate("/home/profile", { state: { tab: 0, emp: false } });
    } else if (state && state.from === "employee") {
      navigate("/home/profile", { state: { tab: 0, emp: true } });
    }
  }
  function checkAll(id, isChecked) {
    const permission = [...selectedPermissions];
    const permissionsArray = permissionItem.filter(
      (e) => e.id === parseInt(id)
    );
    if (permissionsArray.length > 0) {
      const elementsIndex = permissionItem.findIndex(
        (element) => element.id == parseInt(id)
      );
      const newArray = [...permissionItem];
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        selectAll: !isChecked,
        isError: false,
      };
      setPermissionItem(newArray);
      if (!isChecked) {
        const selectedRestPermissions = RolePermissions.filter(
          (e) => e.application_id == parseInt(id)
        );
        selectedRestPermissions.map((item) => {
          const index = permission.indexOf(item.permission_id);
          if (index == -1) {
            permission.push(item.permission_id);
          }
        });
      } else {
        permissionsArray[0].Permissions.map((item) => {
          const index = permission.indexOf(item.permission_id);
          if (index == -1) {
          } else {
            permission.splice(index, 1);
          }
        });
      }
    } else {
      const permissionIndex = permissionItem.findIndex((e) => e.id === id);
      const permissionData = permissionItem[permissionIndex];
      permissionData.Permissions.map((item) => {
        permission.push(item.permission_id);
      });
      permissionData.selectAll = false;
      permissionItem[permissionIndex] = permissionData;
      setPermissionItem(permissionItem);
    }
    setSelectedPermissions(permission);
    enableSaveButton(name, description);
  }

  function checkItList(item) {
    const id = item.permission_id;
    const { key } = item;
    const permissions1 = [...selectedPermissions];
    const index = permissions1.indexOf(id);
    if (index == -1) {
      permissions1.push(id);
      if (key == "reprint_tickets" || key == "settings") {
        var perObj = Permissions.permissions.filter(
          (e) => e.key == "manage_tickets"
        );
        if (perObj.length > 0) {
          var isIndex = permissions1.indexOf(perObj[0].permission_id);
          if (isIndex == -1) {
            permissions1.push(perObj[0].permission_id);
          }
        }
      }
      if (key == "view_transaction_details" || key == "view_hawkeye") {
        var perObj = Permissions.permissions.filter(
          (e) => e.key == "transactions"
        );
        if (perObj.length > 0) {
          var isIndex = permissions1.indexOf(perObj[0].permission_id);
          if (isIndex == -1) {
            permissions1.push(perObj[0].permission_id);
          }
        }
      }
      if (key == "email_report") {
        const reports = ["sale_reports", "inventory_reports"];
        reports.map((reportItem) => {
          const perObj = Permissions.permissions.filter(
            (e) => e.key == reportItem
          );
          if (perObj.length > 0) {
            const isIndex = permissions1.indexOf(perObj[0].permission_id);
            if (isIndex == -1) {
              permissions1.push(perObj[0].permission_id);
            }
          }
        });
      }
      if (key == "update_availability" || key == "menu_addons") {
        var perObj = Permissions.permissions.filter(
          (e) => e.key == "menu_items"
        );
        if (perObj.length > 0) {
          var isIndex = permissions1.indexOf(perObj[0].permission_id);
          if (isIndex == -1) {
            permissions1.push(perObj[0].permission_id);
          }
        }
      }
      permissionItem.map((item) => {
        const permissionObj = item.Permissions.filter(
          (e) => e.permission_id === id
        );
        if (permissionObj.length > 0) {
          let length = 0;
          if (item.Permissions.length > 0) {
            item.Permissions.map((item1) => {
              const ispresent = permissions1.filter(
                (e) => e === item1.permission_id
              );
              if (ispresent.length > 0) {
                length++;
              } else {
              }
            });
          }
          const newArray = [...permissionItem];
          const elementsIndex = permissionItem.findIndex(
            (element) => element.id == item.id
          );
          if (length == item.Permissions.length) {
            newArray[elementsIndex] = {
              ...newArray[elementsIndex],
              selectAll: true,
              isError: false,
            };
          } else {
            newArray[elementsIndex] = {
              ...newArray[elementsIndex],
              selectAll: false,
              isError: false,
            };
          }
          setPermissionItem(newArray);
        }
      });
    } else {
      permissions1.splice(index, 1);
      console.log("keykey", key, "index,", index);
      if (key == "manage_tickets") {
        var kdsList = ["reprint_tickets", "settings"];
        kdsList.map((item) => {
          const pId = Permissions.permissions.filter((e) => e.key == item);
          if (pId.length > 0) {
            const isIndex = permissions1.indexOf(pId[0].permission_id);
            if (isIndex != -1) {
              permissions1.splice(isIndex, 1);
            }
          }
        });
      }
      if (key == "transactions") {
        var kdsList = ["view_transaction_details", "view_hawkeye"];
        kdsList.map((item) => {
          const pId = Permissions.permissions.filter((e) => e.key == item);
          if (pId.length > 0) {
            const isIndex = permissions1.indexOf(pId[0].permission_id);
            if (isIndex != -1) {
              permissions1.splice(isIndex, 1);
            }
          }
        });
      }
      if (key == "sale_reports" || key == "inventory_reports") {
        var isIndex = true;
        if (key == "sale_reports") {
          var pId = Permissions.permissions.filter(
            (e) => e.key == "inventory_reports"
          );
          if (pId.length > 0) {
            var isIndex = permissions1.indexOf(pId[0].permission_id);
            if (isIndex != -1) {
              isIndex = false;
            }
          }
        } else {
          var pId = Permissions.permissions.filter(
            (e) => e.key == "sale_reports"
          );
          if (pId.length > 0) {
            var isIndex = permissions1.indexOf(pId[0].permission_id);
            if (isIndex != -1) {
              isIndex = false;
            }
          }
        }
        if (isIndex) {
          var kdsList = ["email_report"];
          kdsList.map((item) => {
            const pId = Permissions.permissions.filter((e) => e.key == item);
            if (pId.length > 0) {
              const isIndex = permissions1.indexOf(pId[0].permission_id);
              if (isIndex != -1) {
                permissions1.splice(isIndex, 1);
              }
            }
          });
        }
      }
      if (key == "menu_items") {
        var kdsList = ["update_availability", "menu_addons"];
        kdsList.map((item) => {
          const pId = Permissions.permissions.filter((e) => e.key == item);
          if (pId.length > 0) {
            const isIndex = permissions1.indexOf(pId[0].permission_id);
            if (isIndex != -1) {
              permissions1.splice(isIndex, 1);
            }
          }
        });
      }
      permissionItem.map((item) => {
        const permissionObj = item.Permissions.filter(
          (e) => e.permission_id === parseInt(id)
        );
        if (permissionObj.length > 0) {
          const elementsIndex = permissionItem.findIndex(
            (element) => element.id == item.id
          );
          const newArray = [...permissionItem];
          newArray[elementsIndex] = {
            ...newArray[elementsIndex],
            selectAll: false,
          };
          setPermissionItem(newArray);
        }
      });
    }
    if (permissions1.length > 0) {
      setErrorStatus(false);
    } else {
    }
    setSelectedPermissions(permissions1);
    enableSaveButton(name, description);
  }
  // function capitalize(s) {
  //   if (typeof s !== "string") return "";
  //   var strs = s.toLowerCase().split(" ");
  //   return strs
  //     .map((ss) => {
  //       return ss.charAt(0).toUpperCase() + ss.slice(1).toLowerCase();
  //     })
  //     .join(" ");
  // }
  const btnProps = {};
  if (btnDisable) {
    btnProps.disabled = true;
  }

  const handleDisable = (item, id) => {
    let isDisable = false;
    const index = RolePermissions.findIndex(
      (e) => e.key == item && e.application_id == id
    );
    if (index != -1) {
      isDisable = true;
    }
    return isDisable;
  };

  return (
    <Grid
      container
      data-qaid='editRole_mainGrid'
      style={{ backgroundColor: "#EEE" }}>
      <Grid
        container
        data-qaid='editRole_subGrid1'
        spacing={2}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "#FFF",
          paddingTop: "25px",
          paddingBottom: "25px",
        }}>
        <Grid
          item
          data-qaid='editRole_backGrid'
          style={{
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
            marginLeft: 30,
          }}>
          <Back
            data-qaid='editRole_backIcon'
            onClick={backtoHome}
            style={{ cursor: "pointer" }}
          />
          <AvenirBlackH7
            data-qaid='editRole_backLabel'
            label={Context.langData.edit_role}
            style={{ fontWeight: "bold", paddingLeft: 20 }}
          />
        </Grid>
        <Grid item data-qaid='editRole_save_cancel_Grid'>
          <Button
            className={style.buttonClass}
            data-qaid='editRole_cancel_button'
            style={{
              backgroundColor: "#FFF",
              color: "white",
              marginRight: 10,
              width: 103,
              height: 40,
              border: "2px solid #051D33",
            }}
            variant='contained'
            id='cancel'
            onClick={backtoHome}>
            <AvenirBlackH4
              data-qaid='editRole_cancel_label'
              label={Context.langData.cancel}
            />
          </Button>
          <Button
            className={style.buttonClass}
            data-qaid='editRole_save_button'
            {...btnProps}
            style={{
              marginRight: 10,
              backgroundColor: btnDisable == true ? "#EEE" : "#FFB600",
              color: "white",
              width: 90,
              height: 40,
              marginRight: 20,
            }}
            variant='contained'
            id='next'
            onClick={submit}>
            <AvenirBlackH4
              data-qaid='editRole_save_label'
              label={Context.langData.save}
            />
          </Button>
        </Grid>
      </Grid>

      <Grid
        item
        data-qaid='editRole_subGrid2'
        lg={12}
        md={12}
        sm={12}
        style={{
          overflow: "scroll",
          // height: "550px",
          marginTop: 30,
          marginLeft: "20px",
          marginRight: "20px",
        }}>
        <Card data-qaid='editRole_card' style={{ marginTop: 20 }}>
          <CardContent>
            <Grid
              data-qaid='editRole_roleInfo_labelGrid'
              className={classes.contactInfoMainView}>
              <AvenirBlackH2
                data-qaid='editRole_roleInfo_label'
                label={Context.langData.role_info}
              />
            </Grid>
            <hr data-qaid='editRole_hr_div' className={classes.hrStyles} />

            <Grid
              container
              data-qaid='editRole_name_description_Grid'
              spacing={4}
              style={{
                paddingTop: "20px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}>
              <Grid
                item
                data-qaid='editRole_name_grid'
                xs={12}
                sm={6}
                md={6}
                lg={5}>
                <AvenirBlackH4
                  data-qaid='editRole_name_label'
                  label={Context.langData.name_star}
                  style={{ marginBottom: 10 }}
                />
                <TextInputComponent
                  id='outlined-basic'
                  variant='outlined'
                  value={name}
                  onChange={(e) => {
                    changeText(e.target.value, "name");
                    onBlurName(e);
                  }}
                  style={{ width: "100%" }}
                  className={classes.sample}
                  maxLength={64}
                  errorBorder={emailExists ? "1px solid red" : null}
                  InputProps={{
                    classes: {
                      root: classes.labelRoot,
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                    },
                  }}
                />
                {emailExists && (
                  <H5
                    label={emailExists ? "Role name already exists" : ""}
                    style={{ color: "#e6473c", paddingLeft: 10, paddingTop: 5 }}
                  />
                )}
              </Grid>
              <Grid
                item
                data-qaid='editRole_description_grid'
                xs={12}
                sm={6}
                md={6}
                lg={5}>
                <AvenirBlackH4
                  data-qaid='editRole_description_label'
                  label={Context.langData.description_star}
                  style={{ marginBottom: 10 }}
                />
                <TextInputComponent
                  id='outlined-basic'
                  variant='outlined'
                  value={description}
                  style={{ width: "100%" }}
                  onChange={(e) => changeText(e.target.value, "description")}
                  className={classes.sample}
                  maxLength={300}
                  InputProps={{
                    classes: {
                      root: classes.labelRoot,
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card
          data-qaid='editRole_card2'
          style={{ marginTop: 30, marginBottom: 30 }}>
          <CardContent>
            <Grid
              data-qaid='editRole_rolePermission_label2'
              className={classes.contactInfoMainView}>
              <AvenirBlackH2
                data-qaid='editRole_rolePermissionLabel'
                label={Context.langData.role_step_2}
              />
            </Grid>
            <hr data-qaid='editRole_hr_div' className={classes.hrStyles} />
            <div>
              {permissionItem.map((item, index) => {
                console.log("permission", item);
                const list_id = `application_${item.id}`;
                const div_id = `div_${item.id}`;
                const application = checkedList.filter(
                  (e) => e.application_id == item.id
                );
                const convertText = validateAlphaNumeric(item.name);
                return (
                  <div
                    key={index}
                    id={div_id}
                    data-qaid='editRole_rolePermissions_view'>
                    <AvenirBlackH4
                      data-qaid='editRole_mainHeading'
                      label={`${convertText}*`}
                      className='col l12 s12 m12'
                      style={{
                        marginBottom: 10,
                        marginLeft: 10,
                        marginTop: 20,
                        color: "#051D33",
                      }}
                    />
                    <Grid
                      data-qaid='editRole_selectAllCheckbox_grid'
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                      }}>
                      <CustomCheckbox
                        data-qaid='editRole_selectAllCheckbox'
                        id={list_id}
                        checked={item.selectAll}
                        onChange={() => checkAll(item.id, item.selectAll)}
                        unCheckColor={item.selectAll ? "#004C60" : "#C3C3C3"}
                        marginLeft={0}
                      />
                      <AvenirBlackH4
                        data-qaid='editRole_selectAll_label'
                        label={Context.langData.select_deselect_all}
                        style={{ color: "#051D33" }}
                      />
                    </Grid>

                    <div
                      data-qaid='editRole_rolePermission_checkbox_div'
                      className='col l12 s12 m12'
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}>
                      {item.Permissions.map((permissionItem, index2) => {
                        const convertText = validateAlphaNumeric(
                          permissionItem.name
                        );
                        return (
                          <Grid
                            data-qaid='editRole_rolePermission_checkbox_Grid'
                            key={index2}
                            style={{
                              marginRight: 50,
                              width: "20%",
                              flexDirection: "row",
                              display: "flex",
                              alignItems: "center",
                            }}>
                            <CustomCheckbox
                              data-qaid='editRole_checkbox_rolePermissions'
                              id={permissionItem.permission_id}
                              checked={
                                selectedPermissions.indexOf(
                                  permissionItem.permission_id
                                ) !== -1
                              }
                              marginLeft={0}
                              onChange={() => checkItList(permissionItem)}
                              unCheckColor={
                                selectedPermissions.indexOf(
                                  permissionItem.permission_id
                                ) !== -1
                                  ? "#004C60"
                                  : "#C3C3C3"
                              }
                              disabled={
                                application == -1 ||
                                !handleDisable(
                                  permissionItem?.key,
                                  permissionItem?.application_id
                                )
                              }
                              label={convertText}
                              labelColor={
                                application == -1 ||
                                !handleDisable(
                                  permissionItem.key,
                                  permissionItem?.application_id
                                )
                                  ? "#707272"
                                  : "#051D33"
                              }
                            />
                          </Grid>
                        );
                      })}
                    </div>
                    {item.isError && (
                      <AvenirBlackH4
                        data-qaid='editRole_errorStatus'
                        label='Select at least one permission'
                        style={{ marginLeft: 10, marginTop: 10, color: "red" }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </CardContent>
        </Card>
        {snackbar && (
          <SnackBar
            data-qaid='createRole_snackbar'
            open
            setOpen={() => setSnackbar(null)}
            severity={snackbar.severity}
            message={snackbar.message}
          />
        )}
      </Grid>
    </Grid>
  );
};
export default EditRole;
