import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  makeStyles,
  Grid,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import MyContext from "../../Provider/MyContext";
import Page from "../../components/Page";
import { activateRest, checkEmailExist } from "../../redux/actions";
import { H4, AvenirBlackH4, H2, H3 } from "../../utils/text";
import InputText from "../../components/InputText";
import styles from "../../styles/index";
import Message from "../../components/Message";
import OpenEyeIcon from "../../components/SVG/OpenEyeIcon";
import CircleClose from "../../components/SVG/CircleClose";
import CircleCheck from "../../components/SVG/CircleCheck";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F3FAFD",
    overflowY: "auto",
  },
}));

const ActivationFirstStep = () => {
  const style = styles();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isExists, setExists] = useState(false);
  const Context = useContext(MyContext);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const [password, setPassword] = useState("");
  const [hidePassword, setHidePassword] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const [PwdRequirmentList, setPwdRequirmentList] = useState([
    { key: 1, value: Context.langData.eleven_characters, check: false },
    { key: 2, value: Context.langData.one_uppercase, check: false },
    { key: 3, value: Context.langData.one_lowercase, check: false },
    { key: 4, value: Context.langData.one_number, check: false },
    {
      key: 5,
      value: Context.langData.one_special_case_chatacter,
      check: false,
    },
  ]);
  console.log(
    "location?.state?",
    location?.state.data.rest_id,
    "storeData",
    location?.state.storeData
  );
  const ActivateAccount = (data) => {
    const obj = {
      ...data,
      password,
      rest_id: location?.state.data.rest_id,
    };
    const st1 = location?.state?.storeData || {};
    const _checkPwd = PwdRequirmentList.filter((e) => e.check);
    if (_checkPwd.length !== 5) {
      setErrorText(true);
    } else {
      setErrorText(false);
      dispatch(
        activateRest(obj, (status, error) => {
          if (status) {
            setAlertData({
              open: true,
              severity: "success",
              msg: "Successfully activated",
            });
            navigate("/mfa", { state: { st1, fromLogin: false } });
          } else {
            setAlertData({ open: true, severity: "error", msg: error });
          }
          closeMessage();
        })
      );
    }
    // navigate('/termsandUser', { state: { st1, data } })
  };
  const onBlurEmail = (e, setFieldValue) => {
    const val = e.target.value;
    document.getElementById("exists").style.display = "none";
    document.getElementById("password").removeAttribute("disabled");
    // setFieldValue('password', "")
    document.getElementById("password").style["background-color"] = "#fff";
    if (val.length > 0) {
      dispatch(
        checkEmailExist({ email: val }, (status, data) => {
          console.log(status, data);
          if (status) {
            setExists(data.exists);
            if (data.exists) {
              const _list = [...PwdRequirmentList];
              console.log("EMAIL exists");
              document.getElementById("password").style["background-color"] =
                "#f9f9f9";
              document.getElementById("exists").style.display = "block";
              // setFieldValue('password', "Dummy@12345")
              setPassword("");
              const passwordEl = document.getElementById("password");
              passwordEl.value = "";
              passwordEl.setAttribute("disabled", "disabled");
              setHidePassword(false);
              setErrorText(false);
              _list.map((i) => {
                i.check = true;
              });
              setPwdRequirmentList(_list);
            } else if (password.length > 0) {
              handlePassword(password);
            } else {
              const updateErrors = PwdRequirmentList.map((updateFalse) => ({
                ...updateFalse,
                check: false,
              }));
              setPwdRequirmentList(updateErrors);
              console.log("EMAIL NOT FOUND");
            }
          } else {
            setExists(false);
            // setHidePassword(true)
            // setErrorText(true)
            console.log("ERR");
          }
        })
      );
    }
    console.log(e.target.value);
  };
  const handleChangeTempPin = (value, setFieldValue) => {
    const reg = new RegExp("^d+$");
    if (!isNaN(value)) {
      setFieldValue("temp_pin", value);
    }
  };
  const handleOnCLickBack = () => {
    navigate("/activate");
  };
  const closeMessage = () => {
    setTimeout(() => {
      setAlertData({ open: false, severity: "success", msg: "" });
    }, 3000);
  };
  const handlePassword = (val) => {
    setPassword(val);
    const list = [...PwdRequirmentList];

    list.map((i) => {
      switch (i.key) {
        case 1:
          i.check = hasLength(val);
          break;
        case 2:
          i.check = hasUpper(val);
          break;
        case 3:
          i.check = hasLower(val);
          break;
        case 4:
          i.check = hasNumber(val);
          break;
        case 5:
          i.check = hasSpecial(val);
          break;
        default:
          i.check = false;
      }
    });
    setPwdRequirmentList(list);
  };
  const hasLength = (val) => val.length >= 11;
  const hasUpper = (val) => val.match(/[A-Z]+/g);
  const hasLower = (val) => val.match(/[a-z]+/g);
  const hasNumber = (val) => val.match(/\d+/g);
  const hasSpecial = (val) => val.match(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+/g);
  return (
    <Page data-qaid='activation_page' className={classes.root}>
      <Box
        data-qaid='activation_box'
        display='flex'
        flexDirection='column'
        height='100%'
        style={{ marginTop: 56, marginBottom: 56 }}
        justifyContent='center'>
        <Container
          data-qaid='activation_container'
          style={{ backgroundColor: "#FFFFFF", width: 600, height: 1060 }}>
          <Formik
            enableReinitialize
            data-qaid='activation_formik'
            initialValues={{
              first_name: location?.state?.user?.first_name || "",
              last_name: location?.state?.user?.last_name || "",
              email: location?.state?.user?.email || "",
              // password: location?.state?.user?.password || '',
              username: location?.state?.user?.username || "",
              temp_pin: location?.state?.user?.temp_pin || "",
            }}
            validationSchema={Yup.object().shape({
              first_name: Yup.string().required(
                Context.langData.first_name_error
              ),
              last_name: Yup.string()
                .max(255)
                .required(Context.langData.last_name_error),
              email: Yup.string()
                .email(Context.langData.email_valid_error)
                .max(255)
                .required(Context.langData.email_error),
              // password: Yup.string().max(255).required(Context.langData.password_error)
              //     .matches(
              //         /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{11,})/,
              //         Context.langData.password_error
              //     ),
              username: Yup.string()
                .min(4)
                .max(255)
                .required(Context.langData.user_name_error),
              temp_pin: Yup.string()
                .max(6)
                .required(Context.langData.pin_error)
                .matches(/^[0-9]{6}/, Context.langData.pin_error),
            })}
            onSubmit={(values) => {
              ActivateAccount(values);
            }}>
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              touched,
              values,
              isValid,
              dirty,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid
                  data-qaid='activation_formik_main_grid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 48,
                  }}>
                  <H2
                    label={Context.langData.second_step}
                    style={{ color: "#051D33" }}
                  />
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    margin: 30,
                    flexDirection: "column",
                  }}>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      marginBottom: 16,
                    }}>
                    <Grid
                      data-qaid='activation_formik_email_grid'
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        width: "49%",
                        marginRight: "2%",
                      }}>
                      <AvenirBlackH4
                        data-qaid='lactivation_formik_eamillabel'
                        label={Context.langData.first_Name}
                        style={{ color: "#051D33" }}>
                        {" "}
                      </AvenirBlackH4>
                      <InputText
                        data-qaid='activation_formik_email_input'
                        style={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: 4,
                          display: "flex",
                          marginTop: 8,
                        }}
                        error={touched.first_name && errors.first_name}
                        fullWidth
                        helperText={touched.first_name && errors.first_name}
                        margin='normal'
                        name='first_name'
                        maxLength={128}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='text'
                        value={values.first_name}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid
                      data-qaid='activation_formik_email_grid'
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        width: "49%",
                      }}>
                      <AvenirBlackH4
                        data-qaid='lactivation_formik_eamillabel'
                        label={Context.langData.last_Name}
                        style={{ color: "#051D33" }}>
                        {" "}
                      </AvenirBlackH4>
                      <InputText
                        data-qaid='activation_formik_email_input'
                        style={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: 4,
                          display: "flex",
                          marginTop: 8,
                        }}
                        error={touched.last_name && errors.last_name}
                        fullWidth
                        helperText={touched.last_name && errors.last_name}
                        margin='normal'
                        name='last_name'
                        maxLength={128}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='text'
                        value={values.last_name}
                        variant='outlined'
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    style={{
                      display: "flex",
                      borderBottom: "1px solid #EEEEEE",
                      marginBottom: 16,
                    }}>
                    <H3
                      data-qaid='lactivation_formik_eamillabel'
                      label={Context.langData.portal_access}
                      style={{ color: "#051D33", marginBottom: 10 }}>
                      {" "}
                    </H3>
                  </Grid>

                  <Grid
                    data-qaid='activation_formik_email_grid'
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      marginBottom: 16,
                    }}>
                    <AvenirBlackH4
                      data-qaid='lactivation_formik_eamillabel'
                      label={Context.langData.email}
                      style={{ color: "#051D33" }}>
                      {" "}
                    </AvenirBlackH4>
                    <InputText
                      data-qaid='activation_formik_email_input'
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: 4,
                        display: "flex",
                        marginTop: 8,
                      }}
                      error={touched.email && errors.email}
                      helperText={touched.email && errors.email}
                      margin='normal'
                      name='email'
                      maxLength={64}
                      onBlur={(e) => {
                        handleBlur(e);
                        onBlurEmail(e, setFieldValue);
                      }}
                      onChange={handleChange}
                      type='text'
                      value={values.email}
                      variant='outlined'
                    />
                  </Grid>

                  <Grid
                    data-qaid='activation_formik_password_grid'
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      marginBottom: 16,
                    }}>
                    <AvenirBlackH4
                      data-qaid='activation_formik_password_label'
                      label={Context.langData.password}
                      style={{
                        color: "#051D33",
                        display: "flex",
                        alignItems: "center",
                      }}>
                      {" "}
                    </AvenirBlackH4>
                    <InputText
                      data-qaid='activation_formik_password_input'
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: 4,
                        display: "flex",
                        marginTop: 8,
                      }}
                      // error={touched.password && errors.password}
                      // helperText={touched.password && errors.password}
                      margin='normal'
                      name='password'
                      maxLength={256}
                      // onBlur={handleBlur}
                      onChange={(e) => handlePassword(e.target.value)}
                      error={errorText}
                      type={hidePassword ? "text" : "password"}
                      variant='outlined'
                      // info={Context.langData.password_error}
                      id='password'
                      errorIcon={false}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() => {
                              setHidePassword(!hidePassword);
                            }}
                            edge='end'>
                            {hidePassword ? <OpenEyeIcon /> : <OpenEyeIcon />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <AvenirBlackH4
                      label={Context.langData.password_must_contain_at_least}
                      style={{ paddingTop: 20 }}
                    />
                    {PwdRequirmentList.map((i) => (
                      <Grid
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          display: "flex",
                          paddingTop: 10,
                        }}>
                        {i.check ? (
                          <CircleCheck height={24} width={24} />
                        ) : (
                          <CircleClose height={24} width={24} color='#AB1212' />
                        )}
                        <H4 label={i.value} style={{ paddingLeft: 10 }} />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid
                    data-qaid='exist'
                    id='exists'
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      marginBottom: 16,
                      display: "none",
                    }}>
                    <AvenirBlackH4
                      data-qaid='activation_formik_password_label'
                      label={Context.langData.exists_email}
                      style={{
                        color: "#1C7D26",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 10,
                      }}>
                      {" "}
                    </AvenirBlackH4>
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      borderBottom: "1px solid #EEEEEE",
                      marginBottom: 16,
                    }}>
                    <H3
                      data-qaid='lactivation_formik_eamillabel'
                      label={Context.langData.app_access}
                      style={{ color: "#051D33", marginBottom: 10 }}>
                      {" "}
                    </H3>
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      marginBottom: 16,
                    }}>
                    <Grid
                      data-qaid='activation_formik_email_grid'
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        width: "100%",
                        marginBottom: 16,
                      }}>
                      <AvenirBlackH4
                        data-qaid='lactivation_formik_eamillabel'
                        label={Context.langData.username}
                        style={{ color: "#051D33" }}>
                        {" "}
                      </AvenirBlackH4>
                      <InputText
                        data-qaid='activation_formik_email_input'
                        style={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: 4,
                          display: "flex",
                          marginTop: 8,
                        }}
                        error={touched.username && errors.username}
                        fullWidth
                        helperText={touched.username && errors.username}
                        margin='normal'
                        name='username'
                        maxLength={32}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='text'
                        info={Context.langData.user_name_error}
                        value={values.username}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid
                      data-qaid='activation_formik_email_grid'
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        width: "49%",
                      }}>
                      <AvenirBlackH4
                        data-qaid='lactivation_formik_eamillabel'
                        label={Context.langData.pin}
                        style={{ color: "#051D33" }}>
                        {" "}
                      </AvenirBlackH4>
                      <InputText
                        data-qaid='activation_formik_email_input'
                        style={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: 4,
                          display: "flex",
                          marginTop: 8,
                        }}
                        error={touched.temp_pin && errors.temp_pin}
                        fullWidth
                        helperText={touched.temp_pin && errors.temp_pin}
                        margin='normal'
                        InputProps={{
                          maxLength: 6,
                        }}
                        name='temp_pin'
                        maxLength={6}
                        onBlur={handleBlur}
                        onChange={(e) =>
                          handleChangeTempPin(e.target.value, setFieldValue)
                        }
                        type='text'
                        info={Context.langData.pin_error}
                        value={values.temp_pin}
                        variant='outlined'
                      />
                    </Grid>
                  </Grid>
                  <Box
                    data-qaid='activation_formik_action_box'
                    md={2}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginTop: 16,
                      flexDirection: "row",
                    }}>
                    <Button
                      className={style.buttonClass}
                      data-qaid='activation_formik_login_button'
                      style={{
                        backgroundColor: "#FFFFFF",
                        height: 40,
                        width: 91,
                        borderRadius: 5,
                        border: "2px solid #051D33",
                      }}
                      onClick={handleOnCLickBack}
                      variant='contained'>
                      <AvenirBlackH4
                        data-qaid='lactivation_formik_login_label'
                        style={{ color: "#051D33" }}
                        label={Context.langData.back}
                      />
                    </Button>
                    <Button
                      className={style.buttonClass}
                      data-qaid='activation_formik_login_button'
                      style={{
                        backgroundColor: "#FFB600",
                        marginLeft: 10,
                        height: 40,
                        width: 105,
                        borderRadius: 5,
                      }}
                      type='submit'
                      // disabled={!(dirty && isValid)}
                      variant='contained'>
                      <AvenirBlackH4
                        data-qaid='lactivation_formik_login_label'
                        style={{ color: "#051D33" }}
                        label={Context.langData.submit}
                      />
                    </Button>
                  </Box>
                </Grid>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
      <Message
        data-qaid='activation_formik_message'
        open={alertData.open}
        severity={alertData.severity}
        msg={alertData.msg}
        handleClose={() => console.log()}
      />
    </Page>
  );
};

export default ActivationFirstStep;
