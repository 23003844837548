import React from "react";
import { Navigate } from "react-router";
import { useIsAuthenticated } from "../custom-hooks/useIsAuthenticated";

const AuthRoute = ({ element: Element, redirectTo, unProtected = false }) => {
  const { isAuthenticatedFlag } = useIsAuthenticated();
  if (isAuthenticatedFlag) {
    return unProtected ? <Navigate to={redirectTo} replace /> : <Element />;
  }
  return unProtected ? <Element /> : <Navigate to={redirectTo} replace />;
};

export default AuthRoute;
