import React from "react";
import { Grid } from "@material-ui/core";
import { AvenirBlackH2 } from "../utils/text";
import { MinusIcon, PlusStandardIcon } from "./SVG";

const Counter_multiQty = ({
  count = 1,
  onChange,
  max = 10,
  min = 1,
  label,
  isMandatory,
  onError,
  otherCount,
  isAllowMultiSelect,
  multiSelectMax,
}) => {
  const decrement = () => {
    if (count > min) {
      onChange(count - 1);
    } else if (isMandatory) {
      if (label == "min")
        onError("Min value can't be less than 1 for mandatory modifiers");
      else if (otherCount == count) {
        onError("Max value can't less than min value");
      } else onError("Max value should be 2 or more for multi-quantity");
    } else if (label == "min") {
      onError("Disabled min changing option for optional");
    } else onError("Max value should be 2 or more for multi-quantity");
  };
  const increment = () => {
    const checkCount = isAllowMultiSelect ? max : 10;
    if (count < multiSelectMax && count < max) {
      onChange(count + 1);
    } else if (label == "max") {
      onError(`Max value can't be greater than ${max}`);
    } else if (isMandatory) {
      onError("Min value can't be greater than max value");
    } else {
      onError("Disabled min changing option for optional");
    }
  };
  return (
    <Grid
      container
      direction='row'
      style={{ border: "1px solid #051D33", height: 50, borderRadius: 5 }}>
      <Grid
        item
        xs={4}
        onClick={decrement}
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}>
        <MinusIcon />
      </Grid>
      <Grid
        item
        xs={4}
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <AvenirBlackH2 label={count} />
      </Grid>
      <Grid
        item
        xs={4}
        onClick={increment}
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}>
        <PlusStandardIcon />
      </Grid>
    </Grid>
  );
};

export default Counter_multiQty;
