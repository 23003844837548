import React, { useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { H4, AvenirBlackH4, AvenirBlackH3 } from "../utils/text";
import MyContext from "../Provider/MyContext";
import { RightArrow, DownArrow } from "./SVG";
import { CustomPagination } from ".";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
}));
export default function BasicTable(props) {
  const Context = useContext(MyContext);
  console.log(
    "props.rows",
    JSON.stringify(props.rows),
    JSON.stringify(props.columns)
  );
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [selectedId, setSelectedId] = React.useState([]);
  const [checkedAll, setCheckedAll] = React.useState(false);
  const [checkedRows, setCheckedRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  useEffect(() => {
    if (props.onCheckedIds) {
      props.onCheckedIds(selectedId);
    }
  }, [selectedId]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  function checkIt(id) {
    const ids = [...selectedId];
    const index = ids.indexOf(id);
    if (index == -1) {
      ids.push(id);
    } else {
      ids.splice(index, 1);
    }
    setSelectedId(ids);
    if (props.rows.length == ids.length) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }

  function onClickView(row) {
    props.onClickView(row);
  }
  function onPageChange() {}
  function onSortClick(value, sort, index) {
    console.log("sort", sort);
    if (props.sortingOnClick) {
      props.sortingOnClick(value, sort, index);
    }
  }
  const handleSort = (name, desc, index) => {
    props.handleSort(name, desc, index);
  };
  return (
    <Grid data-qaid='recipedatatable_main_grid'>
      <Grid
        data-qaid='recipedatatable_header_grid'
        style={{
          flexDirextion: "row",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          marginTop: 50,
          width: "100%",
          backgroundColor: "#F3FAFD",
          height: 56,
          paddingLeft: 20,
          paddingRight: 20,
        }}>
        <Grid
          style={{
            display: "flex",
            flexDirection: "row",
            width: "96%",
            alignItems: "end",
            justifyContent: "space-between",
          }}>
          {props.columns.map((column, index3) => (
            <Grid
              data-qaid='recipedatatable_header_item_grid'
              key={index3}
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0 10px",
                alignItems: "center",
                paddingRight: 10,
                cursor: "pointer",
                width: column.size,
              }}
              onClick={() => {
                onSortClick(column.field, column.sort, index3);
              }}>
              <AvenirBlackH3
                data-qaid='recipedatatable_header_label'
                label={column.display}
              />
              {/* <ArrowsIcon data-qaid="recipedatatable_arrowsicon" style={{ marginRight: 10 }} onClick={() => {
                                    handleSort(column.field, !column.desc, index3)}} /> */}
              <Grid
                onClick={() => {
                  handleSort(column.field, !column.desc, index3);
                }}>
                <DownArrow data-qaid='recipedatatable_arrowsicon' />
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid
          data-qaid='recipedatatable_header_item_grid2'
          style={{ width: "4%" }}
        />
      </Grid>
      {props.rows.length > 0 ? (
        <>
          {props.rows.map((row, index) => (
            <Grid
              data-qaid='recipedatatable_header_grid'
              style={{
                flexDirextion: "row",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                height: 56,
                marginTop: 10,
                width: "100%",
                paddingLeft: 20,
                paddingRight: 20,
                backgroundColor: "#FAFAFA",
              }}
              onClick={() => {
                onClickView(row);
              }}>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "96%",
                  justifyContent: "space-between",
                }}>
                {props.columns.map((colItem, index3) => (
                  <Grid
                    data-qaid='recipedatatable_column_item_grid'
                    key={index3}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      paddingRight: 5,
                      cursor: "pointer",
                      width: colItem.size,
                      height: 56,
                    }}>
                    <H4
                      data-qaid='recipedatatable_row_value_label'
                      label={String(row[colItem.field])}
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid
                data-qaid='recipedatatable_header_item_grid2'
                style={{
                  width: "4%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <RightArrow
                  data-qaid='recipedatatable_rigtarrow'
                  style={{ marginRight: 5, marginLeft: 5 }}
                  onClick={() => {
                    onClickView(row);
                  }}
                />
              </Grid>
            </Grid>
          ))}{" "}
        </>
      ) : (
        <Grid
          item
          lg={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            paddingTop: 8,
          }}>
          <AvenirBlackH4
            data-qaid='salesSummary_grandTotalLabe2'
            label={Context.langData.no_results_available}
          />
        </Grid>
      )}
      <Grid
        data-qaid='recipedatatable_footer_grid'
        container
        style={{
          justifyContent: "center",
          display: "flex",
          marginTop: 25,
          marginBottom: 25,
          width: "100%",
        }}>
        <Grid data-qaid='recipedatatable_custompagination_grid'>
          <CustomPagination
            data-qaid='recipedatatable_custompagination'
            total={1}
            onChange={onPageChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
