import React, { useContext } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Page from "../../components/Page";
import MyContext from "../../Provider/MyContext";
import styles from "../../styles/index";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const RegisterView = () => {
  const style = styles();
  const classes = useStyles();
  const navigate = useNavigate();
  const Context = useContext(MyContext);
  return (
    <Page
      data-qaid='registerview_page'
      className={classes.root}
      title={Context.langData.register}>
      <Box
        data-qaid='registerview_box'
        display='flex'
        flexDirection='column'
        height='100%'
        justifyContent='center'>
        <Container data-qaid='registerview_conter' maxWidth='sm'>
          <Formik
            data-qaid='registerview_formik'
            initialValues={{
              email: "",
              firstName: "",
              lastName: "",
              password: "",
              policy: false,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email(Context.langData.email_valid_error)
                .max(255)
                .required(Context.langData.email_required_error),
              firstName: Yup.string()
                .max(255)
                .required(Context.langData.first_name_req_error),
              lastName: Yup.string()
                .max(255)
                .required(Context.langData.last_name_req_error),
              password: Yup.string()
                .max(255)
                .required(Context.langData.password_required_error),
              policy: Yup.boolean().oneOf([true], Context.langData.reg_policy),
            })}
            onSubmit={() => {
              navigate("/app/dashboard", { replace: true });
            }}>
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box data-qaid='registerview_formik_box' mb={3}>
                  <Typography
                    data-qaid='registerview_formik_new'
                    color='textPrimary'
                    variant='h2'>
                    {Context.langData.create_new_account}
                  </Typography>
                  <Typography
                    data-qaid='registerview_formik_email'
                    color='textSecondary'
                    gutterBottom
                    variant='body2'>
                    {Context.langData.use_your_email_text}
                  </Typography>
                </Box>
                <TextField
                  data-qaid='registerview_formik_fname'
                  error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  helperText={touched.firstName && errors.firstName}
                  label={Context.langData.first_name}
                  margin='normal'
                  name='firstName'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  variant='outlined'
                />
                <TextField
                  data-qaid='registerview_formik_lname'
                  error={Boolean(touched.lastName && errors.lastName)}
                  fullWidth
                  helperText={touched.lastName && errors.lastName}
                  label={Context.langData.last_name}
                  margin='normal'
                  name='lastName'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  variant='outlined'
                />
                <TextField
                  data-qaid='registerview_formik_email'
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={Context.langData.email}
                  margin='normal'
                  name='email'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='email'
                  value={values.email}
                  variant='outlined'
                />
                <TextField
                  data-qaid='registerview_formik_password'
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label={Context.langData.password}
                  margin='normal'
                  name='password'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='password'
                  value={values.password}
                  variant='outlined'
                />
                <Box
                  data-qaid='registerview_formik_box'
                  alignItems='center'
                  display='flex'
                  ml={-1}>
                  <Checkbox
                    data-qaid='registerview_formik_checkbox'
                    checked={values.policy}
                    name={Context.langData.policy}
                    onChange={handleChange}
                  />
                  <Typography
                    data-qaid='registerview_formik_policy1'
                    color='textSecondary'
                    variant='body1'>
                    {Context.langData.policy_text_1}{" "}
                    <Link
                      data-qaid='registerview_formik_link1'
                      color='primary'
                      component={RouterLink}
                      to='#'
                      underline='always'
                      variant='h6'>
                      {Context.langData.policy_text_2}
                    </Link>
                  </Typography>
                </Box>
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText
                    data-qaid='registerview_formik_policy_error'
                    error>
                    {errors.policy}
                  </FormHelperText>
                )}
                <Box my={2}>
                  <Button
                    className={style.buttonClass}
                    data-qaid='registerview_formik_signup'
                    color='primary'
                    disabled={isSubmitting}
                    fullWidth
                    size='large'
                    type='submit'
                    variant='contained'>
                    {Context.langData.signup_now}
                  </Button>
                </Box>
                <Typography
                  data-qaid='registerview_formik_newaccount'
                  color='textSecondary'
                  variant='body1'>
                  {Context.langData.have_an_account}{" "}
                  <Link
                    data-qaid='registerview_formik_link2'
                    component={RouterLink}
                    to='/login'
                    variant='h6'>
                    {Context.langData.Signin}
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default RegisterView;
