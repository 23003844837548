import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Grid,
  Button,
  makeStyles,
  withStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import MultiSelect from "../../../components/MultiSelect";
import Daterange from "../../../components/DateRange";
import {
  fetchAllCostingVendors,
  fetchAllVendors,
} from "../../../redux/actions";
import SimpleTable from "../../../components/SimpleTable";
import MyContext from "../../../Provider/MyContext";
import { H4, AvenirBlackH4 } from "../../../utils/text";
import CircleClose from "../../../components/SVG/CircleClose";
import DownlodeIcon from "../../../components/SVG/DownlodeIcon";
import styles from "../../../styles/index";

const useStyles = makeStyles({
  btnApply: {
    backgroundColor: "#f0585f",
    color: "white",
    marginLeft: "10px",
  },
  btnClear: {
    backgroundColor: "#dcdcdc",
    color: "black",
  },
});

const Vendors = () => {
  const style = styles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [table, setTable] = useState([]);
  const costingVendorData = useSelector(
    (state) => state.inventory.costingVendors
  );
  const vendorData = useSelector((state) => state.inventory.vendors);
  const [date, setDate] = useState({
    start_ts: moment().startOf("day").unix(),
    end_ts: moment().endOf("day").unix(),
  });
  const [vendors, setVendors] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const Context = useContext(MyContext);

  useEffect(() => {
    dispatch(fetchAllCostingVendors({ ...date, vendor_id_list: "all" }));
    dispatch(fetchAllVendors());
  }, [date]);

  useEffect(() => {
    if (costingVendorData?.stocks?.length > 0) {
      setTable(costingVendorData.stocks);
    } else {
      setTable([]);
    }
  }, [costingVendorData.stocks]);

  const onDateChange = useCallback((d) => {
    setDate({ start_ts: d.start_date, end_ts: d.end_date });
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #f4f4f5",
      //   color:'green'
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      "&:focus": {
        backgroundColor: "white",
        color: "black",
        // height:'20px',
        // width:'20px',
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {},
      },
    },
  }))(MenuItem);

  const costingColumns = [
    {
      field: "balance_amount",
      display: Context.langData.balance_amount,
      size: "15%",
    },
    {
      field: "paid_amount",
      display: Context.langData.paid_amount,
      size: "10%",
    },
    {
      field: "total_purchases",
      display: Context.langData.total_purchases,
      size: "18%",
    },
    {
      field: "total_tax",
      display: Context.langData.total_without_tax,
      size: "12%",
    },
    { field: "stock_name", display: Context.langData.stock_item, size: "15%" },
    {
      field: "received_qty",
      display: Context.langData.received_qty,
      size: "15%",
    },
    { field: "total_cost", display: Context.langData.amount, size: "15%" },
  ];

  const handleClearVendor = () => {
    setVendors([]);
    dispatch(fetchAllCostingVendors({ ...date, vendor_id_list: "all" }));
  };

  const handleApplyVendor = () => {
    dispatch(
      fetchAllCostingVendors({ ...date, vendor_id_list: vendors.join("") })
    );
  };

  return (
    <>
      <Grid data-qaid='vendors_main_grid' container style={{ marginLeft: 20 }}>
        <Grid
          data-qaid='vendors_child_grid'
          container
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            marginRight: 20,
          }}>
          <Grid
            item
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}>
            <Grid data-qaid='vendors_date_grid' item style={{}}>
              <AvenirBlackH4
                data-qaid='vendors_date_label'
                label={Context.langData.from_to}
              />
              <Daterange
                data-qaid='vendors_dateRange'
                onDateChange={onDateChange}
                style={{ height: 56 }}
              />
            </Grid>

            <Grid item style={{ marginLeft: 20 }}>
              <AvenirBlackH4
                data-qaid='vendors_vendorlabel'
                label={Context.langData.select_vendor}
              />
              <MultiSelect
                data-qaid='vendors_vendor_select'
                list={vendorData}
                value='vendor_id'
                name='name'
                placeholder={Context.langData.all_vendors}
                selectedValue={vendors}
                handleChange={(e) => setVendors(e)}
                border
                minWidth={319}
              />
            </Grid>
          </Grid>
          <Grid
            data-qaid='vendors_actions_grid'
            item
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingTop: 15,
            }}>
            <CircleClose
              data-qaid='vendors_closeicon'
              onClick={handleClearVendor}
              style={{ marginRight: 10 }}
            />
            <H4 data-qaid='vendors_clearlabel' label={Context.langData.clear} />
            <Button
              className={style.buttonClass}
              data-qaid='vendors_apply_button'
              variant='contained'
              className={classes.btnApply}
              onClick={handleApplyVendor}
              style={{
                backgroundColor: "#FFB600",
                marginLeft: 20,
                marginRight: 20,
              }}>
              <H4 label={Context.langData.apply} />
            </Button>
            <Button
              className={style.buttonClass}
              data-qaid='vendors_creategrid'
              style={{ backgroundColor: "#FFB600" }}
              variant='contained'
              id='cancel'
              onClick={handleClick}>
              <DownlodeIcon
                data-qaid='vendors_createpluse'
                style={{ marginRight: 5 }}
              />
              <H4
                data-qaid='vendors_createlabel'
                label={Context.langData.download}
              />
            </Button>
          </Grid>
        </Grid>
        {/* <Grid container style={{ justifyContent: 'space-around', marginTop: '10px' }}>
                <Grid item>
                    <Button variant="contained" onClick={handleClick} >
                        <Typography variant="h5" >{Context.langData.download}</Typography> <ArrowDropDownIcon /> </Button>
                    <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <StyledMenuItem>
                            <ListItemText primary="Excel" onClick={() => { alert('Downloading') }} />
                        </StyledMenuItem>
                    </StyledMenu>
                </Grid>
                <Grid item style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <span>{Context.langData.amount_prefix} {costingVendorData.total_amount}</span>
                    <span>{Context.langData.balance_amount}</span>
                </Grid>
                <Grid item style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <span>{Context.langData.amount_prefix} {costingVendorData.amount_paid}</span>
                    <span>{Context.langData.paid_amount}</span>
                </Grid>
                <Grid item style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <span>{Context.langData.amount_prefix} {costingVendorData.total_amount}</span>
                    <span>{Context.langData.total_purchases}</span>
                </Grid>
                <Grid item style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <span>{Context.langData.amount_prefix}</span>
                    <span>{Context.langData.total_without_tax}</span>
                </Grid>
            </Grid> */}
      </Grid>
      <Grid data-qaid='vendors_simpleTable_grid' style={{ marginTop: "20px" }}>
        <SimpleTable
          data-qaid='vendors_simpleTable'
          columns={costingColumns}
          rows={table}
        />
      </Grid>
    </>
  );
};

export default Vendors;
