import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

const useMfa = () => {
  const navigate = useNavigate();
  const { mfa_required } = useSelector((state) => state.loginReducer);
  const location = useLocation();
  const { pathname } = location;
  useEffect(() => {
    const mfa = localStorage.getItem("mfa_required", 1);
    if (pathname.indexOf("mfa") == -1) {
      if (mfa_required) {
        navigate("/mfa", { state: { fromLogin: true } });
      }
      if (mfa) {
        navigate("/mfa", { state: { fromLogin: true } });
      }
    } else if (location.state == null) {
      if (!(mfa_required && mfa)) {
        navigate("/", { state: { fromLogin: true } });
      }
    }
  }, [mfa_required, pathname]);
  return [];
};

export default useMfa;
