import * as React from "react";

const InstagramIcon = (props) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.6167 1.25C3.20045 1.25 1.2417 3.20875 1.2417 5.625V14.375C1.2417 16.7912 3.20045 18.75 5.6167 18.75H14.3667C16.7829 18.75 18.7417 16.7912 18.7417 14.375V5.625C18.7417 3.20875 16.7829 1.25 14.3667 1.25H5.6167ZM-0.00830078 5.625C-0.00830078 2.5184 2.5101 0 5.6167 0H14.3667C17.4733 0 19.9917 2.5184 19.9917 5.625V14.375C19.9917 17.4816 17.4733 20 14.3667 20H5.6167C2.5101 20 -0.00830078 17.4816 -0.00830078 14.375V5.625Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.9917 6.45833C8.03569 6.45833 6.45003 8.04399 6.45003 10C6.45003 11.956 8.03569 13.5417 9.9917 13.5417C11.9477 13.5417 13.5334 11.956 13.5334 10C13.5334 8.04399 11.9477 6.45833 9.9917 6.45833ZM5.20003 10C5.20003 7.35364 7.34533 5.20833 9.9917 5.20833C12.6381 5.20833 14.7834 7.35364 14.7834 10C14.7834 12.6464 12.6381 14.7917 9.9917 14.7917C7.34533 14.7917 5.20003 12.6464 5.20003 10Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.7 3.82581C15.5895 3.72684 15.4434 3.66667 15.2834 3.66667C14.9382 3.66667 14.6584 3.94649 14.6584 4.29167C14.6584 4.56793 14.7681 4.83289 14.9635 5.02824C15.1588 5.22359 15.4238 5.33333 15.7 5.33333C15.9763 5.33333 16.2413 5.22359 16.4366 5.02824C16.632 4.83289 16.7417 4.56793 16.7417 4.29167C16.7417 3.94649 16.4619 3.66667 16.1167 3.66667C15.9566 3.66667 15.8106 3.72684 15.7 3.82581Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.7 4.75752C15.8106 4.85649 15.9566 4.91667 16.1167 4.91667C16.4619 4.91667 16.7417 4.63684 16.7417 4.29167C16.7417 4.0154 16.632 3.75045 16.4366 3.5551C16.2413 3.35975 15.9763 3.25 15.7 3.25C15.4238 3.25 15.1588 3.35975 14.9635 3.5551C14.7681 3.75045 14.6584 4.0154 14.6584 4.29167C14.6584 4.63684 14.9382 4.91667 15.2834 4.91667C15.4434 4.91667 15.5895 4.85649 15.7 4.75752Z'
      fill='white'
    />
  </svg>
);

export default InstagramIcon;
