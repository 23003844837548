import React, { useState, useEffect, useContext, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  AvenirBlackH4,
  H5,
  AvenirBlackH3,
  AvenirBlackLightH3,
  AvenirBlackH2,
  H6,
  H4,
} from "../../../utils/text";
import RightDrawer from "../../../components/RightDrawer";
import CircleClose from "../../../components/SVG/CloseCircle";
import { ConvertPrice } from "../../../utils/util";
import styles from "../../../styles/index";
import BackIcon from "../../../components/SVG/Back";
import InputText from "../../../components/InputText";
import AutoCompliteDropDown from "../../../components/AutoComplete";
import CirclePluse from "../../../components/SVG/CirclePluse";
import {
  saveCustomer,
  fetchCustomerCheck,
  fetchCustomers,
} from "../../../redux/actions";
import MyContext from "../../../Provider/MyContext";
import SnackBar from "../../../components/SnackBar";
import WarningIcon from "../../../components/SVG/WarningIcon";

const useCustomerStyles = makeStyles((theme) => ({
  newCustomerHeadingView: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  newCustomerText: {
    fontWeight: "bold",
    fontSize: 24,
  },
  saveBtn: {
    marginRight: "20px",
    backgroundColor: "#FFB600",
    color: "white",
    height: "40px",
    width: "103px",
  },
  cancelBtn: {
    marginLeft: "20px",
    backgroundColor: "#707272",
    color: "white",
    fontWeight: "bold",
  },
  contactInfoMainView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "1%",
  },
  hrStyles: {
    marginTop: "1%",
    border: "1px solid #EEE",
  },
  checkboxMainView: {
    display: "flex",
    alignItems: "center",
    paddingTop: "10px",
  },
  additionalInfoCardView: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  additionalInfoText: {
    display: "flex",
    marginTop: "1%",
  },
  root: {},
  title: {
    fontSize: "18px",
  },
  input: {
    color: "black !important",
    fontSize: 14,
    backgroundColor: "#FFF",
    borderRadius: 4,
  },
  notchedOutline: {
    border: "1px solid #707272 !important",
  },
  labelRoot: {},
  formControl: {
    minWidth: "100%",
    backgroundColor: "#FFF !important",
    border: "#707272",
    borderRadius: 4,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

const GiftCardAddCustomerModal = ({ open, callback, onClose }) => {
  const style = styles();
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  const [selectedCustomer, setSelectedcustomer] = useState(null);
  const [customerData, setCustomerData] = useState([]);
  const [openCreateCustomer, setOpenCreateCustomer] = useState(false);
  const CustomersList = useSelector(
    (state) => state.profileReducers.customersList
  );
  const [serachText, setSearchText] = useState("");
  const [note, setNote] = useState("");
  const [snackbar, setSnackbar] = useState(null);
  const classes = useCustomerStyles();
  const [enabled, setEnabled] = useState(false);
  const [province, setProvince] = useState(0);
  const [emailExists, setEmailExists] = useState(false);
  const [lang, setLang] = useState(0);
  const refundObj = useSelector((state) => state.transactions.refundObj);
  const formRef = useRef();
  const [giftCardAmmount, setGiftCardAmount] = useState();
  const WarningText = (
    <Grid
      style={{
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        paddingTop: 5,
      }}>
      <WarningIcon color='#AB1212' height={16} width={16} />
      <H5
        label='Customer email already exists'
        style={{ color: "#AB1212", paddingLeft: 5 }}
      />
    </Grid>
  );
  const validateSchema = () => {
    const validate = Yup.object().shape({
      firstName: Yup.string()
        .max(255)
        .required(Context.langData.first_name_req_error),
      lastName: Yup.string()
        .max(255)
        .required(Context.langData.last_name_req_error),
      email: Yup.string()
        .email(Context.langData.email_valid_error)
        .max(255)
        .required(Context.langData.email_required_error),
      notes: Yup.string().max(255).required(Context.langData.notes_req_error),
    });
    return validate;
  };

  useEffect(() => {
    if (refundObj.refunds_respons) {
      const giftCard = refundObj.refunds_response.filter(
        (e) => e.ptype == "gift"
      );
      if (giftCard.length > 0) {
        setGiftCardAmount(giftCard[0]?.amount || 0);
      }
    }
  }, [refundObj]);
  const validateAddress = (address) => {
    const arr = ["streetAddress", "city", "postalCode"];
    const temp = [];
    arr.map((key) => {
      if (address.values[key] == "" || address.values[key] == null) {
        temp.push(key);
      }
    });
    if (temp.length === arr.length || temp.length === 0) {
      return null;
    }
    return "Address Incomplete";
  };

  function capitalize(s) {
    if (typeof s !== "string") return "";
    const strs = s.toLowerCase().split(" ");
    return strs
      .map((ss) => ss.charAt(0).toUpperCase() + ss.slice(1).toLowerCase())
      .join(" ");
  }

  const onBlurEmail = (e) => {
    if (e.target.value) {
      dispatch(
        fetchCustomerCheck({ email: e.target.value }, (status, data) => {
          if (status) {
            if (data.exist) {
              setEmailExists(true);
            } else {
              setEmailExists(false);
            }
          } else {
            console.log("email alreay exists");
          }
        })
      );
    }
  };
  const handleSelectCustomer = (name) => {
    setSearchText(name);
    const queryString = { limit: 20, offset: 0 };
    queryString.search_text = name;
    dispatch(fetchCustomers(queryString));
  };
  useEffect(() => {
    if (CustomersList?.data?.length > 0 && serachText) {
      CustomersList.data.map((item) => {
        item.name = `${item.full_name}-${item.email}`;
      });
      setCustomerData(CustomersList.data);
    }
  }, [CustomersList]);
  const handleChooseCustomer = (value) => {
    const filterData = customerData.filter((e) => e.name == value);
    if (filterData.length > 0) {
      setSelectedcustomer(filterData[0]);
    }
  };
  const handleContinue = (customer_id, value) => {
    if (value && customer_id) {
      const obj = { ...refundObj, customer_id, gift_comment: value };
      dispatch({ type: "SET_TRANSACTION_REFUND", payload: obj });
      callback(true);
    } else {
      setSnackbar({ message: "erroroororororoorrooro", severity: "error" });
    }
  };
  const handleSubmit = () => {
    if (openCreateCustomer) {
      if (formRef.current) {
        formRef.current.handleSubmit();
      }
    } else {
      handleContinue(selectedCustomer?.customer_id, note);
    }
  };
  return (
    <RightDrawer data-qaid='createcomment_centermodal' open={open} padding={1}>
      <>
        <Grid
          style={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
          }}>
          <Grid
            style={{
              display: "flex",
              height: "85%",
              flexDirection: "column",
              overflowX: "auto",
            }}>
            <Grid
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 20,
                marginTop: 20,
              }}>
              <Grid
                data-qaid='createcategory_close_grid'
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  alignItems: "center",
                }}>
                <CircleClose
                  data-qaid='createcategory_closeicon'
                  size={20}
                  onClick={() => onClose(true)}
                  style={{ marginRight: 40, cursor: "pointer" }}
                />
              </Grid>
            </Grid>
            <Grid
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 20,
                marginLeft: 49,
              }}>
              <Grid
                data-qaid='createcategory_close_grid'
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "40%",
                  alignItems: "center",
                }}>
                <BackIcon
                  data-qaid='createcategory_closeicon'
                  size={20}
                  onClick={() => onClose(false)}
                  style={{ marginRight: 40, cursor: "pointer" }}
                />
              </Grid>
            </Grid>
            <Grid
              data-qaid='transactions_refund_main_grid'
              container
              direction='column'
              style={{
                marginLeft: 49,
                marginBottom: 20,
                marginRight: 49,
                width: 500,
              }}>
              <H6
                label={Context.langData.refund_order}
                style={{ marginBottom: 32 }}
              />
              <AvenirBlackLightH3
                label={`${Context.langData.giftCard_customer_body_1} $${ConvertPrice(giftCardAmmount)} ${Context.langData.giftCard_customer_body_1_1}`}
                style={{ marginBottom: 10 }}
              />
              <AvenirBlackLightH3
                label={Context.langData.giftCard_customer_body_2}
                style={{ marginBottom: 25 }}
              />
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}>
                <Grid
                  data-qaid='gift_card-customer_grid'
                  style={{ width: "100%" }}>
                  <AutoCompliteDropDown
                    options={customerData}
                    onChange={(value) => handleChooseCustomer(value)}
                    onInputChange={(value) => handleSelectCustomer(value)}
                    labelKey='name'
                    placeholder={Context.langData.customer_serch_text}
                  />
                  {selectedCustomer == null ? (
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        marginBottom: 20,
                        marginTop: 18,
                      }}
                      onClick={() => {
                        setOpenCreateCustomer(true);
                      }}>
                      <CirclePluse
                        color='#007191'
                        width={23}
                        height={23}
                        style={{ marginRight: 9 }}
                      />
                      <AvenirBlackH4
                        data-qaid='transactions_refund_gift_text'
                        label={Context.langData.add_new_customer}
                        style={{ color: "#007191" }}
                      />
                    </Grid>
                  ) : (
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: 8,
                        width: "100%",
                        marginTop: 20,
                      }}>
                      <H4
                        label={Context.langData.note_1}
                        style={{ marginBottom: 8 }}
                      />
                      <InputText
                        style={{ width: "100%" }}
                        value={note}
                        onChange={(val) => setNote(val.target.value)}
                        id='note'
                      />
                    </Grid>
                  )}
                </Grid>
                {openCreateCustomer && (
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}>
                    <Formik
                      innerRef={formRef}
                      enableReinitialize
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                        notes: "",
                      }}
                      validationSchema={validateSchema}
                      onSubmit={(values) => {
                        const valid = validateAddress({ values });
                        if (valid) {
                          setSnackbar({ message: valid, severity: "error" });
                        } else {
                          dispatch(
                            saveCustomer(
                              {
                                first_name: values.firstName,
                                last_name: values.lastName,
                                email: values.email,
                                language: "English",
                                phone_number: null,
                                enable_marketing: true,
                                assigned_customer_id: null,
                                birthday: null,
                                street_number: null,
                                street_address: null,
                                unit_number: null,
                                city: null,
                                province: null,
                                postal_code: null,
                                notes: values.notes,
                              },
                              (status, data) => {
                                if (status) {
                                  setOpenCreateCustomer(false);
                                  setSelectedcustomer({
                                    customer_id: data.customer_id,
                                    name: `${
                                      values.firstName + values.lastName
                                    }-${values.email}`,
                                  });
                                  handleContinue(
                                    data.customer_id,
                                    values.notes
                                  );
                                } else {
                                  setSnackbar({
                                    message: data.message,
                                    severity: "error",
                                  });
                                }
                              }
                            )
                          );
                        }
                      }}>
                      {({
                        errors,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        touched,
                        values,
                      }) => (
                        <form
                          onSubmit={handleSubmit}
                          style={{ height: "100%" }}>
                          <Grid
                            data-qaid='newCustomers_mainContainer'
                            container
                            style={{
                              backgroundColor: "#FFFFFF",
                              height: "100%",
                            }}>
                            <Grid
                              data-qaid='newCustomers_contactInfoContainer'
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              style={{ overflow: "scroll", marginTop: 10 }}>
                              <Grid
                                data-qaid='newCustomers_contactInfoLabelView'
                                className={classes.contactInfoMainView}>
                                <AvenirBlackH2
                                  data-qaid='newCustomers_contactInfoLabel'
                                  label={Context.langData.cnt_info}
                                />
                              </Grid>
                              <Grid
                                data-qaid='newCustomers_firstNameContainer'
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}>
                                <Grid
                                  data-qaid='newCustomers_firstNameLabelView'
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    marginBottom: 10,
                                  }}>
                                  <AvenirBlackH4
                                    data-qaid='newCustomers_firstNameLabel'
                                    label={Context.langData.first_name}
                                    style={{ marginBottom: 5 }}
                                  />
                                  <InputText
                                    value={values.firstName}
                                    onBlur={handleBlur}
                                    onChange={(val) =>
                                      setFieldValue(
                                        "firstName",
                                        capitalize(val.target.value)
                                      )
                                    }
                                    fullWidth
                                    error={
                                      touched.firstName && errors.firstName
                                    }
                                    helperText={
                                      touched.firstName && errors.firstName
                                    }
                                    id='firstName'
                                    name='firstName'
                                    variant='outlined'
                                    style={{ width: "100%" }}
                                    className={classes.sample}
                                    InputProps={{
                                      classes: {
                                        root: classes.labelRoot,
                                        notchedOutline: classes.notchedOutline,
                                        input: classes.input,
                                      },
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  data-qaid='newCustomers_lastNameLabelView'
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    marginBottom: 10,
                                  }}>
                                  <AvenirBlackH4
                                    data-qaid='newCustomers_lastNameLabel'
                                    label={Context.langData.last_name}
                                    style={{ marginBottom: 5 }}
                                  />
                                  <InputText
                                    value={values.lastName}
                                    onBlur={handleBlur}
                                    onChange={(val) =>
                                      setFieldValue(
                                        "lastName",
                                        capitalize(val.target.value)
                                      )
                                    }
                                    fullWidth
                                    error={touched.lastName && errors.lastName}
                                    helperText={
                                      touched.lastName && errors.lastName
                                    }
                                    id='lastName'
                                    variant='outlined'
                                    style={{ width: "100%" }}
                                    className={classes.sample}
                                    InputProps={{
                                      classes: {
                                        root: classes.labelRoot,
                                        notchedOutline: classes.notchedOutline,
                                        input: classes.input,
                                      },
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                data-qaid='newCustomers_emailLabelView'
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                  marginBottom: 10,
                                }}>
                                <AvenirBlackH4
                                  data-qaid='newCustomers_emailLabel'
                                  label={Context.langData.email_header_star}
                                  style={{ marginBottom: 5 }}
                                />
                                <InputText
                                  value={values.email}
                                  onBlur={(e) => {
                                    handleBlur(e);
                                  }}
                                  onChange={(val) => {
                                    setFieldValue(
                                      "email",
                                      val.target.value.trim().replace(/\s/g, "")
                                    );
                                    onBlurEmail(val, setFieldValue);
                                  }}
                                  fullWidth
                                  error={touched.email && errors.email}
                                  helperText={touched.email && errors.email}
                                  id='email'
                                  variant='outlined'
                                  style={{ width: "100%" }}
                                  className={classes.sample}
                                  errorBorder={
                                    emailExists ? "1px solid red" : null
                                  }
                                  InputProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      notchedOutline: classes.notchedOutline,
                                      input: classes.input,
                                    },
                                  }}
                                />
                                {emailExists && (
                                  <H5
                                    label={emailExists ? WarningText : ""}
                                    style={{
                                      color: "#AB1212",
                                      paddingTop: 5,
                                    }}
                                  />
                                )}
                              </Grid>
                              <Grid
                                data-qaid='newCustomers_lastNameLabelView'
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                  marginBottom: 10,
                                }}>
                                <AvenirBlackH4
                                  data-qaid='newCustomers_lastNameLabel'
                                  label={Context.langData.notes_man}
                                  style={{ marginBottom: 5 }}
                                />
                                <InputText
                                  value={values.notes}
                                  onBlur={handleBlur}
                                  onChange={(val) =>
                                    setFieldValue("notes", val.target.value)
                                  }
                                  fullWidth
                                  error={touched.notes && errors.notes}
                                  helperText={touched.notes && errors.notes}
                                  id='notes'
                                  variant='outlined'
                                  style={{ width: "100%" }}
                                  className={classes.sample}
                                  InputProps={{
                                    classes: {
                                      root: classes.labelRoot,
                                      notchedOutline: classes.notchedOutline,
                                      input: classes.input,
                                    },
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </form>
                      )}
                    </Formik>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{
              display: "flex",
              height: "15%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid
              data-qaid='transactions_refund_action_grid'
              container
              style={{ backgroundColor: "#FAFAFA" }}>
              <Grid
                data-qaid='transactions_refund_cancel_btn'
                item
                xs={6}
                lg={6}
                sm={6}
                md={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: 32,
                  marginBottom: 32,
                  paddingLeft: 49,
                  alignItems: "center",
                }}>
                <AvenirBlackH3
                  label={`${Context.langData.refund_total_amount}${ConvertPrice(refundObj.refund_amount)}`}
                  style={{ color: "#000000" }}
                />
              </Grid>
              <Grid
                data-qaid='transactions_refund_cancel_btn'
                item
                xs={6}
                lg={6}
                sm={6}
                md={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 32,
                  marginBottom: 32,
                }}>
                <Button
                  className={style.buttonClass}
                  data-qaid='transactions_refund_cancel_button'
                  onClick={() => {
                    callback(true);
                  }}
                  variant='outlined'
                  style={{
                    backgroundColor: "#FFFFFF",
                    marginRight: 16,
                    border: "2px solid #707272",
                    width: 103,
                  }}>
                  <AvenirBlackH4
                    data-qaid='transactions_refund_cancel_text'
                    label={Context.langData.cancel}
                    style={{ color: "#004C60" }}
                  />
                </Button>
                <Button
                  className={style.buttonClass}
                  data-qaid='transactions_refund_save_btn'
                  variant='contained'
                  style={{ backgroundColor: "#FFB600", marginRight: 49 }}
                  onClick={() => {
                    handleSubmit();
                  }}>
                  <AvenirBlackH4
                    data-qaid='transactions_refund_save_text'
                    label={Context.langData.continue}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {snackbar && (
          <SnackBar
            data-qaid='shifts_snackbar'
            open
            setOpen={() => setSnackbar(null)}
            severity={snackbar.severity}
            message={snackbar.message}
          />
        )}
      </>
    </RightDrawer>
  );
};

export default GiftCardAddCustomerModal;
