import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { Grid, makeStyles, Button, Checkbox, Chip } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import Page from "../../../components/Page";
import Daterange from "../../../components/DateRange";
import { TBold, H4, H6, AvenirBlackH4 } from "../../../utils/text";
import RightDrawer from "../../../components/RightDrawer";
import MyContext from "../../../Provider/MyContext";
import {
  fetchOrderTypes,
  fetchDashBoardSales,
  fetchRunningSales,
  fetchTopSales,
  fetchAllCategories,
  postTrackerSettings,
  fetchTracker,
  fetchItemsByCatId,
} from "../../../redux/actions";
import DashboardIcon from "../../../components/SVG/Dashboard";
import CloseCircle from "../../../components/SVG/CloseCircle";
import styles from "../../../styles/index";
import CheckBoxIcon from "../../../components/SVG/CheckBoxIcon";
import CheckedCheckBoxIcon from "../../../components/SVG/CheckBoxOn";
import Sales from "./sales";
import RunningSales from "./RunningSales";
import TopSales from "./TopSales";
import OrderTypeChart from "./OrderTypeChart";
import ShiftsChart from "./ShiftsChart";
import PaymentChart from "./PaymentChart";
import Tracker from "./Tracker";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
    width: "100%",
    minWidth: "100%",
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: "#c6c6c6",
    width: "100%",
  },
  noSidePadding: {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: 320,
    [theme.breakpoints.down("sm")]: {
      maxHeight: 700,
      height: 500,
    },
  },
  noSidePaddingsale: {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: 150,
  },
  relative: {
    position: "relative",
  },
  absolute: {
    position: "absolute",
    top: -10,
    right: 30,
  },
  subSection: {
    position: "absolute",
    bottom: 0,
    paddingTop: 10,
    paddingBottom: 10,
    borderTop: "1px solid #c6c6c6",
  },
  padding: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  paddingHorizontal: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  pad: {
    padding: 4,
  },
  fw: {
    width: "100%",
  },
  chart: {
    minHeight: 350,
    maxHeight: 350,
  },
}));

const Dashboard = () => {
  const style = styles();
  const classes = useStyles();
  const [dates, setDates] = useState(null);
  const [topSalesCategory, setTopSalesCategory] = useState(1);
  const ordertypes = useSelector((state) => state.metaReducer.ordertypes);
  const userPermissions = useSelector(
    (state) => state.loginReducer.userPermissions
  );
  const [trackerSetting, setTrackerSetting] = useState(false);
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const [buttonValue, setButtonValue] = useState(0);
  const isDashboardPermission = useMemo(() => {
    const isIndex = userPermissions.findIndex((e) => e.key == "dashboard");
    if (isIndex != -1) {
      if (userPermissions[isIndex].status) {
        return true;
      }
      return false;
    }
    return false;
  }, [userPermissions]);
  const onDateChange = (d) => {
    const newDates = {
      ...dates,
      from_date: d.startDate,
      to_date: d.endDate,
    };
    if (JSON.stringify(dates) !== JSON.stringify(newDates)) {
      setDates(newDates);
    }
  };
  useEffect(() => {
    if (isDashboardPermission) {
      dispatch(fetchOrderTypes());
    }
  }, []);
  const refreshDashboardSales = useCallback(
    (value = null) => {
      const quary = {
        ...dates,
      };
      if (value) {
        quary.order_types = value.join(",");
      }
      dispatch(fetchDashBoardSales(quary));
    },
    [dates]
  );
  const refreshLiveSales = useCallback(() => {
    dispatch(fetchRunningSales());
  }, []);
  useEffect(() => {
    if (isDashboardPermission)
      if (dates && ordertypes) {
        dispatch(fetchDashBoardSales(dates));
        dispatch(fetchRunningSales());
        dispatch(fetchTopSales(dates, topSalesCategory));
      }
  }, [dates, ordertypes]);

  const onChangeTopcategories = (e) => {
    setTopSalesCategory(e.target.value);
    dispatch(fetchTopSales(dates, e.target.value));
  };
  const onRefreshTopSales = (e) => {
    dispatch(fetchTopSales(dates, topSalesCategory));
  };
  const onCallBack = () => {
    setTrackerSetting(false);
    dispatch(fetchTracker(dates));
  };
  const handleTrackerSetting = (value, key) => {
    setTrackerSetting(value);
    setButtonValue(key);
  };
  return (
    <Page
      data-qaid='dashboard_index_page'
      className={classes.root}
      title={Context.langData.dashboard}>
      <Grid data-qaid='dashboard_index_title_grid' style={{ marginLeft: 56 }}>
        <H6
          data-qaid='dashboard_index_title'
          label={Context.langData.dashboard}
        />
      </Grid>
      <Grid
        data-qaid='dashboard_index_border'
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{ borderBottom: "3px solid #EEEEEE", marginTop: 30 }}
      />
      {isDashboardPermission ? (
        <Grid
          data-qaid='dashboard_index_body_grid'
          style={{
            display: "flex",
            marginLeft: 56,
            marginRight: 50,
            flexDirection: "column",
          }}>
          <Grid
            data-qaid='dashboard_index_date_grid'
            item
            sm={12}
            lg={12}
            md={12}
            style={{
              marginTop: 30,
              display: "flex",
              flexDirection: "row",
              marginBottom: 20,
            }}
            spacing={3}>
            <Grid
              data-qaid='dashboard_index_date_grid2'
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 16,
              }}>
              <Grid
                data-qaid='dashboard_index_date_main_grid'
                item
                justify='flex-start'
                style={{
                  textAlign: "initial",
                  display: "flex",
                  flexDirection: "column",
                }}>
                <AvenirBlackH4
                  data-qaid='dashboard_index_date_label'
                  label={Context.langData.date_range}
                  style={{ marginBottom: 10 }}
                />
                <Daterange
                  data-qaid='dashboard_index_daterange'
                  onDateChange={onDateChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            data-qaid='dashboard_index_body'
            style={{ display: "flex", flexDirection: "row" }}>
            <Grid
              data-qaid='dashboard_index_sales_grid'
              style={{ display: "flex", flexDirection: "row", width: "100%" }}>
              <Grid
                data-qaid='dashboard_index_sales_child_grid'
                item
                sm={12}
                lg={12}
                md={12}
                className={classes.fw}
                style={{ display: "flex", flexDirection: "row" }}>
                <Grid
                  data-qaid='dashboard_index_sale_grid'
                  item
                  sm={12}
                  lg={6}
                  md={6}
                  style={{ marginRight: 24 }}>
                  <Sales
                    data-qaid='dashboard_index_sales'
                    classes={classes}
                    ordertypes={ordertypes}
                    refresh={refreshDashboardSales}
                  />
                </Grid>
                <Grid
                  data-qaid='dashboard_index_runningsales_grid'
                  item
                  sm={12}
                  lg={6}
                  md={6}>
                  <RunningSales
                    data-qaid='dashboard_index_runningsales'
                    classes={classes}
                    refresh={refreshLiveSales}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            data-qaid='dashboard_index_chart_grid'
            container
            spacing={4}
            style={{ display: "flex", flexDirection: "row" }}>
            <Grid
              data-qaid='dashboard_index_doughtchart_grid'
              item
              sm={12}
              lg={8}
              md={8}
              style={{ display: "flex", flexDirection: "column" }}>
              <Grid
                data-qaid='dashboard_index_otype_grid'
                item
                lg={12}
                sm={12}
                md={12}
                className={classes.fw}
                style={{ marginBottom: 16 }}>
                <OrderTypeChart
                  data-qaid='dashboard_index_otypechart'
                  classes={classes}
                  dates={dates}
                />
              </Grid>
              <Grid
                data-qaid='dashboard_index_shift_grid'
                item
                lg={12}
                sm={12}
                md={12}
                className={classes.fw}
                style={{ marginBottom: 16 }}>
                <ShiftsChart
                  data-qaid='dashboard_index_shift_chart'
                  classes={classes}
                  dates={dates}
                />
              </Grid>
              <Grid
                data-qaid='dashboard_index_payment_grid'
                item
                lg={12}
                sm={12}
                md={12}
                className={classes.fw}>
                <PaymentChart
                  data-qaid='dashboard_index_paymentchart'
                  classes={classes}
                  dates={dates}
                />
              </Grid>
            </Grid>
            <Grid
              data-qaid='dashboard_index_right_grid'
              item
              sm={12}
              lg={4}
              md={4}
              className={classes.fw}
              style={{ display: "flex", flexDirection: "column" }}>
              <Grid
                data-qaid='dashboard_index_topsales_grid'
                style={{ marginBottom: 16 }}>
                <TopSales
                  data-qaid='dashboard_index_topsales'
                  classes={classes}
                  refresh={onRefreshTopSales}
                  topSalesCategory={topSalesCategory}
                  handleChange={onChangeTopcategories}
                />
              </Grid>
              <Grid data-qaid='dashboard_index_tracker_grid'>
                <Tracker
                  data-qaid='dashboard_index_tracker'
                  classes={classes}
                  dates={dates}
                  trackerSetting={handleTrackerSetting}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          data-qaid='dashboard_nopermissions_View'
          item
          lg={12}
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            marginTop: "20%",
          }}>
          <H6
            data-qaid='dashboard_nopermissions_message'
            label={Context.langData.no_permission_error}
            style={{ textAlign: "center", width: "55%", fontWeight: "bold" }}
          />
        </Grid>
      )}
      {trackerSetting && (
        <TrackerSettings
          data-qaid='dashboard_index_tracker_settings'
          open={trackerSetting}
          buttonValue={buttonValue}
          onClose={() => setTrackerSetting(false)}
          callback={onCallBack}
        />
      )}
    </Page>
  );
};

const TrackerSettings = ({ open, onClose, callback, buttonValue }) => {
  const style = styles();
  const [items, setItems] = useState([]);
  const allItems = useSelector((state) => state.menu.category_items);
  console.log({ allItems });
  const Categories = useSelector((state) => state.menu.categories);
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const tracker = useSelector((state) => state.dashboard.tracker);
  const Context = useContext(MyContext);
  const [value, setValue] = useState(0);
  const [buttonArray, setButtonArray] = useState([
    Context.langData.items,
    Context.langData.categories,
  ]);
  useEffect(() => {
    dispatch(fetchItemsByCatId());
    dispatch(fetchAllCategories());
  }, []);
  useEffect(() => {
    setValue(buttonValue);
  }, [buttonValue]);
  const handleChange = (item) => {
    setValue(item);
  };
  useEffect(() => {
    if (allItems) setItems(allItems.items);
  }, [allItems]);
  useEffect(() => {
    if (tracker) {
      if (tracker.cat_id_list) {
        setSelectedCategories(tracker.cat_id_list);
      }
      if (tracker.item_id_list) {
        setSelectedItems(tracker.item_id_list);
      }
    }
  }, [tracker]);

  const handleSave = () => {
    let category_grouping = [];
    if (tracker && tracker.category_grouping) {
      category_grouping = tracker.category_grouping;
    }
    dispatch(
      postTrackerSettings(
        {
          cat_id_list: selectedCategories,
          item_id_list: selectedItems,
          category_grouping,
        },
        callback
      )
    );
  };
  const onClickItemsCheck = (id) => {
    const itemIds = [...selectedItems];
    const index = itemIds.indexOf(id);
    if (index == -1) {
      itemIds.push(id);
    } else {
      itemIds.splice(index, 1);
    }
    setSelectedItems(itemIds);
  };
  const onClickCategoriesCheck = (catId) => {
    const catIds = [...selectedCategories];
    const index = catIds.indexOf(catId);
    if (index == -1) {
      catIds.push(catId);
    } else {
      catIds.splice(index, 1);
    }
    setSelectedCategories(catIds);
  };
  const getItemName = (id) => {
    let itemName = "";
    const ItemList = [...items];
    const itemNameArray = ItemList.filter((e) => e.item_id == id);
    if (itemNameArray.length > 0) {
      itemName = itemNameArray[0].name;
    }
    return itemName;
  };
  const handleDelete = (id) => {
    const itemIds = [...selectedItems];
    const index = itemIds.indexOf(id);
    itemIds.splice(index, 1);
    setSelectedItems(itemIds);
  };
  const getCatName = (id) => {
    let catName = "";
    const CatNameArray = Categories.filter((e) => e.cat_id == id);
    if (CatNameArray.length > 0) {
      catName = CatNameArray[0].name;
    }
    return catName;
  };
  const handleDeleteCategories = (id) => {
    const catIds = [...selectedCategories];
    const index = catIds.indexOf(id);
    catIds.splice(index, 1);
    setSelectedCategories(catIds);
  };
  return (
    <RightDrawer
      data-qaid='assigntaxescentermodal'
      open={open}
      onClose={onClose}
      padding={1}>
      <>
        <Grid
          style={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            width: 600,
          }}>
          <Grid
            style={{
              display: "flex",
              height: "85%",
              flexDirection: "column",
              overflowX: "auto",
              padding: 32,
            }}>
            <Grid>
              <Grid
                data-qaid='dashboard_index_tracker_close_grid'
                item
                lg={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginBottom: 16,
                }}>
                <CloseCircle
                  data-qaid='createkitchen_closeicon'
                  size={20}
                  onClick={onClose}
                />
              </Grid>
              <Grid
                data-qaid='dashboard_index_tracker_title+grid'
                item
                lg={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexDirection: "row",
                  marginBottom: 10,
                }}>
                <DashboardIcon
                  data-qaid='dashboard_index_tracker_dashboard_icon'
                  width={16}
                  height={16}
                  color='#808285'
                />
                <H4
                  data-qaid='dashboard_index_tracker_title'
                  label={Context.langData.dashboard}
                  style={{ color: "#808285", marginLeft: 10 }}
                />
              </Grid>
              <Grid
                data-qaid='dashboard_index_tracker_sub_title_grid'
                item
                lg={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexDirection: "row",
                  marginBottom: 10,
                }}>
                <TBold
                  data-qaid='dashboard_index_tracker_sub_title'
                  label={Context.langData.tracker_settings}
                  style={{ fontSize: 32 }}
                />
              </Grid>
              <Grid
                data-qaid='dashboard_index_tracker_main_grid'
                container
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: 29,
                  paddingRight: 20,
                }}>
                <Grid
                  data-qaid='dashboard_index_tracker_item_grid'
                  className='trackerList'
                  item
                  sm={12}
                  lg={12}
                  md={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}>
                  {buttonArray.map((item, i) => (
                    <Button
                      className={style.buttonClass}
                      data-qaid='settings_button'
                      style={{
                        backgroundColor: value == i ? "#051D33" : "#EEEEEE",
                        marginRight: 1,
                        borderRadius: 0,
                        borderTopLeftRadius: i == 0 ? 5 : 0,
                        borderBottomLeftRadius: i == 0 ? 5 : 0,
                        borderTopRightRadius:
                          buttonArray.length - 1 == i ? 5 : 0,
                        borderBottomRightRadius:
                          buttonArray.length - 1 == i ? 5 : 0,
                      }}
                      onClick={() => handleChange(i)}>
                      <AvenirBlackH4
                        data-qaid='settings_button_label'
                        label={item}
                        style={{
                          color: value == i ? "#FFFFFF" : "#051D33",
                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 10,
                          paddingBottom: 10,
                          lineHeight: "24px",
                        }}
                      />
                    </Button>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              {value == 0 ? (
                <>
                  <Grid
                    data-qaid='dashboard_index_tracker_item_grid'
                    container
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      borderRadius: 4,
                      border: "1px solid #707272",
                      marginBottom: 10,
                    }}>
                    <Grid
                      data-qaid='dashboard_index_tracker_item_sub_grid'
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexWrap: "wrap",
                      }}>
                      {selectedItems.map((id, index1) => (
                        <Chip
                          data-qaid='dashboard_index_tracker_item_chip'
                          key={index1}
                          label={getItemName(id)}
                          variant='outlined'
                          onDelete={() => handleDelete(id)}
                          style={{
                            margin: "5px",
                            borderRadius: 4,
                            borderColor: "#707272",
                          }}
                        />
                      ))}
                    </Grid>
                  </Grid>
                  {items &&
                    items?.map((item, index) => (
                      <Grid
                        data-qaid='dashboard_index_tracker_checkBox_grid'
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          flexDirection: "row",
                        }}>
                        <Checkbox
                          data-qaid='dashboard_index_tracker_item_checkbox'
                          id={item.name}
                          checked={selectedItems.indexOf(item.item_id) !== -1}
                          onChange={() => onClickItemsCheck(item.item_id)}
                          checkedIcon={<CheckedCheckBoxIcon />}
                          icon={<CheckBoxIcon />}
                          style={{ color: "black" }}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                        <H4
                          data-qaid='dashboard_index_tracker_item_label'
                          label={item.name}
                          style={{ marginLeft: 10 }}
                        />
                      </Grid>
                    ))}
                </>
              ) : (
                <>
                  <Grid
                    data-qaid='dashboard_index_tracker_cat_grid2'
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexWrap: "wrap",
                      borderRadius: 4,
                      border: "1px solid #707272",
                      marginBottom: 10,
                    }}>
                    {selectedCategories.map((id3, index3) => (
                      <Chip
                        data-qaid='dashboard_index_tracker_cat_chip'
                        key={`select${id3}${index3}`}
                        label={getCatName(id3)}
                        variant='outlined'
                        onDelete={() => handleDeleteCategories(id3)}
                        style={{
                          margin: "5px",
                          borderRadius: 4,
                          borderColor: "#707272",
                        }}
                      />
                    ))}
                  </Grid>
                  {Categories &&
                    Categories.map((catItem, index4) => (
                      <Grid
                        data-qaid='dashboard_index_tracker_check_grid'
                        key={`cat${index4}${catItem.name}`}
                        item
                        lg={6}
                        sm={6}
                        md={6}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          flexDirection: "row",
                        }}>
                        <Checkbox
                          data-qaid='dashboard_index_tracker_checkbox'
                          id={catItem.name}
                          checked={
                            selectedCategories.indexOf(catItem.cat_id) !== -1
                          }
                          onChange={() =>
                            onClickCategoriesCheck(catItem.cat_id)
                          }
                          checkedIcon={<CheckedCheckBoxIcon />}
                          icon={<CheckBoxIcon />}
                          style={{ color: "black" }}
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                        <H4
                          data-qaid='dashboard_index_tracker_check_label'
                          label={catItem.name}
                          style={{ marginLeft: 10 }}
                        />
                      </Grid>
                    ))}
                </>
              )}
            </Grid>
          </Grid>
          <Grid
            style={{
              display: "flex",
              height: "15%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid
              data-qaid='createtaxe_action_grid'
              container
              style={{
                backgroundColor: "#FAFAFA",
                width: "100%",
                height: "100%",
                border: "1px solid #C3C3C3",
                justifyContent: "flex-end",
                alignItems: "center",
              }}>
              <Button
                className={style.buttonClass}
                data-qaid='createTracker_cancel_button'
                onClick={onClose}
                variant='contained'
                style={{
                  backgroundColor: "#FFF",
                  marginRight: 16,
                  width: 103,
                  height: 40,
                  border: "2px solid #051D33",
                }}>
                <AvenirBlackH4
                  data-qaid='createTracker_cancel_text'
                  label={Context.langData.cancel}
                />
              </Button>
              <Button
                className={style.buttonClass}
                data-qaid='createTracker_save_btn'
                variant='contained'
                style={{
                  backgroundColor: "#FFB600",
                  marginRight: 32,
                  width: 90,
                  height: 40,
                }}
                onClick={() => handleSave()}>
                <AvenirBlackH4
                  data-qaid='createTracker_save_text'
                  label={Context.langData.save}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    </RightDrawer>
  );
};
export default Dashboard;
