import React, { useContext } from "react";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import MyContext from "../Provider/MyContext";
import { AvenirBlackH4 } from "../utils/text";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300 + theme.spacing(3) * 2,
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const ValueLabelComponent = (props) => {
  const { children, open, value } = props;

  return (
    <Tooltip
      data-qaid='sliderbar_valuelabelcomponet_tooltip'
      open={open}
      enterTouchDelay={0}
      placement='top'
      title={value}>
      {children}
    </Tooltip>
  );
};

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

const PrettoSlider = withStyles({
  root: {
    color: "#888",
    height: 16,
  },
  thumb: {
    height: 32,
    width: 32,
    backgroundColor: "#004C60",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 16,
    borderRadius: 10,
    backgroundColor: "#AAA",
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 16,
    width: 1,
  },
  rail: {
    height: 16,
    borderRadius: 10,
    backgroundColor: "#EEE",
  },
})(Slider);

const marks = [
  {
    value: 8,
    label: "",
  },
  {
    value: 2,
    label: "",
  },
  {
    value: 4,
    label: "",
  },
  {
    value: 6,
    label: "",
  },
];

const SliderBar = ({ value, onChange }) => {
  const classes = useStyles();
  const Context = useContext(MyContext);
  return (
    <div data-qaid='sliderbar_div' className={classes.root}>
      {/* <Typography data-qaid="sliderbar_distance_text" gutterBottom>
        {Context.langData.distance}
      </Typography> */}
      <AvenirBlackH4 label={Context.langData.distance} />
      <PrettoSlider
        data-qaid='sliderbar_prettoslider'
        valueLabelDisplay='auto'
        aria-label='pretto slider'
        defaultValue={0}
        value={value}
        min={0}
        max={20}
        step={0.5}
        onChange={(e, v) => onChange(v)}
      />
    </div>
  );
};

export default SliderBar;
