import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { H4 } from "../utils/text";
import {
  CircleClose as CircleCloseIcon,
  CircleCheck as CircleClick,
} from "./SVG";

const SnackBar = ({ open, setOpen, message, duration = 2000, severity }) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    if (setOpen) setOpen(false);
  };

  return (
    <Snackbar
      data-qaid='snackbar_snackbar'
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      style={{ height: 56 }}>
      {severity == "error" ? (
        <Alert
          icon={
            <CircleCloseIcon
              fontSize='inherit'
              color='#AB1212'
              width={18}
              height={18}
              style={{ alignItems: "center" }}
            />
          }
          data-qaid='snackbar_alert'
          variant='outlined'
          elevation={6}
          style={{
            backgroundColor: "#FDF3F2",
            border: "2px solid #AB1212",
            display: "flex",
            alignItems: "center",
          }}>
          <H4 label={message} />
        </Alert>
      ) : severity == "warn" ? (
        <Alert
          icon={
            <CircleCloseIcon
              fontSize='inherit'
              color='#AB1212'
              width={18}
              height={18}
              style={{ alignItems: "center" }}
            />
          }
          data-qaid='snackbar_alert'
          variant='outlined'
          elevation={6}
          style={{
            backgroundColor: "#FDF3F2",
            border: "2px solid #AB1212",
            display: "flex",
            alignItems: "center",
          }}>
          <H4 label={message} />
        </Alert>
      ) : (
        <Alert
          icon={
            <CircleClick
              fontSize='inherit'
              color='#1C7D26'
              width={18}
              height={18}
              style={{ alignItems: "center" }}
            />
          }
          data-qaid='snackbar_alert'
          variant='outlined'
          elevation={6}
          style={{
            backgroundColor: "#E6F6E7",
            border: "2px solid #1C7D26",
            display: "flex",
            alignItems: "center",
          }}>
          <H4 label={message} />
        </Alert>
      )}
    </Snackbar>
  );
};

export default SnackBar;
// menu builder,website,floor,dashboard
// pos
