import React, { useState, useEffect, useContext } from "react";
import { Grid, Button, makeStyles, Menu, MenuItem } from "@material-ui/core";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllConsumptions } from "../../../redux/actions";
import MultiSelect from "../../../components/MultiSelect";
import Daterange from "../../../components/DateRange";
import SimpleTable from "../../../components/SimpleTable";
import MyContext from "../../../Provider/MyContext";
import { AvenirBlackH4 } from "../../../utils/text";
import CircleClose from "../../../components/SVG/CircleClose";
import DownlodeIcon from "../../../components/SVG/DownlodeIcon";
import { downloadConsumptionReport } from "../../../utils/excel";
import { downloadPdfDocument } from "../../../components/PDFTableGenerator";

const useStyles = makeStyles({
  btnApply: {
    backgroundColor: "#f0585f",
    color: "white",
    marginLeft: "10px",
  },
  btnClear: {
    backgroundColor: "#dcdcdc",
    color: "black",
  },
});

const InventoryConsumption = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [date, setDate] = useState({});

  const kitchenData = useSelector((state) => state.inventory.departments);
  const consumptionsData = useSelector(
    (state) => state.inventoryReportReducer.consumptions
  );
  const [kitchenName, setKitchenName] = useState([]);
  const [table, setTable] = useState([]);
  const [resetDate, toggleResetDate] = useState(false);
  const Context = useContext(MyContext);
  const [consumptionColumns, setConsumptionColumns] = useState([
    { field: "itemName", display: Context.langData.stock_item, size: "20%" },
    { field: "itemUnitTypeCode", display: Context.langData.units, size: "20%" },
    {
      field: "theoreticalConsumption",
      display: Context.langData.theoretical,
      size: "20%",
    },
    {
      field: "actualConsumption",
      display: Context.langData.actual,
      size: "15%",
    },
    { field: "difference", display: Context.langData.difference, size: "15%" },
    { field: "variation", display: Context.langData.variation, size: "15%" },
    {
      field: "disposedQuantity",
      display: Context.langData.disposed,
      size: "15%",
    },
  ]);
  useEffect(() => {
    console.log("consumptionsData called");
    if (consumptionsData?.length > 0) {
      setTable(consumptionsData);
    } else {
      setTable([]);
    }
  }, [consumptionsData]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleApply();
    }, 100);
    return () => clearTimeout(timeout);
  }, [date, kitchenName]);
  const [anchorEl, setAnchorEl] = useState(null);

  const onDateChange = (d) => {
    //   if (
    //     date['from_date'] !== d.start.toISOString() ||
    //     date['to_date'] !== d.end.toISOString()
    //  ) {
    //     setDate({
    //       from_date: d.start.toISOString(),
    //       to_date: d.end.toISOString(),
    //   });
    //   }
    if (date.from_date !== d.startDate || date.to_date !== d.endDate) {
      setDate({
        from_date: d.startDate,
        to_date: d.endDate,
      });
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleKitchenList = (value) => {
    setKitchenName(value);
    console.log("value value", value);
  };
  const handleApply = () => {
    let obj = { ...date };
    if (kitchenName.length > 0) {
      obj = {
        ...obj,
        kitchen_ids: kitchenName.join(","),
      };
    } else {
      obj = {
        ...obj,
        kitchen_ids: "all",
      };
    }
    console.log("handleApply values obj", obj);
    dispatch(fetchAllConsumptions(obj));
  };
  const sortingOnClick = (value, sortValue, index) => {
    const columnData = [...consumptionColumns];
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setConsumptionColumns(columnData);
    const sortConsumptionSales = _.orderBy(
      table,
      [
        (d) =>
          typeof d[value] === "string" ? d[value].toLowerCase() : d[value],
      ],
      [sortValue ? "desc" : "asc"]
    );
    setTable(sortConsumptionSales);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const downloadExcel = () => {
    downloadConsumptionReport(table);
    handleCloseMenu();
  };
  const downloadPDF = () => {
    const fileName = "ConsumptionReports";
    const reportDate = {
      // start_date: moment(date.from_date).unix(),
      // end_date: moment(date.to_date).unix()
      start_date: date.startDate,
      end_date: date.endDate,
    };
    downloadPdfDocument(
      consumptionColumns,
      table,
      Context.langData.consumption,
      reportDate,
      fileName
    );
    handleCloseMenu();
  };
  const onClear = () => {
    setKitchenName([]);
    toggleResetDate(true);
    setTimeout(() => {
      toggleResetDate(false);
    }, 200);
  };
  console.log("CUNSUMPTIO", table);
  return (
    <Grid
      data-qaid='consumption_main_grid'
      container
      style={{ paddingLeft: 20, paddingRight: 20 }}>
      <Grid
        data-qaid='consumption_child_grid'
        container
        lg={12}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}>
        <Grid
          item
          style={{
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
          }}>
          <Grid data-qaid='consumption_date_grid'>
            <AvenirBlackH4
              data-qaid='consumption_date_labell'
              label={Context.langData.from_to}
            />
            <Daterange
              data-qaid='consumption_dateRange'
              onDateChange={onDateChange}
              refresh={resetDate}
            />
          </Grid>
          <Grid
            data-qaid='consumption_vendor_kitchen_grid'
            item
            style={{ justifyContent: "flex-start", marginLeft: 10 }}>
            <AvenirBlackH4
              data-qaid='consumption_kitchenLabel'
              label={Context.langData.all_kitchens}
            />
            <MultiSelect
              data-qaid='consumption_kitchen_multiselect'
              list={kitchenData}
              value='kitchen_id'
              name='name'
              placeholder={Context.langData.all_kitchens}
              selectedValue={kitchenName}
              handleChange={(e) => handleKitchenList(e)}
              border={false}
              height={56}
              width={298}
            />
          </Grid>
        </Grid>
        <Grid
          data-qaid='consumption_actions_grid'
          item
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: 10,
            marginLeft: 10,
          }}>
          <Grid
            data-qaid='consumption_clearMainGrid'
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: 10,
              alignItems: "center",
              cursor: "pointer",
            }}>
            <CircleClose
              data-qaid='consumption_closeicon'
              style={{ marginRight: 10 }}
              onClick={() => onClear()}
            />
            <AvenirBlackH4
              data-qaid='consumption_clearlabel'
              label={Context.langData.clear}
            />
          </Grid>
          <Button
            onClick={(event) => setAnchorEl(event.currentTarget)}
            data-qaid='consolodated_index_h1_downlode'
            style={{
              backgroundColor: "#FFB600",
              marginRight: 0,
              height: 40,
              width: "149px",
            }}
            variant='contained'
            id='cancel'>
            <DownlodeIcon
              data-qaid='consolodated_index_h1_downlodeicon'
              style={{ marginRight: 10 }}
            />
            <AvenirBlackH4
              data-qaid='consolodated_createlabel'
              label={Context.langData.download}
            />
          </Button>
          <Menu
            id='simple-menu-download'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}>
            <MenuItem onClick={downloadExcel}>
              {Context.langData.export_excel}
            </MenuItem>
            <MenuItem onClick={downloadPDF}>
              {Context.langData.export_pdf}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      <Grid
        data-qaid='consumption_simpleTable_grid'
        item
        style={{ width: "100%", marginTop: 40 }}>
        <SimpleTable
          data-qaid='consumption_simpleTable'
          columns={consumptionColumns}
          rows={table}
          isEditArrow={false}
          isOnClick={false}
          sortingOnClick={sortingOnClick}
        />
      </Grid>
    </Grid>
  );
};

export default InventoryConsumption;
