import * as React from "react";

const DisabledCheckbox = (props) => (
  <svg
    width={24}
    height={24}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <g opacity={0.5} fillRule='evenodd' clipRule='evenodd' fill='#707272'>
      <path d='M19 1H5a4 4 0 0 0-4 4v14a4 4 0 0 0 4 4h14a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4ZM5 0a5 5 0 0 0-5 5v14a5 5 0 0 0 5 5h14a5 5 0 0 0 5-5V5a5 5 0 0 0-5-5H5Z' />
      <path d='M19.418 4.873a.656.656 0 0 1 .08.924l-9.8 11.667a.656.656 0 0 1-.99.017l-4.2-4.667a.656.656 0 0 1 .976-.878l3.695 4.106 9.315-11.09a.656.656 0 0 1 .924-.08Z' />
    </g>
  </svg>
);

export default DisabledCheckbox;
