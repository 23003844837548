import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Grid } from "@material-ui/core";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  border: `1px solid #C3C3C3`,
  background: isDragging ? "#EEEEEE" : "#FFFFFF",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#EEEEEE" : "#FFFFFF",
  padding: grid,
  width: 250,
});

export default function Position(props) {
  console.log("props", props);
  const [data, setData] = React.useState(props.data);
  const [selectedId, setSelectedId] = React.useState([]);
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(data, result.source.index, result.destination.index);
    setData(items);
  };
  return (
    <DragDropContext data-qaid='postion_dragdropcontext' onDragEnd={onDragEnd}>
      <Droppable data-qaid='postion_droppable' droppableId='droppable'>
        {(provided, snapshot) => (
          <Grid
            data-qaid='postion_main_grid'
            item
            xs={12}
            lg={12}
            sm={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 32,
              marginBottom: 32,
            }}
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}>
            {data.map((item, index) => (
              <Draggable
                data-qaid='postion_draggable'
                key={item.name}
                draggableId={item.name}
                index={index}>
                {(provided, snapshot) => (
                  <Grid
                    data-qaid='postion_draggable_grid'
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}>
                    {item.name}
                  </Grid>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
      {/* < Grid container style={{ margin: 20 }}>
                <Button variant="contained" onClick={() => { props.callBack(true, data) }}> Save</Button>
                <Button variant="contained" onClick={() => { props.callBack(false, data) }}> Cancel </Button>
            </Grid> */}
    </DragDropContext>
  );
}
