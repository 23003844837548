import * as React from "react";

const FloorView = ({ width = 25, height = 25, ...props }) => (
  <svg
    width={width}
    height={height}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.03 1.563h-14a3.5 3.5 0 0 0-3.5 3.5v14a3.5 3.5 0 0 0 3.5 3.5h14a3.5 3.5 0 0 0 3.5-3.5v-14a3.5 3.5 0 0 0-3.5-3.5Zm-14-1.5a5 5 0 0 0-5 5v14a5 5 0 0 0 5 5h14a5 5 0 0 0 5-5v-14a5 5 0 0 0-5-5h-14Z'
      fill='#051D33'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='m11.06 1.77-10 10L0 10.709l10-10 1.06 1.06ZM22.56 2.77l-20 20-1.06-1.061 20-20 1.06 1.06Z'
      fill='#051D33'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='m7 17.77-6.5-6.5 1.06-1.061 6.5 6.5L7 17.769ZM16 7.77l-6.5-6.5L10.56.209l6.5 6.5L16 7.769ZM17.5 18.27l-6-6 1.06-1.061 6 6-1.06 1.06Z'
      fill='#051D33'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='m23.56 14.27-9.5 9.5L13 22.709l9.5-9.5 1.06 1.06Z'
      fill='#051D33'
    />
  </svg>
);

export default FloorView;
