import * as React from "react";

const ReportIssueIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.125 12.375a.75.75 0 01.75.75v2.5a.75.75 0 01-1.5 0v-2.5a.75.75 0 01.75-.75zM3.125 6.375a.5.5 0 00-.5.5v8.75a.75.75 0 01-1.5 0v-8.75a2 2 0 012-2H6.25a.75.75 0 010 1.5H3.125z'
      fill='#004C60'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M-.125 15.625a.75.75 0 01.75-.75h7.222a.75.75 0 01.65.378 1.731 1.731 0 003.005 0 .75.75 0 01.651-.378h7.222a.75.75 0 01.75.75v1.25a3.25 3.25 0 01-3.25 3.25H3.125a3.25 3.25 0 01-3.25-3.25v-1.25zm1.5.75v.5a1.75 1.75 0 001.75 1.75h13.75a1.75 1.75 0 001.75-1.75v-.5h-6.073a3.23 3.23 0 01-5.104 0H1.375zM15.727.113l.033.009a6.073 6.073 0 11-4.402 11.27l-3.65.998a.75.75 0 01-.87-1.055l1.566-3.18A6.052 6.052 0 0115.727.113zm-.36 1.457a4.553 4.553 0 00-5.459 6.287.75.75 0 01-.005.65l-.984 1.997 2.33-.636a.75.75 0 01.563.07 4.573 4.573 0 103.556-8.368z'
      fill='#004C60'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.063 6.776A1.062 1.062 0 0013 7.84v-.001m0 .001a1.062 1.062 0 101.063-1.064M14.063 2.401a.75.75 0 01.75.75v2.5a.75.75 0 01-1.5 0v-2.5a.75.75 0 01.75-.75z'
      fill='#004C60'
    />
  </svg>
);

export default ReportIssueIcon;
