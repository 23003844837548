import * as React from "react";

const RotateLeft = ({ width = 24, height = 24, ...props }) => (
  <svg
    {...props}
    width={width}
    height={height}
    viewBox='0 0 24 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.5 11.363l-3 3-3-3M12.75 21.082a8.974 8.974 0 003-.253M7.715 19.007c.58.488 1.221.901 1.906 1.229M19.312 18.985a8.995 8.995 0 001.942-2.3'
      stroke='#000'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M22.469 12.863a9 9 0 10-17.969-.75v2.25'
      stroke='#000'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default RotateLeft;
