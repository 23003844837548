import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import _ from "lodash";
import DataTable from "../../../components/DataTable";
import CirclePluse from "../../../components/SVG/PluseIcon";
import MyContext from "../../../Provider/MyContext";
import { AvenirBlackH4 } from "../../../utils/text";
import styles from "../../../styles/index";
import DeleteIcon from "../../../components/SVG/Delete";
import {
  fetchPettyCashCategories,
  deletePettyCashCategories,
} from "../../../redux/actions";
import SnackBar from "../../../components/SnackBar";
import AlertDialog from "../../../components/Dialog";
import CreateCategory from "./createCategory";
import EditCategory from "./editCategory";

const CategoriesList = () => {
  const style = styles();
  const Context = useContext(MyContext);
  const [columns, setColumns] = useState([
    { field: "cat_name", headerName: Context.langData.cat_name, size: "50%" },
    {
      field: "description",
      headerName: Context.langData.description,
      size: "50%",
    },
  ]);
  const [selectedId, setSelectedId] = React.useState([]);
  const [btnDisable, setBtnDisable] = React.useState(true);
  const [openCreate, toggleCreateDrawer] = useState(false);
  const [openEdit, toggleEditDrawer] = useState(false);
  const [row, setRow] = useState({});
  const PettyCashCategories = useSelector(
    (state) => state.pettyCashReducer.categories
  );
  const [selectedTabData, setSelectedTabData] = useState([]);
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const moreCategoreis = `${
    Context.langData.delete_categories_body + selectedId.length
  } ${Context.langData.categories}?`;
  const heading =
    selectedId.length > 1
      ? Context.langData.delete_categories
      : Context.langData.delete_categorie;
  const bodyMessage =
    selectedId.length > 1
      ? moreCategoreis
      : Context.langData.delete_categorie_body;

  useEffect(() => {
    const filterPettyCashData = _.reverse(PettyCashCategories);
    setSelectedTabData(filterPettyCashData);
  }, [PettyCashCategories]);
  function onCheckedIds(ids) {
    setSelectedId(ids);
    if (ids.length > 0) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  }
  const handleDeleteCategories = () => {
    if (selectedId.length > 0) {
      setShowDialog(true);
    }
  };
  function onClickAction(rowValue) {
    setRow(rowValue);
    toggleEditDrawer(true);
  }
  const btnProps = {};
  if (btnDisable) {
    btnProps.disabled = true;
  }
  function commanCallback() {
    toggleCreateDrawer(false);
    toggleEditDrawer(false);
    dispatch(fetchPettyCashCategories());
  }
  const handleClose = (val) => {
    if (val) {
      dispatch(
        deletePettyCashCategories(JSON.stringify(selectedId), deleteCallback)
      );
    }
    setShowDialog(false);
  };
  const onPageinationChange = () => {
    console.log("on page change");
  };
  const sortingOnClick = (value, sortValue, index) => {
    const columnData = [...columns];
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setColumns(columnData);
    const sortCategories = _.orderBy(
      selectedTabData,
      [(d) => d[value].toLowerCase()],
      [sortValue ? "asc" : "desc"]
    );
    setSelectedTabData(sortCategories);
  };
  function createCallback(isSuccess) {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.cat_create_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.category_create_failed,
        severity: "error",
      });
    }
    commanCallback();
  }
  function editCallback(isSuccess) {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.cat_edit_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.category_edit_failed,
        severity: "error",
      });
    }
    commanCallback();
  }
  function deleteCallback(isSuccess) {
    if (isSuccess) {
      setSnackbar({
        message:
          selectedId.length > 1
            ? Context.langData.cats_delete_succ
            : Context.langData.cat_delete_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.category_delete_failed,
        severity: "error",
      });
    }
    commanCallback();
  }
  const handleCreateCategory = () => {
    toggleCreateDrawer(true);
    // dispatch(fetchPettyCashCategories())
  };

  return (
    <div data-qaid='categoriesList_div'>
      <Grid
        data-qaid='categoriesList_grid'
        container
        direction='row'
        style={{ padding: 20 }}>
        <Grid
          data-qaid='categoriesList_buttongrid'
          item
          xs={12}
          sm={12}
          lg={12}
          style={{ marginTop: 24 }}>
          <Grid
            data-qaid='categoriesList_add grid'
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: 20,
            }}>
            <Grid
              data-qaid='categoriesList_add grid'
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: 20,
              }}>
              <Button
                className={style.buttonClass}
                data-qaid='categoriesList_deletebutton'
                style={{ color: "white", marginRight: 10 }}
                variant='text'
                id='cancel'
                onClick={handleDeleteCategories}>
                <DeleteIcon
                  data-qaid='categoriesList_delete_icon'
                  width={16}
                  height={16}
                  style={{ marginRight: 5 }}
                />
                <AvenirBlackH4
                  data-qaid='categoriesList_delete_label'
                  label={Context.langData.delete}
                />
              </Button>
              <Button
                className={style.buttonClass}
                data-qaid='categoriesList_addbutton'
                style={{
                  backgroundColor: "#FFB600",
                  color: "white",
                  marginRight: 10,
                }}
                variant='contained'
                id='cancel'
                onClick={handleCreateCategory}>
                <CirclePluse data-qaid='categoriesList_circleicon' />
                <AvenirBlackH4
                  data-qaid='categoriesList_createlabel'
                  label={Context.langData.create_category}
                  style={{ paddingLeft: 5 }}
                />
              </Button>
              {/* <ContextMenu style={{ marginLeft: 10 }} menuItems={ContextMenuData} callBack={contextMenuCallBack} /> */}
            </Grid>
          </Grid>
          <DataTable
            data-qaid='categoriesList_datatable'
            columns={columns}
            rows={selectedTabData}
            onCheckedIds={onCheckedIds}
            refresh={openCreate}
            onClickAction={onClickAction}
            onPageChange={onPageinationChange}
            sortingOnClick={sortingOnClick}
          />
        </Grid>
      </Grid>
      {openCreate && (
        <CreateCategory
          data-qaid='categoriesList_createcategory'
          open={openCreate}
          onClose={() => toggleCreateDrawer(false)}
          callback={createCallback}
        />
      )}
      {openEdit && (
        <EditCategory
          data-qaid='categoriesList_createcategory'
          open={openEdit}
          row={row}
          onClose={() => toggleEditDrawer(false)}
          callback={editCallback}
        />
      )}
      {snackbar && (
        <SnackBar
          data-qaid='categories_snackbar'
          open
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
      <AlertDialog
        data-qaid='categoriesList_alertdialog'
        showDialog={showDialog}
        body={bodyMessage}
        heading={heading}
        IsError={false}
        handleClose={handleClose}
      />
    </div>
  );
};

export default CategoriesList;
