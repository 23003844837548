import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      data-qaid='verticaltabs_tabpanel_main_div'
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box data-qaid='verticaltabs_tabpanel_box' p={3}>
          <Typography data-qaid='verticaltabs_tabpanel_children'>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    border: `1px solid #EEEEEE`,
    justifyContent: "flex-start",
    height: "100%",
    maxWidth: "100%",
    minWidth: "100%",
    paddingTop: 24,
    paddingBottom: 40,
  },
  tabs: {
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    "& .MuiTab-textColorInherit": {
      opacity: 1,
    },
  },
}));

const VerticalTabs = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.indexCallback(newValue);
  };
  useEffect(() => {
    if (props.Tabvalue) {
      setValue(props.Tabvalue);
      console.log("TabvalueTabvalue", props.Tabvalue);
    }
  }, [props]);
  return (
    <div data-qaid='verticaltabs_main_div' className={classes.root}>
      <Tabs
        data-qaid='verticaltabs_tabs'
        className={classes.tabs}
        orientation='vertical'
        variant='scrollable'
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}>
        {props.tabData.map((item, index) => (
          <Tab
            data-qaid='verticaltabs_tab'
            key={item.id}
            style={{
              color: "#004C60",
              backgroundColor: value == index ? "#C8E6F1" : "#FAFAFA",
              fontSize: 14,
              fontFamily: "AvenirLT-Medium",
              paddingLeft: 40,
            }}
            label={item.name}
          />
        ))}
      </Tabs>
    </div>
  );
};
export default VerticalTabs;
