import * as React from "react";

const TimeIcon = ({ width = 15, height = 15, color = "#004C60", ...props }) => (
  <svg
    width='15'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5ZM0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 5C8.27614 5 8.5 5.22386 8.5 5.5V8C8.5 8.27614 8.27614 8.5 8 8.5C7.72386 8.5 7.5 8.27614 7.5 8V5.5C7.5 5.22386 7.72386 5 8 5Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.64648 7.64641C7.84177 7.45117 8.15835 7.4512 8.35359 7.64648L11.4783 10.7718C11.6735 10.9671 11.6735 11.2837 11.4782 11.4789C11.2829 11.6742 10.9663 11.6741 10.7711 11.4788L7.64641 8.35352C7.45117 8.15823 7.4512 7.84165 7.64648 7.64641Z'
      fill={color}
    />
  </svg>
);

export default TimeIcon;
