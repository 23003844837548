import * as Excel from "exceljs";

const generateRecipeWorkBook = (workbook, data) => {
  console.log(data);
  const ws = workbook.addWorksheet("Recipe");
  let i = 0;
  let l = 0;
  data.map((d) => {
    ws.addRow([d.name, d.serving]);
    if (d.name.length > l) {
      l = d.name.length;
    }
    i++;
    ws.getRow(i).height = 20;
    ws.getCell(`A${i}`).id = d.id;
    ws.getCell(`B${i}`).id = d.serving;
    ws.getRow(i).name = "Item";
    setItemsRowStyle(ws, `A${i}`);
    setItemsRowStyle(ws, `B${i}`);
    if (d.recipe.length > 0) {
      d.recipe.map((r) => {
        ws.addRow([r.stock_name, r.amount, r.units]);
        i++;
        setRecipeRowStyle(ws, `A${i}`);
        setRecipeRowStyle(ws, `B${i}`);
        setRecipeRowStyle(ws, `C${i}`);
        ws.getCell(`A${i}`).id = r.stock_id;
        ws.getRow(i).name = "recipe";
      });
    }
  });
  ws.getColumn(1).width = l;
  ws.getColumn(2).width = 30;
  console.log("MAX LENGTH", l);
  return workbook;
  // worksheet.addRow({ id: 1, name: 'John Doe', dob: new Date(1970, 1, 1) });
};
const setItemsRowStyle = (ws, cellId) => {
  ws.getCell(cellId).font = {
    color: { argb: "000000" },
    size: 13,
    bold: true,
  };
  ws.getCell(cellId).alignment = { vertical: "middle" };
};
const setRecipeRowStyle = (ws, cellId) => {
  ws.getCell(cellId).font = {
    color: { argb: "000000" },
    size: 12,
  };
  ws.getCell(cellId).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "d4cdbc" },
  };
};
const uploadRecipe = async (data) => {
  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(data);
  const ws = workbook.worksheets[0];
  let recipe = [];
  let itemObj = null;
  const items = [];
  const { lastRow } = ws;
  ws.eachRow((row, rowNumber) => {
    console.log(rowNumber, lastRow == row);
    if (row.values.length == 3) {
      if (itemObj) {
        itemObj.recipe = recipe;
        items.push(itemObj);
      }
      recipe = [];
      itemObj = getItemObj(ws, rowNumber);
    } else {
      const obj = getRecipeObj(ws, rowNumber);
      recipe.push(obj);
    }
    if (lastRow == row) {
      itemObj.recipe = recipe;
      items.push(itemObj);
    }
  });
  return items;
};

const getRecipeObj = (ws, rowNumber) => ({
  stock_name: ws.getCell(`A${rowNumber}`).value,
  amount: ws.getCell(`B${rowNumber}`).value,
  units: ws.getCell(`C${rowNumber}`).value,
});
const getItemObj = (ws, rowNumber) => ({
  item_name: ws.getCell(`A${rowNumber}`).value,
  serving: ws.getCell(`B${rowNumber}`).value,
});

export { generateRecipeWorkBook, uploadRecipe };
