import * as React from "react";

const Mobile = ({
  width = 48,
  height = 69,
  color = "transparent",
  ...props
}) => {
  let invert = "#fff";
  if (color == "white") {
    invert = "#000";
  }
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox='0 0 48 69'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'>
      <rect
        x={1}
        y={1}
        width={46}
        height={46}
        rx={4}
        fill={color}
        stroke='#fff'
        strokeWidth={2}
      />

      <path
        strokeWidth={2}
        stroke={invert}
        d='M26.47 14.594h-5.938a2.375 2.375 0 00-2.375 2.375V30.03a2.375 2.375 0 002.375 2.375h5.937a2.375 2.375 0 002.375-2.375V16.97a2.375 2.375 0 00-2.375-2.375zM18.157 28.844h10.687'
      />

      <path
        strokeWidth={2}
        d='M6.665 65h1.008v-7.128h.024L10.745 65h.612l3.048-7.128h.024V65h1.008v-8.496h-1.464l-2.904 6.66h-.024l-2.88-6.66h-1.5V65zm10.395-2.808c0 1.752 1.272 2.952 3 2.952s3-1.2 3-2.952-1.272-2.952-3-2.952-3 1.2-3 2.952zm1.008 0c0-1.176.78-2.088 1.992-2.088s1.992.912 1.992 2.088c0 1.176-.78 2.088-1.992 2.088s-1.992-.912-1.992-2.088zM24.461 65h.936v-.996h.024c.348.636 1.152 1.14 2.16 1.14 1.572 0 2.808-1.272 2.808-2.952 0-1.704-1.188-2.952-2.952-2.952-.816 0-1.5.36-2.016.96h-.024v-4.272h-.936V65zm.936-2.808c0-1.176.78-2.088 1.992-2.088s1.992.912 1.992 2.088c0 1.176-.78 2.088-1.992 2.088s-1.992-.912-1.992-2.088zM31.93 65h.936v-5.616h-.936V65zm-.216-7.812c0 .396.288.684.684.684a.662.662 0 00.684-.684.684.684 0 10-1.368 0zM34.812 65h.936v-9.072h-.936V65zm7.107-1.572c-.444.564-.984.852-1.788.852-1.02 0-1.764-.828-1.836-1.8h4.536v-.3c0-1.86-1.08-2.94-2.664-2.94-1.656 0-2.88 1.212-2.88 2.952 0 1.632 1.08 2.952 2.844 2.952 1.044 0 1.836-.312 2.496-1.164l-.708-.552zm-3.624-1.74c.12-.948.804-1.584 1.836-1.584s1.656.636 1.692 1.584h-3.528z'
        fill='#fff'
      />
      <defs>
        <clipPath id='prefix__clip0'>
          <path fill='#fff' transform='translate(14 14)' d='M0 0h19v19H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Mobile;
