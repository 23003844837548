import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Box, Button, Container, makeStyles, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import MyContext from "../../Provider/MyContext";
import Page from "../../components/Page";
import { H4, AvenirBlackH4, H2, H5 } from "../../utils/text";
import {
  mfaActivation,
  resendMfaCode,
  mfaLoginVerification,
  mfaLoginResend,
  fetchRolePermissions,
} from "../../redux/actions";
import Message from "../../components/Message";
import styles from "../../styles/index";
import InputText from "../../components/InputText";
import MailIcon from "../../components/SVG/MailIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F3FAFD",
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const MfaStep = () => {
  const style = styles();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const loginDetails = useSelector((state) => state.loginReducer.loginDetails);
  const Context = useContext(MyContext);
  const location = useLocation();

  const handleResendMail = () => {
    const obj = { ...location.state.st1 };
    const isFromLoginScreen = location?.state?.fromLogin || false;
    if (isFromLoginScreen) {
      dispatch(
        mfaLoginResend({}, (status, response) => {
          if (status) {
            setAlertData({
              open: true,
              severity: "success",
              msg: Context.langData.code_resent_successfully,
            });
          } else {
            setAlertData({
              open: true,
              severity: "error",
              msg: Context.langData.mfa_resend_failed,
            });
          }
          closeMessage();
        })
      );
    } else {
      dispatch(
        resendMfaCode(obj, (status, response) => {
          if (status) {
            setAlertData({
              open: true,
              severity: "success",
              msg: Context.langData.code_resent_successfully,
            });
          } else {
            setAlertData({
              open: true,
              severity: "error",
              msg: Context.langData.mfa_resend_failed,
            });
          }
          closeMessage();
        })
      );
    }
  };
  const closeMessage = () => {
    setTimeout(() => {
      setAlertData({ open: false, severity: "success", msg: "" });
    }, 3000);
  };
  const handleWindowClose = (ev) => {
    ev.preventDefault();
    localStorage.removeItem("mfa_required");
    localStorage.removeItem("_expiredTime");
    localStorage.removeItem("_warningTime");
    return true;
  };
  useEffect(() => {
    window.addEventListener("beforeunload", handleWindowClose);
    return () => window.removeEventListener("beforeunload", handleWindowClose);
  }, []);
  return (
    <Page data-qaid='activation_page' className={classes.root}>
      <Box
        data-qaid='activation_box'
        display='flex'
        flexDirection='column'
        height='100%'
        justifyContent='center'>
        <Container
          data-qaid='activation_container'
          maxWidth='sm'
          style={{ backgroundColor: "#FFFFFF" }}>
          <Formik
            data-qaid='activation_formik'
            initialValues={{
              mfa_pin: "",
            }}
            validationSchema={Yup.object().shape({
              mfa_pin: Yup.string()
                .max(255)
                .required(Context.langData.mfa_valid_error),
            })}
            onSubmit={(values, actions) => {
              const isFromLoginScreen = location?.state?.fromLogin || false;
              console.log("isFromLoginScreen===>", isFromLoginScreen);
              if (isFromLoginScreen) {
                const loginMfaobj = { mfa_pin: values.mfa_pin };
                dispatch(
                  mfaLoginVerification(loginMfaobj, (status, response) => {
                    if (status) {
                      dispatch(fetchRolePermissions());
                      dispatch({ type: "MFA_REQUIRED", payload: false });
                      localStorage.removeItem("mfa_required", 1);
                      setAlertData({
                        open: true,
                        severity: "success",
                        msg: "Successfully verified",
                      });
                      if (loginDetails.restaurants.length > 1) {
                        navigate("/branches");
                      } else if (loginDetails.restaurants.length == 1) {
                        if (loginDetails.restaurants[0].tz) {
                          moment.tz.setDefault(loginDetails.restaurants[0].tz);
                        }
                        navigate("/home/dashboard");
                      }
                    } else {
                      actions.setErrors({
                        mfa_pin: Context.langData.mfa_error,
                      });
                    }
                  })
                );
              } else {
                const obj = { ...location.state.st1, mfa_pin: values.mfa_pin };
                dispatch(
                  mfaActivation(obj, (status, response) => {
                    if (status) {
                      setAlertData({
                        open: true,
                        severity: "success",
                        msg: "Successfully verified",
                      });
                      const st1 = location?.state?.st1 || {};
                      navigate("/termsandUser", { state: { st1 } });
                    } else {
                      actions.setErrors({
                        mfa_pin: Context.langData.mfa_error,
                      });
                    }
                  })
                );
              }
            }}>
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setErrors,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid
                  data-qaid='activation_formik_main_grid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 48,
                  }}>
                  <MailIcon />
                  <H2
                    label={Context.langData.please_verify_your_email}
                    style={{ color: "#051D33", marginTop: 10 }}
                  />
                </Grid>
                <Grid
                  data-qaid='activation_formik_mfa_pin_grid'
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    marginTop: 16,
                    marginLeft: 30,
                    marginRight: 30,
                  }}>
                  <H4
                    label={Context.langData.mfa_body}
                    style={{ color: "#000000" }}
                  />
                  <InputText
                    data-qaid='activation_formik_mfa_pin_input'
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: 4,
                      display: "flex",
                      marginTop: 16,
                    }}
                    error={touched.mfa_pin && errors.mfa_pin}
                    fullWidth
                    helperText={touched.mfa_pin && errors.mfa_pin}
                    margin='normal'
                    name='mfa_pin'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='text'
                    errorIcon={false}
                    value={values.mfa_pin}
                    variant='outlined'
                  />
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "row",
                    }}>
                    <H5
                      label={Context.langData.mfa_resend_1}
                      style={{ marginRight: 5 }}
                    />
                    <span
                      onClick={handleResendMail}
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}>
                      <H5 label={Context.langData.mfa_resend_2} />
                    </span>
                  </Grid>
                </Grid>
                <Box
                  data-qaid='activation_formik_action_box'
                  md={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 32,
                    marginBottom: 40,
                    marginLeft: 30,
                    marginRight: 30,
                  }}>
                  <Button
                    className={style.buttonClass}
                    data-qaid='activation_formik_login_button'
                    type='submit'
                    variant='contained'
                    style={{
                      width: 92,
                      height: 40,
                      backgroundColor: "#FFB600",
                      borderRadius: 5,
                    }}>
                    <AvenirBlackH4
                      data-qaid='lactivation_formik_login_label'
                      style={{ color: "#051D33" }}
                      label={Context.langData.continue}
                    />
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
      <Message
        data-qaid='activation_formik_message'
        open={alertData.open}
        severity={alertData.severity}
        msg={alertData.msg}
        handleClose={() => console.log()}
      />
    </Page>
  );
};

export default MfaStep;
