import React, { useState, useEffect, useContext } from "react";
import { Grid, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import $ from "jquery";
import RecipeDataTable from "../../../components/RecipeDataTable";
import {
  fetchAllRecipeAddons,
  uploadAddonRecipes,
  fetchAllRecipeAddonDetails,
} from "../../../redux/actions";
import MyContext from "../../../Provider/MyContext";
import { H4 } from "../../../utils/text";
import DownlodeIcon from "../../../components/SVG/DownlodeIcon";
import styles from "../../../styles/index";
import { downloadAddonRecipe } from "../../../utils/excel";
import UploadItemRecipe from "../../../components/UploadModal";
import UploadErrosTAble from "../../../components/UploadErrosTable";
import SnackBar from "../../../components/SnackBar";
import RecipeFormula from "./addonRecipeFormula";

const Addons = () => {
  const style = styles();
  const [table, setTable] = useState([]);
  const RecipeAddonsData = useSelector((state) => state.inventory.recipeAddons);
  const RecipeAddonDetails = useSelector(
    (state) => state.inventory.recipeAddonDetails
  );
  const stocksData = useSelector((state) => state.inventory.stocks);
  const [btnDisable, setBtnDisable] = useState(true);
  const dispatch = useDispatch();
  const [row, setRow] = useState({});
  const [viewRecipe, setViewRecipe] = useState(false);
  const Context = useContext(MyContext);
  const [isDownload, setIsDownload] = useState(false);
  const [isUploadError, setIsUploadError] = useState(false);
  const [uploadTitle, setUploadTitle] = useState();
  const [updatedList, setUpdatedList] = useState([]);
  const [errosData, setErrosData] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [UploadModal, setUploadModal] = useState(false);
  const [columns, setColumns] = useState([
    {
      field: "addon_name",
      headerName: Context.langData.item_name,
      sort: false,
    },
    { field: "recipeStatus", headerName: Context.langData.recipe, sort: false },
  ]);
  const valueArray = [
    { name: Context.langData.update_addon_recipe, value: 1 },
    { name: Context.langData.add_addon_recipe, value: 2 },
  ];
  const [recipeAddons, setRecipeAddons] = useState([]);
  useEffect(() => {
    setRecipeAddons(RecipeAddonsData);
  }, [RecipeAddonsData]);
  useEffect(() => {
    dispatch(fetchAllRecipeAddons());
  }, []);

  useEffect(() => {
    if (isDownload && RecipeAddonDetails && RecipeAddonDetails.length > 0) {
      downloadAddonRecipe(RecipeAddonDetails);
      setIsDownload(false);
    }
  }, [RecipeAddonDetails]);

  const commanCallback = () => {
    dispatch(fetchAllRecipeAddons());
  };
  const onClickView = (rowValue) => {
    setRow(rowValue);
    setViewRecipe(true);
  };
  const sortingOnClick = (value, sortValue, index) => {
    const columnData = [...columns];
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setColumns(columnData);
    const sortRecipeAddons = _.orderBy(
      recipeAddons,
      [(d) => d[value].toLowerCase()],
      [sortValue ? "desc" : "asc"]
    );
    setRecipeAddons(sortRecipeAddons);
  };
  const callback = (isSuccess, data) => {
    setViewRecipe(false);
    if (isSuccess) {
      if (data.status == "AllSucceeded") {
        setSnackbar({
          message: Context.langData.recipe_edit_succ,
          severity: "success",
        });
      } else {
        setSnackbar({
          message: Context.langData.recipe_edit_with_error,
          severity: "warn",
        });
      }
    } else {
      setSnackbar({
        message: Context.langData.recipe_edit_failed,
        severity: "error",
      });
    }
    dispatch(fetchAllRecipeAddons());
  };
  const download = () => {
    if (RecipeAddonDetails && RecipeAddonDetails.length > 0) {
      downloadAddonRecipe(RecipeAddonDetails);
      setIsDownload(false);
    } else {
      setIsDownload(true);
      const recipeArray = [];
      if (recipeAddons && recipeAddons.length > 0) {
        recipeAddons.map((rItem, index) => {
          if (rItem.recipe_id > 0) {
            recipeArray.push(rItem.recipe_id);
          }
        });
      }
      if (recipeArray.length > 0) {
        const ids = recipeArray.join(",");
        const params = { ext_product_id: ids };
        dispatch(fetchAllRecipeAddonDetails(params));
      }
    }
  };
  const triggerUpload = () => {
    setUploadModal(true);
  };
  const uploadCallback = (fileName, itemsList, valueKey) => {
    parseAddonsRecipeFile(itemsList, valueKey);
    setUploadTitle(Context.langData.missing_recipe_information);
  };
  const parseAddonsRecipeFile = (json, valueKey) => {
    const data = [];
    let row = null;
    let items = [];
    json?.map((item, index) => {
      if (item.Availability == "") delete item.Availability;
    });
    if (json?.length > 0) {
      json.map((item, index) => {
        let obj = "";
        if (typeof item.Availability !== "undefined") {
          if (row != null) {
            row.recipe = items;
            data.push(row);
            items = [];
          }
          row = {
            addon_name: item["Addon name"],
            index,
          };
          obj = {
            stock_name: item["Stock name"],
            amount: item.Quantity,
            units: item.Units,
            index,
          };
        } else {
          obj = {
            stock_name: item["Stock name"],
            amount: item.Quantity,
            units: item.Units,
            index,
          };
        }
        items.push(obj);
      });
      if (row != null) {
        row.recipe = items;
        data.push(row);
      }
    }
    console.log("data 1212", data, valueKey, "valueKey");
    parseAddonsRecipe(data, valueKey);
  };

  const parseAddonsRecipe = (data, uploadStep) => {
    const responce = RecipeAddonsData;
    console.log("responceresponce---12", responce);
    const stocks = stocksData;
    const errors = [];
    const parsedData = [];
    data?.map((item, i) => {
      const obj = {};
      const tempArray = [];
      const tempError = [];
      if (typeof item.addon_name === "undefined" || item.addon_name == "")
        tempError.push(`Addon name not entered at row ${item.index + 2}`);
      if (tempError.length == 0) {
        const result = $.grep(
          responce,
          (e) =>
            e.addon_name.toString().toLowerCase() ==
            item.addon_name.toString().toLowerCase()
        );
        console.log("result---12", result);
        if (result?.length != 0) {
          if (uploadStep == 1 && result[0].recipe_id == 0)
            tempError.push("Recipe doesn't exist");
          if (uploadStep == 2 && result[0].recipe_id > 0)
            tempError.push("Recipe already exist");
          obj.addon_name = item.addon_name;
          obj.addon_id = result[0].addon_id;
          item?.recipe.map((stock, j) => {
            const stocksErr = [];
            if (
              typeof stock.stock_name === "undefined" ||
              stock.stock_name == ""
            )
              stocksErr.push(
                `Stock name not entered at row ${stock.index + 2}`
              );
            else if (typeof stock.units === "undefined" || stock.units == "")
              stocksErr.push(
                `Units not entered to ${stock.stock_name} at row ${
                  stock.index + 2
                }`
              );
            else if (
              typeof stock.amount === "undefined" ||
              stock.quantity == ""
            )
              stocksErr.push(
                `Quantity not entered to ${stock.stock_name} at row ${
                  stock.index + 2
                }`
              );
            else if (isNaN(stock.amount))
              stocksErr.push(
                `${stock.stock_name} quantity is not a number at row ${
                  stock.index + 2
                }`
              );

            if (stocksErr.length == 0) {
              const r = $.grep(
                stocks,
                (e) =>
                  e.name.toString().toLowerCase() ==
                  stock.stock_name.toString().toLowerCase()
              );
              if (r.length != 0) {
                if (
                  stock.units.toString().toLowerCase() ==
                  r[0].units.toString().toLowerCase()
                )
                  stock.stock_id = r[0].stock_id;
                else
                  stocksErr.push(
                    `${
                      stock.stock_name
                    } units not matched with stock item units`
                  );
              } else
                stocksErr.push(
                  `${stock.stock_name} stock item not found stock balance`
                );

              tempArray.push(stock);
            }
            $.merge(tempError, stocksErr);
          });
        } else {
          tempError.push(`${item.addon_name} is not found in Addons`);
        }
      }
      obj.recipe = tempArray;
      if (tempError.length == 0) {
        parsedData.push(obj);
      } else {
        errors.push({ name: item.addon_name, errors: tempError.join(",") });
      }
    });
    console.log(
      "parsedDataparsedData==>",
      parsedData,
      "errorserrors===>",
      errors,
      "datadata===>",
      data,
      "stocks====>",
      stocks
    );
    setUpdatedList(parsedData);
    setErrosData(errors);
    if (errors.length > 0) {
      setUploadModal(false);
      setIsUploadError(true);
    } else if (parsedData.length > 0) {
      setUploadModal(false);
      dispatch(uploadAddonRecipes(parsedData, addonRecipeUploadCallback));
    } else {
      setUploadModal(false);
      setSnackbar({
        message: Context.langData.upload_valid_file,
        severity: "error",
      });
    }
  };
  const handleProceedUpload = () => {
    if (updatedList.length > 0) {
      dispatch(uploadAddonRecipes(updatedList, addonRecipeUploadCallback));
    } else {
      setSnackbar({
        message: Context.langData.no_addon_recipes_error,
        severity: "error",
      });
    }
    setIsUploadError(false);
  };
  const handleCancelUpload = () => {
    setIsUploadError(false);
    setUpdatedList([]);
  };
  const addonRecipeUploadCallback = (isSuccess) => {
    commanCallback();
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.addon_recipes_upload_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.addon_recipes_upload_failed,
        severity: "error",
      });
    }
  };
  return (
    <>
      <Grid
        data-qaid='addons_mainContainer'
        container
        style={{ marginBottom: 10 }}>
        <Grid
          data-qaid='addons_downlodeContainer'
          container
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            marginTop: 20,
            marginLeft: 16,
            marginBottom: 10,
          }}>
          <Grid
            data-qaid='addons_downlodeGrid'
            item
            xs
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingTop: 15,
              justifyContent: "flex-end",
            }}>
            <Button
              onClick={download}
              className={style.buttonClass}
              data-qaid='addons_downlodeButton'
              style={{ marginRight: 24 }}
              variant='text'
              id='cancel'>
              <DownlodeIcon
                data-qaid='addons_downlodeIcon'
                style={{ marginRight: 5 }}
                color='#004C60'
              />
              <H4
                data-qaid='addons_downlodeLabel'
                label={Context.langData.download}
                style={{ color: "#004C60" }}
              />
            </Button>
            {/* <Button onClick={triggerUpload} className={style.buttonClass} data-qaid="addons_uploadButton" style={{ backgroundColor: "#FFB600", marginRight: 24 }} variant="contained" id="cancel"  >
                            <UploadIcon data-qaid="addons_uploadIcon" style={{ marginRight: 5 }} />
                            <H4 data-qaid="addons_uploadLabel" label={Context.langData.addons_upload} />
                        </Button> */}
          </Grid>
        </Grid>
      </Grid>
      {recipeAddons.length > 0 && (
        <RecipeDataTable
          data-qaid='addons_recipeDataTable'
          columns={columns}
          rows={recipeAddons}
          sortingOnClick={sortingOnClick}
          onClickView={onClickView}
          noRowData={false}
        />
      )}
      {viewRecipe && (
        <RecipeFormula
          data-qaid='addons_receipeformula'
          open={viewRecipe}
          row={row}
          onClose={() => setViewRecipe(false)}
          callback={callback}
        />
      )}
      {snackbar && (
        <SnackBar
          data-qaid='taxeslist_snackbar'
          open
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
      {UploadModal && (
        <UploadItemRecipe
          data-qaid='items_recipeupload'
          open={UploadModal}
          onClose={() => setUploadModal(false)}
          callback={uploadCallback}
          valueArray={valueArray}
          dropDownName={Context.langData.addon_upload}
          dropDownBody={Context.langData.addon_upload_body}
        />
      )}
      {isUploadError && (
        <UploadErrosTAble
          data-qaid='items_recipeupload'
          open={isUploadError}
          onClose={() => handleCancelUpload()}
          proceed={() => handleProceedUpload()}
          labelName={Context.langData.recipe_name}
          data={errosData}
          title={uploadTitle}
          error={Context.langData.recipe_error_body}
        />
      )}
    </>
  );
};

export default Addons;
