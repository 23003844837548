import React, { useEffect, useState, useContext } from "react";
import { Grid, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import MyContext from "../../../../Provider/MyContext";
import InventoryIcon from "../../../../components/SVG/InventoryIcon";
import CircleClose from "../../../../components/SVG/CircleClose";
import {
  AvenirBlackH4,
  H4,
  H5,
  H6,
  DotText,
  DotTextBoldNormal,
} from "../../../../utils/text";
import InputText from "../../../../components/InputText";
import {
  ConvertPrice,
  parseToFloat,
  ConvertToCurrencyPrice,
  ConvertDateToUTCFormat,
} from "../../../../utils/util";
import styles from "../../../../styles/index";
import SingleDate from "../../../../components/SingleDate";
import { addFulfillment } from "../../../../redux/actions";

const defaultCurrency = "CAD";
const AddFulfillment = ({ open, onClose, row, callback, saveCallback }) => {
  const style = styles();
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  const indentViewData = useSelector((state) => state.inventory.indentViewdata);
  const [error, setError] = useState("");
  const [reqBody, setReqbody] = useState({
    billNumber: "",
    note: "",
    dateReceived: moment().format("MM/DD/YYYY"),
    balance: 0,
    totalTax: 0,
    deliveryCharges: {
      amount: 0,
      currency: defaultCurrency,
    },
    totalAmount: {
      amount: 0,
      currency: defaultCurrency,
    },
    amountPaid: {
      amount: 0,
      currency: defaultCurrency,
    },
    items: [],
  });

  useEffect(() => {
    setError("");
    const itemsArray = [];
    for (let i = 0; i < indentViewData.items?.length; i++) {
      const item = {
        id: indentViewData.items[i].id,
        name: indentViewData.items[i].name,
        fulfilledQuantity: 0,
        requestedQuantity: indentViewData.items[i].requestedQuantity,
        unitTypeCode: indentViewData.items[i].unitTypeCode,
        unitPrice: {
          amount: 0,
          currency: defaultCurrency,
        },
        tax: {
          amount: 0,
          currency: defaultCurrency,
        },
        totalPrice: {
          amount: 0,
          currency: defaultCurrency,
        },
      };
      itemsArray.push(item);
    }

    const object = { ...reqBody, items: itemsArray };
    setReqbody(object);
    setTimeout(console.log(JSON.stringify(reqBody)), 2000);
  }, []);

  const handleSave = () => {
    if (
      parseFloat(reqBody.amountPaid.amount) >
      parseFloat(reqBody.totalAmount.amount)
    ) {
      setError(Context.langData.fulfillment_error);
    } else {
      console.log(`save: ${JSON.stringify(reqBody)}`);
      const purchaseOrderId = indentViewData.id;
      console.log(`purchase order id:${purchaseOrderId}`);
      const itemArray = [];
      reqBody?.items?.map((itemObj) => {
        const item = {
          id: itemObj.id,
          fulfilledQuantity: itemObj.fulfilledQuantity,
          unitPrice: ConvertToCurrencyPrice(
            Math.round(parseToFloat(itemObj.unitPrice.amount) * 100),
            itemObj.unitPrice.currency
          ),
          tax: ConvertToCurrencyPrice(
            Math.round(parseToFloat(itemObj.tax.amount) * 100),
            itemObj.tax.currency
          ),
        };
        itemArray.push(item);
      });
      if (itemArray.length > 0) {
        const req = {
          billNumber: reqBody.billNumber,
          note: reqBody.note,
          dateReceived: ConvertDateToUTCFormat(reqBody.dateReceived),
          deliveryCharges: ConvertToCurrencyPrice(
            Math.round(parseToFloat(reqBody.deliveryCharges.amount) * 100),
            reqBody.deliveryCharges.currency
          ),
          amountPaid: ConvertToCurrencyPrice(
            Math.round(parseToFloat(reqBody.amountPaid.amount) * 100),
            reqBody.amountPaid.currency
          ),
          items: itemArray,
        };
        console.log(`add fulfillment: ${JSON.stringify(req)}`);
        dispatch(addFulfillment(purchaseOrderId, req, saveCallback));
      } else {
        setError(Context.langData.add_fulfillment);
      }
    }
  };

  const handleOnChange = (ojectKey, value) => {
    let obj = { ...reqBody };
    if (ojectKey == "deliveryCharges" || ojectKey == "amountPaid") {
      var { currency } = obj[ojectKey];
      obj = { ...obj, [ojectKey]: ConvertToCurrencyPrice(value, currency) };
    } else {
      obj = { ...obj, [ojectKey]: value };
    }

    let totalAmount = 0;
    let paidAmount = 0;
    obj?.items?.map((itemObj) => {
      totalAmount +=
        parseToFloat(itemObj.tax.amount) +
        parseToFloat(itemObj.unitPrice.amount) *
          parseToFloat(itemObj.fulfilledQuantity);
    });

    if (obj?.amountPaid?.amount) {
      paidAmount = obj.amountPaid.amount;
    }

    if (ojectKey == "deliveryCharges") {
      totalAmount += parseToFloat(value) || 0;
    } else {
      totalAmount += parseToFloat(obj.deliveryCharges.amount) || 0;
    }

    const object = {
      ...obj,
      balance: ConvertPrice(totalAmount - paidAmount),
      totalAmount: ConvertToCurrencyPrice(ConvertPrice(totalAmount), currency),
    };
    setReqbody(object);
  };

  const handleItemChange = (index, key, value) => {
    const obj = { ...reqBody };
    const itemsData = [...reqBody.items];
    const { currency } = itemsData[index][key];
    if (key == "unitPrice" || key == "tax") {
      itemsData[index] = {
        ...itemsData[index],
        [key]: ConvertToCurrencyPrice(value, currency),
      };
    } else {
      itemsData[index] = { ...itemsData[index], [key]: value };
    }
    var itemTotalPrice = 0;
    const itemObj = itemsData[index];
    var itemTotalPrice = ConvertPrice(
      (parseToFloat(itemObj.tax.amount) || 0) +
        parseToFloat(itemObj.unitPrice.amount) *
          parseToFloat(itemObj.fulfilledQuantity)
    );
    itemsData[index] = {
      ...itemsData[index],
      totalPrice: ConvertToCurrencyPrice(itemTotalPrice, currency),
    };
    let totalTax = 0;
    let totalAmount = 0;
    let paidAmount = 0;
    if (obj?.amountPaid?.amount) {
      paidAmount = obj.amountPaid.amount;
    }
    itemsData.map((item) => {
      if (item.tax.amount) {
        totalTax = parseToFloat(totalTax) + parseToFloat(item.tax.amount);
      }
      if (item) {
        totalAmount =
          parseToFloat(totalAmount) +
          parseToFloat(item.fulfilledQuantity) *
            parseToFloat(item.unitPrice.amount) +
          parseToFloat(item.tax.amount);
      }
    });
    const totalAmountValue =
      parseToFloat(totalAmount) +
      (parseToFloat(reqBody.deliveryCharges.amount) || 0);

    const object = {
      ...obj,
      items: itemsData,
      balance: ConvertPrice(totalAmountValue - paidAmount),
      totalTax: ConvertPrice(totalTax),
      totalAmount: ConvertToCurrencyPrice(
        ConvertPrice(totalAmountValue),
        currency
      ),
    };
    setReqbody(object);
  };

  const prefixDollar = (value) => `$${value}`;
  const handleAmountOnBlurChange = (key) => {
    let { amount } = reqBody[key];
    if (typeof amount === "string") {
      amount = amount.replace(/\,/g, "");
    }
    const obj = {
      ...reqBody,
      [key]: ConvertToCurrencyPrice(
        ConvertPrice(amount),
        reqBody[key].currency
      ),
    };
    setReqbody(obj);
  };

  const handleAmount = (val, key) => {
    const validated = val.match(/^(\d*\.{0,1}\d{0,2}$)/);
    if (validated) {
      handleOnChange(key, val || "");
    }
  };

  const handleFocus = (val, key) => {
    const commaVal = val.replace(/\,/g, "");
    const validated = commaVal.match(/^(\d*\.{0,1}\d{0,2}$)/);
    if (validated) {
      handleOnChange(key, commaVal || "");
    }
  };

  return (
    <Grid
      data-qaid='viewIndentId_body_grid'
      style={{ display: "flex", height: "100%", flexDirection: "column" }}>
      <Grid
        data-qaid='viewIndentId_child1_grid'
        style={{
          display: "flex",
          height: error ? "80%" : "85%",
          flexDirection: "column",
          overflowX: "auto",
        }}>
        <Grid
          data-qaid='viewIndentId_close_grid'
          style={{
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}>
          <CircleClose
            data-qaid='viewIndentId_closeicon'
            size={20}
            onClick={onClose}
            style={{ marginRight: 40, marginTop: 20 }}
          />
        </Grid>
        <Grid
          data-qaid='viewIndentId_settings_grid'
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            marginLeft: 56,
            marginBottom: 10,
          }}>
          <InventoryIcon
            data-qaid='viewIndentId_setting_icon'
            style={{ marginRight: 10 }}
            width={22.5}
            height={24}
            color='#707272'
          />
          <H4
            data-qaid='viewIndentId_settings'
            label={Context.langData.inventory}
            style={{ color: "#707272" }}
          />
        </Grid>
        <H6
          data-qaid='viewIndentId_title'
          style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
          label={Context.langData.add_fulfillment}
        />
        <Grid
          data-qaid='viewIndentId_main_grid'
          style={{
            marginLeft: 56,
            marginBottom: 20,
            marginRight: 56,
            width: 500,
            display: "flex",
            flexDirection: "column",
          }}>
          <Grid
            data-qaid='viewIndentId_vendorContainer'
            container
            style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Grid
              data-qaid='viewIndentId_vendorGrid'
              item
              xs={6}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "50%",
              }}>
              <DotTextBoldNormal
                data-qaid='rviewIndentId_vendorLabel'
                label={`${Context.langData.vendor}: ${indentViewData.vendorName}`}
                style={{ marginBottom: 10, width: "100%" }}
              />
            </Grid>
          </Grid>
          <Grid
            data-qaid='viewIndentId_subContainer2'
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: 10,
            }}>
            <Grid
              data-qaid='viewIndentId_billnoGrid'
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "50%",
              }}>
              <AvenirBlackH4
                data-qaid='rviewIndentId_billnoLabel'
                label={`${Context.langData.bill_no}`}
                style={{ marginBottom: 10 }}
              />
              <InputText
                style={{ width: "100%" }}
                name='billno'
                disabled={false}
                maxLength={128}
                value={reqBody.billNumber}
                id='fulfillmentBillNo'
                onChange={(e) => {
                  handleOnChange("billNumber", e.target.value);
                }}
              />
            </Grid>
            <Grid
              data-qaid='viewIndentId_amountGrid'
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "50%",
                marginLeft: 20,
              }}>
              <AvenirBlackH4
                data-qaid='viewIndentId_amountLabel'
                label={`${Context.langData.amount_paid}`}
                style={{ marginBottom: 10 }}
              />
              <InputText
                style={{ width: "100%" }}
                isPlaceHolder={false}
                disabled={false}
                inputPlaceholder='$0.00'
                name='amount'
                maxLength={8}
                id='fulfillmentAmountPaid'
                value={`${reqBody.amountPaid.amount == 0.0 ? "" : `$${reqBody.amountPaid.amount}`}`}
                onBlur={() => {
                  handleAmountOnBlurChange("amountPaid");
                }}
                onChange={(e) =>
                  handleAmount(e.target.value.replace("$", ""), "amountPaid")
                }
                onFocus={(e) =>
                  handleFocus(e.target.value.replace("$", ""), "amountPaid")
                }
              />
            </Grid>
          </Grid>
          <Grid
            data-qaid='viewIndentId_subContainer3'
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: 10,
            }}>
            <Grid
              data-qaid='viewIndentId_totalamountGrid'
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "50%",
              }}>
              <AvenirBlackH4
                data-qaid='rviewIndentId_totalAmountLabel'
                label={`${Context.langData.total_amount}`}
                style={{ marginBottom: 10 }}
              />
              <InputText
                style={{ width: "100%" }}
                disabled
                isPlaceHolder={false}
                inputPlaceholder='$0.00'
                name='total_amount'
                id='fulfillmentTotalAmount'
                value={`${prefixDollar(ConvertPrice(reqBody.totalAmount.amount))}`}
                onChange={(e) =>
                  handleAmount(e.target.value.replace("$", ""), "totalAmount")
                }
              />
            </Grid>
            <Grid
              data-qaid='viewIndentId_deliveryGrid'
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "50%",
                marginLeft: 20,
              }}>
              <AvenirBlackH4
                data-qaid='viewIndentId_deliveryLabel'
                label={`${Context.langData.delivery_charges}`}
                style={{ marginBottom: 10 }}
              />
              <InputText
                style={{ width: "100%" }}
                isPlaceHolder={false}
                disabled={false}
                name='delivery_charges'
                inputPlaceholder='$0.00'
                maxLength={8}
                value={`${reqBody.deliveryCharges.amount == 0.0 ? "" : `$${reqBody.deliveryCharges.amount}`}`}
                id='fulfillmentDelivery'
                onBlur={() => {
                  handleAmountOnBlurChange("deliveryCharges");
                }}
                onChange={(e) =>
                  handleAmount(
                    e.target.value.replace("$", ""),
                    "deliveryCharges"
                  )
                }
                onFocus={(e) =>
                  handleFocus(
                    e.target.value.replace("$", ""),
                    "deliveryCharges"
                  )
                }
              />
            </Grid>
          </Grid>
          <Grid
            data-qaid='viewIndentId_subContainer4'
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: 10,
            }}>
            <Grid
              data-qaid='viewIndentId_balanceGrid'
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "50%",
              }}>
              <AvenirBlackH4
                data-qaid='rviewIndentId_balanceLabel'
                label={`${Context.langData.balance}`}
                style={{ marginBottom: 10 }}
              />
              <InputText
                style={{ width: "100%" }}
                disabled
                isPlaceHolder={false}
                inputPlaceholder='$0.00'
                name='balance'
                id='fulfillmentBalance'
                value={`${prefixDollar(ConvertPrice(reqBody.balance))}`}
                onChange={(e) => {
                  console.log("//");
                }}
              />
            </Grid>
            <Grid
              data-qaid='viewIndentId_totalTaxGrid'
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "50%",
                marginLeft: 20,
              }}>
              <AvenirBlackH4
                data-qaid='viewIndentId_totalTaxLabel'
                label={`${Context.langData.total_tax}`}
                style={{ marginBottom: 10 }}
              />
              <InputText
                style={{ width: "100%" }}
                disabled
                isPlaceHolder={false}
                inputPlaceholder='$0.00'
                name='total_tax'
                id='fulfillmentTax'
                value={`${prefixDollar(ConvertPrice(reqBody.totalTax))}`}
                onChange={(e) => {
                  console.log("//");
                }}
              />
            </Grid>
          </Grid>
          <Grid
            data-qaid='viewIndentId_date_receivedGrid'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              width: "100%",
              marginTop: 10,
            }}>
            <AvenirBlackH4
              data-qaid='viewIndentId_date_receivedLabel'
              label={`${Context.langData.date_received}`}
              style={{ marginBottom: 10 }}
            />
            {/* <DatePicker data-qaid="viewIndentId_datepicker_Label" value={moment().format("YYYY-MM-DD")} callback={(e) => { handleOnChange("received_on", moment(e).format('YYYY-MM-DD')) }} style={{ width: '50%' }} /> */}
            <Grid style={{ width: "50%" }}>
              <SingleDate
                value={reqBody.dateReceived}
                height={56}
                maxDate={new Date()}
                border={false}
                callback={(e) => {
                  handleOnChange(
                    "dateReceived",
                    moment(e).format("MM/DD/YYYY")
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid
            data-qaid='viewIndentId_noteGrid'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              width: "100%",
            }}>
            <AvenirBlackH4
              data-qaid='rviewIndentId_noteLabel'
              label={`${Context.langData.note}`}
              style={{ marginBottom: 10 }}
            />
            <InputText
              style={{ width: "100%" }}
              name='note'
              id='fulfillmentNotes'
              value={reqBody.note}
              isPlaceHolder={false}
              maxLength={512}
              // onChange={(e) => { setNotes(e.target.value) }}
              onChange={(e) => {
                handleOnChange("note", e.target.value);
              }}
            />
          </Grid>
          <Grid
            data-qaid='viewIndentId_table_body'
            item
            style={{
              marginTop: "20px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}>
            <Grid
              data-qaid='viewIndentId_subContainer3'
              item
              style={{
                height: "40px",
                width: "100%",
                backgroundColor: "#F3FAFD",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}>
              <Grid
                data-qaid='viewIndentId_dateGrid'
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  width: "20%",
                }}>
                <AvenirBlackH4
                  data-qaid='viewIndentId_itemHeading'
                  label={Context.langData.items}
                  style={{ paddingLeft: 20 }}
                />
              </Grid>
              <Grid
                data-qaid='viewIndentId_dateGrid'
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  width: "16%",
                }}>
                <AvenirBlackH4
                  data-qaid='viewIndentId_request_qtyHeading'
                  label={Context.langData.requested}
                />
              </Grid>
              <Grid
                data-qaid='viewIndentId_dateGrid'
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  width: "16%",
                }}>
                <AvenirBlackH4
                  data-qaid='viewIndentId_request_qtyHeading'
                  label={Context.langData.received}
                />
              </Grid>
              <Grid
                data-qaid='viewIndentId_dateGrid'
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  width: "16%",
                }}>
                <AvenirBlackH4
                  data-qaid='viewIndentId_request_qtyHeading'
                  label={Context.langData.unit_price}
                />
              </Grid>
              <Grid
                data-qaid='viewIndentId_dateGrid'
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  width: "16%",
                }}>
                <AvenirBlackH4
                  data-qaid='viewIndentId_request_qtyHeading'
                  label={Context.langData.tax}
                />
              </Grid>
              <Grid
                data-qaid='viewIndentId_dateGrid'
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  width: "16%",
                }}>
                <AvenirBlackH4
                  data-qaid='viewIndentId_request_qtyHeading'
                  label={Context.langData.price}
                  style={{ marginRight: 4 }}
                />
              </Grid>
            </Grid>

            {reqBody?.items?.length > 0
              ? reqBody.items.map((itemObj, index) => (
                  <Grid
                    key={itemObj.stock_name}
                    data-qaid='viewIndentId_subContainer3'
                    item
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      backgroundColor: "#FAFAFA",
                      minHeight: 50,
                      marginTop: 6,
                    }}>
                    <Grid
                      data-qaid='viewIndentId_dateGrid'
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        width: "20%",
                      }}>
                      <DotText
                        data-qaid='viewIndentId_itemHeading'
                        label={`${itemObj.name}`}
                        style={{ paddingLeft: 20 }}
                      />
                    </Grid>
                    <Grid
                      data-qaid='viewIndentId_dateGrid'
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        width: "16%",
                      }}>
                      <H5
                        data-qaid='viewIndentId_request_qtyHeading'
                        label={`${itemObj.requestedQuantity} ${itemObj.unitTypeCode}`}
                      />
                    </Grid>
                    <Grid
                      data-qaid='viewIndentId_dateGrid'
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        width: "15%",
                        marginRight: "1%",
                      }}>
                      <InputText
                        style={{ backgroundColor: "#FFFFFF", maxHeight: 38 }}
                        name='fulfilledQuantity'
                        height={38}
                        maxLength={8}
                        id={itemObj.itemId}
                        value={itemObj.fulfilledQuantity || ""}
                        isPlaceHolder={false}
                        onChange={(e) => {
                          handleItemChange(
                            index,
                            "fulfilledQuantity",
                            parseToFloat(e.target.value) || ""
                          );
                        }}
                      />
                    </Grid>
                    <Grid
                      data-qaid='viewIndentId_dateGrid'
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        width: "15%",
                        marginRight: "1%",
                      }}>
                      <InputText
                        style={{ backgroundColor: "#FFFFFF", maxHeight: 38 }}
                        name='unitPrice'
                        height={38}
                        placeholder='$0.00'
                        id={itemObj.unitPrice.amount}
                        maxLength={9} // with $
                        value={`$${itemObj.unitPrice.amount || ""}`}
                        isPlaceHolder={false}
                        onBlur={() => {
                          let { amount } = itemObj.unitPrice;
                          if (typeof amount === "string") {
                            amount = amount.replace(/\,/g, "");
                          }
                          handleItemChange(
                            index,
                            "unitPrice",
                            ConvertPrice(amount)
                          );
                        }}
                        onChange={(e) => {
                          handleItemChange(
                            index,
                            "unitPrice",
                            e.target.value.replace("$", "") || ""
                          );
                        }}
                      />
                    </Grid>
                    <Grid
                      data-qaid='viewIndentId_dateGrid'
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        width: "15%",
                        marginRight: "1%",
                      }}>
                      <InputText
                        style={{
                          backgroundColor: "#FFFFFF",
                          maxHeight: 38,
                          width: "100%",
                        }}
                        name='taxAmount'
                        height={38}
                        placeholder='$0.00'
                        maxLength={9} // with $
                        value={`$${itemObj.tax.amount || ""}`}
                        id={itemObj.tax.amount}
                        isPlaceHolder={false}
                        onBlur={() => {
                          let { amount } = itemObj.tax;
                          if (typeof amount === "string") {
                            amount = amount.replace(/\,/g, "");
                          }
                          handleItemChange(index, "tax", ConvertPrice(amount));
                        }}
                        onChange={(e) => {
                          handleItemChange(
                            index,
                            "tax",
                            e.target.value.replace("$", "") || ""
                          );
                        }}
                      />
                    </Grid>
                    <Grid
                      data-qaid='viewIndentId_dateGrid'
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        width: "16%",
                      }}>
                      <AvenirBlackH4
                        data-qaid='viewIndentId_request_qtyHeading'
                        label={`$${itemObj.totalPrice.amount}`}
                        style={{ marginRight: 4 }}
                      />
                    </Grid>
                  </Grid>
                ))
              : null}
          </Grid>
        </Grid>
      </Grid>
      {error && (
        <Grid
          style={{
            height: "5%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <AvenirBlackH4
            label={error}
            style={{
              color: "#AB1212",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        </Grid>
      )}
      <Grid
        data-qaid='createGroup_cancel_main_Grid'
        style={{
          display: "flex",
          height: "15%",
          flexDirection: "column",
          justifyContent: "flex-end",
          backgroundColor: "#FAFAFA",
          border: "1px solid #C3C3C3",
          marginTop: 2,
        }}>
        <Grid
          data-qaid='createGroup_cancelGrid'
          item
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            paddingTop: "25px",
            alignItems: "center",
            paddingBottom: "30px",
            paddingRight: 25,
          }}>
          <Button
            className={style.buttonClass}
            data-qaid='createGroup_cancelButton'
            style={{
              backgroundColor: "#FFF",
              border: "2px solid #051D33",
              marginRight: 10,
              width: 103,
              height: 40,
              borderColor: "#707272",
            }}
            variant='contained'
            id='cancel'
            onClick={onClose}>
            <AvenirBlackH4
              data-qaid='createGroup_cancelLabel'
              label={Context.langData.cancel}
              style={{ color: "#004C60" }}
            />
          </Button>
          <Button
            className={style.buttonClass}
            data-qaid='createGroup_saveButton'
            style={{
              backgroundColor: "#FFB600",
              marginRight: 10,
              width: 90,
              height: 40,
            }}
            variant='contained'
            id='cancel'
            type='submit'
            onClick={() => handleSave()}>
            <AvenirBlackH4
              data-qaid='createGroup_saveLabel'
              label={Context.langData.save}
            />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddFulfillment;
