import * as React from "react";

const VerticalGrid = (props) => (
  <svg
    {...props}
    width={56}
    height={76}
    viewBox='0 0 48 76'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <mask
      id='prefix__a'
      maskUnits='userSpaceOnUse'
      x={0}
      y={0}
      width={48}
      height={48}>
      <rect
        x={47.5}
        y={0.5}
        width={47}
        height={47}
        rx={4.5}
        transform='rotate(90 47.5 .5)'
        fill='#fff'
        stroke='#EEE'
      />
    </mask>
    <g mask='url(#prefix__a)'>
      <rect
        x={48}
        width={48}
        height={48}
        rx={5}
        transform='rotate(90 48 0)'
        fill='#EEE'
      />
      <path
        stroke='#373737'
        strokeWidth={3}
        d='M38.5 0v49M32.5 0v49M26.5 0v49M20.5 0v49M14.5 0v49M8.5 0v49'
      />
    </g>
    <path
      d='M7.363 69h.972l3.324-8.496H10.59l-2.7 6.984h-.024L5.25 60.504H4.11L7.363 69zm8.731-1.572c-.444.564-.984.852-1.788.852-1.02 0-1.764-.828-1.836-1.8h4.536v-.3c0-1.86-1.08-2.94-2.664-2.94-1.656 0-2.88 1.212-2.88 2.952 0 1.632 1.08 2.952 2.844 2.952 1.044 0 1.836-.312 2.496-1.164l-.708-.552zm-3.624-1.74c.12-.948.804-1.584 1.836-1.584s1.656.636 1.692 1.584H12.47zM18.454 69h.936v-2.736c0-1.284.504-2.088 1.644-2.088.168 0 .348.024.504.072l.06-.948a1.112 1.112 0 00-.384-.06c-.924 0-1.62.516-1.872 1.068h-.024c0-.312-.024-.624-.024-.924h-.888c0 .372.048.816.048 1.332V69zm7.291-5.616H24.09v-1.596h-.936v1.596H21.93v.792h1.224v3.084c0 1.164.216 1.884 1.548 1.884.348 0 .768-.06 1.092-.204l-.036-.852a1.822 1.822 0 01-.828.192c-.828 0-.84-.576-.84-1.296v-2.808h1.656v-.792zM26.975 69h.937v-5.616h-.936V69zm-.215-7.812c0 .396.288.684.684.684a.662.662 0 00.684-.684.684.684 0 10-1.368 0zm7.755 2.94c-.564-.612-1.332-.888-2.16-.888-1.728 0-2.904 1.248-2.904 2.952 0 1.728 1.164 2.952 2.904 2.952.816 0 1.584-.276 2.124-.9l-.696-.66c-.348.48-.816.696-1.428.696-1.14 0-1.896-.936-1.896-2.088 0-1.152.636-2.088 1.896-2.088.564 0 1.02.204 1.416.684l.744-.66zm1.565.588c.444-.408 1.068-.612 1.668-.612.876 0 1.392.42 1.392 1.236v.168h-.36c-1.38 0-3.54.108-3.54 1.968 0 1.092.948 1.668 2.088 1.668.852 0 1.476-.408 1.848-.984h.024c0 .24 0 .588.06.84h.9a6.237 6.237 0 01-.084-.972v-2.412c0-1.584-.696-2.376-2.352-2.376-.816 0-1.656.276-2.232.864l.588.612zm3.06 1.584v.36c-.024.912-.576 1.692-1.656 1.692-.708 0-1.236-.264-1.236-.972 0-.756.768-1.08 1.956-1.08h.936zm2.73 2.7h.936v-9.072h-.936V69z'
      fill='#000'
    />
  </svg>
);

export default VerticalGrid;
