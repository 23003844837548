import React, { useContext } from "react";
import { Button, Grid } from "@material-ui/core";
import MyContext from "../../../../Provider/MyContext";
import { H4, H2, AvenirBlackH4 } from "../../../../utils/text";
import CenterModal from "../../../../components/CenterModal";

const ChangeKitchenModal = ({ openModal, closeModal, callbackModal }) => {
  const Context = useContext(MyContext);
  const handleYes = () => {
    callbackModal(true);
  };

  return (
    <CenterModal
      open={openModal}
      style={{ width: 400, height: 278 }}
      borderRadius={5}
      onClose={closeModal}>
      <Grid
        borderRadius={5}
        style={{
          width: 400,
          height: 278,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: 30,
        }}>
        <H2 label={Context.langData.change_default_kitchen} />
        <H4
          label={
            Context.langData
              .changing_the_default_kitchen_will_mean_all_categories
          }
          style={{ lineHeight: "24px" }}
        />
        <Grid
          style={{
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}>
          <Button
            data-qaid='dialog_cancel_button'
            variant='contained'
            onClick={closeModal}
            style={{
              backgroundColor: "#FFF",
              width: "48%",
              height: 44,
              border: "2px solid #051D33",
            }}>
            <AvenirBlackH4
              data-qaid='dialog_cancel_label'
              label={Context.langData.cancel}
            />
          </Button>
          <Button
            data-qaid='dialog_delete_button'
            variant='contained'
            onClick={handleYes}
            style={{ backgroundColor: "#FFB600", width: "48%", height: 44 }}>
            <AvenirBlackH4
              data-qaid='dialog_delete_label'
              label={Context.langData.yes}
            />
          </Button>
        </Grid>
      </Grid>
    </CenterModal>
  );
};

export default ChangeKitchenModal;
