import React, { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import moment from "moment";
import styles from "../../../styles/index";
import RightDrawer from "../../../components/RightDrawer";
import InputText from "../../../components/InputText";
import CirclePluse from "../../../components/SVG/CirclePluse";
import MyContext from "../../../Provider/MyContext";
import {
  H4,
  H3,
  AvenirBlackH4,
  H5,
  AvenirBlackH3,
  H8,
} from "../../../utils/text";
import CloseCircle from "../../../components/SVG/CloseCircle";
// import DatePicker from '../../../components/DatePicker';
import PettyCashIcon from "../../../components/SVG/PettyCashIcon";
import DownVector from "../../../components/SVG/DownVector";
import UpVector from "../../../components/SVG/UpVector";
import SingleSelect from "../../../components/SingleSelect";
import { createPettyCashExpenses } from "../../../redux/actions";
import SnackBar from "../../../components/SnackBar";
import ExclamationIcon from "../../../components/SVG/ExclamationIcon";

const AddExpenses = ({
  open,
  onClose,
  callback,
  sessionData = null,
  closeBalance,
}) => {
  const style = styles();
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([
    { cat_id: "", notes: "", amount: "", tx_type: 0 },
  ]);
  const PettyCashCategories = useSelector(
    (state) => state.pettyCashReducer.categories
  );
  const [txType, setTxType] = useState(0);
  const [submitClick, setSubmittClick] = useState(false);
  const [openList, setOpenList] = useState([0]);
  const [amountError, setAmountError] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const txList = [
    {
      id: 0,
      name: "Credit",
    },
    {
      id: 1,
      name: "Debit",
    },
  ];
  const handleNoteChange = (index, value) => {
    if (value.length < 501) {
      const dataArray = [...data];
      dataArray[index] = { ...dataArray[index], notes: value };
      setData(dataArray);
    }
  };
  const handleAmountChange = (index, value) => {
    const dataArray = [...data];
    dataArray[index] = { ...dataArray[index], amount: value };
    setData(dataArray);
  };
  const handleExpensesCatChange = (index, value) => {
    setIsError(false);
    const dataArray = [...data];
    dataArray[index] = { ...dataArray[index], cat_id: value };
    setData(dataArray);
  };
  const handleTransactionTypeCatChange = (index, value) => {
    setIsError(false);
    const dataArray = [...data];
    dataArray[index] = { ...dataArray[index], tx_type: value };
    setData(dataArray);
  };
  const handleIncreaseExpense = () => {
    setIsError(false);
    const dataArray = [...data];
    dataArray.push({ cat_id: "", notes: "", amount: "", tx_type: 0 });
    const list = [...openList];
    list.push(dataArray.length - 1);
    setOpenList(list);
    setData(dataArray);
  };
  const handleSave = () => {
    setSubmittClick(true);
    let errorFlog = 0;
    if (data) {
      data.map((item) => {
        if (item.cat_id) {
        } else {
          errorFlog += 1;
        }
        if (item.amount <= 0) {
          setAmountError(true);
          errorFlog += 1;
        } else {
        }
      });
    } else {
      errorFlog = 1;
    }
    if (errorFlog == 0) {
      const obj = {
        txn_date: sessionData,
        ts: moment().unix(),
        txns: data,
      };
      let insuffecientError = false;
      if (data) {
        data.map((item) => {
          if (item.tx_type == 1) {
            if (parseFloat(item.amount) > closeBalance) {
              insuffecientError = true;
            }
          }
        });
        if (!insuffecientError) {
          dispatch(createPettyCashExpenses(obj, callback));
        } else {
          setSnackbar({ message: "Insuffecient balance ", severity: "error" });
        }
      }
    } else {
    }
  };
  const handleClickAccordion = (index) => {
    const list = [...openList];
    const isIndex = list.findIndex((e) => e == index);
    if (isIndex == -1) {
      list.push(index);
    } else {
      list.splice(isIndex, 1);
    }
    setOpenList(list);
  };
  const isExpand = (index) => {
    let value = false;
    const list = [...openList];
    const isIndex = list.findIndex((e) => e == index);
    if (isIndex == -1) {
    } else {
      value = true;
    }
    return value;
  };
  // const isValidNumber = (value) => {
  //     return isNaN(value)
  // }
  return (
    <RightDrawer
      data-qaid='addExpenses_rightmodal'
      open={open}
      onClose={onClose}
      padding={1}>
      <>
        <Grid
          data-qaid='addExpenses_main_grid'
          style={{ display: "flex", height: "100%", flexDirection: "column" }}>
          <Grid
            data-qaid='addExpenses_child2_grid'
            style={{
              display: "flex",
              height: "85%",
              flexDirection: "column",
              overflowX: "auto",
            }}>
            <Grid
              data-qaid='addExpenses_close_grid'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
                marginTop: 20,
              }}>
              <CloseCircle
                data-qaid='addExpenses_close_icon'
                style={{ marginRight: 40 }}
                onClick={onClose}
              />
            </Grid>
            <Grid
              data-qaid='addExpenses_pettycash_grid'
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "row",
                marginLeft: 48,
              }}>
              <PettyCashIcon
                data-qaid='IaddExpenses_pettycash_icon'
                color='#707272'
                width={16}
                height={14}
                style={{ marginRight: 5 }}
              />
              <H4
                data-qaid='addExpenses_heade5'
                style={{
                  color: "#707272",
                  display: "flex",
                  alignItems: "center",
                }}
                label={Context.langData.petty_cash}
              />
            </Grid>
            <H8
              data-qaid='addExpenses_header'
              style={{ marginLeft: 48, marginBottom: 56, marginTop: 10 }}
              label={Context.langData.adding_an_expense}
            />
            <Grid
              data-qaid='startSession_grid1'
              style={{
                display: "flex",
                flexDirection: "column",
                width: 500,
                marginLeft: 48,
                marginRight: 48,
              }}>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <Grid style={{ paddingTop: 10 }}>
                    <Grid
                      container
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        cursor: "pointer",
                        marginBottom: 16,
                        borderBottom: "1px solid #EEEEEE",
                        paddingBottom: 16,
                      }}
                      onClick={() => handleClickAccordion(index)}>
                      <Grid
                        item
                        lg={10}
                        sm={12}
                        md={10}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}>
                        <H3
                          data-qaid='addExpenses_expensesname'
                          label={`${Context.langData.expense} ${index + 1}`}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        sm={2}
                        md={2}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}>
                        {isExpand(index) ? <UpVector /> : <DownVector />}
                      </Grid>
                    </Grid>
                    {isExpand(index) ? (
                      <Grid
                        data-qaid='addExpenses_main_grid'
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}>
                        <Grid
                          data-qaid='addExpenses_expensecat_grid'
                          style={{
                            display: "flex",
                            width: "100%",
                            flexDirection: "column",
                            marginBottom: 16,
                          }}>
                          <AvenirBlackH4
                            label={Context.langData.expense_category}
                            style={{ marginBottom: 10 }}
                          />
                          <SingleSelect
                            data-qaid='addExpenses_expensecat_select'
                            list={PettyCashCategories}
                            valueKey='id'
                            displayKey='cat_name'
                            value={item.cat_id}
                            handleChange={(e) =>
                              handleExpensesCatChange(index, e.target.value)
                            }
                            border={false}
                            height={56}
                            // minWidth={150}
                            // maxWidth={170}
                          />
                          {item.cat_id ? (
                            <></>
                          ) : (
                            <>
                              {submitClick && (
                                <Grid
                                  style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    display: "flex",
                                    marginTop: 5,
                                  }}>
                                  <ExclamationIcon
                                    color='#AB1212'
                                    style={{ marginRight: 5 }}
                                  />
                                  <H5
                                    label={
                                      Context.langData.please_select_category
                                    }
                                    style={{ color: "#AB1212" }}
                                  />
                                </Grid>
                              )}
                            </>
                          )}
                        </Grid>
                        <Grid
                          data-qaid='addExpenses_expensecat_grid'
                          style={{
                            display: "flex",
                            width: "100%",
                            flexDirection: "column",
                            marginBottom: 16,
                          }}>
                          <AvenirBlackH4
                            label={Context.langData.transaction_type}
                            style={{ marginBottom: 10 }}
                          />
                          <SingleSelect
                            data-qaid='addExpenses_expensecat_select'
                            list={txList}
                            valueKey='id'
                            displayKey='name'
                            value={item.tx_type}
                            handleChange={(e) =>
                              handleTransactionTypeCatChange(
                                index,
                                e.target.value
                              )
                            }
                            border={false}
                            height={56}

                            // minWidth={150}
                            // maxWidth={170}
                          />
                        </Grid>
                        <Grid
                          data-qaid='addExpenses_note_grid'
                          style={{
                            display: "flex",
                            width: "100%",
                            flexDirection: "column",
                            marginBottom: 16,
                          }}>
                          <AvenirBlackH4
                            data-qaid='addExpenses_notelabel'
                            label={Context.langData.note}
                            style={{ marginBottom: 10 }}
                          />
                          <textarea
                            style={{
                              width: "100%",
                              height: 128,
                              fontFamily: "AvenirLT",
                              fontSize: 14,
                              resize: "none",
                              paddingTop: 10,
                              paddingLeft: 10,
                              outlineColor: "#004C60",
                              borderRadius: 5,
                            }}
                            data-qaid='addExpenses_noteip'
                            value={item.notes}
                            onChange={(val) =>
                              handleNoteChange(index, val.target.value)
                            }
                            id='name'
                          />
                          <H5
                            data-qaid='addExpenses_note_error'
                            label={`${item.notes.length || 0}${Context.langData._500_characters_count}`}
                            style={{ color: "#707272" }}
                          />
                        </Grid>
                        <Grid
                          data-qaid='addExpenses_amount_grid'
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: 16,
                            width: "100%",
                          }}>
                          <AvenirBlackH4
                            data-qaid='addExpenses_amount_label'
                            label={Context.langData.total_amount}
                            style={{ marginBottom: 10 }}
                          />
                          <InputText
                            data-qaid='addExpenses_amuntip'
                            value={`$${item.amount}`}
                            maxLength={9} // with $
                            onChange={(val) =>
                              handleAmountChange(
                                index,
                                val.target.value.replace(/[^0-9\.?]/g, "")
                              )
                            }
                            id='name'
                            inputPlaceholder='$0'
                          />
                          {/* {amountError && <H5 label={Context.langData.valid_number} style={{ color: '#FF0000', marginTop: 8 }}></H5>} */}
                          {item.amount <= 0 ? (
                            <>
                              {amountError && (
                                <Grid
                                  style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    display: "flex",
                                    marginTop: 5,
                                  }}>
                                  <ExclamationIcon
                                    color='#AB1212'
                                    style={{ marginRight: 5 }}
                                  />
                                  <H5
                                    label={Context.langData.valid_number}
                                    style={{ color: "#AB1212" }}
                                  />
                                </Grid>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                ))
              ) : (
                <Grid />
              )}
              <Grid
                data-qaid='addExpenses_createexpenses_grid'
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  morginTop: 30,
                  borderBottom: "1px solid #EEEEEE",
                }}>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 10,
                    marginBottom: 10,
                    width: "100%",
                  }}>
                  <Grid
                    data-qaid='addExpenses_crete_expenses_grid2'
                    style={{
                      width: "70%",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}>
                    <AvenirBlackH3
                      data-qaid='addExpenses_createexpenses_label'
                      label={Context.langData.create_another_new_expense}
                      style={{ color: "#004C60" }}
                    />
                  </Grid>
                  <Grid
                    data-qaid='addExpenses_create_pluse_grid'
                    style={{
                      width: "30%",
                      display: "flex",
                      justifyContent: "flex-end",
                      cursor: "pointer",
                    }}>
                    <CirclePluse
                      data-qaid='addExpenses_create_pluseicon'
                      color='#004C60'
                      width={23}
                      height={23}
                      onClick={() => {
                        handleIncreaseExpense();
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            data-qaid='addExpenses_button_grid'
            container
            style={{
              display: "flex",
              height: "15%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid
              data-qaid='addExpenses_action_grid'
              container
              style={{
                backgroundColor: "#FAFAFA",
                border: "1px solid #C3C3C3",
              }}>
              <Grid style={{ display: "flex", marginLeft: 56 }}>
                {isError ? (
                  <H4
                    label={Context.langData.addons_error}
                    style={{ color: "#FF0000" }}
                  />
                ) : null}
              </Grid>
              <Grid
                data-qaid='addExpenses_cancel_btn'
                item
                xs={12}
                lg={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 32,
                  alignItems: "center",
                  marginRight: 32,
                }}>
                <Button
                  className={style.buttonClass}
                  data-qaid='addExpenses_cancel_button'
                  onClick={onClose}
                  variant='outlined'
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "2px solid #051D33",
                    marginRight: 16,
                    height: 40,
                    marginBottom: 16,
                    width: 103,
                  }}>
                  <AvenirBlackH4
                    data-qaid='addExpenses_cancel_text'
                    label={Context.langData.cancel}
                  />
                </Button>
                <Button
                  className={style.buttonClass}
                  data-qaid='addExpenses_cancel_button'
                  onClick={handleSave}
                  variant='contained'
                  style={{
                    backgroundColor: "#FFB600",
                    marginRight: 16,
                    marginBottom: 16,
                    height: 40,
                    width: 111,
                  }}>
                  <AvenirBlackH4
                    data-qaid='addExpenses_cancel_text'
                    label={Context.langData.confirm}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {snackbar && (
          <SnackBar
            data-qaid='categories_snackbar'
            open
            setOpen={() => setSnackbar(null)}
            severity={snackbar.severity}
            message={snackbar.message}
          />
        )}
      </>
    </RightDrawer>
  );
};

export default AddExpenses;
