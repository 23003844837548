import moment from "moment";

const initialState = {
  kitchens: [],
  printers: [],
  meta: [],
  shifts: [],
  payments: [],
  configPayments: [],
  offers: [],
  reservation: {},
  assignTaxes: [],
  orderDeliveryMethods: {},
  deliveryOptions: [],
  rememberMe: false,
  terminals: [],
  eodSettings: {},
  eodReports: [],
  kitchenTicketSettings: [],
  guestCheckSettings: [],
};
function parsePrinter(response) {
  const obj = response;
  obj.map((d) => {
    d.id = d.printer_id;
    if (d.kitchens) {
      d.assignment = d.kitchens.map((x) => x.kitchen_name).join(", ");
      d.assigned_kitchens = d.kitchens.map((x) => ({
        id: x.kitchen_id,
        name: x.kitchen_name,
      }));
      console.log("parsePrinter");
      console.log(d.assigned_kitchens);
    }
  });
  return obj;
}
function parseMeta(response) {
  const obj = response;
  obj.map((d) => {
    d.id = d.label_id;
  });
  return obj;
}
function parseShifts(response) {
  const obj = response;
  obj.map((d) => {
    d.id = d.shift_id;
    d.start_time = moment(d.start_time, "H:mm:ss").format("HH:mm");
    d.end_time = moment(d.end_time, "H:mm:ss").format("HH:mm");
  });
  return obj;
}

function parsePayments(response) {
  const obj = response;
  obj.map((d) => {
    d.id = d.payment_id;
  });
  return obj;
}

function parseDeliveryOptions(response) {
  const obj = response;
  obj.map((d) => {
    d.id = d.delivery_option_id;
  });
  return obj;
}
function parseOffers(response) {
  const obj = response;
  obj.map((d) => {
    d.id = d.promotion_id;
    if (d.active == 1) {
      d.status = "Active";
    } else {
      d.status = "Inactive";
    }
    d.discount =
      d.discount_type == "flat" ? `$${d.discount}` : `${d.discount}%`;
  });
  return obj;
}
function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_KITCHENS_SUCCESS":
      return {
        ...state,
        kitchens: action.payload,
      };
    case "GET_PRINTERS_SUCCESS":
      return {
        ...state,
        printers: parsePrinter(action.payload),
      };
    case "GET_MORE_SUCCESS":
      return {
        ...state,
        meta: parseMeta(action.payload),
      };
    case "GET_SHIFTS_SUCCESS":
      return {
        ...state,
        shifts: parseShifts(action.payload),
      };

    case "GET_PAYMENT_SUCCESS":
      return {
        ...state,
        payments: parsePayments(action.payload),
      };
    case "GET_CONFIG_PAYMENT_SUCCESS":
      return {
        ...state,
        configPayments: parsePayments(action.payload),
      };
    case "GET_OFFERS_SUCCESS":
      return {
        ...state,
        offers: parseOffers(action.payload),
      };
    case "GET_RESERVATIONS_SUCCESS":
      return {
        ...state,
        reservation: action.payload,
      };
    case "GET_ASSIGN_TAXES_SUCCESS":
      return {
        ...state,
        assignTaxes: action.payload,
      };
    case "GET_ORDER_DELIVERY":
      return {
        ...state,
        orderDeliveryMethods: action.payload,
      };
    case "GET_DELIVERY_OPTIONS":
      return {
        ...state,
        deliveryOptions: parseDeliveryOptions(action.payload),
      };
    case "GET_REMEMBER_ME_SUCCESS":
      return {
        ...state,
        rememberMe: action.payload.remember_login,
      };
    case "GET_TERMINAL_SUCCESS":
      return {
        ...state,
        terminals: action.payload,
      };
    case "GET_EOD_SETTINGS":
      return {
        ...state,
        eodSettings: { ...action.payload },
      };

    case "GET_EOD_REPORTS":
      return {
        ...state,
        eodReports: action.payload,
      };
    case "GET_KITCHEN_TICKET_SETTINGS":
      return {
        ...state,
        kitchenTicketSettings: action.payload,
      };
    case "GET_GUEST_CHECK_SETTINGS":
      return {
        ...state,
        guestCheckSettings: action.payload,
      };
    case "CLEAR":
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
export default settingsReducer;
