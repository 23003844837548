import qs from "qs";
import instance from "./axiosInstance";
import urls from "./urls";

const fetchRoles = (params, callback) => (dispatch) => {
  instance.get(urls.roles.all, { params }).then((response) => {
    console.log(response);
    if (response.status === 200) {
      dispatch({ type: "GET_ROLE_SUCCESS", payload: response.data });
      if (callback) {
        callback();
      }
    }
  });
};

const fetchRolePermissions = () => (dispatch) => {
  instance.get(urls.roles.permissions).then((response) => {
    console.log(response);
    if (response.status === 200) {
      dispatch({
        type: "GET_ROLE_PERMISSIONS_SUCCESS",
        payload: response.data,
      });
    }
  });
};
const fetchEmployees = (params) => {
  console.log("call employe url", JSON.stringify(params));
  return (dispatch) => {
    instance
      .get(urls.employees.all, {
        params,
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "repeat" }),
      })
      .then((response) => {
        console.log(`GET_EMPLOYEES_SUCCESS${response}`);
        if (response.status == 200) {
          dispatch({ type: "GET_EMPLOYEES_SUCCESS", payload: response.data });
        }
      });
  };
};
const fetchAllEmployees = (params, callBack) => {
  console.log("call employe url", JSON.stringify(params));
  return (dispatch) => {
    instance
      .get(urls.employees.allEmployees, {
        params,
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "repeat" }),
      })
      .then((response) => {
        console.log(`GET_EMPLOYEES_SUCCESS${response}`);
        callBack(response.data);
        if (response.status == 200) {
          // dispatch({ type: 'GET_EMPLOYEES_SUCCESS', payload: response.data })
        }
      })
      .catch((err) => {
        callBack({ employees: [] });
      });
  };
};
const fetchEmployee = (params, callback) => (dispatch) => {
  instance.get(urls.employees.employee, { params }).then((response) => {
    console.log(response);
    if (response.status === 200) {
      if (callback) {
        callback(response.data);
      }
    }
  });
};

const fetchRoleDetails = (params, callback) => {
  console.log("call employe url");
  return (dispatch) => {
    instance.get(urls.roles.roles, { params }).then((response) => {
      console.log(`GET_EMPLOYEES_SUCCESS${response}`);
      if (response.status == 200) {
        callback(response.data);
        // dispatch({ type: 'GET_EMPLOYEES_SUCCESS', payload: response.data })
      }
    });
  };
};
const fetchCustomers = (params) => {
  console.log("call customer url", params, "paramsÇparams");
  return (dispatch) => {
    instance.get(urls.customers.all, { params }).then((response) => {
      console.log(`GET_CUSTOMER_SUCCESS${response}`);
      if (response.status == 200) {
        dispatch({ type: "GET_CUSTOMERS_SUCCESS", payload: response.data });
      }
    });
  };
};
const fetchAllCustomers = (params, callBack) => {
  console.log("call customer url", params, "paramsÇparams");
  return (dispatch) => {
    instance
      .get(urls.customers.Allcustomer, { params })
      .then((response) => {
        if (response.status == 200) {
          console.log(
            "response.data ====>response.dataresponse.data====>",
            response.data
          );
          callBack(response.data);
        }
      })
      .catch((e) => {
        console.log("custom", e);
        callBack({ customers: [] });
      });
  };
};
const fetchCustomer = (params, callback) => (dispatch) => {
  instance.get(urls.customers.customer, { params }).then((response) => {
    console.log(response);
    if (response.status === 200) {
      if (callback) {
        callback(response.data);
      }
    }
  });
};

const addRole = (cred, callback) => (dispatch) => {
  instance
    .post(urls.roles.roles, cred)
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        callback(true, response.data);
        dispatch({ type: "" });
      } else {
      }
    })
    .catch((err) => {
      // const { data } = err.response;
      callback(false, err);
      dispatch({ type: "" });
    });
};

const deleteRoles = (cred, callback) => (dispatch) => {
  instance
    .delete(urls.roles.roles, { data: cred })
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        callback(true);
        dispatch({ type: "" });
      }
    })
    .catch((err) => {
      console.log(err);
      callback(false);
    });
};

const deleteEmployees = (cred, callback) => (dispatch) => {
  instance
    .delete(urls.employees.employee, { data: cred })
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        callback(true);
        dispatch({ type: "" });
      }
    })
    .catch((err) => {
      console.log(err);
      callback(false);
    });
};
const editRole = (params, callback) => (dispatch) => {
  instance
    .put(urls.roles.roles, params)
    .then((response) => {
      if (response.status === 200) {
        callback(true, response.data);
        dispatch({ type: "" });
      } else {
      }
    })
    .catch((err) => {
      callback(false, err.message);
      dispatch({ type: "" });
    });
};

const saveCustomer = (params, callback) => {
  console.log(params, "klfjdkggg");
  return (dispatch) => {
    instance
      .post(urls.customers.customer, params)
      .then((response) => {
        const { data } = response;
        console.log(response.data, "sjhdkjsadshf");

        callback(true, data);
        dispatch({ type: "" });
      })
      .catch((err) => {
        // const { data } = err.response;
        // console.log(err, 'sjhdkjsadsdsfshf')

        callback(false, err);
        dispatch({ type: "" });
      });
  };
};

// const saveCustomer = (params, objData, callback) => {
//   return (dispatch) => {
//     instance.post(urls.saveCustomer, params).then((response) => {
//       const { data } = response;
//       // console.log(data, 'dataaas')
//       objData['customerId'] = data.customer_id
//       dispatch({ type: types.SEARCH_DATA, payload: objData })
//       dispatch(getSearchData());
//       callback(true, data)
//       dispatch({ type: '' })
//     }).catch(err => {
//       // console.log(err, 'errojksdhfksjkdhkjr')
//       callback(false, err)
//       dispatch({ type: '' })
//     })
//   }
// }

const editCustomer = (params, callback) => (dispatch) => {
  instance
    .put(urls.customers.customer, params)
    .then((response) => {
      const { data } = response;
      callback(true, data);
      dispatch({ type: "" });
    })
    .catch((err) => {
      // const { data } = err.response;
      callback(false, {});
      dispatch({ type: "" });
    });
};

const deleteCustomer = (cred, callback) => (dispatch) => {
  instance
    .delete(urls.customers.customer, { data: cred })
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        callback(true);
        dispatch({ type: "" });
      }
    })
    .catch((err) => {
      console.log(err);
      callback(false);
    });
};

const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);
const saveEmployee = (params, callback) => (dispatch) => {
  instance
    .post(urls.employees.employee, params)
    .then((response) => {
      const { data } = response;
      callback(true, data);
      dispatch({ type: "" });
    })
    .catch((err) => {
      // const { data } = err.response;
      callback(false, { message: capitalizeFirstLetter(err.message) });
      dispatch({ type: "" });
    });
};
const resetEmail = (params, callback) => (dispatch) => {
  instance
    .post(urls.employees.resetemail, params)
    .then((response) => {
      const { data } = response;
      callback(true, data);
      dispatch({ type: "" });
    })
    .catch((err) => {
      const { data } = err.response;
      callback(false, data);
      dispatch({ type: "" });
    });
};
const editEmploye = (params, callback) => (dispatch) => {
  instance
    .put(urls.employees.employee, params)
    .then((response) => {
      const { data } = response;
      callback(true, data);
      dispatch({ type: "" });
    })
    .catch((err) => {
      // const { data } = err.response;
      callback(false, { message: capitalizeFirstLetter(err.message) });
      dispatch({ type: "" });
    });
};

const fetchCustomerCheck = (cred, callback) => (dispatch) => {
  instance
    .put(urls.customers.emailCheck, cred)
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        callback(true, response.data);
      }
    })
    .catch((err) => {
      console.log(" data login", err);
      const { data } = err.response;
      callback(false, data);
      dispatch({ type: "" });
    });
};

const fetchEmployeeCheck = (cred, callback) => (dispatch) => {
  instance
    .put(urls.employees.emailCheck, cred)
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        callback(true, response.data);
      }
    })
    .catch((err) => {
      console.log(" data login", err);
      const { data } = err.response;
      callback(false, data);
      dispatch({ type: "" });
    });
};

const fetchRoleNameCheck = (cred, callback) => (dispatch) => {
  instance
    .put(urls.roles.emailCheck, cred)
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        callback(true, response.data);
      }
    })
    .catch((err) => {
      console.log(" data login", err);
      const { data } = err.response;
      callback(false, data);
      dispatch({ type: "" });
    });
};

const uploadCustomersToServer = (params, callback) => (dispatch) => {
  instance
    .post(urls.customers.importCustomer, params)
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        if (callback) {
          callback(true);
        }
      }
    })
    .catch((e) => {
      callback(false);
    });
};

export {
  fetchRoles,
  fetchEmployees,
  fetchAllEmployees,
  fetchCustomers,
  fetchAllCustomers,
  fetchRolePermissions,
  addRole,
  editRole,
  saveCustomer,
  editCustomer,
  deleteCustomer,
  fetchCustomer,
  fetchRoleDetails,
  saveEmployee,
  editEmploye,
  deleteRoles,
  deleteEmployees,
  fetchEmployee,
  resetEmail,
  fetchCustomerCheck,
  fetchEmployeeCheck,
  fetchRoleNameCheck,
  uploadCustomersToServer,
};
