import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import Daterange from "../../components/DateRange";
import MultiSelect from "../../components/MultiSelect";
import { fetchTransactions } from "../../redux/actions";
import { AvenirBlackH4 } from "../../utils/text";
import MyContext from "../../Provider/MyContext";
import CircleClose from "../../components/SVG/CircleClose";
import DownlodeIcon from "../../components/SVG/DownlodeIcon";
import RefreshIcon from "../../components/SVG/RefreshIcon";
import TopSearchBar from "../../components/SearchBar";
import EmployeeDropDown from "../../components/EmployeeDropDown";

const Controls = ({
  showPayments = false,
  showVendors = false,
  showOrderTypes = false,
  showEmployeeFilter = false,
  showTransactions = false,
  type = "all",
  showBtns = true,
  onChange,
  onDownload,
  isTransaction = false,
  handleText,
  tz,
  limit,
  offsetValue,
  filterCode,
  orderBy = null,
  sortBy = null,
}) => {
  console.log("OFFSET VALUE", offsetValue, showEmployeeFilter);
  const Context = useContext(MyContext);
  // const transactionTypes = [
  //   { name: Context.langData.all, value: "all" },
  //   { name: Context.langData.seat, value: "seat" },
  //   { name: Context.langData.even, value: "even" }
  // ];
  const [date, setDate] = useState({});
  // const [selectedTransactionTypes, setSelectedTransactionTypes] = useState([]);
  const [selectedPaymentTypes, setSelectedPaymentTypes] = useState([]);
  const [selectedOrderTypes, setSelectedOrderTypes] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [resetDate, toggleResetDate] = useState(false);
  const { paymentTypes, ordertypes } = useSelector(
    (state) => state.metaReducer
  );
  const vendors = useSelector((state) => state.inventory.vendors);
  const dispatch = useDispatch();
  const [serchText, setSerchText] = useState("");
  const transactions = useSelector((state) => state.transactions.transactions);
  const [empData, setEmpData] = useState([]);

  const onDateChange = (d) => {
    if (JSON.stringify(date) !== JSON.stringify(d)) {
      console.log("????", d);
      setDate(d);
    }
  };
  useEffect(() => {
    if (type == "online") {
      onChange(selectedOrderTypes);
    }
  }, [selectedOrderTypes]);

  const clear = () => {
    // setSelectedTransactionTypes([]);
    setSelectedPaymentTypes([]);
    setSelectedOrderTypes([]);
    setSelectedVendors([]);
    setEmpData([]);
    setSerchText("");
    toggleResetDate(true);
    setTimeout(() => {
      toggleResetDate(false);
    }, 200);
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleApply();
    }, 100);
    return () => clearTimeout(timeout);
  }, [
    type,
    date,
    selectedOrderTypes,
    selectedVendors,
    selectedPaymentTypes,
    offsetValue,
    empData,
    filterCode,
    sortBy,
    orderBy,
  ]);
  const handleApply = () => {
    const obj = {
      from_date: date.startDate,
      to_date: date.endDate,
      limit,
      offset: offsetValue,
      hawkeye_type: filterCode == null ? null : filterCode + 1,
    };
    // if (showTransactions) {
    //   if (selectedTransactionTypes.length > 0) {
    //     obj["bill_types"] = selectedTransactionTypes.join(",")
    //   }
    // }
    if (showPayments) {
      if (selectedPaymentTypes.length > 0) {
        obj.payment_types = selectedPaymentTypes.join(",");
      }
    }
    if (showVendors) {
      if (selectedVendors.length > 0) {
        obj.vendor_id_list = selectedVendors.join(",");
      }
    }
    if (showOrderTypes) {
      if (selectedOrderTypes.length > 0) {
        obj.order_types = selectedOrderTypes.join(",");
      }
    }
    if (showEmployeeFilter) {
      if (empData.length > 0) {
        obj.employee_id = empData.join(",");
      }
    }
    if (orderBy && sortBy) {
      obj.sortBy = sortBy;
      obj.orderBy = orderBy;
    }
    if (type == "all") {
      dispatch(fetchTransactions(obj));
    }
  };
  const downloadExcel = () => {
    console.log("you clicked download on", type);
    onDownload();
  };
  const handleSearchText = (e) => {
    setSerchText(e);
    handleText(e);
  };
  // let transactionNames = []
  // transactions.transactions.map((i) => {
  //   transactionNames.push(i.otype_name).toString()
  // })
  // const uniq = [...new Set(transactionNames)];
  // console.log("timez__one", transactionNames)
  return (
    <Grid
      data-qaid='controls_maingrid'
      container
      spacing={1}
      style={{ display: "flex", marginTop: 10 }}>
      <Grid data-qaid='controls_child_grid' container xs={12} md={12} lg={12}>
        <Grid
          data-qaid='controls_content_grid'
          item
          lg={10}
          style={{
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
          }}>
          <Grid
            data-qaid='controls_dategrid'
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: 24,
              marginLeft: 32,
            }}>
            <AvenirBlackH4
              data-qaid='controls_date_label'
              label={Context.langData.from_to}
            />
            <Daterange
              data-qaid='controls_daterange'
              timezone={tz}
              onDateChange={onDateChange}
              style={{ display: "flex", height: 56 }}
              refresh={resetDate}
            />
          </Grid>
          {showOrderTypes && (
            <Grid
              data-qaid='controls_ordertype_grid'
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 14,
              }}>
              <AvenirBlackH4
                data-qaid='controls_ordertype_label'
                label={Context.langData.order_type}
              />
              <MultiSelect
                data-qaid='controls_ordertypemultiselcet'
                list={ordertypes}
                value='otype_id'
                name='otype_name'
                placeholder={Context.langData.all_order_types}
                selectedValue={selectedOrderTypes}
                handleChange={(e) => setSelectedOrderTypes(e)}
                border={false}
                height={56}
                width={200}
                FormWidth='100%'
              />
            </Grid>
          )}
          {showEmployeeFilter && (
            <EmployeeDropDown
              selectedValue={empData}
              handleChange={setEmpData}
              lg={4}
            />
          )}
          {showPayments && (
            <Grid
              data-qaid='controls_payment_grid'
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 24,
              }}>
              <AvenirBlackH4
                data-qaid='controls_payment_label'
                label={Context.langData.payment_type}
              />
              <MultiSelect
                data-qaid='controls_payment_multiselect'
                list={paymentTypes}
                value='ptype_id'
                name='name'
                placeholder={Context.langData.all_payment_types}
                selectedValue={selectedPaymentTypes}
                handleChange={(e) => setSelectedPaymentTypes(e)}
                border={false}
                height={56}
                width={246}
                FormWidth='100%'
              />
            </Grid>
          )}
          {showVendors && (
            <Grid
              data-qaid='controls_vendor_grid'
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 24,
              }}>
              <AvenirBlackH4
                data-qaid='controls_vendor_label'
                label={Context.langData.vendors}
              />
              <MultiSelect
                data-qaid='controls_vendor_multiselect'
                list={vendors}
                value='vendor_id'
                name='name'
                placeholder={Context.langData.all_vendors}
                selectedValue={selectedVendors}
                handleChange={(e) => {
                  console.log(e);
                  setSelectedVendors(e);
                }}
                border={false}
                isVendor
                height={56}
                width={246}
                FormWidth='100%'
              />
            </Grid>
          )}
          {isTransaction && (
            <Grid
              data-qaid='contactTracing_topSearchBarGrid'
              item
              lg={4}
              style={{ marginTop: 25, paddingRight: 10 }}>
              <TopSearchBar
                value={serchText}
                onChange={(e) =>
                  handleSearchText(e.target.value.replace(/[^0-9]/g, ""))
                }
                placeholder={Context.langData.search_by_order_no}
                style={{ height: 58 }}
                width='100%'
              />
            </Grid>
          )}
        </Grid>
        {!isTransaction ? (
          <Grid
            data-qaid='salesSummary_clearContainer'
            item
            lg={2}
            style={{
              flexDirection: "row",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
            }}>
            <Grid
              data-qaid='salesSummary_clearGrid'
              onClick={() => clear()}
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: 20,
                alignItems: "center",
                cursor: "pointer",
              }}>
              <CircleClose data-qaid='salesSummary_circleCloseIcon' />
              <AvenirBlackH4
                data-qaid='salesSummary_clearLabel'
                label={Context.langData.clear}
                style={{ marginLeft: 5, color: "#004C60" }}
              />
            </Grid>
            <Grid container>
              <Button
                onClick={downloadExcel}
                data-qaid='salesSummary_downlodeButtonGrid'
                style={{
                  backgroundColor: "#FFB600",
                  height: 40,
                  marginRight: 0,
                  width: "149px",
                }}
                variant='contained'
                id='cancel'>
                <DownlodeIcon
                  data-qaid='salesSummary_downlodeIcon'
                  style={{ marginRight: 10 }}
                />
                <AvenirBlackH4
                  data-qaid='salesSummary_downlodelabel'
                  label={Context.langData.download}
                />
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid
            data-qaid='contactTracing_resetFiltersLabelView'
            item
            lg={2}
            onClick={() => clear()}
            style={{
              flexDirection: "row",
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
              justifyContent: "flex-end",
              marginLeft: 0,
              paddingRight: 50,
              paddingTop: 20,
            }}>
            <RefreshIcon />
            <AvenirBlackH4
              data-qaid='contactTracing_resetFiltersLabel'
              label={Context.langData.reset_filters}
              style={{ marginLeft: 0, marginRight: 10, color: "#004C60" }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Controls;
