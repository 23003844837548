import instance, { inventory_instance } from "./axiosInstance";

import urls, { insertId } from "./urls";

const fetchAllStocks = () => (dispatch) => {
  inventory_instance.get(urls.inventory.stocks).then((response) => {
    if (response.status === 200 || response.status === 304)
      dispatch({ type: "SET_STOCKS", payload: response.data });
  });
};

const fetchAllVendors = () => (dispatch) => {
  inventory_instance.get(urls.inventory.vendors).then((response) => {
    if (response.status === 200 || response.status === 304)
      dispatch({ type: "SET_VENDORS", payload: response.data });
  });
};

const fetchDepartments = (params) => (dispatch) => {
  inventory_instance
    .get(urls.inventory.departments, { params })
    .then((response) => {
      console.log(response);
      if (response.status === 200 || response.status === 304) {
        dispatch({ type: "SET_KITCHEN", payload: response.data });
      }
    });
};

const saveVendor = (params, callback) => {
  console.log("save vendor", params);
  return (dispatch) => {
    inventory_instance
      .post(urls.inventory.addVendor, params)
      .then((response) => {
        callback(true);
      })
      .catch((err) => {
        callback(false);
      });
  };
};

const assignStocksToVendor = (vendorId, params, callback) => (dispatch) => {
  inventory_instance
    .put(insertId(urls.inventory.assign_stocks_to_vendor, vendorId), params)
    .then((response) => {
      callback(true);
    })
    .catch((err) => {
      callback(false);
    });
};
const saveEditVendor = (venderId, params, callback) => (dispatch) => {
  inventory_instance
    .put(insertId(urls.inventory.vendorProfile, venderId), params)
    .then((response) => {
      callback(true);
    })
    .catch((err) => {
      callback(false);
    });
};

const deleteVendors = (id, callback) => (dispatch) => {
  inventory_instance
    .delete(insertId(urls.inventory.vendor, id))
    .then((response) => {
      console.log(response);
      if (response.status === 200 || response.status === 204) {
        callback(true);
      }
    })
    .catch((err) => {
      callback(false);
      console.log(err);
    });
};

const fetchAllRecipeItems = () => (dispatch) => {
  instance.get(urls.inventory.recipeItems).then((response) => {
    if (response.status === 200 || response.status === 304)
      dispatch({ type: "SET_RECIPE_ITEMS", payload: response.data });
  });
};

const fetchAllRecipeItemDetails = (params) => (dispatch) => {
  inventory_instance
    .get(urls.inventory.getRecipeUrl, { params })
    .then((response) => {
      if (response.status === 200 || response.status === 304)
        dispatch({
          type: "SET_RECIPE_ITEMS_DETAIL",
          payload: response.data.data,
        });
    });
};

const fetchAllRecipeAddonDetails = (params) => (dispatch) => {
  inventory_instance
    .get(urls.inventory.getRecipeUrl, { params })
    .then((response) => {
      if (response.status === 200 || response.status === 304)
        dispatch({
          type: "SET_RECIPE_ADDONS_DETAIL",
          payload: response.data.data,
        });
    });
};

const fetchRecipeByRecipeId = (params) => (dispatch) => {
  inventory_instance
    .get(urls.inventory.getRecipeUrl, { params })
    .then((response) => {
      if (response.status === 200 || response.status === 304) {
        dispatch({ type: "SET_RECIPE_VIEW", payload: response.data.data });
      }
    });
};

const fetchAllRecipeAddons = () => (dispatch) => {
  instance.get(urls.menu.recipeAddons).then((response) => {
    if (response.status === 200 || response.status === 304)
      dispatch({ type: "SET_RECIPE_ADDONS", payload: response.data });
  });
};

const deletePurchaseOrder = (ids, callback) => (dispatch) => {
  inventory_instance
    .post(urls.inventory.indentBatchDelete, ids)
    .then((response) => {
      console.log(response);
      if (response.status === 200 || response.status === 204) {
        callback(true);
      }
    })
    .catch((err) => {
      callback(false);
      console.log(err);
    });
};

const fetchRecipeByItemId = (params, callback) => (dispatch) => {
  instance.get(urls.inventory.recipeUrl, { params }).then((response) => {
    if (response.status === 200 || response.status === 304) {
      callback(response.data);
    }
  });
};
const saveRecipe = (params, callback) => {
  console.log("save recipe", params);
  return (dispatch) => {
    instance
      .post(urls.inventory.recipeUrl, params)
      .then((response) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 207
        ) {
          const itemdata = [];
          params.recipes.map((item, inx) => {
            const itm = { id: 0 };
            const index = response.data.recipes.findIndex(
              (e) => e.serving_id == item.serving_id
            );
            if (index != -1) {
              itm.extProductId =
                response.data.recipes[index].recipe_id.toString();
              itm.items = response.data.recipes[index].recipe;
              itm.name = `${params.name} - ${item.name}`;
              itemdata.push(itm);
            }
          });
          const iparams = {
            data: itemdata,
          };
          inventory_instance
            .post(urls.inventory.batchProductItemGroup, iparams)
            .then((response) => {
              const { data } = response;
              callback(true, data);
              dispatch({ type: "" });
            })
            .catch((err) => {
              const data = err.status;
              callback(false, data);
              dispatch({ type: "" });
            });
        }
      })
      .catch((err) => {
        const data = err.status;
        callback(false, data);
        dispatch({ type: "" });
      });
  };
};
const uploadStockItems = (params, callback) => (dispatch) => {
  instance
    .post(urls.inventory.upload_stock, params)
    .then((response) => {
      const { data } = response;
      callback(true);
    })
    .catch((err) => {
      callback(false);
    });
};
const uploadItemRecipes = (params, callback) => (dispatch) => {
  instance
    .post(urls.inventory.uploadItemsRecipesURL, params)
    .then((response) => {
      const { data } = response;
      callback(true);
    })
    .catch((err) => {
      callback(false);
    });
};
const uploadAddonRecipes = (params, callback) => (dispatch) => {
  instance
    .post(urls.inventory.uploadAddonsRecipesURL, params)
    .then((response) => {
      const { data } = response;
      callback(true);
    })
    .catch((err) => {
      callback(false);
    });
};
const saveAddonRecipe = (params, callback) => {
  console.log("save vendor", params);
  return (dispatch) => {
    instance
      .post(urls.menu.recipeAddons, params)
      .then((response) => {
        const { data } = response;
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 207
        ) {
          const itemdata = [];
          const itm = { id: 0 };
          itm.extProductId = response.data.recipe_id.toString();
          itm.items = params.recipe;
          itm.name = params.addon_name;
          itemdata.push(itm);

          const iparams = {
            data: itemdata,
          };
          inventory_instance
            .post(urls.inventory.batchProductItemGroup, iparams)
            .then((response) => {
              const { data } = response;
              callback(true, data);
              dispatch({ type: "" });
            });
        }
      })
      .catch((err) => {
        const data = err.status;
        callback(false, data);
        dispatch({ type: "" });
      });
  };
};
const fetchAllCostingVendors = (params) => (dispatch) => {
  instance.get(urls.inventory.costingVendors, { params }).then((response) => {
    if (response.status === 200 || response.status === 304)
      dispatch({ type: "SET_COSTING_VENDORS", payload: response.data });
  });
};

const fetchAllCostingKitchens = (params) => (dispatch) => {
  instance.get(urls.inventory.costingKitchens, { params }).then((response) => {
    if (response.status === 200 || response.status === 304)
      dispatch({ type: "SET_COSTING_KITCHENS", payload: response.data });
  });
};

const fetchAllEditVendors = (id, callback) => (dispatch) => {
  inventory_instance
    .get(insertId(urls.inventory.vendor, id))
    .then((response) => {
      if (response.status === 200)
        if (callback) {
          callback(response.data);
        }
    });
};

const fetchAllKitchenStocks = (departmentId) => (dispatch) => {
  inventory_instance
    .get(insertId(urls.inventory.kitchenStock, departmentId))
    .then((response) => {
      if (response.status === 200 || response.status === 304) {
        inventory_instance
          .get(insertId(urls.inventory.kitchenValue, departmentId))
          .then((responseValue) => {
            if (responseValue.status === 200 || responseValue.status === 304) {
              const payloaddata = {
                value: responseValue.data,
                stocks: response.data.data,
              };
              dispatch({ type: "SET_KITCHEN_STOCKS", payload: payloaddata });
            }
          });
      }
    });
};

const fetchKitchenValue = (departmentId) => (dispatch) => {
  inventory_instance
    .get(insertId(urls.inventory.kitchenValue, departmentId))
    .then((response) => {
      if (response.status === 200 || response.status === 304)
        dispatch({ type: "SET_KITCHEN_VALUE", payload: response.data });
    });
};

const updateKitchenStocks = (params, departmentId, callback) => (dispatch) => {
  inventory_instance
    .post(
      insertId(urls.inventory.kitchenStockAdjustments, departmentId),
      params
    )
    .then((response) => {
      const { data } = response;
      callback(true, data);
      dispatch({ type: "" });
    })
    .catch((err) => {
      const data = err.status;
      callback(false, data);
      dispatch({ type: "" });
    });
};

const kitchenLiveConsumption = (params, callback) => (dispatch) => {
  instance
    .get(urls.inventory.liveConsumption, { params })
    .then((response) => {
      if (response.status === 200 || response.status === 304) callback(true);
    })
    .catch((err) => {
      callback(false);
      console.log(err);
    });
};
const fetchAllGroupsData = () => (dispatch) => {
  inventory_instance.get(urls.inventory.groupsData).then((response) => {
    if (response.status === 200 || response.status === 304)
      dispatch({ type: "SET_GROUPS_DATA", payload: response.data.data });
  });
};
const createGroups = (params, callback) => (dispatch) => {
  inventory_instance
    .post(urls.inventory.groupsData, params)
    .then((response) => {
      const { data } = response;
      callback(true, data);
      dispatch({ type: "" });
    })
    .catch((err) => {
      const data = err.status;
      callback(false, data);
      dispatch({ type: "" });
    });
};
const editGroups = (id, params, callback) => (dispatch) => {
  inventory_instance
    .put(insertId(urls.inventory.groupData, id), params)
    .then((response) => {
      const { data } = response;
      callback(true, data);
      dispatch({ type: "" });
    })
    .catch((err) => {
      const data = err.status;
      callback(false, data);
      dispatch({ type: "" });
    });
};
const deleteGroups = (id, callback) => (dispatch) => {
  inventory_instance
    .delete(insertId(urls.inventory.groupData, id))
    .then((response) => {
      console.log(response);
      if (response.status === 200 || response.status === 204) {
        callback(true);
      }
    })
    .catch((err) => {
      callback(false);
      console.log(err);
    });
};

const deleteStockBalance = (ids, callback) => (dispatch) => {
  inventory_instance
    .post(urls.inventory.batchDeleteStock, ids)
    .then((response) => {
      console.log(response);
      if (response.status === 200 || response.status === 204) {
        callback(true);
      }
    })
    .catch((err) => {
      callback(false);
    });
};

const fetchAllIssues = (params) => (dispatch) => {
  inventory_instance.get(urls.inventory.issues, { params }).then((response) => {
    if (response.status === 200 || response.status === 304)
      dispatch({ type: "SET_ISSUES", payload: response.data });
  });
};

const fetchAllIndents = (params) => (dispatch) => {
  inventory_instance.get(urls.inventory.indent, { params }).then((response) => {
    if (response.status === 200 || response.status === 304)
      dispatch({ type: "SET_INDENT", payload: response.data });
  });
};

const fetchAllIssuesView = (id, callback) => (dispatch) => {
  inventory_instance
    .get(insertId(urls.inventory.issueView, id))
    .then((response) => {
      if (response.status === 200 || response.status === 304)
        dispatch({ type: "SET_ISSUES_VIEW", payload: response.data });
      callback(response.data);
    });
};
const saveIssue = (params, callback) => (dispatch) => {
  inventory_instance
    .post(urls.inventory.issues, params)
    .then((response) => {
      const { data } = response;
      callback(true);
      dispatch({ type: "" });
    })
    .catch((err) => {
      // const { data } = err.response;
      callback(false);
      dispatch({ type: "" });
    });
};
const undoIssue = (id, callback) => (dispatch) => {
  inventory_instance
    .delete(insertId(urls.inventory.issueView, id))
    .then((response) => {
      if (response.status === 200 || response.status === 204) {
        callback(true);
      }
    })
    .catch((err) => {
      callback(false);
    });
};
const fetchAllIndentView = (params, callback) => (dispatch) => {
  instance.get(urls.inventory.indentView, { params }).then((response) => {
    if (response.status === 200 || response.status === 304)
      dispatch({ type: "SET_INDENT_VIEW", payload: response.data });
    // callback(response.data);
  });
};
const fetchSpecificIndent = (poid) => (dispatch) => {
  inventory_instance
    .get(insertId(urls.inventory.specificIndent, poid))
    .then((response) => {
      if (response.status === 200 || response.status === 304)
        dispatch({ type: "SET_INDENT_VIEW", payload: response.data });
    });
};
const updateIndentStatus = (poid, params, callback) => (dispatch) => {
  inventory_instance
    .put(insertId(urls.inventory.indentStatus, poid), params)
    .then((response) => {
      if (response.status === 204) callback(true);
    })
    .catch((err) => {
      callback(false);
    });
};
const saveAmountPaid = (poid, fid, params, callback) => (dispatch) => {
  inventory_instance
    .put(
      insertId(urls.inventory.updateFulfillmentPaidAmount, poid, fid),
      params
    )
    .then((response) => {
      if (response.status === 204) callback(true);
      dispatch({ type: "" });
    })
    .catch((err) => {
      callback(false);
      dispatch({ type: "" });
    });
};

const deleteFulfillment = (poid, fid, callback) => (dispatch) => {
  inventory_instance
    .delete(insertId(urls.inventory.specificFulfillment, poid, fid))
    .then((response) => {
      if (response.status === 204) callback(true);
    })
    .catch((err) => {
      callback(false);
    });
};
const saveRaisedIndent = (params, callback) => (dispatch) => {
  inventory_instance
    .post(urls.inventory.indentView, params)
    .then((response) => {
      const { data } = response;
      callback(true);
      dispatch({ type: "" });
    })
    .catch((err) => {
      callback(false);
      dispatch({ type: "" });
    });
};
const saveIndentData = (params, callback) => (dispatch) => {
  instance
    .put(urls.inventory.indentView, params)
    .then((response) => {
      const { data } = response;
      callback(true);
      dispatch({ type: "" });
    })
    .catch((err) => {
      callback(false);
      dispatch({ type: "" });
    });
};

const addFulfillment = (poId, params, callback) => (dispatch) => {
  inventory_instance
    .post(insertId(urls.inventory.fulfillment, poId), params)
    .then((response) => {
      const { data } = response;
      callback(true);
      dispatch({ type: "" });
    })
    .catch((err) => {
      callback(false);
      dispatch({ type: "" });
    });
};
const saveStock = (params, callback) => (dispatch) => {
  inventory_instance
    .post(urls.inventory.stocks, params)
    .then((response) => {
      callback(true);
    })
    .catch((err) => {
      callback(false);
    });
};
const editStock = (id, params, callback) => (dispatch) => {
  inventory_instance
    .put(insertId(urls.inventory.stockItem, id), params)
    .then((response) => {
      callback(true);
    })
    .catch((err) => {
      callback(false);
    });
};
const fetchStockDetails = (id, callback) => (dispatch) => {
  inventory_instance
    .get(insertId(urls.inventory.stockItem, id))
    .then((response) => {
      if (response.status === 200 || response.status === 304)
        callback(response.data);
    });
};
const uploadIssues = (params, callback) => (dispatch) => {
  instance
    .put(urls.inventory.issueUpload, params)
    .then((response) => {
      const { data } = response;
      callback(true);
    })
    .catch((err) => {
      callback(false);
    });
};
const uploadPurchaseOrders = (params, callback) => (dispatch) => {
  instance
    .post(urls.inventory.indentUpload, params)
    .then((response) => {
      const { data } = response;
      callback(true);
    })
    .catch((err) => {
      callback(false);
    });
};

export {
  fetchAllStocks,
  fetchDepartments,
  fetchAllVendors,
  saveVendor,
  fetchAllRecipeItems,
  fetchAllRecipeAddons,
  fetchAllRecipeItemDetails,
  fetchAllRecipeAddonDetails,
  deleteVendors,
  fetchAllCostingVendors,
  fetchAllCostingKitchens,
  fetchAllEditVendors,
  saveEditVendor,
  fetchAllKitchenStocks,
  fetchKitchenValue,
  fetchAllGroupsData,
  deleteGroups,
  deleteStockBalance,
  fetchAllIssues,
  fetchAllIndents,
  fetchAllIssuesView,
  fetchAllIndentView,
  saveRaisedIndent,
  addFulfillment,
  saveStock,
  createGroups,
  editGroups,
  editStock,
  fetchStockDetails,
  saveIndentData,
  saveIssue,
  fetchRecipeByItemId,
  saveRecipe,
  saveAddonRecipe,
  updateKitchenStocks,
  kitchenLiveConsumption,
  assignStocksToVendor,
  undoIssue,
  deletePurchaseOrder,
  uploadStockItems,
  uploadAddonRecipes,
  uploadItemRecipes,
  uploadIssues,
  uploadPurchaseOrders,
  fetchRecipeByRecipeId,
  fetchSpecificIndent,
  saveAmountPaid,
  deleteFulfillment,
  updateIndentStatus,
};
