import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";

const IsAuthenticatedContext = createContext();
export const IsAuthenticatedProvider = ({ children }) => {
  const loginDetails = useSelector((state) => state.loginReducer.loginDetails);
  const [isAuthenticatedFlag, setIsAuthenticatedFlag] = useState(
    loginDetails.oauth_token
  );

  useEffect(() => {
    setIsAuthenticatedFlag(loginDetails.oauth_token);
    console.log({ isAuthenticatedFlag });
  }, [loginDetails.oauth_token]);

  const contextValue = useMemo(
    () => ({ isAuthenticatedFlag, setIsAuthenticatedFlag }),
    [isAuthenticatedFlag, setIsAuthenticatedFlag]
  );
  return (
    <IsAuthenticatedContext.Provider value={contextValue}>
      {children}
    </IsAuthenticatedContext.Provider>
  );
};
export function useIsAuthenticated() {
  const context = useContext(IsAuthenticatedContext);
  if (!context) {
    throw new Error("Must use inside IsAuthenticatedProvider");
  }
  return context;
}
