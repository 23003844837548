import moment from "moment";

const initialState = {
  roles: { total: 0, roles: [] },
  roleOffset: 0,
  rolesPages: 1,
  empOffset: 0,
  empPages: 1,
  limit: 25,
  employeesList: {
    total: 0,
    data: [],
  },
  customersList: {},
  permissions: [],
  rolePermissions: [],
  customersOffset: 0,
};

function parseEmployeeRoles(data) {
  if (data.roles && data.roles.length > 0) {
    data.roles.map((item) => {
      console.log("itemitem", item);
      item.id = item.role_id;
      if (item.role_application) {
        const roleApplications = item.role_application.split(",");
        roleApplications.sort((a, b) => a.localeCompare(b));
        item.application = roleApplications.join(", ");
      } else {
        item.application = "-";
      }
      item.full_name = item.name;
    });
  }
  return data;
}
function parseEmployess(r, roles) {
  if (typeof r.employees !== "undefined") {
    const obj = {
      total: r.total,
      data: r.employees,
    };
    if (obj.data && obj.data.length > 0) {
      obj.data.map((d) => {
        d.id = d.employee_id;
        d.username = d.username || "-";
        d.email = d.email || "-";
        d.status = d.status == 1 ? "Active" : "Inactive";
        let rs = [];
        if (roles && roles.roles && roles.roles.length > 0) {
          rs = roles.roles.filter((role) => role.role_id == d.role_id);
        }
        if (rs.length > 0) {
          d.role = rs[0].name;
          d.role_link = rs[0].name;
        } else {
          d.role = "";
          d.role_link = "";
        }
      });
    }
    return obj;
  }
  return {
    total: 0,
    data: [],
  };
}
function parseCustomers(customers) {
  if (typeof customers.customers !== "undefined") {
    const obj = {
      total: customers.total,
      data: customers.customers,
    };
    obj.data.map((item) => {
      item.ts = item.registered_on;
      item.registered_on = moment(item.ts * 1000).format("MM/DD/YYYY");
      item.id = item.customer_id;
      if (!item.email) {
        item.email = "-";
      }
      if (!item.phone_number) {
        item.phone_number = "-";
      } else {
        const x = item.phone_number;
        item.phone_number = `${x.substring(0, 1)}-${x.substring(
          1,
          4
        )}-${x.substring(4, 7)}-${x.substring(7)}`;
      }
    });
    return obj;
  }
  return {
    total: 0,
    data: [],
  };
}
function profileReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_ROLE_SUCCESS":
      return {
        ...state,
        roles: parseEmployeeRoles(action.payload),
      };
    case "GET_EMPLOYEES_SUCCESS":
      return {
        ...state,
        employeesList: parseEmployess(action.payload, state.roles),
      };
    case "GET_CUSTOMERS_SUCCESS":
      return {
        ...state,
        customersList: parseCustomers(action.payload),
      };
    case "GET_ROLE_PERMISSIONS_SUCCESS":
      return {
        ...state,
        permissions: action.payload,
      };
    case "ADD_ROLE_SUCCESS":
      return {
        ...state,
      };
    case "EDIT_ROLE_SUCCESS":
      return {
        ...state,
      };
    case "SET_ROLE_PERMISSIONS":
      return {
        ...state,
        rolePermissions: action.payload,
      };
    case "SET_CUSTOMERS_OFFSET":
      return {
        ...state,
        customersOffset: action.payload,
      };
    case "CLEAR_PROFILE_DATA":
      return {
        ...state,
        employeesList: {
          total: 0,
          data: [],
        },
        customersList: {},
        rolePermissions: [],
      };
    case "CLEAR":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
export default profileReducer;
