import React, { useEffect, useState, useContext } from "react";
import { Grid, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  AssignAddonToItems,
  fetchAddonCategories,
} from "../../../redux/actions";
import MyContext from "../../../Provider/MyContext";
import CircleClose from "../../../components/SVG/CircleClose";
import { AvenirRedH4, AvenirBlackH4, H4, H6 } from "../../../utils/text";
import styles from "../../../styles/index";
import SingleSelect from "../../../components/SingleSelect";
import CenterModal from "../../../components/CenterModal";

const ItemAssignAddons = ({ open, onClose, items, callback }) => {
  const style = styles();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.menu.addonCategories);
  const Context = useContext(MyContext);
  const [newCategory, setNewCategory] = useState(1);
  const [newCategoryName, setNewCategoryName] = useState([]);
  const [errorFlag, setError] = useState(null);

  useEffect(() => {
    dispatch(fetchAddonCategories());
    setNewCategory(0);
  }, []);

  const handleAassign = () => {
    if (newCategory) {
    } else {
      setError(Context.langData.no_data);
      return;
    }

    const reqItems = [];

    items.map((p) => {
      const obj = {};
      obj.id = p.item_id;
      obj.name = p.name;
      reqItems.push(obj);
    });

    const reqAddon = [{ addon_cat_id: newCategory }];
    const request = {
      modifiers: reqAddon,
      items: reqItems,
    };

    dispatch(AssignAddonToItems(request, callback));
  };

  const handleCategoryChange = (value) => {
    setError("");
    setNewCategory(value);
    const cat = categories.filter((e) => e.cat_id == value);
    if (cat.length > 0) setNewCategoryName(cat[0].name);
    else setNewCategoryName("");
  };

  return (
    <CenterModal open={open} padding={1}>
      <>
        <Grid
          data-qaid='assignCategories__body_grid'
          style={{
            display: "flex",
            height: 400,
            flexDirection: "column",
            width: 592,
          }}>
          <Grid
            data-qaid='assignCategories__child1_grid'
            style={{
              display: "flex",
              height: "85%",
              flexDirection: "column",
              overflowX: "auto",
            }}>
            <Grid
              data-qaid='assignCategories__close_grid'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}>
              <CircleClose
                data-qaid='assignCategories__closeicon'
                size={20}
                onClick={onClose}
                style={{ marginRight: 36, marginTop: 20 }}
              />
            </Grid>
            <H6
              data-qaid='assignCategories__title'
              style={{ marginLeft: 36, marginBottom: 20, marginRight: 36 }}
              label={Context.langData.assign_addon.replace(
                "${itemNum}",
                items.length
              )}
            />
            <H4
              data-qaid='assignCategories__desc'
              style={{ marginLeft: 36, marginBottom: 20, marginRight: 36 }}
              label={Context.langData.assign_addon_desc}
            />
            <Grid
              container
              direction='column'
              style={{
                marginLeft: 36,
                marginBottom: 40,
                marginRight: 36,
                width: "85%",
              }}>
              <AvenirBlackH4
                label={Context.langData.modifier_group}
                style={{ marginBottom: 5 }}
              />
              <SingleSelect
                list={[{ name: "Select one", id: 0 }].concat(categories)}
                valueKey='id'
                displayKey='name'
                value={newCategory}
                handleChange={(e) => handleCategoryChange(e.target.value)}
                border={false}
                height={56}
                FormWidth='100%'
                borderStyle='1px solid #707272'
              />
            </Grid>
          </Grid>
          <Grid
            data-qaid='assignCategories_cancel_main_Grid'
            style={{
              display: "flex",
              height: "15%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid
              data-qaid='assignCategories_cancelGrid'
              item
              style={{
                display: "flex",
                justifyContent: "flex-end",
                backgroundColor: "#FAFAFA",
                width: "100%",
                paddingTop: "25px",
                alignItems: "center",
                paddingBottom: "25px",
                border: "1px solid #C3C3C3",
              }}>
              <AvenirRedH4
                data-qaid='assignCategory_request_errorHeading'
                label={errorFlag}
              />
              <Button
                className={style.buttonClass}
                data-qaid='assignCategories_cancelButton'
                style={{
                  backgroundColor: "#00B6C9",
                  color: "white",
                  marginRight: 10,
                  borderColor: "#707272",
                  marginLeft: 20,
                }}
                variant='contained'
                id='cancel'
                onClick={onClose}>
                <AvenirBlackH4
                  data-qaid='assignCategories_cancelLabel'
                  label={Context.langData.cancel}
                />
              </Button>
              <Button
                className={style.buttonClass}
                data-qaid='assignCategories_saveButton'
                style={{
                  backgroundColor: "#FFB600",
                  color: "white",
                  marginRight: 20,
                }}
                variant='contained'
                id='cancel'
                type='submit'
                onClick={handleAassign}>
                <AvenirBlackH4
                  data-qaid='assignCategories_saveLabel'
                  label={Context.langData.reassign}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    </CenterModal>
  );
};

export default ItemAssignAddons;
