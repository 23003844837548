import * as React from "react";

const RightArrow = (props) => (
  <svg
    width={8}
    height={13}
    viewBox='0 0 8 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path d='M1.41 0L0 1.41 4.58 6 0 10.59 1.41 12l6-6-6-6z' fill='#004C60' />
  </svg>
);

export default RightArrow;
