import * as React from "react";

const LogoutIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.811 1.302a9.5 9.5 0 11-4.73 12.86.75.75 0 111.348-.657 8 8 0 10.137-7.27.75.75 0 11-1.323-.707A9.5 9.5 0 016.81 1.302zm3.284 5.046a.75.75 0 011.06 0l3.125 3.125a.75.75 0 010 1.06l-3.125 3.126a.75.75 0 11-1.06-1.061l1.844-1.845H.625a.75.75 0 010-1.5h11.314L10.095 7.41a.75.75 0 010-1.061z'
      fill='#004C60'
    />
  </svg>
);

export default LogoutIcon;
