import * as React from "react";

const Portrait = ({
  width = 48,
  height = 69,
  color = "transparent",
  ...props
}) => {
  let invert = "#fff";
  if (color == "white") {
    invert = "#000";
  }
  return (
    <svg
      width={width}
      height={height}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <rect
        x={1}
        y={1}
        width={46}
        height={46}
        rx={4}
        fill={color}
        stroke='#fff'
        strokeWidth={2}
      />
      <path
        d='M22.25 12.75h-8.5a1 1 0 0 0-1 1v14.5a1 1 0 0 0 1 1h8.5a1 1 0 0 0 1-1v-14.5a1 1 0 0 0-1-1ZM20.25 35.25a1.5 1.5 0 0 1-1.5-1.5M33.75 35.25a1.5 1.5 0 0 0 1.5-1.5M18.75 29.25v1.5M35.25 29.25v1.5M23.25 35.25h2.25M28.5 35.25h2.25M33.75 24.75a1.5 1.5 0 0 1 1.5 1.5M23.25 24.75h2.25M28.5 24.75h2.25M27.439 13.5l-1.719 1.72a.749.749 0 0 0 0 1.06L27.439 18M30 20.56l1.72 1.72a.75.75 0 0 0 1.06 0l1.72-1.72'
        stroke={invert}
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25.5 15.75h3.75a3 3 0 0 1 3 3v3.75'
        stroke={invert}
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.111 65h.792v-4.032H8.74c1.332 0 2.556-.684 2.556-2.22 0-1.776-1.536-2.244-2.736-2.244H6.111V65Zm.792-7.776h1.752c.912 0 1.848.432 1.848 1.524 0 1.116-.9 1.5-1.8 1.5h-1.8v-3.024Zm5.583 5.004c0 1.692 1.212 2.916 2.916 2.916s2.916-1.224 2.916-2.916-1.212-2.916-2.916-2.916-2.916 1.224-2.916 2.916Zm.72 0c0-1.248.84-2.268 2.196-2.268 1.356 0 2.196 1.02 2.196 2.268 0 1.248-.84 2.268-2.196 2.268-1.356 0-2.196-1.02-2.196-2.268ZM19.923 65h.72v-3.024c0-1.08.564-1.944 1.812-1.944.072 0 .168.012.276.048l.084-.708a1.69 1.69 0 0 0-.42-.06c-.828 0-1.404.468-1.764 1.116-.024-.108-.036-.564-.048-.972h-.72c0 .288.06 1.152.06 1.56V65Zm6.708-5.544h-1.584V57.86h-.72v1.596h-1.2v.648h1.2v3.612c0 .792.396 1.428 1.428 1.428.276 0 .672-.096.936-.192l-.06-.648c-.252.108-.54.192-.816.192-.48 0-.768-.288-.768-.78v-3.612h1.584v-.648ZM27.915 65h.72v-3.024c0-1.08.564-1.944 1.812-1.944.072 0 .168.012.276.048l.084-.708a1.69 1.69 0 0 0-.42-.06c-.828 0-1.404.468-1.764 1.116-.024-.108-.036-.564-.048-.972h-.72c0 .288.06 1.152.06 1.56V65Zm8.304-3.684c0-1.404-.864-2.004-2.208-2.004-.72 0-1.584.276-2.112.78l.42.492a2.504 2.504 0 0 1 1.692-.624c.888 0 1.56.384 1.56 1.344v.336h-.84c-1.092 0-3.072.168-3.072 1.86 0 1.176 1.008 1.644 1.956 1.644.936 0 1.536-.336 1.944-1.044h.024c0 .312.024.624.072.9h.672c-.084-.636-.108-1.248-.108-2.328v-1.356Zm-.648.9v.372c0 .924-.396 1.908-1.932 1.908-.6 0-1.26-.348-1.26-1.032 0-.948 1.2-1.248 2.4-1.248h.792ZM38.254 65h.72v-5.544h-.72V65Zm-.18-7.74c0 .3.24.54.54.54.3 0 .54-.24.54-.54 0-.3-.24-.54-.54-.54-.3 0-.54.24-.54.54Zm5.655 2.196h-1.584V57.86h-.72v1.596h-1.2v.648h1.2v3.612c0 .792.396 1.428 1.428 1.428.276 0 .672-.096.936-.192l-.06-.648c-.252.108-.54.192-.816.192-.48 0-.768-.288-.768-.78v-3.612h1.584v-.648Z'
        fill='#fff'
      />
    </svg>
  );
};

export default Portrait;
