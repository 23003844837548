import React, { useState } from "react";
import { Box, OutlinedInput, IconButton, makeStyles } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import { AvenirBlackH4 } from "../utils/text";

const useOutlinedInputStyles = makeStyles((theme) => ({
  input: {
    padding: "2px",
    textAlign: "center",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none", // Remove border on focus
    },
    "& .MuiInputBase-root:focus": { outline: "none" },
  },
}));

const SpacingInput = (props) => {
  const classes = useOutlinedInputStyles();
  const [count, setCount] = useState(0);

  const { field, form } = props;

  const handleIncrement = () => {
    form.setFieldValue(field.name, Math.min(10, (field.value || 0) + 1));
  };

  const handleDecrement = () => {
    form.setFieldValue(field.name, Math.max(0, (field.value || 0) - 1));
  };

  const handleChange = (event) => {
    form.setFieldValue(field.name, parseInt(event.target.value));
  };

  return (
    <Box pl={1}>
      <AvenirBlackH4 label={props.title} />
      <Box
        style={{
          width: "70%",
          display: "flex",
        }}>
        <IconButton onClick={handleDecrement}>
          <Remove />
        </IconButton>
        <OutlinedInput
          label={props.label}
          type='number'
          name={props.name}
          value={count}
          onChange={handleChange}
          onBlur={props.onBlur}
          variant={props.variant}
          key={props.key}
          InputLabelProps={props.InputLabelProps}
          inputProps={{ min: 0, max: 10 }}
          classes={{
            input: classes.input,
            notchedOutline: classes.notchedOutline,
          }}
          style={{
            width: "100px",
            marginLeft: "5px",
            marginRight: "5px",
            textAlign: "center",
            borderRadius: "5px",
          }}
          disableUnderline
          {...field}
          readOnly
        />
        <IconButton onClick={handleIncrement}>
          <Add />
        </IconButton>
      </Box>
    </Box>
  );
};

export default SpacingInput;
