import {
  startOfWeek,
  startOfMonth,
  endOfWeek,
  endOfMonth,
  isBefore,
  addDays,
  isSameDay,
  isWithinInterval,
  toDate,
  parseISO,
  isValid,
} from "date-fns";

export const identity = (x) => x;

export const chunks = (array, size) =>
  Array.from({ length: Math.ceil(array.length / size) }, (v, i) =>
    array.slice(i * size, i * size + size)
  );

export const combine = (...args) => args.filter(identity).join(" ");

// Date
export const getDaysInMonth = (date) => {
  const startWeek = startOfWeek(startOfMonth(date));
  const endWeek = endOfWeek(endOfMonth(date));
  const days = [];
  for (let curr = startWeek; isBefore(curr, endWeek); ) {
    days.push(curr);
    curr = addDays(curr, 1);
  }
  return days;
};

export const isStartOfRange = ({ startDate }, day) =>
  startDate && isSameDay(day, startDate);

export const isEndOfRange = ({ endDate }, day) =>
  endDate && isSameDay(day, endDate);

export const inDateRange = ({ startDate, endDate }, day) =>
  startDate &&
  endDate &&
  (isWithinInterval(day, {
    start: startDate,
    end: endDate,
  }) ||
    isSameDay(day, startDate) ||
    isSameDay(day, endDate));

export const isRangeSameDay = ({ startDate, endDate }) => {
  if (startDate && endDate) {
    return isSameDay(startDate, endDate);
  }
  return false;
};

// type Falsy = false | null | undefined | 0 | "";

export const parseOptionalDate = (date, defaultValue) => {
  console.log("parseOptionalDate", date);
  if (date instanceof Date) {
    const parsed = toDate(date);
    if (isValid(parsed)) return parsed;
  }

  if (date instanceof String) {
    const parsed = parseISO(date);
    if (isValid(parsed)) return parsed;
  }

  return defaultValue;
};
