import React, { useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Button, Grid } from "@material-ui/core";
import { H6, AvenirBlackH4 } from "../../utils/text";

import MyContext from "../../Provider/MyContext";
import Expenses from "./expenses";
import Categories from "./categories";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const PettyCash = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const userPermissions = useSelector(
    (state) => state.loginReducer.userPermissions
  );
  const pettyCashList = [
    { key: "petty_cash", title: Context.langData.expenses, id: 0 },
    { key: "petty_cash", title: Context.langData.categories, id: 1 },
  ];
  const [buttonArray, setButtonArray] = useState(pettyCashList);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const isPettyCashPermission = useMemo(() => {
    const isIndex = userPermissions.findIndex((e) => e.key == "petty_cash");
    if (isIndex != -1) {
      if (userPermissions[isIndex].status) {
        return true;
      }
      return false;
    }
    return false;
  }, [userPermissions]);

  const handleSelectItem = (item) => {
    setSelectedIndex(item);
  };
  const renderSelectedView = () => {
    if (selectedIndex == 0) {
      return <Expenses data-qaid='pettycash_expenses' />;
    }
    if (selectedIndex == 1) {
      return <Categories data-qaid='pettycash_categories' />;
    }
  };
  return (
    <>
      {isPettyCashPermission ? (
        <Grid
          data-qaid='pettycash_index_grid'
          style={{
            backgroundColor: "#FFFFFF",
            minHeight: "100%",
            width: "100%",
          }}>
          <Grid
            data-qaid='pettycash_index_contentgrid'
            style={{ height: "20%" }}>
            <Grid
              data-qaid='pettycash_index_titlegrid'
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ marginLeft: 36 }}>
              <H6
                data-qaid='pettycash_index_title_label'
                label={Context.langData.pettycash}
                style={{ color: "#051D33", paddingTop: 36 }}
              />
            </Grid>
            <Grid
              data-qaid='pettycash_index_bodygrid'
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                marginLeft: 36,
                flexDirextion: "row",
                marginBottom: 20,
                marginTop: 20,
              }}>
              {buttonArray.map((item, i) => (
                <Button
                  data-qaid='pettycash_button'
                  style={{
                    backgroundColor:
                      selectedIndex == item.id ? "#051D33" : "#EEEEEE",
                    marginRight: 1,
                    borderRadius: 0,
                    borderTopLeftRadius: i == 0 ? 5 : 0,
                    borderBottomLeftRadius: i == 0 ? 5 : 0,
                    borderTopRightRadius: buttonArray.length - 1 == i ? 5 : 0,
                    borderBottomRightRadius:
                      buttonArray.length - 1 == i ? 5 : 0,
                    height: 52,
                    width: 93,
                  }}
                  onClick={() => handleSelectItem(item.id)}>
                  <AvenirBlackH4
                    data-qaid='pettycash_button_label'
                    label={item.title}
                    style={{
                      color: selectedIndex == item.id ? "#FFFFFF" : "#051D33",
                      paddingLeft: 8,
                      paddingRight: 8,
                    }}
                  />
                </Button>
              ))}
            </Grid>
            <Grid
              data-qaid='dashboard_index_border'
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                borderBottom: "1px solid #EEEEEE",
                marginTop: 20,
              }}
            />
          </Grid>
          <Grid data-qaid='pettycash_body_view' style={{ height: "80%" }}>
            {renderSelectedView()}
          </Grid>
        </Grid>
      ) : (
        <Grid
          data-qaid='pettycash_index_body_grid'
          style={{ backgroundColor: "#FFFFFF", height: "100%" }}>
          <Grid
            data-qaid='pettycash_index_main_grid'
            item
            xs={12}
            md={6}
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Grid
              data-qaid='pettycash_index_title_grid'
              style={{ marginLeft: 56, marginRight: 56, paddingTop: 50 }}>
              <H6
                data-qaid='pettycash_index_title'
                label={Context.langData.pettycash}
              />
            </Grid>
          </Grid>
          <Grid
            data-qaid='pettycash_index_border'
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              borderBottom: "1px solid #EEEEEE",
              marginTop: 12,
            }}
          />
          <Grid
            data-qaid='pettycash_nopermissions_View'
            item
            lg={12}
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              paddingTop: "20%",
            }}>
            <H6
              data-qaid='pettycash_nopermissions_message'
              label={Context.langData.no_permission_error}
              style={{
                textAlign: "center",
                width: "55%",
                fontWeight: "bold",
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PettyCash;
