import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { fetchTaxes } from "../../../../redux/actions";
import TaxesList from "./taxesList";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Taxes = () => {
  const classes = useStyles();
  const restId = useSelector((state) => state.loginReducer.selectedRestaurant);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTaxes(restId.rest_id));
  }, []);
  return (
    <div>
      <TaxesList data-qaid='taxes-taxeslist' />
    </div>
  );
};

export default Taxes;
