import * as React from "react";

const MenuIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M21 7H3M21 12H3M21 17H3'
      stroke='#004C60'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default MenuIcon;
