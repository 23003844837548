import React from "react";
import { TextField, createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const styles = (theme) =>
  createStyles({
    input: {
      "&::placeholder": {
        color: "red",
      },
      textTransform: "capitalize",
    },
  });
const helperTextStyles = makeStyles((theme) => ({
  root: {
    marginTop: 5,
    marginLeft: 10,
    color: "black",
  },
  error: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: "#AB1212",
    },
  },
}));
const TextInputComponent = ({
  errorBorder = null,
  maxLength = 500,
  ...props
}) => {
  const classes = styles();
  const helperTestClasses = helperTextStyles();

  const onChangeInput = (e) => {
    if (props.capitalize) {
      props.onChange(e.target.value);
    } else {
      props.onChange(e);
    }
  };
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    const strs = s.toLowerCase().split(" ");
    return strs
      .map((ss) => ss.charAt(0).toUpperCase() + ss.slice(1).toLowerCase())
      .join(" ");
  };
  console.log("props.capitalize", props.capitalize ? "capitalize" : "none");
  return (
    <TextField
      data-qaid='textinputcomponent_textfield'
      {...props}
      value={props.value}
      onBlur={props.onBlur}
      onChange={props.onChange}
      fullWidth
      error={props.error}
      helperText={props.helperText}
      placeholder={props.placeholder}
      type={props.type}
      id={props.id}
      label={props.label}
      mask={props.mask}
      name={props.name}
      spellCheck
      variant={props.variant}
      size={props.size}
      style={{ width: "100%", border: errorBorder, borderRadius: 5 }}
      className={props.className}
      FormHelperTextProps={{ classes: helperTestClasses }}
      InputProps={{
        classes: { input: "as" },
      }}
      inputProps={{
        spellCheck: true,
        lang: "en",
        maxLength,
        style: { textTransform: props.capitalize ? "capitalize" : "none" },
      }}
    />
  );
};

export default TextInputComponent;
