import * as React from "react";

const HorizontalGrid = (props) => (
  <svg
    {...props}
    width={65}
    height={76}
    viewBox='0 0 56 76'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <mask
      id='prefix__a'
      maskUnits='userSpaceOnUse'
      x={4}
      y={0}
      width={48}
      height={48}>
      <rect
        x={4.5}
        y={0.5}
        width={47}
        height={47}
        rx={4.5}
        fill='#fff'
        stroke='#EEE'
      />
    </mask>
    <g mask='url(#prefix__a)'>
      <g filter='url(#prefix__filter0_i)'>
        <rect x={4} width={48} height={48} rx={5} fill='#808285' />
      </g>
      <path
        stroke='#fff'
        strokeWidth={3}
        d='M56 39.5H0M55.5 33.5H.5M56 27.5H0M55.5 21.5H.5M56 15.5H0M55.5 9.5H.5'
      />
    </g>
    <path
      d='M1.388 69h1.008v-3.96h4.536V69H7.94v-8.496H6.932v3.6H2.396v-3.6H1.388V69zm8.168-2.808c0 1.752 1.272 2.952 3 2.952s3-1.2 3-2.952-1.272-2.952-3-2.952-3 1.2-3 2.952zm1.008 0c0-1.176.78-2.088 1.992-2.088s1.992.912 1.992 2.088c0 1.176-.78 2.088-1.992 2.088s-1.992-.912-1.992-2.088zM17.005 69h.936v-2.736c0-1.284.504-2.088 1.644-2.088.168 0 .348.024.504.072l.06-.948a1.112 1.112 0 00-.384-.06c-.924 0-1.62.516-1.872 1.068h-.024c0-.312-.024-.624-.024-.924h-.888c0 .372.048.816.048 1.332V69zm4.303 0h.936v-5.616h-.936V69zm-.216-7.812c0 .396.288.684.684.684a.662.662 0 00.684-.684.684.684 0 10-1.368 0zM23.579 69h4.608v-.792h-3.384l3.3-4.104v-.72h-4.38v.792h3.156l-3.3 4.092V69zm5.536-2.808c0 1.752 1.272 2.952 3 2.952s3-1.2 3-2.952-1.272-2.952-3-2.952-3 1.2-3 2.952zm1.008 0c0-1.176.78-2.088 1.992-2.088s1.992.912 1.992 2.088c0 1.176-.78 2.088-1.992 2.088s-1.992-.912-1.992-2.088zM36.563 69h.937v-2.736c0-1.284.528-2.16 1.74-2.16.876 0 1.284.588 1.284 1.524V69h.936v-3.468c0-1.356-.66-2.292-2.136-2.292-.924 0-1.62.516-1.872 1.068h-.024c0-.312-.024-.624-.024-.924h-.888c0 .372.048.816.048 1.332V69zm9.74-5.616h-1.655v-1.596h-.936v1.596h-1.224v.792h1.224v3.084c0 1.164.216 1.884 1.548 1.884.348 0 .768-.06 1.092-.204l-.036-.852a1.822 1.822 0 01-.828.192c-.828 0-.84-.576-.84-1.296v-2.808h1.656v-.792zm1.664 1.332c.444-.408 1.068-.612 1.668-.612.876 0 1.392.42 1.392 1.236v.168h-.36c-1.38 0-3.54.108-3.54 1.968 0 1.092.947 1.668 2.087 1.668.852 0 1.477-.408 1.849-.984h.023c0 .24 0 .588.06.84h.9a6.237 6.237 0 01-.084-.972v-2.412c0-1.584-.696-2.376-2.352-2.376-.816 0-1.655.276-2.231.864l.588.612zm3.06 1.584v.36c-.025.912-.577 1.692-1.657 1.692-.707 0-1.235-.264-1.235-.972 0-.756.768-1.08 1.956-1.08h.935zm2.73 2.7h.936v-9.072h-.936V69z'
      fill='#373737'
    />
    <defs>
      <filter
        id='prefix__filter0_i'
        x={4}
        y={0}
        width={48}
        height={49}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feColorMatrix
          in='SourceAlpha'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={2.5} />
        <feComposite in2='hardAlpha' operator='arithmetic' k2={-1} k3={1} />
        <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0' />
        <feBlend in2='shape' result='effect1_innerShadow' />
      </filter>
    </defs>
  </svg>
);

export default HorizontalGrid;
