import axios from "axios";
import moment from "moment";
import config from "../../config.json";
import { ReadFromSession } from "../../Provider/sessionStorageServices";
import { StorageKey } from "../../models/Constant";
import { ssoReAuth } from "../../Provider/SsoServices";
import urls from "./urls";

let isRefreshing = false;
let failedQueue = [];
let store;

export const injectStoreInAxios = (_store) => {
  store = _store;
};

const instance = axios.create({
  baseURL: config.api_path,
  headers: {
    "content-type": "application/json",
  },
});

const setLoadingSign = (url) => {
  if (
    url !== "restaurant/customer/all" &&
    url !== "restaurant/employee/all" &&
    url !== "restaurant/employee/roles/all" &&
    url !== "restaurant/customer/check" &&
    url !== "restaurant/employee/check" &&
    url !== "restaurant/employee/role/check" &&
    url !== "restaurant/reports/visitor_log"
  ) {
    store.dispatch({ type: "LOADING" });
  }
};

const setLoadedSign = (url) => {
  store.dispatch({ type: "LOADED" });
};

// Use token if it is provided, otherwise use token in the store
const setAuthHeader = (req, token = "") => {
  if (req.headers && req.headers.oauth_token) return;
  const bearer_token =
    token || store.getState().loginReducer.loginDetails?.oauth_token;

  console.log("passed in token, bearer_token", token, bearer_token);
  if (bearer_token) {
    req.headers.oauth_token = bearer_token;
  }
  req.headers["X-Correlation-Id"] = crypto.randomUUID();
};

// This call is necessary since redux operation may take time to resolve, but the subsequent APi call
// needs the auth header right away
const setInstanceHeader = (url, token) => {
  if ((url === "login" || url === "refresh") && token) {
    instance.defaults.headers.common.oauth_token = token;
  }
};

const getRefreshTokenBody = () => {
  const { loginReducer } = store.getState();
  return {
    refresh_token: loginReducer.loginDetails.refresh_token,
    rest_id: loginReducer.selectedRestaurant.rest_id,
  };
};

const getRefreshToken = async () => {
  try {
    const result = await instance.post(urls.refresh, getRefreshTokenBody());
    // console.log("refresh token call result", result, result.data.oauth_token);
    store.dispatch({ type: "SET_OAUTH_TOKEN", payload: result.data });
    return result.data.oauth_token;
  } catch (error) {
    console.log("Refresh token call failed", error);
    return Promise.reject("failed to refresh token");
  }
};

export const wait = async (timeout) =>
  new Promise((resolve) => setTimeout(resolve, timeout));

const simulateGetRefreshToken = async () => {
  try {
    await wait(1000);
    return store.getState().loginReducer.loginDetails?.oauth_token;
  } catch (error) {
    console.log("Refresh token call failed", error);
    return Promise.reject("failed to refresh token");
  }
};
const processQueue = (error, token = null) => {
  console.log("failedQueue in processQueue", failedQueue);
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

instance.interceptors.response.use(
  (response) => {
    setLoadedSign();
    setInstanceHeader(response.config.url, response.data.oauth_token);
    console.log(
      "API CALL COMPLETED ::: ",
      response.config.url,
      " ::: ",
      moment().format("HH:mm:ss a")
    );
    return response;
  },
  async (err) => handleResponseError(err)
);

const handleResponseError = (err) => {
  setLoadedSign();
  const originalRequest = err.config;
  console.error("API CALL FAILED ::: ", err.config?.url, err.response);

  if (err.config?.url === urls.refresh) {
    if (err.response.status === 419) {
      // 419 in refresh call means refresh token expired, log user out
      store.dispatch({ type: "SET_BACKED_SESSION_TIMEOUT", payload: true });
      return;
    }
    if (!err.config?._retry) {
      // retry one time
      const req = {
        ...originalRequest,
        headers: { ...originalRequest.headers },
        body: getRefreshTokenBody(),
        _retry: true,
      };
      return instance(req);
    }
    store.dispatch({ type: "SET_BACKED_SESSION_TIMEOUT", payload: true });
    return;
  }
  if (err.response.status === 419) {
    if (ReadFromSession(StorageKey.Is_Sso) === "true") {
      ssoReAuth(store.getState().loginReducer.selectedRestaurant.rest_id);
      return;
    }
    console.log("isRefreshing", isRefreshing);

    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          const req = {
            ...originalRequest,
            headers: { ...originalRequest.headers, oauth_token: token },
          };
          // console.log("running in q", req);
          return instance(req);
        })
        .catch((err) => Promise.reject(err));
    }

    isRefreshing = true;

    return new Promise((resolve, reject) => {
      getRefreshToken()
        // simulateGetRefreshToken()
        .then((token) => {
          console.log("failedQueue, new token ", failedQueue, token);
          setInstanceHeader(err.config?.url, token);
          const req = {
            ...originalRequest,
            headers: { ...originalRequest.headers, oauth_token: token },
          };
          processQueue(null, token);
          // originalRequest.url = originalRequest.url.replace("_wrong", "");
          resolve(instance(req));
        })
        .catch((err) => {
          processQueue(err, null);
          reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  }

  const { data } = err.response;
  if (data) {
    if (!data.status) {
      data.status = data.message;
    }
    if (data.mfa_authorized === false) {
      store.dispatch({ type: "MFA_REQUIRED", payload: true });
    }
  }
  return Promise.reject(data);
};

instance.interceptors.request.use((req) => {
  const { url } = req;
  setLoadingSign(url);
  if (url !== "login") {
    setAuthHeader(req);
  }
  console.log(
    "API CALLING ::: ",
    req.method,
    " ::: ",
    req.url,
    " ::: ",
    moment().format("HH:mm:ss a")
  );
  return req;
});
export default instance;

const inventory_instance = axios.create({
  baseURL: config.inventory_api_path,

  headers: {
    "content-type": "application/json",
  },
});

inventory_instance.interceptors.request.use((req) => {
  const { url } = req;
  setLoadingSign(url);
  if (url !== "login") {
    setAuthHeader(req);
  }
  return req;
});

inventory_instance.interceptors.response.use(
  (response) => {
    setLoadedSign();
    setInstanceHeader(response.config.url, response.data.oauth_token);
    console.log(
      "API CALL COMPLETED ::: ",
      response.config.url,
      " ::: ",
      moment().format("HH:mm:ss a")
    );
    return response;
  },
  async (err) => {
    handleResponseError(err);
  }
);

const report_instance = axios.create({
  baseURL: config.reporting_api_path,

  headers: {
    "content-type": "application/json",
  },
});

report_instance.interceptors.request.use((req) => {
  const { url } = req;
  setLoadingSign(url);
  if (url !== "login") {
    setAuthHeader(req);
  }
  return req;
});

report_instance.interceptors.response.use(
  (response) => {
    setLoadedSign();
    setInstanceHeader(response.config.url, response.data.oauth_token);
    return response;
  },
  async (err) => {
    handleResponseError(err);
  }
);

const tax_configuration_instance = axios.create({
  baseURL: config.restaurant_api_path,

  headers: {
    "content-type": "application/json",
  },
});

tax_configuration_instance.interceptors.request.use((req) => {
  const { url } = req;
  setLoadingSign(url);
  if (url !== "login") {
    setAuthHeader(req);
  }
  return req;
});

tax_configuration_instance.interceptors.response.use(
  (response) => {
    setLoadedSign();
    setInstanceHeader(response.config.url, response.data.oauth_token);
    console.log(
      "API CALL COMPLETED ::: ",
      response.config.url,
      " ::: ",
      moment().format("HH:mm:ss a")
    );
    return response;
  },
  async (err) => {
    handleResponseError(err);
  }
);

const ticket_customization_instance = axios.create({
  baseURL: config.restaurant_api_path,

  headers: {
    "content-type": "application/json",
  },
});

ticket_customization_instance.interceptors.request.use((req) => {
  const { url } = req;
  setLoadingSign(url);
  if (url !== "login") {
    setAuthHeader(req);
  }
  return req;
});

ticket_customization_instance.interceptors.response.use(
  (response) => {
    setLoadedSign();
    setInstanceHeader(response.config.url, response.data.oauth_token);
    console.log(
      "API CALL COMPLETED ::: ",
      response.config.url,
      " ::: ",
      moment().format("HH:mm:ss a")
    );
    return response;
  },
  async (err) => {
    handleResponseError(err);
  }
);

export {
  inventory_instance,
  report_instance,
  tax_configuration_instance,
  ticket_customization_instance,
};

// import axios from 'axios';
// import StoreConfig from '../store';
// import { refresh } from './login';
// import config from '../../config.json'
// import { ssoReAuth } from '../../Provider/SsoServices';

// const instance = axios.create({

//   baseURL: process.env.NODE_ENV == "development" ? config.developmentURL : config.api_path,
//   //baseURL: 'https://dev.monerisgoappetit.io/api/e/v2/',

//   // baseURL: config.api_path,
//   // baseURL: 'http://192.168.1.20:8081/api/e/v2/',

//   headers: {
//     'content-type': 'application/json',
//   },
// });

// instance.interceptors.response.use(res => {
//   console.log("ID DEV", process.env.NODE_ENV)
//   const { store } = StoreConfig;
//   store.dispatch({ type: 'LOADED' })
//   console.log("in axios instance")
//   if (res.config.url === 'login') {
//     instance.defaults.headers.common['oauth_token'] = res.data.oauth_token;
//   }
//   if (res.config.url === 'refresh') {
//     instance.defaults.headers.common['oauth_token'] = res.data.oauth_token;
//   }
//   return res
// },
//   err => {
//     const { store } = StoreConfig;
//     store.dispatch({ type: 'LOADED' })
//     console.log(err.toString())
//     if (err.toString().indexOf("419") !== -1) {
//       const { loginReducer } = store.getState();
//       // store.dispatch({ type: 'SET_BACKED_SESSION_TIMEOUT', payload: true })
//       // store.dispatch(refresh({ refresh_token: loginReducer.loginDetails.refresh_token, rest_id: loginReducer.selectedRestaurant.rest_id }))

//       const is_sso = ssoReAuth(loginReducer?.selectedRestaurant?.rest_id)
//       if (!is_sso) {
//         store.dispatch({ type: 'SET_BACKED_SESSION_TIMEOUT', payload: true })
//         store.dispatch(refresh({ refresh_token: loginReducer?.loginDetails?.refresh_token, rest_id: loginReducer?.selectedRestaurant?.rest_id }))
//       }
//     }

//     if (err.response) {

//       const { data } = err.response;
//       const codes = [401, 403, 419]
//       console.log("ERRRORSSSSS", data)
//       if (!data.status) {
//         data["status"] = data.message;
//       }
//       if (codes.indexOf(data?.status_code) != -1 && err.response.config.url != "login") {
//         return store.dispatch({ type: "SET_EXPIRY", payload: data })
//       }
//       if (data.mfa_authorized === false) {
//         store.dispatch({ type: 'MFA_REQUIRED', payload: true })
//       }
//       return Promise.reject(data)
//     }
//     else {

//       return Promise.reject({ status: err.message })
//     }

//   }
// )

// instance.interceptors.request.use(req => {
//   const { store } = StoreConfig;
//   if (
//     req.url !== 'restaurant/customer/all' &&
//     req.url !== 'restaurant/employee/all' &&
//     req.url !== 'restaurant/employee/roles/all' &&
//     req.url !== 'restaurant/customer/check' &&
//     req.url !== 'restaurant/employee/check' &&
//     req.url !== 'restaurant/employee/role/check' &&
//     req.url !== 'restaurant/reports/visitor_log'
//   ) {
//     store.dispatch({ type: 'LOADING' })
//   }
//   if (req.url !== 'login') {
//     const { store } = StoreConfig;
//     req.headers['oauth_token'] = store.getState().loginReducer.loginDetails.oauth_token;
//   }
//   return req
// })
// export default instance;
