import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  avenir: {
    fontFamily: "AvenirLT",
  },

  padding: (props) => ({
    padding: props.padding || theme.spacing(1),
  }),
  paddingHorizantal: (props) => ({
    paddingLeft: props.padding || theme.spacing(1),
    paddingRight: props.padding || theme.spacing(1),
  }),
  paddingTop50: {
    paddingTop: 64,
  },
  paddingVertical: (props) => ({
    paddingTop: props.padding || theme.spacing(1),
    paddingBottom: props.padding || theme.spacing(1),
  }),
  margin: (props) => ({
    margin: props.margin || theme.spacing(1),
  }),
  marginHorizantal: (props) => ({
    marginLeft: props.margin || theme.spacing(1),
    marginRight: props.margin || theme.spacing(1),
  }),
  marginVertical: (props) => ({
    marginTop: props.margin || theme.spacing(1),
    marginBottom: props.margin || theme.spacing(1),
  }),
  price: {
    color: theme.palette.text.price,
    fontWeight: "bold",
    fontSize: "1.5rem",
  },
  fullWidth: {
    width: "100%",
  },
  fullHeight: {
    height: "100%",
  },
  minWidth: (props) => ({
    minWidth: props.minWidth || 200,
  }),
  cursor: {
    cursor: "pointer",
  },
  justifyAlignCenter: {
    justifyContent: "center",
    alignItems: "center",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  itemImage: {
    width: "100%",
    height: 150,
    // paddingTop: 10,
    // paddingBottom: 10
  },
  objectFit: {
    color: "transparent",
    width: "100%",
    height: "100%",
    objectFit: "fill",
    textAlign: "center",
    textIndent: 10000,
  },
  veg: {
    color: "green",
  },
  border: (props) => ({
    border: 1,
    borderColor: props.borderColor ? props.borderColor : "#c6c6c6",
    borderStyle: "solid",
  }),
  borderBottom: (props) => ({
    borderBottom: 1,
    borderBottomColor: props.borderColor ? props.borderColor : "#c6c6c6",
    borderBottomStyle: "solid",
  }),
  subheader: {
    fontFamily: "AvenirLT",
    fontSize: 20,
    fontWeight: 400,
  },
  main_header: {
    fontFamily: "AvenirLT",
    fontSize: 32,
    fontWeight: 400,
    marginLeft: 10,
  },
  sub_header: {
    fontFamily: "AvenirLT",
    fontSize: 24,
    fontWeight: 400,
    marginLeft: 10,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  fw400: {
    fontWeight: 400,
  },
  fw500: {
    fontWeight: 500,
  },
  fw600: {
    fontWeight: 600,
  },
  fw700: {
    fontWeight: 700,
  },
  fw800: {
    fontWeight: 800,
  },
  fw900: {
    fontWeight: 900,
  },
  fwBold: {
    fontWeight: "bold",
  },
  black: {
    color: "black",
  },
  white: {
    color: "white",
  },
  avenirLT: {
    fontFamily: "AvenirLT",
  },
  avenirLTBold: {
    fontFamily: "AvenirLTBold",
  },
  avenirLTMedium: {
    fontFamily: "AvenirLTMedium",
  },
  buttonClass: {
    height: 40,
    borderRadius: 5,
  },
  icon: {
    minWidth: "35px",
  },
}));

export default useStyles;
