import React, { useEffect, useState, useContext } from "react";
import { Grid, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  fetchSpecificIndent,
  updateIndentStatus,
} from "../../../../redux/actions";
import RightDrawer from "../../../../components/RightDrawer";
import MyContext from "../../../../Provider/MyContext";
import InventoryIcon from "../../../../components/SVG/InventoryIcon";
import CircleClose from "../../../../components/SVG/CircleClose";
import {
  AvenirBlackH4,
  H4,
  H6,
  AvenirBlackH3,
  DotText,
  AvenirBlackH5,
} from "../../../../utils/text";
import { ConvertPrice, parseToFloat } from "../../../../utils/util";
import styles from "../../../../styles/index";
import LoopVector from "../../../../components/SVG/LoopVector";
import RightArrow from "../../../../components/SVG/RightArrow";
import SnackBar from "../../../../components/SnackBar";
import AlertDialog from "../../../../components/Dialog";
import AddFulfillment from "./addFulfillment";
import IssueIndent from "./issueIndent";
import RepeatIndent from "./repeatIndent";
import EditFulfillment from "./editFulfillment";

const ViewIndentId = (props) => {
  const style = styles();
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  const indentViewData = useSelector((state) => state.inventory.indentViewdata);
  const [showRepeatIndent, setShowRepeatIndent] = useState(false);
  const [showIssueIndent, setShowIssueIndent] = useState(false);
  const [data, setData] = useState({});
  const [snackbar, setSnackbar] = useState(null);
  const [error, setError] = useState("");
  const [isClose, setIsClose] = useState(false);
  const [showFulfilment, setShowFulfilment] = useState(false);
  const [detailFulfillment, setDetailFulfillment] = useState(0);
  const [showIndent, setShowIndent] = useState(true);
  const [showAddFulfillment, setShowAddFulfillment] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [heading, setHeading] = useState(Context.langData.delete_vendor);
  const [bodyMessage, setBodyMessage] = useState(
    Context.langData.delete_undo_body_msg
  );
  const {
    open,
    onClose,
    row,
    callback,
    repeatIndentCallback,
    onClickAction,
    closeCallback,
  } = props;

  useEffect(() => {
    if (indentViewData.status === "Closed") {
      setIsClose(true);
    } else {
      setIsClose(false);
    }
    dispatch(fetchSpecificIndent(row.indent_id));
  }, []);
  useEffect(() => {
    if (indentViewData) {
      let totalTax = 0;
      let totalAmount = 0;
      const itemsArray = [];
      indentViewData?.items?.map((itemObj) => {
        itemObj.received =
          itemObj.received != 0 ? itemObj.received : itemObj.amount;
        itemsArray.push(itemObj);
        totalTax += parseToFloat(itemObj.tax_amount);
        totalAmount +=
          parseToFloat(itemObj.tax_amount) +
          parseToFloat(itemObj.price) * parseToFloat(itemObj.received);
      });
      const totalAmountValue =
        totalAmount + parseToFloat(indentViewData.delivery);
      const obj = {
        ...indentViewData,
        balance: ConvertPrice(totalAmountValue - indentViewData.amount_paid),
        total_tax: totalTax,
        total_amount: ConvertPrice(totalAmountValue),
        amount_paid: ConvertPrice(indentViewData.amount_paid),
        delivery: ConvertPrice(indentViewData.delivery),
      };
      if (obj.status == Context.langData.closed) {
        setIsClose(true);
      } else {
        setIsClose(false);
      }
      setData(obj);
      setError("");
    }
    console.log(JSON.stringify(indentViewData.raiseDate));
  }, [indentViewData]);

  const repeatCallback = () => {
    callback();
  };

  const handleClose = () => {
    if (totalAmount() == amountPaid()) {
      setError("");
      setHeading(
        Context.langData.close_indent_header + Context.langData.delete_single
      );
      setShowDialog(true);
    } else {
      setError(Context.langData.close_error);
    }
  };
  const handleCloseAlert = (val) => {
    if (totalAmount() <= amountPaid()) {
      if (val) {
        const reqBody = {
          status: "CLOSED",
        };
        dispatch(updateIndentStatus(indentViewData.id, reqBody, closeSuccess));
      }
    } else {
      closeSuccess(false);
    }
    setShowDialog(false);
  };
  const closeSuccess = (success) => {
    if (success) {
      closeCallback(success);
    } else {
      setSnackbar({
        message: Context.langData.failed_close_indent,
        severity: "error",
      });
    }
  };
  const totalAmount = () => {
    let total = 0;
    if (indentViewData?.fulfillments?.length > 0) {
      indentViewData.fulfillments.map((f) => {
        if (f.totalAmount !== null) {
          total += f.totalAmount.amount;
        }
      });
    }
    return total / 100;
  };
  const amountPaid = () => {
    let total = 0;
    if (indentViewData?.fulfillments?.length > 0) {
      indentViewData.fulfillments.map((f) => {
        if (f.amountPaid !== null) {
          total += f.amountPaid.amount;
        }
      });
    }
    return total / 100;
  };
  const prefixDollar = (value) => `$${value}`;

  const fulfillmentTotal = (fulfillment) => {
    let total = 0;
    fulfillment.items?.map((i, index) => {
      total = total + i.fulfilledQuantity * i.unitPrice.amount + i.tax.amount;
    });
    return total;
  };
  const displayFulfillment = (fulfillment, event) => {
    setDetailFulfillment(fulfillment);
    setShowFulfilment(true);
    setShowIndent(false);
  };
  const deleteFulfillmentCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.success_delete_fulfillment,
        severity: "success",
      });
      setShowFulfilment(false);
      setShowIndent(true);
      dispatch(fetchSpecificIndent(row.indent_id));
    } else {
      setSnackbar({
        message: Context.langData.failed_delete_fulfillment,
        severity: "error",
      });
    }
  };

  const addFulfillmentCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.success_add_fulfillment,
        severity: "success",
      });
      setShowAddFulfillment(false);
      setShowIndent(true);
      dispatch(fetchSpecificIndent(row.indent_id));
    } else {
      setSnackbar({
        message: Context.langData.failed_add_fulfillment,
        severity: "error",
      });
    }
  };

  const updateFulfillmentCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.success_edit_fulfillment,
        severity: "success",
      });
      setShowFulfilment(false);
      setShowIndent(true);
      dispatch(fetchSpecificIndent(row.indent_id));
    } else {
      setSnackbar({
        message: Context.langData.failed_edit_fulfillment,
        severity: "error",
      });
    }
  };

  const getReceivedQty = (itemId) => {
    let totalQty = 0;
    if (indentViewData.fulfillments != null) {
      indentViewData.fulfillments.map((f, index) => {
        if (f.items != null) {
          f.items.map((i, index) => {
            if (i.id == itemId) {
              totalQty += i.fulfilledQuantity;
            }
          });
        }
      });
    }
    return totalQty;
  };
  return (
    <RightDrawer open={open} padding={1}>
      {showIndent && (
        <Grid
          data-qaid='viewIndentId_body_grid'
          style={{ display: "flex", height: "100%", flexDirection: "column" }}>
          <Grid
            data-qaid='viewIndentId_child1_grid'
            style={{
              display: "flex",
              height: "85%",
              flexDirection: "column",
              overflowX: "auto",
              marginBottom: 10,
            }}>
            <Grid
              data-qaid='viewIndentId_close_grid'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}>
              <CircleClose
                data-qaid='viewIndentId_closeicon'
                size={20}
                onClick={onClose}
                style={{ marginRight: 40, marginTop: 20 }}
              />
            </Grid>
            <Grid
              data-qaid='viewIndentId_settings_grid'
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 56,
                marginBottom: 10,
              }}>
              <InventoryIcon
                data-qaid='viewIndentId_setting_icon'
                style={{ marginRight: 10 }}
                width={22.5}
                height={24}
                color='#707272'
              />
              <H4
                data-qaid='viewIndentId_settings'
                label={Context.langData.inventory}
                style={{ color: "#707272" }}
              />
            </Grid>
            <H6
              data-qaid='viewIndentId_title'
              style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
              label={Context.langData.indent_info}
            />
            <Grid
              data-qaid='viewIndentId_main_grid'
              direction='row'
              container
              spacing={1}
              style={{
                marginLeft: 0,
                marginRight: 0,
                width: 500,
                display: "flex",
                flexDirection: "column",
                height: 60,
              }}>
              <Grid
                data-qaid='viewIndentId_dateGrid'
                item
                sm={6}
                style={{
                  marginLeft: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}>
                <AvenirBlackH3
                  data-qaid='transaction_title'
                  label={Context.langData.transaction_details}
                  style={{ marginLeft: 56, marginBottom: 20 }}
                />
              </Grid>
              <Grid
                data-qaid='viewIndentId_dateGrid'
                item
                sm={6}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}>
                {indentViewData.status !== "Closed" ? (
                  <Button
                    className={style.buttonClass}
                    data-qaid='createGroup_AddFulfillmentButton'
                    style={{
                      backgroundColor: "#FFB600",
                      marginRight: -50,
                      width: 120,
                      height: 40,
                    }}
                    variant='contained'
                    id='cancel'
                    type='submit'
                    onClick={() => {
                      handleClose();
                    }}>
                    <AvenirBlackH5
                      data-qaid='createGroup_saveLabel'
                      label={Context.langData.close_indent}
                    />
                  </Button>
                ) : null}
              </Grid>
            </Grid>
            <Grid
              style={{
                borderBottom: "1px solid #EEEEEE",
                marginLeft: 56,
                marginBottom: 20,
                marginRight: 56,
              }}
            />
            <Grid
              data-qaid='viewIndentId_main_grid'
              style={{
                marginLeft: 56,
                marginBottom: 20,
                marginRight: 56,
                width: 500,
                display: "flex",
                flexDirection: "column",
              }}>
              <Grid
                data-qaid='viewIndentId_vendorContainer'
                container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}>
                <Grid
                  data-qaid='viewIndentId_vendorGrid'
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "50%",
                  }}>
                  <DotText
                    data-qaid='rviewIndentId_vendorLabel'
                    label={`${Context.langData.vendor}: ${indentViewData.vendorName}`}
                    style={{ marginBottom: 10 }}
                  />
                </Grid>
                <Grid
                  data-qaid='viewIndentId_vendorGrid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "50%",
                  }}>
                  <H4
                    data-qaid='viewIndentId_dateLabel'
                    label={`${Context.langData.date_created}: ${moment(indentViewData.raisedDate).format("MMM DD, YYYY")}`}
                    style={{ marginBottom: 10 }}
                  />
                </Grid>
              </Grid>
              <Grid
                data-qaid='viewIndentId_vendorContainer'
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}>
                <Grid
                  data-qaid='viewIndentId_vendorGrid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "50%",
                  }}>
                  <H4
                    data-qaid='rviewIndentId_TotalAmountLabel'
                    label={`${Context.langData.total_amount}: ${prefixDollar(ConvertPrice(totalAmount()))}`}
                    style={{ marginBottom: 10 }}
                  />
                </Grid>
                <Grid
                  data-qaid='viewIndentId_vendorGrid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "50%",
                  }}>
                  <H4
                    data-qaid='viewIndentId_StatusLabel'
                    label={`${Context.langData.status}: ${indentViewData.status}`}
                    style={{ marginBottom: 10 }}
                  />
                </Grid>
              </Grid>
              <Grid
                data-qaid='viewIndentId_vendorContainer'
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}>
                <Grid
                  data-qaid='viewIndentId_vendorGrid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "50%",
                  }}>
                  <H4
                    data-qaid='rviewIndentId_AmountPaidLabel'
                    label={`${Context.langData.paid_amount}: ${prefixDollar(ConvertPrice(amountPaid()))}`}
                    style={{ marginBottom: 10 }}
                  />
                </Grid>
                <Grid
                  data-qaid='viewIndentId_vendorGrid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "50%",
                  }}>
                  <H4
                    data-qaid='rviewIndentId_BalanceLabel'
                    label={`${Context.langData.balance_amount}: ${prefixDollar(ConvertPrice(totalAmount() - amountPaid()))}`}
                    style={{ marginBottom: 10 }}
                  />
                </Grid>
              </Grid>

              <Grid
                data-qaid='viewIndentId_table_body'
                item
                style={{
                  marginTop: "20px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}>
                <Grid
                  data-qaid='viewIndentId_subContainer3'
                  item
                  style={{
                    height: "40px",
                    width: "100%",
                    backgroundColor: "#F3FAFD",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}>
                  <Grid
                    data-qaid='viewIndentId_dateGrid'
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "40%",
                    }}>
                    <AvenirBlackH4
                      data-qaid='viewIndentId_itemHeading'
                      label={Context.langData.items}
                      style={{ marginLeft: 20 }}
                    />
                  </Grid>
                  <Grid
                    data-qaid='viewIndentId_dateGrid'
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "30%",
                    }}>
                    <AvenirBlackH4
                      data-qaid='viewIndentId_request_qtyHeading'
                      label={Context.langData.request_qty}
                    />
                  </Grid>
                  <Grid
                    data-qaid='viewIndentId_dateGrid'
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "30%",
                    }}>
                    <AvenirBlackH4
                      data-qaid='viewIndentId_request_qtyHeading'
                      label={Context.langData.recieved_qty}
                    />
                  </Grid>
                </Grid>
                {indentViewData?.items?.length > 0
                  ? indentViewData.items.map((itemObj, index) => (
                      <Grid
                        container
                        spacing={2}
                        key={itemObj.id}
                        data-qaid='raiseIndent_stockNameContainer'
                        item
                        style={{
                          marginTop: "10px",
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          backgroundColor: "#FAFAFA",
                          marginLeft: 0,
                        }}>
                        <Grid
                          item
                          xs
                          data-qaid='viewIndentId_dateGrid'
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            width: "40%",
                          }}>
                          <H4
                            data-qaid='viewIndentId_itemHeading'
                            label={`${itemObj.name}`}
                            style={{ marginLeft: 0 }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs
                          data-qaid='viewIndentId_dateGrid'
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            width: "30%",
                          }}>
                          <H4
                            data-qaid='viewIndentId_request_qtyHeading'
                            label={`${itemObj.requestedQuantity} ${itemObj.unitTypeCode}`}
                            style={{ marginLeft: 20 }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs
                          data-qaid='viewIndentId_dateGrid'
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            width: "30%",
                          }}>
                          <H4
                            data-qaid='viewIndentId_request_qtyHeading'
                            label={`${getReceivedQty(itemObj.id)} ${itemObj.unitTypeCode}`}
                            style={{ marginLeft: 10 }}
                          />
                        </Grid>
                      </Grid>
                    ))
                  : null}
              </Grid>
            </Grid>
            <Grid>
              <AvenirBlackH3
                data-qaid='transaction_title'
                label={Context.langData.fulfillments}
                style={{
                  marginLeft: 56,
                  marginTop: 20,
                  marginBottom: 20,
                  marginRight: 56,
                }}
              />
              <Grid
                style={{
                  borderBottom: "1px solid #EEEEEE",
                  marginLeft: 56,
                  marginBottom: 20,
                  marginRight: 56,
                }}
              />
              <Grid
                data-qaid='viewIndentId_subContainer4'
                item
                style={{
                  height: "40px",
                  width: "100%",
                  backgroundColor: "#F3FAFD",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: 500,
                  marginLeft: 56,
                }}>
                <Grid
                  data-qaid='viewIndentId_dateGrid'
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    width: "20%",
                  }}>
                  <AvenirBlackH4
                    data-qaid='viewIndentId_dateHeading'
                    label={Context.langData.date_received}
                    style={{ marginLeft: 0 }}
                  />
                </Grid>
                <Grid
                  data-qaid='viewIndentId_dateGrid'
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    width: "20%",
                  }}>
                  <AvenirBlackH4
                    data-qaid='viewIndentId_request_fulfillmentNoHeading'
                    label={Context.langData.fulfillment_no}
                    style={{ marginLeft: 0 }}
                  />
                </Grid>
                <Grid
                  data-qaid='viewIndentId_dateGrid'
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    width: "20%",
                  }}>
                  <AvenirBlackH4
                    data-qaid='viewIndentId_request_billNoHeading'
                    label={Context.langData.bill_no}
                    style={{ marginLeft: -10 }}
                  />
                </Grid>
                <Grid
                  data-qaid='viewIndentId_dateGrid'
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    width: "20%",
                  }}>
                  <AvenirBlackH4
                    data-qaid='viewIndentId_request_amountPaidHeading'
                    label={Context.langData.total_amount}
                    style={{ marginLeft: -20 }}
                  />
                </Grid>
                <Grid
                  data-qaid='viewIndentId_dateGrid'
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    width: "20%",
                  }}>
                  <AvenirBlackH4
                    data-qaid='viewIndentId_request_balanceHeading'
                    label={Context.langData.amount_paid}
                    style={{ marginLeft: -20 }}
                  />
                </Grid>
              </Grid>
              <Grid>
                {indentViewData?.fulfillments?.length > 0
                  ? indentViewData.fulfillments.map((fulfillment) => {
                      const fulfillmentWithRequestQty = { ...fulfillment };
                      fulfillmentWithRequestQty?.items?.map((fItem) => {
                        // find the request quantity from indentViewData
                        for (
                          let i = 0;
                          i < indentViewData?.items?.length;
                          i++
                        ) {
                          if (indentViewData.items[i].id == fItem.id) {
                            fItem.requestedQuantity =
                              indentViewData.items[i].requestedQuantity;
                            break;
                          }
                        }
                      });
                      return (
                        <Grid
                          container
                          spacing={2}
                          key={fulfillment.id}
                          data-qaid='raiseIndent_stockNameContainer'
                          item
                          style={{
                            maxHeight: 75,
                            marginTop: "10px",
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            backgroundColor: "#FAFAFA",
                            width: 500,
                            marginLeft: 56,
                          }}>
                          <Grid
                            item
                            xs
                            data-qaid='viewIndentId_dateGrid'
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              width: "20%",
                            }}>
                            <H4
                              data-qaid='viewIndentId_dateHeading'
                              label={moment(fulfillment.dateReceived).format(
                                "YYYY-MM-DD"
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            xs
                            data-qaid='viewIndentId_dateGrid'
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              width: "20%",
                            }}>
                            <H4
                              data-qaid='viewIndentId_request_fulfillmentNoHeading'
                              label={fulfillment.id}
                            />
                          </Grid>
                          <Grid
                            item
                            xs
                            data-qaid='viewIndentId_dateGrid'
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              width: "20%",
                            }}>
                            <H4
                              data-qaid='viewIndentId_request_billNoHeading'
                              label={fulfillment.billNumber}
                            />
                          </Grid>
                          <Grid
                            item
                            xs
                            data-qaid='viewIndentId_dateGrid'
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              width: "20%",
                            }}>
                            <H4
                              data-qaid='viewIndentId_request_amountPaidHeading'
                              label={
                                fulfillment.totalAmount
                                  ? prefixDollar(
                                      ConvertPrice(
                                        fulfillment.totalAmount.amount / 100
                                      )
                                    )
                                  : 0
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            data-qaid='viewIndentId_dateGrid'
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              width: "20%",
                            }}>
                            <H4
                              data-qaid='viewIndentId_request_balanceHeading'
                              label={
                                fulfillment.amountPaid
                                  ? prefixDollar(
                                      ConvertPrice(
                                        fulfillment.amountPaid.amount / 100
                                      )
                                    )
                                  : 0
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            xs
                            data-qaid='viewIndentId_dateGrid'
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              width: "20%",
                            }}>
                            <RightArrow
                              data-qaid='datatable_right_arrow'
                              style={{ cursor: "pointer" }}
                              data-id={fulfillment.id}
                              data-name={fulfillment.id}
                              id={fulfillment.id}
                              onClick={(e) => {
                                displayFulfillment(
                                  fulfillmentWithRequestQty,
                                  e
                                );
                              }}
                            />
                          </Grid>
                        </Grid>
                      );
                    })
                  : null}
              </Grid>
            </Grid>
          </Grid>
          {error && (
            <Grid
              style={{
                height: "5%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <AvenirBlackH4
                label={error}
                style={{
                  color: "#AB1212",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            </Grid>
          )}
          <Grid
            data-qaid='createGroup_cancel_main_Grid'
            style={{
              display: "flex",
              height: "15%",
              flexDirection: "column",
              justifyContent: "flex-end",
              backgroundColor: "#FAFAFA",
              border: "1px solid #C3C3C3",
              marginTop: 2,
            }}>
            <Grid
              data-qaid='createGroup_cancelGrid'
              item
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                paddingTop: "25px",
                alignItems: "center",
                paddingBottom: "30px",
                paddingRight: 25,
              }}>
              <Button
                className={style.buttonClass}
                data-qaid='viewIndentId_repeatIndentButton'
                size='large'
                variant='text'
                type='submit'
                onClick={() => {
                  setShowRepeatIndent(true);
                }}
                style={{ color: "white", cursor: "pointer" }}>
                <LoopVector style={{ marginRight: 8 }} />
                <AvenirBlackH4
                  data-qaid='createGroup_cancelLabel'
                  label={Context.langData.repeat_indent}
                  style={{ color: "#004C60" }}
                />
              </Button>
              {indentViewData.status !== "Closed" ? (
                <Button
                  className={style.buttonClass}
                  data-qaid='createGroup_AddFulfillmentButton'
                  style={{
                    backgroundColor: "#FFB600",
                    marginRight: 10,
                    width: 90,
                    height: 40,
                  }}
                  variant='contained'
                  id='cancel'
                  type='submit'
                  onClick={() => {
                    setShowIndent(false);
                    setShowAddFulfillment(true);
                  }}>
                  <AvenirBlackH5
                    data-qaid='createGroup_saveLabel'
                    label={Context.langData.add_fulfillment}
                  />
                </Button>
              ) : null}
              <Button
                className={style.buttonClass}
                data-qaid='createGroup_cancelButton'
                style={{
                  backgroundColor: "#FFF",
                  border: "2px solid #051D33",
                  marginRight: 10,
                  width: 103,
                  height: 40,
                  borderColor: "#707272",
                }}
                variant='contained'
                id='cancel'
                onClick={onClose}>
                <AvenirBlackH4
                  data-qaid='createGroup_cancelLabel'
                  label={Context.langData.close}
                  style={{ color: "#004C60" }}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      {showFulfilment && (
        <EditFulfillment
          open={showFulfilment}
          fulfillment={detailFulfillment}
          onClose={() => {
            setShowFulfilment(false);
            setShowIndent(true);
            setError("");
          }}
          deleteCallback={deleteFulfillmentCallback}
          saveCallback={updateFulfillmentCallback}
        />
      )}
      {showAddFulfillment && (
        <AddFulfillment
          open={showFulfilment}
          onClose={() => {
            setShowAddFulfillment(false);
            setShowIndent(true);
            setError("");
          }}
          saveCallback={addFulfillmentCallback}
        />
      )}
      {showRepeatIndent && (
        <RepeatIndent
          open={showRepeatIndent}
          row={row}
          onClose={() => setShowRepeatIndent(false)}
          repeatCallback={repeatIndentCallback}
        />
      )}
      {showIssueIndent && (
        <IssueIndent
          open={showIssueIndent}
          row={row}
          onClose={() => setShowIssueIndent(false)}
          repeatCallback={repeatCallback}
        />
      )}
      {snackbar && (
        <SnackBar
          data-qaid='indent_snackbar'
          open
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
      <AlertDialog
        showExtra={false}
        showDialog={showDialog}
        body={bodyMessage}
        heading={heading}
        IsError={false}
        handleClose={handleCloseAlert}
      />
    </RightDrawer>
  );
};

export default ViewIndentId;
