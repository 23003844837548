import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { AvenirBlackH4, H4 } from "../utils/text";
import packageInfo from "../../package.json";

const Footer = ({ from }) => {
  const link = useMemo(() => {
    const loc = window.location.origin;
    if (packageInfo.routepath)
      return `${loc}/${packageInfo.routepath}/terms_of_use`;
    return `${loc}/terms_of_use`;
  }, [packageInfo.routepath]);

  const date = new Date();
  const year = date.getFullYear();

  return (
    <footer
      style={{
        paddingTop: 10,
        paddingBottom: 10,
        position: "fixed",
        bottom: 0,
        width: "100%",
        zIndex: 1200,
        backgroundColor: "white",
        paddingLeft: 30,
        paddingRight: 30,
        display: "flex",
        alignItems: "center",
      }}>
      <span
        style={{
          fontSize: 14,
          fontWeight: 300,
          color: "#707272",
          marginRight: 60,
        }}>
        <H4 label={`Copyright Moneris Go Restaurant POS ${year}`} />
      </span>
      <Link title='Moneris.com' link='https://www.moneris.com' from={from} />
      <Link title='Terms of use' link={link} from={from} />
      <Link
        title='Privacy notice'
        link='https://www.moneris.com/en/legal/privacy-notice'
        from={from}
      />
      <Link
        title='Support'
        link='https://www.moneris.com/en/support/moneris-go/moneris-go-app%C3%A9tit'
        from={from}
      />
    </footer>
  );
};
const Link = ({ title, link, isOpenNewTab = true, from }) => {
  const navigate = useNavigate();
  const onClick = () => {
    if (from == "main") {
      navigate("/terms");
    } else {
      navigate("/home/terms");
    }
  };
  return (
    <span style={{ marginLeft: 10, marginRight: 10, fontWeight: 500 }}>
      {isOpenNewTab ? (
        <a href={link} target='_blank' rel='noreferrer'>
          <AvenirBlackH4 label={title} style={{ color: "#004C60" }} />
        </a>
      ) : (
        <span onClick={onClick} style={{ cursor: "pointer" }}>
          <AvenirBlackH4 label={title} style={{ color: "#004C60" }} />
        </span>
      )}
    </span>
  );
};
export default Footer;
