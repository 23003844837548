import * as React from "react";

const CalenderIcon = (props) => (
  <svg
    width='16'
    height='17'
    viewBox='0 0 16 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0)'>
      <path
        d='M15 1.79712H0.99998C0.63179 1.79712 0.333313 2.0956 0.333313 2.46379V15.1305C0.333313 15.4986 0.63179 15.7971 0.99998 15.7971H15C15.3682 15.7971 15.6666 15.4986 15.6666 15.1305V2.46379C15.6666 2.0956 15.3682 1.79712 15 1.79712Z'
        stroke='#004C60'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.66669 0.463867V3.7972'
        stroke='#004C60'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.3333 0.463867V3.7972'
        stroke='#004C60'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.333313 5.13062H15.6666'
        stroke='#004C60'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect
          width='16'
          height='16'
          fill='white'
          transform='translate(0 0.129883)'
        />
      </clipPath>
    </defs>
  </svg>
);

export default CalenderIcon;
