import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import VerticalTabs from "../../../components/verticalTabs";
import {
  fetchOrderType_ps,
  fetchOrderTypes,
  fetchPaymentTypes,
} from "../../../redux/actions";
import MyContext from "../../../Provider/MyContext";
import SalesSummaryTabs from "./salesSummary";
import DaywiseSales from "./daywiseSales";
import ItemSales from "./itemSales";
import AlcoholSales from "./alcoholSales";
import ModifierSales from "./modifierSales";
import PettyCash from "./pettyCash";
import Groups from "./groups";
import EmployeeSales from "./employeeSalesReport";

const useStyles = makeStyles({
  btnApply: {
    backgroundColor: "#f0585f",
    color: "white",
    marginLeft: "10px",
  },
  btnClear: {
    backgroundColor: "#dcdcdc",
    color: "black",
  },
  title: {
    fontSize: 14,
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: "50%",
    backgroundColor: "#f4f4f5 !important",
    borderWidth: 0,
    borderColor: "#f4f4f5",
  },
  tabs: {
    border: `1px solid #f4f4f5`,
  },
});

const SalesReport = () => {
  const classes = useStyles();
  const Context = useContext(MyContext);
  const [selectIndexTabChange, setSelectIndexTabChange] = useState(0);
  const dispatch = useDispatch();
  const callback = (value) => {
    setSelectIndexTabChange(value);
  };
  useEffect(() => {
    dispatch(fetchPaymentTypes());
    dispatch(fetchOrderType_ps());
    dispatch(fetchOrderTypes());
  }, []);
  const TabsData = [
    { name: Context.langData.sales_summary, id: 0 },
    { name: Context.langData.day_wise_sales, id: 1 },
    { name: Context.langData.items_sales, id: 2 },
    { name: Context.langData.alcohol_sales, id: 3 },
    { name: Context.langData.modifier_sales, id: 4 },
    { name: Context.langData.employee_sales, id: 5 },
    { name: Context.langData.groups, id: 6 },
    { name: Context.langData.petty_cash, id: 7 },
  ];
  return (
    <Grid data-qaid='sales_mainContainer' container style={{ padding: 20 }}>
      <Grid
        item
        xs={12}
        lg={2}
        style={{ display: "flex", backgroundColor: "#FAFAFA" }}>
        <VerticalTabs tabData={TabsData} indexCallback={callback} />
      </Grid>
      <Grid
        data-qaid='sales_subContainer'
        item
        xs={12}
        sm={12}
        md={9}
        lg={10}
        style={{ paddingLeft: 10 }}>
        {selectIndexTabChange == 0 && <SalesSummaryTabs />}
        {selectIndexTabChange == 1 && <DaywiseSales />}
        {selectIndexTabChange == 2 && <ItemSales />}
        {selectIndexTabChange == 3 && <AlcoholSales />}
        {selectIndexTabChange == 4 && <ModifierSales />}
        {selectIndexTabChange == 6 && <Groups />}
        {selectIndexTabChange == 7 && <PettyCash />}
        {selectIndexTabChange == 5 && <EmployeeSales />}
      </Grid>
    </Grid>
  );
};

export default SalesReport;
