import React, { useEffect, useState, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, FormHelperText } from "@material-ui/core";
import _ from "lodash";
import DataTable from "../../../components/DataTable";
import styles from "../../../styles/index";
import RightDrawer from "../../../components/RightDrawer";
import InputText from "../../../components/InputText";
import {
  fetchOrderTypes,
  createOrderTypes,
  editOrderTypes,
  deleteOrderTypes,
} from "../../../redux/actions";
import CirclePluse from "../../../components/SVG/PluseIcon";
import DeleteIcon from "../../../components/SVG/Delete";
import MyContext from "../../../Provider/MyContext";
import { H2, AvenirBlackH4, H5 } from "../../../utils/text";
import CloseCircle from "../../../components/SVG/CloseCircle";
import ExclamationIcon from "../../../components/SVG/ExclamationIcon";
import SingleSelect from "../../../components/SingleSelect";
import SnackBar from "../../../components/SnackBar";
import NoData from "../../../components/NoData";
import { validateText } from "../../../utils/util";
import AlertDialog from "../../../components/Dialog";

const OrderTypesList = () => {
  const style = styles();
  const Context = useContext(MyContext);
  const [columns, setColumns] = useState([
    {
      field: "otype_name",
      headerName: Context.langData.order_type,
      size: "25%",
    },
    {
      field: "description",
      headerName: Context.langData.description,
      size: "25%",
    },
    {
      field: "order_channel_name",
      headerName: Context.langData.order_channel,
      size: "25%",
    },
    {
      field: "delivery_mode_name",
      headerName: Context.langData.delivery_modes,
      size: "25%",
    },
  ]);
  const OrderTypes = useSelector((state) => state.metaReducer.ordertypes);
  const Taxes = useSelector((state) => state.settingsReducer.taxes);
  const [selectedId, setSelectedId] = React.useState([]);
  const [btnDisable, setBtnDisable] = React.useState(true);
  const [openCreate, toggleCreateDrawer] = useState(false);
  const [openEdit, toggleEditDrawer] = useState(false);
  const [openView, toggleViewDrawer] = useState(false);
  const [openSettings, toggleSettingsDrawer] = useState(false);
  const [row, setRow] = useState({});
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const [heading, setHeading] = useState(
    Context.langData.delete_ordertype_head
  );
  // const [bodyMessage, setBodyMessage] = useState(Context.langData.delete_otype_body);
  const OrderDeliveryMethods = useSelector(
    (state) => state.settingsReducer.orderDeliveryMethods
  );
  const [orderTypesData, setOrderTypesData] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [orders, setOrders] = useState([]);
  const bodyMessage =
    selectedId.length > 1
      ? `Are you sure you want to delete ${selectedId.length} order types?`
      : Context.langData.delete_otype_body;

  // useEffect(() => {
  //     setOrders(OrderTypes)
  // }, [OrderTypes])

  useEffect(() => {
    if (OrderTypes) {
      const oArray = [];
      OrderTypes.map((oItem) => {
        let deliveryModeName = "";
        let orderChannelName = "";
        let disableEdit = false;
        if (OrderDeliveryMethods && OrderDeliveryMethods.delivery_modes) {
          const orderDelivery = OrderDeliveryMethods.delivery_modes.filter(
            (e) => e.delivery_mode_id == oItem.delivery_mode_id
          );
          if (orderDelivery.length > 0) {
            deliveryModeName = orderDelivery[0].name;
          }
        }
        if (OrderDeliveryMethods && OrderDeliveryMethods.order_channels) {
          const orderChannel = OrderDeliveryMethods.order_channels.filter(
            (e) => e.order_channel_id == oItem.order_channel_id
          );
          if (orderChannel.length > 0) {
            orderChannelName = orderChannel[0].name;
          }
          if (oItem.order_channel_id == 3 || oItem.order_channel_id == 4) {
            disableEdit = true; // disable row if third-party or Kiosk
          }
        }

        const obj = {
          otype_name: oItem.name,
          id: oItem.id,
          description: oItem.description,
          delivery_mode_name: deliveryModeName,
          order_channel_name: orderChannelName,
          isDisabled: disableEdit,
        };
        oArray.push(obj);
      });
      setOrderTypesData(oArray);
      setOrders(oArray);
    }
  }, [OrderDeliveryMethods, OrderTypes]);
  function onCheckedIds(ids) {
    setSelectedId(ids);
    if (ids.length > 0) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  }
  function onClickAction(rowValue) {
    setRow(rowValue);
    if (rowValue.isDisabled) {
      toggleViewDrawer(true);
    } else {
      toggleEditDrawer(true);
    }
  }
  const btnProps = {};
  if (btnDisable) {
    btnProps.disabled = true;
  }
  function commanCallback() {
    toggleCreateDrawer(false);
    toggleEditDrawer(false);
    toggleViewDrawer(false);
    toggleSettingsDrawer(false);
    dispatch(fetchOrderTypes());
  }
  function createCallback(isSuccess) {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.ordertype_create_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.order_type_create_failed,
        severity: "error",
      });
    }
    commanCallback();
  }
  function editCallback(isSuccess) {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.ordertype_edit_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.order_type_edit_failed,
        severity: "error",
      });
    }
    commanCallback();
  }
  function deleteCallback(isSuccess, res) {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.ordertype_delete_succ,
        severity: "success",
      });
    } else {
      setSnackbar({ message: res.message, severity: "error" });
    }
    commanCallback();
  }
  const handleClose = (val) => {
    if (val) {
      dispatch(deleteOrderTypes({ otype_id_list: selectedId }, deleteCallback));
    }
    setShowDialog(false);
  };
  function deletePrinter(value) {
    if (selectedId && selectedId.length > 0) {
      setShowDialog(value);
    } else {
    }
  }
  const onPageinationChange = () => {
    console.log("on page change");
  };

  const sortingOnClick = (value, sortValue, index) => {
    const columnData = [...columns];
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setColumns(columnData);
    console.log(columnData, "columnDatasfsdf");
    const sortShifts = _.orderBy(
      orders,
      [(d) => d[value].toLowerCase()],
      [sortValue ? "desc" : "asc"]
    );
    setOrders(sortShifts);
    setOrderTypesData(sortShifts);
    console.log(sortShifts, "columnDatsadsadasfsdf");
  };

  return (
    <div data-qaid='otype_div'>
      {orderTypesData.length > 0 ? (
        <Grid
          data-qaid='kitchenList_grid'
          container
          direction='row'
          style={{ padding: 20 }}>
          <Grid
            data-qaid='kitchenList_buttongrid'
            item
            xs={12}
            sm={12}
            lg={12}
            style={{ marginTop: 24 }}>
            <>
              <Grid
                data-qaid='kitchenList_add grid'
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginRight: 20,
                  marginBottom: 10,
                }}>
                <Button
                  className={style.buttonClass}
                  data-qaid='otype_delete_button'
                  style={{ color: "white", marginRight: 10 }}
                  variant='text'
                  id='cancel'
                  onClick={() => deletePrinter(true)}>
                  <DeleteIcon
                    data-qaid='otype_deleteicon'
                    width={16}
                    height={16}
                    style={{ marginRight: 5 }}
                  />
                  <AvenirBlackH4
                    data-qaid='otype_delete_label'
                    label={Context.langData.delete}
                  />
                </Button>
                <Button
                  className={style.buttonClass}
                  data-qaid='otype_create_button'
                  style={{ backgroundColor: "#FFB600", color: "white" }}
                  variant='contained'
                  id='cancel'
                  onClick={() => toggleCreateDrawer(true)}>
                  <CirclePluse data-qaid='otype_createIcon' />
                  <AvenirBlackH4
                    data-qaid='otype_create_otype'
                    label={Context.langData.create_otypes}
                    style={{ paddingLeft: 5 }}
                  />
                </Button>
                {/* <ContextMenu style={{ marginLeft: 10 }} menuItems={ContextMenuData} callBack={callBack} /> */}
              </Grid>
              {OrderTypes.length > 0 ? (
                <DataTable
                  data-qaid='otype_datatable'
                  columns={columns}
                  rows={orderTypesData}
                  onCheckedIds={onCheckedIds}
                  onClickAction={onClickAction}
                  sortingOnClick={sortingOnClick}
                  onPageChange={onPageinationChange}
                />
              ) : (
                // <DataTable data-qaid="otype_datatable" columns={columns} rows={orderTypesData} onCheckedIds={onCheckedIds} onClickAction={onClickAction} onSettingsClick={onSettingsClick} onPageChange={onPageinationChange} />
                <div>
                  <AvenirBlackH4
                    data-qaid='kitchenList_nodata'
                    label={Context.langData.no_data}
                  />
                </div>
              )}
            </>
          </Grid>
        </Grid>
      ) : (
        <NoData page='order type' callback={() => toggleCreateDrawer(true)} />
      )}
      {openCreate && (
        <CreateOrderType
          data-qaid='otype_createottpe'
          open={openCreate}
          onClose={() => toggleCreateDrawer(false)}
          orderTypes={OrderTypes}
          taxes={Taxes}
          callback={createCallback}
        />
      )}
      {openEdit && (
        <EditOrderTypeDrawer
          data-qaid='otype_editOtype'
          open={openEdit}
          row={row}
          orderTypes={OrderTypes}
          taxes={Taxes}
          onClose={() => toggleEditDrawer(false)}
          callback={editCallback}
        />
      )}
      {openView && (
        <ViewOrderTypeDrawer
          data-qaid='otype_viewtype'
          open={openView}
          row={row}
          orderTypes={OrderTypes}
          taxes={Taxes}
          onClose={() => toggleViewDrawer(false)}
        />
      )}
      {/* {
                openSettings &&
                <SettingsOrderTypeDrawer
                    data-qaid="otype_settingsotype"
                    open={openSettings}
                    row={row}
                    payments={PaymentTypes}
                    onClose={() => toggleSettingsDrawer(false)}
                    callback={editCallback}
                />
            } */}
      {snackbar && (
        <SnackBar
          data-qaid='categories_snackbar'
          open
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
      <AlertDialog
        data-qaid='otype_alertOtype'
        showDialog={showDialog}
        body={bodyMessage}
        heading={heading}
        IsError={false}
        handleClose={handleClose}
      />
    </div>
  );
};
const CreateOrderType = ({ open, callback, onClose, orderTypes }) => {
  const userPermissions = useSelector(
    (state) => state.loginReducer.userPermissions
  );
  const Context = useContext(MyContext);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const style = styles();
  const [nameError, setNameError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [orderChaneleError, setOrderChanelError] = useState(null);
  const [orderDeliveryError, setOrderDeliveryError] = useState(null);
  const [ordertypeError, setOrderTypeerror] = useState(null);

  const [ordertype, setOrderType] = useState(0);
  const [tax, setTax] = useState(0);
  const [packageCharge, setPackageCharge] = useState(0);
  const [orderChannel, setOrderChannel] = useState(1);
  const [orderDeliveryMode, setOrderDeliveryMode] = useState(2);
  const OrderDeliveryMethods = useSelector(
    (state) => state.settingsReducer.orderDeliveryMethods
  );
  console.log(OrderDeliveryMethods, "My Orders");

  const getOrderChannels = useMemo(() => {
    const filterarray = OrderDeliveryMethods.order_channels.filter(
      (item) => item.name == "In Restaurant"
    );
    return filterarray;
  }, []);

  const getDeliveryModes = useMemo(() => {
    const filterarray = OrderDeliveryMethods.delivery_modes.filter(
      (item) => item.name == "Takeaway"
    );
    return filterarray;
  }, []);

  console.log(orderTypes, "orderTypessddsffd");
  const dispatch = useDispatch();
  const save = () => {
    setDescriptionError(null);
    let errorTag = 0;
    if (name) {
      setNameError(null);
    } else {
      errorTag += 1;
      setNameError(Context.langData.no_text_error);
    }
    const elementsIndex = orderTypes.findIndex(
      (indexElement) => indexElement.name.toUpperCase() == name.toUpperCase()
    );
    if (elementsIndex == -1) {
    } else {
      errorTag += 1;
      setNameError(`${name} already exist`);
    }

    if (orderChannel != 0) {
      setOrderChanelError(null);
    } else {
      errorTag += 1;
      setOrderChanelError(Context.langData.please_select_option);
    }
    if (orderDeliveryMode != 0) {
      setOrderDeliveryError(null);
    } else {
      errorTag += 1;
      setOrderDeliveryError(Context.langData.please_select_option);
    }
    if (errorTag == 0) {
      const obj = {
        description,
        copy_otype_id: ordertype != 0 ? ordertype : null,
        name,
        order_channel_id: orderChannel,
        delivery_mode_id: orderDeliveryMode,
      };
      dispatch(createOrderTypes(obj, callback));
    } else {
    }
  };
  const onHandleChangeOpDropDown = (event) => {
    setOrderTypeerror(null);
    setOrderType(parseInt(event.target.value));
  };

  const onHandleChangeOrderDelivery = (event) => {
    setOrderDeliveryError(null);
    setOrderDeliveryMode(parseInt(event.target.value));
  };
  const onHandleChangeOrderChannelMethod = (event) => {
    setOrderChanelError(null);
    setOrderChannel(parseInt(event.target.value));
  };
  const handleChangeName = (val) => {
    // const upper = val.charAt(0).toUpperCase() + val.slice(1);
    setNameError(null);
    setName(val);
  };
  const handleChangeDescription = (event) => {
    setDescriptionError(null);
    setDescription(event.target.value);
  };
  return (
    <RightDrawer open={open} onClose={onClose} padding={1}>
      <>
        <Grid
          style={{ display: "flex", height: "100%", flexDirection: "column" }}>
          <Grid
            style={{
              display: "flex",
              height: "80%",
              flexDirection: "column",
              overflowX: "auto",
            }}>
            <Grid
              data-qaid='assignkitchen_close_grid'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}>
              <CloseCircle
                data-qaid='assignkitchen_closeicon'
                size={20}
                onClick={onClose}
                style={{ marginRight: 30, marginTop: 20 }}
              />
            </Grid>
            <H2
              data-qaid='editkitchen_title'
              style={{ marginLeft: 32, marginBottom: 24 }}
              label={Context.langData.create_order_type}
            />
            <Grid
              direction='column'
              style={{ marginLeft: 32, marginRight: 32, width: 500 }}>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 24,
                }}>
                <AvenirBlackH4
                  data-qaid='editkitchen_passwordlabel'
                  label={Context.langData.order_type_name}
                  style={{ marginBottom: 5 }}
                />
                <InputText
                  value={name}
                  error={nameError}
                  maxLength={64}
                  onChange={(val) =>
                    handleChangeName(validateText(val.target.value))
                  }
                  id='name'
                />
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 24,
                }}>
                <AvenirBlackH4
                  data-qaid='editkitchen_passwordlabel'
                  label={Context.langData.description}
                  style={{ marginBottom: 5 }}
                />
                <InputText
                  value={description}
                  error={descriptionError}
                  maxLength={128}
                  onChange={(val) => handleChangeDescription(val)}
                  id='description'
                />
              </Grid>

              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 24,
                }}>
                <AvenirBlackH4
                  data-qaid='editkitchen_usernamelabel'
                  label={Context.langData.pick_order_channel}
                  style={{ marginBottom: 5 }}
                />
                <SingleSelect
                  list={[
                    { name: "Select order channel", order_channel_id: 0 },
                  ].concat(getOrderChannels)}
                  valueKey='order_channel_id'
                  displayKey='name'
                  value={orderChannel}
                  handleChange={onHandleChangeOrderChannelMethod}
                  border={false}
                  height={56}
                  borderStyle={
                    orderChaneleError
                      ? "1px solid #AB1212"
                      : "1px solid #004C60"
                  }
                  FormWidth='100%'
                />
                {orderChaneleError ? (
                  <FormHelperText
                    data-qaid='inputtext_formhelpertext'
                    style={{
                      color: "#AB1212",
                      display: "flex",
                      marginLeft: 0,
                    }}>
                    <ExclamationIcon
                      color='#AB1212'
                      style={{ marginRight: 5 }}
                    />
                    <H5
                      label={orderChaneleError}
                      style={{ color: "#AB1212 " }}
                    />
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 24,
                }}>
                <AvenirBlackH4
                  data-qaid='editkitchen_usernamelabel'
                  label={Context.langData.pick_delivery_modes}
                  style={{ marginBottom: 5 }}
                />
                <SingleSelect
                  list={[
                    { name: "Select delivery mode", delivery_mode_id: 0 },
                  ].concat(getDeliveryModes)}
                  valueKey='delivery_mode_id'
                  displayKey='name'
                  value={orderDeliveryMode}
                  handleChange={onHandleChangeOrderDelivery}
                  border={false}
                  height={56}
                  borderStyle={
                    orderDeliveryError
                      ? "1px solid #AB1212"
                      : "1px solid #004C60"
                  }
                  FormWidth='100%'
                />
                {orderDeliveryError ? (
                  <FormHelperText
                    data-qaid='inputtext_formhelpertext'
                    style={{
                      color: "#AB1212",
                      display: "flex",
                      marginLeft: 0,
                    }}>
                    <ExclamationIcon
                      color='#AB1212'
                      style={{ marginRight: 5 }}
                    />
                    <H5
                      label={orderDeliveryError}
                      style={{ color: "#AB1212 " }}
                    />
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 24,
                }}>
                <AvenirBlackH4
                  data-qaid='editkitchen_usernamelabel'
                  label={Context.langData.pick_item_price}
                  style={{ marginBottom: 5 }}
                />
                <SingleSelect
                  list={[
                    { name: Context.langData.select_order_type, otype_id: 0 },
                  ].concat(orderTypes)}
                  valueKey='otype_id'
                  displayKey='name'
                  value={ordertype}
                  handleChange={onHandleChangeOpDropDown}
                  border={false}
                  height={56}
                  FormWidth='100%'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{
              display: "flex",
              height: "20%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid
              data-qaid='editkitchen_action_grid'
              container
              style={{
                backgroundColor: "#FAFAFA",
                border: "1px solid #C3C3C3",
                marginTop: "100%",
              }}>
              <Grid
                data-qaid='editkitchen_cancel_btn'
                item
                xs={12}
                lg={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 32,
                  marginBottom: 32,
                }}>
                <Button
                  className={style.buttonClass}
                  onClick={onClose}
                  variant='contained'
                  style={{
                    backgroundColor: "#FFF",
                    marginRight: 16,
                    height: 40,
                    width: 103,
                    border: "2px solid #051D33",
                  }}>
                  <AvenirBlackH4
                    data-qaid='editkitchen_cancel_text'
                    label={Context.langData.cancel}
                  />
                </Button>
                <Button
                  className={style.buttonClass}
                  data-qaid='editkitchen_save_btn'
                  variant='contained'
                  style={{
                    backgroundColor: "#FFB600",
                    marginRight: 32,
                    height: 40,
                    width: 90,
                  }}
                  onClick={save}>
                  <AvenirBlackH4
                    data-qaid='editkitchen_save_text'
                    label={Context.langData.save}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    </RightDrawer>
  );
};

const EditOrderTypeDrawer = ({ open, callback, onClose, row }) => {
  const userPermissions = useSelector(
    (state) => state.loginReducer.userPermissions
  );
  const Context = useContext(MyContext);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [nameError, setNameError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [orderChaneleError, setOrderChanelError] = useState(null);
  const [orderDeliveryError, setOrderDeliveryError] = useState(null);
  const [ordertypeError, setOrderTypeerror] = useState(null);
  const [id, setId] = useState(0);
  const [tax, setTax] = useState(0);
  const [packageCharge, setPackageCharge] = useState(0);
  const dispatch = useDispatch();
  const [orderChannel, setOrderChannel] = useState(0);
  const [orderDeliveryMode, setOrderDeliveryMode] = useState(0);
  const [orderChannelList, setOrderChannelList] = useState([]);
  const [orderDeliveryModeList, setOrderDeliveryModeList] = useState([]);
  const OrderDeliveryMethods = useSelector(
    (state) => state.settingsReducer.orderDeliveryMethods
  );
  useEffect(() => {
    if (row) {
      const rowOrderChannel = getOrderChanel(row.order_channel_name);
      const rowDeliveryMode = getOrderDeliverymode(row.delivery_mode_name);

      const orderChannelList = OrderDeliveryMethods.order_channels.filter(
        (item) => item.name == "In Restaurant"
      );
      const orderDeliveryModeList = OrderDeliveryMethods.delivery_modes.filter(
        (item) => item.name == "Takeaway"
      );

      setId(row.id);
      setName(row.otype_name);
      setDescription(row.description);
      setOrderChannel(rowOrderChannel);
      setOrderDeliveryMode(rowDeliveryMode);

      setOrderChannelList(orderChannelList);
      setOrderDeliveryModeList(orderDeliveryModeList);
    }
  }, []);

  const filterThirdPartyChannel = (orderChannelsList, orderChannel) => {
    let filterArray = orderChannelsList;
    const filterList = ["In Restaurant"];

    // Filter out order channel if not the same as order type
    for (let i = 0; i < filterList.length; i++) {
      filterArray = filterArray.filter((item) => item.name != filterList[i]);
    }

    return filterArray;
  };

  const filterDeliveryMode = (deliveryModesList, orderDeliveryMode) => {
    // Filter out delivery channel if order type is not delivery
    const deliveryChannel = OrderDeliveryMethods.delivery_modes.find(
      (e) => e.name == "Delivery"
    );

    // Check delivery type id, then check ID based off of order type
    const deliveryID = deliveryChannel ? deliveryChannel.delivery_mode_id : 0;
    if (deliveryID !== orderDeliveryMode) {
      const filterArray = deliveryModesList.filter(
        (item) => item.name != "Delivery"
      );
      return filterArray;
    }

    return deliveryModesList;
  };

  const getOrderChanel = (value) => {
    let returnVal = 0;
    if (OrderDeliveryMethods && OrderDeliveryMethods.order_channels) {
      const filterarray = OrderDeliveryMethods.order_channels.filter(
        (item) => item.name == value
      );
      if (filterarray.length > 0) {
        returnVal = filterarray[0].order_channel_id;
      }
    }
    return returnVal;
  };
  const getOrderDeliverymode = (value) => {
    let returnVal = 0;
    if (OrderDeliveryMethods && OrderDeliveryMethods.delivery_modes) {
      const filterarray = OrderDeliveryMethods.delivery_modes.filter(
        (item) => item.name == value
      );
      if (filterarray.length > 0) {
        returnVal = filterarray[0].delivery_mode_id;
      }
    }
    return returnVal;
  };
  const save = () => {
    setDescriptionError(null);
    let errorTag = 0;
    if (name) {
      setNameError(null);
    } else {
      errorTag += 1;
      setNameError(Context.langData.no_text_error);
    }

    if (orderChannel != 0) {
      setOrderChanelError(null);
    } else {
      errorTag += 1;
      setOrderChanelError(Context.langData.please_select_option);
    }
    if (orderDeliveryMode != 0) {
      setOrderDeliveryError(null);
    } else {
      errorTag += 1;
      setOrderDeliveryError(Context.langData.please_select_option);
    }
    if (errorTag == 0) {
      const obj = {
        description,
        otype_id: id,
        name,
        order_channel_id: orderChannel,
        delivery_mode_id: orderDeliveryMode,
      };
      dispatch(editOrderTypes(obj, callback));
    } else {
    }
  };
  const onHandleChangeOrderDelivery = (event) => {
    setOrderDeliveryError(null);
    setOrderDeliveryMode(parseInt(event.target.value));
  };
  const onHandleChangeOrderChannelMethod = (event) => {
    setOrderChanelError(null);
    setOrderChannel(parseInt(event.target.value));
  };
  const handleChangeName = (val) => {
    setNameError(null);
    setName(val);
  };
  const handleChangeDescription = (event) => {
    setDescriptionError(null);
    setDescription(event.target.value);
  };
  return (
    <RightDrawer open={open} onClose={onClose} padding={1}>
      <>
        <Grid
          style={{ display: "flex", height: "100%", flexDirection: "column" }}>
          <Grid
            style={{
              display: "flex",
              height: "80%",
              flexDirection: "column",
              overflowX: "auto",
            }}>
            <Grid
              data-qaid='assignkitchen_close_grid'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}>
              <CloseCircle
                data-qaid='assignkitchen_closeicon'
                size={20}
                onClick={onClose}
                style={{ marginRight: 30, marginTop: 20 }}
              />
            </Grid>
            <H2
              data-qaid='editkitchen_title'
              style={{ marginLeft: 32, marginBottom: 24 }}
              label={Context.langData.edit_order_type}
            />
            <Grid
              direction='column'
              style={{ marginLeft: 32, marginRight: 32, width: 500 }}>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 24,
                }}>
                <AvenirBlackH4
                  data-qaid='editkitchen_passwordlabel'
                  label={Context.langData.order_type_name}
                  style={{ marginBottom: 5 }}
                />
                <InputText
                  value={name}
                  error={nameError}
                  maxLength={40}
                  onChange={(val) =>
                    handleChangeName(validateText(val.target.value))
                  }
                  id='name'
                />
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 24,
                }}>
                <AvenirBlackH4
                  data-qaid='editkitchen_passwordlabel'
                  label={Context.langData.description}
                  style={{ marginBottom: 5 }}
                />
                <InputText
                  value={description}
                  error={descriptionError}
                  maxLength={128}
                  onChange={(val) => handleChangeDescription(val)}
                  id='description'
                />
              </Grid>

              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 24,
                }}>
                <AvenirBlackH4
                  data-qaid='editkitchen_usernamelabel'
                  label={Context.langData.pick_order_channel}
                  style={{ marginBottom: 5 }}
                />
                <SingleSelect
                  list={[
                    { name: "Select order channel", order_channel_id: 0 },
                  ].concat(orderChannelList)}
                  valueKey='order_channel_id'
                  displayKey='name'
                  value={orderChannel}
                  handleChange={onHandleChangeOrderChannelMethod}
                  border={false}
                  height={56}
                  borderStyle={
                    orderChaneleError
                      ? "1px solid #AB1212"
                      : "1px solid #004C60"
                  }
                  FormWidth='100%'
                />
                {orderChaneleError ? (
                  <FormHelperText
                    data-qaid='inputtext_formhelpertext'
                    style={{
                      color: "#AB1212",
                      display: "flex",
                      marginLeft: 0,
                    }}>
                    <ExclamationIcon
                      color='#AB1212'
                      style={{ marginRight: 5 }}
                    />
                    <H5
                      label={orderChaneleError}
                      style={{ color: "#AB1212 " }}
                    />
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 24,
                }}>
                <AvenirBlackH4
                  data-qaid='editkitchen_usernamelabel'
                  label={Context.langData.pick_delivery_modes}
                  style={{ marginBottom: 5 }}
                />
                <SingleSelect
                  list={[
                    { name: "Select delivery mode", delivery_mode_id: 0 },
                  ].concat(orderDeliveryModeList)}
                  valueKey='delivery_mode_id'
                  displayKey='name'
                  value={orderDeliveryMode}
                  handleChange={onHandleChangeOrderDelivery}
                  border={false}
                  height={56}
                  borderStyle={
                    orderDeliveryError
                      ? "1px solid #AB1212"
                      : "1px solid #004C60"
                  }
                  FormWidth='100%'
                />
                {orderDeliveryError ? (
                  <FormHelperText
                    data-qaid='inputtext_formhelpertext'
                    style={{
                      color: "#AB1212",
                      display: "flex",
                      marginLeft: 0,
                    }}>
                    <ExclamationIcon
                      color='#AB1212'
                      style={{ marginRight: 5 }}
                    />
                    <H5
                      label={orderDeliveryError}
                      style={{ color: "#AB1212 " }}
                    />
                  </FormHelperText>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{
              display: "flex",
              height: "20%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid
              data-qaid='editkitchen_action_grid'
              container
              style={{
                backgroundColor: "#FAFAFA",
                border: "1px solid #C3C3C3",
                marginTop: "100%",
              }}>
              <Grid
                data-qaid='editkitchen_cancel_btn'
                item
                xs={12}
                lg={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 32,
                  marginBottom: 32,
                }}>
                <Button
                  onClick={onClose}
                  variant='contained'
                  style={{
                    backgroundColor: "#FFF",
                    marginRight: 16,
                    border: "2px solid #051D33",
                    height: 40,
                    width: 103,
                  }}>
                  <AvenirBlackH4
                    data-qaid='editkitchen_cancel_text'
                    label={Context.langData.cancel}
                  />
                </Button>
                <Button
                  data-qaid='editkitchen_save_btn'
                  variant='contained'
                  style={{
                    backgroundColor: "#FFB600",
                    marginRight: 32,
                    height: 40,
                    width: 90,
                  }}
                  onClick={save}>
                  <AvenirBlackH4
                    data-qaid='editkitchen_save_text'
                    label={Context.langData.save}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    </RightDrawer>
  );
};

const ViewOrderTypeDrawer = ({ open, callback, onClose, row }) => {
  const userPermissions = useSelector(
    (state) => state.loginReducer.userPermissions
  );
  const Context = useContext(MyContext);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [nameError, setNameError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [id, setId] = useState(0);
  const dispatch = useDispatch();
  const [orderChannel, setOrderChannel] = useState(0);
  const [orderDeliveryMode, setOrderDeliveryMode] = useState(0);
  const OrderDeliveryMethods = useSelector(
    (state) => state.settingsReducer.orderDeliveryMethods
  );
  useEffect(() => {
    if (row) {
      setId(row.id);
      setName(row.otype_name);
      setDescription(row.description);
      setOrderChannel(row.order_channel_name);
      setOrderDeliveryMode(row.delivery_mode_name);
    }
  }, []);

  return (
    <RightDrawer open={open} onClose={onClose} padding={1}>
      <>
        <Grid
          style={{ display: "flex", height: "100%", flexDirection: "column" }}>
          <Grid
            style={{
              display: "flex",
              height: "80%",
              flexDirection: "column",
              overflowX: "auto",
            }}>
            <Grid
              data-qaid='assignkitchen_close_grid'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}>
              <CloseCircle
                data-qaid='assignkitchen_closeicon'
                size={20}
                onClick={onClose}
                style={{ marginRight: 30, marginTop: 20 }}
              />
            </Grid>
            <H2
              data-qaid='editkitchen_title'
              style={{ marginLeft: 32, marginBottom: 24 }}
              label={Context.langData.order_type}
            />
            <Grid
              direction='column'
              style={{ marginLeft: 32, marginRight: 32, width: 500 }}>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 24,
                }}>
                <AvenirBlackH4
                  data-qaid='editkitchen_passwordlabel'
                  label={Context.langData.order_type_name}
                  style={{ marginBottom: 5 }}
                />
                <InputText
                  value={name}
                  maxLength={40}
                  id='name'
                  disabled='true'
                />
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 24,
                }}>
                <AvenirBlackH4
                  data-qaid='editkitchen_passwordlabel'
                  label={Context.langData.description}
                  style={{ marginBottom: 5 }}
                />
                <InputText
                  value={description}
                  maxLength={128}
                  id='description'
                  disabled='true'
                />
              </Grid>

              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 24,
                }}>
                <AvenirBlackH4
                  data-qaid='editkitchen_usernamelabel'
                  label={Context.langData.pick_order_channel}
                  style={{ marginBottom: 5 }}
                />
                <InputText
                  value={orderChannel}
                  maxLength={128}
                  id='orderChannel'
                  disabled='true'
                />
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 24,
                }}>
                <AvenirBlackH4
                  data-qaid='editkitchen_usernamelabel'
                  label={Context.langData.pick_delivery_modes}
                  style={{ marginBottom: 5 }}
                />
                <InputText
                  value={orderDeliveryMode}
                  maxLength={128}
                  id='orderDeliveryMode'
                  disabled='true'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{
              display: "flex",
              height: "20%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid
              data-qaid='editkitchen_action_grid'
              container
              style={{
                backgroundColor: "#FAFAFA",
                border: "1px solid #C3C3C3",
                marginTop: "100%",
              }}>
              <Grid
                data-qaid='editkitchen_cancel_btn'
                item
                xs={12}
                lg={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 32,
                  marginBottom: 32,
                }}>
                <Button
                  onClick={onClose}
                  variant='contained'
                  style={{
                    backgroundColor: "#FFF",
                    marginRight: 16,
                    border: "2px solid #051D33",
                    height: 40,
                    width: 103,
                  }}>
                  <AvenirBlackH4
                    data-qaid='editkitchen_cancel_text'
                    label={Context.langData.cancel}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    </RightDrawer>
  );
};

export default OrderTypesList;
