import * as React from "react";

const Line = (props) => (
  <svg
    width={1}
    height={23}
    viewBox='0 0 1 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path stroke='#EEE' d='M.5 0v23' />
  </svg>
);

export default Line;
