import React, { useEffect } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import { AvenirBlackH3, DotText } from "../utils/text";
import { RightArrow, DownArrow } from "./SVG";
import { CustomPagination, CustomCheckbox } from ".";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
}));
export default function CustomerDataTable(props) {
  const classes = useStyles();
  const [selectedId, setSelectedId] = React.useState([]);
  const [checkedAll, setCheckedAll] = React.useState(false);

  useEffect(() => {
    if (props.onCheckedIds) {
      props.onCheckedIds(selectedId);
    }
  }, [selectedId]);
  useEffect(() => {
    setSelectedId([]);
  }, [props.rows]);
  function checkIt(id) {
    const ids = [...selectedId];
    const index = ids.indexOf(id);
    if (index == -1) {
      ids.push(id);
    } else {
      ids.splice(index, 1);
    }
    setSelectedId(ids);
    if (props.rows.length == ids.length) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }
  function onClickAction(row, e) {
    props.onClickAction(row, e);
  }
  const handleSort = (name, desc, index) => {
    props.handleSort(name, desc, index);
  };
  return (
    <Grid
      data-qaid='customerDataTable_main_grid'
      container
      style={{ marginRight: 20, marginTop: 20 }}>
      <Grid
        data-qaid='customerDataTable_child_grid'
        container
        style={{
          marginRight: 20,
          flexDirextion: "row",
          display: "flex",
          justifyContent: "flex-end",
          height: 56,
        }}>
        <Grid container style={{ padding: 20, width: "5%" }} />
        <Grid
          data-qaid='customerDataTable_columns_data'
          container
          style={{
            display: "flex",
            flexDirextion: "row",
            justifyContent: "space-evenly",
            width: "94%",
          }}>
          {props.columns.map((column, index3) => (
            <Grid
              data-qaid='customerDataTable_column_child_grid'
              item
              xs={column.size}
              sm={column.size}
              md={column.size}
              lg={column.size}
              key={index3}
              style={{ display: "flex", flexDirection: "row", gap: "0 10px" }}>
              <AvenirBlackH3
                data-qaid='customerDataTable_column_label'
                label={column.headerName}
                style={{ marginLeft: 10 }}
              />
              {column.sort ? (
                // <ArrowsIcon data-qaid="customerDataTable_arrow_icon" style={{ marginRight: 15, cursor: 'pointer' }} onClick={() => {
                //     handleSort(column.field, !column.desc, index3)
                // }} /> :
                <Grid
                  onClick={() => {
                    handleSort(column.field, !column.desc, index3);
                  }}>
                  <DownArrow
                    data-qaid='customerDataTable_arrow_icon'
                    style={{ marginRight: 15, cursor: "pointer" }}
                  />
                </Grid>
              ) : (
                <Grid />
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
      {props.rows.map((row, index) => (
        <Grid
          data-qaid='customerDataTable_row_main_grid'
          container
          style={{
            marginBottom: 8,
            flexDirextion: "row",
            display: "flex",
            backgroundColor: "#FAFAFA",
            borderRadius: 5,
            height: 56,
            justifyContent: "center",
            position: "relative",
          }}>
          <Grid
            data-qaid='customerDataTable_row_checkbox_grid'
            container
            style={{ paddingLeft: 10, width: "5%" }}>
            <CustomCheckbox
              data-qaid='customerDataTable_row_checlbox'
              checked={selectedId.indexOf(row.id) !== -1}
              onChange={() => checkIt(row.id)}
              id={String(row.id)}
              marginLeft={0}
              // inputProps={{ 'aria-label': 'select all desserts' }}
              // style={{ color: selectedId.indexOf(row.id) !== -1 ? '#004C60' : '#C3C3C3' }}
            />
          </Grid>
          <Grid
            data-qaid='customerDataTable_row_items_grid'
            container
            onClick={(e) => {
              onClickAction(row, e);
            }}
            style={{
              display: "flex",
              flexDirextion: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              cursor: "pointer",
              paddingRight: 10,
              width: "94%",
              maxHeight: 56,
            }}>
            {props.columns.map((colItem, index3) => {
              const w = `${(colItem.size * 100) / 12}%`;
              return (
                <Grid
                  data-qaid='datatable_row_label_grid'
                  item
                  xs={colItem.size}
                  sm={colItem.size}
                  md={colItem.size}
                  lg={colItem.size}
                  key={index3}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingLeft:
                      props.columns.length - props.columns.length - 2 < index3
                        ? 10
                        : 0,
                    maxWidth: w,
                  }}>
                  {typeof row[colItem.field] === "string" ||
                  typeof row[colItem.field] === "number" ? (
                    <DotText
                      data-qaid='datatable_row_label'
                      label={String(row[colItem.field])}
                      style={{ textAlign: "left" }}
                    />
                  ) : (
                    <>{row[colItem.field]}</>
                  )}
                </Grid>
              );
            })}
            <Grid
              style={{
                cursor: "pointer",
                width: 20,
                position: "absolute",
                top: 10,
                right: 0,
              }}
              onClick={(e) => {
                onClickAction(row, e);
              }}>
              <RightArrow
                data-qaid='customerDataTable_rightarrow'
                data-id={row.id}
                data-name={row.name}
                id={row.id}
              />
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid
        data-qaid='customerDataTable_pagenavigation_grid'
        container
        style={{
          justifyContent: "center",
          display: "flex",
          marginTop: 25,
          marginBottom: 25,
          width: "100%",
        }}>
        <Grid data-qaid='customerDataTable_pagination_child_grid' item>
          <CustomPagination
            data-qaid='customerDataTable_pagination'
            total={props.total}
            offeset={props?.offset || 1}
            onChange={props.onPageChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
