import React, { useState, useContext, useMemo, useEffect } from "react";
import {
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  makeStyles,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { AvenirBlackH3, H4, AvenirBlackH4 } from "../../../../utils/text";
import MyContext from "../../../../Provider/MyContext";
import InfoOutlined from "../../../../components/SVG/InfoOutlined";
import SingleSelect from "../../../../components/SingleSelect";
import { fetchAllTaxProfiles } from "../../../../redux/actions";
import CustomCheckbox from "../../../../components/CustomCheckbox";

const useStyles = makeStyles((theme) => ({
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "#EEE",
      marginLeft: 10,
      marginRight: 10,
    },
  },
  radio: {
    "&$checked": {
      color: "#004C60",
    },
    fontSize: 14,
    "&.MuiFormControlLabel-label.MuiTypography-body1": {
      fontSize: 14,
    },
  },
  checked: {
    color: "#707272",
  },
  root: {
    fontSize: 14,
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  label: {
    fontSize: 14,
  },
  mainDiv: {
    backgroundColor: theme.palette.background.paper,
    marginTop: "2%",
  },
}));
const taxList = [
  { id: "category-tax", name: "Category tax" },
  { id: "custom-tax", name: "Custom Tax" },
  { id: "tax-free", name: "Tax-free" },
];

const CustomTaxMode = ({ from, info, setInfo, taxProfileIdErr }) => {
  console.log({ info });
  const classes = useStyles();
  const Context = useContext(MyContext);
  const showText = useMemo(() => {
    let text = "";
    if (info?.tax_mode === "category-tax" || info?.tax_mode === undefined) {
      text = Context.langData.category_tax_description;
    } else if (info?.tax_mode === "custom-tax") {
      text = Context.langData.tax_custom_description;
    } else if (info?.tax_mode === "tax-free") {
      text = Context.langData.tax_free_description;
    }
    return text;
  });
  const dispatch = useDispatch();
  const [taxProfileID, setTaxProfileID] = useState(null);
  const restId = useSelector((state) => state.loginReducer.selectedRestaurant);
  const taxProfiles = useSelector(
    (state) => state.taxConfigurationReducer.taxProfiles
  );
  useEffect(() => {
    if (info?.tax_mode === "custom-tax") {
      dispatch(fetchAllTaxProfiles({ id: restId.rest_id }));
      setTaxProfileID(info?.taxp_id);
    }
  }, [info]);

  const handleTaxProfile = (val) => {
    setInfo({ ...info, taxp_id: val.target.value });
    setTaxProfileID(val.target.value);
  };

  const handleTaxOptionChange = (value) => {
    if (value === "custom-tax") {
      dispatch(fetchAllTaxProfiles({ id: restId.rest_id }));
    }
    if (value === "tax-free") {
      setInfo({
        ...info,
        tax_mode: "tax-free",
        tax_inclusive: false,
        taxp_id: null,
      });
    } else if (value === "category-tax") {
      setInfo({
        ...info,
        tax_mode: "category-tax",
        taxp_id: null,
      });
    } else if (value === "custom-tax") {
      setInfo({
        ...info,
        tax_mode: "custom-tax",
        taxp_id: taxProfileID,
      });
    }
  };
  const handleTaxInclusive = (event) => {
    setInfo({
      ...info,
      tax_inclusive: event.target.checked,
    });
  };

  return (
    <Grid container className='taxMode '>
      <Grid
        container
        style={{
          marginTop: 30,
          borderBottom: "1px solid #EEE",
          paddingBottom: from === "combo" ? 0 : 20,
        }}>
        <Grid item xs={8}>
          <AvenirBlackH3 label='Tax' />
        </Grid>
      </Grid>

      <RadioGroup
        data-qaid='tax-list'
        row
        aria-label='position'
        name='position'
        value={info?.tax_mode}
        onChange={(e) => handleTaxOptionChange(e.target.value)}
        style={{
          width: "100%",
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
        }}
        classes={{
          root: classes.radio,
          label: classes.label,
        }}>
        {taxList.length > 0 &&
          taxList.map((taxType, index) => (
            <FormControlLabel
              data-qaid='tax-list'
              value={taxType.id}
              control={
                <Radio
                  key={index}
                  classes={{
                    root: classes.radio,
                    label: classes.label,
                  }}
                />
              }
              label={taxType.name}
            />
          ))}
      </RadioGroup>
      <Grid
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 15,
          backgroundColor: "#F3FAFD",
          width: "100%",
          height: "auto",
          alignItems: "flex-start",
          borderRadius: 5,
          marginTop: 24,
          marginBottom: 32,
        }}>
        <InfoOutlined width={24} height={24} color='#004C60' />
        <Grid style={{ width: "90%", marginLeft: 10 }}>
          <H4 label={showText} style={{ lineHeight: "24px" }} />
        </Grid>
      </Grid>
      {info?.tax_mode === "custom-tax" && (
        <Grid
          item
          data-qaid='SingleSelectView'
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}>
          <AvenirBlackH4
            data-qaid='tax_profile_select'
            label={Context.langData.tax_profile}
            style={{ marginBottom: 5 }}
          />
          <SingleSelect
            list={taxProfiles}
            value={taxProfileID}
            valueKey='id'
            displayKey='name'
            border={false}
            handleChange={handleTaxProfile}
            FormWidth='100%'
            height={56}
            width='100%'
          />
        </Grid>
      )}

      {info?.tax_mode !== "tax-free" && (
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "space-between",
            marginTop: "5%",
          }}>
          <CustomCheckbox
            checkedColor='#004C60'
            checked={info?.tax_inclusive}
            onChange={handleTaxInclusive}
            label='Tax inclusive'
            name='tax-inclusive'
          />
          <Grid style={{ marginLeft: "5%" }}>
            <H4
              label={Context.langData.tax_inclusive_description}
              style={{
                width: "100%",
                whiteSpace: "pre-line",
              }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(CustomTaxMode);
