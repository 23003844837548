import React from "react";
import { Typography, CssBaseline, Container } from "@material-ui/core";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      data-qaid='tabpanel_main_div'
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <React.Fragment data-qaid='tabpanel_react_fragment'>
          <CssBaseline data-qaid='tabpanel_cssbaseline' />
          <Container data-qaid='tabpanel_container' fixed>
            <Typography
              data-qaid='tabpanel_childer'
              component='div'
              style={{ backgroundColor: "#f5f6f8", height: "100vh" }}>
              {children}
            </Typography>
          </Container>
        </React.Fragment>
      )}
    </div>
  );
};
export default TabPanel;
