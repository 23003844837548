import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  makeStyles,
  Grid,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import MyContext from "../../Provider/MyContext";
import Page from "../../components/Page";
import { resetPassword } from "../../redux/actions";
import { H4, AvenirBlackH4, H2, H6, H5 } from "../../utils/text";
import styles from "../../styles/index";
import LoginLogo from "../../components/SVG/LoginLogo";
import CircleClose from "../../components/SVG/CircleClose";
import CircleCheck from "../../components/SVG/CircleCheck";
import Message from "../../components/Message";
import InputText from "../../components/InputText";
import ExclamationIcon from "../../components/SVG/ExclamationIcon";
import OpenEyeIcon from "../../components/SVG/OpenEyeIcon";

// import CircleCheck from '../../components/SVG/CircleCheck';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F3FAFD",
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const PaasswordReset = () => {
  const style = styles();
  const { token } = useParams();
  const location = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginDetails = useSelector((state) => state.loginReducer.loginDetails);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "success",
    msg: "",
  });
  const Context = useContext(MyContext);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const [showResetSuccess, setShowResetSuccess] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorText, setErrorText] = useState(false);
  const [hidePassword, setHidePassword] = useState(false);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(false);
  const [PwdRequirmentList, setPwdRequirmentList] = useState([
    { key: 1, value: Context.langData.eleven_characters, check: false },
    { key: 2, value: Context.langData.one_uppercase, check: false },
    { key: 3, value: Context.langData.one_lowercase, check: false },
    { key: 4, value: Context.langData.one_number, check: false },
    {
      key: 5,
      value: Context.langData.one_special_case_chatacter,
      check: false,
    },
  ]);

  const loginFailedCallback = (value, data) => {
    let d = "";
    if (value) {
      // navigate('/');
      setShowResetSuccess(true);
      sessionStorage.clear();
      localStorage.clear();
      d = { open: true, severity: "success", msg: data };
    } else {
      d = { open: true, severity: "error", msg: data };
    }
    setAlertData(d);
    setTimeout(() => {
      setAlertData({ open: false, severity: "success", msg: "" });
    }, 3000);
  };
  useEffect(() => {
    if (!showResetSuccess) {
      const myInput = document.getElementById("newPassword");
      myInput.onpaste = (e) => e.preventDefault();
    }
  }, []);

  const handleResetPwd = () => {
    if (newPassword == "" || confirmNewPassword == "") {
      setErrorText(true);
    } else {
      setErrorText(false);
      if (newPassword !== confirmNewPassword) {
        setErrorText(true);
      } else {
        setErrorText(false);
        const _checkPwd = PwdRequirmentList.filter((e) => e.check == false);
        if (_checkPwd.length > 0) {
          setErrorText(true);
        } else {
          setErrorText(false);
          dispatch(
            resetPassword(
              {
                password: newPassword,
                token: location.search.replace("?token=", ""),
              },
              loginFailedCallback
            )
          );
        }
      }
    }
  };

  setTimeout(() => {
    if (showResetSuccess) {
      navigate("/");
    }
  }, 30000);

  const handleContinueLogin = () => {
    navigate("/");
  };

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
    const list = [...PwdRequirmentList];
    const val = e.target.value;
    list.map((i) => {
      switch (i.key) {
        case 1:
          i.check = hasLength(val);
          break;
        case 2:
          i.check = hasUpper(val);
          break;
        case 3:
          i.check = hasLower(val);
          break;
        case 4:
          i.check = hasNumber(val);
          break;
        case 5:
          i.check = hasSpecial(val);
          break;
        default:
          i.check = false;
      }
    });
    setPwdRequirmentList(list);
  };
  const hasLength = (val) => val.length >= 11;
  const hasUpper = (val) => !!val.match(/[A-Z]+/g);
  const hasLower = (val) => !!val.match(/[a-z]+/g);
  const hasNumber = (val) => !!val.match(/\d+/g);
  const hasSpecial = (val) =>
    !!val.match(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+/g);
  return (
    <Page
      data-qaid='loginView_page'
      className={classes.root}
      title={Context.langData.password_reset}>
      <Box
        data-qaid='loginView_box'
        display='flex'
        flexDirection='column'
        height='100%'
        justifyContent='center'>
        <Container data-qaid='loginView_container' maxWidth='sm'>
          {!showResetSuccess ? (
            // <Formik
            //     data-qaid="loginView_formik"
            //     initialValues={{
            //         password: '',
            //         newPassword: '',
            //     }}
            //     validationSchema={Yup.object().shape({
            //         newPassword: Yup.string().max(255).required(Context.langData.password_required_error)
            //             .matches(
            //             /^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.{11,})/, Context.langData.password_validation_error
            //             ),
            //         password: Yup.string().max(255).required(Context.langData.password_required_error)
            //             .matches(
            //             /^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.{11,})/, Context.langData.password_validation_error
            //             ),
            //         newPassword: Yup.string()
            //             .oneOf([Yup.ref('password'), null], Context.langData.resetPassword_match_check)
            //             .required(Context.langData.resetPassword_match_check)
            //         // newPassword: Yup.string().when("password", {
            //         //     is: val => (val && val.length > 0 ? true : false),
            //         //     then: Yup.string().oneOf(
            //         //         [Yup.ref("password")],
            //         //         Context.langData.resetPassword_match_check
            //         //     )
            //         // })
            //     })}
            //     onSubmit={(values) => {
            //         dispatch(resetPassword({ password: values.password, token: location.search.replace("?token=", "") }, loginFailedCallback))
            //     }}
            // >
            //     {({
            //         errors,
            //         handleBlur,
            //         handleChange,
            //         handleSubmit,
            //         isSubmitting,
            //         touched,
            //         values
            //     }) => (
            //         <form onSubmit={handleSubmit}>

            //             <Grid data-qaid="loginView_formik_main_grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
            //                 <LoginLogo />
            //                 {/* <CircleCheck height={24} width={24}/> */}
            //             </Grid>

            //             <Grid data-qaid="loginView_formik_main_grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 56 }}>
            //                 <H6 label={Context.langData.password_reset} />
            //             </Grid>

            //             <Grid data-qaid="loginView_formik_email_grid" style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
            //                 <AvenirBlackH4 data-qaid="loginView_formik_eamillabel" label={Context.langData.new_password} style={{ color: '#051D33' }}> </AvenirBlackH4>
            //                 <InputText
            //                     data-qaid="loginView_formik_email_input"
            //                     error={touched.password && errors.password}
            //                     fullWidth
            //                     helperText={touched.password && errors.password}
            //                     errorIcon={false}
            //                     margin="normal"
            //                     name="password"
            //                     onBlur={handleBlur}
            //                     onChange={handleChange}
            //                     value={values.password}
            //                     variant="outlined"
            //                     type="password"
            //                 />
            //                 <AvenirBlackH4 label={Context.langData.password_must_contain_at_least}/>
            //                 <Grid style={{flexDirection:'row', alignItems: 'center', display: 'flex', paddingTop: 5}}>
            //                     <CircleClose height={24} width={24} color={'#AB1212'}/>
            //                     <H4 label={Context.langData.eleven_characters} style={{paddingLeft: 10}}/>
            //                 </Grid>
            //                 <Grid style={{flexDirection:'row', alignItems: 'center', display: 'flex', paddingTop: 5}}>
            //                     <CircleClose height={24} width={24} color={'#AB1212'}/>
            //                     <H4 label={Context.langData.one_uppercase} style={{paddingLeft: 10}}/>
            //                 </Grid>
            //                 <Grid style={{flexDirection:'row', alignItems: 'center', display: 'flex', paddingTop: 5}}>
            //                     <CircleClose height={24} width={24} color={'#AB1212'}/>
            //                     <H4 label={Context.langData.one_lowercase} style={{paddingLeft: 10}}/>
            //                 </Grid>
            //                 <Grid style={{flexDirection:'row', alignItems: 'center', display: 'flex', paddingTop: 5}}>
            //                     <CircleClose height={24} width={24} color={'#AB1212'}/>
            //                     <H4 label={Context.langData.one_number} style={{paddingLeft: 10}}/>
            //                 </Grid>
            //                 <Grid style={{flexDirection:'row', alignItems: 'center', display: 'flex', paddingTop: 5}}>
            //                     <CircleClose height={24} width={24} color={'#AB1212'}/>
            //                     <H4 label={Context.langData.one_special_case_chatacter} style={{paddingLeft: 10}}/>
            //                 </Grid>
            //             </Grid>
            //             <Grid data-qaid="loginView_formik_password_grid" style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', marginTop: 30 }}>
            //                 <AvenirBlackH4 data-qaid="loginView_formik_password_label" label={Context.langData.confirm_new_password} style={{ color: '#051D33' }}> </AvenirBlackH4>
            //                 <InputText
            //                     data-qaid="loginView_formik_password_input"
            //                     error={touched.newPassword && errors.newPassword}
            //                     fullWidth
            //                     helperText={touched.newPassword && errors.newPassword}
            //                     margin="normal"
            //                     name="newPassword"
            //                     onBlur={handleBlur}
            //                     onChange={handleChange}
            //                     type="password"
            //                     id="newpassword"
            //                     value={values.newPassword}
            //                     variant="outlined"
            //                 />
            //             </Grid>
            //             <Box data-qaid="loginView_formik_action_box" my={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 32 }}>
            //                 <Button
            //                     className={style.buttonClass}
            //                     data-qaid="loginView_formik_login_button"
            //                     style={{ backgroundColor: '#FFB600' }}
            //                     type="submit"
            //                     variant="contained"
            //                 >
            //                     <AvenirBlackH4 data-qaid="loginView_formik_login_label" label={Context.langData.reset} />
            //                 </Button>
            //             </Box>

            //         </form>
            //     )}
            // </Formik>
            <Grid>
              <Grid
                data-qaid='loginView_formik_main_grid'
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 20,
                }}>
                <LoginLogo />
                {/* <CircleCheck height={24} width={24}/> */}
              </Grid>

              <Grid
                data-qaid='loginView_formik_main_grid'
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 56,
                }}>
                <H6 label={Context.langData.password_reset} />
              </Grid>

              <Grid
                data-qaid='editOffer_namegrid'
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 10,
                  width: "70%",
                  marginLeft: "15%",
                }}>
                <AvenirBlackH4
                  data-qaid='loginView_formik_eamillabel'
                  label={Context.langData.new_password}
                  style={{ color: "#051D33" }}>
                  {" "}
                </AvenirBlackH4>
                <InputText
                  data-qaid='editOffer_nameip'
                  error={errorText}
                  onChange={(val) => handleNewPassword(val)}
                  id='newPassword'
                  fullWidth
                  name='newPassword'
                  type={hidePassword ? "text" : "password"}
                  variant='outlined'
                  errorIcon={false}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => {
                          setHidePassword(!hidePassword);
                        }}
                        edge='end'>
                        {hidePassword ? <OpenEyeIcon /> : <OpenEyeIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <AvenirBlackH4
                  label={Context.langData.password_must_contain_at_least}
                  style={{ paddingTop: 20 }}
                />
                {PwdRequirmentList.map((i) => (
                  <Grid
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      display: "flex",
                      paddingTop: 10,
                    }}>
                    {i.check ? (
                      <CircleCheck height={24} width={24} />
                    ) : (
                      <CircleClose height={24} width={24} color='#AB1212' />
                    )}
                    <H4 label={i.value} style={{ paddingLeft: 10 }} />
                  </Grid>
                ))}
              </Grid>

              <Grid
                data-qaid='editOffer_namegrid'
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 10,
                  width: "70%",
                  marginLeft: "15%",
                  marginTop: "5%",
                }}>
                <AvenirBlackH4
                  data-qaid='loginView_formik_password_label'
                  label={Context.langData.confirm_new_password}
                  style={{ color: "#051D33" }}>
                  {" "}
                </AvenirBlackH4>
                <InputText
                  data-qaid='editOffer_nameip'
                  error={errorText}
                  onChange={(val) => setConfirmNewPassword(val.target.value)}
                  id='confirmNewPassword'
                  fullWidth
                  name='confirmNewPassword'
                  type={hideConfirmPassword ? "text" : "password"}
                  variant='outlined'
                  errorIcon={false}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => {
                          setHideConfirmPassword(!hideConfirmPassword);
                        }}
                        edge='end'>
                        {hideConfirmPassword ? (
                          <OpenEyeIcon />
                        ) : (
                          <OpenEyeIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errorText && (
                  <Grid
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      display: "flex",
                    }}>
                    <ExclamationIcon
                      color='#AB1212'
                      style={{ marginRight: 5 }}
                    />
                    <H5
                      label={Context.langData.resetPassword_match_check}
                      style={{ color: "#AB1212" }}
                    />
                  </Grid>
                )}
              </Grid>

              <Box
                data-qaid='loginView_formik_action_box'
                my={2}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 32,
                }}>
                <Button
                  className={style.buttonClass}
                  data-qaid='loginView_formik_login_button'
                  style={{ backgroundColor: "#FFB600", width: 96 }}
                  type='submit'
                  onClick={handleResetPwd}
                  variant='contained'>
                  <AvenirBlackH4
                    data-qaid='loginView_formik_login_label'
                    label={Context.langData.reset}
                  />
                </Button>
              </Box>
            </Grid>
          ) : (
            <Grid
              style={{
                border: "1px solid #EEEEEE",
                height: 338,
                width: 521,
                borderRadius: 5,
                backgroundColor: "#FFF",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                padding: 30,
              }}>
              <CircleCheck />
              <H2
                label={Context.langData.password_reset_successfully}
                style={{ paddingTop: 30 }}
              />
              <H4
                label={Context.langData.redirected_to_main_sign_in_page}
                style={{ paddingTop: 20, textAlign: "center" }}
              />
              <Button
                className={style.buttonClass}
                data-qaid='loginView_formik_login_button'
                style={{ backgroundColor: "#FFB600", marginTop: 30 }}
                type='submit'
                onClick={handleContinueLogin}
                variant='contained'>
                <AvenirBlackH4
                  data-qaid='loginView_formik_login_label'
                  label={Context.langData.continue}
                />
              </Button>
            </Grid>
          )}
        </Container>
      </Box>
      <Message
        data-qaid='loginView_formik_message'
        open={alertData.open}
        severity={alertData.severity}
        msg={alertData.msg}
      />
    </Page>
  );
};
export default PaasswordReset;
