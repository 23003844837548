import * as React from "react";
// import Svg, { G, Path, Defs, ClipPath } from "react-native-svg"

const ExclamationIcon = ({ color = "#051D33", ...props }) => (
  <svg
    width={16}
    height={16}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <g clipPath='url(#prefix__clip0)' fill={color}>
      <path d='M8 9.5c.273 0 .5-.227.5-.5V4a.5.5 0 10-1 0v5c0 .273.227.5.5.5z' />
      <path d='M15.5 6.793L9.207.5a1.726 1.726 0 00-.554-.373 1.707 1.707 0 00-1.306 0A1.726 1.726 0 006.793.5L.5 6.793a1.705 1.705 0 000 2.414L6.793 15.5a1.705 1.705 0 002.414 0L15.5 9.207a1.705 1.705 0 000-2.413zM14.793 8.5L8.5 14.793a.725.725 0 01-1 0L1.207 8.5a.706.706 0 010-1L7.5 1.207a.69.69 0 01.767-.153.676.676 0 01.233.153L14.793 7.5a.706.706 0 010 1z' />
      <path d='M7.999 11.98a.733.733 0 100-1.467.733.733 0 000 1.467z' />
    </g>
    <defs>
      <cipPath id='prefix__clip0'>
        <path fill={color} d='M0 0h16v16H0z' />
      </cipPath>
    </defs>
  </svg>
);

export default ExclamationIcon;
