import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Button, Menu, MenuItem } from "@material-ui/core";
import moment from "moment";
import _ from "lodash";
import { H2, H3, H6, AvenirBlackH4 } from "../../../utils/text";
import MultiSelect from "../../../components/MultiSelect";
import MyContext from "../../../Provider/MyContext";
import ReportsTable from "../../../components/ReportsTable";
import Daterange from "../../../components/DateRange";

import { fetchPurchasesData } from "../../../redux/actions";
import CircleClose from "../../../components/SVG/CircleClose";
import {
  ConvertPrice,
  ConvertCurrencyPriceToDollar,
} from "../../../utils/util";
import { downloadPurchasesPdfDocument } from "../../../components/PDFTableGenerator";
import DownlodeIcon from "../../../components/SVG/DownlodeIcon";
import { downloadPurchasesReport } from "../../../utils/excel";

const Purchases = () => {
  const Context = useContext(MyContext);
  const [table, setTable] = useState([]);
  const purchasesData = useSelector(
    (state) => state.inventoryReportReducer.purchases
  );
  const [date, setDate] = useState({});
  const dispatch = useDispatch();
  const [vendors, setVendors] = useState([]);
  const [resetDate, toggleResetDate] = useState(false);
  const vendorData = useSelector((state) => state.inventory.vendors);
  const [purchaseColumns, setPurchaseColumns] = useState([
    {
      field: "po_id",
      display: Context.langData.purchase_order_id,
      size: "15%",
    },
    { field: "bill_no", display: Context.langData.bill_No, size: "15%" },
    { field: "date", display: Context.langData.date_received, size: "20%" },
    {
      field: "total_amount",
      display: Context.langData.amount,
      align: "right",
      size: "15%",
      isNumber: true,
    },
    { field: "vendor_name", display: Context.langData.vendor, size: "15%" },
    {
      field: "amount_paid",
      display: Context.langData.payment,
      align: "right",
      size: "15%",
      isNumber: true,
    },
    {
      field: "balance_amount",
      display: Context.langData.balance,
      align: "right",
      size: "15%",
      isNumber: true,
    },
  ]);
  const [transactions, setTransactions] = useState([]);
  const [amounts, setAmounts] = useState([0, 0, 0]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleApplyVendorData();
    }, 100);
    return () => clearTimeout(timeout);
  }, [date, vendors]);

  const onDateChange = (d) => {
    if (date.from_date !== d.startDate || date.to_date !== d.endDate) {
      setDate({
        from_date: d.startDate,
        to_date: d.endDate,
      });
    }
  };

  const handleApplyVendorData = () => {
    let obj = { ...date };
    if (vendors.length > 0) {
      obj = {
        ...obj,
        vendor_ids: vendors.join(","),
      };
    } else {
      obj = {
        ...obj,
        vendor_ids: "all",
      };
    }
    dispatch(fetchPurchasesData(obj));
  };
  const handleClearVendorData = () => {
    setVendors([]);
    toggleResetDate(true);
    setTimeout(() => {
      toggleResetDate(false);
    }, 200);
  };
  useEffect(() => {
    const parsed_data = [];
    let total = 0;
    let paid = 0;
    if (purchasesData?.data?.length > 0) {
      purchasesData.data.map((fulfill) => {
        console.log("fulfill==>", fulfill);
        parsed_data.push({
          po_id: fulfill.purchaseOrderId,
          bill_no: fulfill.billNo,
          date: fulfill.dateReceived,
          total_amount: ConvertCurrencyPriceToDollar(fulfill.totalAmount),
          vendor_name: fulfill.vendorName,
          amount_paid: ConvertCurrencyPriceToDollar(fulfill.paidAmount),
          balance_amount: ConvertCurrencyPriceToDollar(fulfill.balanceAmount),
        });
        total += fulfill.totalAmount.amount;
        paid += fulfill.paidAmount.amount;
      });
      setTable(parsed_data);
      const total_amount = total / 100.0;
      const amount_paid = paid / 100.0;
      const balance = total_amount - amount_paid;
      setAmounts([total_amount, amount_paid, balance]);
    } else {
      setTable([]);
      setAmounts([0, 0, 0]);
    }

    console.log("parsed_data==>", parsed_data);
    console.log("table==>", table);
    console.log("amount==>", amounts);
  }, [purchasesData]);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const downloadExcel = () => {
    const purchasesSummary = {
      total_amount: amounts[0],
      amount_paid: amounts[1],
      balance: amounts[2],
    };
    downloadPurchasesReport(table, purchasesSummary);
    handleCloseMenu();
  };
  const downloadPDF = () => {
    const reportDate = {
      start_date: moment(date.from_date).unix(),
      end_date: moment(date.to_date).unix(),
    };
    const dataToPDF = [{ data: table, headers: purchaseColumns }];
    const purchasesSummary = {
      total_amount: amounts[0],
      amount_paid: amounts[1],
      balance: amounts[2],
    };
    downloadPurchasesPdfDocument(
      // purchaseColumns,
      // table,
      dataToPDF,
      Context.langData.purchases,
      reportDate,
      "PurchasesInventory",
      purchasesSummary
    );
    handleCloseMenu();
  };
  const handleSort = (name, desc, index, catIndx) => {
    const columnData = [...purchaseColumns];
    columnData[index] = { ...columnData[index], desc };
    setPurchaseColumns(columnData);
    let sortItemwiseSales;
    if (
      name == "total_amount" ||
      name == "amount_paid" ||
      name == "balance_amount" ||
      name == "po_id"
    ) {
      sortItemwiseSales = _.orderBy(
        table,
        [(catItem) => catItem[name]],
        [desc ? "desc" : "asc"]
      );
    } else {
      sortItemwiseSales = _.orderBy(
        table,
        [(catItem) => catItem[name].toLowerCase()],
        [desc ? "desc" : "asc"]
      );
    }
    setTable(sortItemwiseSales);
  };

  return (
    <Grid
      data-qaid='vendors_mainContainer'
      container
      style={{ paddingLeft: 20, paddingRight: 20 }}>
      <Grid
        data-qaid='vendors_subContainer1'
        container
        style={{
          flexDirection: "row",
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <Grid
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}>
          <Grid data-qaid='vendors_dateGrid' item style={{}}>
            <AvenirBlackH4
              data-qaid='vendors_dateLabel'
              label={Context.langData.from_to}
            />
            <Daterange
              data-qaid='vendors_dateRange'
              onDateChange={onDateChange}
              refresh={resetDate}
            />
          </Grid>

          <Grid
            data-qaid='vendors_selectOrderView'
            item
            style={{ marginLeft: 10 }}>
            <AvenirBlackH4
              data-qaid='vendors_selectOrderLabel'
              label={Context.langData.select_vendor}
            />
            <MultiSelect
              data-qaid='vendors_setVendorsGrid'
              list={vendorData}
              value='vendor_id'
              name='name'
              placeholder={Context.langData.all_vendors}
              selectedValue={vendors}
              handleChange={(e) => setVendors(e)}
              border={false}
              height={56}
              width={298}
            />
          </Grid>
        </Grid>

        <Grid
          item
          style={{
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-end",
            marginLeft: 20,
          }}>
          <Grid
            data-qaid='vendors_clearContainer'
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: 10,
              alignItems: "center",
              cursor: "pointer",
            }}>
            <CircleClose
              data-qaid='vendors_closeIcon'
              onClick={() => handleClearVendorData()}
            />
            <AvenirBlackH4
              data-qaid='vendors_clearLabel'
              label={Context.langData.clear}
              style={{ marginLeft: 5 }}
            />
          </Grid>
          <Button
            onClick={(event) => setAnchorEl(event.currentTarget)}
            data-qaid='kitchens_downlodeLabelView'
            style={{
              backgroundColor: "#FFB600",
              height: 40,
              marginRight: 0,
              width: 149,
            }}
            variant='contained'
            id='cancel'>
            <DownlodeIcon
              data-qaid='consolodated_index_h1_downlodeicon'
              style={{ marginRight: 10 }}
            />
            <AvenirBlackH4
              data-qaid='kitchens_downlodeLabel'
              label={Context.langData.download}
            />
          </Button>
          <Menu
            id='simple-menu-download'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}>
            <MenuItem onClick={downloadExcel}>
              {Context.langData.export_excel}
            </MenuItem>
            <MenuItem onClick={downloadPDF}>
              {Context.langData.export_pdf}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      <Grid
        data-qaid='purchases_header_child2'
        container
        lg={12}
        style={{
          justifyContent: "space-between",
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          paddingLeft: 36,
          paddingRight: 36,
          marginTop: 20,
        }}>
        <Grid item style={{ flexDirection: "row", display: "flex" }}>
          <Grid data-qaid='purchases_total_grid'>
            <PriceTag
              data-qaid='purchases_total_pricetag'
              amount={`$${ConvertPrice(amounts[0])}`}
              label={Context.langData.total_purchases}
            />
          </Grid>
          <Grid
            data-qaid='purchases_amountpaid_grid'
            style={{ paddingLeft: 50 }}>
            <PriceTag
              data-qaid='purchases_amountpaid_pricetag'
              amount={`$${ConvertPrice(amounts[1])}`}
              label={Context.langData.paid}
            />
          </Grid>
        </Grid>
        <Grid
          data-qaid='purchases_balance_grid'
          item
          style={{
            display: "flex",
            flexDirection: "column",
            paddingRight: "10%",
          }}>
          <H6
            data-qaid='purchases_priceTage_label'
            label={`$${ConvertPrice(amounts[2])}`}
          />
          <H3
            data-qaid='purchases_balance_pricetag'
            label={Context.langData.total_balance}
          />
        </Grid>
      </Grid>

      <Grid
        data-qaid='purchases_reportsTableView'
        item
        xs={12}
        lg={12}
        style={{ marginTop: 20 }}>
        <ReportsTable
          data-qaid='purchases_reportsTable'
          columns={purchaseColumns}
          rows={table}
          handleSort={(name, desc, colIndex) =>
            handleSort(name, desc, colIndex)
          }
        />
      </Grid>
    </Grid>
  );
};
const PriceTag = ({ amount, label, classes }) => (
  <Grid data-qaid='purchases_priceTage_grid' container direction='column'>
    <H2 data-qaid='purchases_priceTage_amount_label' label={amount} />
    <AvenirBlackH4
      data-qaid='purchases_priceTage_label'
      label={label}
      style={{ color: "#707272" }}
    />
  </Grid>
);
export default Purchases;
