import * as React from "react";

const Undo = ({ width = 16, height = 16, ...props }) => (
  <svg
    {...props}
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <g
      clipPath='url(#prefix__clip0)'
      stroke={props.disabled ? "#000" : "#C3C3C3"}
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'>
      <path d='M.625.623v6.25h6.25M10 19.373a9.375 9.375 0 10-8.833-12.5' />
    </g>
    <defs>
      <clipPath id='prefix__clip0'>
        <path fill='#fff' d='M0 0h20v20H0z' />
      </clipPath>
    </defs>
  </svg>
);

export default Undo;
