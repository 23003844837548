import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  topBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 24px",
    height: 76,
    [theme.breakpoints.down("sm")]: {
      height: 100,
    },
    backgroundColor: "white",
    position: "static",
  },
  wood_vertical: {
    backgroundImage: "url('../icons/wood_vertical.jpg')",
  },
}));

export default useStyles;
