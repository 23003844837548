import * as React from "react";

const Return = (props) => (
  <svg
    width={20}
    height={17}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.583.75c0-.23.187-.417.417-.417h4.167a5.833 5.833 0 0 1 0 11.667H1.425l3.453 3.453a.417.417 0 1 1-.59.589L.15 11.904a.416.416 0 0 1-.15-.322c0-.107.04-.214.122-.296L4.289 7.12a.417.417 0 1 1 .589.59L1.42 11.167h12.747a5 5 0 0 0 0-10H10A.417.417 0 0 1 9.583.75Z'
      fill='#004C60'
    />
  </svg>
);

export default Return;
