import React, { useEffect, useContext } from "react";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../styles";
import MyContext from "../Provider/MyContext";
import {
  Settings as SettingsIcon,
  Edit as EditIcon,
  InfoOutlined as InfoIcon,
} from "./SVG";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 10,
    border: `1px solid`,
    borderColor: "#C3C3C3",
    height: 78,
    backgroundColor: "white",
  },
  button2: {
    fontSize: 14,
    fontWeight: 400,
    color: "#000000",
  },
  button1: {
    fontSize: 14,
    fontWeight: 400,
    color: "#000000",
    marginRight: 10,
  },
  firstText: {
    display: "flex",
    alignItems: "center",
    marginLeft: 32,
    color: "black",
    fontSize: 16,
    fontWeight: 800,
  },
  secondText: {
    display: "flex",
    alignItems: "center",
    marginLeft: 0,
    color: "black",
    fontSize: 14,
    fontWeight: 400,
  },
}));
const ListView = () => {
  const Context = useContext(MyContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const style = styles({ padding: 20 });
  useEffect(() => {
    dispatch({ type: "CLEAR_FLOORPLAN" });
  }, []);

  return (
    <Grid
      data-qaid='listview_main_grid'
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      container
      className={classes.root}
      direction='row'>
      <Grid
        data-qaid='listview_summerplan_grid'
        item
        xs={2}
        sm={2}
        md={2}
        lg={2}
        className={classes.firstText}>
        <Typography data-qaid='listview_summerplan_text'>
          {Context.langData.summerFloor_plan}
        </Typography>
      </Grid>
      <Grid
        data-qaid='listview_active_grid'
        item
        xs={1}
        sm={1}
        md={1}
        lg={1}
        className={classes.secondText}>
        <InfoIcon data-qaid='listview_infoicon' />
        <Typography data-qaid='listview_active_text'>
          {Context.langData.active}
        </Typography>
      </Grid>
      <Grid data-qaid='listview_empty_grid' item xs={6} sm={6} md={6} lg={6} />
      <Grid
        data-qaid='listview_action_grid'
        item
        xs={2}
        sm={2}
        md={2}
        lg={2}
        style={{ display: "flex", alignItems: "center" }}>
        <IconButton
          data-qaid='listview_settings_iconbutton'
          className={classes.button1}>
          <SettingsIcon data-qaid='listview_settingsicon' />
          {Context.langData.settings}
        </IconButton>
        <IconButton
          data-qaid='listview_edit_iconbutton'
          className={classes.button2}>
          <EditIcon data-qaid='listview_editicon' />
          {Context.langData.edit_floorplan}
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default ListView;
