import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { H4 } from "../utils/text";
import { CheckBoxIcon, CheckedCheckBoxIcon, DisabledCheckbox } from "./SVG";

const CustomCheckbox = withStyles({
  root: {},
  checked: {},
})(({
  checkedColor = "#004C60",
  unCheckColor = "#C3C3C3",
  label,
  labelColor = "#051D33",
  marginLeft,
  labelWeight = "normal",
  marginRight = 0,
  isDisabled = false,
  onChange,
  ...props
}) => {
  const onClick = (e) => {
    if (!isDisabled) {
      onChange(e);
    }
  };
  // console.log("isDisabled", isDisabled, label)
  return (
    <FormControlLabel
      style={{ marginLeft, marginRight }}
      control={
        <Checkbox
          {...props}
          onChange={onClick}
          checkedIcon={
            isDisabled ? (
              <DisabledCheckbox />
            ) : (
              <CheckedCheckBoxIcon color={checkedColor} />
            )
          }
          icon={<CheckBoxIcon color={unCheckColor} />}
        />
      }
      label={<H4 label={label} style={{ fontWeight: labelWeight }} />}
    />
  );
});

export default CustomCheckbox;
