import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Tooltip from "@material-ui/core/Tooltip";
import { Grid, Button } from "@material-ui/core";
// import InfoIcon from '@material-ui/icons/Info';
import moment from "moment";
import MyContext from "../Provider/MyContext";
import { AvenirBlackH4, T } from "../utils/text";
import {
  Edit as EditOutlinedIcon,
  InfoOutlined as InfoOutlinedIcon,
  Settings as SettingsOutlinedIcon,
  InfoFilled as InfoIcon,
} from "./SVG";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 382,
    height: 187,
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
    padding: 10,
    marginTop: 20,
  },
  subHeader: {
    fontSize: 12,
    fontWeight: 400,
    color: "#54575A",
    fontFamily: "AvenirLT",
  },
  headerTitle: {
    fontSize: 16,
    fontWeight: 800,
    color: "#373737",
    fontFamily: "AvenirLT",
    color: "",
    textOverflow: "ellipsis",
    maxWidth: "80%",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  disheaderTitle: {
    fontSize: 16,
    fontWeight: 800,
    fontFamily: "AvenirLT",
    color: "#707272",
    maxWidth: "80%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  button: {
    fontSize: 14,
    fontWeight: 400,
    color: "#000000",
    fontFamily: "AvenirLT",
  },
  textType: {
    fontSize: 12,
    fontWeight: 700,
    marginTop: 5,
    marginLeft: 20,
    fontFamily: "AvenirLT",
  },
  textType1: {
    fontSize: 12,
    fontWeight: 700,
    marginTop: 25,
    marginLeft: 20,
    fontFamily: "AvenirLT",
  },
  content: {
    width: "85%",
  },
  inactive: {
    backgroundColor: "#FAFAFA",
    maxWidth: 345,
    margin: 10,
  },
}));
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: "#051D33",
    color: "#FFF",
    width: 184,
    height: 160,
  },
}));

const BootstrapTooltip = (props) => {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
};
const Row = ({ label, value, bold = false }) => (
  <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
    <T style={{ color: "#FFF" }} label={label} />
    {/* <T style={{ color: "#FFF" }} label={":"} /> */}
    <T
      style={{ color: "#FFF", fontWeight: bold ? 800 : 500 }}
      label={`:  ${value}`}
    />
  </div>
);
export default function CardView({
  item,
  onSettings,
  onEdit,
  orderTypeName,
  backgroundColor,
  isActive = false,
}) {
  const Context = useContext(MyContext);
  const classes = useStyles();
  const [hover, setHover] = useState(true);
  console.log("Item", item);
  const d = item.last_modified_on
    ? moment(item.last_modified_on * 1000).format("MMMM DD, YYYY")
    : moment(item.created_on * 1000).format("MMMM DD, YYYY");
  return (
    <Grid data-qaid='bcardView_main_grid' container>
      <Grid data-qaid='bcardView_child_grid' item lg={11} sm={12} xs={12}>
        <Card
          data-qaid='bcardView_card'
          className={classes.root}
          style={{ borderTop: isActive ? "5px solid #00B6C9" : "0px solid" }}>
          <CardHeader
            data-qaid='bcardView_cardheader'
            title={item.name}
            classes={{
              title: isActive ? classes.headerTitle : classes.disheaderTitle,
              subheader: classes.subHeader,
              content: classes.content,
            }}
            subheader={
              item.last_modified_on ? `Last modified: ${d}` : `Created On: ${d}`
            }
            action={
              <BootstrapTooltip
                data-qaid='bcardView_bootstraptooltip'
                placement='bottom-end'
                title={
                  <Grid
                    data-qaid='bcardView_react_fragment'
                    style={{ padding: 25, height: 200, width: 300 }}>
                    <Row
                      label={Context.langData.order_type}
                      value={item.otype_names}
                    />
                    <Row label='Sections' value={item.number_of_sections} />
                    <Row
                      bold
                      label={Context.langData.tables}
                      value={item.table_count ? item.table_count : 0}
                    />
                    <Row
                      bold
                      label={Context.langData.seats}
                      value={item.seat_count ? item.seat_count : 0}
                    />
                  </Grid>
                }>
                {hover == true ? (
                  <Grid
                    data-qaid='bcardView_infoout_grid'
                    onMouseEnter={() => setHover(false)}>
                    <InfoOutlinedIcon
                      data-qaid='bcardView_infoout_icon'
                      width={24}
                      height={24}
                      color='#004C60'
                    />
                  </Grid>
                ) : (
                  <Grid
                    data-qaid='bcardView_info_grid'
                    onMouseLeave={() => setHover(true)}>
                    <InfoIcon
                      data-qaid='bcardView_info_icon'
                      width={24}
                      height={24}
                      color='#004C60'
                    />
                  </Grid>
                )}
              </BootstrapTooltip>
            }
          />
          <CardActions data-qaid='bcardView_cardActions'>
            <Button
              startIcon={
                <EditOutlinedIcon
                  data-qaid='bcardView_editIcon'
                  color='#004C60'
                  width={23}
                  height={23}
                />
              }
              data-qaid='bcardView_icon_button'
              aria-label='edit'
              className={classes.button}
              onClick={() => onEdit(item)}>
              <AvenirBlackH4
                data-qaid='bcardView_editfloor'
                style={{ marginLeft: 0, color: "#004C60" }}
                label={Context.langData.edit_floor_plan}
              />
            </Button>
            <Button
              startIcon={
                <SettingsOutlinedIcon
                  data-qaid='bcardView_setting'
                  color='#004C60'
                  width={23}
                  height={23}
                />
              }
              data-qaid='bcardView_icon_button'
              aria-label='settings'
              className={classes.button}
              onClick={() => onSettings(item)}>
              <AvenirBlackH4
                style={{ marginLeft: 0, color: "#004C60" }}
                label={Context.langData.settings}
              />
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}
