import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function SimpleBreadcrumbs(props) {
  const classes = useStyles();
  return (
    <div data-qaid='breadcrubs_div' className={classes.root}>
      <Breadcrumbs
        data-qaid='breadcrubs_breadcrubs'
        aria-label='breadcrumb'
        separator='›'
        style={{ margin: 20, cursor: "pointer" }}>
        {props.list
          ? props.list.map((item, index2) => (
              <Link
                data-qaid='breadcrubs_link'
                color='inherit'
                onClick={() => {
                  props.breadCrumbCallBack(item.id);
                }}>
                <Typography
                  data-qaid='breadcrubs_name'
                  variant='h5'
                  style={{ color: "white" }}>
                  {item.name}
                </Typography>
              </Link>
            ))
          : null}
      </Breadcrumbs>
    </div>
  );
}
