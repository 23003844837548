import * as React from "react";

const Transaction = ({ width = 24, height = 24, color = "#fff", ...props }) => (
  <svg
    {...props}
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 1.25a8.75 8.75 0 100 17.5 8.75 8.75 0 000-17.5zM0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.875 6.875c0-.345.28-.625.625-.625h6.875a.625.625 0 110 1.25H7.5a.625.625 0 01-.625-.625z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.433 3.933a.625.625 0 01.884 0l2.5 2.5a.625.625 0 010 .884l-2.5 2.5a.625.625 0 11-.884-.884l2.058-2.058-2.058-2.058a.625.625 0 010-.884zM5 13.125c0-.345.28-.625.625-.625H12.5a.625.625 0 110 1.25H5.625A.625.625 0 015 13.125z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.567 10.183a.625.625 0 010 .884l-2.058 2.058 2.058 2.058a.625.625 0 11-.884.884l-2.5-2.5a.625.625 0 010-.884l2.5-2.5a.625.625 0 01.884 0z'
      fill={color}
    />
  </svg>
);

export default Transaction;
