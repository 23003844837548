import * as React from "react";

const Dashboard = ({
  width = 24,
  height = 24,
  color = "#004C60",
  ...props
}) => (
  <svg
    {...props}
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.333 1.25H12.5a.417.417 0 00-.417.417v2.5c0 .23.187.416.417.416h5.833c.23 0 .417-.186.417-.416v-2.5a.417.417 0 00-.417-.417zM7.5 15.417H1.667a.417.417 0 00-.417.416v2.5c0 .23.187.417.417.417H7.5c.23 0 .417-.186.417-.417v-2.5a.417.417 0 00-.417-.416zm0-14.167H1.667a.417.417 0 00-.417.417v9.166c0 .23.187.417.417.417H7.5c.23 0 .417-.187.417-.417V1.667A.417.417 0 007.5 1.25zm10.833 7.5H12.5a.417.417 0 00-.417.417v9.166c0 .23.187.417.417.417h5.833c.23 0 .417-.186.417-.417V9.167a.417.417 0 00-.417-.417zM12.5 0c-.92 0-1.667.746-1.667 1.667v2.5c0 .92.746 1.666 1.667 1.666h5.833c.92 0 1.667-.746 1.667-1.666v-2.5C20 .747 19.254 0 18.333 0H12.5zM1.667 14.167c-.92 0-1.667.746-1.667 1.666v2.5C0 19.253.746 20 1.667 20H7.5c.92 0 1.667-.746 1.667-1.667v-2.5c0-.92-.747-1.666-1.667-1.666H1.667zM0 1.667C0 .747.746 0 1.667 0H7.5c.92 0 1.667.746 1.667 1.667v9.166c0 .92-.747 1.667-1.667 1.667H1.667C.747 12.5 0 11.754 0 10.833V1.667zM12.5 7.5c-.92 0-1.667.746-1.667 1.667v9.166c0 .92.746 1.667 1.667 1.667h5.833c.92 0 1.667-.746 1.667-1.667V9.167c0-.92-.746-1.667-1.667-1.667H12.5z'
      fill={color}
    />
  </svg>
);

export default Dashboard;
