import React, { useState, useEffect, useContext } from "react";
import { Grid, Button } from "@material-ui/core";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import $ from "jquery";
import * as XLSX from "xlsx";
import SingleSelect from "../../../../components/SingleSelect";
import MyContext from "../../../../Provider/MyContext";
import ContextMenu from "../../../../components/ContextMenu";
import {
  fetchAllIndents,
  deletePurchaseOrder,
  uploadPurchaseOrders,
} from "../../../../redux/actions";
import AlertDialog from "../../../../components/Dialog";
import DataTable from "../../../../components/DataTable";
import { AvenirBlackH4, H4, H2 } from "../../../../utils/text";
import ArrowRightIcon from "../../../../components/SVG/ArrowRightIcon";
import styles from "../../../../styles/index";
import UploadIcon from "../../../../components/SVG/UploadIcon";
import DeleteIcon from "../../../../components/SVG/Delete";
import SnackBar from "../../../../components/SnackBar";
import UploadErrosTAble from "../../../../components/UploadErrosTable";
import InputText from "../../../../components/InputText";
import CenterModal from "../../../../components/CenterModal";
import { PurchaseOrderStatus } from "../../../../utils/util";
import PrintIndent from "./printIndent";
import RaiseIndent from "./raiseIndent";
import ViewIndentId from "./viewIndentId";

const Indents = () => {
  const style = styles();
  const dispatch = useDispatch();
  const [table, setTable] = useState([]);
  const indentData = useSelector((state) => state.inventory.indents);
  const vendorsData = useSelector((state) => state.inventory.vendors);
  const stocksData = useSelector((state) => state.inventory.stocks);
  const Context = useContext(MyContext);
  const [allIndents, setAllIndents] = useState("all");
  const [allVendors, setAllVendors] = useState("all");
  const [anchorEl, setAnchorEl] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedId, setSelectedId] = useState([]);
  const [btnDisable, setBtnDisable] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [heading, setHeading] = useState(Context.langData.delete_vendor);
  const [bodyMessage, setBodyMessage] = useState(
    Context.langData.delete_undo_body_msg
  );
  const [viewIndentId, setViewIndentId] = useState(false);
  const [row, setRow] = useState({});
  const [vendorList, setVendorList] = useState([]);
  const [showRaiseIndent, setShowRaiseIndent] = useState(false);
  const [showPrintIndent, setShowPrintIndent] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [fileName, setFileName] = useState("");
  const [itemsList, setItemsList] = useState([]);
  const [errors, setErrors] = useState("");
  const [isUploadError, setIsUploadError] = useState(false);
  const [showPurchaseUploadModal, setShowPurchaseUploadModal] = useState(false);
  const [parseData, setParseData] = useState([]);
  const purchaseOrderStatus = PurchaseOrderStatus();

  useEffect(() => {
    if (allVendors) {
      let offsetValue = 0;
      if (pageNumber > 0) {
        offsetValue = pageNumber - 1;
      }
      // dispatch(fetchAllIndents({ offset: offsetValue, limit: 20, status_list: allIndents, vendor_id: allVendors }));
      dispatch(fetchAllIndents({ status: allIndents, vendor_id: allVendors }));
    }
  }, [allVendors, allIndents]);

  useEffect(() => {
    if (indentData && indentData.data && indentData.data.length > 0) {
      indentData.data.map((item) => {
        console.log("ITEMMMMMMMM", item);
        // item["isDisabled"] = item.status == "Closed"
        item.print = (
          <span
            onClick={() => {
              onPrintClick(item);
            }}
            style={{ textDecoration: "underline", cursor: "pointer" }}>
            <AvenirBlackH4
              style={{ color: "#004C60" }}
              label={Context.langData.print}
            />
          </span>
        );
      });
      setTable(indentData.data);
    } else {
      setTable([]);
    }
  }, [indentData]);
  const onPrintClick = (rowValue) => {
    setRow(rowValue);
    setShowPrintIndent(true);
    console.log("Print121212");
  };

  const onCheckedIds = (ids) => {
    setSelectedId(ids);
    if (ids.length > 0) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  };

  const [columns, setColumns] = useState([
    // { field: 'IssueId', headerName: Context.langData.purchase_order_id, link: true, size: '20%', sort: false },
    {
      field: "indent_id",
      headerName: Context.langData.purchase_order_,
      size: "25%",
      sort: false,
    },
    {
      field: "vendor_name",
      headerName: Context.langData.vendor,
      size: "20%",
      sort: false,
    },
    {
      field: "status",
      headerName: Context.langData.status,
      size: "15%",
      sort: false,
    },
    {
      field: "date",
      headerName: Context.langData.date,
      size: "15%",
      sort: false,
    },
    {
      field: "total_amount",
      headerName: Context.langData.total_amount,
      size: "15%",
      sort: false,
      isNumber: true,
    },
    {
      field: "print",
      headerName: Context.langData.print,
      size: "10%",
      sort: false,
    },
  ]);
  const onPageinationChange = (value) => {
    setPageNumber(value);
  };

  const handleCloseAlert = (val) => {
    if (val) {
      const ids = [];
      selectedId.forEach((id) => {
        ids.push({ id });
      });
      const obj = {
        data: ids,
      };
      dispatch(deletePurchaseOrder(obj, deleteCallback));
    }
    setShowDialog(false);
  };
  const closePurchaseOrderCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.success_close_indent,
        severity: "success",
      });
      setViewIndentId(false);
      dispatch(fetchAllIndents({ status: allIndents, vendor_id: allVendors }));
    }
  };
  const deleteCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.purchase_order_delete_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.purchase_order_delete_failed,
        severity: "error",
      });
    }
    commanCallback();
  };
  function onClickIndentId(rowValue, event) {
    setRow(rowValue);
    setViewIndentId(true);
  }
  const commanCallback = () => {
    setViewIndentId(false);
    setShowPrintIndent(false);
    if (allVendors) {
      let offsetValue = 0;
      if (pageNumber > 0) {
        offsetValue = pageNumber - 1;
      }
      dispatch(fetchAllIndents({ status: allIndents, vendor_id: allVendors }));
    }
    setShowPurchaseUploadModal(false);
    setItemsList([]);
    setFileName("");
  };
  const namesData = [
    { name: purchaseOrderStatus.ALL, value: "all" },
    { name: purchaseOrderStatus.RAISED, value: "RAISED" },
    { name: purchaseOrderStatus.IN_PROGRESS, value: "IN_PROGRESS" },
    { name: purchaseOrderStatus.FULFILLED, value: "FULFILLED" },
    { name: purchaseOrderStatus.CLOSED, value: "CLOSED" },
  ];

  const ContextMenuData = [
    // {
    //   id: 2,
    //   icon: UploadIcon,
    //   name: Context.langData.upload_indents
    // },
    {
      id: 1,
      icon: DeleteIcon,
      name: Context.langData.delete,
    },
  ];

  const callBack = (id) => {
    // if (id == 2) {
    //   console.log("id===1", id)
    //   setShowPurchaseUploadModal(true)
    //   // triggerUpload();
    // }
    if (id == 1) {
      if (selectedId.length > 0) {
        console.log("?????", selectedId);
        let canDelete = true;
        selectedId.map((id) => {
          const indent = table.find((e) => e.id == id);
          if (indent) {
            if (indent.status != purchaseOrderStatus.RAISED) {
              canDelete = false;
            }
          }
        });
        console.log("CanDELETE", canDelete);
        if (canDelete) {
          if (selectedId.length > 1) {
            setHeading(
              Context.langData.delete_purchae_order_header +
                Context.langData.delete_plural_text
            );
          } else {
            setHeading(
              Context.langData.delete_purchae_order_header +
                Context.langData.delete_single
            );
          }
          setShowDialog(true);
        } else {
          console.log(
            "You can't delete purchase order which is not in raised status"
          );
          setSnackbar({
            message: Context.langData.delete_indents_error,
            severity: "error",
          });
        }
      }
    }
  };
  const uploadPurchaseOrderCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.purchase_order_upload_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.purchase_order_upload_failed,
        severity: "error",
      });
    }
    commanCallback();
  };

  const upload = (event) => {
    console.log("event trigeered", event);
    const file = event.target.files[0];
    console.log("file", file);
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = e.target.result;
      const arr = String.fromCharCode.apply(null, new Uint8Array(data));
      const wb = XLSX.read(btoa(arr), { type: "base64" });
      const resultsData = to_json(wb);
      console.log("resultsData===>", resultsData);
      setParseData(parseXlsxFileData(resultsData));
      console.log("parseData===>", parseData);
    };
    setFileName(file?.name);
    reader.readAsArrayBuffer(file);
  };

  const saveCallback = () => {
    if (parseData.length > 0) {
      const itemsData = checkXlsxSheets(parseData);
      if (itemsData?.indent_errors?.length == 0) {
        if (itemsData?.indent_list?.length > 0) {
          setItemsList(itemsData.indent_list);
          dispatch(
            uploadPurchaseOrders(
              {
                date: moment().format("YYYY-MM-DD"),
                indents: itemsData.indent_list,
              },
              uploadPurchaseOrderCallback
            )
          );
        }
      } else {
        setErrors(itemsData.indent_errors);
        setIsUploadError(true);
        setItemsList(itemsData.indent_list);
      }
    } else {
      setSnackbar({
        message: Context.langData.upload_valid_file,
        severity: "error",
      });
      console.log("called==>");
      setShowPurchaseUploadModal(false);
    }
  };
  const handleProceedUpload = () => {
    if (itemsList.length > 0) {
      dispatch(
        uploadPurchaseOrders(
          {
            date: moment().format("YYYY-MM-DD"),
            indents: itemsList,
          },
          uploadPurchaseOrderCallback
        )
      );
    } else {
      setSnackbar({
        message: Context.langData.no_purchase_orders_error,
        severity: "error",
      });
    }
    setIsUploadError(false);
  };
  const handleCancelUpload = () => {
    setIsUploadError(false);
    setItemsList([]);
  };
  const to_json = (workbook) => {
    const result = {};
    const sheetNameList = workbook.SheetNames;
    sheetNameList.map((y) => {
      const roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[y], {
        raw: false,
        dateNF: "yyyy-mm-dd",
      });
      if (roa.length > 0) {
        result[y] = roa;
      }
    });
    return result;
  };

  const parseXlsxFileData = (file) => {
    const file_vendors = file.vendors;
    const file_stocks = file.stocks;
    const vendors_info = [];
    const stocks_info = [];
    file_vendors?.map((vendor, index) => {
      const obj = { row: index };
      $.each(vendor, (key, value) => {
        if (key.trim().toLowerCase() == "vendor name") {
          obj.vendor_name = value;
        } else if (key.trim().toLowerCase() == "bill no") {
          obj.bill_no = value;
        } else if (key.trim().toLowerCase() == "received date") {
          console.log(
            "value",
            value,
            " moment(value).format(YYYY - MM - DD)",
            moment(value).format("YYYY-MM-DD")
          );
          obj.received_on = moment(value).format("YYYY-MM-DD");
        } else if (key.trim().toLowerCase() == "delivery charges") {
          obj.delivery = value;
        } else if (key.trim().toLowerCase() == "amount paid") {
          obj.amount_paid = value;
        } else if (key.trim().toLowerCase() == "notes") {
          obj.notes = value;
        } else if (key.trim().toLowerCase() == "status") {
          obj.status = value;
        }
      });
      vendors_info.push(obj);
    });
    let stock_list = [];
    let obj = null;
    console.log("file_stocks=>", file_stocks);
    file_stocks?.map((stock, index) => {
      if (typeof stock["Bill no"] !== "undefined") {
        if (obj != null) {
          obj.items = stock_list;
          stocks_info.push(obj);
          obj = null;
          stock_list = [];
        }
        obj = {};
        obj.bill_no = stock["Bill no"];
        var stockObj = {
          row: index,
          stock_name: stock["Stock name"],
          requested: stock["Requested qty"],
          received: stock["Received qty"],
          price: stock.Price,
          tax_amount: stock["Tax amount"],
        };
        stock_list.push(stockObj);
      } else {
        var stockObj = {
          row: index,
          stock_name: stock["Stock name"],
          requested: stock["Requested qty"],
          received: stock["Received qty"],
          price: stock.Price,
          tax_amount: stock["Tax amount"],
        };
        stock_list.push(stockObj);
      }
    });
    if (obj != null) {
      obj.items = stock_list;
      stocks_info.push(obj);
    }
    console.log("stocks_info==>>==>>", stocks_info);
    vendors_info?.map((vendor) => {
      const findBill = $.grep(stocks_info, (e) => e.bill_no == vendor.bill_no);
      if (findBill.length != 0) {
        let billList = [];
        findBill.map((billItem) => {
          billList = billList.concat(billItem.items);
        });
        console.log("billList==>", billList);
        vendor.items = billList;
      }
    });
    return vendors_info;
  };

  const checkXlsxSheets = (data) => {
    const indentErrors = [];
    const parsedIndents = [];
    const indentStatus = [
      { name: "raised", id: 1 },
      { name: "inprogress", id: 2 },
      { name: "closed", id: 3 },
      { name: "fulfilled", id: 4 },
    ];
    console.log("data----121212", data);
    data.map((indent) => {
      const tempError = [];
      const tempStocksError = [];
      const parsedStocks = [];
      if (typeof indent.vendor_name === "undefined") {
        tempError.push("vendor name not entered");
      } else if (indent.vendor_name.trim() == "") {
        tempError.push("vendor name not entered");
      }

      if (typeof indent.bill_no === "undefined") {
        tempError.push("bill number not entered");
      } else if (indent.bill_no == "") {
        tempError.push("bill number not entered");
      } else if (isNaN(indent.bill_no)) {
        tempError.push("bill number should be a number");
      } else {
        indent.bill_no = Number(indent.bill_no);
      }

      if (typeof indent.received_on === "undefined") {
        tempError.push("received date not entered");
      } else if (indent.received_on == "") {
        tempError.push("received date not entered");
      }
      if (typeof indent.amount_paid === "undefined") {
        tempError.push("paid amount not entered");
      } else if (indent.amount_paid == "") {
        tempError.push("paid amount not entered");
      } else if (isNaN(indent.amount_paid)) {
        tempError.push("paid amount should be a number");
      } else {
        indent.amount_paid = Number(indent.amount_paid);
      }

      if (typeof indent.delivery === "undefined") {
        indent.delivery = 0;
      } else if (isNaN(indent.delivery)) {
        indent.delivery = 0;
      } else {
        indent.delivery = Number(indent.delivery);
      }
      if (typeof indent.notes === "undefined") {
        indent.notes = "N/A";
      }
      if (typeof indent.status === "undefined") {
        tempError.push("please mention indent status");
      } else if (indent.status.trim() == "") {
        tempError.push("please mention indent status");
      } else {
        const find_status = $.grep(
          indentStatus,
          (e) => e.name == indent.status.trim().toLowerCase()
        );
        if (find_status.length != 0) {
          const status_id = find_status[0].id;
          indent.status =
            status_id == 1 ? "Raised" : status_id == 2 ? "Pending" : "Closed";
        } else {
          tempError.push("indent status should be raised or pending or closed");
        }
      }
      if (tempError.length == 0) {
        const find_vendor = _isVendorExist({ name: indent.vendor_name });
        if (find_vendor.result) {
          indent.vendor_id = find_vendor.vendor_id;
          indent.vendor_stocks = find_vendor.vendor_stocks;
        } else {
          tempError.push(
            "vendor name not found in inventory vendors, please check vendor name in excelsheet"
          );
        }
      }
      console.log("indent====>", indent);
      if (tempError.length == 0) {
        indent?.items?.map((stock) => {
          const checkStock = _checkStock(
            stock,
            indent.vendor_id,
            indent.vendor_name
          );
          if (checkStock.error) {
            tempStocksError.push(checkStock.errors);
          } else {
            parsedStocks.push(checkStock);
          }
        });
        if (tempStocksError.length == 0) {
          indent.items = parsedStocks;
          let totalTax = 0;
          let totalAmount = 0;
          if (parsedStocks.length > 0) {
            parsedStocks.map((stockItem) => {
              totalTax += stockItem.tax_amount;
              totalAmount += stockItem.total_amount;
            });
          }
          totalAmount += indent.delivery;
          indent.total_tax = totalTax;
          indent.total_amount = totalAmount;
          if (indent.status == "Closed") {
            if (totalAmount == indent.amount_paid) {
              parsedIndents.push(indent);
            } else {
              indentErrors.push({
                name: indent.bill_no,
                errors:
                  "Amount paid and Total amounts are must be equal for closed status",
              });
            }
          } else {
            parsedIndents.push(indent);
          }
        } else {
          indentErrors.push({
            name: indent.bill_no,
            errors: tempStocksError.join(" & "),
          });
        }
      } else {
        indentErrors.push({
          name: indent.bill_no,
          errors: `In vendors sheet at row ${
            indent.row + 2
          } ==> ${tempError.join(", ")}`,
        });
      }
    });
    return { indent_list: parsedIndents, indent_errors: indentErrors };
  };
  const _isVendorExist = (data) => {
    const vendors = vendorsData;
    const result = $.grep(
      vendors,
      (e) => e.name.trim().toLowerCase() == data.name.trim().toLowerCase()
    );
    if (result.length != 0) {
      const vendor = result[0];
      return {
        result: true,
        vendor_id: vendor.vendor_id,
        vendor_stocks: vendor.stocks,
      };
    }
    return { result: false };
  };
  const _checkStock = (stock, id) => {
    const stocks = stocksData;
    const tempStockErrors = [];
    if (typeof stock.stock_name === "undefined") {
      tempStockErrors.push("stock name not entered");
    } else if (stock.stock_name.trim() == "") {
      tempStockErrors.push("stock name not entered");
    }

    if (typeof stock.requested === "undefined") {
      tempStockErrors.push("requested quantity not entered");
    } else if (stock.requested == "") {
      tempStockErrors.push("requested quantity not entered");
    } else if (isNaN(stock.requested)) {
      tempStockErrors.push("requested quantity should be a number");
    } else {
      stock.requested = Number(stock.requested);
    }

    if (typeof stock.received === "undefined") {
      tempStockErrors.push("received quantity not entered");
    } else if (stock.received == "") {
      tempStockErrors.push("received quantity not entered");
    } else if (isNaN(stock.received)) {
      tempStockErrors.push("received quantity should be a number");
    } else {
      stock.received = Number(stock.received);
    }

    if (typeof stock.price === "undefined") {
      tempStockErrors.push("unit price not entered");
    } else if (stock.price == "") {
      tempStockErrors.push("unit price not entered");
    } else if (isNaN(stock.price)) {
      tempStockErrors.push("unit price should be a number");
    } else {
      stock.price = Number(stock.price);
    }

    if (typeof stock.tax_amount === "undefined") {
      tempStockErrors.push("tax amount not entered");
    } else if (stock.tax_amount == "") {
      tempStockErrors.push("tax amount not entered");
    } else if (isNaN(stock.tax_amount)) {
      tempStockErrors.push("tax amount should be a number");
    } else {
      stock.tax_amount = Number(stock.tax_amount);
    }

    // if (typeof stock.total_amount == "undefined") {
    //   tempStockErrors.push("total amount not entered");
    // } else {
    //   if (stock.total_amount == "") {
    //     tempStockErrors.push("total amount not entered");
    //   } else if (isNaN(stock.total_amount)) {
    //     tempStockErrors.push("total amount should be a number");
    //   } else {
    //     stock["total_amount"] = Number(stock.total_amount);
    //   }
    // }

    if (tempStockErrors.length == 0) {
      const result = $.grep(
        stocks,
        (e) =>
          e.name.trim().toLowerCase() == stock.stock_name.trim().toLowerCase()
      );
      if (result.length != 0) {
        const store_stock = result[0];
        const stock_vendor_ids = store_stock.vendor_ids.split(",");
        const check_stock_vendor = $.inArray(id.toString(), stock_vendor_ids);
        if (check_stock_vendor != -1) {
          stock.vendor_ids = store_stock.vendor_ids;
          stock.stock_id = store_stock.stock_id;
          stock.units = store_stock.units;
        } else {
          tempStockErrors.push(
            `${
              stock.stock_name
            } is not contain vendors or not belongs to this vendor`
          );
        }
      } else {
        tempStockErrors.push(
          `${stock.stock_name} is not exist store stocks, please check`
        );
      }
    }

    if (tempStockErrors.length == 0) {
      stock.error = false;
      stock.total_amount = stock.received * stock.price + stock.tax_amount;
      return stock;
    }
    return {
      error: true,
      name: stock.stock_name,
      errors: `In stocks sheet at row ${
        stock.row + 2
      } ==> ${tempStockErrors.join(", ")}`,
    };
  };
  useEffect(() => {
    const array = [{ vendor_id: "all", name: Context.langData.all_vendors }];
    if (vendorsData.length > 0) {
      vendorsData.map((item) => {
        array.push(item);
      });
    }
    setVendorList(array);
  }, [vendorsData]);

  const handleRaiseIndent = () => {
    setShowRaiseIndent(true);
  };
  const indentCallback = () => {
    setShowRaiseIndent(false);
    commanCallback();
  };

  const closeViewIndent = () => {
    setViewIndentId(false);
    commanCallback();
  };

  const updateIndentCallback = (isSuccess) => {
    commanCallback();
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.raise_purchase_update_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.raise_purchase_failed,
        severity: "error",
      });
    }
  };
  const repeteIndentCallback = (isSuccess) => {
    commanCallback();
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.raise_purchase_create_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.raise_purchase_create_failed,
        severity: "error",
      });
    }
  };
  const sortingOnClick = (value, sortValue, index) => {
    const columnData = [...columns];
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setColumns(columnData);
    if (value == "date") {
      var sortIndentData = _.orderBy(
        table,
        [(d) => new Date(d[value])],
        [sortValue ? "desc" : "asc"]
      );
    } else {
      var sortIndentData = _.orderBy(
        table,
        [
          (d) =>
            typeof d[value] === "string" ? d[value].toLowerCase() : d[value],
        ],
        [sortValue ? "desc" : "asc"]
      );
    }
    setTable(sortIndentData);
  };
  const triggerUpload = () => {
    console.log("clicked");
    document.getElementById("upload").click();
  };
  const DeleteUploadFile = () => {
    setItemsList([]);
    setFileName("");
  };
  return (
    <Grid container style={{}}>
      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: 20,
        }}>
        <Grid item lg={4} xs={12} md={4} style={{}}>
          <AvenirBlackH4
            label={Context.langData.select_purchase_order}
            style={{ paddingBottom: 5 }}
          />
          <SingleSelect
            minWidth='100%'
            list={namesData}
            valueKey='value'
            displayKey='name'
            value={allIndents}
            handleChange={(e) => setAllIndents(e.target.value)}
            border={false}
            height={56}
          />
        </Grid>
        <Grid item lg={4} xs={12} md={4} style={{ paddingLeft: 10 }}>
          <AvenirBlackH4
            label={Context.langData.select_vendor}
            style={{ paddingBottom: 5 }}
          />
          <SingleSelect
            minWidth='100%'
            list={vendorList}
            valueKey='vendor_id'
            displayKey='name'
            value={allVendors}
            handleChange={(e) => setAllVendors(e.target.value)}
            border={false}
            height={56}
          />
        </Grid>

        <Grid
          item
          xs={12}
          lg={4}
          md={4}
          style={{
            alignItems: "center",
            marginTop: 20,
            justifyContent: "center",
            display: "flex",
          }}>
          <Button
            className={style.buttonClass}
            data-qaid='indents_raiseIndentsButton'
            style={{
              backgroundColor: "#FFB600",
              color: "white",
              marginRight: 10,
            }}
            variant='contained'
            id='cancel'
            onClick={() => {
              handleRaiseIndent();
            }}>
            <ArrowRightIcon ddata-qaid='indents_rightIcon' />
            <AvenirBlackH4
              data-qaid='indents_raiseIndentsLabel'
              label={Context.langData.raise_purchase}
              style={{ marginLeft: 5 }}
            />
          </Button>
          <ContextMenu
            style={{ marginLeft: 10, marginBottom: 10 }}
            menuItems={ContextMenuData}
            callBack={callBack}
          />
          {/* <input onChange={upload} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" type="file" style={{ display: 'none' }} id="upload" /> */}
        </Grid>
      </Grid>
      <Grid
        container
        style={{ marginTop: "20px", display: "flex", width: "100%" }}>
        <Grid item lg={12}>
          <DataTable
            data-qaid='indents_dataTable'
            columns={columns}
            rows={table}
            onCheckedIds={onCheckedIds}
            onClickAction={onClickIndentId}
            onPageChange={onPageinationChange}
            style={{ width: "100%" }}
            sortingOnClick={sortingOnClick}
          />
        </Grid>
      </Grid>
      {viewIndentId && (
        <ViewIndentId
          open={viewIndentId}
          row={row}
          onClose={closeViewIndent}
          callback={updateIndentCallback}
          repeatIndentCallback={repeteIndentCallback}
          closeCallback={closePurchaseOrderCallback}
        />
      )}
      {showRaiseIndent && (
        <RaiseIndent
          open={showRaiseIndent}
          row={row}
          onClose={() => setShowRaiseIndent(false)}
          callback={indentCallback}
        />
      )}
      {showPrintIndent && (
        <PrintIndent
          open={showPrintIndent}
          row={row}
          onClose={() => setShowPrintIndent(false)}
          callback={commanCallback}
        />
      )}
      {showPurchaseUploadModal && (
        <PurchaseUploadModal
          showModal={showPurchaseUploadModal}
          closeModal={() => setShowPurchaseUploadModal(false)}
          saveCallback={saveCallback}
          fileName={fileName}
          deleteFileCallback={DeleteUploadFile}
          upload={upload}
        />
      )}
      {snackbar && (
        <SnackBar
          data-qaid='indent_snackbar'
          open
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
      {isUploadError && (
        <UploadErrosTAble
          data-qaid='issue_upload'
          open={isUploadError}
          onClose={() => handleCancelUpload()}
          proceed={() => handleProceedUpload()}
          data={errors}
          labelName={Context.langData.name}
          title={Context.langData.missing_indent_information}
          error={Context.langData.purchase_order_error_body}
        />
      )}
      <AlertDialog
        showExtra={false}
        showDialog={showDialog}
        body={bodyMessage}
        heading={heading}
        IsError={false}
        handleClose={handleCloseAlert}
      />
    </Grid>
  );
};

const PurchaseUploadModal = ({
  showModal,
  closeModal,
  saveCallback,
  fileName,
  deleteFileCallback,
  upload,
}) => {
  const style = styles();
  const dispatch = useDispatch();
  const Context = useContext(MyContext);

  const triggerUpload = () => {
    document.getElementById("upload").click();
  };

  return (
    <CenterModal open={showModal} onClose={closeModal}>
      <Grid
        style={{
          width: 495,
          height: fileName ? 308 : 220,
        }}>
        <Grid style={{ margin: 32 }}>
          <Grid
            style={{
              height: fileName ? 153 : 50,
            }}>
            <H2
              label={Context.langData.purchase_item_upload}
              style={{ marginBottom: 12 }}
            />
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "column",
              }}>
              {/* <AvenirBlackH4
                data-qaid="createcomment_valuelabel"
                label={dropDownBody}
                style={{ marginBottom: 8 }}
              /> */}
            </Grid>
            {fileName ? (
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 12,
                }}>
                <AvenirBlackH4
                  data-qaid='createcomment_valuelabel'
                  label={Context.langData.file}
                  style={{ marginBottom: 8 }}
                />
                <Grid style={{ display: "flex", flexDirection: "row" }}>
                  <Grid style={{ display: "flex", width: "75%" }}>
                    <InputText value={fileName} disabled id='fileName' />
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      width: "25%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      cursor: "pointer",
                    }}
                    onClick={deleteFileCallback}>
                    <DeleteIcon />
                    <AvenirBlackH4
                      data-qaid='items_createlabel'
                      label={Context.langData.delete}
                      style={{ color: "#004C60", marginLeft: 8 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          <Button
            onClick={triggerUpload}
            className={style.buttonClass}
            data-qaid='items_creategrid'
            style={{ backgroundColor: "#FFFFFF", paddingLeft: 0 }}>
            <UploadIcon
              data-qaid='items_createpluse'
              color='#004C60'
              width={20}
              height={20}
              style={{ marginRight: 8 }}
            />
            <AvenirBlackH4
              data-qaid='items_createlabel'
              label={Context.langData.upload_a_file}
              style={{ color: "#004C60" }}
            />
            <input
              onChange={upload}
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              type='file'
              style={{ display: "none" }}
              id='upload'
            />
          </Button>
        </Grid>
        <Grid
          style={{
            height: 106,
            backgroundColor: "#FAFAFA",
            borderTop: "1px solid  #C3C3C3",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}>
          <Button
            onClick={closeModal}
            className={style.buttonClass}
            data-qaid='items_creategrid'
            style={{
              backgroundColor: "#FFFFFF",
              marginRight: 24,
              border: "2px solid #051D33",
              width: 103,
              height: 40,
            }}
            variant='outlined'>
            <H4 data-qaid='items_createlabel' label={Context.langData.cancel} />
          </Button>
          <Button
            onClick={saveCallback}
            className={style.buttonClass}
            data-qaid='items_creategrid'
            style={{
              backgroundColor: "#FFB600",
              width: 103,
              height: 40,
              marginRight: 32,
            }}
            variant='contained'>
            <H4 data-qaid='items_createlabel' label={Context.langData.save} />
          </Button>
        </Grid>
      </Grid>
    </CenterModal>
  );
};

export default Indents;
