import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  fetchKitchenName,
  fetchPrinters,
  fetchKitchenPrinterUnassigned,
} from "../../../../redux/actions";
import PrintersList from "./printersList";

const Printers = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPrinters());
    dispatch(fetchKitchenName());
    dispatch(fetchKitchenPrinterUnassigned());
  }, []);
  return (
    <div>
      <PrintersList data-qaid='printers_printerList' />
    </div>
  );
};

export default Printers;
