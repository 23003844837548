import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import MyContext from "../../../Provider/MyContext";
import { H4, AvenirBlackH4, H5, H8 } from "../../../utils/text";
import InputText from "../../../components/InputText";
import styles from "../../../styles/index";
import { createPettyCashCategories } from "../../../redux/actions";
import RightDrawer from "../../../components/RightDrawer";
import PettyCashIcon from "../../../components/SVG/PettyCashIcon";
import CircleClose from "../../../components/SVG/CloseCircle";
import { validateText } from "../../../utils/util";

const CreateCategory = ({ open, callback, onClose }) => {
  const style = styles();

  const Context = useContext(MyContext);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();

  const saveCategory = () => {
    if (name) {
      dispatch(
        createPettyCashCategories({ cat_name: name, description }, callback)
      );
    } else {
      setNameError(Context.langData.pettycash_catName_mandatory);
    }
  };
  return (
    <RightDrawer
      data-qaid='createcomment_centermodal'
      open={open}
      onClose={onClose}
      padding={1}>
      <>
        <Grid
          style={{ display: "flex", height: "100%", flexDirection: "column" }}>
          <Grid
            style={{ display: "flex", height: "85%", flexDirection: "column" }}>
            <Grid
              data-qaid='createcategory_close_grid'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}>
              <CircleClose
                data-qaid='createcategory_closeicon'
                size={20}
                onClick={onClose}
                style={{ marginRight: 40, marginTop: 20 }}
              />
            </Grid>
            <Grid
              data-qaid='createcategory_settings_grid'
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 56,
                marginBottom: 10,
              }}>
              <PettyCashIcon
                data-qaid='createcategory_setting_icon'
                style={{ marginRight: 10 }}
                width={22.5}
                height={24}
                color='#707272'
              />
              <H4
                data-qaid='createcategory_settings'
                label={Context.langData.petty_cash}
                style={{ color: "#707272" }}
              />
            </Grid>
            <H8
              data-qaid='createcategory_title'
              style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
              label={Context.langData.create_category}
            />
            <Grid
              data-qaid='createCategory_grid1'
              style={{
                display: "flex",
                flexDirection: "column",
                width: 500,
                marginLeft: 56,
                marginRight: 56,
              }}>
              <Grid
                data-qaid='createCategory_name'
                item
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 16,
                }}>
                <AvenirBlackH4
                  data-qaid='createCategory_namelabel'
                  label={Context.langData.cat_name}
                />
                <InputText
                  data-qaid='createCategory_nameip'
                  value={name}
                  error={nameError}
                  maxLength={500}
                  onChange={(val) => setName(validateText(val.target.value))}
                  inputProps={{ maxLength: 500 }}
                  id='name'
                />
                <Grid
                  item
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    display: "flex",
                    padding: 5,
                    paddingTop: 5,
                  }}>
                  {/* <Grid style={{flexDirection:'row', alignItems:'center', display:'flex'}}>
                                        <InfoOutlinedIcon style={{ height: 16, width:16}}/>
                                        <H5 label={Context.langData.help} style={{paddingTop:5, paddingLeft: 5}}/>
                                    </Grid> */}
                  <Grid style={{ justifyContent: "flex-end" }}>
                    <H5
                      label={
                        (name?.length == undefined ? "0" : name?.length) +
                        Context.langData._500_characters_count
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                data-qaid='createkitchen_description_grid'
                item
                style={{ display: "flex", flexDirection: "column" }}>
                <AvenirBlackH4
                  data-qaid='createkitchen_descriptionlabel'
                  label={Context.langData.description}
                />
                <InputText
                  data-qaid='createCategory_description'
                  value={description}
                  // error={error}
                  maxLength={500}
                  inputProps={{ maxLength: 500 }}
                  onChange={(val) => setDescription(val.target.value)}
                  id='name'
                />
                <Grid
                  item
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    display: "flex",
                    padding: 5,
                    paddingTop: 5,
                  }}>
                  {/* <Grid style={{flexDirection:'row', alignItems:'center', display:'flex'}}>
                                        <InfoOutlinedIcon style={{ height: 16, width:16}}/>
                                        <H5 label={Context.langData.help} style={{paddingTop:5, paddingLeft: 5}}/>
                                    </Grid> */}
                  <Grid style={{ justifyContent: "flex-end" }}>
                    <H5
                      label={
                        (description?.length == undefined
                          ? "0"
                          : description?.length) +
                        Context.langData._500_characters_count
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{
              display: "flex",
              height: "15%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid
              data-qaid='createCategory_action_grid'
              container
              style={{
                backgroundColor: "#FAFAFA",
                border: "1px solid #C3C3C3",
              }}>
              <Grid
                data-qaid='createCategory_cancel_btn'
                item
                xs={12}
                lg={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 32,
                  marginBottom: 32,
                }}>
                <Button
                  className={style.buttonClass}
                  data-qaid='createCategory_cancel_button'
                  onClick={onClose}
                  variant='contained'
                  style={{
                    backgroundColor: "#FFF",
                    marginRight: 16,
                    border: "2px solid #051D33",
                    height: 40,
                    width: 103,
                  }}>
                  <AvenirBlackH4
                    data-qaid='createCategory_cancel_text'
                    label={Context.langData.cancel}
                  />
                </Button>
                <Button
                  className={style.buttonClass}
                  data-qaid='createCategory_save_btn'
                  variant='contained'
                  style={{
                    backgroundColor: "#FFB600",
                    marginRight: 32,
                    height: 40,
                    width: 90,
                  }}
                  onClick={saveCategory}>
                  <AvenirBlackH4
                    data-qaid='createCategory_save_text'
                    label={Context.langData.save}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    </RightDrawer>
  );
};

export default CreateCategory;
