import React, { useState, useEffect, useContext } from "react";
import { Grid, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import {
  fetchAllVendors,
  deleteVendors,
  fetchAllStocks,
} from "../../../redux/actions";
import MyContext from "../../../Provider/MyContext";
import DeleteIcon from "../../../components/SVG/Delete";
import CirclePluse from "../../../components/SVG/PluseIcon";
import { H4, AvenirBlackH4 } from "../../../utils/text";
import DataTable from "../../../components/DataTable";
import NoData from "../../../components/NoData";
import SnackBar from "../../../components/SnackBar";
import { setPhoneNumber } from "../../../utils/util";
import AlertDialog from "../../../components/Dialog";
import ViewVendorStock from "./viewVendorStock";
import CreateVendor from "./createVendor";
import EditVendors from "./editVendor";

const InventoryVendors = () => {
  const VendorsData = useSelector((state) => state.inventory.vendors);
  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState([]);
  const [btnDisable, setBtnDisable] = useState(true);
  const [row, setRow] = useState({});
  const [rowView, setRowView] = useState({});
  const [openEdit, toggleEditDrawer] = useState(false);
  const [newVendor, setNewVendor] = useState(false);
  const [viewVendor, setViewVendor] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const Context = useContext(MyContext);
  const [snackbar, setSnackbar] = useState(null);
  const [heading, setHeading] = useState(Context.langData.delete_vendor_header);
  const [bodyMessage, setBodyMessage] = useState(
    Context.langData.delete_undo_body_msg
  );
  const [columns, setColumns] = useState([
    {
      field: "name",
      headerName: Context.langData.vendor,
      size: "30%",
      sort: false,
    },
    {
      field: "mob",
      headerName: Context.langData.phoneNumber,
      size: "25%",
      sort: false,
    },
    {
      field: "email",
      headerName: Context.langData.email_header,
      size: "25%",
      sort: false,
    },
    {
      field: "view",
      headerName: Context.langData.stock_items,
      size: "20%",
      sort: false,
    },
  ]);
  const [vendorList, setVendorList] = useState([]);
  useEffect(() => {
    if (VendorsData) {
      VendorsData.map((vendor) => {
        vendor.mob = setPhoneNumber(vendor.mob);
        vendor.view = (
          <span
            onClick={() => {
              onViewClick(vendor);
            }}
            style={{ textDecoration: "underline", cursor: "pointer" }}>
            <H4 label={Context.langData.view} style={{ color: "#004C60" }} />
          </span>
        );
      });
    }
    setVendorList(VendorsData.reverse());
  }, [VendorsData]);
  const onViewClick = (rowValue) => {
    setRow(rowValue);
    setViewVendor(true);
  };
  useEffect(() => {
    dispatch(fetchAllVendors());
    dispatch(fetchAllStocks());
  }, []);
  const btnProps = {};
  if (btnDisable) {
    btnProps.disabled = true;
  }

  const onCheckedIds = (ids) => {
    setSelectedId(ids);
    if (ids.length > 0) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  };
  function onClickAction(rowValue, event) {
    setRow(rowValue);
    toggleEditDrawer(true);
  }
  function commanCallback() {
    setViewVendor(false);
    setNewVendor(false);
    toggleEditDrawer(false);
    dispatch(fetchAllVendors());
  }

  const onClickView = (rowValue) => {
    setRowView(rowValue);
    setViewVendor(true);
  };

  const handleCreateVendor = () => {
    setNewVendor(true);
  };

  const handleClose = (val) => {
    if (val) {
      selectedId.forEach((vid) => {
        dispatch(deleteVendors(vid, deleteCallback));
      });
    }
    setShowDialog(false);
  };
  function deleteVendor(value) {
    if (selectedId.length > 0) {
      if (selectedId.length > 1) {
        setHeading(
          Context.langData.delete_vendor_header +
            Context.langData.delete_plural_text
        );
      } else {
        setHeading(
          Context.langData.delete_vendor_header + Context.langData.delete_single
        );
      }
      setShowDialog(value);
    }
  }
  const sortingOnClick = (value, sortValue, index) => {
    const columnData = [...columns];
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setColumns(columnData);
    const sortVendors = _.orderBy(
      vendorList,
      [
        (d) =>
          typeof d[value] === "string" ? d[value].toLowerCase() : d[value],
      ],
      [sortValue ? "desc" : "asc"]
    );
    setVendorList(sortVendors);
  };
  const createCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.vendor_create_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.vendor_create_failed,
        severity: "error",
      });
    }
    commanCallback();
  };
  const editCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.vendor_edit_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.vendor_edit_failed,
        severity: "error",
      });
    }
    commanCallback();
  };
  const deleteCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.vendor_delete_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.vendor_delete_failed,
        severity: "error",
      });
    }
    commanCallback();
  };
  const assignStockCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.vendor_stock_assign_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.vendor_stock_assign_failed,
        severity: "error",
      });
    }
    commanCallback();
  };
  const ContextMenuData = [
    {
      id: 1,
      icon: DeleteIcon,
      name: Context.langData.delete,
    },
  ];

  const contextMenuCallBack = (id) => {
    if (id == 1) {
      deleteVendor(true);
    }
  };

  return (
    <Grid data-qaid='vendors_mainContainer' container>
      <>
        {vendorList.length > 0 ? (
          <Grid item style={{ width: "100%", padding: 20 }}>
            <Grid
              data-qaid='vendors_subContainer1'
              style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                data-qaid='vendors_deleteButton'
                style={{ color: "white", marginRight: 10, height: 40 }}
                variant='text'
                id='cancel'
                onClick={() => deleteVendor(true)}>
                <DeleteIcon
                  data-qaid='vendors_deleteIcon'
                  width={16}
                  height={16}
                  style={{ marginRight: 5 }}
                />
                <AvenirBlackH4
                  data-qaid='vendors_deleteLabel'
                  label={Context.langData.delete}
                />
              </Button>
              <Button
                data-qaid='vendors_createVendorButton'
                style={{
                  backgroundColor: "#FFB600",
                  color: "white",
                  marginRight: 28,
                  height: 40,
                }}
                variant='contained'
                id='cancel'
                onClick={handleCreateVendor}>
                <CirclePluse data-qaid='vendors_createVendorPulse' />
                <AvenirBlackH4
                  data-qaid='vendors_createVendorLabel'
                  label={Context.langData.create_vendor}
                  style={{ paddingLeft: 5 }}
                />
              </Button>
              {/* <ContextMenu style={{  }} menuItems={ContextMenuData} callBack={contextMenuCallBack} /> */}
            </Grid>
            <Grid
              data-qaid='vendors_subContainer2'
              item
              xs={12}
              style={{ marginTop: "20px" }}>
              <DataTable
                data-qaid='vendors_datatable'
                columns={columns}
                rows={vendorList}
                onCheckedIds={onCheckedIds}
                onClickAction={onClickAction}
                style={{ width: "100%" }}
                sortingOnClick={sortingOnClick}
              />
            </Grid>
          </Grid>
        ) : (
          <NoData page='vendor' callback={handleCreateVendor} />
        )}

        <Grid item xs={12}>
          {openEdit && (
            <EditVendors
              open={openEdit}
              row={row}
              onClose={() => toggleEditDrawer(false)}
              callback={editCallback}
            />
          )}

          {newVendor && (
            <CreateVendor
              open={newVendor}
              onClose={() => setNewVendor(false)}
              callback={createCallback}
            />
          )}

          {viewVendor && (
            <ViewVendorStock
              open={viewVendor}
              row={row}
              onClose={() => setViewVendor(false)}
              callback={assignStockCallback}
            />
          )}
          {snackbar && (
            <SnackBar
              data-qaid='taxeslist_snackbar'
              open
              setOpen={() => setSnackbar(null)}
              severity={snackbar.severity}
              message={snackbar.message}
            />
          )}
          <AlertDialog
            showExtra={false}
            showDialog={showDialog}
            body={bodyMessage}
            heading={heading}
            IsError={false}
            handleClose={handleClose}
          />
        </Grid>
      </>
    </Grid>
  );
};

export default InventoryVendors;
