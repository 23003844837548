import config from "../config.json";

export class SsoWarningTimer {
  constructor({ timeout, onTimeout, onExpired, store }) {
    /**
     * SsoWarningTimer
     * Class that handles the timing of the session timeout warning popup
     * Pulls the warning time from the config, and saves the warning timestamp to localStorage
     *
     * timeout (date/int) :the timestamp where the session times out on. The warning time difference is accounted for in the class
     * onTimeout (function): calls when the time runs out
     */
    this.timeout = timeout;
    this.onTimeout = onTimeout;
    this.store = store;
    this.timerKey = "_warningTime";
    const expiredTime = parseInt(localStorage.getItem(this.timerKey), 10);
    if (expiredTime > 0 && expiredTime < Date.now()) {
      onExpired();
      return;
    }
    this.setExpiredTime();
    this.startInterval();
  }

  startInterval() {
    this.interval = setInterval(() => {
      const expiredTime = parseInt(localStorage.getItem(this.timerKey), 10);
      if (expiredTime < Date.now()) {
        const { oauth_token } = this.store.getState().loginReducer.loginDetails;
        if (oauth_token) {
          if (this.onTimeout) {
            this.onTimeout();
            this.cleanUp();
          }
        }
      }
    }, 1000);
  }

  setExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      // Store the timestamp in localStorage to persist across pages
      localStorage.setItem(
        this.timerKey,
        this.timeout - config.sessionTimeoutWarning * 1000
      );
    }, 1000);
  }

  cleanUp() {
    localStorage.removeItem(this.timerKey);
    clearInterval(this.interval);
  }
}

export class SsoTimer {
  constructor({ timeout, onTimeout, onExpired, store }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;
    this.store = store;
    this.timerKey = "_expiredTime";
    const expiredTime = parseInt(localStorage.getItem(this.timerKey), 10);
    if (expiredTime > 0 && expiredTime < Date.now()) {
      onExpired();
      return;
    }
    this.setExpiredTime();
    this.startInterval();
  }

  startInterval() {
    this.interval = setInterval(() => {
      const expiredTime = parseInt(localStorage.getItem(this.timerKey), 10);
      if (expiredTime < Date.now()) {
        const { oauth_token } = this.store.getState().loginReducer.loginDetails;
        if (oauth_token) {
          if (this.onTimeout) {
            this.onTimeout();
            this.cleanUp();
          }
        }
      }
    }, 1000);
  }

  setExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      // Store the timestamp in localStorage to persist across pages
      localStorage.setItem(this.timerKey, this.timeout);
    }, 300);
  }

  cleanUp() {
    localStorage.removeItem(this.timerKey);
    clearInterval(this.interval);
  }
}
