import React, { useEffect, useState, useContext } from "react";
import { Grid, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { deleteFulfillment, saveAmountPaid } from "../../../../redux/actions";
import MyContext from "../../../../Provider/MyContext";
import InventoryIcon from "../../../../components/SVG/InventoryIcon";
import CircleClose from "../../../../components/SVG/CircleClose";
import {
  AvenirBlackH4,
  H4,
  H5,
  H6,
  AvenirBlackH5,
  DotTextBoldNormal,
} from "../../../../utils/text";
import InputText from "../../../../components/InputText";
import { ConvertPrice, parseToFloat } from "../../../../utils/util";
import styles from "../../../../styles/index";
import AlertDialog from "../../../../components/Dialog";

const EditFulfillment = (props) => {
  const style = styles();
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  const indentViewData = useSelector((state) => state.inventory.indentViewdata);
  const [amountPaid, setAmountPaid] = useState(0);
  const [balance, setBalance] = useState(0);
  const [notes, setNotes] = useState("");
  const [error, setError] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [heading, setHeading] = useState(
    Context.langData.delete_fulfillment_header
  );
  const [bodyMessage, setBodyMessage] = useState(
    Context.langData.delete_undo_body_msg
  );
  const { fulfillment, onClose, deleteCallback, saveCallback } = props;

  useEffect(() => {
    console.log(indentViewData.status);
    console.log(JSON.stringify(fulfillment));
    let amountPaid = 0;
    if (fulfillment.amountPaid != null) {
      amountPaid = ConvertPrice(fulfillment.amountPaid.amount / 100);
      setAmountPaid(amountPaid);
    }
    if (fulfillment.note !== "") {
      setNotes(fulfillment.note);
    }
    setBalance(TotalAmount() - amountPaid);
    setError("");
  }, []);
  const handleSave = () => {
    if (parseToFloat(amountPaid) > TotalAmount()) {
      setError(Context.langData.fulfillment_error);
    } else {
      const reqBody = {
        amountPaid: {
          amount: Math.round(parseToFloat(amountPaid) * 100),
          currency: "CAD",
        },
      };

      dispatch(
        saveAmountPaid(indentViewData.id, fulfillment.id, reqBody, saveCallback)
      );
    }
  };

  const handleDelete = () => {
    setHeading(
      Context.langData.delete_fulfillment_header +
        Context.langData.delete_single
    );
    setShowDialog(true);
  };
  const handleCloseAlert = (val) => {
    if (val) {
      dispatch(
        deleteFulfillment(indentViewData.id, fulfillment.id, deleteCallback)
      );
    }
    setShowDialog(false);
  };

  const TotalTax = () => {
    let total = 0;
    if (fulfillment?.items?.length > 0) {
      fulfillment.items.map((item) => {
        total += item.tax?.amount;
      });
    }
    return total / 100;
  };
  const TotalAmount = () => {
    let total = 0;
    if (fulfillment?.items?.length > 0) {
      fulfillment.items.map((itemObj) => {
        total +=
          itemObj.unitPrice?.amount * itemObj.fulfilledQuantity +
          itemObj.tax?.amount;
      });
    }
    total += fulfillment?.deliveryCharges?.amount;
    return total / 100;
  };

  const prefixDollar = (value) => `$${value}`;
  const handleAmount = (val) => {
    console.log(`handleAmount: ${val}`);
    const validated = val.match(/^(\d*\.{0,1}\d{0,2}$)/);
    if (validated) {
      setAmountPaid(val);
      // update balance
      setBalance(TotalAmount() - val);
    }
  };

  const handleAmountOnBlurChange = () => {
    console.log(`handleAmountOnBlurChange: ${amountPaid}`);
    let amount = amountPaid;
    if (typeof amount === "string") {
      amount = amount.replace(/\,/g, "");
    }
    setAmountPaid(ConvertPrice(amount));
  };

  const handleFocus = (val) => {
    console.log(`handleFocus: ${val}`);
    const commaVal = val.replace(/\,/g, "");
    const validated = commaVal.match(/^(\d*\.{0,1}\d{0,2}$)/);
    if (validated) {
      setAmountPaid(parseToFloat(val));
      // update balance
      setBalance(TotalAmount() - parseToFloat(val));
    }
  };

  return (
    <Grid
      data-qaid='viewIndentId_body_grid'
      style={{ display: "flex", height: "100%", flexDirection: "column" }}>
      <Grid
        data-qaid='viewIndentId_child1_grid'
        style={{
          display: "flex",
          height: error ? "80%" : "85%",
          flexDirection: "column",
          overflowX: "auto",
        }}>
        <Grid
          data-qaid='viewIndentId_close_grid'
          style={{
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}>
          <CircleClose
            data-qaid='viewIndentId_closeicon'
            size={20}
            onClick={onClose}
            style={{ marginRight: 40, marginTop: 20 }}
          />
        </Grid>
        <Grid
          data-qaid='viewIndentId_settings_grid'
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            marginLeft: 56,
            marginBottom: 10,
          }}>
          <InventoryIcon
            data-qaid='viewIndentId_setting_icon'
            style={{ marginRight: 10 }}
            width={22.5}
            height={24}
            color='#707272'
          />
          <H4
            data-qaid='viewIndentId_settings'
            label={Context.langData.inventory}
            style={{ color: "#707272" }}
          />
        </Grid>
        <H6
          data-qaid='viewIndentId_title'
          style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
          label={Context.langData.edit_fulfillment}
        />
        <Grid
          data-qaid='viewIndentId_main_grid'
          style={{
            marginLeft: 56,
            marginBottom: 20,
            marginRight: 56,
            width: 500,
            display: "flex",
            flexDirection: "column",
          }}>
          <Grid
            data-qaid='viewIndentId_vendorContainer'
            container
            style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Grid
              data-qaid='viewIndentId_vendorGrid'
              item
              xs={6}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "50%",
              }}>
              <DotTextBoldNormal
                data-qaid='rviewIndentId_vendorLabel'
                label={`${Context.langData.vendor}: ${indentViewData.vendorName}`}
                style={{ marginBottom: 10, width: "100%" }}
              />
            </Grid>
            <Grid
              data-qaid='viewIndentId_dateGrid'
              item
              xs={6}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                width: "50%",
              }}>
              <AvenirBlackH4
                data-qaid='viewIndentId_dateLabel'
                label={`${Context.langData.date_received}: ${moment(indentViewData.raiseDate).format("YYYY-MM-DD")}`}
                style={{ marginBottom: 10 }}
              />
            </Grid>
          </Grid>
          <Grid
            data-qaid='viewIndentId_subContainer2'
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: 10,
            }}>
            <Grid
              data-qaid='viewIndentId_billnoGrid'
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "50%",
              }}>
              <AvenirBlackH4
                data-qaid='rviewIndentId_billnoLabel'
                label={`${Context.langData.bill_no}`}
                style={{ marginBottom: 10 }}
              />
              <InputText
                style={{ width: "100%" }}
                name='billno'
                disabled
                maxLength={128}
                inputPlaceholder={fulfillment.billNumber}
                value={fulfillment.billNumber}
                id='fulfillmentBillNo'
              />
            </Grid>
            <Grid
              data-qaid='viewIndentId_amountGrid'
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "50%",
                marginLeft: 20,
              }}>
              <AvenirBlackH4
                data-qaid='viewIndentId_amountLabel'
                label={`${Context.langData.amount_paid}`}
                style={{ marginBottom: 10 }}
              />
              <InputText
                style={{ width: "100%" }}
                disabled={indentViewData.status === "Closed"}
                isPlaceHolder={false}
                inputPlaceholder='$0.00'
                name='amount'
                maxLength={8}
                id='fulfillmentAmountPaid'
                value={`${amountPaid == 0.0 ? "" : `$${amountPaid}`}`}
                onChange={(e) => handleAmount(e.target.value.replace("$", ""))}
                onBlur={() => {
                  handleAmountOnBlurChange();
                }}
                onFocus={(e) => handleFocus(e.target.value.replace("$", ""))}
              />
            </Grid>
          </Grid>
          <Grid
            data-qaid='viewIndentId_subContainer3'
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: 10,
            }}>
            <Grid
              data-qaid='viewIndentId_totalamountGrid'
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "50%",
              }}>
              <AvenirBlackH4
                data-qaid='rviewIndentId_totalAmountLabel'
                label={`${Context.langData.total_amount}`}
                style={{ marginBottom: 10 }}
              />
              <InputText
                style={{ width: "100%" }}
                disabled
                isPlaceHolder={false}
                placeholder='$0.00'
                name='total_amount'
                id='fulfillmentTotalAmount'
                value={`${prefixDollar(ConvertPrice(TotalAmount()))}`}
              />
            </Grid>
            <Grid
              data-qaid='viewIndentId_deliveryGrid'
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "50%",
                marginLeft: 20,
              }}>
              <AvenirBlackH4
                data-qaid='viewIndentId_deliveryLabel'
                label={`${Context.langData.delivery_charges}`}
                style={{ marginBottom: 10 }}
              />
              <InputText
                style={{ width: "100%" }}
                isPlaceHolder={false}
                disabled
                name='delivery_charges'
                inputPlaceholder='$0.00'
                maxLength={8}
                value={`${prefixDollar(ConvertPrice(fulfillment.deliveryCharges?.amount === 0 ? 0 : fulfillment.deliveryCharges.amount / 100))}`}
                id='fulfillmentDelivery'
              />
            </Grid>
          </Grid>
          <Grid
            data-qaid='viewIndentId_subContainer4'
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: 10,
            }}>
            <Grid
              data-qaid='viewIndentId_balanceGrid'
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "50%",
              }}>
              <AvenirBlackH4
                data-qaid='rviewIndentId_balanceLabel'
                label={`${Context.langData.balance}`}
                style={{ marginBottom: 10 }}
              />
              <InputText
                style={{ width: "100%" }}
                disabled
                isPlaceHolder={false}
                placeholder='$0.00'
                name='balance'
                id='fulfillmentBalance'
                value={`${prefixDollar(ConvertPrice(balance))}`}
                onChange={(e) => {
                  console.log("//");
                }}
              />
            </Grid>
            <Grid
              data-qaid='viewIndentId_totalTaxGrid'
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "50%",
                marginLeft: 20,
              }}>
              <AvenirBlackH4
                data-qaid='viewIndentId_totalTaxLabel'
                label={`${Context.langData.total_tax}`}
                style={{ marginBottom: 10 }}
              />
              <InputText
                style={{ width: "100%" }}
                disabled
                isPlaceHolder={false}
                placeholder='$0.00'
                name='total_tax'
                id='fulfillmentTax'
                value={`${prefixDollar(ConvertPrice(TotalTax()))}`}
                onChange={(e) => {
                  console.log("//");
                }}
              />
            </Grid>
          </Grid>
          <Grid
            data-qaid='viewIndentId_noteGrid'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              width: "100%",
            }}>
            <AvenirBlackH4
              data-qaid='rviewIndentId_noteLabel'
              label={`${Context.langData.note}`}
              style={{ marginBottom: 10 }}
            />
            <InputText
              style={{ width: "100%" }}
              name='note'
              id='fulfillmentNotes'
              value={notes}
              isPlaceHolder={false}
              disabled
              maxLength={512}
            />
          </Grid>
          <Grid
            data-qaid='viewIndentId_table_body'
            item
            style={{
              marginTop: "20px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}>
            <Grid
              data-qaid='viewIndentId_subContainer3'
              item
              style={{
                height: "40px",
                width: "100%",
                backgroundColor: "#F3FAFD",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}>
              <Grid
                data-qaid='viewIndentId_dateGrid'
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  width: "20%",
                }}>
                <AvenirBlackH4
                  data-qaid='viewIndentId_itemHeading'
                  label={Context.langData.items}
                  style={{ paddingLeft: 20 }}
                />
              </Grid>
              <Grid
                data-qaid='viewIndentId_dateGrid'
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  width: "16%",
                }}>
                <AvenirBlackH4
                  data-qaid='viewIndentId_request_qtyHeading'
                  label={Context.langData.requested}
                />
              </Grid>
              <Grid
                data-qaid='viewIndentId_dateGrid'
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  width: "16%",
                }}>
                <AvenirBlackH4
                  data-qaid='viewIndentId_request_qtyHeading'
                  label={Context.langData.received}
                />
              </Grid>
              <Grid
                data-qaid='viewIndentId_dateGrid'
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  width: "16%",
                }}>
                <AvenirBlackH4
                  data-qaid='viewIndentId_request_qtyHeading'
                  label={Context.langData.unit_price}
                />
              </Grid>
              <Grid
                data-qaid='viewIndentId_dateGrid'
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  width: "16%",
                }}>
                <AvenirBlackH4
                  data-qaid='viewIndentId_request_qtyHeading'
                  label={Context.langData.tax}
                />
              </Grid>
              <Grid
                data-qaid='viewIndentId_dateGrid'
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  width: "16%",
                }}>
                <AvenirBlackH4
                  data-qaid='viewIndentId_request_qtyHeading'
                  label={Context.langData.price}
                  style={{ marginRight: 4 }}
                />
              </Grid>
            </Grid>
            {fulfillment?.items?.length > 0
              ? fulfillment.items.map((itemObj, index) => (
                  <Grid
                    key={itemObj.stock_name}
                    data-qaid='viewIndentId_subContainer3'
                    item
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      backgroundColor: "#FAFAFA",
                      minHeight: 50,
                      marginTop: 6,
                    }}>
                    <Grid
                      data-qaid='viewIndentId_dateGrid'
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        width: "20%",
                      }}>
                      <H5
                        data-qaid='viewIndentId_itemHeading'
                        label={`${itemObj.name}`}
                        style={{ paddingLeft: 20 }}
                      />
                    </Grid>
                    <Grid
                      data-qaid='viewIndentId_dateGrid'
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        width: "16%",
                      }}>
                      <H5
                        data-qaid='viewIndentId_request_qtyHeading'
                        label={`${itemObj.requestedQuantity} ${itemObj.unitTypeCode}`}
                      />
                    </Grid>
                    <Grid
                      data-qaid='viewIndentId_dateGrid'
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        width: "15%",
                        marginRight: "1%",
                      }}>
                      <H5
                        data-qaid='viewIndentId_receive_qtyHeading'
                        label={`${itemObj.fulfilledQuantity} ${itemObj.unitTypeCode}`}
                      />
                    </Grid>
                    <Grid
                      data-qaid='viewIndentId_dateGrid'
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        width: "15%",
                        marginRight: "1%",
                      }}>
                      <H5
                        data-qaid='viewIndentId_unit_priceHeading'
                        label={prefixDollar(
                          ConvertPrice(itemObj.unitPrice.amount / 100)
                        )}
                      />
                    </Grid>
                    <Grid
                      data-qaid='viewIndentId_dateGrid'
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        width: "15%",
                        marginRight: "1%",
                      }}>
                      <H5
                        data-qaid='viewIndentId_tax_Heading'
                        label={prefixDollar(
                          ConvertPrice(itemObj.tax.amount / 100)
                        )}
                      />
                    </Grid>
                    <Grid
                      data-qaid='viewIndentId_dateGrid'
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        width: "16%",
                      }}>
                      <AvenirBlackH4
                        data-qaid='viewIndentId_request_qtyHeading'
                        label={`$${ConvertPrice(
                          ((parseToFloat(itemObj.tax.amount) || 0) +
                            parseToFloat(itemObj.unitPrice.amount) *
                              parseToFloat(itemObj.fulfilledQuantity)) /
                            100
                        )}`}
                        style={{ marginRight: 4 }}
                      />
                    </Grid>
                  </Grid>
                ))
              : null}
          </Grid>
        </Grid>
      </Grid>
      {error && (
        <Grid
          style={{
            height: "5%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <AvenirBlackH4
            label={error}
            style={{
              color: "#AB1212",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        </Grid>
      )}
      <Grid
        data-qaid='createGroup_cancel_main_Grid'
        style={{
          display: "flex",
          height: "15%",
          flexDirection: "column",
          justifyContent: "flex-end",
          backgroundColor: "#FAFAFA",
          border: "1px solid #C3C3C3",
          marginTop: 2,
        }}>
        <Grid
          data-qaid='createGroup_cancelGrid'
          item
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            paddingTop: "25px",
            alignItems: "center",
            paddingBottom: "30px",
            paddingRight: 25,
          }}>
          {indentViewData.status !== "Closed" ? (
            <Button
              className={style.buttonClass}
              data-qaid='createGroup_saveButton'
              style={{
                backgroundColor: "#FFB600",
                marginRight: 220,
                width: 90,
                height: 40,
              }}
              variant='contained'
              id='cancel'
              type='submit'
              onClick={() => handleDelete()}>
              <AvenirBlackH5
                data-qaid='createGroup_saveLabel'
                label={Context.langData.delete_fulfillment}
              />
            </Button>
          ) : null}
          {indentViewData.status !== "Closed" ? (
            <Button
              className={style.buttonClass}
              data-qaid='createGroup_cancelButton'
              style={{
                backgroundColor: "#FFF",
                border: "2px solid #051D33",
                marginRight: 10,
                width: 103,
                height: 40,
                borderColor: "#707272",
              }}
              variant='contained'
              id='cancel'
              onClick={onClose}>
              <AvenirBlackH4
                data-qaid='createGroup_cancelLabel'
                label={Context.langData.cancel}
                style={{ color: "#004C60" }}
              />
            </Button>
          ) : (
            <Button
              className={style.buttonClass}
              data-qaid='createGroup_cancelButton'
              style={{
                backgroundColor: "#FFF",
                border: "2px solid #051D33",
                marginRight: 10,
                width: 103,
                height: 40,
                borderColor: "#707272",
              }}
              variant='contained'
              id='cancel'
              onClick={onClose}>
              <AvenirBlackH4
                data-qaid='createGroup_cancelLabel'
                label={Context.langData.close}
                style={{ color: "#004C60" }}
              />
            </Button>
          )}

          {indentViewData.status !== "Closed" ? (
            <Button
              className={style.buttonClass}
              data-qaid='createGroup_saveButton'
              style={{
                backgroundColor: "#FFB600",
                marginRight: 10,
                width: 90,
                height: 40,
              }}
              variant='contained'
              id='cancel'
              type='submit'
              onClick={() => handleSave()}>
              <AvenirBlackH4
                data-qaid='createGroup_saveLabel'
                label={Context.langData.save}
              />
            </Button>
          ) : null}
        </Grid>
      </Grid>
      <AlertDialog
        showExtra={false}
        showDialog={showDialog}
        body={bodyMessage}
        heading={heading}
        IsError={false}
        handleClose={handleCloseAlert}
      />
    </Grid>
  );
};

export default EditFulfillment;
