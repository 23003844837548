import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import { H6 } from "../utils/text";
import MyContext from "../Provider/MyContext";

const NoPermission = () => {
  const Context = useContext(MyContext);
  return (
    <Grid
      data-qaid='transactions_index_grid'
      style={{ backgroundColor: "#FFFFFF", minHeight: "100%", width: "100%" }}>
      <Grid
        data-qaid='dashboard_nopermissions_View'
        item
        lg={12}
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          marginTop: "20%",
        }}>
        <H6
          data-qaid='dashboard_nopermissions_message'
          label={Context.langData.no_permission_error}
          style={{ textAlign: "center", width: "55%", fontWeight: "bold" }}
        />
      </Grid>
    </Grid>
  );
};

export default NoPermission;
