import React, { useState, useEffect, useContext, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Checkbox } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  createGroup,
  fetchgroupDetails,
  fetchAllCategories,
} from "../../../redux/actions";
import RightDrawer from "../../../components/RightDrawer";
import Daterange from "../../../components/DateRange";
import {
  AvenirBlackH4,
  AvenirBlackH2,
  H4,
  H8,
  TBold,
  DotText,
} from "../../../utils/text";
import MultiSelect from "../../../components/MultiSelect";
import MyContext from "../../../Provider/MyContext";
import ReportsTable from "../../../components/ReportsTable";
import CirclePluse from "../../../components/SVG/PluseIcon";
import PencileIcon from "../../../components/SVG/PencileIcon";
import DeleteIcon from "../../../components/SVG/Delete";
import ReportsIcon from "../../../components/SVG/Report";
import InputText from "../../../components/InputText";
import CircleClose from "../../../components/SVG/CloseCircle";
import AlertDialog from "../../../components/Dialog";

const useStyles = makeStyles({
  mainDiv: {
    border: "1px solid #f4f4f5",
    marginLeft: "1%",
  },
  itemSalesText: {
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "20px",
    height: "50px",
    paddingTop: "10px",
    paddingLeft: "1%",
  },
  tableHeading: {
    height: "50px",
    paddingTop: "10px",
    paddingLeft: "1%",
    backgroundColor: "#f4f4f5",
    marginTop: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
  itemHeadingText: {
    fontWeight: "bold",
    fontSize: "18px",
  },
  editIconStyles: {
    width: "70px",
    cursor: "pointer",
  },
});

const Groups = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [table1, setTable1] = useState([]);
  const groupDetails = useSelector(
    (state) => state.salesReportReducer.groupDetails
  );
  const categories = useSelector((state) => state.menu.categories);
  const [showEditGroup, setShowEditGroup] = useState(false);
  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const [row, setRow] = useState({});
  const Context = useContext(MyContext);
  const ordertypes = useSelector((state) => state.metaReducer.ordertypes);
  const [date, setDate] = useState({
    startDate: moment().startOf("day").format("YYYY-MM-DD"),
    endDate: moment().endOf("day").format("YYYY-MM-DD"),
  });
  const [ordertype, setOrderType] = useState([]);
  const [isEditIndex, setIsEditIndex] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [heading, setHeading] = useState(Context.langData.delete_groups);
  const [bodyMessage, setBodyMessage] = useState(
    Context.langData.delete_group_body
  );
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [groupColumns, setGroupColumns] = useState([
    { field: "name", display: "Categories", size: "20%" },
    { field: "quantity", display: "Total qty", size: "20%" },
    { field: "total_price", display: "Subtotal", size: "15%", isNumber: true },
    {
      field: "total_discount",
      display: "Discount",
      size: "15%",
      isNumber: true,
    },
    { field: "total_tax", display: "Taxes", size: "15%", isNumber: true },
    { field: "final_price", display: "Total", size: "15%", isNumber: true },
  ]);
  useEffect(() => {
    handleApplyDropdown();
  }, [date, ordertype]);
  useEffect(() => {
    dispatch(fetchAllCategories());
  }, []);
  useEffect(() => {
    if (groupDetails?.category_grouping?.length > 0) {
      const groups = [...groupDetails.category_grouping];
      groups.map((g) => {
        const temp = [];
        g.cat_id_list.map((cat_id) => {
          const cat = _.find(
            groupDetails.data.category_info,
            (info) => info.cat_id == cat_id
          );
          if (cat) {
            temp.push(cat);
          }
        });
        g.list = temp;
      });
      setTable1(groups);
    } else {
      setTable1([]);
    }
  }, [groupDetails]);

  const onDateChange = (d) => {
    if (JSON.stringify(date) !== JSON.stringify(d)) {
      setDate(d);
    }
  };
  const handleApplyDropdown = () => {
    let obj = {
      from_date: date.startDate,
      to_date: date.endDate,
    };
    if (ordertype.length > 0) {
      obj = {
        ...obj,
        order_types: ordertype.join(","),
      };
    }
    dispatch(fetchgroupDetails(obj));
  };
  const createGroupCallback = () => {
    handleApplyDropdown();
    setShowCreateGroup(false);
    setShowEditGroup(false);
    setDeleteIndex(null);
  };
  const handleClose = (val) => {
    if (val) {
      // if (deleteIndex) {
      const sendData = [...groupDetails?.category_grouping];
      sendData.splice(deleteIndex, 1);
      const sendObj = {
        category_grouping: sendData,
        cat_id_list: groupDetails?.cat_id_list,
        item_id_list: groupDetails?.item_id_list,
      };
      dispatch(createGroup(sendObj, createGroupCallback));
      // }
    }
    setShowDialog(false);
  };

  const EditGroupRightDrawer = (row, index) => {
    setIsEditIndex(index);
    setRow(row);
    setShowEditGroup(true);
  };
  const deleteGroup = (index) => {
    setDeleteIndex(index);
    setShowDialog(true);
  };

  const handleSort = (name, desc, index, catIndx) => {
    const columnData = [...groupColumns];
    columnData[index] = { ...columnData[index], desc };
    setGroupColumns(columnData);
    const catItems = table1[catIndx].list;
    let sortItemwiseSales;
    if (name == "name") {
      sortItemwiseSales = _.orderBy(
        catItems,
        [(catItem) => catItem[name].toLowerCase()],
        [desc ? "desc" : "asc"]
      );
    } else {
      sortItemwiseSales = _.orderBy(catItems, [name], [desc ? "desc" : "asc"]);
    }
    table1[catIndx].list = sortItemwiseSales;
    setTable1(table1);
  };

  return (
    <Grid
      data-qaid='groups_mainContainer'
      container
      style={{ paddingLeft: 20, paddingRight: 20 }}>
      <Grid data-qaid='groups_subContainer1' container>
        <Grid
          data-qaid='groups_dateContainer'
          item
          lg={8}
          style={{
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
          }}>
          <Grid data-qaid='groups_dateGrid' item style={{}}>
            <AvenirBlackH4
              data-qaid='groups_dateLabel'
              label={Context.langData.from_to}
            />
            <Daterange
              data-qaid='groups_dateRange'
              onDateChange={onDateChange}
            />
          </Grid>
          <Grid
            data-qaid='groups_orderTypeGrid'
            item
            style={{ paddingLeft: 20 }}>
            <AvenirBlackH4
              data-qaid='groups_orderTypeLabel'
              label={Context.langData.select_order_an_type}
            />
            <MultiSelect
              list={ordertypes}
              value='otype_id'
              name='otype_name'
              placeholder='All order types'
              selectedValue={ordertype}
              handleChange={(e) => setOrderType(e)}
              border={false}
              height={56}
              width={298}
            />
          </Grid>
        </Grid>
        <Grid
          data-qaid='groups_createGroupGrid'
          item
          lg={4}
          style={{
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 10,
          }}>
          <Button
            data-qaid='groups_createGroupButton'
            style={{
              backgroundColor: "#FFB600",
              height: 40,
              marginRight: 0,
              width: "149px",
            }}
            variant='contained'
            id='cancel'
            onClick={() => {
              setShowCreateGroup(true);
            }}>
            <CirclePluse data-qaid='groups_circlePluse' />
            <AvenirBlackH4
              data-qaid='groups_createGroupLabel'
              label={Context.langData.create_group}
              style={{ paddingLeft: 5 }}
            />
          </Button>
          {/* <ContextMenu /> */}
        </Grid>
      </Grid>
      <Grid data-qaid='groups_subContainer2' item lg={12}>
        <Grid
          data-qaid='groups_ReportsTableGrid'
          item
          style={{ marginTop: 20 }}>
          {table1.length > 0 ? (
            table1.map((item, index) => (
              <>
                <Grid
                  data-qaid='groups_HeadingLabelGrid'
                  container
                  style={{
                    marginTop: 40,
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    marginBottom: 20,
                    marginLeft: 10,
                    paddingRight: 30,
                  }}>
                  <Grid item>
                    <AvenirBlackH2
                      data-qaid='groups_HeadingLabel'
                      label={item.group_name}
                      style={{ fontWeight: "bold" }}
                    />
                  </Grid>
                  <Grid item style={{ display: "flex" }}>
                    <Grid
                      data-qaid='groups_editLabelGrid'
                      item
                      style={{
                        display: "flex",
                        marginRight: 20,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        EditGroupRightDrawer(item, index);
                      }}>
                      <PencileIcon />
                      <AvenirBlackH4
                        data-qaid='groups_editLabel'
                        label={Context.langData.edit}
                        style={{ marginLeft: 5 }}
                      />
                    </Grid>
                    <Grid
                      data-qaid='groups_deleteLabelGrid'
                      item
                      style={{ display: "flex", cursor: "pointer" }}
                      onClick={() => deleteGroup(index)}>
                      <DeleteIcon
                        data-qaid='groups_deleteIcon'
                        width={16}
                        height={16}
                        style={{ marginRight: 5 }}
                      />
                      <AvenirBlackH4
                        data-qaid='groups_deleteLabel'
                        label={Context.langData.delete}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <ReportsTable
                  data-qaid='groups_ReportsTable'
                  handleSort={(name, desc, colIndex) =>
                    handleSort(name, desc, colIndex, index)
                  }
                  columns={groupColumns}
                  rows={item.list}
                />
              </>
            ))
          ) : (
            <Grid
              item
              lg={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                paddingTop: 20,
              }}>
              <TBold
                label={Context.langData.no_results_available}
                style={{ fontSize: 32, color: "#051D33", marginTop: 20 }}
              />
            </Grid>
          )}
        </Grid>
        {showEditGroup && (
          <EditGroup
            open={showEditGroup}
            onClose={() => setShowEditGroup(false)}
            row={row}
            data={groupDetails}
            callback={createGroupCallback}
            isEdit={isEditIndex}
          />
        )}
        {showCreateGroup && (
          <EditGroup
            open={showCreateGroup}
            onClose={() => setShowCreateGroup(false)}
            data={groupDetails}
            callback={createGroupCallback}
          />
        )}
        <AlertDialog
          data-qaid='morelist_alertdialog'
          showDialog={showDialog}
          body={bodyMessage}
          heading={heading}
          IsError={false}
          handleClose={handleClose}
        />
      </Grid>
    </Grid>
  );
};

const EditGroup = ({
  open,
  onClose,
  row = null,
  data,
  callback,
  isEdit = null,
}) => {
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const [groupName, setGroupName] = useState("");
  const groupList = useSelector((state) => state.menu.categories);
  console.log("GROUPS", data, isEdit);
  const [checkId, setCheckId] = useState([]);
  const [error, setError] = useState("");
  const [title, setTitle] = useState(Context.langData.create_group);
  const checkIt = (id) => {
    const setCheckedList = [...checkId];
    const index = setCheckedList.indexOf(id);
    if (index == -1) {
      setCheckedList.push(id);
    } else {
      setCheckedList.splice(index, 1);
    }
    setCheckId(setCheckedList);
  };
  const isGroupNameExist = useMemo(() => {
    const groups = data?.category_grouping;
    if (groups) {
      const index = groups.findIndex(
        (e) => e.group_name.toLowerCase() == groupName.toLowerCase()
      );

      if (index !== -1) {
        if (index !== isEdit) {
          setError(Context.langData.group_name_exist);
          return true;
        }
      }
    }
    setError("");
    return false;
  }, [data, groupName, isEdit]);

  useEffect(() => {
    console.log("rowrowrowrow", row);
    if (row) {
      setGroupName(row.group_name);
      setCheckId(row.cat_id_list);
    }
  }, [row]);
  const handleSave = () => {
    if (!isGroupNameExist) {
      if (groupName) {
        if (checkId.length > 0) {
          const sendData = [...data?.category_grouping];
          let sendObj = {};
          if (sendData) {
            if (isEdit !== null) {
              sendData[isEdit] = {
                ...sendData[isEdit],
                group_name: groupName,
                cat_id_list: checkId,
              };
              sendObj = {
                category_grouping: sendData,
                cat_id_list: data?.cat_id_list,
                item_id_list: data?.item_id_list,
              };
            } else {
              const obj = {
                group_name: groupName,
                cat_id_list: checkId,
              };
              sendData.push(obj);
              sendObj = {
                category_grouping: sendData,
                cat_id_list: data?.cat_id_list,
                item_id_list: data?.item_id_list,
              };
            }
            console.log("");
            dispatch(createGroup(sendObj, callback));
          }
        } else {
        }
      } else {
        setError(Context.langData.no_text_error);
      }
    }
  };
  return (
    <RightDrawer data-qaid='creategroup_centermodal' open={open} padding={1}>
      <>
        <Grid
          style={{ display: "flex", height: "100%", flexDirection: "column" }}>
          <Grid
            style={{
              display: "flex",
              height: "85%",
              flexDirection: "column",
              overflowX: "auto",
            }}>
            <Grid
              data-qaid='creategroup_close_grid'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}>
              <CircleClose
                data-qaid='creategroup_closeicon'
                size={20}
                onClick={onClose}
                style={{ marginRight: 40, marginTop: 20 }}
              />
            </Grid>
            <Grid
              data-qaid='creategroup_settings_grid'
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 56,
                marginBottom: 10,
              }}>
              <ReportsIcon
                data-qaid='creategroup_setting_icon'
                style={{ marginRight: 10 }}
                width={22.5}
                height={24}
                color='#707272'
              />
              <H4
                data-qaid='creategroup_settings'
                label={Context.langData.reports}
                style={{ color: "#707272" }}
              />
            </Grid>
            <H8
              data-qaid='creategroup_title'
              style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
              label={title}
            />
            <Grid
              data-qaid='creategroup_main_grid'
              container
              direction='column'
              style={{
                marginLeft: 56,
                marginBottom: 20,
                marginRight: 56,
                width: 500,
              }}>
              <Grid
                data-qaid='creategroup_name_grid'
                style={{ display: "flex", flexDirection: "column" }}>
                <AvenirBlackH4
                  data-qaid='creategroup_namelabel'
                  label={Context.langData.group_name}
                  style={{ marginBottom: 12 }}
                />
                <InputText
                  data-qaid='creategroup_nameinput'
                  value={groupName}
                  error={error}
                  onChange={(val) => setGroupName(val.target.value)}
                  id='name'
                />
              </Grid>
              <Grid style={{ display: "flex", flexFlow: "wrap" }}>
                {groupList.map((item, index2) => (
                  <Grid
                    key={index2}
                    style={{
                      width: "28%",
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                      marginRight: 20,
                    }}>
                    <Checkbox
                      id={item.name}
                      checked={
                        checkId ? checkId.indexOf(item.cat_id) !== -1 : false
                      }
                      onChange={() => checkIt(item.cat_id)}
                      style={{ color: "black" }}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                    <Grid style={{ width: 120 }}>
                      <DotText label={item.name} />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{
              display: "flex",
              height: "15%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid
              data-qaid='editmore_action_grid'
              container
              style={{ backgroundColor: "#FAFAFA" }}>
              <Grid
                data-qaid='editmore_cancel_btn'
                item
                xs={12}
                lg={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 32,
                  marginBottom: 32,
                }}>
                <Button
                  data-qaid='editmore_cancel_button'
                  onClick={onClose}
                  variant='contained'
                  style={{
                    backgroundColor: "#FFF",
                    marginRight: 16,
                    height: 40,
                    border: "2px solid #051D33",
                    width: 103,
                  }}>
                  <AvenirBlackH4
                    data-qaid='editmore_cancel_text'
                    label={Context.langData.cancel}
                  />
                </Button>
                <Button
                  data-qaid='editmore_save_btn'
                  variant='contained'
                  style={{
                    backgroundColor: "#FFB600",
                    marginRight: 32,
                    height: 40,
                    width: 90,
                  }}
                  onClick={handleSave}>
                  <AvenirBlackH4
                    data-qaid='editmore_save_text'
                    label={Context.langData.save}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    </RightDrawer>
  );
};
export default Groups;
