import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles({
  root: {
    width: 80,
    height: 48,
    padding: 8,
  },
  switchBase: {
    padding: 11,
    // color: '#ff6a00',
    color: "#004C60",
  },
  thumb: {
    width: 26,
    height: 26,
  },
  track: {
    // background: 'linear-gradient(to right, #C3C3C3, #C3C3C3)',
    background: "#FFF",
    border: "1px solid #004C60",
    opacity: "1 !important",
    borderRadius: 20,
    position: "relative",
    "&:before, &:after": {
      display: "inline-block",
      position: "absolute",
      top: "50%",
      width: "50%",
      transform: "translateY(-50%)",
      color: "#fff",
      textAlign: "center",
    },
    "&:before": {
      content: '"On"',
      left: 4,
      opacity: 0,
    },
    "&:after": {
      content: '"Off"',
      right: 4,
      color: "#004C60",
    },
  },
  checked: {
    color: "#fff",
    "&$switchBase": {
      color: "#004C60",
      transform: "translateX(32px)",
      "&:hover": {
        // backgroundColor: 'rgba(24,90,257,0.08)',
        // backgroundColor: '#C3C3C3',
      },
    },
    "& $thumb": {
      backgroundColor: "#fff",
    },
    "& + $track": {
      // background: 'linear-gradient(to right, #43cea2, #185a9d)',
      background: "#004C60",
      "&:before": {
        opacity: 1,
      },
      "&:after": {
        opacity: 0,
      },
    },
  },
});

const OnOffSwitch = ({ onChange, show }) => {
  const classes = useStyles();
  return (
    <Switch
      data-qaid='onOffswitch_switch'
      color='default'
      classes={classes}
      checked={show}
      onChange={(e) => onChange(!show)}
    />
  );
};

export default OnOffSwitch;
