import React from "react";

const DownArrow = (props) => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.3999 16.9714V6'
      stroke='#004C60'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.3999 15.4287L12.3999 18.0001L16.3999 15.4287'
      stroke='#004C60'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default DownArrow;
