import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import theme from "../theme";
import { AvenirBlackH4, DotText } from "../utils/text";
import { ConvertPrice } from "../utils/util";
import { DownArrow } from "./SVG";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  head: {
    fontWeight: "bold",
    padding: theme.spacing(1),
    backgroundColor: "#f4f4f5",
  },
  cell: {
    padding: theme.spacing(1),
  },
});

const ReportsTable = (props) => {
  const classes = useStyles();
  const getCoulmnDisplayName = (isNumber, value) => {
    var value = isNumber ? `$${ConvertPrice(String(value))}` : String(value);
    return value;
  };
  const handleSort = (name, desc, index) => {
    props.handleSort(name, desc, index);
  };
  return (
    <Grid data-qaid='reportstable_main_grid'>
      <Grid
        data-qaid='reportstable_header_child_grid'
        style={{
          display: "flex",
          flexDirextion: "row",
          alignItems: "center",
          backgroundColor: "#F3FAFD",
          height: 56,
          borderRadius: 5,
          paddingLeft: 10,
          paddingRight: 10,
          width: "100%",
        }}>
        {props.columns?.map((column, index3) => (
          <Grid
            data-qaid='reportstable_header_grid'
            key={index3}
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0 10px",
              width: column.size,
              paddingRight: 10,
              alignItems: "center",
            }}>
            <AvenirBlackH4
              data-qaid='reportstable_header_label'
              label={column.display}
            />
            <Grid
              onClick={() => {
                handleSort(column.field, !column.desc, index3);
              }}>
              <DownArrow />
            </Grid>
          </Grid>
        ))}
      </Grid>
      {props.rows.map((row, index) => (
        <Grid
          key={index}
          data-qaid='reportstable_rowitem_grid'
          style={{
            flexDirextion: "row",
            display: "flex",
            backgroundColor: "#FAFAFA",
            alignItems: "center",
            marginTop: 10,
            borderRadius: 5,
            paddingLeft: 10,
            paddingRight: 10,
            width: "100%",
            height: 56,
          }}>
          {props.columns.map((colItem, index3) => (
            <Grid
              data-qaid='reportstable_rowlabel_grid'
              key={index3}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 10,
                width: colItem.size,
                paddingRight: 10,
                alignItems: "center",
              }}>
              <DotText
                data-qaid='reportstable_rowlabel'
                label={getCoulmnDisplayName(
                  colItem.isNumber || false,
                  row[colItem.field]
                )}
              />
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};

export default ReportsTable;
