import * as React from "react";

const HelpIcon = ({ color = "#004c60", ...props }) => (
  <svg
    width={20}
    height={20}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.12 5.807a1.728 1.728 0 00-1.848 1.724.74.74 0 11-1.482 0 3.21 3.21 0 114.28 3.027.494.494 0 00-.33.466v.828a.74.74 0 11-1.48 0v-.828a1.976 1.976 0 011.316-1.863 1.728 1.728 0 00-.457-3.354z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 1.481a8.519 8.519 0 100 17.038A8.519 8.519 0 0010 1.48zM0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z'
      fill={color}
    />
    <path
      d='M10.988 14.618a.988.988 0 11-1.976 0 .988.988 0 011.976 0z'
      fill={color}
    />
  </svg>
);

export default HelpIcon;
