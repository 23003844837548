import React from "react";

const SvgIcon = ({ icon, ...props }) => {
  const getIcon = () => {
    switch (icon) {
      case "rect":
        return <Rect {...props} />;
      case "circle":
        return <Circle {...props} />;
      case "barstool":
        return <BarStool {...props} />;
      case "door":
        return <Door {...props} />;
      case "sofa":
        return <Sofa {...props} />;
      case "kitchen":
        return <Kitchen {...props} />;
      case "flowervash":
        return <FlowerVase {...props} />;
      case "washroom":
        return <Washroom {...props} />;
      case "handwash":
        return <HandWash {...props} />;
      case "table":
        return <Table {...props} />;
      case "bar":
        return <Rect {...props} />;
      case "wall":
        return <Wall {...props} />;
      case "stair":
        return <Stair {...props} />;
      case "square":
        return <Square {...props} strokeWidth={6} stroke='#fff' />;
      default:
        return <Rect {...props} />;
    }
  };
  return getIcon(icon);
};
const Square = ({ width, height, ...props }) => (
  <Rect width={width} height={height} {...props} />
);
const Rect = ({
  name,
  showName = true,
  titleId,
  width = 100,
  color = "transparent",
  height = 100,
  strokeWidth = 4,
  stroke = "#fff",
  ...props
}) => {
  const viewBox = `0 0 ${width} ${height}`;
  const d = `M0 0h${width}v${height}H0z`;
  const r = props.isRotate ? -1 : 1;
  const deg = props.isRotate ? 270 : 0;
  return (
    <svg {...props} viewBox={viewBox} width={width} height={height}>
      <path fill={color} stroke={stroke} d={d} strokeWidth={strokeWidth} />
      {showName ? (
        <text
          x={width * 0.45 * r}
          y={height / 2}
          fill='black'
          dominantBaseline='middle'
          textAnchor='middle'
          style={{ transform: `rotateZ(${deg}deg)` }}>
          {name}
        </text>
      ) : null}
    </svg>
  );
};

const Circle = ({
  name,
  showName = true,
  titleId,
  width = 100,
  height = 100,
  color = "#EEEEEE",
  stroke = "#fff",
  strokeWidth = 4,
  ...props
}) => {
  const r = props.isRotate ? -1 : 1;
  const deg = props.isRotate ? 270 : 0;
  return (
    <svg {...props} height={height} width={width}>
      <ellipse
        cx={width / 2}
        cy={height / 2}
        rx={width / 2 - 5}
        ry={height / 2 - 5}
        strokeWidth={strokeWidth}
        stroke={stroke}
        fill={color}
      />
      {showName ? (
        <text
          x={width * 0.45 * r}
          y={height / 2}
          style={{ transform: `rotateZ(${deg}deg)` }}
          fill='black'
          dominantBaseline='middle'
          textAnchor='middle'>
          {name}
        </text>
      ) : null}
    </svg>
  );
};
const BarStool = ({
  width = 100,
  height = 100,
  color = "transparent",
  strokeWidth = 4,
  ...props
}) => (
  <Circle
    strokeWidth={6}
    width={width}
    height={height}
    color={color}
    {...props}
  />
);

const Sofa = ({ width = 100, height = 100, ...props }) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 512 52'
    fill='#f00'>
    <path d='M486.625 191.23h-10.172v-37.805c0-22.349-18.182-40.531-40.531-40.531H289.031c-13.617 0-25.678 6.757-33.031 17.086-7.353-10.33-19.414-17.086-33.031-17.086h-92.282a7.5 7.5 0 000 15h92.282c14.078 0 25.531 11.453 25.531 25.531v75.01c-.157-.151-.323-.292-.482-.441-8.89-8.289-20.939-13.067-33.101-13.067H89.989c-.661-10.07-7.526-18.927-16.999-22.285a24.925 24.925 0 00-8.322-1.412H50.547v-37.805c0-14.078 11.453-25.531 25.531-25.531h24.609a7.5 7.5 0 000-15H76.078c-22.349 0-40.531 18.182-40.531 40.531v37.805H25.375C11.383 191.23 0 202.615 0 216.605v78.311c0 29.138 23.706 52.844 52.844 52.844h3.769l-8.218 22.105c-5.181 13.934 5.483 29.241 20.325 29.241h12.066c6.754 0 13.219-3.216 17.294-8.603l32.331-42.743h251.18l32.331 42.744c4.075 5.386 10.54 8.602 17.293 8.602h12.065c14.86 0 25.505-15.31 20.325-29.241l-8.219-22.105h3.77c29.138 0 52.844-23.706 52.844-52.844v-78.311c0-13.99-11.383-25.375-25.375-25.375zM263.5 153.427c0-14.079 11.453-25.532 25.531-25.532h146.891c14.078 0 25.531 11.453 25.531 25.531v37.805h-14.122c-9.972 0-19.538 6.4-23.406 15.582a25.214 25.214 0 00-1.914 8.116H297.083c-12.35 0-24.659 4.969-33.583 13.508v-75.01zM90.044 229.928h124.873c18.518.001 33.583 15.066 33.583 33.584v10.665H90.044v-44.249zm-3.927 151.526a6.72 6.72 0 01-5.332 2.652H68.719c-4.592 0-7.865-4.711-6.265-9.013l10.162-27.333h38.986l-25.485 33.694zm357.163 2.651h-12.065a6.72 6.72 0 01-5.331-2.651l-25.486-33.694h38.986l10.162 27.332c1.603 4.312-1.693 9.013-6.266 9.013zm15.876-51.345H52.844C31.977 332.761 15 315.783 15 294.916v-78.311c0-5.721 4.654-10.375 10.375-10.375h39.293c5.721 0 10.375 4.654 10.375 10.375v65.071a7.5 7.5 0 007.5 7.5H358.02a7.5 7.5 0 000-15H263.5v-10.665c0-18.518 15.065-33.583 33.583-33.583h124.873v44.249H388.02a7.5 7.5 0 000 15h41.436a7.5 7.5 0 007.5-7.5v-65.071c0-5.721 4.654-10.375 10.375-10.375h39.293c5.721 0 10.375 4.654 10.375 10.375v78.31H497c0 20.867-16.977 37.844-37.844 37.844z' />
  </svg>
);

const Door = ({ width = 100, height = 100, stroke = "black", ...props }) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    stroke={stroke}
    viewBox='0 0 512 512'
    height={height}>
    <path
      fill={stroke}
      d='M486.4 494.933h-51.2V8.533c0-5.12-3.413-8.533-8.533-8.533H85.333C80.213 0 76.8 3.413 76.8 8.533v486.4H25.6c-5.12 0-8.533 3.413-8.533 8.533S20.48 512 25.6 512H486.4c5.12 0 8.533-3.413 8.533-8.533s-3.413-8.534-8.533-8.534zm-358.4 0V51.2h256v443.733H128zm273.067 0V42.667c0-5.12-3.413-8.533-8.533-8.533H119.467c-5.12 0-8.533 3.413-8.533 8.533v452.267H93.867V17.067h324.267v477.867h-17.067z'
    />
    <path
      fill={stroke}
      d='M358.4 247.467h-34.133c-5.12 0-8.533 3.413-8.533 8.533v8.533h-17.067c-5.12 0-8.533 3.413-8.533 8.533 0 5.12 3.413 8.533 8.533 8.533h17.067v8.533c0 5.12 3.413 8.533 8.533 8.533H358.4c5.12 0 8.533-3.413 8.533-8.533V256c0-5.12-3.413-8.533-8.533-8.533zm-8.533 34.133H332.8v-17.067h17.067V281.6z'
    />
  </svg>
);

const Kitchen = ({
  width = 100,
  height = 100,
  showName = false,
  name = "",
  stroke = "black",
  ...props
}) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 64 64'>
    <g xmlns='http://www.w3.org/2000/svg' data-name='Cooking-food-kitchen'>
      <path
        stroke={stroke}
        d='M16 55h1v-3a1 1 0 011-1h1.56a3.906 3.906 0 01-.56-2c0-1.94 2.71-5.06 3.26-5.67a1.027 1.027 0 011.48 0c.55.61 3.26 3.73 3.26 5.67a3.906 3.906 0 01-.56 2H28a1 1 0 011 1v3h4v-3a1 1 0 011-1h1.56a3.906 3.906 0 01-.56-2c0-1.94 2.71-5.06 3.26-5.67a1.027 1.027 0 011.48 0c.55.61 3.26 3.73 3.26 5.67a3.906 3.906 0 01-.56 2H44a1 1 0 011 1v3h4v-3a1 1 0 011-1h1.56a3.906 3.906 0 01-.56-2c0-1.94 2.71-5.06 3.26-5.67a1.027 1.027 0 011.48 0c.55.61 3.26 3.73 3.26 5.67a3.906 3.906 0 01-.56 2H60a1 1 0 011 1v3a1 1 0 011 1v4a1 1 0 01-1 1H16a1 1 0 01-1-1v-4a1 1 0 011-1zm7-9.45c-1.06 1.33-2 2.83-2 3.45a2 2 0 004 0c0-.64-.94-2.13-2-3.45zM27 53h-8v2h8zm12-7.45c-1.06 1.33-2 2.83-2 3.45a2 2 0 004 0c0-.64-.94-2.13-2-3.45zM43 53h-8v2h8zm12-7.45c-1.06 1.33-2 2.83-2 3.45a2 2 0 004 0c0-.64-.94-2.13-2-3.45zM59 53h-8v2h8zm-42 6h43v-2H17zM3.13 26.17a2.923 2.923 0 012.52-.54L19.12 29H61a1 1 0 011 1v3a7.008 7.008 0 01-7 7H23a7.007 7.007 0 01-6.87-5.68L4.22 31.34a2.942 2.942 0 01-1.09-5.17zM18 33a5 5 0 005 5h32a5 5 0 005-5v-2H18zM4.71 29.4L16 32.22v-1.94L5.17 27.57a.951.951 0 00-1.17.92.94.94 0 00.71.91z'
        data-original={stroke}
      />
    </g>
    {showName ? (
      <text
        x={width * 0.5}
        y={height}
        fill='black'
        dominantBaseline='middle'
        textAnchor='middle'>
        {name}
      </text>
    ) : null}
  </svg>
);

const FlowerVase = ({
  width = 100,
  height = 100,
  stroke = "transparent",
  ...props
}) => {
  console.log("color of plant", stroke);
  return (
    <svg
      {...props}
      height={width}
      viewBox='0 0 74 74'
      width={width}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        stroke={stroke}
        d='M47.984 72H21.831a1 1 0 01-.984-.823l-4.708-26.154a1 1 0 01.984-1.177h35.569a1 1 0 01.984 1.177l-4.707 26.154a1 1 0 01-.985.823zm-25.317-2h24.481L51.5 45.846H18.319z'
      />
      <path
        stroke={stroke}
        d='M55.787 45.846H14.028a1.569 1.569 0 01-1.567-1.567v-5.557a1.569 1.569 0 011.567-1.566h41.759a1.569 1.569 0 011.567 1.566v5.558a1.569 1.569 0 01-1.567 1.566zm-41.326-2h40.893v-4.691H14.461z'
      />
      <path
        stroke={stroke}
        d='M35.411 39.155h-.076a1 1 0 01-.922-1.072 33.813 33.813 0 011.066-6.258 34.7 34.7 0 0112.472-18.684 1 1 0 011.216 1.588 32.7 32.7 0 00-11.755 17.608 31.865 31.865 0 00-1 5.893 1 1 0 01-1.001.925z'
      />
      <path
        stroke={stroke}
        d='M47.743 27.387a18.118 18.118 0 01-8.456-2.473 1 1 0 01-.44-.474c-.132-.3-3.2-7.362.688-13.62C42.9 5.413 50.3 2.445 61.531 2a.981.981 0 01.744.29 1 1 0 01.295.742c-.023.727-.676 17.869-9.5 22.956a10.488 10.488 0 01-5.327 1.399zm-7.189-4.065c4.578 2.382 8.45 2.7 11.512.935C58.754 20.4 60.214 7.834 60.5 4.052c-9.9.554-16.374 3.179-19.262 7.809-2.913 4.675-1.183 10.124-.685 11.46zM36.445 33.084a1 1 0 01-.924-.617 22.739 22.739 0 00-12.577-12.416 1 1 0 11.744-1.856A24.74 24.74 0 0137.368 31.7a1 1 0 01-.923 1.384z'
      />
      <path
        stroke={stroke}
        d='M24.359 28.665a7.6 7.6 0 01-1.887-.24c-7.1-1.814-10.841-13.456-11-13.951A1 1 0 0112.2 13.2c7.8-1.851 13.464-1.219 16.843 1.878 3.936 3.608 3.173 9.126 3.139 9.36a1 1 0 01-.3.575c-2.524 2.429-5.047 3.652-7.523 3.652zM13.748 14.9c1.045 2.783 4.3 10.33 9.222 11.585 2.277.58 4.72-.315 7.263-2.665.079-1.117.116-4.844-2.555-7.281-2.715-2.474-7.399-3.025-13.93-1.639z'
      />
    </svg>
  );
};

const Washroom = ({ width = 100, height = 100, ...props }) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    viewBox='0 0 480 480'
    height={height}>
    <path d='M344 224h-8v-16h8c13.255 0 24-10.745 24-24V46.528c12.473-4.393 19.023-18.066 14.629-30.539A23.944 23.944 0 00360 0H120c-13.224-.025-23.964 10.675-23.989 23.899A23.943 23.943 0 00112 46.528V184c0 13.255 10.745 24 24 24h8v16h-8c-22.08.026-39.974 17.92-40 40a8 8 0 008 8c-.157 53.459 31.2 101.99 80 123.816V472a8 8 0 008 8h96a8 8 0 008-8v-76.184c48.793-21.834 80.147-70.36 80-123.816a8 8 0 008-8c-.026-22.08-17.92-39.974-40-40zM112 24a8 8 0 018-8h240a8 8 0 010 16H120a8 8 0 01-8-8zm24 168a8 8 0 01-8-8V48h224v136a8 8 0 01-8 8H136zm184 16v16H160v-16h160zM200 464v-61.984l.744.2c.8.24 1.6.424 2.4.648 2.928.832 5.885 1.563 8.872 2.192 1.432.304 2.872.552 4.312.8 2.472.432 4.96.8 7.464 1.08 1.52.184 3.024.376 4.56.504 3.259.272 6.525.427 9.8.464.608 0 1.2.088 1.808.088.608 0 1.208-.08 1.816-.088 3.275-.037 6.539-.192 9.792-.464 1.536-.128 3.056-.328 4.584-.504a143.25 143.25 0 007.4-1.072c1.464-.256 2.928-.52 4.384-.8 2.912-.6 5.784-1.328 8.632-2.128.888-.248 1.792-.448 2.68-.712.232-.072.48-.12.712-.192L280 464h-80zm84.96-80.76a120.41 120.41 0 01-67.912 6.544 116 116 0 01-22.056-6.568C149.565 365.015 119.844 320.937 120 272h240c.152 48.956-29.59 93.046-75.04 111.24zM113.368 256A24.047 24.047 0 01136 240h208a24.047 24.047 0 0122.632 16H113.368z' />
    <path d='M208 72h-25.472c-4.418-12.497-18.131-19.046-30.627-14.627S132.855 75.503 137.273 88s18.131 19.046 30.627 14.627A23.998 23.998 0 00182.527 88H208a8 8 0 000-16zm-48 16a8 8 0 110-16 8 8 0 010 16z' />
  </svg>
);
const HandWash = ({ width = 100, height = 100, ...props }) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 64 64'
    width={width}
    height={height}>
    <path d='M61 48h-1v-5a1 1 0 00-1-1h-5V8a6 6 0 00-6-6H36a6 6 0 00-6 6v6h-1a1 1 0 00-1 1v4a1 1 0 001 1h2.56l-1.48 3.95A2.994 2.994 0 0032.89 28h.22a2.994 2.994 0 002.81-4.05L34.44 20H37a1 1 0 001-1v-4a1 1 0 00-1-1h-1V9a1 1 0 011-1h10a1 1 0 011 1v16h-4v-3h-2v8h2v-3h4v15h-5a1 1 0 00-1 1v5h-4v-8h4a4 4 0 100-8h-5.01A4.978 4.978 0 0033 30h-3.4a3.986 3.986 0 00-1.84-2.37l-10.32-5.95a4.875 4.875 0 00-2.51-.68H3a1 1 0 00-1 1v12a1 1 0 001 1h2.69a2.956 2.956 0 011.95.72l3.59 3.08a5 5 0 003.25 1.2H20v8H3a1 1 0 00-1 1v4a9.014 9.014 0 009 9h42a9.014 9.014 0 009-9v-4a1 1 0 00-1-1zM33.94 25.57a1 1 0 01-.83.43h-.22a.995.995 0 01-.94-1.35l1.05-2.8 1.05 2.8a1 1 0 01-.11.92zM36 18h-6v-2h6zM47 6H37a3.009 3.009 0 00-3 3v5h-2V8a4 4 0 014-4h12a4 4 0 014 4v34h-2V9a3.009 3.009 0 00-3-3zm-3 38h14v4H44zm-2-10a2.015 2.015 0 012 2 2.006 2.006 0 01-2 2h-4v-3a5.566 5.566 0 00-.11-1zm-9-2a3.019 3.019 0 013 3v13h-4V35a5.037 5.037 0 00-.67-2.5c-.11-.18-.23-.34-.35-.5zm-18.52 6a2.956 2.956 0 01-1.95-.72L8.94 34.2A5 5 0 005.69 33H4V23h10.93a2.916 2.916 0 011.5.41l10.33 5.95a2 2 0 01.99 1.67.41.41 0 00.01.11c0 .06-.01.11-.01.17a1.942 1.942 0 01-.26.79 2.111 2.111 0 01-.18.27 2.013 2.013 0 01-2.55.46l-11.26-6.5-1 1.74 11.26 6.5a4.011 4.011 0 005.09-.93 4.328 4.328 0 00.38-.54c.01-.02.01-.04.02-.06a2.191 2.191 0 01.34.46A2.969 2.969 0 0130 35v3zM30 40v8h-2v-8zm-4 0v8h-4v-8zm34 13a7.008 7.008 0 01-7 7H11a7.008 7.008 0 01-7-7v-3h56z' />
  </svg>
);
const Table = ({ width = 50, ...props }) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 74 74'
    width={width}
    height={width}>
    <path d='M71 60h-5.889a1 1 0 01-1-1V45.333H51.629V59a1 1 0 01-1 1h-5.888a1 1 0 01-1-1V40.973a3.347 3.347 0 013.343-3.343h17.027V15.373A1.375 1.375 0 0165.484 14h5.142A1.375 1.375 0 0172 15.373V59a1 1 0 01-1 1zm-4.889-2H70V16h-3.889v22.63a1 1 0 01-1 1H47.083a1.345 1.345 0 00-1.343 1.343V58h3.889V44.333a1 1 0 011-1h14.482a1 1 0 011 1zm4.515-42zM29.259 60h-5.888a1 1 0 01-1-1V45.333H9.889V59a1 1 0 01-1 1H3a1 1 0 01-1-1V15.373A1.375 1.375 0 013.374 14h5.142a1.375 1.375 0 011.373 1.373V37.63h17.028a3.347 3.347 0 013.343 3.343V59a1 1 0 01-1.001 1zm-4.889-2h3.889V40.973a1.345 1.345 0 00-1.343-1.343H8.889a1 1 0 01-1-1V16H4v42h3.889V44.333a1 1 0 011-1h14.482a1 1 0 011 1zM37 60a1 1 0 01-1-1V32.5a1 1 0 012 0V59a1 1 0 01-1 1z' />
    <path d='M40 60h-6a1 1 0 010-2h6a1 1 0 010 2zM55 33.5H19a1 1 0 01-1-1v-1.812A5.694 5.694 0 0123.688 25h26.625A5.694 5.694 0 0156 30.688V32.5a1 1 0 01-1 1zm-35-2h34v-.812A3.692 3.692 0 0050.313 27H23.688A3.692 3.692 0 0020 30.688z' />
  </svg>
);
const Wall = ({ width = 20, height = 20, stroke = "#000", ...props }) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 512 512'
    stroke={stroke}>
    <path
      fill={stroke}
      d='M486.4 51.2H25.6C11.46 51.2 0 62.66 0 76.8v358.4c0 14.14 11.46 25.6 25.6 25.6h460.8c14.14 0 25.6-11.46 25.6-25.6V76.8c0-14.14-11.46-25.6-25.6-25.6zM25.6 76.8h153.6v102.4H25.6V76.8zm0 128h307.2v102.4H25.6V204.8zm153.6 230.4H25.6V332.8h153.6v102.4zm307.2 0H204.8V332.8h281.6v102.4zm0-128h-128V204.8h128v102.4zm0-128H204.8V76.8h281.6v102.4z'
    />
  </svg>
);
const Floor = ({ size = 20, ...props }) => {
  const r = size * 0.15;
  return (
    <svg {...props} width={size} height={size}>
      <circle
        cx={size * 0.25}
        cy={size * 0.25}
        r={r}
        stroke='#808285'
        strokeWidth={2}
        fill='#fff'
      />
      <circle
        cx={size * 0.25}
        cy={size * 0.75}
        r={r}
        stroke='#808285'
        strokeWidth={2}
        fill='#fff'
      />
      <circle
        cx={size * 0.75}
        cy={size * 0.75}
        r={r}
        stroke='#808285'
        strokeWidth={2}
        fill='#fff'
      />
      <circle
        cx={size * 0.75}
        cy={size * 0.25}
        r={r}
        stroke='#808285'
        strokeWidth={2}
        fill='#fff'
      />
    </svg>
  );
};
const Bar = ({ width = 15, height = 45, ...props }) => (
  <svg {...props} width={width} height={height}>
    <path fill='#fff' stroke='#000' strokeWidth={4} d='M0 0h10v30H0z' />
  </svg>
);

const Stair = ({ width, height, stroke, ...props }) => {
  stroke = stroke == "transparent" ? "#fff" : stroke;
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' {...props}>
      <path
        fill={stroke}
        stroke='#000'
        strokeWidth={3}
        d='M497 0H376.5c-8.284 0-15 6.716-15 15v105.5H256c-8.284 0-15 6.716-15 15V241H135.5c-8.284 0-15 6.716-15 15v105.5H15c-8.284 0-15 6.716-15 15V497c0 8.284 6.716 15 15 15h482c8.284 0 15-6.716 15-15V15c0-8.284-6.716-15-15-15z'
      />
    </svg>
  );
};

export {
  Rect,
  Circle,
  BarStool,
  Sofa,
  Door,
  Kitchen,
  FlowerVase,
  Washroom,
  HandWash,
  SvgIcon,
  Floor,
  Bar,
  Wall,
};
