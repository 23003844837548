import React from "react";
import { Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import colors from "../styles/colors";

const H1 = ({ style, label, ...props }) => (
  <Typography
    {...props}
    style={{
      color: colors.navyText,
      ...style,
      fontSize: 20,
      fontFamily: "AvenirLT-Black",
    }}>
    {label}
  </Typography>
);

const H2 = ({ style, label, ...props }) => (
  <Typography
    {...props}
    style={{
      color: colors.navyText,
      ...style,
      fontSize: 18,
      fontFamily: "AvenirLT-Black",
    }}>
    {label}
  </Typography>
);

const H3 = ({ style, label }) => (
  <Typography
    style={{
      color: colors.navyText,
      ...style,
      fontSize: 16,
      fontFamily: "AvenirLT-Medium",
    }}>
    {label}
  </Typography>
);

const H4 = ({ style, label, ...props }) => (
  <Typography
    {...props}
    style={{
      color: colors.navyText,
      ...style,
      fontSize: 14,
      fontFamily: "AvenirLT",
    }}>
    {label}
  </Typography>
);

const H5 = ({ style, label }) => (
  <Typography
    style={{
      color: colors.navyText,
      ...style,
      fontSize: 12,
      fontFamily: "AvenirLT",
    }}>
    {label}
  </Typography>
);

const H6 = ({ style, label }) => (
  <Typography
    style={{
      color: colors.navyText,
      ...style,
      fontSize: 24,
      fontFamily: "AvenirLT-Black",
    }}>
    {label}
  </Typography>
);

const T = ({ style, label, ...props }) => (
  <Typography
    style={{
      fontFamily: "AvenirLT",
      color: "#051D33",
      fontSize: 14,
      ...style,
    }}
    {...props}>
    {label}
  </Typography>
);

const TBold = ({ style, label }) => (
  <Typography
    style={{
      fontFamily: "AvenirLT-Black",
      color: "#051D33",
      fontWeight: "bold",
      ...style,
    }}>
    {label}
  </Typography>
);

const DotText = ({ style, label }) => (
  <Typography
    style={{
      color: colors.navyText,
      fontSize: 14,
      fontFamily: "AvenirLT",
      ...style,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    }}>
    {label}
  </Typography>
);

const DotTextBold = ({ style, label }) => (
  // return (<Typography style={{ color: colors.navyText, fontSize: 14, fontFamily: 'AvenirLT', ...style, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{label}</Typography>)
  <Typography
    style={{
      color: colors.navyText,
      ...style,
      fontFamily: "AvenirLT-Black",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    }}>
    {label}
  </Typography>
);
const DotTextMedium = ({ style, label }) => (
  <Typography
    style={{
      color: colors.navyText,
      ...style,
      fontFamily: "AvenirLT-Medium",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    }}>
    {label}
  </Typography>
);
const DotTextBoldNormal = ({ style, label, lines = 1 }) => (
  <Tooltip title={label}>
    <Typography
      numberOfLines={lines}
      ellipsizeMode='tail'
      style={{
        color: colors.navyText,
        fontFamily: "AvenirLT",
        ...style,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      }}>
      {label}
    </Typography>
  </Tooltip>
);
const AvenirBlackH3 = ({ style, label }) => (
  <Typography
    style={{
      color: colors.navyText,
      fontSize: 16,
      ...style,
      fontFamily: "AvenirLT-Medium",
    }}>
    {label}
  </Typography>
);
const AvenirRedH4 = ({ style, label, ...props }) => (
  <Typography
    {...props}
    style={{
      color: colors.redText,
      ...style,
      fontSize: 14,
      fontFamily: "AvenirLT-Medium",
    }}>
    {label}
  </Typography>
);
const AvenirWhiteH4 = ({ style, label, ...props }) => (
  <Typography
    {...props}
    style={{
      color: colors.whiteText,
      ...style,
      fontSize: 14,
      fontFamily: "AvenirLT-Medium",
    }}>
    {label}
  </Typography>
);

const AvenirGreyH4 = ({ style, label, ...props }) => (
  <Typography
    {...props}
    style={{
      color: colors.greyText,
      ...style,
      fontSize: 14,
      fontFamily: "AvenirLT-Medium",
    }}>
    {label}
  </Typography>
);

const AvenirBlackH4 = ({ style, label, ...props }) => (
  <Typography
    {...props}
    style={{
      color: colors.navyText,
      ...style,
      fontSize: 14,
      fontFamily: "AvenirLT-Medium",
    }}>
    {label}
  </Typography>
);
const AvenirBlackH5 = ({ style, label, ...props }) => (
  <Typography
    {...props}
    style={{
      color: colors.navyText,
      ...style,
      fontSize: 12,
      fontFamily: "AvenirLT-Medium",
    }}>
    {label}
  </Typography>
);
const AvenirBlackH2 = ({ style, label }) => (
  <Typography
    style={{
      color: colors.navyText,
      ...style,
      fontSize: 18,
      fontFamily: "AvenirLT-Medium",
    }}>
    {label}
  </Typography>
);

const AvenirBlackH7 = ({ style, label }) => (
  <Typography
    style={{
      color: colors.navyText,
      ...style,
      fontSize: 24,
      fontFamily: "AvenirLT-Medium",
    }}>
    {label}
  </Typography>
);
const H8 = ({ style, label }) => (
  <Typography
    style={{
      color: colors.navyText,
      ...style,
      fontSize: 28,
      fontFamily: "AvenirLT-Black",
    }}>
    {label}
  </Typography>
);
const AvenirBlackLightH3 = ({ style, label }) => (
  <Typography
    style={{
      color: colors.navyText,
      ...style,
      fontSize: 16,
      fontFamily: "AvenirLT",
    }}>
    {label}
  </Typography>
);
const AvenirBlackLightH2 = ({ style, label }) => (
  <Typography
    style={{
      color: colors.navyText,
      ...style,
      fontSize: 18,
      fontFamily: "AvenirLT",
    }}>
    {label}
  </Typography>
);
export {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  T,
  TBold,
  DotText,
  DotTextBold,
  DotTextMedium,
  AvenirBlackH3,
  AvenirRedH4,
  AvenirWhiteH4,
  AvenirBlackH4,
  AvenirGreyH4,
  AvenirBlackH7,
  AvenirBlackH5,
  AvenirBlackH2,
  H8,
  AvenirBlackLightH3,
  AvenirBlackLightH2,
  DotTextBoldNormal,
};
