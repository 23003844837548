import React, { useEffect, useState, useContext } from "react";
import { Grid, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllCategories,
  AssignCategoryToItems,
} from "../../../redux/actions";
import MyContext from "../../../Provider/MyContext";
import RightPointingArrow from "../../../components/SVG/RightPointingArrow";
import CircleClose from "../../../components/SVG/CircleClose";
import { AvenirRedH4, AvenirBlackH4, H4, H6 } from "../../../utils/text";
import styles from "../../../styles/index";
import SingleSelect from "../../../components/SingleSelect";
import CenterModal from "../../../components/CenterModal";

const ItemAssignCategories = ({
  open,
  onClose,
  items,
  currentCategory,
  callback,
}) => {
  const style = styles();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.menu.categories);
  const [table, setTable] = useState([]);
  const [showRepeatIssue, setShowRepeatIssue] = useState(false);
  const [showSendIssue, setShowSendIssue] = useState(false);
  const Context = useContext(MyContext);
  const [newCategoryName, setNewCategoryName] = useState([]);
  const [newCategory, setNewCategory] = useState(1);
  const [errorFlag, setError] = useState(null);

  useEffect(() => {
    dispatch(fetchAllCategories());
    setNewCategory(0);
  }, []);

  const handleCategoryChange = (value) => {
    setError("");
    setNewCategory(value);
    const cat = categories.filter((e) => e.cat_id == value);
    if (cat.length > 0) setNewCategoryName(cat[0].name);
    else setNewCategoryName("");
  };

  const handleReassign = () => {
    if (newCategory) {
    } else {
      setError(`${Context.langData.no_data}   `);
      return;
    }

    const reqItems = [];

    items.map((p) => {
      const obj = {};
      obj.id = p.item_id;
      obj.name = p.name;
      reqItems.push(obj);
    });

    const request = {
      category_id: newCategory,
      items: reqItems,
    };

    dispatch(AssignCategoryToItems(request, callback));
  };

  return (
    <CenterModal open={open} padding={1}>
      <>
        <Grid
          data-qaid='assignCategories__body_grid'
          style={{
            display: "flex",
            height: 600,
            flexDirection: "column",
            width: 800,
          }}>
          <Grid
            data-qaid='assignCategories__child1_grid'
            style={{
              display: "flex",
              height: "85%",
              flexDirection: "column",
              overflowX: "auto",
            }}>
            <Grid
              data-qaid='assignCategories__close_grid'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}>
              <CircleClose
                data-qaid='assignCategories__closeicon'
                size={20}
                onClick={onClose}
                style={{ marginRight: 36, marginTop: 20 }}
              />
            </Grid>
            <H6
              data-qaid='assignCategories__title'
              style={{ marginLeft: 36, marginBottom: 20, marginRight: 36 }}
              label={Context.langData.reassign_category}
            />
            <H4
              data-qaid='assignCategories__desc'
              style={{ marginLeft: 36, marginBottom: 20, marginRight: 36 }}
              label={Context.langData.reassign_category_desc}
            />
            <Grid
              data-qaid='assignCategory_kitchenContainer'
              item
              style={{
                marginTop: "20px",
                marginLeft: "20px",
                marginRight: "20px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}>
              <Grid
                data-qaid='assignCategory_subContainer1'
                item
                style={{
                  height: "40px",
                  width: "95%",
                  backgroundColor: "#FFFFFF",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}>
                <Grid
                  data-qaid='assignCategory_dateGrid'
                  style={{ width: "40%" }}>
                  <AvenirBlackH4
                    data-qaid='viewIndentId_itemHeading'
                    label={Context.langData.items}
                  />
                </Grid>
                <Grid
                  data-qaid='assignCategory_dateGrid'
                  style={{ width: "20%" }}>
                  <AvenirBlackH4
                    data-qaid='assignCategory_request_current'
                    label={Context.langData.current_category}
                  />
                </Grid>
                <Grid
                  data-qaid='assignCategory_dateGrid'
                  style={{ width: "10%" }}
                />
                <Grid
                  data-qaid='assignCategory_dateGrid'
                  style={{ width: "30%" }}>
                  <AvenirBlackH4
                    data-qaid='assignCategory_request_replacement'
                    label={Context.langData.replacement_category}
                  />
                </Grid>
              </Grid>
              <Grid
                data-qaid='assignCategory_subContainer1'
                item
                style={{
                  height: "60px",
                  width: "95%",
                  backgroundColor: "#FAFAFA",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}>
                <Grid
                  data-qaid='assignCategory_dateGrid1'
                  style={{ width: "40%" }}>
                  <AvenirBlackH4
                    data-qaid='viewIndentId_itemHeading'
                    label={`${items?.length} items`}
                  />
                </Grid>
                <Grid
                  data-qaid='assignCategory_dateGrid2'
                  style={{ width: "20%" }}>
                  <H4
                    data-qaid='assignCategory_request_current'
                    label={currentCategory}
                  />
                </Grid>
                <Grid
                  data-qaid='assignCategory_dateGrid3'
                  style={{ width: "10%" }}>
                  <RightPointingArrow
                    data-qaid='assignCategories_rightarrow_icon'
                    style={{ marginRight: 30 }}
                  />
                </Grid>
                <Grid
                  data-qaid='assignCategory_dateGrid4'
                  style={{ width: "30%" }}>
                  <SingleSelect
                    list={[{ name: "Select one", cat_id: 0 }].concat(
                      categories
                    )}
                    valueKey='cat_id'
                    displayKey='name'
                    value={newCategory}
                    handleChange={(e) => handleCategoryChange(e.target.value)}
                    border={false}
                    height={56}
                    FormWidth='95%'
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{
                  flexDirection: "row",
                  width: "100%",
                  marginTop: 24,
                  minHeight: 100,
                  maxHeight: 200,
                  overflowY: "auto",
                }}>
                {items?.length > 0 ? (
                  items.map((itemObj, index) => (
                    <Grid
                      data-qaid='assignCategory_subContainer3'
                      item
                      style={{
                        width: "95%",
                        backgroundColor: "#FFFFFF",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 8,
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}>
                      <Grid
                        data-qaid='assignCategory_dateGrid'
                        style={{ width: "40%" }}>
                        <H4
                          data-qaid='viewIndentId_itemHeading'
                          label={itemObj.name}
                          style={{ marginLeft: 20 }}
                        />
                      </Grid>
                      <Grid
                        data-qaid='assignCategory_dateGrid'
                        style={{ width: "20%" }}>
                        <H4
                          data-qaid='assignCategory_request_qtyHeading'
                          label={itemObj.category}
                        />
                      </Grid>
                      <Grid
                        data-qaid='assignCategory_dateGrid3'
                        style={{ width: "10%" }}>
                        <RightPointingArrow
                          data-qaid='assignCategories_rightarrow_icon'
                          style={{ marginRight: 30 }}
                        />
                      </Grid>
                      <Grid
                        data-qaid='assignCategory_dateGrid3'
                        style={{ width: "30%" }}>
                        <H4
                          data-qaid='viewIndentId_itemHeading'
                          label={newCategoryName}
                        />
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            data-qaid='assignCategories_cancel_main_Grid'
            style={{
              display: "flex",
              height: "15%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid
              data-qaid='assignCategories_cancelGrid'
              item
              style={{
                display: "flex",
                justifyContent: "flex-end",
                backgroundColor: "#FAFAFA",
                width: "100%",
                paddingTop: "25px",
                alignItems: "center",
                paddingBottom: "25px",
                border: "1px solid #C3C3C3",
              }}>
              <AvenirRedH4
                data-qaid='assignCategory_request_errorHeading'
                label={errorFlag}
              />
              <Button
                className={style.buttonClass}
                data-qaid='assignCategories_cancelButton'
                style={{
                  backgroundColor: "#00B6C9",
                  color: "white",
                  marginLeft: 10,
                  marginRight: 10,
                  borderColor: "#707272",
                }}
                variant='contained'
                id='cancel'
                onClick={onClose}>
                <AvenirBlackH4
                  data-qaid='assignCategories_cancelLabel'
                  label={Context.langData.cancel}
                />
              </Button>
              <Button
                className={style.buttonClass}
                data-qaid='assignCategories_saveButton'
                style={{
                  backgroundColor: "#FFB600",
                  color: "white",
                  marginRight: 10,
                }}
                variant='contained'
                id='cancel'
                type='submit'
                onClick={handleReassign}>
                <AvenirBlackH4
                  data-qaid='assignCategories_saveLabel'
                  label={Context.langData.reassign}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    </CenterModal>
  );
};

export default ItemAssignCategories;
