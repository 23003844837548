import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Grid } from "@material-ui/core";
import VerticalTabs from "../../../components/verticalTabs";
import MyContext from "../../../Provider/MyContext";
import NoPermissionPage from "../../../components/NoPermission";
import TaxProfiles from "./TaxProfiles";
import Taxes from "./Taxes";
import Terminals from "./Terminals";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Payments = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const userPermissions = useSelector(
    (state) => state.loginReducer.userPermissions
  );
  const Tabs = [
    {
      name: Context.langData.taxes,
      id: 0,
    },
    {
      name: Context.langData.taxProfiles,
      id: 1,
    },
    {
      name: Context.langData.terminals,
      id: 2,
    },
  ];
  function indexCallback(value) {
    const Index = Tabs.findIndex((element) => element.id == value);
    setSelectedTabIndex(value);
  }
  const renderView = () => {
    switch (selectedTabIndex) {
      case 0:
        return (
          <>
            {isPermissionCheck("taxes") ? (
              <Taxes data-qaid='ordertypeList_index' />
            ) : (
              <NoPermissionPage data-qaid='ordertypeList_nopermission' />
            )}
          </>
        );
      case 1:
        return (
          <>
            {isPermissionCheck("taxes") ? (
              <TaxProfiles data-qaid='ordertypeList_index' />
            ) : (
              <NoPermissionPage data-qaid='ordertypeList_nopermission' />
            )}
          </>
        );
      case 2:
        return <Terminals />;
      // case 2: return(
      //     <Terminals />
      // )
      // case 3: return(
      //     <NoPermissionPage data-qaid="ordertypeList_nopermission"/>
      // )

      default:
        return null;
    }
  };
  const isPermissionCheck = (val) => {
    let isPermission = false;
    const obj = userPermissions.filter((e) => e.key == val);
    if (obj.length > 0) {
      if (obj[0].status) {
        isPermission = true;
      } else {
      }
    } else {
    }

    return isPermission;
  };
  return (
    <div
      data-qaid='ordertypes_index_div'
      style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20 }}>
      <Grid
        data-qaid='menu_grid'
        container
        direction='row'
        style={{ borderTop: "1px solid #EEEEEE" }}>
        <Grid
          data-qaid='menu_tabs'
          item
          xs={12}
          lg={2}
          style={{ backgroundColor: "#FAFAFA" }}>
          <VerticalTabs
            data-qaid='menu_options_vtabs'
            tabData={Tabs}
            indexCallback={indexCallback}
            style={{ marginTop: 24 }}
          />
        </Grid>

        <Grid
          data-qaid='menu_options_addbutton'
          item
          xs={12}
          lg={10}
          style={{ marginTop: 24 }}>
          {renderView()}
        </Grid>
      </Grid>
    </div>
  );
};

export default Payments;
