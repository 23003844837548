import instance from "./axiosInstance";
import urls, { insertId } from "./urls";

const fetchListOfEodReports = (params) => (dispatch) => {
  instance
    .get(insertId(urls.eodReports.listEodReports, params.id, params.id2))
    .then((response) => {
      console.log("response-pv", response);
      if (response.status === 200) {
        dispatch({
          type: "GET_LIST_EOD_REPORTS",
          payload: response.data.data,
        });
      }
    });
};

const fetchSpecificEodReport = (params) => (dispatch) => {
  instance
    .get(insertId(urls.eodReports.specificEodReport, params))
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({ type: "GET_SPECIFIC_EOD_REPORT", payload: response.data });
      }
    });
};

const fetchListOfUserReports = (params) => (dispatch) => {
  const finalURL = params.id3
    ? insertId(
        urls.eodReports.listEodShiftReports_employeeID,
        params.id,
        params.id2,
        params.id3
      )
    : insertId(urls.eodReports.listEodShiftReports, params.id, params.id2);
  instance.get(finalURL).then((response) => {
    if (response.status === 200) {
      dispatch({
        type: "GET_LIST_EOD_SHIFT_REPORTS",
        payload: response.data.data,
      });
    }
  });
};

const fetchSpecificShiftReport = (params) => (dispatch) => {
  instance
    .get(insertId(urls.eodReports.specificEodShiftReport, params))
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({
          type: "GET_SPECIFIC_EOD_SHIFT_REPORTS",
          payload: response.data,
        });
      }
    });
};

const postEmailReport = (params, callback) => (dispatch) => {
  instance
    .post(insertId(urls.eodReports.emailReports, params))
    .then((response) => {
      if (response.status === 200) {
        callback(true);
        dispatch({ type: "" });
      }
    })
    .catch((err) => {
      callback(false);
      console.log(err);
    });
};

export {
  fetchListOfEodReports,
  fetchSpecificEodReport,
  fetchListOfUserReports,
  fetchSpecificShiftReport,
  postEmailReport,
};
