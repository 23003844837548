import React from "react";
import { Grid, FormHelperText } from "@material-ui/core";
import { useDispatch } from "react-redux";
import InputText from "../../../../components/InputText";
import CustomCheckbox from "../../../../components/CustomCheckbox";
import {
  H4,
  AvenirBlackH4,
  AvenirBlackH3,
  DotText,
  H5,
} from "../../../../utils/text";
import CirclePlus from "../../../../components/SVG/CirclePluse";
import ChangeImage from "../create/ChangeImage";
import { ConvertPrice, validateAlphaNumeric } from "../../../../utils/util";
import { fetchImageUrl, putImageUrl } from "../../../../redux/actions";
import InfoOutlined from "../../../../components/SVG/InfoOutlined";

const Basic = ({
  basicInfo = {},
  setBasicInfo,
  ordertypes,
  addComboItems,
  comboNameValidate,
  comboPriceValidate,
  comboselectionValidate,
  onBlurPriceCallback,
  onBlurNameCallback,
}) => {
  const dispatch = useDispatch();
  const onChangeFields = (field, value) => {
    let _data = value;
    if (field == "name") {
      _data = validateAlphaNumeric(value);
    }
    setBasicInfo({ ...basicInfo, [field]: _data });
  };
  const onOrderTypeSelect = (val, id) => {
    console.log(val, id);
    let selected = [...basicInfo.ordertypes];
    const index = selected.findIndex((e) => e == id);
    if (index == -1) {
      selected.push(id);
    } else {
      selected = [...selected.slice(0, index), ...selected.slice(index + 1)];
    }
    setBasicInfo({ ...basicInfo, ordertypes: selected });
  };
  const onImageChange = (image, file) => {
    if (file) {
      dispatch(
        fetchImageUrl(
          {
            file_name: image,
            content_type: file.type,
            image_loc: "menu",
          },
          (response) => {
            completeLogo(response, file, 1);
          }
        )
      );
    } else {
      onChangeFields("img_url", null);
    }
  };
  const completeLogo = (response, file, itemId) => {
    const obj = {};
    const url = response.upload_url.split("?")[0];
    obj.ContentType = file.type;
    obj.Body = file;
    dispatch(
      putImageUrl(
        response.upload_url,
        obj,
        (res) => {
          onChangeFields("img_url", url);
        },
        response.storage == "azure"
      )
    );
  };
  const handleOnBlurChange = (data) => {
    onBlurPriceCallback();
    if (basicInfo.price !== "") {
      setBasicInfo({ ...basicInfo, price: ConvertPrice(data) });
    }
  };
  console.log("basicInfobasicInfo", basicInfo);
  return (
    <Grid container>
      <Grid
        container
        direction='row'
        spacing={0.5}
        style={{ marginTop: 30, padding: "0px 24px" }}>
        <Grid item xs={8} style={{ marginRight: 10 }}>
          <InputText
            onChange={(e) => onChangeFields("name", e.target.value)}
            value={basicInfo.name}
            maxLength={64}
            errorIcon
            error={comboNameValidate == "" ? "Combo name is required" : null}
            onBlur={() => onBlurNameCallback()}
            placeholder='Item name'
          />
        </Grid>
        <Grid style={{ width: 137 }}>
          <InputText
            onChange={(e) =>
              onChangeFields("price", e.target.value.replace(/[^0-9.]/g, ""))
            }
            value={`${basicInfo.price == "" ? "" : `$${basicInfo.price}`}`}
            placeholder='Price'
            comboPriceValidate
            error={comboPriceValidate == "" ? "Price is required" : null}
            inputPlaceholder='$0.00'
            maxLength={9}
            onBlur={() => {
              handleOnBlurChange(basicInfo.price);
            }}
            // inputProps={{ step: 0.01, min: 0.00 }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        alignItems='center'
        style={{ marginTop: 20, padding: "0px 24px" }}>
        <Grid item xs={6}>
          <H4 label='Active item' style={{ marginRight: 60 }} />
        </Grid>
        <Grid item xs={6}>
          <CustomCheckbox
            checked={basicInfo.available == 1}
            onChange={(e) =>
              onChangeFields("available", e.target.checked ? 1 : 0)
            }
          />
        </Grid>
      </Grid>
      {ordertypes.map((otype, indx) => (
        <Grid
          container
          direction='row'
          alignItems='center'
          style={{ padding: "0px 50px" }}>
          <Grid item xs={6}>
            <DotText label={otype.name} style={{ marginRight: 60 }} />
          </Grid>
          <Grid item xs={6}>
            <CustomCheckbox
              checked={basicInfo.ordertypes.indexOf(otype.otype_id) !== -1}
              onChange={(e) =>
                onOrderTypeSelect(e.target.checked, otype.otype_id)
              }
            />
          </Grid>
        </Grid>
      ))}
      <Grid
        container
        direction='row'
        style={{ marginTop: 20, padding: 20, backgroundColor: "#F3FAFD" }}>
        <Grid item xs={6}>
          <InputText
            onChange={(e) => onChangeFields("desc", e.target.value)}
            value={basicInfo.desc}
            placeholder='Description'
            height={150}
            maxLength={256}
            multiline
            inputProps={{ step: 0.01, min: 0.0 }}
            rows={6}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: 10 }}>
          <AvenirBlackH4 label='Image' style={{ marginLeft: 5 }} />
          <ChangeImage image={basicInfo.img_url} onChange={onImageChange} />
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: 20, padding: 20 }}>
        {basicInfo.combos.map((combo, index) => (
          <Combo
            combo={combo}
            key={index}
            addComboItems={addComboItems}
            commboindex={index}
          />
        ))}
        {comboselectionValidate != true ? (
          <FormHelperText
            data-qaid='inputtext_formhelpertext'
            style={{
              color: "#AB1212",
              display: "flex",
              marginLeft: 0,
              marginTop: 8,
            }}>
            <InfoOutlined
              width={16}
              height={16}
              color='#AB1212'
              style={{ marginRight: 6 }}
            />
            <H5
              label='Combo selection is required'
              style={{ color: "#AB1212 " }}
            />
          </FormHelperText>
        ) : null}
      </Grid>

      <Grid
        container
        direction='row'
        justify='center'
        onClick={() =>
          addComboItems(
            { combo_selection_name: "", combo_selection_items: [] },
            -1
          )
        }>
        <CirclePlus color='#004C60' />
        <AvenirBlackH3
          label='Add new combo selection'
          style={{ marginLeft: 5, color: "#004C60" }}
        />
      </Grid>
    </Grid>
  );
};

const Combo = ({ combo, addComboItems, commboindex }) => (
  <Grid container>
    <Grid
      container
      direction='row'
      style={{
        paddingTop: 16,
        paddingBottom: 16,
        borderBottom: "1px solid #EEEEEE",
      }}>
      <Grid item xs={8}>
        <AvenirBlackH3 label={combo.combo_selection_name} />
      </Grid>
      <Grid item xs={4}>
        <Grid
          container
          direction='row'
          justify='flex-end'
          onClick={() => addComboItems(combo, commboindex)}>
          <CirclePlus color='#004C60' />
          <AvenirBlackH3
            label='Add'
            style={{ marginLeft: 5, color: "#004C60" }}
          />
        </Grid>
      </Grid>
    </Grid>
    {combo.combo_selection_items.length > 0 && (
      <Grid container style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <H4 label='Name' style={{ color: "#707272" }} />
        </Grid>
        <Grid container>
          {combo.combo_selection_items.map((item, index) => {
            let name = item.item_name;
            if (item.price) {
              name += `  (+$${ConvertPrice(item.price)})`;
            }
            return (
              <Grid item xs={12} key={index} style={{ marginTop: 16 }}>
                <H4 label={name} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    )}
  </Grid>
);
export default Basic;
