import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Button,
  Popper,
  IconButton,
  ClickAwayListener,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import moment from "moment";
import clsx from "clsx";
import { H4, AvenirBlackH4 } from "../utils/text";
import { CalenderIcon } from "./SVG";

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: '200px',
    border: "1px solid #004C60",
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: 10,
    height: 56,
    cursor: "pointer",
  },
  popover: {
    width: 240,
    padding: 10,
    marginTop: 0,
    border: "1px solid #004C60",
  },
  monthItem: {
    padding: 6,
    fontWeight: "bold",
    borderRadius: 5,
  },
  typography: {
    padding: theme.spacing(2),
  },
  selected: {
    backgroundColor: "#92D2E7",
    color: "#051D33",
  },
}));
const months = [
  { month: 1, name: "Jan" },
  { month: 2, name: "Feb" },
  { month: 3, name: "Mar" },
  { month: 4, name: "Apr" },
  { month: 5, name: "May" },
  { month: 6, name: "Jun" },
  { month: 7, name: "Jul" },
  { month: 8, name: "Aug" },
  { month: 9, name: "Sep" },
  { month: 10, name: "Oct" },
  { month: 11, name: "Nov" },
  { month: 12, name: "Dec" },
];
const MonthPicker = ({ selectedDate, handleDateChange }) => {
  console.log("selectedDate selectedDate", selectedDate);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [displayString, setDisplayString] = useState();
  const [isEnable, toggleEnable] = useState(true);
  const handleClick = (event) => {
    if (!open) {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    }
  };
  useEffect(() => {
    if (selectedDate) {
      setSelectedMonth(parseInt(selectedDate.month));
      setSelectedYear(parseInt(selectedDate.year));
      setDisplayString(
        `${moment()
          .month(selectedDate.month - 1)
          .format("MMM")} ${selectedDate.year}`
      );
    }
  }, [selectedDate]);
  const close = () => {
    setOpen(false);
  };
  useEffect(() => {
    const d = new Date();
    const selected = new Date();
    selected.setFullYear(selectedYear);
    selected.setMonth(selectedMonth - 1);
    toggleEnable(d >= selected);
  }, [selectedMonth, selectedYear]);
  const apply = () => {
    if (isEnable) {
      handleDateChange({ year: selectedYear, month: selectedMonth });
      close();
    }
  };

  return (
    <ClickAwayListener onClickAway={close}>
      <Grid
        data-qaid='monthpicker_main_grid'
        container
        justify='center'
        className={classes.root}
        onClick={handleClick}>
        <CalenderIcon />
        <H4
          data-qaid='monthpicker_button'
          label={displayString}
          style={{ paddingLeft: 10 }}
        />
        {/* <ArrowDownIcon data-qaid="monthpicker_arrowdown_icon" onClick={handleClick} /> */}
        <Popper
          data-qaid='monthpicker_popper'
          open={open}
          anchorEl={anchorEl}
          transition
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}>
          <Paper data-qaid='monthpicker_paper' className={classes.popover}>
            <Grid
              data-qaid='monthpicker_content_grid'
              container
              justify='center'
              alignItems='center'
              style={{ borderBottom: "1px solid #f2f2f2" }}>
              <Grid data-qaid='monthpicker_calender_grid' item xs={12}>
                <Grid data-qaid='monthpicker_year_main_grid' container>
                  <Grid data-qaid='monthpicker_minus_grid' item xs={3}>
                    <IconButton
                      data-qaid='monthpicker_year_minusbutton'
                      onClick={() => setSelectedYear(selectedYear - 1)}>
                      <KeyboardArrowLeftIcon data-qaid='monthpicker_minus_icon' />
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    lg={6}
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}>
                    {/* <Grid data-qaid="monthpicker_year_grid" container alignItems="center" style={{ height: '100%' }}> */}
                    {/* <Button data-qaid="monthpicker_year_button"> */}
                    <AvenirBlackH4
                      data-qaid='monthpicker_year_text'
                      label={selectedYear}
                    />
                    {/* </Button> */}
                    {/* </Grid> */}
                  </Grid>
                  <Grid data-qaid='monthpicker_icon_grid' item xs={3}>
                    <IconButton
                      data-qaid='monthpicker_icon_button'
                      onClick={() => setSelectedYear(selectedYear + 1)}>
                      <KeyboardArrowRightIcon data-qaid='monthpicker_keyboardrighticon' />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justify='center' style={{ padding: 5 }}>
              {months.map((month) => (
                <Grid
                  data-qaid='monthpicker_month_grid'
                  item
                  xs={3}
                  key={month.name}>
                  <Grid
                    data-qaid='monthpicker_month_grid_1'
                    container
                    justify='center'
                    className={clsx(
                      classes.monthItem,
                      selectedMonth === month.month && classes.selected
                    )}>
                    <Grid
                      data-qaid='monthpicker_month_button'
                      onClick={() => setSelectedMonth(month.month)}
                      style={{
                        cursor: "pointer",
                        height: 30,
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}>
                      <H4
                        data-qaid='monthpicker_month_name'
                        variant='h6'
                        label={month.name}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid
              data-qaid='monthpicker_actions_grid'
              container
              style={{ borderTop: "1px solid #EEE" }}>
              <Grid
                container
                data-qaid='dateRange_button_div'
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: 20,
                  marginRight: 20,
                  alignItems: "center",
                  flexDirection: "row",
                  marginTop: 10,
                }}>
                <Button
                  data-qaid='dateRange_close_button_grid'
                  variant='contained'
                  style={{ backgroundColor: "#00B6C9", marginRight: 10 }}
                  onClick={close}>
                  <H4 data-qaid='dateRange_close_button_label' label='Close' />
                </Button>
                <Button
                  data-qaid='dateRange_apply_button'
                  variant='contained'
                  style={{ backgroundColor: isEnable ? "#FFB600" : "#c6c6c6" }}
                  onClick={apply}>
                  <H4 data-qaid='dateRange_apply_button_label' label='Apply' />
                </Button>
              </Grid>
              {/* <Grid data-qaid="monthpicker_close_grid1" item xs={6}>
              <Grid data-qaid="monthpicker_close_grid" container justify="center">
                <Button data-qaid="monthpicker_close_button" onClick={close}>Close</Button>
              </Grid>

            </Grid>
            <Grid item xs={6}>
              <Grid data-qaid="monthpicker_apply_grid" container justify="center">
                <Button data-qaid="monthpicker_apply_button" onClick={apply}>Apply</Button>
              </Grid>
            </Grid> */}
            </Grid>
          </Paper>
        </Popper>
      </Grid>
    </ClickAwayListener>
  );
};

export default MonthPicker;
