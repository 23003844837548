import * as React from "react";

const Close = ({
  width = 24,
  height = 24,
  color = "#fff",
  background = "#000",
  ...props
}) => (
  <svg
    {...props}
    width={width}
    height={height}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z'
      fill={background}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.374 8.626a1 1 0 010 1.414L10.04 23.374a1 1 0 01-1.414-1.414L21.96 8.626a1 1 0 011.415 0z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.626 8.626a1 1 0 011.414 0L23.374 21.96a1 1 0 01-1.415 1.414L8.626 10.04a1 1 0 010-1.414z'
      fill={color}
    />
  </svg>
);

export default Close;
