import React from "react";
import { Grid } from "@material-ui/core";
import { AvenirBlackH3 } from "../../../../utils/text";
import Edit from "../../../../components/SVG/Edit";

const Title = ({
  title,
  edit = false,
  onPress,
  marginLeft = 20,
  paddingBottom = 0,
}) => (
  <Grid
    container
    style={{
      paddingTop: 15,
      paddingBottom,
      borderBottom: "1px solid #EEE",
      marginLeft,
    }}>
    <Grid item xs={9}>
      <AvenirBlackH3 label={title} />
    </Grid>
    {edit && (
      <Grid xs={3}>
        <Grid container justify='flex-end'>
          <span
            onClick={onPress}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
            }}>
            <Edit color='#004C60' width={20} height={20} />
            <AvenirBlackH3
              label='Edit'
              style={{ color: "#004C60", marginLeft: 5 }}
            />
          </span>
        </Grid>
      </Grid>
    )}
  </Grid>
);

export default Title;
