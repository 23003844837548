import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Button, MenuItem, Menu } from "@material-ui/core";
import _ from "lodash";
import MultiSelect from "../../../components/MultiSelect";
import MyContext from "../../../Provider/MyContext";
import ReportsTable from "../../../components/ReportsTable";
import Daterange from "../../../components/DateRange";
import DownlodeIcon from "../../../components/SVG/DownlodeIcon";
import { AvenirBlackH7, AvenirBlackH4, H2 } from "../../../utils/text";
import { fetchKitchensData } from "../../../redux/actions";
import CircleClose from "../../../components/SVG/CircleClose";
import { downloadKitchenReport } from "../../../utils/excel";
import { ConvertCurrencyPriceToDollar } from "../../../utils/util";
import { downloadKitchenInventoryPdfDocument } from "../../../components/PDFTableGenerator";

const Kitchens = () => {
  const Context = useContext(MyContext);
  const [table1, setTable1] = useState([]);
  const [kitchens, setKitchens] = useState([]);
  const kitchenName = useSelector((state) => state.inventory.departments);
  const kitchenData = useSelector(
    (state) => state.inventoryReportReducer.kitchens
  );
  const [resetDate, toggleResetDate] = useState(false);
  const [date, setDate] = useState({
    // from_date: moment().toISOString(),
    // to_date: moment().endOf('day').toISOString(),
  });
  const dispatch = useDispatch();
  const [kitchenColumns, setKitchenColumns] = useState([
    { field: "itemName", display: Context.langData.stock_item, size: "30%" },
    {
      field: "issuedQuantity",
      display: Context.langData.issued_qty,
      size: "20%",
    },
    { field: "itemUnitTypeCode", display: Context.langData.units, size: "10%" },
    {
      field: "averagePriceValue",
      display: Context.langData.avg_price,
      size: "20%",
      isNumber: true,
    },
    {
      field: "amountValue",
      display: Context.langData.amount,
      size: "20%",
      isNumber: true,
    },
  ]);
  const [anchorEl, setAnchorEl] = useState(null);

  const onDateChange = (d) => {
    if (date.from_date !== d.startDate || date.to_date !== d.endDate) {
      setDate({
        from_date: d.startDate,
        to_date: d.endDate,
      });
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleApplyKitchenData();
    }, 100);
    return () => clearTimeout(timeout);
  }, [date, kitchens]);
  const handleApplyKitchenData = () => {
    let obj = { ...date };
    if (kitchens.length > 0) {
      obj = {
        ...obj,
        kitchen_ids: kitchens.join(","),
      };
    } else {
      obj = {
        ...obj,
        kitchen_ids: "all",
      };
    }
    dispatch(fetchKitchensData(obj));
  };
  const handleClearKitchenData = () => {
    setKitchens([]);
    toggleResetDate(true);
    setTimeout(() => {
      toggleResetDate(false);
    }, 200);
  };
  useEffect(() => {
    if (kitchenData?.data?.length > 0) {
      const total = kitchenData.totalAmount;
      const parsed_data = [];

      kitchenData?.data?.map((obj) => {
        if (obj?.data.length > 0) {
          obj?.data.map((stock) => {
            stock.averagePriceValue = ConvertCurrencyPriceToDollar(
              stock.averagePrice
            );
            stock.amountValue = ConvertCurrencyPriceToDollar(stock.amount);
          });
        }
        if (obj.data.length != 0) parsed_data.push(obj);
      });
      var KitchensReportData = {
        total: ConvertCurrencyPriceToDollar(total),
        kitchens_data: parsed_data,
      };
      setTable1(KitchensReportData);
    } else {
      var KitchensReportData = {
        total: "0.00",
        kitchens_data: [],
      };
      setTable1(KitchensReportData);
    }
  }, [kitchenData]);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const downloadExcel = () => {
    if (table1) downloadKitchenReport(table1);
    handleCloseMenu();
  };

  const downloadPDF = () => {
    const headers = [...kitchenColumns];
    downloadKitchenInventoryPdfDocument(
      headers,
      table1.kitchens_data,
      Context.langData.kitchen_inventory_report,
      date,
      table1.total,
      "KitchenInventory"
    );

    handleCloseMenu();
  };

  const handleSort = (name, desc, index, catIndx) => {
    const columnData = [...kitchenColumns];
    columnData[index] = { ...columnData[index], desc };
    setKitchenColumns(columnData);
    const stocks = table1.kitchens_data;
    const catItems = stocks[catIndx].data;
    let sortItemwiseSales;
    if (
      name == "averagePriceValue" ||
      name == "amountValue" ||
      name == "issuedQuantity"
    ) {
      sortItemwiseSales = _.orderBy(
        catItems,
        [(catItem) => catItem[name]],
        [desc ? "desc" : "asc"]
      );
    } else {
      sortItemwiseSales = _.orderBy(
        catItems,
        [(catItem) => catItem[name].toLowerCase()],
        [desc ? "desc" : "asc"]
      );
    }
    stocks[catIndx].data = sortItemwiseSales;
    setTable1({ ...table1, kitchens_data: stocks });
  };

  return (
    <Grid
      data-qaid='kitchens_kitchens_main_grid'
      container
      style={{ paddingLeft: 20, paddingRight: 20 }}>
      <Grid
        data-qaid='kitchens_kitchens_item'
        container
        style={{
          flexDirection: "row",
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <Grid
          item
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}>
          <Grid data-qaid='kitchens_date_grid' item style={{}}>
            <AvenirBlackH4
              data-qaid='kitchens_date_labell'
              label={Context.langData.from_to}
            />
            <Daterange
              data-qaid='kitchens_dateRange'
              onDateChange={onDateChange}
              refresh={resetDate}
            />
          </Grid>

          <Grid
            data-qaid='kitchens_selectKitchen'
            item
            style={{ marginLeft: 20 }}>
            <AvenirBlackH4
              data-qaid='kitchens_selectKitchenLabel'
              label={Context.langData.select_kitchen}
            />
            <MultiSelect
              list={kitchenName}
              value='kitchen_id'
              name='name'
              placeholder={Context.langData.all_kitchens}
              selectedValue={kitchens}
              handleChange={(e) => setKitchens(e)}
              border={false}
              height={56}
              width={298}
            />
          </Grid>
        </Grid>

        <Grid
          data-qaid='kitchens_clearMainContainer'
          item
          style={{
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-end",
            marginLeft: 20,
            marginTop: 10,
          }}>
          <Grid
            data-qaid='kitchens_clearMainGrid'
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: 20,
              alignItems: "center",
              cursor: "pointer",
            }}>
            <CircleClose
              data-qaid='kitchens_clearLabelView'
              onClick={() => handleClearKitchenData()}
            />
            <AvenirBlackH4
              data-qaid='kitchens_clearLabel'
              label={Context.langData.clear}
              style={{ marginLeft: 5 }}
            />
          </Grid>
          <Button
            onClick={(event) => setAnchorEl(event.currentTarget)}
            data-qaid='kitchens_downlodeLabelView'
            style={{
              backgroundColor: "#FFB600",
              height: 40,
              marginRight: 0,
              width: "149px",
            }}
            variant='contained'
            id='cancel'>
            <DownlodeIcon
              data-qaid='kitchens_downlodeIcon'
              style={{ marginRight: 10 }}
            />
            <AvenirBlackH4
              data-qaid='kitchens_downlodeLabel'
              label={Context.langData.download}
            />
          </Button>
          <Menu
            id='simple-menu-download'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}>
            <MenuItem onClick={downloadExcel}>
              {Context.langData.export_excel}
            </MenuItem>
            <MenuItem onClick={downloadPDF}>
              {Context.langData.export_pdf}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>

      <Grid
        data-qaid='kitchens_totalAmountContainer'
        container
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: 20,
          marginTop: 20,
          marginBottom: 20,
        }}>
        <Grid
          data-qaid='kitchens_totalAmountGrid'
          style={{
            flexDirection: "row",
            dispaly: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <AvenirBlackH7
            data-qaid='kitchens_totalAmountLabel1'
            label={`$${table1.total}`}
            style={{ fontWeight: "bold" }}
          />
          <AvenirBlackH4
            data-qaid='kitchens_totalAmountLabel2'
            label={Context.langData.total_amount}
          />
        </Grid>
      </Grid>
      <Grid
        data-qaid='kitchens_reportsTableView'
        item
        xs={12}
        lg={12}
        style={{ marginTop: 20 }}>
        {table1?.kitchens_data?.length > 0 ? (
          table1.kitchens_data.map((item, index) => (
            <Grid
              key={index}
              style={{ display: "flex", flexDirection: "column" }}>
              <Grid
                data-qaid='kitchens_kitchenNameGrid'
                style={{
                  flexDirection: "row",
                  dispaly: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: 20,
                }}>
                <H2 data-qaid='kitchens_kitchenName' label={item.kitchenName} />
              </Grid>
              <ReportsTable
                data-qaid='kitchens_reportsTable'
                columns={kitchenColumns}
                rows={item.data}
                handleSort={(name, desc, colIndex) =>
                  handleSort(name, desc, colIndex, index)
                }
              />
            </Grid>
          ))
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};

export default Kitchens;
