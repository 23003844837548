// convert from ApiSSOLoginRedirect to SSOLoginRedirect
import { SSOLoginRedirect } from "./SsoModels";

export function convertSSOModel(obj) {
  const result = new SSOLoginRedirect("");
  if (!obj) return result;

  result.url = obj["redirect url"];

  return result;
}
