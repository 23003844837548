import React, { useEffect, useState, useContext } from "react";
import { Grid, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { fetchAllIssuesView } from "../../../../redux/actions";
import RightDrawer from "../../../../components/RightDrawer";
import MyContext from "../../../../Provider/MyContext";
import InventoryIcon from "../../../../components/SVG/InventoryIcon";
import CircleClose from "../../../../components/SVG/CircleClose";
import { AvenirBlackH4, H4, H6 } from "../../../../utils/text";
import styles from "../../../../styles/index";
import SendIssue from "./viewSendStock";
import ReturnIssue from "./viewReturnStock";

const ViewIssueId = ({ open, onClose, row, callback }) => {
  const style = styles();
  const dispatch = useDispatch();
  const issuesViewData = useSelector((state) => state.inventory.issuesViewData);
  const [table, setTable] = useState([]);
  const [showRepeatIssue, setShowRepeatIssue] = useState(false);
  const [showSendIssue, setShowSendIssue] = useState(false);
  const Context = useContext(MyContext);

  useEffect(() => {
    if (
      issuesViewData &&
      issuesViewData.items &&
      issuesViewData.items.length > 0
    ) {
      setTable(issuesViewData.items);
    } else {
      setTable([]);
    }
  }, [issuesViewData]);

  useEffect(() => {
    dispatch(fetchAllIssuesView(row.id, fetxhCallback));
  }, []);
  const fetxhCallback = () => {};

  const handleRepeatIssue = () => {
    if (row.type == "Issue") {
      setShowSendIssue(true);
    } else {
      setShowRepeatIssue(true);
    }
  };
  return (
    <RightDrawer open={open} padding={1}>
      <>
        <Grid
          data-qaid='viewIssueId__body_grid'
          style={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            width: 592,
          }}>
          <Grid
            data-qaid='viewIssueId__child1_grid'
            style={{
              display: "flex",
              height: "85%",
              flexDirection: "column",
              overflowX: "auto",
            }}>
            <Grid
              data-qaid='viewIssueId__close_grid'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}>
              <CircleClose
                data-qaid='viewIssueId__closeicon'
                size={20}
                onClick={onClose}
                style={{ marginRight: 36, marginTop: 20 }}
              />
            </Grid>
            <Grid
              data-qaid='viewIssueId__settings_grid'
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 36,
                marginBottom: 10,
              }}>
              <InventoryIcon
                data-qaid='viewIssueId__setting_icon'
                style={{ marginRight: 10 }}
                width={22.5}
                height={24}
                color='#707272'
              />
              <H4
                data-qaid='viewIssueId__settings'
                label={Context.langData.inventory}
                style={{ color: "#707272" }}
              />
            </Grid>
            <H6
              data-qaid='viewIssueId__title'
              style={{ marginLeft: 36, marginBottom: 20, marginRight: 36 }}
              label={Context.langData.issues}
            />

            <Grid
              data-qaid='viewIssueId_main_grid'
              style={{
                marginLeft: 36,
                marginBottom: 20,
                marginRight: 36,
                width: 500,
                display: "flex",
                flexDirection: "column",
              }}>
              <Grid
                data-qaid='viewIssue_kitchenContainer'
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}>
                <Grid
                  data-qaid='viewIssue_kitchenGrid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "50%",
                  }}>
                  <H4
                    data-qaid='viewIssue_kitchenLabel'
                    label={`${Context.langData.kitchen}: ${row.kitchenName}`}
                    style={{ marginBottom: 10 }}
                  />
                </Grid>
                <Grid
                  data-qaid='viewIssue_dateGrid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    width: "50%",
                    marginLeft: 20,
                    alignItems: "flex-end",
                  }}>
                  <H4
                    data-qaid='viewIssue_dateLabel'
                    label={`${Context.langData.date}: ${moment(row.dateTime).format("MMM DD, YYYY")}`}
                    style={{ marginBottom: 10 }}
                  />
                </Grid>
              </Grid>
              <Grid
                data-qaid='viewIssue_secondContainer'
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}>
                <Grid
                  data-qaid='viewIssue_issueGrid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "50%",
                  }}>
                  <H4
                    data-qaid='viewIssue_issueLabel'
                    label={`${Context.langData.issue_number}: ${row.id}`}
                    style={{ marginBottom: 10 }}
                  />
                </Grid>
                <Grid
                  data-qaid='viewIssue_statusGrid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    width: "50%",
                    marginLeft: 20,
                    alignItems: "flex-end",
                  }}>
                  <H4
                    data-qaid='viewIssue_statusLabel'
                    label={`${Context.langData.status}: ${row.type}`}
                    style={{ marginBottom: 10 }}
                  />
                </Grid>
              </Grid>
              <Grid
                data-qaid='viewIssue_table_body'
                item
                style={{
                  marginTop: "20px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}>
                <Grid
                  data-qaid='viewIssue_subContainer3'
                  item
                  style={{
                    height: "40px",
                    width: "100%",
                    backgroundColor: "#F3FAFD",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}>
                  <Grid data-qaid='viewIssue_dateGrid' style={{ width: "70%" }}>
                    <AvenirBlackH4
                      data-qaid='viewIndentId_itemHeading'
                      label={Context.langData.items}
                    />
                  </Grid>
                  <Grid data-qaid='viewIssue_dateGrid' style={{ width: "30%" }}>
                    <AvenirBlackH4
                      data-qaid='viewIssue_request_qtyHeading'
                      label={Context.langData.dispatch_qty}
                    />
                  </Grid>
                </Grid>
                {table?.length > 0 ? (
                  table.map((itemObj, index) => (
                    <Grid
                      data-qaid='viewIssue_subContainer3'
                      item
                      style={{
                        height: "40px",
                        width: "100%",
                        backgroundColor: "#FAFAFA",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 8,
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}>
                      <Grid
                        data-qaid='viewIssue_dateGrid'
                        style={{ width: "70%" }}>
                        <H4
                          data-qaid='viewIndentId_itemHeading'
                          label={`${itemObj.name}`}
                        />
                      </Grid>
                      <Grid
                        data-qaid='viewIssue_dateGrid'
                        style={{ width: "30%" }}>
                        <H4
                          data-qaid='viewIssue_request_qtyHeading'
                          label={`${itemObj.quantity} ${itemObj.unitTypeCode}`}
                        />
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            data-qaid='viewIssueId_cancel_main_Grid'
            style={{
              display: "flex",
              height: "15%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid
              data-qaid='viewIssueId_cancelGrid'
              item
              style={{
                display: "flex",
                justifyContent: "flex-end",
                backgroundColor: "#FAFAFA",
                width: "100%",
                paddingTop: "25px",
                alignItems: "center",
                paddingBottom: "25px",
                border: "1px solid #C3C3C3",
              }}>
              <Button
                className={style.buttonClass}
                data-qaid='viewIssueId_cancelButton'
                style={{
                  backgroundColor: "#00B6C9",
                  color: "white",
                  marginRight: 10,
                  borderColor: "#707272",
                }}
                variant='contained'
                id='cancel'
                onClick={onClose}>
                <AvenirBlackH4
                  data-qaid='viewIssueId_cancelLabel'
                  label={Context.langData.cancel}
                />
              </Button>
              <Button
                className={style.buttonClass}
                data-qaid='viewIssueId_saveButton'
                style={{
                  backgroundColor: "#FFB600",
                  color: "white",
                  marginRight: 10,
                }}
                variant='contained'
                id='cancel'
                type='submit'
                onClick={handleRepeatIssue}>
                <AvenirBlackH4
                  data-qaid='viewIssueId_saveLabel'
                  label={`${row.type == "Issue" ? Context.langData.repeat_issue : Context.langData.repeat_return}`}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {showRepeatIssue && (
          <ReturnIssue
            open={showRepeatIssue}
            data={table}
            onClose={() => setShowRepeatIssue(false)}
            callback={callback}
          />
        )}
        {showSendIssue && (
          <SendIssue
            open={showSendIssue}
            data={table}
            onClose={() => setShowSendIssue(false)}
            callback={callback}
          />
        )}
      </>
    </RightDrawer>
  );
};

export default ViewIssueId;
