import moment from "moment-timezone";
import setState from "./setState";

const initialState = {
  transactions: [],
  purchases: {},
  refundObj: {},
};
const parsePurchasesData = (data) => {
  // const tz = "Canada/Pacific";
  if (typeof data.indents !== "undefined") {
    data.balance_amount =
      Math.round((data.total_amount - data.amount_paid) * 100) / 100;
    data.indents.map((item, index) => {
      const status_text =
        item.amount_paid == 0
          ? "Not Settled"
          : item.total_amount == item.amount_paid
            ? "Settled"
            : "Partly Settled";
      if (item.notes != null) {
        if (item.notes.length > 25) item.notes = item.notes.substring(0, 25);
      } else {
        item.notes = "N/A";
      }
      // moment.setDefault(tz)
      item.date = moment(item.ts * 1000)
        .tz(moment.tz.guess())
        .format("h:mm a MMM D, YYYY");
      item.bill_view = item.indent_id;
      item.balance_amount =
        Math.round((item.total_amount - item.amount_paid) * 100) / 100;
      item.status_text = status_text;
      item.status_id =
        status_text == "Not Settled"
          ? 1
          : status_text == "Partly Settled"
            ? 2
            : 3;
    });
    return data;
  }
  return { total_amount: 0, amount_paid: 0, balance_amount: 0, indents: [] };
};
const parseTransactionData = (data) => {
  // const tz = "Canada/Pacific";
  data.transactions.map((item, index) => {
    const channel_order_number =
      item.order_channel_name == "Kiosk"
        ? item.kiosk
        : item.order_channel_name == "Third Party" &&
            item.partner_id == item.integrated_partner_id
          ? item.external_order_id
          : item.marketplace_order_number;

    // moment.setDefault(tz)
    item.channel_order_number = channel_order_number;
  });
  return data;
};

function transactions(state = initialState, action) {
  switch (action.type) {
    case "SET_TRANSACTIONS":
      return setState(
        state,
        parseTransactionData(action.payload),
        "transactions"
      );
    case "SET_PURCHASE_TRANSACTIONS":
      return setState(state, parsePurchasesData(action.payload), "purchases");
    case "SET_TRANSACTION_REFUND":
      return setState(state, action.payload, "refundObj");
    case "CLEAR":
      return { ...initialState };
    default:
      return state;
  }
}

export default transactions;
