import React, { useState, useContext, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import RightDrawer from "../../../components/RightDrawer";
import MyContext from "../../../Provider/MyContext";
import styles from "../../../styles/index";
import { AvenirBlackH4, H4, H6 } from "../../../utils/text";
import InventoryIcon from "../../../components/SVG/InventoryIcon";
import CircleClose from "../../../components/SVG/CircleClose";
import AutoCompliteDropDown from "../../../components/AutoComplete";
import CloseCircle from "../../../components/SVG/CircleClose";
import { assignStocksToVendor } from "../../../redux/actions";

const ViewVendorStock = ({ open, row, onClose, callback }) => {
  const style = styles();
  const getStocks = useSelector((state) => state.inventory.stocks);
  const [stockArray, setStockArray] = useState([]);
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("row==>", row);
    if (row) {
      if (row.items) {
        setStockArray(row.items);
      } else {
        setStockArray([]);
      }
    }
  }, []);
  const selectedStock = (value) => {
    const itemListArray = [...stockArray];
    const item = itemListArray.filter((e) => e.stock_name == value);
    if (item.length > 0) {
    } else if (getStocks && getStocks.length > 0) {
      const stock = getStocks.filter((e) => e.name == value);
      if (stock.length > 0) {
        const obj = {
          id: stock[0].id,
          name: stock[0].name,
          categoryId: stock[0].categoryId,
          categoryName: stock[0].categoryName,
          unitTypeCode: stock[0].unitTypeCode,
        };
        itemListArray.push(obj);
      }
    }
    console.log("itemListArray=>", itemListArray);
    setStockArray(itemListArray);
  };
  const handleDelete = (item) => {
    const stockArrayData = [...stockArray];
    const indexValue = stockArrayData.findIndex(
      (element) => element.id == item.id
    );
    stockArrayData.splice(indexValue, 1);
    setStockArray(stockArrayData);
  };
  const handleSave = () => {
    const stockids = [];
    if (stockArray && stockArray.length > 0) {
      stockArray.map((item) => {
        const itm = { id: item.id };
        stockids.push(itm);
      });
    }
    const obj = {
      data: stockids,
    };
    console.log("objobj==>>", obj);
    dispatch(assignStocksToVendor(row.vendor_id, obj, callback));
  };
  const handleSelect = (value) => {
    selectedStock(value);
  };
  return (
    <RightDrawer open={open} padding={1}>
      <>
        <Grid
          data-qaid='viewIndentId_body_grid'
          style={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
          }}>
          <Grid
            data-qaid='viewIndentId_child1_grid'
            style={{
              display: "flex",
              height: "85%",
              flexDirection: "column",
              overflowX: "auto",
            }}>
            <Grid
              data-qaid='viewIndentId_close_grid'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}>
              <CircleClose
                data-qaid='viewIndentId_closeicon'
                size={20}
                onClick={onClose}
                style={{ marginRight: 40, marginTop: 20 }}
              />
            </Grid>
            <Grid
              data-qaid='viewIndentId_settings_grid'
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 56,
                marginBottom: 10,
              }}>
              <InventoryIcon
                data-qaid='viewIndentId_setting_icon'
                style={{ marginRight: 10 }}
                width={22.5}
                height={24}
                color='#707272'
              />
              <H4
                data-qaid='viewIndentId_settings'
                label={Context.langData.inventory}
                style={{ color: "#707272" }}
              />
            </Grid>
            <H6
              data-qaid='viewIndentId_title'
              style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
              label={Context.langData.vendors_stock}
            />
            {/* <Grid style={{ borderBottom: '1px solid #EEEEEE', marginLeft: 56, marginBottom: 20, marginRight: 56 }}>
                            </Grid> */}
            <Grid
              data-qaid='viewIndentId_main_grid'
              style={{
                marginLeft: 56,
                marginBottom: 20,
                marginRight: 56,
                width: 500,
                display: "flex",
                flexDirection: "column",
              }}>
              <Grid
                data-qaid='raiseIndent_stockItemsGrid'
                item
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 10,
                }}>
                <AvenirBlackH4
                  data-qaid='raiseIndents_stockItemsLabel'
                  label={Context.langData.search_stock}
                  style={{ marginBottom: 10 }}
                />
                <AutoCompliteDropDown
                  options={getStocks}
                  onChange={handleSelect}
                  labelKey='name'
                  key={stockArray}
                />
              </Grid>
              <Grid
                data-qaid='viewIndentId_table_body'
                item
                style={{
                  marginTop: "20px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}>
                {stockArray && stockArray.length > 0 ? (
                  stockArray?.map((item) => (
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        border: "1px solid #C3C3C3",
                        borderRadius: 5,
                        minHeight: 40,
                        marginBottom: 10,
                        alignItems: "center",
                      }}>
                      <Grid style={{ width: "100%" }}>
                        <AvenirBlackH4
                          label={item.name}
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            marginLeft: 10,
                          }}
                        />
                      </Grid>
                      <Grid style={{}}>
                        <CloseCircle
                          onClick={() => {
                            handleDelete(item);
                          }}
                          width={16}
                          height={16}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginRight: 10,
                          }}
                        />
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            data-qaid='createGroup_cancel_main_Grid'
            style={{
              display: "flex",
              height: "15%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid
              data-qaid='createGroup_cancelGrid'
              item
              style={{
                display: "flex",
                justifyContent: "flex-end",
                backgroundColor: "#FAFAFA",
                width: "100%",
                paddingTop: "25px",
                alignItems: "center",
                paddingBottom: "25px",
                border: "1px solid #C3C3C3",
              }}>
              <Button
                className={style.buttonClass}
                data-qaid='createGroup_cancelButton'
                style={{
                  backgroundColor: "#FFF",
                  marginRight: 10,
                  width: 103,
                  border: "2px solid #051D33",
                  height: 40,
                }}
                variant='outlined'
                id='cancel'
                onClick={() => onClose()}>
                <AvenirBlackH4
                  data-qaid='createGroup_cancelLabel'
                  label={Context.langData.cancel}
                  style={{ color: "#004C60" }}
                />
              </Button>
              <Button
                className={style.buttonClass}
                data-qaid='createGroup_saveButton'
                style={{
                  backgroundColor: "#FFB600",
                  marginRight: 10,
                  width: 90,
                  height: 40,
                }}
                variant='contained'
                id='cancel'
                type='submit'
                onClick={() => handleSave()}>
                <AvenirBlackH4
                  data-qaid='createGroup_saveLabel'
                  label={Context.langData.save}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    </RightDrawer>
  );
};

export default ViewVendorStock;
