import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  makeStyles,
  withStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import VerticalTabs from "../../../components/verticalTabs";
import {
  fetchAllRecipeItems,
  fetchAllRecipeAddons,
  fetchAllStocks,
} from "../../../redux/actions";
import MyContext from "../../../Provider/MyContext";
import Items from "./items";
import Addons from "./addons";

const useStyles = makeStyles({
  btnItems: {
    backgroundColor: "#f0585f",
    color: "white",
    marginRight: "20px",
  },
  btnDownload: {
    backgroundColor: "#f0585f",
    color: "white",
    marginLeft: "10px",
  },
});

const InventoryRecipe = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectIndexTabChange, setSelectIndexTabChange] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const Context = useContext(MyContext);

  useEffect(() => {
    dispatch(fetchAllRecipeItems());
    dispatch(fetchAllRecipeAddons());
    dispatch(fetchAllStocks());
  }, []);

  const Tabs = [
    { name: Context.langData.items, id: 0 },
    { name: Context.langData.addons, id: 1 },
  ];

  const callback = (value) => {
    if (value == 1) {
      setSelectIndexTabChange(1);
    } else {
      setSelectIndexTabChange(0);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #f4f4f5",
      //   color:'green'
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      "&:focus": {
        backgroundColor: "white",
        color: "black",
        // height:'20px',
        // width:'20px',
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {},
      },
    },
  }))(MenuItem);

  return (
    <Grid data-qaid='recipe_index' container style={{ padding: 20 }}>
      <Grid
        data-qaid='recipe_verticalTab_grid'
        item
        xs={12}
        lg={2}
        style={{ backgroundColor: "#FAFAFA" }}>
        <VerticalTabs
          data-qaid='recipe_verticalTabs'
          tabData={Tabs}
          indexCallback={callback}
        />
      </Grid>
      <Grid
        data-qaid='recipe_content_grid'
        item
        xs={12}
        lg={10}
        style={{ paddingLeft: "10px" }}>
        {selectIndexTabChange == 0 && <Items data-qaid='recipe_items' />}

        {selectIndexTabChange == 1 && <Addons data-qaid='recipe_addons' />}
      </Grid>
    </Grid>
  );
};

export default InventoryRecipe;
