import React, { useCallback, useState, useEffect, useContext } from "react";
import {
  Grid,
  Button,
  makeStyles,
  withStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import Daterange from "../../../components/DateRange";
import { fetchKitchenName, fetchAllConsumptions } from "../../../redux/actions";
import styles from "../../../styles/index";
import MultiSelect from "../../../components/MultiSelect";
import SimpleTable from "../../../components/SimpleTable";
import MyContext from "../../../Provider/MyContext";
import { H4, AvenirBlackH4 } from "../../../utils/text";
import CircleClose from "../../../components/SVG/CircleClose";
import DownlodeIcon from "../../../components/SVG/DownlodeIcon";

const useStyles = makeStyles({
  btnApply: {
    backgroundColor: "#f0585f",
    color: "white",
    marginLeft: "10px",
  },
  btnClear: {
    backgroundColor: "#dcdcdc",
    color: "black",
  },
});

const InventoryConsumption = () => {
  const style = styles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [date, setDate] = useState({
    start_ts: moment().startOf("day").unix(),
    end_ts: moment().endOf("day").unix(),
  });
  const kitchenData = useSelector((state) => state.metaReducer.kitchenName);
  const consumptionsData = useSelector((state) => state.inventory.consumptions);
  const [kitchenName, setKitchenName] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [table, setTable] = useState([]);
  const Context = useContext(MyContext);
  const [consumptionsColumns, setConsumptionsColumns] = useState([
    { field: "stock_name", display: Context.langData.stock_item, size: "20%" },
    {
      field: "theoritical",
      display: Context.langData.theoretical,
      size: "20%",
    },
    { field: "actual", display: Context.langData.actual, size: "15%" },
    {
      field: "packaging_charges",
      display: Context.langData.difference,
      size: "15%",
    },
    {
      field: "delivery_charges",
      display: Context.langData.variation,
      size: "15%",
    },
    { field: "disposed", display: Context.langData.disposed, size: "15%" },
  ]);
  useEffect(() => {
    console.log("consumptionsData called");
    if (consumptionsData?.length > 0) {
      setTable(consumptionsData);
    } else {
      setTable([]);
    }
  }, [consumptionsData]);

  useEffect(() => {
    console.log(
      "consumptiondataaa date date",
      date,
      "KitchenName,,,,,,,",
      kitchenName
    );
    dispatch(fetchAllConsumptions({ ...date, kitchen_id_list: "all" }));
    dispatch(fetchKitchenName());
  }, [date]);

  const onDateChange = useCallback((d) => {
    setDate({ start_ts: d.start_date, end_ts: d.end_date });
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #f4f4f5",
      //   color:'green'
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      "&:focus": {
        backgroundColor: "white",
        color: "black",
        // height:'20px',
        // width:'20px',
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {},
      },
    },
  }))(MenuItem);

  const handleKitchenList = (value) => {
    // var kitchenList = [...kitchenName]
    // console.log("value value", kitchenList, "value", value)
    // if (kitchenList.length > 0) {
    //     var index = kitchenList.findIndex(e => e == value[0]);
    //     if (index == -1) {
    //         kitchenList.push(value[0])
    //     } else {
    //         kitchenList.splice(index, 1);
    //     }
    // } else {
    //     kitchenList.push(value[0])
    // }
    setKitchenName(value);
    console.log("value value", value);
  };
  const handleApply = () => {
    const obj = {
      ...date,
      kitchen_id_list: kitchenName,
    };
    console.log("handleApply values obj", obj);
    dispatch(fetchAllConsumptions(obj));
    dispatch(fetchKitchenName());
  };

  const sortingOnClick = (value, sortValue, index) => {
    const columnData = [...consumptionsColumns];
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setConsumptionsColumns(columnData);
    const sortConsumptionsColumns = _.orderBy(
      table,
      [value],
      [sortValue ? "desc" : "asc"]
    );
    setTable(sortConsumptionsColumns);
  };
  return (
    <Grid
      data-qaid='consumption_main_grid'
      container
      style={{ backgroundColor: "white", flex: 1, padding: "20px" }}>
      <Grid
        data-qaid='consumption_child_grid'
        container
        style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <Grid
          data-qaid='consumption_date_grid'
          item
          style={{ paddingLeft: 30 }}>
          <AvenirBlackH4
            data-qaid='consumption_date_labell'
            label={Context.langData.from_to}
          />
          <Daterange
            data-qaid='consumption_dateRange'
            onDateChange={onDateChange}
          />
        </Grid>

        <Grid
          data-qaid='consumption_vendor_kitchen_grid'
          item
          style={{ justifyContent: "flex-start", marginLeft: 20 }}>
          <AvenirBlackH4
            data-qaid='consumption_kitchenLabel'
            label={Context.langData.all_kitchens}
          />
          <MultiSelect
            data-qaid='consumption_kitchen_multiselect'
            list={kitchenData}
            value='kitchen_id'
            name='name'
            placeholder={Context.langData.all_kitchens}
            selectedValue={kitchenName}
            handleChange={(e) => handleKitchenList(e)}
            border
            minWidth={319}
          />
        </Grid>
        <Grid
          lg={6}
          data-qaid='consumption_actions_grid'
          item
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: 10,
          }}>
          <CircleClose
            data-qaid='consumption_closeicon'
            style={{ marginRight: 10 }}
            onClick={() => {
              setKitchenName([]);
            }}
          />
          <H4
            data-qaid='consumption_clearlabel'
            label={Context.langData.clear}
          />
          <Button
            className={style.buttonClass}
            variant='contained'
            style={{
              backgroundColor: "#FFB600",
              marginLeft: 20,
              marginRight: 20,
            }}
            onClick={handleApply}>
            <H4 label={Context.langData.apply} />
          </Button>
          <Button
            className={style.buttonClass}
            data-qaid='consumption_creategrid'
            style={{ backgroundColor: "#FFB600" }}
            onClick={handleClick}
            variant='contained'
            id='cancel'>
            <DownlodeIcon
              data-qaid='consumption_createpluse'
              style={{ marginRight: 5 }}
            />
            <H4
              data-qaid='consumption_createlabel'
              label={Context.langData.download}
            />
          </Button>
          {/* <StyledMenu
                        data-qaid="consumption_styled_menu"
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <StyledMenuItem data-qaid="consumption_styledMenu_item">
                            <ListItemText data-qaid="consumption_listitemText" primary="Excel" onClick={() => { alert('Downloading') }} />
                        </StyledMenuItem>
                    </StyledMenu> */}
        </Grid>
      </Grid>
      <Grid
        data-qaid='consumption_simpleTable_grid'
        item
        style={{ width: "100%", marginTop: "20px" }}>
        <SimpleTable
          data-qaid='consumption_simpleTable'
          columns={consumptionsColumns}
          rows={table}
          isEditArrow={false}
          isOnClick={false}
          sortingOnClick={sortingOnClick}
        />
      </Grid>
    </Grid>
  );
};

export default InventoryConsumption;
