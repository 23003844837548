const initialState = {
  salesSummary: {},
  paymentMethods: {},
  orderTypes: {},
  shiftReports: {},
  taxTypes: {},
  daywiseSales: {},
  itemSales: {},
  menuSales: {},
  addonDetails: {},
  waiterDetails: {},
  cashierDetails: {},
  groupDetails: { category_grouping: [] },
  updateGroup: {},
  groupList: {},
  pettycashReports: {},
  employeeSales: [],
  alcoholReports: [],
  onlineSalesSummary: { items: [] },
  modifierSales: [],
};

const parseItemsReport = (data) => {
  console.log("datadatadatadata", data);
  if (data.length > 0) {
    data.push(data.shift());
    data.map((cat, cindex) => {
      if (cat.menu_cat_id == 0) cat.menu_cat = "Deleted Items";
      const { items } = cat;
      items.map((item, iindex) => {
        if (item.serving_name?.toLowerCase() != "standard")
          item.item_name += ` - ${item.serving_name}`;
      });
    });
  }
  return data;
};
function salesReportReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_SALES_SUMMARY_SUCCESS":
      return {
        ...state,
        salesSummary: action.payload,
      };
    case "GET_PAYMENT_METHODS_SUCCESS":
      return {
        ...state,
        paymentMethods: action.payload,
      };
    case "GET_ORDERTYPE_SUCCESS":
      return {
        ...state,
        orderTypes: action.payload,
      };
    case "GET_SHIFT_REPORT_SUCCESS":
      return {
        ...state,
        shiftReports: action.payload,
      };
    case "GET_TAX_TYPE_SUCCESS":
      return {
        ...state,
        taxTypes: action.payload,
      };
    case "GET_DAYWISE_SALES_SUCCESS":
      return {
        ...state,
        daywiseSales: action.payload,
      };
    case "GET_ITEM_SALES_SUCCESS":
      return {
        ...state,
        itemSales: parseItemsReport(action.payload),
      };
    case "GET_MENU_SALES_SUCCESS":
      return {
        ...state,
        menuSales: action.payload,
      };
    case "GET_ADDON_DETAILS_SUCCESS":
      return {
        ...state,
        addonDetails: action.payload,
      };
    case "GET_WAITER_DETAILS_SUCCESS":
      return {
        ...state,
        waiterDetails: action.payload,
      };
    case "GET_CASHIER_DETAILS_SUCCESS":
      return {
        ...state,
        cashierDetails: action.payload,
      };
    case "GET_GROUP_DETAILS_SUCCESS":
      return {
        ...state,
        groupDetails: action.payload,
      };

    case "GET_UPDATE_GROUP_SUCCESS":
      return {
        ...state,
        updateGroup: action.payload,
      };
    case "GET_GROUP_LIST":
      return {
        ...state,
        groupList: action.payload,
      };
    case "GET_PETTYCASH_REPORTS":
      return {
        ...state,
        pettycashReports: action.payload,
      };
    case "GET_EMPLOYEE_SALES":
      return {
        ...state,
        employeeSales: action.payload,
      };
    case "GET_ALCOHOL_REPORTS":
      return {
        ...state,
        alcoholReports: action.payload,
      };
    case "GET_ONLINE_SALES_SUMMARY":
      return {
        ...state,
        onlineSalesSummary: action.payload,
      };
    case "GET_MODIFIER_SALES":
      return {
        ...state,
        modifierSales: action.payload,
      };
    case "CLEAR":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
export default salesReportReducer;
