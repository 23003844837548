import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  AvenirBlackH4,
  AvenirBlackH3,
  H6,
  H4,
  T,
  AvenirBlackH5,
} from "../../../utils/text";
import RightDrawer from "../../../components/RightDrawer";
import CircleClose from "../../../components/SVG/CloseCircle";
import SingleSelect from "../../../components/SingleSelect";
import { ConvertPrice } from "../../../utils/util";
import styles from "../../../styles/index";
import BackIcon from "../../../components/SVG/Back";
import InputText from "../../../components/InputText";
import CustomCheckbox from "../../../components/CustomCheckbox";
import MyContext from "../../../Provider/MyContext";
import SnackBar from "../../../components/SnackBar";

const useStyles = makeStyles((theme) => ({
  price: {
    color: theme.palette.text.price,
    fontWeight: "bold",
    fontSize: "1.5rem",
  },
  paper: {
    padding: 5,
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "#EEE",
      marginLeft: 10,
      marginRight: 10,
    },
  },
  radio: {
    "&$checked": {
      color: "#004C60",
    },
  },
  checked: {
    color: "#707272",
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  mainDiv: {
    backgroundColor: theme.palette.background.paper,
    marginTop: "2%",
  },
  root: {
    paddingLeft: 6,
    width: "100%",
    height: "60px",
    "& $notchedOutline": {
      borderColor: "#707272",
    },
    "&:hover $notchedOutline": {
      borderColor: "#707272",
    },
    "&$focused $notchedOutline": {
      borderColor: "#004C60",
    },
    "&.Mui-error": {
      borderColor: "#AB1212",
    },
  },
  focused: {},
  notchedOutline: {},
  option: {
    borderBottom: "1px solid #EEEEEE",
    "&:last-child": {
      borderBottom: "1px solid #FFF",
    },
    height: 40,
    "&:hover": {
      backgroundColor: "#C8E6F1 !important",
    },
  },
}));

const RefundModal = ({ open, callback, onClose }) => {
  const style = styles();
  const classes = useStyles();
  const Context = useContext(MyContext);
  const refundObj = useSelector((state) => state.transactions.refundObj);
  console.log("refundObj", refundObj);
  const [name, setName] = useState(1);
  const [customerefundData, setCustomrefundData] = useState({
    id: "0",
    amount: 0,
    percentage: 0,
  });
  const [items, setItems] = useState([]);
  const [refundingAmountObj, setRefundingAmountObj] = useState({
    refundAmount: 0,
    refundTax: 0,
    total: 0,
  });
  const [snackbar, setSnackbar] = useState(null);
  useEffect(() => {
    setItems(refundObj.items);
  }, [refundObj]);
  const refundDropDownData = [
    {
      id: 1,
      name: Context.langData.item,
    },
    {
      id: 2,
      name: Context.langData.custom_amount,
    },
    {
      id: 3,
      name: Context.langData.entire_bill,
    },
  ];
  const handleChangeCustomerefund = (value) => {
    setRefundingAmountObj({ refundAmount: 0, refundTax: 0, total: 0 });
    let obj = { ...customerefundData };
    if (value == "0") {
      obj = { ...obj, percentage: 0, id: value };
    } else {
      obj = { ...obj, amount: 0, id: value };
    }
    setCustomrefundData(obj);
  };
  const findTotalAndTax = (val, tax) => {
    const final_per = 100 + tax;
    const total = Math.round(((val * 100) / final_per) * 100) / 100;
    const applied_tax = Math.round((val - total) * 100) / 100;
    return { total, applied_tax };
  };
  const handleChangeAmount = (val) => {
    if (!isNaN(val)) {
      var obj = { ...customerefundData, amount: val };
      setCustomrefundData(obj);

      const taxPercentage = (refundObj.total_tax * 100) / refundObj.total_price;
      const { total, applied_tax } = findTotalAndTax(val, taxPercentage);

      var obj = {
        refundTax: applied_tax,
        refundAmount: total,
        total: val,
      };
      setRefundingAmountObj(obj);
    }
  };
  const handleChangePercentage = (val) => {
    if (!isNaN(val)) {
      var obj = { ...customerefundData, percentage: removeLeadingZeros(val) };
      setCustomrefundData(obj);
      const percentageAmount = ConvertPrice(
        (refundObj.total_price * val) / 100
      );
      const taxAmount = ConvertPrice(
        (percentageAmount * refundObj.total_tax) / refundObj.total_price
      );
      var obj = {
        refundTax: taxAmount,
        refundAmount: percentageAmount,
        total: ConvertPrice(
          parseFloat(percentageAmount) + parseFloat(taxAmount)
        ),
      };
      setRefundingAmountObj(obj);
    }
  };
  const handleChangeCheckedList = (index) => {
    const itemList = [...items];
    const itemPrice =
      parseFloat(
        (itemList[index].final_price - (itemList[index].refunded_amount || 0)) *
          itemList[index].total_price
      ) /
      parseFloat(
        itemList[index].total_price + (itemList[index].total_tax || 0)
      );
    itemList[index] = {
      ...itemList[index],
      amount: itemList[index].isChecked ? 0 : ConvertPrice(itemPrice),
      isChecked: !itemList[index].isChecked,
    };
    setItems(itemList);
  };
  const handleChangePaymentMethod = (index) => {
    const itemList = [...items];
    itemList[index] = {
      ...itemList[index],
      isAmount: !itemList[index].isAmount,
      amount: 0,
      percentage: 0,
    };
    setItems(itemList);
  };
  const removeLeadingZeros = (val) => {
    if (val !== "" && !val.endsWith(".")) {
      const pval = parseFloat(val);
      if (pval == parseInt(val)) {
        return `${pval}`;
      }
      return val;
    }
    return val;
  };
  const handleChangeItemAmount = (index, value) => {
    if (!isNaN(value)) {
      const t = value;
      value =
        t.indexOf(".") >= 0
          ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)
          : t;
      const itemList = [...items];
      const itemTax = ConvertPrice(
        (value * itemList[index].total_tax) / itemList[index].total_price
      );
      if (
        parseFloat(itemTax) +
          parseFloat(value) +
          parseFloat(itemList[index].refunded_amount || 0) <=
        parseFloat(itemList[index].final_price)
      ) {
        itemList[index] = {
          ...itemList[index],
          amount: removeLeadingZeros(value),
          isError: false,
        };
      } else {
        itemList[index] = {
          ...itemList[index],
          amount: removeLeadingZeros(value),
          isError: true,
        };
      }
      setItems(itemList);
    }
  };
  useEffect(() => {
    let totalAmount = 0;
    let totalTax = 0;
    items?.map((itemObj) => {
      const itemTax = ConvertPrice(
        (itemObj.amount * itemObj.total_tax) / itemObj.total_price
      );
      totalAmount = parseFloat(totalAmount) + parseFloat(itemObj.amount);
      totalTax = parseFloat(totalTax) + parseFloat(itemTax);
    });
    const obj = {
      refundTax: ConvertPrice(totalTax),
      refundAmount: ConvertPrice(totalAmount),
      total: ConvertPrice(parseFloat(totalAmount) + parseFloat(totalTax)),
    };
    setRefundingAmountObj(obj);
  }, [items]);
  const handleChangeItempercentage = (index, value) => {
    if (!isNaN(value)) {
      const itemList = [...items];
      const itemPrice = ConvertPrice(
        (value * itemList[index].total_price) / 100
      );
      const itemTax = ConvertPrice(
        (itemPrice * itemList[index].total_tax) / itemList[index].total_price
      );
      if (
        parseFloat(itemTax) +
          parseFloat(itemPrice) +
          parseFloat(itemList[index].refunded_amount || 0) <=
        parseFloat(itemList[index].final_price)
      ) {
        itemList[index] = {
          ...itemList[index],
          amount: itemPrice,
          isError: false,
          percentage: removeLeadingZeros(value),
        };
      } else {
        itemList[index] = {
          ...itemList[index],
          amount: itemPrice,
          isError: true,
          percentage: removeLeadingZeros(value),
        };
      }
      setItems(itemList);
    }
  };
  const handleChangeRefundType = (value) => {
    if (value == 3) {
      if (refundObj?.refund?.length > 0) {
        setSnackbar({
          message: Context.langData.disable_dueto_partial_amount,
          severity: "error",
        });
      } else {
        var obj = {
          refundTax: refundObj.total_tax,
          refundAmount: refundObj.total_price,
          total: refundObj.final_price,
        };
        setName(value);
        setRefundingAmountObj(obj);
      }
    } else {
      setName(value);
      var obj = {
        refundTax: 0,
        refundAmount: 0,
        total: 0,
      };
      setRefundingAmountObj(obj);
    }
  };
  const conditinalrendring = (value) => {
    if (value == 1) {
      return (
        <Grid
          style={{
            display: "flex",
            marginTop: 40,
            flexDirection: "column",
            overflowX: "auto",
          }}>
          {items.length > 0
            ? items.map((item, index) => (
                <Grid style={{ display: "flex", flexDirection: "column" }}>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}>
                    <CustomCheckbox
                      data-qaid='refund_checkBox_item'
                      id={index}
                      checked={
                        item.isChecked ||
                        item.final_price <= item.refunded_amount
                      }
                      onChange={() => handleChangeCheckedList(index)}
                      inputProps={{ "aria-label": "primary checkbox" }}
                      marginLeft={0}
                      unCheckColor={
                        item.final_price <= item.refunded_amount
                          ? "#004C60"
                          : "#C3C3C3"
                      }
                      checkedColor={
                        item.final_price <= item.refunded_amount
                          ? "#707272"
                          : "#004C60"
                      }
                      disabled={item.final_price <= item.refunded_amount}
                      isDisabled={item.final_price <= item.refunded_amount}
                      label={`${item.itemName}`}
                      labelColor={
                        item.final_price <= item.refunded_amount
                          ? "#828e99"
                          : "#051D33"
                      }
                    />
                  </Grid>
                  {item.isChecked &&
                  !(item.final_price == item.refunded_amount) ? (
                    <div style={{ paddingLeft: 20 }}>
                      <RadioGroup
                        data-qaid='assignkitchen_kitchenRadiogroup'
                        row
                        aria-label='position'
                        name='position'
                        value={item.isAmount ? "0" : "1"}
                        onChange={() => handleChangePaymentMethod(index)}
                        style={{
                          flexDirection: "column",
                          display: "flex",
                          marginLeft: 15,
                        }}
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}>
                        <FormControlLabel
                          data-qaid='refund_amount_radio'
                          value='0'
                          control={
                            <Radio
                              classes={{
                                root: classes.radio,
                                checked: classes.checked,
                              }}
                            />
                          }
                          label={Context.langData.dollar_amount}
                        />
                        {item.isAmount ? (
                          <Grid
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: 8,
                            }}>
                            <H4
                              label={Context.langData.enter_amount}
                              style={{ marginBottom: 8, color: "#000000" }}
                            />
                            <InputText
                              value={`$${item.amount}`}
                              onChange={(val) =>
                                handleChangeItemAmount(
                                  index,
                                  val.target.value.replace("$", "")
                                )
                              }
                              onBlur={() => console.log("onBlur")}
                              error={
                                item.isError
                                  ? Context.langData.enter_valid_amount
                                  : null
                              }
                              id='name'
                            />
                          </Grid>
                        ) : null}
                        <FormControlLabel
                          data-qaid='refund_percentage_radio'
                          value='1'
                          control={
                            <Radio
                              classes={{
                                root: classes.radio,
                                checked: classes.checked,
                              }}
                            />
                          }
                          label={Context.langData.percentage}
                        />
                        {!item.isAmount ? (
                          <Grid
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: 8,
                            }}>
                            <H4
                              label={Context.langData.enter_percentage}
                              style={{ marginBottom: 8, color: "#000000" }}
                            />
                            <InputText
                              value={item.percentage}
                              onChange={(val) =>
                                handleChangeItempercentage(
                                  index,
                                  val.target.value
                                )
                              }
                              onBlur={() => console.log("onBlur")}
                              error={
                                item.isError
                                  ? Context.langData.enter_valid_percentage
                                  : null
                              }
                              id='name'
                            />
                          </Grid>
                        ) : null}
                      </RadioGroup>
                    </div>
                  ) : null}
                </Grid>
              ))
            : null}
        </Grid>
      );
    }
    if (value == 2) {
      return (
        <Grid
          style={{ marginTop: 20, display: "flex", flexDirection: "column" }}>
          {refundObj?.refund?.refund_type == "PartialRefundByAmount" ? (
            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Grid
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                }}>
                <Grid
                  style={{
                    display: "flex",
                    width: "50%",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}>
                  <T
                    label={Context.langData.order_total}
                    style={{ color: "#000000" }}
                  />
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    width: "50%",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}>
                  <T
                    label={`$${refundObj?.final_price}`}
                    style={{ color: "#000000" }}
                  />
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  marginTop: 8,
                }}>
                <Grid
                  style={{
                    display: "flex",
                    width: "50%",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}>
                  <T
                    label={Context.langData.refund_applied}
                    style={{ color: "#000000" }}
                  />
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    width: "50%",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}>
                  <T
                    label={`$${refundObj?.refund?.refund_amount}`}
                    style={{ color: "#000000" }}
                  />
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  marginTop: 8,
                }}>
                <Grid
                  style={{
                    display: "flex",
                    width: "50%",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}>
                  <AvenirBlackH3
                    label={Context.langData.remaining_balance}
                    style={{ color: "#000000" }}
                  />
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    width: "50%",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}>
                  <AvenirBlackH3
                    label={`$${ConvertPrice(refundObj?.final_price - (refundObj?.refund?.refund_amount || 0))}`}
                    style={{ color: "#000000" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Grid
            item
            xs={12}
            sm={12}
            lg={12}
            md={12}
            style={{ flexBasis: "auto", marginTop: 8 }}>
            <RadioGroup
              data-qaid='assignkitchen_kitchenRadiogroup'
              row
              aria-label='position'
              name='position'
              value={customerefundData.id}
              onChange={(val) => handleChangeCustomerefund(val.target.value)}
              style={{ flexDirection: "column", display: "flex" }}
              classes={{
                root: classes.radio,
                checked: classes.checked,
              }}>
              <FormControlLabel
                data-qaid='refund_amount_radio'
                value='0'
                control={
                  <Radio
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                  />
                }
                label={Context.langData.dollar_amount}
              />
              {customerefundData.id == 0 ? (
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 8,
                  }}>
                  <H4
                    label={Context.langData.enter_amount}
                    style={{ marginBottom: 8 }}
                  />
                  <InputText
                    value={
                      customerefundData.amount != 0
                        ? `$${customerefundData.amount}`
                        : ``
                    }
                    onBlur={() => console.log("onBlur")}
                    inputPlaceholder='$ 0'
                    onChange={(val) =>
                      handleChangeAmount(val.target.value.replace("$", ""))
                    }
                    error={
                      eval(refundingAmountObj.total) <=
                      eval(
                        parseFloat(refundObj.final_price) -
                          parseFloat(
                            refundObj?.refund?.reduce(
                              (a, b) => a + b.refund_amount,
                              0
                            ) || 0
                          )
                      )
                        ? null
                        : Context.langData.enter_valid_amount
                    }
                    id='name'
                  />
                </Grid>
              ) : null}
              <FormControlLabel
                data-qaid='refund_percentage_radio'
                value='1'
                control={
                  <Radio
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                  />
                }
                label={Context.langData.percentage}
              />
              {customerefundData.id == 1 ? (
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 8,
                  }}>
                  <H4
                    label={Context.langData.enter_percentage}
                    style={{ marginBottom: 8 }}
                  />
                  <InputText
                    value={customerefundData.percentage}
                    onChange={(val) => handleChangePercentage(val.target.value)}
                    onBlur={() => console.log("onBlur")}
                    error={
                      eval(refundingAmountObj.total) <=
                      eval(
                        parseFloat(refundObj.final_price) -
                          parseFloat(
                            refundObj?.refund?.reduce(
                              (a, b) => a + b.refund_amount,
                              0
                            ) || 0
                          )
                      )
                        ? null
                        : Context.langData.enter_valid_percentage
                    }
                    id='name'
                  />
                </Grid>
              ) : null}
            </RadioGroup>
          </Grid>
        </Grid>
      );
    }
    return (
      <AvenirBlackH3
        label={`${Context.langData.total_bill_refund_1}($${ConvertPrice(refundObj?.total_price - (refundObj?.refund?.refund_amount || 0))})${Context.langData.total_bill_refund_2}`}
        style={{ marginTop: 40, color: "#000000" }}
      />
    );
  };
  const handleContinue = () => {
    const obj = { ...refundObj };
    console.log("ON HANDLE CONTINUE", name, refundingAmountObj, obj);
    let isProceed = true;
    let itemsList = [];
    if (name == 1) {
      itemsList = items;
      console.log("ITEMS", items);
      if (items.length > 0) {
        items.map((itemObj) => {
          if (!itemObj.isError) {
          } else {
            isProceed = false;
          }
        });
        const selected = items.filter((e) => e.isChecked);
        if (selected.length == 0) {
          isProceed = false;
          setSnackbar({
            message: Context.langData.no_items_to_select_for_refund,
            severity: "error",
          });
        }
        console.log("selected", selected.length);
      } else {
        console.log("No items added");
      }
      console.log(isProceed);
    } else if (name == 2) {
      if (
        eval(refundingAmountObj.total) <=
        eval(
          refundObj?.final_price -
            (refundObj?.refund?.reduce((a, b) => a + b.refund_amount, 0) || 0)
        )
      ) {
      } else {
        isProceed = false;
      }
    }
    if (refundingAmountObj.total && refundingAmountObj.total != 0) {
    } else {
      isProceed = false;
    }
    if (
      refundingAmountObj.total <=
      parseFloat(refundObj.final_price) -
        parseFloat(
          refundObj?.refund?.reduce((a, b) => a + b.refund_amount, 0) || 0
        )
    ) {
    } else {
      isProceed = false;
      setSnackbar({
        message: Context.langData.refun_greater_than_bill,
        severity: "error",
      });
    }
    if (isProceed) {
      obj.refund_type =
        name == 1
          ? "PartialRefundByItem"
          : name == 2
            ? "PartialRefundByAmount"
            : "FullRefund";
      obj.items = itemsList;
      obj.refund_amount = refundingAmountObj.total;
      obj.refund_percentage = 0;

      callback(obj, { items, name });
    }
  };
  return (
    <RightDrawer data-qaid='createcomment_centermodal' open={open} padding={1}>
      <>
        <Grid
          style={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            width: 550,
          }}>
          <Grid
            container
            style={{
              display: "flex",
              height: "85%",
              overflowX: "auto",
              flexDirection: "column",
            }}>
            <Grid
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}>
              <Grid
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 20,
                  marginTop: 20,
                  height: "15%",
                }}>
                <Grid
                  data-qaid='createcategory_close_grid'
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "50%",
                    alignItems: "center",
                    paddingLeft: 49,
                  }}>
                  <BackIcon
                    data-qaid='createcategory_closeicon'
                    size={20}
                    onClick={() => onClose(false)}
                    style={{ marginRight: 40, cursor: "pointer" }}
                  />
                </Grid>
                <Grid
                  data-qaid='createcategory_close_grid'
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "50%",
                    alignItems: "center",
                  }}>
                  <CircleClose
                    data-qaid='createcategory_closeicon'
                    size={20}
                    onClick={() => onClose(true)}
                    style={{ marginRight: 40, cursor: "pointer" }}
                  />
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "85%",
                }}>
                <Grid
                  data-qaid='transactions_refund_main_grid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    paddingLeft: 49,
                    marginBottom: 20,
                    paddingRight: 49,
                  }}>
                  <H6
                    label={Context.langData.refund_order}
                    style={{ marginBottom: 32 }}
                  />
                  <Grid style={{ display: "flex", flexDirection: "column" }}>
                    <AvenirBlackH4
                      label={Context.langData.refund_by}
                      style={{ marginBottom: 8 }}
                    />
                    <SingleSelect
                      id='innerView'
                      data-qaid='transaction_items'
                      list={refundDropDownData}
                      value={name}
                      valueKey='id'
                      displayKey='name'
                      handleChange={(e) => {
                        handleChangeRefundType(e.target.value);
                      }}
                      border={false}
                      height={56}
                    />
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}>
                    {conditinalrendring(name)}
                  </Grid>
                  <Grid container style={{ height: 100 }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{
              display: "flex",
              height: "15%",
              flexDirection: "column",
              justifyContent: "flex-end",
              zIndex: 99,
            }}>
            <Grid
              data-qaid='transactions_refund_action_grid'
              container
              style={{ backgroundColor: "#FAFAFA" }}>
              <Grid
                data-qaid='transactions_refund_cancel_btn'
                item
                xs={6}
                lg={6}
                sm={6}
                md={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: 32,
                  marginBottom: 32,
                  paddingLeft: 49,
                  alignItems: "center",
                  flexDirection: "row",
                }}>
                <AvenirBlackH4
                  label={`${Context.langData.refund_total_amount}${ConvertPrice(refundingAmountObj.refundAmount)} + $${ConvertPrice(refundingAmountObj.refundTax)} `}
                  style={{ color: "#000000" }}
                />
                <AvenirBlackH5 label='tax' style={{ color: "#000000" }} />
              </Grid>
              <Grid
                data-qaid='transactions_refund_cancel_btn'
                item
                xs={6}
                lg={6}
                sm={6}
                md={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 32,
                  marginBottom: 32,
                }}>
                <Button
                  className={style.buttonClass}
                  data-qaid='transactions_refund_cancel_button'
                  onClick={() => {
                    onClose(true);
                  }}
                  variant='outlined'
                  style={{
                    backgroundColor: "#FFFFFF",
                    marginRight: 16,
                    border: "2px solid #707272",
                    width: 103,
                  }}>
                  <AvenirBlackH4
                    data-qaid='transactions_refund_cancel_text'
                    label={Context.langData.cancel}
                    style={{ color: "#004C60" }}
                  />
                </Button>
                <Button
                  className={style.buttonClass}
                  data-qaid='transactions_refund_save_btn'
                  variant='contained'
                  style={{ backgroundColor: "#FFB600", marginRight: 49 }}
                  onClick={handleContinue}>
                  <AvenirBlackH4
                    data-qaid='transactions_refund_save_text'
                    label={Context.langData.continue}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {snackbar && (
          <SnackBar
            data-qaid='transactions_refund_snackbar'
            open
            setOpen={() => setSnackbar(null)}
            severity={snackbar.severity}
            message={snackbar.message}
          />
        )}
      </>
    </RightDrawer>
  );
};

export default RefundModal;
