import React, { useCallback, useEffect, useState, useContext } from "react";
import { Paper, Grid, Button } from "@material-ui/core";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { fetchOrderTypeSales } from "../../../redux/actions";
import RefreshButton from "../../../components/RefreshButton";
import { H3, AvenirBlackH4 } from "../../../utils/text";
import DoughnutChart from "../../../components/DoughnutChart";
import MyContext from "../../../Provider/MyContext";
import styles from "../../../styles/index";

const OrderTypeChart = ({ classes, dates }) => {
  const style = styles();
  const dispatch = useDispatch();
  const orderTypeSales = useSelector((state) => state.dashboard.orderTypeSales);
  const [type, setType] = useState("sales");
  const [value, setValue] = React.useState(0);
  const Context = useContext(MyContext);
  const [buttonArray, setButtonArray] = useState([
    Context.langData.sales,
    Context.langData.transactions,
  ]);

  const handleChange = (item) => {
    setValue(item);
    if (item == 0) {
      setType("sales");
    } else {
      setType("transactions");
    }
  };
  useEffect(() => {
    if (dates) dispatch(fetchOrderTypeSales(dates));
  }, [dates]);
  const refresh = useCallback(() => {
    dispatch(fetchOrderTypeSales(dates));
  }, [dates]);
  console.log(orderTypeSales);
  return (
    <Paper
      data-qaid='orderTypechart_paper'
      className={clsx(
        classes.paper,
        classes.noSidePadding,
        classes.relative,
        classes.chart
      )}>
      <Grid
        data-qaid='orderTypechart_content_grid'
        container
        className={clsx(
          classes.paddingHorizontal,
          classes.padding,
          classes.relative
        )}
        justify='space-between'
        style={{ marginLeft: 24, paddingLeft: 0, paddingBottom: 0 }}>
        <Grid
          data-qaid='orderTypechart_title_grid'
          item
          sm={12}
          lg={8}
          md={8}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}>
          <H3
            data-qaid='orderTypechart_title'
            label={Context.langData.order_type}
          />
        </Grid>
        <RefreshButton
          data-qaid='orderTypechart_refresh_icon'
          refresh={refresh}
          className={classes.absolute}
        />
      </Grid>

      <Grid
        data-qaid='orderTypechart_button_grid'
        container
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: 10,
          paddingRight: 20,
        }}>
        <Grid
          data-qaid='orderTypechart_child_button'
          item
          sm={12}
          lg={12}
          md={12}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginLeft: 24,
          }}>
          {buttonArray.map((item, i) => (
            <Button
              className={style.buttonClass}
              data-qaid='orderTypechart_button'
              style={{
                backgroundColor: value == i ? "#051D33" : "#EEEEEE",
                marginRight: 1,
                borderRadius: 0,
                borderTopLeftRadius: i == 0 ? 5 : 0,
                borderBottomLeftRadius: i == 0 ? 5 : 0,
                borderTopRightRadius: buttonArray.length - 1 == i ? 5 : 0,
                borderBottomRightRadius: buttonArray.length - 1 == i ? 5 : 0,
              }}
              onClick={() => handleChange(i)}>
              <AvenirBlackH4
                data-qaid='orderTypechart_button_label'
                label={item}
                style={{
                  color: value == i ? "#FFFFFF" : "#051D33",
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 10,
                  paddingBottom: 10,
                  lineHeight: "24px",
                }}
              />
            </Button>
          ))}
        </Grid>
      </Grid>
      <DoughnutChart
        data-qaid='orderTypechart_doughtChart'
        data={orderTypeSales.data || []}
        valueKey={type}
        labelsKey='type'
      />
    </Paper>
  );
};

export default React.memo(OrderTypeChart);
