import * as React from "react";

const RightVector = ({ color = "#004C60", ...props }) => (
  <svg
    width={15}
    height={13}
    viewBox='0 0 15 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M1.91.006L.5 1.416l4.58 4.59-4.58 4.59 1.41 1.41 6-6-6-6z'
      fill={color}
    />
    <path
      d='M8.91.006L7.5 1.416l4.58 4.59-4.58 4.59 1.41 1.41 6-6-6-6z'
      fill={color}
    />
  </svg>
);

export default RightVector;
