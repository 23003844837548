import React, { useState, useEffect, useContext } from "react";
import { Grid, Button, MenuItem, Menu } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Daterange from "../../../components/DateRange";
import {
  AvenirBlackH4,
  AvenirBlackH2,
  AvenirBlackH7,
  TBold,
} from "../../../utils/text";
import MyContext from "../../../Provider/MyContext";
import CircleClose from "../../../components/SVG/CircleClose";
import DownlodeIcon from "../../../components/SVG/DownlodeIcon";
import SalesTable from "../../../components/SalesTable";
import { fetchPettyCashReports } from "../../../redux/actions";
import { downloadPettyCashReport } from "../../../utils/excel";
import { downloadPettyCashPdfDocument } from "../../../components/PDFTableGenerator";

const PettyCash = () => {
  const [table1, setTable1] = useState([]);
  const dispatch = useDispatch();
  const pettycashReports = useSelector(
    (state) => state.salesReportReducer.pettycashReports
  );
  const [date, setDate] = useState({
    startDate: moment().startOf("day").format("YYYY-MM-DD"),
    endDate: moment().endOf("day").format("YYYY-MM-DD"),
  });
  const Context = useContext(MyContext);
  const [openingBalance, setOpeningBalance] = useState(0);
  const [closingBalance, setClosingBalance] = useState(0);
  const [creditBalance, setCreditBalance] = useState(0);
  const [debitBalance, setDebitBalance] = useState(0);
  const [resetDate, toggleResetDate] = useState(false);
  const [pettyCashColumns, setPettyCashColumns] = useState([
    { field: "cat_name", display: Context.langData.cat_name, size: 3 },
    {
      field: "debit",
      display: Context.langData.debits,
      size: 3,
      isNumber: true,
    },
    {
      field: "credit",
      display: Context.langData.credits,
      size: 3,
      isNumber: true,
    },
    {
      field: "net_debit",
      display: Context.langData.net_debits,
      size: 3,
      isNumber: true,
    },
  ]);
  const onDateChange = (d) => {
    if (JSON.stringify(date) !== JSON.stringify(d)) {
      setDate(d);
    }
  };
  useEffect(() => {
    handleApplyDropdown();
  }, [date]);
  useEffect(() => {
    if (pettycashReports.total_txns) {
      const grouped = _.mapValues(
        _.groupBy(pettycashReports.total_txns, "cat_id"),
        (clist) => clist.map((car) => _.omit(car, "cat_id"))
      );
      const filterdPettyCashReport = [];
      Object.keys(grouped).forEach((key) => {
        filterdPettyCashReport.push(grouped[key]);
      });
      const array = [];
      filterdPettyCashReport.map((item) => {
        let credit = 0;
        let debit = 0;
        let obj = {};
        item.map((catItem) => {
          if (catItem.debited == 0) credit += catItem.amount;
          else debit += catItem.amount;
        });
        obj = {
          cat_name: item[0].cat_name,
          debit: debit.toFixed(2),
          credit: credit.toFixed(2),
          net_debit: Math.round(credit - debit).toFixed(2),
          credit_pre: credit,
          debit_pre: debit,
          net_debit_pre: Math.round(credit - debit),
        };
        array.push(obj);
      });
      setTable1(array);
    } else {
      setTable1([]);
    }
    if (pettycashReports?.opening_balance) {
      setOpeningBalance(pettycashReports.opening_balance);
    }
    if (pettycashReports?.closing_balance) {
      setClosingBalance(pettycashReports.closing_balance);
    }
    if (pettycashReports?.credit_amount) {
      setCreditBalance(pettycashReports.credit_amount);
    }
    if (pettycashReports?.debit_amount) {
      setDebitBalance(pettycashReports.debit_amount);
    }
  }, [pettycashReports]);

  const handleApplyDropdown = () => {
    const obj = {
      from_date: date.startDate,
      to_date: date.endDate,
    };
    dispatch(fetchPettyCashReports(obj));
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const downloadExcel = () => {
    downloadPettyCashReport(table1);
    handleCloseMenu();
  };
  const downloadPDF = () => {
    const dataToPDF = [{ data: table1, headers: pettyCashColumns }];
    downloadPettyCashPdfDocument(
      dataToPDF,
      Context.langData.petty_cash,
      date,
      "Petty Cash",
      pettycashReports
    );
    handleCloseMenu();
  };

  const onClear = () => {
    toggleResetDate(true);
    setTimeout(() => {
      toggleResetDate(false);
    }, 200);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const handleSort = (name, desc, index) => {
    const columnData = [...pettyCashColumns];
    columnData[index] = { ...columnData[index], desc };
    setPettyCashColumns(columnData);
    let sortItemwiseSales;
    if (name == "debit" || name == "credit" || name == "net_debit") {
      name += "_pre";
      sortItemwiseSales = _.orderBy(table1, [name], [desc ? "desc" : "asc"]);
    } else {
      sortItemwiseSales = _.orderBy(
        table1,
        [(item) => item[name].toLowerCase()],
        [desc ? "desc" : "asc"]
      );
    }
    setTable1(sortItemwiseSales);
  };
  return (
    <Grid
      data-qaid='pettyCash_mainContainer'
      container
      style={{ paddingLeft: 20, paddingRight: 20 }}>
      <Grid data-qaid='pettyCash_subContainer1' container>
        <Grid
          data-qaid='pettyCash_dateContainer'
          item
          lg={8}
          style={{
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
          }}>
          <Grid data-qaid='pettyCash_dateGrid' item style={{}}>
            <AvenirBlackH4
              data-qaid='pettyCash_dateLabel'
              label={Context.langData.from_to}
            />
            <Daterange
              data-qaid='pettyCash_dateRange'
              onDateChange={onDateChange}
              refresh={resetDate}
            />
          </Grid>
        </Grid>
        <Grid
          data-qaid='pettyCash_dateContainer'
          item
          lg={4}
          style={{
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 10,
          }}>
          <Grid
            data-qaid='pettyCash_dateGrid'
            onClick={onClear}
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: 20,
              alignItems: "center",
              cursor: "pointer",
            }}>
            <CircleClose data-qaid='pettyCash_closeIcon' />
            <AvenirBlackH4
              data-qaid='pettyCash_clearLabel'
              label={Context.langData.clear}
              style={{ marginLeft: 5 }}
            />
          </Grid>
          <Button
            onClick={(event) => setAnchorEl(event.currentTarget)}
            data-qaid='addonDetails_downloadButtonView'
            style={{
              backgroundColor: "#FFB600",
              height: 40,
              marginRight: 0,
              width: "149px",
            }}
            variant='contained'
            id='cancel'>
            <DownlodeIcon
              data-qaid='pettyCash_downlodeIcon'
              style={{ marginRight: 10 }}
            />
            <AvenirBlackH4
              data-qaid='pettyCash_downlodeLabel'
              label={Context.langData.download}
            />
          </Button>
          <Menu
            id='simple-menu-download'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}>
            <MenuItem onClick={downloadExcel}>
              {Context.langData.export_excel}
            </MenuItem>
            <MenuItem onClick={downloadPDF}>
              {Context.langData.export_pdf}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>

      {table1.length > 0 ? (
        <>
          <Grid
            data-qaid='pettyCash_subContainer2'
            container
            style={{ marginTop: 40, alignItems: "center", display: "flex" }}>
            <Grid
              data-qaid='pettyCash_openingBalanceContainer'
              item
              lg={6}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}>
              <Grid
                data-qaid='pettyCash_openingBalanceGrid'
                style={{
                  flexDirection: "row",
                  dispaly: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <AvenirBlackH2
                  data-qaid='pettyCash_openingBalanceLabel1'
                  label={`$${openingBalance.toFixed(2)}`}
                  style={{ fontWeight: "bold" }}
                />
                <AvenirBlackH4
                  data-qaid='pettyCash_openingBalanceLabel2'
                  label={Context.langData.opening_balance}
                  style={{ color: "#707272" }}
                />
              </Grid>
              <Grid
                data-qaid='pettyCash_creditBalanceGrid'
                style={{
                  flexDirection: "row",
                  dispaly: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingLeft: 30,
                }}>
                <AvenirBlackH2
                  data-qaid='pettyCash_creditBalanceLabel1'
                  label={`$${creditBalance.toFixed(2)}`}
                  style={{ fontWeight: "bold" }}
                />
                <AvenirBlackH4
                  data-qaid='pettyCash_creditBalanceLabel2'
                  label={Context.langData.credit_balance}
                  style={{ color: "#707272" }}
                />
              </Grid>
              <Grid
                data-qaid='pettyCash_debitBalanceGrid'
                style={{
                  flexDirection: "row",
                  dispaly: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingLeft: 30,
                }}>
                <AvenirBlackH2
                  data-qaid='pettyCash_debitBalanceLabel1'
                  label={`$${debitBalance.toFixed(2)}`}
                  style={{ fontWeight: "bold" }}
                />
                <AvenirBlackH4
                  data-qaid='pettyCash_debitBalanceLabel1'
                  label={Context.langData.debit_balance}
                  style={{ color: "#707272" }}
                />
              </Grid>
            </Grid>
            <Grid
              data-qaid='pettyCash_closingBalanceContainer'
              item
              lg={6}
              style={{ display: "flex", justifyContent: "flex-end" }}>
              <Grid
                data-qaid='pettyCash_closingBalanceGrid'
                style={{
                  flexDirection: "row",
                  dispaly: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <AvenirBlackH7
                  data-qaid='pettyCash_closingBalanceLabel1'
                  label={`$${closingBalance.toFixed(2)}`}
                  style={{ fontWeight: "bold" }}
                />
                <AvenirBlackH4
                  data-qaid='pettyCash_closingBalanceLabel2'
                  label={Context.langData.closing_balance}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            data-qaid='pettyCash_salesTableGrid'
            item
            lg={12}
            style={{ marginTop: 20 }}>
            <SalesTable
              data-qaid='pettyCash_salesTable'
              handleSort={(name, desc, colIndex) =>
                handleSort(name, desc, colIndex)
              }
              columns={pettyCashColumns}
              rows={table1}
            />
          </Grid>
        </>
      ) : (
        <Grid
          item
          lg={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            paddingTop: 20,
          }}>
          <TBold
            label={Context.langData.no_results_available}
            style={{ fontSize: 32, color: "#051D33", marginTop: 20 }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default PettyCash;
