import React, { useEffect, useState, useContext } from "react";
import { Grid, IconButton } from "@material-ui/core";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../styles";
import InputText from "../../../components/InputText";
import RotateLeft from "../../../components/SVG/RotateLeft";
import RotateRight from "../../../components/SVG/RotateRight";
import Color from "../../../components/Color";
import Message from "../../../components/Message";
import MyContext from "../../../Provider/MyContext";
import { H2, H4, H5, AvenirBlackH4 } from "../../../utils/text";
import Back from "../../../components/SVG/Back";
import { validateAlphaNumeric } from "../../../utils/util";
import { SvgIcon } from "./Icons";

const seats = [
  { seats: 1, key: "1 Seat" },
  { seats: 2, key: "2 Seats" },
  { seats: 3, key: "3 Seats" },
  { seats: 4, key: "4 Seats" },
  { seats: 6, key: "6 Seats" },
  { seats: 8, key: "8 Seats" },
];
const width = 25;
const height = 25;
const rotateStep = 5;
const types = [
  {
    id: "circle",
    icon: "circle",
    width: 40,
    height: 40,
    name: "Round",
    strokeWidth: 3,
    stroke: "#051d33",
  },
  {
    id: "square",
    icon: "rect",
    width: 30,
    height: 30,
    name: "Square",
    strokeWidth: 5,
    stroke: "#051d33",
  },
  {
    id: "rect",
    icon: "rect",
    width: 36,
    height: 19.5,
    name: "Rectangle",
    strokeWidth: 5,
    stroke: "#051d33",
  },
  {
    id: "barstool",
    icon: "circle",
    width: 32.5,
    height: 32.5,
    name: "Bar stool",
    strokeWidth: 3,
    stroke: "#051d33",
  },
];
const ItemSettings = () => {
  const style = styles({ padding: 10, margin: 5 });
  const style1 = styles({ padding: 30, margin: 5 });
  const [name, setName] = useState("");
  const [data, setData] = useState(null);
  const [temp, setTemp] = useState(null);
  const [msg, setMsg] = useState({ open: false, msg: "", severity: "success" });
  const dispatch = useDispatch();
  const workingData = useSelector((state) => state.floorplan.workingData);
  const selectedIndex = useSelector((state) => state.floorplan.selectedIndex);
  const Context = useContext(MyContext);
  useEffect(() => {
    if (selectedIndex !== -1) {
      const tempData = workingData[selectedIndex];
      setData(tempData);
    } else {
      setData(null);
    }
  }, [workingData, selectedIndex]);

  useEffect(() => {
    setTemp(workingData[selectedIndex]);
  }, [selectedIndex]);
  const onBack = () => {
    dispatch({ type: "SELECT_ITEM", index: -1 });
  };
  const onChangeValue = (key, value) => {
    let { width } = data;
    let { height } = data;
    if (key == "width") {
      width = value;
    } else if (key == "height") {
      height = value;
    } else if (key == "name") {
      if (temp.category == "Table") {
        if (value.length > 10) {
          value = value.substring(0, 10);
        }
      } else if (value.length > 30) {
        value = value.substring(0, 30);
      }
    } else if (key === "seats") {
      if (value !== "") {
        value = parseInt(value);
      }
    } else if (key == "content") {
      width = data.height;
      if (value == "rect") {
        width *= 2;
      }
    }
    if (selectedIndex != -1)
      dispatch({
        type: "UPDATE_ITEM",
        payload: { ...data, [key]: value, width, height },
        index: selectedIndex,
      });
    // setData({ ...data, [key]: value })
  };
  const onChangeProperties = (key, value) => {
    console.log(key, value);
    const min = 50;
    if (value) {
      const cat = ["Wall", "Plant"].indexOf(data.category) === -1;
      if ((key == "width" || key == "height") && cat) {
        if (value >= min) {
          onChangeValue(key, parseInt(value));
        } else {
          onChangeValue(key, min);
        }
      } else if (value >= 0) onChangeValue(key, parseInt(value));
    } else {
      onChangeValue(key, 0);
    }
  };
  const onRotateLeft = () => {
    const { rotate } = data;
    const to =
      rotate > rotateStep ? rotate - rotateStep : 360 + rotate - rotateStep;
    onChangeValue("rotate", to);
  };
  const onRotateRight = () => {
    const { rotate } = data;
    const to =
      rotate + rotateStep > 360
        ? rotate + rotateStep - 360
        : rotate + rotateStep;
    onChangeValue("rotate", to);
  };
  const save = () => {
    if (data.name.length > 0) {
      const s = workingData.filter((w, i) => {
        console.log(
          w.name,
          data.name,
          selectedIndex,
          i,
          w.name == data.name && selectedIndex !== i
        );
        return w.name == data.name && selectedIndex !== i;
      });
      console.log(s);
      if (s.length === 0) {
        setMsg({
          open: true,
          msg: data.name + Context.langData.saved_message,
          severity: "success",
        });
        setTemp(data);
        dispatch({ type: "UPDATE_ITEM", payload: data, index: selectedIndex });
        setTimeout(() => {
          setMsg({ open: false, msg: "", severity: "success" });
          dispatch({ type: "CANCEL" });
        }, 2000);
      } else {
        setMsg({
          open: true,
          msg: `Name ${data.name} already exists in list`,
          severity: "error",
        });
        setTimeout(() => {
          setMsg({ open: false, msg: "", severity: "success" });
        }, 2000);
      }
    } else {
      setMsg({ open: true, msg: "Please enter Name", severity: "error" });
      setTimeout(() => {
        setMsg({ open: false, msg: "", severity: "success" });
      }, 2000);
    }
  };
  const onCancel = () => {
    dispatch({ type: "UPDATE_ITEM", payload: temp, index: selectedIndex });
    setTimeout(() => {
      dispatch({ type: "CANCEL" });
    }, 1000);
  };
  if (!data) {
    return null;
  }

  console.log("temp.category", temp.category);
  return (
    <Grid
      container
      className={clsx(style.paddingHorizantal, style.paddingVertical)}
      style={{ position: "relative" }}>
      <Grid
        container
        style={{
          height: window.innerHeight - 155,
          overflow: "auto",
          padding: 20,
        }}>
        <Grid container>
          <span onClick={onBack}>
            <Back />
          </span>
        </Grid>
        <Grid
          container
          className={clsx(style.borderBottom)}
          style={{ height: 40, marginTop: 20 }}>
          <H2 label={temp.name} />
        </Grid>
        <Grid container spacing={1} style={{ height: 100, marginTop: 20 }}>
          <Grid item xs={12} md={12} lg={12}>
            <InputText
              placeholder={`${temp.category} name *`}
              value={data.name}
              maxLength={temp.category == "Table" ? 10 : 30}
              onChange={(val) =>
                onChangeValue("name", validateAlphaNumeric(val.target.value))
              }
            />
          </Grid>

          {/* <Grid item xs={12} md={4}>
              <InputText placeholder={temp.category + " Code"} value={data.code} handleChange={val => onChangeValue('code', val)} />
            </Grid> */}
        </Grid>

        {data.category == "Table" && (
          <>
            <Grid container style={{ height: 100 }}>
              <Grid container>
                <InputText
                  maxLength={4}
                  InputProps={{ min: 1, maxlength: 4, max: 9999 }}
                  min={1}
                  type='number'
                  placeholder='Seats'
                  value={data.seats}
                  onChange={(val) => onChangeValue("seats", val.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container style={{ height: 150 }}>
              <Grid item>
                <H4 label={Context.langData.table_style} />
                <Grid container>
                  {types.map((t, i) => (
                    <Grid
                      item
                      key={i}
                      style={{ paddingLeft: i == 0 ? 0 : 20, marginTop: 10 }}>
                      <Item
                        {...t}
                        onClick={() => onChangeValue("content", t.id)}
                        active={t.id == data.content}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        <Grid container style={{ height: 100, flexDirection: "column" }}>
          <AvenirBlackH4 label={`${Context.langData.color} (Hex value)`} />
          <Grid>
            <Color
              color={data.color}
              setColor={(e) => onChangeValue("color", e)}
            />
          </Grid>
        </Grid>
        {data.category == "Table" && data.content == "rect" && (
          <Grid container direction='column' style={{ height: 80 }}>
            <AvenirBlackH4 label={Context.langData.orientation} />
            <Grid
              container
              direction='row'
              justify='space-between'
              style={{ width: 100 }}>
              <IconButton onClick={onRotateLeft}>
                <RotateLeft />
              </IconButton>
              <IconButton onClick={onRotateRight}>
                <RotateRight />
              </IconButton>
            </Grid>
          </Grid>
        )}

        <Grid container spacing={2} style={{ height: 150, marginBottom: 50 }}>
          <Grid item xs={6}>
            <InputText
              type='number'
              min={1}
              value={data.width}
              onChange={(val) => onChangeProperties("width", val.target.value)}
              placeholder='Width'
            />
          </Grid>
          <Grid item xs={6}>
            <InputText
              type='number'
              min={1}
              value={data.height}
              onChange={(val) => onChangeProperties("height", val.target.value)}
              placeholder='Height'
            />
          </Grid>
          <Grid item xs={6}>
            <InputText
              type='number'
              min={0}
              value={data.x}
              onChange={(val) => onChangeProperties("x", val.target.value)}
              placeholder='Left'
            />
          </Grid>
          <Grid item xs={6}>
            <InputText
              type='number'
              min={0}
              value={data.y}
              onChange={(val) => onChangeProperties("y", val.target.value)}
              placeholder='Top'
            />
          </Grid>
        </Grid>
      </Grid>
      {/*  <Grid container justify="flex-end" alignItems="center" style={{ position: 'absolute', bottom: 0, right: 0, height: 60 }}>
        <Paper justify="flex-end" alignItems="center" style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button startIcon={<HighlightOffOutlinedIcon />} onClick={onCancel}>Cancel</Button>
          <Button startIcon={<CheckCircleOutlineOutlinedIcon />} onClick={save}>Save</Button>
        </Paper>

      </Grid> */}
      <Message
        open={msg.open}
        severity={msg.severity}
        msg={msg.msg}
        handleClose={() => console.log()}
      />
    </Grid>
  );
};

const Item = ({ active = false, onClick, ...props }) => (
  <>
    <div
      onClick={onClick}
      style={{
        width: 48,
        height: 48,
        backgroundColor: active ? "#004C60" : "#EEE",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 5,
      }}>
      <SvgIcon
        {...props}
        color='transparent'
        showName={false}
        stroke={active ? "#FFF" : "#051d33"}
      />
    </div>
    <H5 label={props.name} style={{ paddingTop: 10 }} />
  </>
);
export default ItemSettings;
