import React from "react";
import { Grid } from "@material-ui/core";
import { AvenirBlackH2, T } from "../../../utils/text";
import InfoOutlined from "../../../components/SVG/InfoOutlined";
import CancelButton from "../../../components/CancelButton";
import CustomButton from "../../../components/CustomButton";

const Confirm = ({ callback }) => (
  <Grid container style={{ maxWidth: 400, padding: 25, borderRadius: 5 }}>
    <Grid item xs={12}>
      <AvenirBlackH2 label='Are you sure you wish to leave without saving changes?' />
    </Grid>
    <Grid item xs={12} style={{ marginTop: 25 }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <InfoOutlined width={18} height={18} />
        <T
          label='Changes will be lost if you proceed without saving.'
          style={{ marginLeft: 5 }}
        />
      </div>
    </Grid>
    <Grid item xs={12}>
      <Grid
        container
        style={{ paddingLeft: 0, marginTop: 20 }}
        justify='flex-end'>
        <Grid item xs={6} style={{ padding: 10 }}>
          {/* <Button onClick={() => callback(false)} variant="contained" size="large" style={{ backgroundColor: '#00B6C9', width: '100%' }}>
              <Typography variant="h5">No</Typography>
            </Button> */}
          <CancelButton onClick={() => callback(false)} />
        </Grid>
        <Grid item xs={6}>
          <Grid container style={{ padding: 10 }}>
            {/* <Button onClick={() => callback(true)} variant="contained" size="large" style={{ backgroundColor: '#FFB600', width: '100%' }}>
                <Typography variant="h5">Yes</Typography>
              </Button> */}
            <CustomButton onClick={() => callback(true)} label='Confirm' />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);
export default Confirm;
