import React, { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import styles from "../../../../styles/index";
import InputText from "../../../../components/InputText";
import AlertDialog from "../../../../components/Dialog";
import {
  H4,
  AvenirBlackH4,
  H8,
  AvenirBlackLightH3,
} from "../../../../utils/text";
import DeleteIcon from "../../../../components/SVG/Delete";
import MyContext from "../../../../Provider/MyContext";
import CloseCircle from "../../../../components/SVG/CloseCircle";
import SettingsIcon from "../../../../components/SVG/Settings";
import RightDrawer from "../../../../components/RightDrawer";
import Message from "../../../../components/Message";
import { editTerminal, deleteTerminal } from "../../../../redux/actions";
import HelperText from "../../../../components/HelperText";

const EditTerminal = ({
  open,
  onClose,
  editCallback,
  row,
  deleteCallback,
  termialItems,
}) => {
  const style = styles();
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  const [msg, setMsg] = useState({ open: false, msg: "", severity: "success" });
  const [error, setError] = useState(null);
  const [terminalId, setTerminalId] = useState("");
  const [name, setName] = useState(null);
  const [nameCharCount, setNameCharCount] = useState(0);
  const [showDialog, setShowDialog] = useState(false);
  const heading = Context.langData.delete_terminal;
  const bodyMessage = Context.langData.delete_terminal_body;

  useEffect(() => {
    if (row) {
      setName(row.name);
      setNameCharCount(row.name.length);
      setError(!validName(row.name));
    }
  }, []);

  const handleName = (val) => {
    setName(val);
    setNameCharCount(val.length);
    setError(!validName(val));
  };

  const validName = (val) => val.length <= 30 && /^[a-zA-Z0-9 ]*$/.test(val);

  const onSubmit = () => {
    const obj = {
      moneris_terminal_id: row.moneris_terminal_id,
      name,
      device_id: row.device_id,
    };
    console.log("onSubmit", JSON.stringify(obj), JSON.stringify(row));
    dispatch(editTerminal(obj, editCallback));
  };

  const handleClose = (val) => {
    if (val) {
      dispatch(deleteTerminal({ device_id: row.device_id }, deleteCallback));
    }
    setShowDialog(false);
  };
  const handleDelete = () => {
    setShowDialog(true);
  };

  return (
    <RightDrawer
      data-qaid='assigntaxescentermodal'
      open={open}
      onClose={onClose}
      padding={1}>
      <>
        <Grid
          style={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            width: 600,
          }}>
          <Grid
            style={{
              display: "flex",
              height: "80%",
              flexDirection: "column",
              overflowX: "auto",
            }}>
            <Grid
              data-qaid='assigntaxesclose_grid'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}>
              <CloseCircle
                data-qaid='assigntaxescloseicon'
                size={20}
                onClick={onClose}
                style={{ marginRight: 40, marginTop: 20 }}
              />
            </Grid>
            <Grid
              data-qaid='assigntaxessettings_grid'
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 56,
                marginBottom: 10,
              }}>
              <SettingsIcon
                data-qaid='assigntaxessetting_icon'
                style={{ marginRight: 10 }}
                width={16}
                height={16}
                color='#707272'
              />
              <H4
                data-qaid='assigntaxessettings'
                label={Context.langData.settings}
                style={{ color: "#707272" }}
              />
            </Grid>
            <H8
              data-qaid='assignlitchen_title'
              style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
              label={Context.langData.terminal_details}
            />
            <Grid
              data-qaid='createtaxe_main_grid'
              style={{
                marginLeft: 56,
                marginRight: 56,
                maxWidth: 500,
                marginBottom: 20,
              }}>
              <Grid
                data-qaid='createtaxe_child_grid'
                style={{ display: "flex", flexDirection: "column" }}>
                <Grid
                  data-qaid='createtaxe_catgrid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}>
                  <AvenirBlackH4
                    data-qaid='createtaxe_catlabel'
                    label={Context.langData.terminal_ID}
                    style={{ marginBottom: 5 }}
                  />
                  <AvenirBlackH4
                    data-qaid='createtaxe_catlabel'
                    label={row.moneris_terminal_id}
                    style={{ marginBottom: 5, fontWeight: "bold" }}
                  />
                </Grid>
                <Grid
                  data-qaid='createtaxe_comment_grid'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    marginTop: 10,
                  }}>
                  <AvenirBlackH4
                    data-qaid='createtaxe_comlabel'
                    label={Context.langData.name_optional}
                    style={{ marginBottom: 5 }}
                  />
                  <InputText
                    data-qaid='createtaxe_comip'
                    value={name}
                    onChange={(val) => handleName(val.target.value)}
                    id='comment'
                  />
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <HelperText text='This name should be alphanumeric' />
                    <AvenirBlackLightH3
                      data-qaid='createtaxe_comlabel'
                      label={`${nameCharCount}/30 characters`}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{
              display: "flex",
              height: "20%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid
              data-qaid='createtaxe_action_grid'
              container
              style={{
                backgroundColor: "#FAFAFA",
                border: "1px solid #C3C3C3",
              }}>
              <Grid
                data-qaid='createtaxe_cancel_btn'
                item
                xs={12}
                lg={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 32,
                  marginBottom: 32,
                  alignItems: "center",
                }}>
                <Button
                  className={style.buttonClass}
                  data-qaid='shifts_delete_button'
                  style={{ marginRight: 10 }}
                  variant='text'
                  id='cancel'
                  onClick={handleDelete}>
                  <DeleteIcon
                    data-qaid='shifts_deleteicon'
                    width={16}
                    height={16}
                    style={{ marginRight: 5 }}
                  />
                  <AvenirBlackH4
                    data-qaid='shifts_delete_label'
                    label={Context.langData.delete}
                  />
                </Button>
                <Button
                  className={style.buttonClass}
                  onClick={onClose}
                  variant='contained'
                  style={{
                    backgroundColor: "#FFF",
                    marginRight: 16,
                    border: "2px solid #051D33",
                    height: 40,
                    width: 103,
                  }}>
                  <AvenirBlackH4
                    data-qaid='createtaxe_cancel_text'
                    label={Context.langData.cancel}
                  />
                </Button>
                <Button
                  className={style.buttonClass}
                  data-qaid='createtaxe_save_btn'
                  variant='contained'
                  disabled={error}
                  style={{
                    backgroundColor: error ? "#C3C3C3" : "#FFB600",
                    marginRight: 32,
                    height: 40,
                    width: 90,
                  }}
                  onClick={onSubmit}>
                  <AvenirBlackH4
                    data-qaid='createtaxe_save_text'
                    label={Context.langData.save}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <AlertDialog
            data-qaid='offersList_alertdialog'
            showDialog={showDialog}
            body={bodyMessage}
            heading={heading}
            IsError={false}
            handleClose={handleClose}
          />
          <Message
            open={msg.open}
            severity={msg.severity}
            msg={msg.msg}
            handleClose={() => console.log()}
          />
        </Grid>
      </>
    </RightDrawer>
  );
};

export default EditTerminal;
