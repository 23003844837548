class IdleTimer {
  constructor({ timeout, onTimeout, onExpired, store }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;
    this.store = store;
    this.timerKey = "_expiredTime";
    const expiredTime = parseInt(localStorage.getItem(this.timerKey), 10);
    if (expiredTime > 0 && expiredTime < Date.now()) {
      onExpired();
      return;
    }
    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();
    this.startInterval();
  }

  startInterval() {
    this.updateExpiredTime();
    this.interval = setInterval(() => {
      const expiredTime = parseInt(localStorage.getItem(this.timerKey), 10);
      // console.log("idel timer called", "expiredTime=>", expiredTime, "Date.now()=>", Date.now())
      if (expiredTime < Date.now()) {
        const { oauth_token } = this.store.getState().loginReducer.loginDetails;
        if (oauth_token) {
          if (this.onTimeout) {
            this.onTimeout();
            this.cleanUp();
          }
        }
      }
    }, 1000);
  }

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      // console.log("updateExpiredTime==>", Date.now() + this.timeout * 1000);
      localStorage.setItem(this.timerKey, Date.now() + this.timeout * 1000);
      // sessionStorage
    }, 300);
  }

  tracker() {
    window.addEventListener("mousemove", this.eventHandler);
    window.addEventListener("scroll", this.eventHandler);
    window.addEventListener("keydown", this.eventHandler);
  }

  cleanUp() {
    localStorage.removeItem(this.timerKey);
    clearInterval(this.interval);
    window.removeEventListener("mousemove", this.eventHandler);
    window.removeEventListener("scroll", this.eventHandler);
    window.removeEventListener("keydown", this.eventHandler);
  }
}

export class WarningTimer {
  constructor({ timeout, onTimeout, onExpired, store }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;
    this.store = store;
    this.timerKey = "_warningTime";
    this.eventHandler = this.updateExpiredTime.bind(this);
    const expiredTime = parseInt(localStorage.getItem(this.timerKey), 10);
    if (expiredTime > 0 && expiredTime < Date.now()) {
      onExpired();
      return;
    }
    this.tracker();
    this.startInterval();
  }

  startInterval() {
    this.updateExpiredTime();
    this.interval = setInterval(() => {
      const expiredTime = parseInt(localStorage.getItem(this.timerKey), 10);
      // console.log("idel timer called", "expiredTime=>", expiredTime, "Date.now()=>", Date.now())
      if (expiredTime < Date.now()) {
        const { oauth_token } = this.store.getState().loginReducer.loginDetails;
        if (oauth_token) {
          if (this.onTimeout) {
            this.onTimeout();
            this.cleanUp();
          }
        }
      }
    }, 1000);
  }

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      // console.log("updateExpiredTime==>", Date.now() + this.timeout * 1000);
      localStorage.setItem(this.timerKey, Date.now() + this.timeout * 1000);
      // sessionStorage
    }, 300);
  }

  tracker() {
    window.addEventListener("mousemove", this.eventHandler);
    window.addEventListener("scroll", this.eventHandler);
    window.addEventListener("keydown", this.eventHandler);
  }

  cleanUp() {
    localStorage.removeItem(this.timerKey);
    clearInterval(this.interval);
    window.removeEventListener("mousemove", this.eventHandler);
    window.removeEventListener("scroll", this.eventHandler);
    window.removeEventListener("keydown", this.eventHandler);
  }
}

export default IdleTimer;
