import * as React from "react";

const CheckBoxOn = (props) => (
  <svg
    width={24}
    height={24}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M0 5a5 5 0 015-5h14a5 5 0 015 5v14a5 5 0 01-5 5H5a5 5 0 01-5-5V5z'
      fill='#004C60'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19 1H5a4 4 0 00-4 4v14a4 4 0 004 4h14a4 4 0 004-4V5a4 4 0 00-4-4zM5 0a5 5 0 00-5 5v14a5 5 0 005 5h14a5 5 0 005-5V5a5 5 0 00-5-5H5z'
      fill='#004C60'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.922 4.873a.656.656 0 01.08.924l-9.8 11.667a.656.656 0 01-.99.017l-4.2-4.667a.656.656 0 11.976-.878l3.695 4.106 9.314-11.09a.656.656 0 01.925-.08z'
      fill='#fff'
    />
  </svg>
);

export default CheckBoxOn;
