import React, { useContext } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import {
  H4,
  AvenirBlackH3,
  T,
  AvenirBlackH2,
  AvenirBlackH4,
} from "../utils/text";
import MyContext from "../Provider/MyContext";
import CenterModal from "./CenterModal";

const useStyles = makeStyles({
  textStyles: {
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingRight: 5,
    "&:hover": {
      overflow: "visible",
      whiteSpace: "normal",
    },
  },
});

export default function UploadErrosTAble({
  open,
  data,
  onClose,
  title = "title",
  error = "error",
  labelName = "Name",
  proceed,
}) {
  const Context = useContext(MyContext);
  const classes = useStyles();
  return (
    <CenterModal
      open={open}
      onClose={onClose}
      style={{ width: 960, height: 550 }}>
      <Grid
        data-qaid='datatable_header_grid'
        style={{
          width: 960,
          height: 550,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}>
        <Grid
          style={{
            display: "flex",
            height: "85%",
            flexDirection: "column",
            width: "100%",
            overflow: "auto",
            paddingTop: 32,
            paddingLeft: 32,
            paddingRight: 32,
          }}>
          <AvenirBlackH2 label={title} style={{ marginBottom: 16 }} />
          <T label={error} style={{ color: "#000000", marginBottom: 24 }} />
          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
            }}>
            <Grid
              data-qaid='datatable_header_item_grid'
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                marginBottom: 10,
                width: "35%",
                paddingRight: 10,
                alignItems: "center",
              }}>
              <AvenirBlackH3
                data-qaid='datatable_header_label'
                label={labelName}
                style={{ paddingLeft: 40 }}
              />
            </Grid>
            <Grid
              data-qaid='datatable_header_item_grid'
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                marginBottom: 10,
                width: "65%",
                paddingRight: 10,
                alignItems: "center",
              }}>
              <AvenirBlackH3
                data-qaid='datatable_header_label'
                label='Reason'
              />
            </Grid>
          </Grid>
          <Grid
            style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {data.length > 0 &&
              data.map((row, index) => (
                <Grid
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: 56,
                    alignItems: "center",
                    marginBottom: 8,
                    backgroundColor: "#FAFAFA",
                    borderRadius: 5,
                  }}>
                  <Grid
                    data-qaid='datatable_header_item_grid'
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "35%",
                      alignItems: "center",
                    }}>
                    <H4
                      data-qaid='datatable_header_label'
                      label={row.name}
                      style={{ paddingLeft: 40 }}
                    />
                  </Grid>
                  <Grid
                    data-qaid='datatable_header_item_grid'
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "65%",
                      alignItems: "center",
                    }}>
                    <H4 data-qaid='datatable_header_label' label={row.errors} />
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "flex-end",
            height: "15%",
            width: "100%",
            backgroundColor: "#FAFAFA",
            borderTop: "1px solid #C3C3C3",
          }}>
          <Button
            data-qaid='items_creategrid'
            style={{
              backgroundColor: "#FFFFFF",
              width: 103,
              height: 40,
              marginRight: 32,
              borderRadius: 5,
              border: "2px solid #051D33",
            }}
            variant='outlined'
            onClick={onClose}>
            <AvenirBlackH4
              data-qaid='items_createlabel'
              label={Context.langData.cancel}
            />
          </Button>
          <Button
            data-qaid='items_creategrid'
            style={{
              backgroundColor: "#FFB600",
              width: 113,
              height: 40,
              borderRadius: 5,
              marginRight: 32,
            }}
            variant='contained'
            onClick={() => proceed()}>
            <AvenirBlackH4
              data-qaid='items_createlabel'
              label={Context.langData.proceed}
            />
          </Button>
        </Grid>
      </Grid>
    </CenterModal>
  );
}
