import React, { useEffect, useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Button, Grid } from "@material-ui/core";
import { H6, AvenirBlackH4 } from "../../utils/text";

import MyContext from "../../Provider/MyContext";
import styles from "../../styles/index";
import { fetchAllStocks } from "../../redux/actions";
import NoPermissionPage from "../../components/NoPermission";
import Vendors from "./vendors";
import Stores from "./store";
import Kitchens from "./kitchen";
import Receipes from "./recipe";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Inventory = () => {
  const style = styles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const userPermissions = useSelector(
    (state) => state.loginReducer.userPermissions
  );
  const [buttonArray, setButtonArray] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const inventorylist = [
    { key: "vendors", title: Context.langData.vendors, id: 0 },
    { key: "store_inventory", title: Context.langData.store, id: 1 },
    { key: "kitchen_inventory", title: Context.langData.kitchens, id: 2 },
    { key: "recipes", title: Context.langData.recipe, id: 3 },
  ];
  useEffect(() => {
    dispatch(fetchAllStocks());
  }, []);
  const isItemHasPermission = useMemo(() => {
    const buttonName = [];
    let buttonCount = 0;
    inventorylist.map((childItem) => {
      const obj = userPermissions.filter((e) => e.key == childItem.key);
      if (obj.length > 0) {
        var childObj = {};
        if (obj[0].status) {
          buttonCount += 1;
          childObj = { ...childItem, isPermission: true };
        } else {
          childObj = { ...childItem, isPermission: false };
        }
        // var childObj = { ...childItem, isPermission: true }
        buttonName.push(childObj);
      } else {
        var childObj = { ...childItem, isPermission: false };
        buttonName.push(childObj);
      }
    });
    if (buttonCount == 0) {
      setButtonArray([]);
    } else {
      setButtonArray(buttonName);
    }
    let result = true;
    if (buttonName.length > 0) {
      result = buttonName[selectedIndex].isPermission;
    } else {
      result = false;
    }
    return result;
  }, [userPermissions]);

  const handleSelectItem = (item) => {
    setSelectedIndex(item);
  };
  const renderSelectedView = () => {
    if (selectedIndex == 0) {
      if (isItemHasPermission) {
        return <Vendors data-qaid='inventory_vendors' />;
      }
      return (
        <NoPermissionPage data-qaid='transactions_purchases_nopermissions' />
      );
    }
    if (selectedIndex == 1) {
      if (isItemHasPermission) {
        return <Stores data-qaid='inventory_stores' />;
      }
      return (
        <NoPermissionPage data-qaid='transactions_purchases_nopermissions' />
      );
    }
    if (selectedIndex == 2) {
      if (isItemHasPermission) {
        return <Kitchens data-qaid='inventory_kitchens' />;
      }
      return (
        <NoPermissionPage data-qaid='transactions_purchases_nopermissions' />
      );
    }
    if (selectedIndex == 3) {
      if (isItemHasPermission) {
        return <Receipes data-qaid='inventory_receipes' />;
      }
      return (
        <NoPermissionPage data-qaid='transactions_purchases_nopermissions' />
      );
    }
  };
  return (
    <Grid
      data-qaid='inventory_index_grid'
      style={{ backgroundColor: "#FFFFFF", minHeight: "100%", width: "100%" }}>
      <Grid data-qaid='inventory_index_contentgrid' style={{ height: "20%" }}>
        <Grid
          data-qaid='inventory_index_titlegrid'
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginLeft: 36 }}>
          <H6
            data-qaid='inventory_index_title_label'
            label={Context.langData.inventory}
            style={{
              color: "#051D33",
              paddingTop: 36,
              fontWeigth: "800",
            }}
          />
        </Grid>
        <Grid
          data-qaid='inventory_index_bodygrid'
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginLeft: 36, marginTop: 36, flexDirextion: "row" }}>
          {buttonArray.map((item, i) => (
            <Button
              key={i}
              data-qaid='inventory_button'
              style={{
                backgroundColor:
                  selectedIndex == item.id ? "#051D33" : "#EEEEEE",
                marginRight: 1,
                borderRadius: 0,
                borderTopLeftRadius: i == 0 ? 4 : 0,
                borderBottomLeftRadius: i == 0 ? 4 : 0,
                borderTopRightRadius: buttonArray.length - 1 == i ? 4 : 0,
                borderBottomRightRadius: buttonArray.length - 1 == i ? 4 : 0,
              }}
              onClick={() => handleSelectItem(item.id)}>
              <AvenirBlackH4
                data-qaid='inventory_button_label'
                label={item.title}
                style={{
                  color: selectedIndex == item.id ? "#FFFFFF" : "#051D33",
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 10,
                  paddingBottom: 10,
                  lineHeight: "24px",
                }}
              />
            </Button>
          ))}
        </Grid>
        <Grid style={{ borderTop: "1px solid #EEE", marginTop: 20 }} />
      </Grid>
      <Grid data-qaid='inventory_body_view' style={{ height: "80%" }}>
        {renderSelectedView()}
      </Grid>
    </Grid>
  );
};

export default Inventory;
