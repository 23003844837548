import React from "react";

const ArrowRightIcon = (props) => (
  <svg
    width='17'
    height='9'
    viewBox='0 0 17 9'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.3391 0.456546C13.1689 0.269244 12.879 0.255441 12.6917 0.425715C12.5044 0.595989 12.4906 0.885861 12.6609 1.07316L15.2954 3.97119H1.50001C1.24687 3.97119 1.04167 4.17639 1.04167 4.42952C1.04167 4.68265 1.24687 4.88785 1.50001 4.88785H15.2991L12.6609 7.78988C12.4906 7.97718 12.5044 8.26705 12.6917 8.43733C12.879 8.6076 13.1689 8.5938 13.3391 8.4065L16.6725 4.73983C16.8314 4.56501 16.8314 4.29803 16.6725 4.12321L13.3391 0.456546Z'
      fill='#051D33'
      stroke='#051D33'
      strokeWidth='0.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default ArrowRightIcon;
