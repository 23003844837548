import instance from "./axiosInstance";
import urls from "./urls";

const fetchDashBoardSales = (params) => (dispatch, getState) => {
  params.rest_id = getState().loginReducer?.selectedRestaurant?.rest_id;
  instance.get(urls.dashboard.sales, { params }).then((response) => {
    if (response.status === 200 || response.status === 304)
      dispatch({ type: "SET_DASHBOARD_SALES", payload: response.data });
  });
};

const fetchRunningSales = () => (dispatch, getState) => {
  const params = {
    rest_id: getState().loginReducer?.selectedRestaurant?.rest_id,
  };
  instance.get(urls.dashboard.live, { params }).then((response) => {
    if (response.status === 200 || response.status === 304)
      dispatch({ type: "SET_LIVE_SALES", payload: response.data });
  });
};
const fetchTopSales =
  (params, type = 1) =>
  (dispatch, getState) => {
    params.rest_id = getState().loginReducer?.selectedRestaurant?.rest_id;
    params.number_of_items = 5;
    const url =
      type == 1 ? urls.dashboard.top_items : urls.dashboard.top_categories;
    instance.get(url, { params }).then((response) => {
      if (response.status === 200 || response.status === 304)
        dispatch({ type: "SET_TOP_SALES", payload: response.data });
    });
  };
const fetchOrderTypeSales = (params) => (dispatch, getState) => {
  params.rest_id = getState().loginReducer?.selectedRestaurant?.rest_id;
  instance.get(urls.dashboard.order_type, { params }).then((response) => {
    if (response.status === 200 || response.status === 304) {
      dispatch({ type: "SET_ORDER_TYPE_SALES", payload: response.data });
    }
  });
};
const fetchShiftsSales = (params) => (dispatch, getState) => {
  params.rest_id = getState().loginReducer?.selectedRestaurant?.rest_id;
  instance.get(urls.dashboard.shifts, { params }).then((response) => {
    if (response.status === 200 || response.status === 304) {
      dispatch({ type: "SET_SHIFTS_SALES", payload: response.data });
    }
  });
};
const fetchPaymentTypeSales = (params) => (dispatch, getState) => {
  params.rest_id = getState().loginReducer?.selectedRestaurant?.rest_id;
  instance.get(urls.dashboard.payment_type, { params }).then((response) => {
    if (response.status === 200 || response.status === 304) {
      dispatch({ type: "SET_PAYMENT_SALES", payload: response.data });
    }
  });
};
const fetchTracker = (params) => (dispatch, getState) => {
  params.rest_id = getState().loginReducer?.selectedRestaurant?.rest_id;
  instance.get(urls.dashboard.tracker, { params }).then((response) => {
    if (response.status === 200 || response.status === 304) {
      dispatch({ type: "SET_TRACKER_SALES", payload: response.data });
    }
  });
};
const postTrackerSettings = (params, callback) => (dispatch) => {
  instance.post(urls.dashboard.tracker, params).then((response) => {
    console.log(response);
    if (response.status === 200) {
      callback();
      // dispatch({ type: '' })
    }
  });
};
export {
  fetchDashBoardSales,
  fetchRunningSales,
  fetchTopSales,
  fetchOrderTypeSales,
  fetchShiftsSales,
  fetchPaymentTypeSales,
  fetchTracker,
  postTrackerSettings,
};
