import * as React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const AutoCompliteModifier = ({ options, onChange, labelKey }) => {
  const [value, setValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  console.log("OPTIONS", options, labelKey);
  const d = options.map((option) => option[labelKey]);
  console.log(".....", d);
  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        console.log(newValue);
        setValue(newValue);
        onChange(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        console.log(newInputValue);
        setInputValue(newInputValue);
      }}
      options={options}
      getOptionLabel={(option) => {
        console.log("option", option.addon_cat_name, options.length);
        return option.addon_cat_name || "";
      }}
      id='controllable-states-demo'
      style={{ width: "100%" }}
      sx={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label=''
          variant='outlined'
          style={{ width: "100%" }}
        />
      )}
    />
  );
};

export default React.memo(AutoCompliteModifier);
