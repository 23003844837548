import * as React from "react";

const DesktopIcon = ({ width = 24, height = 24, color = "#fff", ...props }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_13241:49169)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.24707 3C1.83286 3 1.49707 3.33579 1.49707 3.75V15.75C1.49707 16.1642 1.83286 16.5 2.24707 16.5H21.7471C22.1613 16.5 22.4971 16.1642 22.4971 15.75V3.75C22.4971 3.33579 22.1613 3 21.7471 3H2.24707ZM-0.00292969 3.75C-0.00292969 2.50736 1.00443 1.5 2.24707 1.5H21.7471C22.9897 1.5 23.9971 2.50736 23.9971 3.75V15.75C23.9971 16.9926 22.9897 18 21.7471 18H2.24707C1.00443 18 -0.00292969 16.9926 -0.00292969 15.75V3.75Z'
        fill='#004C60'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.25728 17.1267C8.31755 16.7651 8.63045 16.5 8.99708 16.5H14.9971C15.3637 16.5 15.6766 16.7651 15.7369 17.1267L16.4869 21.6267C16.5231 21.8442 16.4618 22.0666 16.3193 22.2348C16.1768 22.403 15.9675 22.5 15.7471 22.5H8.24708C8.02661 22.5 7.81731 22.403 7.67481 22.2348C7.53231 22.0666 7.47104 21.8442 7.50728 21.6267L8.25728 17.1267ZM9.63242 18L9.13242 21H14.8617L14.3617 18H9.63242Z'
        fill='#004C60'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.24707 21.75C5.24707 21.3358 5.58286 21 5.99707 21H17.9971C18.4113 21 18.7471 21.3358 18.7471 21.75C18.7471 22.1642 18.4113 22.5 17.9971 22.5H5.99707C5.58286 22.5 5.24707 22.1642 5.24707 21.75Z'
        fill='#004C60'
      />
    </g>
    <defs>
      <clipPath id='clip0_13241:49169'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default DesktopIcon;
