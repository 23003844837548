import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import styles from "../../../../styles/index";
import RightDrawer from "../../../../components/RightDrawer";
import InputText from "../../../../components/InputText";
import { createKitchen } from "../../../../redux/actions";
import MyContext from "../../../../Provider/MyContext";
import { H4, H2, AvenirBlackH4, H5 } from "../../../../utils/text";
import CloseCircle from "../../../../components/SVG/CloseCircle";
import SettingsIcon from "../../../../components/SVG/Settings";
import CustomCheckbox from "../../../../components/CustomCheckbox";
import { validateAlphaNumeric } from "../../../../utils/util";
import ChangeKitchenModal from "./ChangeKitchenModal";

const CreateKitchen = ({ open, callback, onClose, kitchen }) => {
  const Context = useContext(MyContext);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [nameError, setNameError] = useState("");
  const dispatch = useDispatch();
  const [check, setCheck] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const style = styles();

  const handleCheck = () => {
    const filterKitchen = kitchen.filter((e) => e.is_default == 1);
    if (filterKitchen.length > 0) {
      setOpenModal(true);
    } else {
      setCheck(!check);
    }
  };
  const callbackModal = (val) => {
    if (val) {
      setCheck(true);
    } else {
      setCheck(false);
    }
    setOpenModal(false);
  };

  const save = () => {
    let errorFlag = 0;
    if (name) {
    } else {
      errorFlag += 1;
      setNameError(Context.langData.no_text_error);
    }
    const elementsIndex = kitchen.findIndex(
      (indexElement) => indexElement.name.toUpperCase() == name.toUpperCase()
    );
    if (elementsIndex == -1) {
    } else {
      errorFlag += 1;
      setNameError(`${name} already exist`);
    }

    if (errorFlag == 0) {
      dispatch(
        createKitchen(
          {
            name,
            description,
            is_default: check,
          },
          callback
        )
      );
    } else {
    }
  };

  const handleName = (val) => {
    // const upper = val.charAt(0).toUpperCase() + val.slice(1);
    setName(validateAlphaNumeric(val));
  };

  return (
    <RightDrawer
      onClose={onClose}
      data-qaid='createkitchen_rightDrawer'
      open={open}
      padding={1}>
      <>
        <Grid
          style={{ display: "flex", height: "100%", flexDirection: "column" }}>
          <Grid
            style={{ display: "flex", height: "85%", flexDirection: "column" }}>
            <Grid
              data-qaid='createkitchen_close_grid'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 32,
                cursor: "pointer",
              }}>
              <CloseCircle
                data-qaid='createkitchen_closeicon'
                size={20}
                onClick={onClose}
                style={{ marginRight: 40 }}
              />
            </Grid>
            <Grid
              data-qaid='createkitchen_settings_grid'
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 48,
                marginBottom: 10,
              }}>
              <SettingsIcon
                data-qaid='createkitchen_setting_icon'
                style={{ marginRight: 10 }}
                width={16}
                height={16}
                color='#707272'
              />
              <H4
                data-qaid='createkitchen_settings'
                label={Context.langData.settings}
                style={{ color: "#707272" }}
              />
            </Grid>
            <H2
              data-qaid='createkitchen_title'
              style={{ marginLeft: 48, marginBottom: 24 }}
              label={Context.langData.create_kitchen_account}
            />
            <Grid
              data-qaid='createkitchen_grid1'
              style={{
                marginLeft: 48,
                marginRight: 48,
                height: "80%",
                width: 500,
              }}>
              <Grid
                data-qaid='createkitchen_namegrid'
                item
                xs={12}
                lg={12}
                sm={12}
                md={12}
                style={{ display: "flex", flexDirection: "column" }}>
                <AvenirBlackH4
                  data-qaid='createkitchen_namelabel'
                  label={Context.langData.kitchen_name}
                  style={{ marginBottom: 10 }}
                />
                <InputText
                  data-qaid='createkitchen_nameip'
                  value={name}
                  error={nameError}
                  onChange={(val) => handleName(val.target.value)}
                  id='name'
                  maxLength={64}
                  inputProps={{ maxLength: 30 }}
                />
                <Grid
                  style={{
                    justifyContent: "flex-end",
                    display: "flex",
                    padding: 5,
                  }}>
                  <H5
                    label={name.length + Context.langData._64_characters_count}
                  />
                </Grid>
                <CustomCheckbox
                  data-qaid='createRole_checkbox1'
                  id={1}
                  checked={check}
                  onChange={handleCheck}
                  marginLeft={-10}
                  inputProps={{ "aria-label": "primary checkbox" }}
                  label={Context.langData.set_as_default_kitchen}
                  labelColor='#051D33'
                />
              </Grid>
              <Grid
                data-qaid='createkitchen_username_grid'
                item
                xs={12}
                lg={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 10,
                }}>
                <AvenirBlackH4
                  data-qaid='createkitchen_usernamelabel'
                  label={Context.langData.description}
                  style={{ marginBottom: 10 }}
                />
                <InputText
                  data-qaid='createkitchen_description_Ip'
                  value={description}
                  onChange={(val) => setDescription(val.target.value)}
                  id='description'
                  maxLength={256}
                  inputProps={{ maxLength: 256 }}
                />
                <Grid
                  style={{
                    justifyContent: "flex-end",
                    display: "flex",
                    padding: 5,
                  }}>
                  <H5 label={`${description.length}/256`} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{
              display: "flex",
              height: "15%",
              flexDirection: "column",
              justifyContent: "flex-end",
              backgroundColor: "#FAFAFA",
              border: "1px solid #C3C3C3",
            }}>
            <Grid
              data-qaid='createkitchen_cancel_btn'
              item
              xs={12}
              lg={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 32,
                marginBottom: 32,
              }}>
              <Button
                className={style.buttonClass}
                onClick={onClose}
                variant='contained'
                style={{
                  backgroundColor: "#FFF",
                  marginRight: 16,
                  height: 40,
                  width: 103,
                  border: "2px solid #051D33",
                }}>
                <AvenirBlackH4
                  data-qaid='createkitchen_cancel_text'
                  label={Context.langData.cancel}
                />
              </Button>
              <Button
                className={style.buttonClass}
                data-qaid='createkitchen_save_btn'
                variant='contained'
                style={{
                  backgroundColor: "#FFB600",
                  marginRight: 32,
                  height: 40,
                  width: 90,
                }}
                onClick={save}>
                <AvenirBlackH4
                  data-qaid='createkitchen_save_text'
                  label={Context.langData.create}
                />
              </Button>
            </Grid>
          </Grid>
          {openModal && (
            <ChangeKitchenModal
              openModal={openModal}
              closeModal={() => setOpenModal(false)}
              callbackModal={callbackModal}
            />
          )}
        </Grid>
      </>
    </RightDrawer>
  );
};

export default CreateKitchen;
