import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { fetchTermial, fetchAllCategories } from "../../../../redux/actions";
import TerminalsList from "./terminalsList";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Terminals = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTermial());
    dispatch(fetchAllCategories());
  }, []);
  return (
    <div>
      <TerminalsList data-qaid='taxes-TerminalsList' />
    </div>
  );
};

export default Terminals;
