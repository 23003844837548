import React, { useState, useContext, useEffect } from "react";
import { Grid, Button, Tabs, makeStyles, Tab } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import MyContext from "../../../Provider/MyContext";
import InputText from "../../../components/InputText";
import RightDrawer from "../../../components/RightDrawer";
import { AvenirBlackH4, H4, DotText } from "../../../utils/text";
import InventoryIcon from "../../../components/SVG/InventoryIcon";
import CircleClose from "../../../components/SVG/CloseCircle";
import AutoCompliteDropDown from "../../../components/AutoComplete";
import { saveRecipe, fetchRecipeByRecipeId } from "../../../redux/actions";
import styles from "../../../styles/index";
import DeleteIcon from "../../../components/SVG/Delete";

const blockInvalidChar = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    "& $notchedOutline": {
      borderColor: "#707272",
    },
    "&:hover $notchedOutline": {
      borderColor: "#707272",
    },
    "&$focused $notchedOutline": {
      borderColor: "#707272",
    },
    "&.Mui-error": {
      borderColor: "#AB1212",
    },
  },
  focused: {},
  notchedOutline: {},
});
const RecipeFormula = ({ open, row, onClose, callback }) => {
  const style = styles();
  const classes = useStyles();
  const [searchInput, setSearchInput] = useState("");
  const [quantity, setQuantity] = useState("");
  const recipeAddons = useSelector((state) => state.inventory.recipeAddons);
  const getStocks = useSelector((state) => state.inventory.stocks);
  const [itemsList, setItemsList] = useState([]);
  const Context = useContext(MyContext);
  const [name, setName] = useState("");
  const [itemName, setItemName] = useState("");
  const [id, setId] = useState("");
  const [recipeList, setRecipeList] = useState([]);
  const [unitValue, setUnitValue] = useState(Context.langData.units);
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [servings, setServings] = useState([]);
  const [sendData, setSendData] = useState({});
  const recipeViewData = useSelector((state) => state.inventory.recipeViewData);

  useEffect(() => {
    if (row) {
      setId(row.item_id);
      setItemName(row.name);
      const obj = {
        item_id: row.item_id,
        name: row.name,
        recipes: row.serving,
      };
      if (obj.recipes && obj.recipes.length > 0) {
        const ids = obj.recipes.map((item) => item.recipe_id).join(",");
        const params = { ext_product_id: ids };
        dispatch(fetchRecipeByRecipeId(params));
      }

      RecipeItemCallback(obj);
    }
  }, []);
  useEffect(() => {
    if (recipeViewData) {
      console.log("recipeViewData", recipeViewData);
      // apend recipe details to sendData
      let sendDataObj = { ...sendData };
      const recipeArray = [];
      if (
        sendDataObj &&
        sendDataObj.recipes &&
        sendDataObj.recipes.length > 0
      ) {
        sendDataObj.recipes.map((recipe, index) => {
          // find detail recipe from recipeViewData
          let recipeItems = [];
          const idx = recipeViewData.findIndex(
            (e) => e.extProductId == recipe.recipe_id
          );
          if (idx != -1) {
            recipeItems = recipeViewData[idx].items;
          }
          const obj = {
            serving_id: recipe.serving_id,
            name: recipe.name,
            recipe_id: recipe.recipe_id,
            recipe: recipeItems,
          };
          recipeArray.push(obj);
        });

        sendDataObj = { ...sendDataObj, recipes: recipeArray };
        setSendData(sendDataObj);
      }
    }
  }, [recipeViewData]);

  useEffect(() => {
    if (sendData) {
      const sendDataObj = { ...sendData };
      console.log("sendDataObjsendDataObj", sendDataObj);
      if (
        sendDataObj &&
        sendDataObj.recipes &&
        sendDataObj.recipes.length > 0
      ) {
        const index = sendDataObj.recipes.findIndex(
          (e) => e.serving_id == value
        );
        if (index != -1 && sendDataObj.recipes[index].recipe) {
          setItemsList(sendDataObj.recipes[index].recipe);
        }
      }
    }
  }, [sendData, value]);

  const RecipeItemCallback = (response) => {
    setSendData(response);
    const array = [];
    if (response) {
      if (response.recipes && response.recipes.length > 0) {
        response.recipes.map((item, index) => {
          if (index == 0) {
            setValue(item.serving_id);
          }

          const obj = {
            serving: item.name,
            id: item.serving_id,
          };
          array.push(obj);
        });
      }
    }
    setServings(array);
  };
  const handleAddItem = () => {
    const sendDataObj = { ...sendData };
    console.log("handleAddItem testing", sendDataObj);
    if (name && quantity) {
      if (getStocks) {
        const stockObj = getStocks.filter((e) => e.name == name);
        if (stockObj.length > 0) {
          if (
            sendDataObj &&
            sendDataObj.recipes &&
            sendDataObj.recipes.length > 0
          ) {
            console.log(
              "sendDataObj1111",
              sendDataObj,
              "getStocksgetStocksgetStocks",
              getStocks,
              "namename",
              name,
              "stockObjstockObj",
              stockObj
            );
            const index = sendDataObj.recipes.findIndex(
              (e) => e.serving_id == value
            );
            if (index != -1) {
              console.log("indexindexindex", index);
              let recipesList = [];
              if (sendDataObj.recipes[index].hasOwnProperty("recipe")) {
                recipesList = [...sendDataObj.recipes[index].recipe];
              }
              if (recipesList && recipesList.length > 0) {
                const recipeIndex = recipesList.findIndex(
                  (e) => e.id == stockObj[0].id
                );
                if (recipeIndex == -1) {
                  var obj = {
                    quantity: Number(quantity),
                    id: stockObj[0].id,
                    name: stockObj[0].name,
                    unitTypeCode: stockObj[0].unitTypeCode,
                  };
                  recipesList.push(obj);
                } else {
                }
              } else {
                var obj = {
                  quantity: Number(quantity),
                  id: stockObj[0].id,
                  name: stockObj[0].name,
                  unitTypeCode: stockObj[0].unitTypeCode,
                };
                recipesList.push(obj);
              }
              setItemsList(recipesList);
              setQuantity("");
              sendDataObj.recipes[index] = {
                ...sendDataObj.recipes[index],
                recipe: recipesList,
              };
            } else {
              console.log("false", index);
            }
          } else {
            console.log("false");
          }
        }
      }
    } else {
      console.log("sendDataObjsendDataObj111111", name, quantity);
    }
    setSendData(sendDataObj);
  };
  const handleDelete = () => {};
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSelectStock = (value) => {
    setName(value);
    if (getStocks) {
      const stockObj = getStocks.filter((e) => e.name == value);
      if (stockObj.length > 0) {
        setUnitValue(stockObj[0].unitTypeCode);
      }
    }
  };
  const handleSave = (value) => {
    dispatch(saveRecipe(sendData, callback));
  };
  const handleRemoveStock = (recipeIndex) => {
    const sendDataObj = { ...sendData };
    if (sendDataObj && sendDataObj.recipes) {
      const index = sendDataObj.recipes.findIndex((e) => e.serving_id == value);
      if (index != -1) {
        const recipesList = [...sendDataObj.recipes[index].recipe];
        if (recipesList && recipesList.length > 0) {
          recipesList.splice(recipeIndex, 1);
        } else {
        }
        setItemsList(recipesList);
        setQuantity("");
        sendDataObj.recipes[index] = {
          ...sendDataObj.recipes[index],
          recipe: recipesList,
        };
      } else {
      }
    }
    setSendData(sendDataObj);
  };
  const handleClearStockes = () => {
    const sendDataObj = { ...sendData };
    if (sendDataObj && sendDataObj.recipes) {
      const index = sendDataObj.recipes.findIndex((e) => e.serving_id == value);
      if (index != -1) {
        setItemsList([]);
        setQuantity("");
        sendDataObj.recipes[index] = {
          ...sendDataObj.recipes[index],
          recipe: [],
        };
      } else {
      }
    }
  };
  return (
    <RightDrawer data-qaid='createStock_right_drawer' open={open} padding={1}>
      <>
        <Grid
          data-qaid='createStock_parent_grid'
          style={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            maxWidth: 600,
          }}>
          <Grid
            data-qaid='createStock_body_grid'
            style={{
              display: "flex",
              height: "85%",
              flexDirection: "column",
              overflowX: "auto",
            }}>
            <Grid
              data-qaid='createStock_close_grid'
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}>
              <CircleClose
                data-qaid='createStock_closeicon'
                size={20}
                onClick={onClose}
                style={{ marginRight: 40, marginTop: 20 }}
              />
            </Grid>
            <Grid
              data-qaid='createStock_inventory_grid'
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 56,
                marginBottom: 10,
              }}>
              <InventoryIcon
                data-qaid='createStock_inventory_icon'
                style={{ marginRight: 3 }}
                width={22.5}
                height={24}
                color='#707272'
              />
              <H4
                data-qaid='createStock_inventory'
                label={Context.langData.inventory}
                style={{ color: "#707272" }}
              />
            </Grid>
            <DotText
              data-qaid='createStock_title'
              style={{
                marginLeft: 56,
                marginBottom: 20,
                marginRight: 56,
                fontSize: 28,
                fontFamily: "AvenirLT-Black",
              }}
              label={itemName}
            />
            <Grid
              data-qaid='createStock_main_grid'
              style={{
                marginLeft: 56,
                marginBottom: 20,
                marginRight: 56,
                width: 500,
                display: "flex",
                flexDirection: "column",
              }}>
              {servings && servings.length > 1 ? (
                <Tabs
                  style={{ borderBottom: "1px solid #EEEEEE" }}
                  variant='scrollable'
                  value={value}
                  indicatorColor='primary'
                  textColor='primary'
                  onChange={handleTabChange}>
                  {servings && servings.length > 0
                    ? servings.map((item, index) => (
                        <Tab
                          key={index}
                          label={<AvenirBlackH4 label={item.serving} />}
                          value={item.id}
                        />
                      ))
                    : null}
                </Tabs>
              ) : null}
            </Grid>

            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                paddingLeft: 56,
                paddingRight: 56,
              }}>
              <Grid
                data-qaid='recipeFormula_searchLabelView'
                style={{
                  alignItems: "center",
                  width: "35%",
                  marginRight: "2%",
                }}>
                <AutoCompliteDropDown
                  options={getStocks}
                  onChange={handleSelectStock}
                  labelKey='name'
                  key={itemsList}
                  placeholder={Context.langData.serch_stock_items}
                />
              </Grid>
              <Grid
                data-qaid='recipeFormula_quantityLabelView'
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "35%",
                  marginRight: "5%",
                  marginTop: 7,
                }}>
                <InputText
                  id='quantity'
                  height={60}
                  style={{ marginBottom: 6 }}
                  isPlaceHolder={false}
                  placeholder={Context.langData.quatity}
                  value={quantity}
                  onChange={(e) => {
                    setQuantity(e.target.value);
                  }}
                  size='small'
                  onKeyDown={blockInvalidChar}
                  type='number'
                />
              </Grid>
              <Grid
                data-qaid='recipeFormula_unitsLabelView'
                item
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  display: "flex",
                  width: "20%",
                  marginRight: "5%",
                }}>
                <AvenirBlackH4
                  data-qaid='recipeFormula_unitsLabel1'
                  label={unitValue}
                  style={{ marginRight: 20 }}
                />
                <Button
                  className={style.buttonClass}
                  data-qaid='recipeFormula_saveLabelView'
                  style={{
                    backgroundColor: "#FFB600",
                    color: "white",
                    marginRight: 10,
                    width: "60px",
                  }}
                  variant='contained'
                  id='cancel'
                  type='submit'
                  onClick={() => {
                    handleAddItem();
                  }}>
                  <AvenirBlackH4
                    data-qaid='recipeFormula_saveLabel'
                    label={Context.langData.add}
                  />
                </Button>
              </Grid>
            </Grid>
            <Grid
              data-qaid='createStock_itemListGrid'
              item
              style={{
                marginTop: "20px",
                marginLeft: 56,
                display: "flex",
                flexDirection: "column",
              }}>
              {itemsList
                ? itemsList.map((itemName, index) => (
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}>
                      <Grid
                        key={itemName.name}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          border: "1px solid #C3C3C3",
                          borderRadius: 5,
                          marginBottom: 10,
                          width: "65%",
                          height: 56,
                        }}>
                        <DotText
                          data-qaid='recipeFormula_unitsLabel2'
                          label={itemName.name}
                          style={{
                            cursor: "pointer",
                            marginRight: 10,
                            marginLeft: 10,
                            width: "70%",
                          }}
                        />
                        <AvenirBlackH4
                          data-qaid='recipeFormula_unitsLabel2'
                          label={`${itemName.quantity} ${itemName.unitTypeCode}`}
                          style={{
                            cursor: "pointer",
                            width: "30%",
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingRight: 10,
                          }}
                        />
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginLeft: 10,
                          alignItems: "center",
                        }}>
                        <Button
                          className={style.buttonClass}
                          data-qaid='recipeFormula_clearButton'
                          style={{
                            backgroundColor: "#FFFFFF",
                            color: "white",
                            marginRight: 10,
                            width: "10%",
                          }}
                          variant='text'
                          id='cancel'
                          onClick={() => handleRemoveStock(index)}>
                          <DeleteIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  ))
                : null}
            </Grid>
          </Grid>
          <Grid
            style={{
              display: "flex",
              height: "15%",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}>
            <Grid
              data-qaid='recipeFormula_colourLabelView'
              item
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#FAFAFA",
                width: "100%",
                paddingTop: "20px",
                alignItems: "center",
                paddingBottom: "20px",
                border: "1px solid #C3C3C3",
                paddingLeft: 20,
                marginTop: "10%",
                paddingRight: 20,
              }}>
              <Button
                className={style.buttonClass}
                data-qaid='recipeFormula_colourButton'
                style={{ color: "white", marginRight: 10, width: "150px" }}
                variant='text'
                id='cancel'
                onClick={() => handleClearStockes()}>
                <AvenirBlackH4
                  data-qaid='recipeFormula_colourLabel'
                  label={Context.langData.clear_recipe}
                />
              </Button>
              <Grid>
                <Button
                  className={style.buttonClass}
                  data-qaid='recipeFormula_cancelLabelView'
                  style={{
                    backgroundColor: "#FFF",
                    marginRight: 10,
                    width: 103,
                    height: 40,
                    border: "2px solid #051D33",
                  }}
                  variant='contained'
                  id='cancel'
                  onClick={onClose}>
                  <AvenirBlackH4
                    data-qaid='recipeFormula_cancelLabel'
                    label={Context.langData.cancel}
                  />
                </Button>
                <Button
                  className={style.buttonClass}
                  data-qaid='recipeFormula_saveLabelView'
                  style={{
                    backgroundColor: "#FFB600",
                    marginRight: 10,
                    width: 90,
                    height: 40,
                  }}
                  variant='contained'
                  id='cancel'
                  type='submit'
                  onClick={() => {
                    handleSave();
                  }}>
                  <AvenirBlackH4
                    data-qaid='recipeFormula_saveLabel'
                    label={Context.langData.save}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    </RightDrawer>
  );
};

export default RecipeFormula;
