import React from "react";
import { Grid } from "@material-ui/core";
import { T } from "../utils/text";

const CancelButton = ({ onClick }) => (
  <Grid
    container
    justify='center'
    alignItems='center'
    style={{
      backgroundColor: "white",
      border: "2px solid #707272",
      padding: "10px 0px",
      borderRadius: 5,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    }}
    onClick={onClick}>
    <T label='Cancel' style={{ color: "#004C60" }} />
  </Grid>
);

export default CancelButton;
