import * as React from "react";

const Report = React.memo(
  ({ width = 24, height = 24, color = "#fff", ...props }) => (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox='0 0 18 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.125 1.25a.625.625 0 00-.625.625v16.25a.625.625 0 00.625.625h13.75a.624.624 0 00.625-.625V6.768a.625.625 0 00-.183-.442l-4.893-4.893a.625.625 0 00-.441-.183H2.124zM.799.55C1.151.197 1.628 0 2.125 0h8.857c.497 0 .974.197 1.326.549L17.2 5.442c.351.352.549.828.549 1.325v11.358A1.875 1.875 0 0115.875 20H2.125A1.875 1.875 0 01.25 18.125V1.875C.25 1.378.448.901.8.549z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.875 0c.345 0 .625.28.625.625v5a.625.625 0 00.625.625h5a.625.625 0 110 1.25h-5a1.875 1.875 0 01-1.875-1.875v-5c0-.345.28-.625.625-.625zM6.316 5.974a1.226 1.226 0 00-.598-.115c-.352 0-.647.108-.84.253-.192.144-.253.296-.253.411 0 .116.061.268.254.413.193.144.488.252.84.252.596 0 1.16.18 1.588.503.43.322.752.815.752 1.41 0 .598-.324 1.09-.754 1.412-.426.32-.985.5-1.578.503a2.477 2.477 0 01-2.058-.976.625.625 0 01.995-.757 1.228 1.228 0 001.051.483c.352 0 .648-.108.84-.253.193-.145.254-.297.254-.411 0-.116-.06-.268-.252-.412a1.409 1.409 0 00-.839-.252c-.596 0-1.16-.18-1.59-.503-.429-.322-.753-.815-.753-1.412s.324-1.09.754-1.411c.426-.32.985-.5 1.577-.503a2.476 2.476 0 012.058.976.625.625 0 01-.995.757 1.226 1.226 0 00-.453-.368z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.718 9.766c.346 0 .625.28.625.625v.859a.625.625 0 11-1.25 0v-.86c0-.344.28-.624.625-.624zM5.718 3.75c.346 0 .625.28.625.625v.86a.625.625 0 01-1.25 0v-.86c0-.345.28-.625.625-.625zM8.375 12.5c0-.345.28-.625.625-.625h5a.625.625 0 110 1.25H9a.625.625 0 01-.625-.625zM4 16.25c0-.345.28-.625.625-.625H14a.625.625 0 110 1.25H4.625A.625.625 0 014 16.25z'
        fill={color}
      />
    </svg>
  )
);

export default Report;
