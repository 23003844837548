import moment from "moment";
import * as Excel from "exceljs";
import {
  getWookBook,
  downloadAs,
  setHeaderStyle,
  setNormalRowStyle,
} from "./workbook";

const customerColumns = [
  { id: "first_name", label: "First name", width: 50 },
  { id: "last_name", label: "Last name", width: 50 },
  { id: "email", label: "Email", width: 50 },
  { id: "phone_number", label: "Phone number", width: 20 },
  { id: "ts", label: "Registered date", width: 20 },
  { id: "source_channel", label: "Channel", width: 20 },
  { id: "assigned_customer_id", label: "Assigned customer id", width: 30 },
  { id: "birthday", label: "Birthday", width: 20 },
  { id: "street_address", label: "Address", width: 50 },
  { id: "unit_number", label: "Unit number", width: 20 },
  { id: "city", label: "City", width: 20 },
  { id: "province", label: "Province", width: 20 },
  { id: "postal_code", label: "Postal code", width: 20 },
  { id: "language", label: "Language", width: 20 },
  { id: "notes", label: "Notes", width: 60 },
];
const employeeColumns = [
  { id: "first_name", label: "First name", width: 50 },
  { id: "last_name", label: "Last name", width: 50 },
  { id: "email", label: "Email", width: 50 },
  { id: "phone_number", label: "Phone number", width: 20 },
  { id: "contact_email", label: "Contact email", width: 50 },
  { id: "status", label: "Status", width: 20 },
  { id: "username", label: "Username", width: 20 },
  { id: "name", label: "Role", width: 20 },
  { id: "assigned_employee_id", label: "Assigned employee id", width: 30 },
  { id: "ts", label: "Registered date", width: 20 },
  { id: "birthday", label: "Birthday", width: 20 },
  { id: "street_address", label: "Address", width: 50 },
  { id: "unit_number", label: "Unit number", width: 20 },
  { id: "city", label: "City", width: 20 },
  { id: "province", label: "Province", width: 20 },
  { id: "postal_code", label: "Postal code", width: 20 },
  { id: "language", label: "Language", width: 20 },
  { id: "notes", label: "Notes", width: 60 },
];
const downloadCustomers = (data) => {
  const workbook = getWookBook();
  const ws = workbook.addWorksheet("Customers");
  const labels = customerColumns.map((c) => c.label);
  ws.addRow(labels);
  setHeaderStyle(ws, 1);
  customerColumns.map((c, i) => {
    ws.getColumn(i + 1).width = c.width;
    if (c.hidden) {
      ws.getColumn(i + 1).hidden = true;
    }
  });

  data.map((customer, i) => {
    const rowvals = customerColumns.map((c) => customer[c.id]);
    ws.addRow(rowvals);
    setNormalRowStyle(ws, i + 2);
  });
  downloadAs(workbook, `customers_${moment().format("yyyyMMDDHHmmss")}.xlsx`);
};
const downloadEmployees = (data) => {
  console.log("data====>", data);
  const workbook = getWookBook();
  const ws = workbook.addWorksheet("Employees");
  const labels = employeeColumns.map((e) => e.label);
  ws.addRow(labels);
  setHeaderStyle(ws, 1);
  employeeColumns.map((e, i) => {
    ws.getColumn(i + 1).width = e.width;
    if (e.hidden) {
      ws.getColumn(i + 1).hidden = true;
    }
  });
  data.map((employee, i) => {
    const rowvals = employeeColumns.map((e) => employee[e.id]);
    ws.addRow(rowvals);
    setNormalRowStyle(ws, i + 2);
  });
  downloadAs(workbook, `employees_${moment().format("yyyyMMDDHHmmss")}.xlsx`);
};
const uploadCustomers = async (data) => {
  const workbook = new Excel.Workbook();
  await workbook.xlsx.load(data);
  const ws = workbook.worksheets[0];
  // console.log("worksheet", ws)
  const customers = [];
  ws.eachRow((row, rowNumber) => {
    // console.log("row",row)
    if (rowNumber !== 1 && rowNumber < 12) customers.push(getRowValues(row));
  });
  return customers;
};
const getRowValues = (row) => {
  const obj = {};
  const vals = customerColumns.map((c, i) => {
    if (c.id !== "ts") {
      obj[c.id] = row.getCell(i + 1).value;
      if (c.id == "phone_number") {
        console.log(
          "value value---->",
          String(row.getCell(i + 1).value),
          "phoneIndex---phoneIndex"
        );
        const phoneIndex = String(row.getCell(i + 1).value).indexOf("-");
        if (phoneIndex !== -1) {
          obj[c.id] = row.getCell(i + 1).value?.replace(/\-/g, "");
        }
      }
    }

    return null;
  });
  return obj;
  // return {
  //   first_name: row.getCell(1).value,
  //   last_name: row.getCell(1).value,
  //   email: row.getCell(2).value,
  //   phone_number: row.getCell(3).value,
  //   resigration_on: row.getCell(4).value
  // }
};
export { downloadCustomers, downloadEmployees, uploadCustomers };
