import React, { useState, useEffect, useContext, useCallback } from "react";
import { Paper, Grid, Button } from "@material-ui/core";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../styles";
import CreateOutlinedIcon from "../../../components/SVG/Edit";
import CopyIcon from "../../../components/SVG/Copy";
import EditIcon from "../../../components/SVG/Edit";
import Message from "../../../components/Message";
import SingleSelect from "../../../components/SingleSelect";
import MyContext from "../../../Provider/MyContext";
import CenterModal from "../../../components/CenterModal";
import DeleteIcon from "../../../components/SVG/Delete";
import InfoOutlined from "../../../components/SVG/InfoOutlined";
import {
  saveFloorPlanBuilder,
  getFloorPlanBuilder,
} from "../../../redux/actions";
import { H2, H4, H5, T } from "../../../utils/text";
import FloorView from "../../../components/SVG/FloorView";
import ContextMenu from "../../../components/FloorplanContextMenu";
import Confirm from "./ConfirmUnsave";
import RenameSection from "./renameSection";
import CreateSection from "./createSection";

const ItemsList = ({ data, saveOnEdit }) => {
  const style = styles({ padding: 10, margin: 5 });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElFloor, setAnchorElFloor] = React.useState(null);
  const [tablesData, setTablesData] = useState({ tables: 0, seats: 0 });
  const [current, setCurrent] = useState(-1);
  const [showDeleteSetcion, setShowDeleteSection] = useState(false);
  const [withChangeCreate, toggleWithChange] = useState(false);
  const [msg, setMsg] = useState({ open: false, msg: "", severity: "success" });
  const {
    floorDetails,
    sectionsList,
    currentSection,
    currentStep,
    sectionsData,
    workingData,
    initWorkingData,
    hasChanges,
  } = useSelector((state) => state.floorplan);
  // const sectionsList = useSelector(state => state.floorplan.sectionsList)
  const [selectedSection, setSelectedSection] = useState("");
  const [showCreateSection, toggleCreateSection] = useState(false);
  const [showConfirm, setShowConfirm] = useState(null);
  const [showRename, setRename] = useState(null);
  const Context = useContext(MyContext);
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentSection) setSelectedSection(currentSection.section_id);
    else setSelectedSection([]);
  }, [currentSection]);

  const duplicateItem = (index) => {
    const citem = data[index];
    const currentItem = { ...citem, x: citem.x + 20, y: citem.y + 20 };
    delete currentItem.object_id;
    delete currentItem.table_id;
    const tableName = `${citem.name} Copy`;
    let citemName = "";
    if (tableName.length <= 10) {
      citemName = tableName;
    } else {
      citemName = tableName.substr(0, 10);
    }
    const item = { ...currentItem, id: `${currentItem.id}1`, name: citemName };
    // setMsg({ open: true, msg: citem.name + " has been successfully duplicated", severity: "success" })
    // setTimeout(() => {
    //   setMsg({ open: false, msg: "", severity: "success" })
    // }, 2000);
    dispatch({ type: "ADD_ITEM", payload: item });
    closeMenu();
  };
  const deleteItem = (index) => {
    const citem = data[index];
    setMsg({
      open: true,
      msg: citem.name + Context.langData.item_delete_error,
      severity: "success",
    });
    setTimeout(() => {
      setMsg({ open: false, msg: "", severity: "success" });
    }, 2000);
    dispatch({ type: "DELETE_ITEM", index });
    closeMenu();
  };
  const editItem = (index) => {
    dispatch({ type: "SELECT_ITEM", index });
    closeMenu();
  };
  const closeMenu = () => {
    setAnchorEl(null);
    setCurrent(-1);
  };
  useEffect(() => {
    const tables = data.filter((w) => w.category === "Table");
    let seats = 0;
    tables.map((t) => {
      seats += t.seats;
    });
    setTablesData({ tables: tables.length, seats });
  }, [data]);

  const onChangeSection = (val) => {
    console.log("changing floorplan", val, hasChanges);
    if (hasChanges) {
      setShowConfirm(val);
      if (val == -1) {
        toggleWithChange(val);
        console.log("OPNE");
      }
    } else if (val == -1) {
      toggleCreateSection(true);
      console.log("OPNE");
    } else {
      console.log(val);
      if (currentStep == 0) {
        onChangedSection(val);
      } else {
        console.log("r u sure");
        setShowConfirm(val);
      }
    }
  };
  const onChangedSection = (val) => {
    setSelectedSection(val);
    const { sections } = sectionsData;
    const section = sections.find((e) => e.section_id == val);
    console.log("onChangedSection", section);
    const d = parseFloorBuilder(section);
    console.log(d);
    if (d.floor.type) dispatch({ type: "SET_FLOOR", payload: d });
    dispatch({ type: "CURRENTSTEP" });
    dispatch({ type: "SET_CURRENT_SECTION", payload: section });
    // dispatch({ type: "SECTIONS", payload: sdata, list: sectionsList })
    dispatch({ type: "SET_WORKINGDATA", workingData: d.workingData });
    dispatch({ type: "SET_HISTORY", payload: [d.workingData] });
  };
  const onConfirm = (status) => {
    console.log("change with ", withChangeCreate, status, showConfirm);
    if (withChangeCreate) {
      const v = withChangeCreate;
      console.log("change inner", v);
      toggleWithChange(false);
      setShowConfirm(null);
      if (status) {
        toggleCreateSection(true);
      }
    } else {
      if (status) {
        onChangedSection(showConfirm);
      }

      setShowConfirm(null);
    }
  };
  const onCreateSectiom = useCallback(
    (data) => {
      dispatch({ type: "ADD_CURRENTSTEP" });
      const section = {
        name: data.name,
        otype_id: data.otype,
        objects: [],
        tables: [],
        view_object: {},
        section_config: {
          color: "#BCC1CC",
          orientation: "horizontal",
          type: "solid",
        },
        section_id: data.name,
      };
      const sdata = {
        ...sectionsData,
        sections: [...sectionsData.sections, section],
      };
      const d = parseFloorBuilder(section);
      console.log("sectionsectionsection", section);
      console.log(d);
      if (d?.floor?.type) dispatch({ type: "SET_FLOOR", payload: d });
      dispatch({ type: "SET_CURRENT_SECTION", payload: section });
      dispatch({
        type: "SECTIONS",
        payload: sdata,
        list: [...sectionsList, { section_id: data.name, name: data.name }],
      });
      dispatch({ type: "SET_WORKINGDATA", workingData: d.workingData });
      dispatch({ type: "SET_HISTORY", payload: [d.workingData] });
      toggleCreateSection(false);
    },
    [sectionsData]
  );
  const OnRenameSection = useCallback(
    (data) => {
      const section = { ...currentSection, ...data };
      let sections = [...sectionsData.sections];
      const index = sections.findIndex(
        (e) => e.section_id == section.section_id
      );
      console.log("INDEX", index, section, currentSection);
      sections = [
        ...sections.slice(0, index),
        section,
        ...sections.slice(index + 1),
      ];
      const sdata = {
        ...sectionsData,
        sections,
      };
      const list = sections.map((s) => ({
        section_id: s.section_id,
        name: s.name,
      }));
      console.log("sectionsllllll", section, list, data);
      const d = parseFloorBuilder(section);
      console.log(d);
      if (d?.floor?.type) dispatch({ type: "SET_FLOOR", payload: d });
      dispatch({ type: "SET_CURRENT_SECTION", payload: section });
      dispatch({ type: "SECTIONS", payload: sdata, list });
      dispatch({ type: "SET_WORKINGDATA", workingData: d.workingData });
      dispatch({ type: "SET_HISTORY", payload: [d.workingData] });
      setRename(null);
    },
    [currentSection, sectionsData]
  );
  const deleteSection = () => {
    setShowDeleteSection(true);
  };
  const parseFloorBuilder = (data) => {
    const temp = [];
    console.log("datadata", data);
    if (data.tables?.length > 0) {
      data.tables.map((t) => {
        temp.push({
          ...t.table_config,
          object_id: t.object_id,
          table_id: t.table_id,
          name: t.name,
          seats: t.seats,
        });
      });
    }
    if (data.objects?.length > 0) {
      data.objects.map((t) => {
        temp.push({ ...t.object_config, object_id: t.object_id, name: t.name });
      });
    }
    return { workingData: temp, floor: data.section_config };
  };
  const renameSection = () => {
    saveOnEdit(true);
    setRename({
      name: currentSection.name,
      otype_id: currentSection.otype_id,
      section_id: currentSection.section_id,
    });
  };
  const onDeleteConfirm = (callbackValue) => {
    setShowDeleteSection(false);
    if (callbackValue) {
      console.log(callbackValue);
      const data = [...workingData];
      const tables = data.filter((d) => d.category == "Table");
      const objects = data.filter((d) => d.category !== "Table");
      const t = tables.map((tab) => {
        const { name, seats, table_id, object_id, ...table_config } = tab;
        return { name, seats, table_config, table_id, object_id };
      });
      const o = objects.map((tab) => {
        const { name, object_id, ...object_config } = tab;
        return { name, object_config, object_id };
      });
      const deleteConfig = [];
      initWorkingData.map((ind) => {
        const d = data.findIndex((da) => da.object_id === ind.object_id);
        if (d == -1) {
          deleteConfig.push(ind.object_id);
        }
      });
      const obj = {
        floorplan_id: floorDetails.floorplan_id,
        tables: t,
        objects: o,
        section_config: floorDetails.floor,
        deleted_objects: deleteConfig,
        name: currentSection.name,
        otype_id: currentSection.otype_id,
        delete_section: 1,
      };
      if (currentSection.section_id !== currentSection.name) {
        obj.section_id = currentSection.section_id;
        dispatch({ type: "SET_CURRENT_SECTION", payload: null });
        dispatch(saveFloorPlanBuilder(obj, deleteSectionCallback));
      } else {
        dispatch({ type: "SET_CURRENT_SECTION", payload: null });

        dispatch(
          getFloorPlanBuilder({ floorplan_id: floorDetails.floorplan_id })
        );
      }
    } else {
    }
  };
  const deleteSectionCallback = (status) => {
    setTimeout(() => {
      let d = { open: false, severity: "success", msg: "" };
      if (status == 1) {
        setSelectedSection([]);
        d = {
          open: true,
          severity: "success",
          msg: Context.langData.floor_plan_delete_succ_message,
        };
      } else {
        d = {
          open: true,
          severity: "error",
          msg: Context.langData.error_delete_section,
        };
      }
      setMsg(d);
      setTimeout(() => {
        setMsg({ open: false, severity: "success", msg: "" });
      }, 3000);
    }, 2000);
  };
  const onItemMenuSelected = (id, index) => {
    console.log("ASALKSAS", id, index);
    if (id == 1) {
      console.log("editing item", index);
      editItem(index);
    } else if (id == 2) {
      console.log("deleting item", index);
      deleteItem(index);
    } else if (id == 3) {
      console.log("copy item", index);
      duplicateItem(index);
    }
  };
  return (
    <Grid container className={style.padding} style={{ padding: 20 }}>
      <Grid container className={style.paddingVertical}>
        <H2
          className={clsx(style.fw600)}
          label={Context.langData.floorplan_overview}
        />
      </Grid>
      <Grid container className={style.paddingVertical}>
        <SingleSelect
          list={[...sectionsList, { section_id: -1, name: "Add new section" }]}
          handleChange={(e) => onChangeSection(e.target.value)}
          displayKey='name'
          value={selectedSection}
          valueKey='section_id'
          placeholder='Select Section'
          border={false}
          minWidth={250}
          height={56}
        />
      </Grid>
      {currentSection && (
        <Grid
          container
          className={style.paddingVertical}
          style={{ display: "flex", alignItems: "center" }}>
          <H4
            style={{ color: "#004C60", cursor: "pointer" }}
            label='Rename section'
          />
          <CreateOutlinedIcon
            onClick={renameSection}
            style={{ marginLeft: 16.5 }}
            width={20}
            height={20}
            color='#000000'
          />
          <DeleteIcon
            width={20}
            height={20}
            color='#000000'
            style={{ marginLeft: 10 }}
            onClick={deleteSection}
          />
        </Grid>
      )}
      <Grid container className={style.paddingVertical}>
        <H5
          className={clsx(style.fw500)}
          label={`${Context.langData.viewport}: ${floorDetails.device.device}`}
        />
      </Grid>
      <Grid container className={style.paddingVertical} justify='flex-start'>
        <Paper
          className={clsx(style.border, style.padding, style.marginHorizantal)}>
          <H5 label={Context.langData.tables + tablesData.tables} />
        </Paper>
        <Paper className={clsx(style.border, style.padding)}>
          <H5 label={Context.langData.seats + tablesData.seats} />
        </Paper>
      </Grid>

      <Grid container className={style.paddingVertical} justify='flex-start'>
        <InfoOutlined
          width={12}
          height={12}
          color='#808285'
          style={{ marginRight: 5, marginTop: 2 }}
        />
        <H4 label={Context.langData.click_on} style={{ color: "#808285" }} />
      </Grid>

      <Grid
        container
        className={style.paddingVertical}
        justify='space-between'
        style={{ alignItems: "center" }}>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}>
          <FloorView style={{ marginRight: 5 }} />
          <H4 className={clsx(style.fw600)} label='Floor plan background' />
        </div>
        {currentSection && (
          <div>
            {/* <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickFloorMenu}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorElFloor}
              // getContentAnchorEl={anchorElFloor}
              keepMounted
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElFloor)}
              onClose={() => { setAnchorElFloor(null) }}
            >
              <MenuItem onClick={() => dispatch({ type: "SET_FLOORSELECTED" })} ><EditIcon color={"#000000"} width={16} height={16} /> <span style={{ marginLeft: 5 }}><H4 className={clsx(style.fw500)} label={Context.langData.edit} /></span></MenuItem>
            </Menu> */}
            <div
              style={{
                textAlign: "right",
                color: "red",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => dispatch({ type: "SET_FLOORSELECTED" })}>
              <EditIcon style={{ marginRight: 5 }} width={21} height={21} />
              <T label='Edit' />
            </div>
            {/* <ContextMenu menuItems={[{ id: 1, icon: EditIcon, name: "Edit" }]} callBack={() => dispatch({ type: "SET_FLOORSELECTED" })} /> */}
          </div>
        )}
      </Grid>
      <Grid
        container
        alignItems='flex-start'
        style={{ height: window.innerHeight - 180 }}>
        <Grid container style={{ paddingBottom: 20 }}>
          {data.map((item, index) => (
            <Grid
              item
              xs={12}
              key={index}
              style={{
                maxHeight: 64,
                height: 64,
                backgroundColor: "#fafafa",
                marginBottom: 4,
                display: "flex",
                borderRadius: 5,
              }}
              className={clsx(style.paddingHorizantal, style.marginVertical)}>
              <Grid container justify='center' alignItems='center'>
                <Grid item xs={9}>
                  <H4 className={clsx(style.fw600)} label={item.name} />
                </Grid>
                <Grid item xs={2}>
                  {item.category == "Table" && (
                    <H5 label={`${Context.langData.seats}: ${item.seats}`} />
                  )}
                </Grid>
                <Grid item xs={1}>
                  {/* <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => handleClick(event, index)}>
                        <MoreVertIcon />
                      </IconButton>
                      <MenuActions
                        anchorEl={anchorEl}
                        index={index}
                        onClose={() => { setAnchorEl(null); setCurrent(-1) }}
                        actions={actions}
                        onDelete={() => deleteItem(index)}
                        onEdit={() => editItem(index)}
                        duplicateItem={() => duplicateItem(index)}
                      /> */}
                  <ContextMenu
                    menuItems={[
                      { id: 1, icon: EditIcon, name: "Edit" },
                      { id: 2, icon: DeleteIcon, name: "Delete" },
                      { id: 3, icon: CopyIcon, name: "Duplicate" },
                    ]}
                    showLines
                    callBack={(d) => onItemMenuSelected(d, index)}
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <CenterModal
        open={showCreateSection}
        // onClose={() => toggleCreateSection(false)}
      >
        <CreateSection
          onClose={() => toggleCreateSection(false)}
          onSave={onCreateSectiom}
        />
      </CenterModal>
      <CenterModal open={showRename !== null}>
        <RenameSection
          onClose={() => setRename(null)}
          onSave={(data) => OnRenameSection(data)}
          data={showRename}
        />
      </CenterModal>
      <CenterModal borderRadius={4} open={showConfirm !== null}>
        <Confirm callback={onConfirm} />
      </CenterModal>
      <CenterModal borderRadius={4} open={showDeleteSetcion}>
        <DeleteConfirm callback={onDeleteConfirm} />
      </CenterModal>
      <Message
        open={msg.open}
        severity={msg.severity}
        msg={msg.msg}
        handleClose={() => console.log()}
      />
    </Grid>
  );
};

const DeleteConfirm = ({ callback }) => (
  <Grid container style={{ maxWidth: 400, padding: 32 }}>
    <Grid item xs={12}>
      <H2 label='Delete section?' />
    </Grid>
    <Grid item xs={12} style={{ marginTop: 25 }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <InfoOutlined width={18} height={18} />
        <T
          style={{ marginLeft: 5 }}
          label='Are you sure you want to delete this section? This cannot be undone.'
        />
      </div>
    </Grid>
    <Grid item xs={12}>
      <Grid container style={{ marginTop: 20 }} justify='flex-end'>
        <Grid item xs={6}>
          <Grid container style={{ paddingRight: 5 }}>
            <Button
              onClick={() => callback(true)}
              variant='contained'
              size='large'
              style={{
                backgroundColor: "#FFF",
                border: "2px solid #707272",
                width: "100%",
                padding: "10px 0px",
              }}>
              <T label='Yes, Proceed' />
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: 5 }}>
          <Button
            onClick={() => callback(false)}
            variant='contained'
            size='large'
            style={{
              backgroundColor: "#FFB600",
              border: "2px solid #FFB600",
              width: "100%",
              padding: "10px 0px",
            }}>
            <T label='No, Continue editing' />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);
export default ItemsList;
