import React, { useEffect, useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import useStyles from "../../styles";
import { H6, AvenirBlackH4 } from "../../utils/text";

import MyContext from "../../Provider/MyContext";
import NoPermissionPage from "../../components/NoPermission";
import { fetchPaymentTypes, fetchOrderTypes } from "../../redux/actions";
import AllTransactions from "./AllTransactions";

const Transactions = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const [buttonArray, setButtonArray] = useState([]);
  const TransactionsList = [
    { key: "transactions", title: Context.langData.sales_transactions, id: 0 },
  ];
  const [selectedIndex, setSelectedIndex] = useState(0);
  const userPermissions = useSelector(
    (state) => state.loginReducer.userPermissions
  );
  const handleSelectItem = (item) => {
    setSelectedIndex(item);
  };
  useEffect(() => {
    dispatch(fetchPaymentTypes());
    dispatch(fetchOrderTypes());
    // dispatch(fetchAllVendors());
  }, []);
  const isItemHasPermission = useMemo(() => {
    const buttonName = [];
    let buttonCount = 0;
    TransactionsList.map((childItem) => {
      const obj = userPermissions.filter((e) => e.key == childItem.key);
      if (obj.length > 0) {
        var childObj = {};
        if (obj[0].status) {
          buttonCount += 1;
          childObj = { ...childItem, isPermission: true };
        } else {
          childObj = { ...childItem, isPermission: false };
        }
        buttonName.push(childObj);
      } else {
        var childObj = { ...childItem, isPermission: false };
        buttonName.push(childObj);
      }
    });
    if (buttonCount == 0) {
      setButtonArray([]);
    } else {
      setButtonArray(buttonName);
      let result = false;
      if (buttonName.length > 0) {
        result = buttonName[selectedIndex].isPermission;
      } else {
        result = false;
      }
      return result;
    }
  }, [userPermissions]);
  const renderSelectedView = () => {
    if (selectedIndex == 0) {
      if (isItemHasPermission) {
        return <AllTransactions data-qaid='transactions_allTransactions' />;
      }
      return (
        <NoPermissionPage data-qaid='transactions_allTransactions_nopermissions' />
      );
    }
  };

  return (
    <Grid
      data-qaid='transactions_index_grid'
      style={{
        backgroundColor: "#FFFFFF",
        minHeight: "100%",
        width: "100%",
        overflow: "hidden",
      }}>
      <Grid
        data-qaid='transactions_index_contentgrid'
        style={{ height: "20%" }}>
        <Grid
          data-qaid='transactions_index_titlegrid'
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginLeft: 36 }}>
          <H6
            data-qaid='transactions_index_title_label'
            label={Context.langData.transactions}
            style={{
              color: "#051D33",
              paddingTop: 36,
              fontWeigth: "800",
            }}
          />
        </Grid>
        <Grid
          data-qaid='transactions_index_bodygrid'
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            marginLeft: 36,
            marginRight: 36,
            marginTop: 36,
            marginBottom: 16,
            flexDirextion: "row",
          }}>
          {buttonArray.map((item, i) => (
            <Button
              data-qaid='transaction_button'
              style={{
                backgroundColor:
                  selectedIndex == item.id ? "#051D33" : "#EEEEEE",
                marginRight: 1,
                borderRadius: 0,
                borderTopLeftRadius: i == 0 ? 4 : 0,
                borderBottomLeftRadius: i == 0 ? 4 : 0,
                borderTopRightRadius: buttonArray.length - 1 == i ? 4 : 0,
                borderBottomRightRadius: buttonArray.length - 1 == i ? 4 : 0,
              }}
              onClick={() => handleSelectItem(item.id)}>
              <AvenirBlackH4
                data-qaid='transactions_button_label'
                label={item.title}
                style={{
                  color: selectedIndex == i ? "#FFFFFF" : "#051D33",
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 10,
                  paddingBottom: 10,
                  lineHeight: "24px",
                }}
              />
            </Button>
          ))}
        </Grid>
        <Grid
          data-qaid='transactions_border'
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ borderTop: "1px solid #EEEEEE" }}
        />
      </Grid>
      <Grid data-qaid='transactions_body_view' style={{ height: "80%" }}>
        {renderSelectedView()}
      </Grid>
    </Grid>
  );
};

export default Transactions;
