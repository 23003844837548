import React, { useEffect, useState, useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../styles";
import VerticalGrid from "../../../components/SVG/VerticalGrid";
import HorizontalGrid from "../../../components/SVG/HorizontalGrid";
import Color from "../../../components/Color";
import MyContext from "../../../Provider/MyContext";
import { H2, H4 } from "../../../utils/text";
import Back from "../../../components/SVG/Back";

const FloorSettings = () => {
  const style = styles({ padding: 10, margin: 5 });
  const style1 = styles({ padding: 30, margin: 5 });
  const [selectedDesign, setDesign] = useState("solid");
  const [color, setColor] = useState("#BCC1CC");
  const floor = useSelector((state) => state.floorplan.floorDetails.floor);
  const [orientation, setOriantation] = useState("horizontal");
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const setDesignType = (type) => {
    dispatch({ type: "SET_BACKGROUND", payload: { color, type, orientation } });
    // setDesign(type)
  };
  const changeOriantation = (orientation) => {
    dispatch({
      type: "SET_BACKGROUND",
      payload: { color, type: selectedDesign, orientation },
    });
    // setOriantation(orientation)
  };
  useEffect(() => {
    setDesign(floor.type);
    setColor(floor.color);
    setOriantation(floor.orientation);
  }, [floor]);
  const setBackgroundColor = (color) => {
    // setColor(c.css.backgroundColor);
    dispatch({
      type: "SET_BACKGROUND",
      payload: { color, type: selectedDesign, orientation },
    });
    // const rgb = c.rgb.push(c.alpha).join(",");
    // const rgba = `rgba(${rgb})`;
    // setColor(rgba);
  };
  const onCancel = () => {
    dispatch({ type: "CANCEL" });
  };

  return (
    <Grid
      container
      className={clsx(style.paddingHorizantal, style.fullHeight)}
      style={{ position: "relative", padding: 20 }}>
      <Grid
        container
        style={{ height: window.innerHeight - 180, overflow: "auto" }}>
        <Grid container>
          <span onClick={onCancel}>
            <Back />
          </span>
        </Grid>
        <Grid
          container
          style={{ height: 40, marginTop: 30 }}
          className={clsx(style.borderBottom)}>
          <H2 label={Context.langData.floor} />
        </Grid>
        <Grid
          container
          alignItems='flex-start'
          className={clsx(style.padding)}
          style={{ width: "100%", height: 150 }}>
          <H4 label={Context.langData.design} style={{}} />
          <Grid container justify='flex-start'>
            <Grid
              xs={4}
              item
              className={style.active}
              onClick={() => setDesignType("wood")}>
              <Item
                text='Wood'
                active={selectedDesign == "wood"}
                className='wood_horizontal'
                style={{ width: 50, height: 50 }}
              />
            </Grid>
            <Grid xs={4} item>
              <Item
                text='Tile'
                active={selectedDesign == "tail"}
                className='tail_horizontal'
                style={{ width: 50, height: 50 }}
                onClick={() => setDesignType("tail")}
              />
            </Grid>
            <Grid xs={4} item>
              <Item
                text='Solid'
                active={selectedDesign == "solid"}
                style={{ width: 50, height: 50, backgroundColor: "#DDE0E6" }}
                onClick={() => setDesignType("solid")}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          className={clsx(style.padding)}
          style={{ width: "100%", height: 200 }}>
          {selectedDesign == "solid" ? (
            <Grid container direction='column'>
              <Typography variant='h5'>{Context.langData.color}</Typography>
              <Grid
                container
                justify='flex-start'
                style={{ width: "100%", height: 120 }}
                className={style1.marginVertical}
                alignItems='center'>
                <Color color={color} setColor={setBackgroundColor} />
              </Grid>
            </Grid>
          ) : (
            <>
              {selectedDesign == "tail" ? (
                <></>
              ) : (
                <>
                  <Typography variant='h5'>
                    {Context.langData.orientation}
                  </Typography>
                  <Grid container justify='flex-start' alignItems='center'>
                    <Grid xs={4} item className={style.active}>
                      <Grid
                        container
                        alignItems='center'
                        style={{ width: 120, height: 120 }}>
                        <div
                          onClick={() => changeOriantation("horizontal")}
                          style={{
                            backgroundColor:
                              orientation == "horizontal"
                                ? "#f1f1f1"
                                : "transparent",
                            padding: 20,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          <HorizontalGrid />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid xs={4} item>
                      <Grid
                        container
                        justify='center'
                        alignItems='center'
                        style={{ width: 120, height: 120 }}>
                        <div
                          onClick={() => changeOriantation("vertical")}
                          style={{
                            backgroundColor:
                              orientation == "vertical"
                                ? "#f1f1f1"
                                : "transparent",
                            padding: 20,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          <VerticalGrid />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
const Item = ({ className, style, active = false, onClick, text }) => (
  <div>
    <div
      onClick={onClick}
      style={{
        flexDirection: "column",
        borderRadius: 10,
        width: 60,
        height: 60,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: active ? "black" : "transparent",
      }}>
      <div className={className} style={{ ...style, borderRadius: 10 }} />
    </div>
    <div
      style={{
        display: "flex",
        width: "50%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}>
      {text}
    </div>
  </div>
);
export default FloorSettings;
