import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid, FormHelperText } from "@material-ui/core";
import InputText from "../../../components/InputText";
import { AvenirBlackH4, H5, H6 } from "../../../utils/text";
import CustomButton from "../../../components/CustomButton";
import Color from "../../../components/Color";
import CancelIcon from "../../../components/SVG/CloseCircle";
import { saveWebpages } from "../../../redux/actions";
import WebSiteTitle from "./websiteBuilderTitle";

const EditRestDetails = ({ onClose, data }) => {
  const [color, setColor] = useState("#00B6C9");
  const [name, setName] = useState("");
  const [intro, setIntro] = useState("");
  const [btnText, setBtnText] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setColor(data.button_color || "#00B6C9");
    setName(data.header);
    setIntro(data.introduction);
    setBtnText(data.button_text);
  }, [data]);

  const onSave = () => {
    const obj = {
      button_color: color,
      header: name,
      introduction: intro,
      button_text: btnText,
      isUpdated: true,
    };
    dispatch(
      saveWebpages(obj, (status) => {
        if (status) {
          // dispatch({
          //       type: 'SET_WEB_INFO',
          //        payload: obj })
          onClose();
        }
      })
    );
  };
  const onIntroChange = (val) => {
    if (val.length <= 150) {
      setIntro(val);
    }
  };
  return (
    <Grid container style={{ maxWidth: 450, position: "relative" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: 10,
          paddingRight: 10,
          width: "100%",
        }}>
        <CancelIcon
          style={{ float: "right", cursor: "pointer" }}
          onClick={onClose}
        />
      </div>
      <Grid
        container
        style={{ height: "90%", padding: 20, paddingBottom: 150 }}>
        <Grid item xs={12}>
          <WebSiteTitle />
        </Grid>
        <Grid item xs={12}>
          <span style={{ fontWeight: 900, fontSize: 18 }}>
            <H6 label='Content block' />
          </span>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 30 }}>
          <InputText
            onChange={(e) => setName(e.target.value)}
            placeholder='Heading'
            maxLength={64}
            value={name}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 30 }}>
          <InputText
            onChange={(e) => onIntroChange(e.target.value)}
            placeholder='Introduction text'
            value={intro}
            height='100%'
            rows={5}
            maxLength={128}
            inputProps={{ maxLength: 128 }}
            multiline
          />
          <FormHelperText
            data-qaid='inputtext_formhelpertext'
            style={{
              display: "flex",
              margin: "8px 0px 0px 0px",
              justifyContent: "flex-end",
            }}>
            <H5 label={`${intro.length}/150`} style={{ textAlign: "left" }} />
          </FormHelperText>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 30 }}>
          <InputText
            onChange={(e) => setBtnText(e.target.value)}
            placeholder='Button text'
            maxLength={64}
            value={btnText}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 30 }}>
          <AvenirBlackH4
            data-qaid='inputtext_placeholder'
            label='Button colour'
            style={{ marginBottom: -5 }}
          />
          <Color setColor={(e) => setColor(e)} color={color} width='100%' />
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          borderTop: "1px solid #c3c3c3",
          position: "fixed",
          bottom: 0,
          width: "100%",
          padding: 40,
          paddingRight: 20,
          backgroundColor: "#FAFAFA",
        }}>
        <Grid
          item
          xs={12}
          style={{
            position: "fixed",
            bottom: 20,
            alignSelf: "flex-end",
            right: 40,
          }}>
          <Grid container justify='flex-end'>
            {/* <Button variant="contained" onClick={onSave} style={{ backgroundColor: '#FFB600' }}>
              <AvenirBlackH4 label="Save" />
            </Button> */}
            <CustomButton label='Save' onClick={onSave} width={90} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditRestDetails;
