const initialState = {
  taxProfiles: [],
  createTaxProfile: [],
  editTaxProfile: [],
  taxes: [],
  taxList: [],
  taxCode: {},
  taxProfileList: [],
};

const taxConfigurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TAXESTAX_LIST":
      return {
        ...state,
        taxList: action.payload,
      };
    case "GET_TAX_PROFILES":
      return {
        ...state,
        taxProfiles: parseTaxProfiles(action.payload),
      };
    case "GET_TAX_PROFILE_LIST":
      return {
        ...state,

        taxProfileList: parseTaxProfileList(action.payload),
      };
    case "GET_TAX_PROFILE_ASSOCIATION_LIST":
      return {
        ...state,

        taxProfileAssociations: parseTaxProfileAssociationList(action.payload),
      };
    case "GET_TAX_PROFILE":
      return {
        ...state,
        taxProfiles: action.payload,
      };
    case "GET_TAXES_SUCCESS":
      return {
        ...state,
        taxes: parseTaxes(action.payload),
      };
    case "GET_TAX_CODE":
      return {
        ...state,
        taxCode: action.payload,
      };
    default:
      return state;
  }
};

function parseTaxes(response) {
  const obj = response.data;
  obj.map((d) => {
    d.id = d.id;
    d.value = `${d.rate}%`;
  });
  return obj;
}

const parseTaxProfiles = (response) => {
  const obj = response.data;

  const taxProfiles = obj.map((d) => {
    const taxProfile = {};
    taxProfile.id = d.id;
    taxProfile.name = d.name;
    taxProfile.desc = d.desc;
    taxProfile.taxes = d.taxes.map((x) => `${x.code}(${x.rate}%)`).join(", ");
    taxProfile.hasSpecialRule = d.hasSpecialRule;
    return taxProfile;
  });
  return taxProfiles;
};

const parseTaxProfileAssociationList = (response) => {
  const obj = response.data;

  const taxProfileAssociations = obj.map((d) => {
    const taxProfileAssociation = {};
    taxProfileAssociation.id = d.taxProfileId;
    taxProfileAssociation.name = d.taxProfileName;
    taxProfileAssociation.categories = d.categories;
    taxProfileAssociation.items = d.items;
    return taxProfileAssociation;
  });
  return taxProfileAssociations;
};

const parseTaxProfileList = (response) => {
  const obj = response.data;

  const taxProfiles = obj.map((d) => {
    const taxProfile = {};
    taxProfile.id = d.id;
    taxProfile.name = d.name;

    return taxProfile;
  });
  return taxProfiles;
};
export default taxConfigurationReducer;
