import instance, { report_instance } from "./axiosInstance";

import urls from "./urls";

const fetchConsolidatedData = (params) => (dispatch) => {
  report_instance
    .get(urls.inventoryReports.consolidated, { params })
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({
          type: "GET_CONSOLIDATED_SUCCESS",
          payload: response.data.data,
        });
      }
    });
};

const fetchAllConsumptions = (params) => {
  console.log("fetchAllConsumptions called");
  return (dispatch) => {
    report_instance
      .get(urls.inventoryReports.consumption, { params })
      .then((response) => {
        if (response.status === 200 || response.status === 304)
          dispatch({ type: "SET_CONSUMPTIONS", payload: response.data.data });
      });
  };
};

const fetchKitchensData = (params) => (dispatch) => {
  report_instance
    .get(urls.inventoryReports.kitchenReports, { params })
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({
          type: "GET_KITCHEN_DATA_SUCCESS",
          payload: response.data,
        });
      }
    });
};

const fetchVendorsData = (params) => (dispatch) => {
  report_instance
    .get(urls.inventoryReports.vendorReports, { params })
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({
          type: "GET_VENDORS_DATA_SUCCESS",
          payload: response.data,
        });
      }
    });
};

const fetchPurchasesData = (params) => (dispatch) => {
  report_instance
    .get(urls.inventoryReports.purchaseReports, { params })
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({
          type: "GET_PURCHASES_DATA_SUCCESS",
          payload: response.data,
        });
      }
    });
};

const fetchContactTracing = (params, callback) => (dispatch) => {
  instance
    .get(urls.salesReports.contactTracing, { params })
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        if (params.export !== true)
          dispatch({
            type: "GET_CONTACTTRACING_DATA_SUCCESS",
            payload: response.data,
          });
        if (callback) {
          callback(response.data);
        }
      }
    });
};

export {
  fetchConsolidatedData,
  fetchKitchensData,
  fetchVendorsData,
  fetchPurchasesData,
  fetchAllConsumptions,
  fetchContactTracing,
};
