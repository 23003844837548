import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Grid } from "@material-ui/core";
import Moment from "moment";
import { AvenirBlackH4 } from "../utils/text";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

export default function SimpleDate(props) {
  console.log(
    ` Moment(props.date).format('DD-MM-YYYY')${Moment(props.date).format(
      "DD-MM-YYYY"
    )}`,
    "props.date",
    props.date,
    "Moment(props.date).format('YYYY-MM-DD')",
    Moment(props.date).format("YYYY-MM-DD")
  );
  const classes = useStyles();
  const [date, setDate] = React.useState(
    Moment(props.date).format("YYYY-MM-DD")
  );
  function handleChange(event) {
    console.log("event", Moment(event.target.value).format("DD-MM-YYYY"));
    setDate(Moment(event.target.value).format("YYYY-MM-DD"));
    props.callback(Moment(event.target.value).format("YYYY-MM-DD"));
  }
  return (
    <form data-qaid='simpleDate_form' className={classes.container} noValidate>
      <Grid
        data-qaid='simpleDate_grid'
        style={{ display: "flex", flexDirection: "column" }}>
        <AvenirBlackH4
          data-qaid='simpleDate_label'
          label={`${props.label} :`}
        />
        <TextField
          data-qaid='simpleDate_textfield'
          variant='outlined'
          id='date'
          type='date'
          value={date}
          className={classes.textField}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </form>
  );
}
